export const GET_PRODUCT_BONUS_LIST = 'getcare/admin/productBonus/GET_PRODUCT_BONUS_LIST';
export const UPDATE_PRODUCT_BONUS_ITEM = 'getcare/admin/productBonus/UPDATE_PRODUCT_BONUS_ITEM';
export const DELETE_PRODUCT_BONUS_ITEMS = 'getcare/admin/productBonus/DELETE_PRODUCT_BONUS_ITEMS';
export const EXPORT_PRODUCT_BONUS_LIST_TEMPLATE = 'getcare/admin/productBonus/EXPORT_PRODUCT_BONUS_LIST_TEMPLATE';

// mutation
export const SAVE_TOTAL = 'getcare/admin/productBonus/SAVE_TOTAL';
export const SAVE_LOADING = 'getcare/admin/productBonus/SAVE_LOADING';
export const SAVE_ACTION_LOADING = 'Sgetcare/admin/productBonus/AVE_ACTION_LOADING';

export const SAVE_PRODUCT_BONUS_LIST = 'getcare/admin/productBonus/SAVE_PRODUCT_BONUS_LIST';
export const SAVE_PRODUCT_BONUS_ITEM = 'getcare/admin/productBonus/SAVE_PRODUCT_BONUS_ITEM';
export const REMOVE_PRODUCT_BONUS_ITEMS = 'getcare/admin/productBonus/REMOVE_PRODUCT_BONUS_ITEMS';
