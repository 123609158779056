import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { 
  assetBrandingTypes,
} from 'utils/constanst/adminAssetBrandingConstants';

import IconButton from '@material-ui/core/IconButton';
import EditOutlined from '@material-ui/icons/EditOutlined';
import CloseIcon from '@material-ui/icons/Close';

import gridClasses from '../AssetBrandingListGrid.module.scss';
import classes from './AssetBrandingListItem.module.scss';

class AssetBrandingListItem extends PureComponent {
  render() {
    const {
      value,
      type_id,
      displayFields,
      isEditAllowed,
      isDeleteAllowed,
    } = this.props;

    return (
      <div
        className={`${classes.AssetBrandingListItem} ${gridClasses.Row} ${gridClasses[`Row__column--${displayFields.length}`]}`}
      >
        {
          displayFields.map((field, index) => {
            let displayValue = <div key={index} className={gridClasses.Col}>
              {this.props[field.name]}
            </div>;
            switch (field.name) {
              case "action": {
                displayValue = <div key={index} className={`${classes.ActionsCol} ${gridClasses.Col}`}>
                  {
                    isEditAllowed && <IconButton
                      size="small"
                      color="primary"
                      onClick={this.props.onEditAssetBrandings}
                    ><EditOutlined fontSize="small"/></IconButton>
                  }
                  {
                    isDeleteAllowed && <IconButton
                      size="small"
                      color="secondary"
                      onClick={this.props.onRemoveAssetBrandings}
                    ><CloseIcon fontSize="small"/></IconButton>
                  }
                </div>
                break;
              }
              case "sample": {
                displayValue = <div key={index} className={gridClasses.Col}>
                  {
                    (() => {
                      const type = assetBrandingTypes.find( item => item.value === type_id)
                      if ( !type ) return;
                      let element = value;
                      switch ( type.value ) {
                        case 2: { // Color
                          element = <div style={{width: '32px',height: '18px', backgroundColor: `#${value}`}}></div>;
                          break;
                        }
                        case 3: { // Hình ảnh
                          element = <a href={value} target="_blank">{value}</a>;
                          break;
                        }
                      }
                      return element;
                    })()
                  }
                </div>
                break;
              }
            }
            return displayValue
          })
        }
      </div>
    );
  }
}

AssetBrandingListItem.propTypes = {
  displayFields: PropTypes.array,
  value: PropTypes.string,
  type_id: PropTypes.number,
  isEditAllowed: PropTypes.bool,
  isDeleteAllowed: PropTypes.bool,
};

export default AssetBrandingListItem;
