import React, { PureComponent } from 'react';

import { 

} from 'utils/constanst/adminAssetComponentConstants';
import authApi from 'utils/api/authApi';

import { Formik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { isEqual, maxBy } from 'lodash';

import { toast } from 'react-toastify';
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  Grid,
} from '@material-ui/core';
import AutocompleteVendor from 'components/AutocompleteVendor/AutocompleteVendor';
import FileUpload from 'components/FileUpload/FileUpload';

import classes from './AssetComponentFormDialogForm.module.scss';

class AssetComponentFormDialogForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      uploadingFile: false,
    }
    this.formikRef = React.createRef();
  }

  _prepareData = (params) => {
    return {
      id: params.id,
      name: params.name,
      code: params.code,
      link: params.link,
      platform: params.platform,
      font: params.font,
      font_size: params.font_size,
      width: params.width,
      height: params.height,
      description: params.description,
      getcare_vendor_id: params.getcare_vendor_id,
    }
  }
  _formikInitialValues = () => {
    const { data } = this.props;
    return {
        id: data?.id || undefined,
        name: data?.name || '',
        code: data?.code || '',
        link: data?.link || '',
        platform: data?.platform || '',
        font: data?.font || '',
        font_size: data?.font_size || '',
        width: data?.width || '',
        height: data?.height || '',
        description: data?.description || '',
        getcare_vendor_id: data?.getcare_vendor_id || '',
    }
  }
  _formikValidationSchema = () => {
    return {
      name: Yup.string().required("Vui lòng nhập").max(255, 'Tối đa 255 ký tự'),
      code: Yup.string().required("Vui lòng nhập").max(255, 'Tối đa 255 ký tự'),
      font_size: Yup.string().required("Vui lòng nhập"),
      width: Yup.string().required("Vui lòng nhập"),
      height: Yup.string().required("Vui lòng nhập"),
    }
  }
  _autoSetRatioWidthHeight = (url,setFieldValue) => {
    const img = new Image();
    const react = this;
    const gcd = (a,b) => { 
      if (b == 0) return a;
      else return gcd(b, a % b);
    }
    img.onload = function(){
      const divisor = gcd(this.width, this.height);
      setFieldValue("width",this.width / divisor);
      setFieldValue("height",this.height / divisor);
    };  
    img.src = url;
  }

  handleSubmit = (values) => {
    this.props.onSubmitForm({
      ...this._prepareData(values),
    });
  }
  handleFilesAdded = async (files,setFieldValue) => {
    if (!files[0]) return
    this.setState({ uploadingFile: true });
    const imageFiles = await this.handleUploadImageFiles({ params: { file: files[0] } });
    setFieldValue("link",imageFiles[0]?.url);
    this._autoSetRatioWidthHeight(imageFiles[0]?.url,setFieldValue)
    this.setState({ uploadingFile: false });
  }
  handleUploadImageFiles = async ({ params }) => {
    try {
      const { data: response } = await authApi.uploadMultipleFiles({
        params,
      });
      if (!response?.result || !response.data) {
        toast.error(response.message);
        return;
      }
      return response.data;
    } catch(err) {
      toast.error(err);
    }
    return;
  }

  render() {
    const { 
      uploadingFile,
    } = this.state;
    
    return (
      <Formik
        innerRef={(ref) => { this.props.formRef(ref); this.formikRef = ref }}
        ref={this.props.formikRef}
        initialValues={this._formikInitialValues()}
        onSubmit={this.handleSubmit}
        validationSchema={Yup.object().shape(this._formikValidationSchema())}
      >
        {(props) => {
          const { handleChange, values, errors, setFieldValue } = props;
          return (
            <form noValidate autoComplete="off">
                <FormControl className={classes.FormControl} component="fieldset">
                  <div className={classes.FieldControl}>
                    <label>
                      Name <span className={classes.RequiredMark}>*</span>
                    </label>
                    <TextField
                      autoComplete="off"
                      value={values.name}
                      placeholder="Nhập name"
                      name="name"
                      error={!!errors.name}
                      helperText={errors.name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className={classes.FieldControl}>
                    <label>
                      Code <span className={classes.RequiredMark}>*</span>
                    </label>
                    <TextField
                      autoComplete="off"
                      value={values.code}
                      placeholder="Nhập code"
                      name="code"
                      error={!!errors.code}
                      helperText={errors.code}
                      onChange={handleChange}
                    />
                  </div>
                  <div className={classes.FieldControl}>
                    <label>
                      Link
                    </label>
                    <div className={`${uploadingFile && 'OverlayLoading'}`}>
                      <TextField
                        style={{ width: '100%', marginBottom: '.75rem'}}
                        autoComplete="off"
                        value={values.link}
                        placeholder="Nhập url hình ảnh"
                        name="link"
                        onChange={(e) => {
                          this._autoSetRatioWidthHeight(e.target.value,setFieldValue)
                          handleChange(e)
                        }}
                      />
                      hoặc
                      <FileUpload onFilesAdded={(files) => this.handleFilesAdded(files,setFieldValue)} accept="image/*"/>
                      { values.link  && <div style={{ marginTop: '.75rem'}} className={classes.FileImageUpload}>
                          <img src={values.link} />
                        </div>
                      }
                      <p style={{ marginTop: '.75rem', marginBottom: 0 }}><b>Lưu ý: </b> Hình ảnh sau khi upload sẽ tự động điền width và height, chỉnh sửa width và height phải đúng với tỷ lệ hình ảnh.</p>
                    </div>
                  </div>
                  <div className={classes.FieldControl}>
                    <label>
                      Width 
                    </label>
                    <TextField
                      autoComplete="off"
                      value={values.width}
                      placeholder="Nhập width"
                      name="width"
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={!!errors.width}
                      helperText={errors.width}
                      onChange={handleChange}
                    />
                  </div>
                  <div className={classes.FieldControl}>
                    <label>
                      Height 
                    </label>
                    <TextField
                      autoComplete="off"
                      value={values.height}
                      placeholder="Nhập height"
                      name="height"
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={!!errors.height}
                      helperText={errors.height}
                      onChange={handleChange}
                    />
                  </div>
                  <div className={classes.FieldControl}>
                    <label>
                      Platform 
                    </label>
                    <TextField
                      autoComplete="off"
                      value={values.platform}
                      placeholder="Nhập platform"
                      name="platform"
                      onChange={handleChange}
                    />
                  </div>

                  <div className={classes.FieldControl}>
                    <label>
                      Font 
                    </label>
                    <TextField
                      autoComplete="off"
                      value={values.font}
                      placeholder="Nhập font"
                      name="font"
                      onChange={handleChange}
                    />
                  </div>
                  <div className={classes.FieldControl}>
                    <label>
                      Font size 
                    </label>
                    <TextField
                      autoComplete="off"
                      value={values.font_size}
                      placeholder="Nhập font size"
                      name="font_size"
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={!!errors.font_size}
                      helperText={errors.font_size}
                      onChange={handleChange}
                    />
                  </div>
                  <div className={classes.FieldControl}>
                    <label>
                      Description 
                    </label>
                    <TextField
                      autoComplete="off"
                      value={values.description}
                      placeholder="Nhập description"
                      name="description"
                      multiline
                      rows={4}
                      onChange={handleChange}
                    />
                  </div>
                  <div className={classes.FieldControl}>
                    <label>
                      Vendor <span className={classes.RequiredMark}>*</span>
                    </label>
                    <AutocompleteVendor
                      name="getcare_vendor_id"
                      value={values.getcare_vendor_id}
                      onChange={(e, value) => {
                        setFieldValue("getcare_vendor_id",value)
                      }}
                    />
                  </div>
                </FormControl>
            </form>
          );
        }}
      </Formik>
    );
  }
}

AssetComponentFormDialogForm.propTypes = {

};

AssetComponentFormDialogForm.defaultProps = {

};

export default AssetComponentFormDialogForm;
