import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { updateStore, createStore } from 'redux/actions/admin/storeActions';
import format from 'date-fns/format';
import { validDate } from 'utils/helper';
import { activeStatusMap, DEACTIVE } from 'utils/constanst/adminStoreConstants';
import { dateFormat } from 'utils/constanst/dateConstants';
import Panel from 'components/Panel/Panel';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { VN_ID } from 'utils/constanst/locationConstants';
import customerApi from 'utils/api/admin/customerApi';
import locationApi from 'utils/api/locationApi';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import * as Yup from 'yup';
import DeactiveCustomerDialog from '../DeactiveCustomerDialog/DeactiveCustomerDialog';


import classes from './StoreInfo.module.scss';

const defautCountry = {
  "name": "Viet Nam",
  "id" :  220
};
const WarnMessage = ({ errors }) => {
  const getFieldLabel = (field) => {
    switch (field) {
      case 'getcare_district':
        return 'Quận/Huyện';
      case 'getcare_country':
        return 'Quốc gia';
      case 'getcare_province':
        return 'Tỉnh/TP';
      case 'getcare_ward':
        return 'Phường/Xã';
      case 'address':
        return 'Địa chỉ';
      case 'name':
        return 'Tên khách hàng';
      case 'code':
        return 'Mã khách hàng';
      case 'getcare_customer_type':
        return 'Loại khách hàng';
      case 'phone':
        return 'Điện thoại';
      case 'email':
        return 'Email';
      case 'registration_company':
        return 'Tên doanh nghiệp';
      case 'registration_number':
        return 'Số đăng ký';
      case 'tax_code':
        return 'Mã số thuế';
      case 'tax_address':
        return 'Địa chỉ xuất hoá đơn';
      case 'type_pharmacy':
        return 'Loại hình';
      case 'representative_name':
        return 'Tên người đại diện';
      case 'expertise_person':
        return 'Người chiụ trách nhiệm chuyên môn';
      case 'expertise_number':
        return 'Số chứng chỉ hành nghề của NCTNCM';
      case 'latitude':
        return 'Latitude';
      case 'longitude':
        return 'Longitude';
      default:
        return '';
    }
  }

  return (
    <div className={`${classes.WarningDetails}`}>
      <p className={`${classes.WarningTitle}`}>Vui lòng kiểm tra lại các thông tin sau:</p>
      <ul>
        {Object.keys(errors).map((error, index) => {
          return (
            <li key={`error-${index}`} className={`${classes.WarningField}`}>+ {getFieldLabel(error)}: {errors[error]}</li>
          )
        })}
      </ul>
    </div>
  )
}

class StoreInfo extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      wards: [],
      country: [],
      provinces: [],
      isOpen: false,
      districts: [],
      customerTypes: [],
    }
  }

  async componentDidMount() {
    const { setRef } = this.props;
    setRef(this);

    if (!this.state.editMode) {
      const { data: response } = await customerApi.getCustomerTypes();
      if (!response.result || !response.data) return;
      const customerTypes = [...response.data];
      this.setState({
        customerTypes,
      })

      // In edit mode, reload is executed after base info is ready
      this._reload();
    }
  }

  componentWillUnmount() {
    const { setRef } = this.props;
    setRef(undefined);
  }

  componentDidUpdate(prevProps) {
    if (this.props.id && this.props.id !== prevProps.id) {
      this.setState({
        ...this.props,
      }, () => {
        // Reset to initial state when new item is loaded
        this.props.onChange && this.props.onChange(false);
        this.formRef && this.formRef.resetForm({
          values: this._getInitialValues()
        });
        this._reload();
      })
    }
  }

  _reload = async () => {
    await this.loadCountries();
    await this.loadProvinces();
    await this.loadDistricts();
    await this.loadWards();
  }

  // Return item having given template or null if not found
  findItem = (list, template) => {
    const items = list.filter(item => item?.id === template?.id);
    return items.length ? items[0] : null;
  }


  loadCountries = async () => {
    // Load provinces once
    if (this.state.country.length === 0) {
      let countryId = this.state.getcare_country?.id;
      if (!countryId) {
        countryId = VN_ID;
      }

      const { data: response } = await locationApi.getCountries({
        name: ''
      })
      if (response?.result) {
        this.setState({
          country: [...response.data],
        })
      }
    }
  }


  loadProvinces = async () => {

    // Load provinces once

    const { provinces, getcare_country, getcare_province } = this.state;
    let currentProvince = this.findItem(provinces, getcare_province);

    if (getcare_country && !currentProvince) {
      const { data: response } = await locationApi.getProvinces({
        name: '',
        getcare_country_id: getcare_country?.id
      });
      if (response?.result) {
        // Verify currently-selected district
        const newProvinceList = [...response.data];
        currentProvince = this.findItem(newProvinceList, getcare_province);
        this.setState({
          provinces: newProvinceList,
          getcare_province: currentProvince
        })
      }
    }else {
      const { data: response } = await locationApi.getProvinces({
        name: '',
        getcare_country_id: 220
      });
      if (response?.result) {
        // Verify currently-selected district
        const newProvinceList = [...response.data];
        currentProvince = this.findItem(newProvinceList, getcare_province);
        this.setState({
          provinces: newProvinceList,
          getcare_province: currentProvince
        })
      }
    }
  }

  loadDistricts = async () => {
    // Districts loaded only if given province is provided
    // and currently-selected district is invalid
    const { districts, getcare_district, getcare_province } = this.state;
    let currentDistrict = this.findItem(districts, getcare_district);
    if (getcare_province && !currentDistrict) {
      const { data: response } = await locationApi.getDistricts({
        name: '',
        getcare_province_id: getcare_province?.id
      });
      if (response?.result) {
        // Verify currently-selected district
        const newDistrictList = [...response.data];
        currentDistrict = this.findItem(newDistrictList, getcare_district);
        this.setState({
          districts: newDistrictList,
          getcare_district: currentDistrict
        })
      }
    }
  }

  handleClose = () => {
    this.setState({
      isOpen: false
    })
  }

  handleOpen = () => {
    this.setState({
      isOpen: true
    })
  }

  loadWards = async () => {
    let newState;
    let getcare_ward;
    // Wards loaded only a district given
    if (this.state.getcare_district) {
      const { data: response } = await locationApi.getWards({
        name: '',
        getcare_district_id: this.state.getcare_district.id
      })
      if (response?.result) {
        const wards = [...response.data];
        newState = { wards };
        getcare_ward = this.findItem(wards, this.state.getcare_ward);
      }
    }
    newState = {
      ...newState,
      getcare_ward,
    }
    this.setState(newState);
  }

  _getInitialValues = () => {
    const {
      name,
      note,
      phone,
      email,
      address,
      tax_code,
      latitude,
      longitude,
      tax_address,
      getcare_ward,
      type_pharmacy,
      expertise_number,
      expertise_person,
      getcare_country,
      getcare_district,
      getcare_province,
      registration_number,
      representative_name,
      registration_company,
      getcare_customer_type,
    } = this.state;

    return {
      name: name || '',
      note: note || '',
      email: email || '',
      phone: phone || '',
      address: address || '',
      tax_code: tax_code || '',
      latitude: latitude || '',
      longitude: longitude || '',
      tax_address: tax_address || '',
      getcare_ward: getcare_ward ? getcare_ward : null,
      type_pharmacy: type_pharmacy || '',
      expertise_number: expertise_number || '',
      expertise_person: expertise_person || '',
      getcare_country: getcare_country ? getcare_country : defautCountry,
      getcare_province: getcare_province ? getcare_province : null,
      getcare_district: getcare_district ? getcare_district : null,
      representative_name: representative_name || '',
      registration_number: registration_number || '',
      registration_company: registration_company || '',
      getcare_customer_type: getcare_customer_type ? getcare_customer_type : null,
    }
  }

  _getValidationShape = () => {
    return {
      name: Yup.string().required("Vui lòng nhập").max(255, 'Tối đa 255 ký tự'),
      phone: Yup.string().required("Vui lòng nhập").max(55, 'Tối đa 55 ký tự'),
      email: Yup.string().matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'Email không đúng định dạng').max(55, 'Tối đa 55 ký tự'),
      note: Yup.string().max(512, 'Tối đa 512 ký tự'),
      registration_number: Yup.string().max(255, 'Tối đa 255 ký tự'),
      type_pharmacy: Yup.string().max(255, 'Tối đa 255 ký tự'),
      address: Yup.string().required("Vui lòng nhập").max(255, 'Tối đa 255 ký tự'),
      representative_name: Yup.string().max(255, 'Tối đa 255 ký tự'),
      registration_company: Yup.string().max(255, 'Tối đa 255 ký tự'),
      expertise_person: Yup.string().max(255, 'Tối đa 255 ký tự'),
      expertise_number: Yup.string().max(255, 'Tối đa 255 ký tự'),
      tax_code: Yup.string().max(255, 'Tối đa 255 ký tự'),
      tax_address: Yup.string().max(255, 'Tối đa 255 ký tự'),
      longitude: Yup.string().matches(/^[-]?\d+\.?\d+$/i, 'Longitude không đúng định dạng').max(30, 'Tối đa 30 ký tự'),
      latitude: Yup.string().matches(/^[-]?\d+\.?\d+$/, 'Latitude không đúng định dạng').max(30, 'Tối đa 30 ký tự'),
      getcare_customer_type: Yup.object().nullable().required("Vui lòng chọn"),
      getcare_country: Yup.object().nullable().required("Vui lòng chọn"),
      getcare_province: Yup.object().nullable().when('getcare_country', { 
        is: value => value && value.name === "Viet Nam",
        then: Yup.object().nullable().required('Vui lòng chọn'),
      }),

      getcare_district: Yup.object().nullable().when('getcare_country', { 
        is: value => value && value.name === "Viet Nam",
        then: Yup.object().nullable().required('Vui lòng chọn'),
      }),
      getcare_ward: Yup.object().nullable().when('getcare_country', { 
        is: value => value && value.name === "Viet Nam",
        then: Yup.object().nullable().required('Vui lòng chọn'),
      }),
    }
  }

  handleChange = (field, value, needReload) => {
    const { onChange } = this.props;
    let newState = {
      ...this.state,
      [field]: value,
    }
    if (field === 'getcare_province') {
      newState = {
        ...newState,
        wards: [],
        districts: [],
        getcare_district: null,
        getcare_ward: null,
      }
    } else if (field === 'getcare_district') {
      newState = {
        ...newState,
        wards: [],
        getcare_ward: null,
      }
    }else if (field === 'getcare_country') {
      newState = {
        ...newState,
        wards: [],
        districts: [],
        province: [],
        getcare_district: null,
        getcare_ward: null,
        getcare_province: [],
      }
    }
    this.setState(newState, () => {
      onChange && onChange(true);
      needReload && this._reload();
    })
  }

  revertChanges = () => {
    // Revert back all changes from props (to state)
    this.setState({
      ...this.props,
    }, () => {
      this.formRef.resetForm();
      this._reload();
    });
  }

  saveChanges = async () => {
    // Submit the form for validation
    if (this.formRef.isValid) {
      this.formRef.submitForm();
      return true;
    } else {
      const errors = await this.formRef.validateForm(this.formRef.values);
      toast.error(<WarnMessage errors={errors} />);
      return false;
    }
  }

  handleSubmit = (values) => {
    const { onDataUpdated } = this.props;
    // Collect changes and submit them to the backend
    let changes = {};
    Object.keys(values).forEach(field => {
      const currentValue = values[field];
      const originalValue = this.props[field] || '';

      let submitField;
      let submitValue;
      let isChanged = false;
      switch (field) {
        case 'getcare_province':
          submitField = 'getcare_province_id';
          submitValue = currentValue?.id;
          isChanged = currentValue?.id !== originalValue?.id;
          break;
        case 'getcare_country':
          submitField = 'getcare_country_id';
          submitValue = currentValue?.id;
          isChanged = currentValue?.id !== originalValue?.id;
          break;
        case 'getcare_district':
          submitField = 'getcare_district_id';
          submitValue = currentValue?.id;
          isChanged = currentValue?.id !== originalValue?.id;
          break;
        case 'getcare_ward':
          submitField = 'getcare_ward_id';
          submitValue = currentValue?.id;
          isChanged = currentValue?.id !== originalValue?.id;
          break;
        case 'getcare_customer_type':
          submitField = 'getcare_customer_type_id';
          submitValue = currentValue?.id;
          isChanged = currentValue?.id !== originalValue?.id;
          break;
        case 'phone':
          submitField = 'phone';
          submitValue = (/^(84)/).test(currentValue) ? currentValue.replace(/^(84)/, '+84') : currentValue;
          isChanged = currentValue !== originalValue;
          break;
        default:
          submitField = field;
          submitValue = currentValue;
          isChanged = currentValue !== originalValue;
      }

      if (isChanged) {
        changes = {
          ...changes,
          [submitField]: submitValue,
        }
      }
    })


    if (Object.keys(changes).length) {
      if (this.props.id) {
        this.props.updateStore({
          id: this.props.id,
          params: changes,
          onDataUpdated,
        })
      } else {
        this.props.createStore({
          params: changes,
        })
      }
    }
  }

  isDifferent = (item1, item2) => {
    if (item1 && item2) {
      // both items are not null
      return item1 !== item2;
    } else if (item1 || item2) {
      // one is null, & other is not
      return true;
    } else {
      // both items are null
      return false;
    }
  }

  render() {
    const {
      code,
      wards,
      active,
      approval,
      editMode,
      districts,
      country,
      provinces,
      created_at,
      updated_at,
      deactive_date,
      customerTypes,
      isOpen,
      unapproval_reason,
      latest_approval_data,
    } = this.state;

    const isCreateAllowed = !editMode && this.props.isCreateAllowed;
    const isEditAllowed = editMode && this.props.isEditAllowed;
    const hasActions = isCreateAllowed || isEditAllowed;

    const approved = approval === 1;
    const currentStatus = active === 1 ? 1 : -1;
    const empty = {}
    const redColor = {
      style: { color: 'red' }
    }

    return (
      <Formik
        innerRef={(ref) => this.formRef = ref}
        initialValues={this._getInitialValues()}
        onSubmit={this.handleSubmit}
         enableReinitialize={true}
        validationSchema={Yup.object().shape(this._getValidationShape())}>
        {({ values, errors, handleChange, setFieldValue }) => {
          const isNameDiff = this.isDifferent(values?.name, latest_approval_data?.name);
          const isAddressDiff = this.isDifferent(values?.address, latest_approval_data?.address);
          const isCountryDiff = this.isDifferent(values?.getcare_country?.id, latest_approval_data?.getcare_country?.id);
          const isProvinceDiff = this.isDifferent(values?.getcare_province?.id, latest_approval_data?.getcare_province?.id);
          const isDistrictDiff = this.isDifferent(values?.getcare_district?.id, latest_approval_data?.getcare_district?.id);
          const isWardDiff = this.isDifferent(values?.getcare_ward?.id, latest_approval_data?.getcare_ward?.id);
          const isPhoneDiff = this.isDifferent(values?.phone, latest_approval_data?.phone);
          const isEmailDiff = this.isDifferent(values?.email, latest_approval_data?.email);
          const isRegistrationCompanyDiff = this.isDifferent(values?.registration_company, latest_approval_data?.registration_company);
          const isTaxCodeDiff = this.isDifferent(values?.tax_code, latest_approval_data?.tax_code);
          const isTaxAddressDiff = this.isDifferent(values?.tax_address, latest_approval_data?.tax_address);
          const isRegistrationNumberDiff = this.isDifferent(values?.registration_number, latest_approval_data?.registration_number);
          const isTypePharmacyDiff = this.isDifferent(values?.type_pharmacy, latest_approval_data?.type_pharmacy);
          const isRepresentativeNameDiff = this.isDifferent(values?.representative_name, latest_approval_data?.representative_name);
          const isExpertiseNumberDiff = this.isDifferent(values?.expertise_number, latest_approval_data?.expertise_number);
          const isExpertisePersonDiff = this.isDifferent(values?.expertise_person, latest_approval_data?.expertise_person);
          const isCustomerTypeDiff = this.isDifferent(values?.getcare_customer_type?.id, latest_approval_data?.getcare_customer_type?.id);

          const originalName = isNameDiff ? latest_approval_data?.name : '';
          const originalAddress = isAddressDiff ? latest_approval_data?.address : '';
          const originalCountry = isCountryDiff ? latest_approval_data?.getcare_country?.name : '';
          const originalProvince = isProvinceDiff ? latest_approval_data?.getcare_province?.name : '';
          const originalDistrict = isDistrictDiff ? latest_approval_data?.getcare_district?.name : '';
          const originalWard = isWardDiff ? latest_approval_data?.getcare_ward?.name : '';
          const originalPhone = isPhoneDiff ? latest_approval_data?.phone : '';
          const originalEmail = isEmailDiff ? latest_approval_data?.email : '';
          const originalRegistrationCompany = isRegistrationCompanyDiff ? latest_approval_data?.registration_company : '';
          const originalTaxCode = isTaxCodeDiff ? latest_approval_data?.tax_code : '';
          const originalTaxAddress = isTaxAddressDiff ? latest_approval_data?.tax_address : '';
          const originalRegistrationNumber = isRegistrationNumberDiff ? latest_approval_data?.registration_number : '';
          const originalTypePharmacy = isTypePharmacyDiff ? latest_approval_data?.type_pharmacy : '';
          const originalRepresentativeName = isRepresentativeNameDiff ? latest_approval_data?.representative_name : '';
          const originalExpertiseNumber = isExpertiseNumberDiff ? latest_approval_data?.expertise_number : '';
          const originalExpertisePerson = isExpertisePersonDiff ? latest_approval_data?.expertise_person : '';
          const originalCustomerType = isCustomerTypeDiff ? latest_approval_data?.getcare_customer_type?.name : '';
          const currentCountry = this.findItem(country, values.getcare_country);
          const currentProvince = this.findItem(provinces, values.getcare_province);
          
          const currentDistrict = this.findItem(districts, values.getcare_district);
          const currentWard = this.findItem(wards, values.getcare_ward);
          const currentCustomerType = this.findItem(customerTypes, values.getcare_customer_type);

          return (
            <form noValidate autoComplete="off">
              <Panel
                title="Thông tin chung"
                panelClassName={classes.Panel}
                contentClassName={classes.PanelContent}
                content={
                  <div className={`${classes.Details} ${classes.IsEditing}`}>
                    <div className={classes.DetailsCol}>
                      {editMode && (
                        <div className={classes.FieldControl}>
                          <label>Trạng thái</label>
                          {activeStatusMap[currentStatus]}
                        </div>
                      )}
                      {editMode && (
                        <div className={classes.FieldControl}>
                          <label>Mã khách hàng*</label>
                          {code}
                        </div>
                      )}
                      <div className={classes.FieldControl}>
                        <label>Loại khách hàng*</label>
                        { hasActions ?
                          <Autocomplete
                            disableClearable
                            name="getcare_customer_type"
                            value={currentCustomerType}
                            options={customerTypes || []}
                            getOptionLabel={(option) => option?.name || ''}
                            renderOption={(option) => option?.name}
                            getOptionSelected={(option, value) => option.id === value.id}
                            renderInput={(params) => (
                              <TextField
                                placeholder="Chọn..."
                                {...params}
                                error={!editMode && !!errors.getcare_customer_type}
                                helperText={editMode ? originalCustomerType : errors.getcare_customer_type}
                                InputProps={{
                                  ...params.InputProps,
                                  ...editMode && isCustomerTypeDiff && !approved ? redColor : empty
                                }}
                              />
                            )}
                            onChange={(e, newValue) => {
                              setFieldValue('getcare_customer_type', newValue);
                              this.handleChange('getcare_customer_type', newValue);
                            }}
                          /> : (
                            <div className={classes.Info}>
                              <p className={`${!approved && isCustomerTypeDiff ? 'TextDanger' : ''}`}>{ currentCustomerType?.name || '-' }</p>
                              <p className={classes.OriInfo}>{ originalCustomerType }</p>
                            </div>
                          )
                        }
                      </div>
                      <div className={classes.FieldControl}>
                        <label>Tên khách hàng*</label>
                        { hasActions ?
                          <TextField
                            multiline
                            rowsMax={2}
                            autoComplete="off"
                            value={values.name}
                            placeholder="Nhập..."
                            autoFocus={true}
                            name="name"
                            error={!editMode && !!errors.name}
                            helperText={editMode ? originalName : errors.name}
                            inputProps={!approved && editMode && isNameDiff ? redColor : empty}
                            onChange={(e) => {
                              handleChange(e);
                              this.handleChange('name', e.target.value);
                            }}
                          /> : (
                            <div className={classes.Info}>
                              <p className={`${!approved && isNameDiff ? 'TextDanger' : ''}`}>{ values.name || '-' }</p>
                              <p className={classes.OriInfo}>{ originalName }</p>
                            </div>
                          )
                        }
                      </div>
                      <div className={classes.FieldControl}>
                        <label>Địa chỉ*</label>
                        { hasActions ?
                          <TextField
                            multiline
                            rowsMax={3}
                            autoComplete="off"
                            value={values.address}
                            placeholder="Nhập..."
                            name="address"
                            error={!editMode && !!errors.address}
                            helperText={editMode ? originalAddress : errors.address}
                            inputProps={!approved && editMode && isAddressDiff ? redColor : empty}
                            onChange={(e) => {
                              handleChange(e);
                              this.handleChange('address', e.target.value);
                            }}
                          /> : (
                            <div className={classes.Info}>
                              <p className={`${!approved && isAddressDiff ? 'TextDanger' : ''}`}>{ values.address|| '-' }</p>
                              <p className={classes.OriInfo}>{ originalAddress }</p>
                            </div>
                          )
                        }
                      </div>
                      <div className={classes.FieldControl}>
                        <label>Quốc gia*</label>
                        { hasActions ?
                          <Autocomplete
                            disableClearable
                            name="country"
                            value={currentCountry}
                            options={country || []}
                            getOptionLabel={(option) => option?.name || ''}
                            renderOption={(option) => option?.name}
                            getOptionSelected={(option, value) => option.id === value.id}
                            renderInput={(params) => (
                              <TextField
                                placeholder="Chọn..."
                                {...params}
                                error={!editMode && !!errors.getcare_country}
                                helperText={editMode ? originalCountry : errors.getcare_country}
                                InputProps={{
                                  ...params.InputProps,
                                  ...editMode && isCountryDiff && !approved ? redColor : empty
                                }}
                              />
                            )}
                            onChange={async(e, newValue) => {
                             await setFieldValue('getcare_country', newValue);
                              setFieldValue('getcare_province', '');
                              setFieldValue('getcare_district', '');
                              setFieldValue('getcare_ward', '');
                              const needReload = newValue?.id !== this.state.getcare_country?.id;
                              await this.handleChange('getcare_country', newValue, needReload);
                            }}
                          /> : (
                            <div className={classes.Info}>
                              <p className={`${!approved && isCountryDiff ? 'TextDanger' : ''}`}>{ currentCountry?.name|| '-' }</p>
                              <p className={classes.OriInfo}>{ originalCountry }</p>
                            </div>
                          )
                        }
                      </div>
                 
                      <div className={classes.FieldControl}>
                        <label>Tỉnh/TP*</label>
                        { hasActions ?
                          <Autocomplete
                            disableClearable
                            name="provinces"
                            value={currentProvince}
                            options={provinces || []}
                            getOptionLabel={(option) => option?.name || ''}
                            renderOption={(option) => option?.name}
                            getOptionSelected={(option, value) => option.id === value.id}
                            renderInput={(params) => (
                              <TextField
                                placeholder="Chọn..."
                                {...params}
                                error={!editMode && errors &&  !!errors.getcare_province}
                                helperText={editMode ? originalProvince : errors.getcare_province}
                                InputProps={{
                                  ...params.InputProps,
                                  ...editMode && isProvinceDiff && !approved ? redColor : empty
                                }}
                              />
                            )}
                            onChange={async (e, newValue) => {
                              await setFieldValue('getcare_province', newValue);
                              setFieldValue('getcare_district', null);
                              setFieldValue('getcare_ward', null);
    
                              const needReload = newValue?.id !== this.state.getcare_province?.id;
                              await this.handleChange('getcare_province', newValue, needReload);
                            }}
                          /> : (
                            <div className={classes.Info}>
                              <p className={`${!approved && isProvinceDiff ? 'TextDanger' : ''}`}>{ currentProvince?.name|| '-' }</p>
                              <p className={classes.OriInfo}>{ originalProvince }</p>
                            </div>
                          )
                        }
                      </div>
                      <div className={classes.FieldControl}>
                        <label>Quận/Huyện*</label>
                        { hasActions ?
                          <Autocomplete
                            disableClearable
                            name="district"
                            value={currentDistrict}
                            options={districts || []}
                            getOptionLabel={(option) => option?.name || ''}
                            renderOption={(option) => option?.name}
                            getOptionSelected={(option, value) => option.id === value.id}
                            renderInput={(params) => (
                              <TextField
                                placeholder="Chọn..."
                                {...params}
                                error={!editMode && !!errors.getcare_district}
                                helperText={editMode ? originalDistrict : errors.getcare_district}
                                InputProps={{
                                  ...params.InputProps,
                                  ...editMode && isDistrictDiff && !approved ? redColor : empty
                                }}
                              />
                            )}
                            onChange={ async(e, newValue) => {
                             await  setFieldValue('getcare_district', newValue);
                              setFieldValue('getcare_ward', null);
                              const needReload = newValue?.id !== this.state.getcare_district?.id;
                              await this.handleChange('getcare_district', newValue, needReload);
                            }}
                          /> : (
                            <div className={classes.Info}>
                              <p className={`${!approved && isDistrictDiff ? 'TextDanger' : ''}`}>{ currentDistrict?.name|| '-' }</p>
                              <p className={classes.OriInfo}>{ originalDistrict }</p>
                            </div>
                          )
                        }
                      </div>
                      <div className={classes.FieldControl}>
                        <label>Phường/Xã*</label>
                        { hasActions ?
                          <Autocomplete
                            disableClearable
                            name="ward"
                            value={currentWard}
                            options={wards || []}
                            getOptionLabel={(option) => option?.name || ''}
                            renderOption={(option) => option?.name}
                            getOptionSelected={(option, value) => option.id === value.id}
                            renderInput={(params) => (
                              <TextField
                                placeholder="Chọn..."
                                {...params}
                                error={!editMode && !!errors.getcare_ward}
                                helperText={editMode ? originalWard : errors.getcare_ward}
                                InputProps={{
                                  ...params.InputProps,
                                  ...editMode && isWardDiff && !approved ? redColor : empty
                                }}
                              />
                            )}
                            onChange={(e, newValue) => {
                              setFieldValue('getcare_ward', newValue);
                              this.handleChange('getcare_ward', newValue, true);
                            }}
                          /> : (
                            <div className={classes.Info}>
                              <p className={`${!approved && isWardDiff ? 'TextDanger' : ''}`}>{ currentWard?.name|| '-' }</p>
                              <p className={classes.OriInfo}>{ originalWard }</p>
                            </div>
                          )
                        }
                      </div>
                      <div className={classes.FieldControl}>
                        <label>Điện thoại*</label>
                        { hasActions ?
                            <TextField
                              autoComplete="off"
                              value={values.phone}
                              placeholder="Nhập..."
                              name="phone"
                              error={!editMode && !!errors.phone}
                              helperText={editMode ? originalPhone : errors.phone}
                              inputProps={editMode && isPhoneDiff && !approved ? redColor : empty}
                              onChange={(e) => {
                                handleChange(e);
                                this.handleChange('phone', e.target.value);
                              }}
                            />
                          : (
                            <div className={classes.Info}>
                              <p className={`${!approved && isPhoneDiff ? 'TextDanger' : ''}`}>{ values.phone|| '-' }</p>
                              <p className={classes.OriInfo}>{ originalPhone }</p>
                            </div>
                          )
                        }
                      </div>
                      <div className={classes.FieldControl}>
                        <label>Email</label>
                        { hasActions ?
                          <TextField
                            autoComplete="off"
                            value={values.email}
                            placeholder="Nhập..."
                            name="email"
                            error={!editMode && !!errors.email}
                            helperText={editMode ? originalEmail : errors.email}
                            inputProps={!approved && editMode && isEmailDiff ? redColor : empty}
                            onChange={(e) => {
                              handleChange(e);
                              this.handleChange('email', e.target.value);
                            }}
                          /> : (
                            <div className={classes.Info}>
                              <p className={`${!approved && isEmailDiff ? 'TextDanger' : ''}`}>{ values.email|| '-' }</p>
                              <p className={classes.OriInfo}>{ originalEmail }</p>
                            </div>
                          )
                        }
                      </div>
                    </div>

                    <div className={classes.DetailsCol}>
                      <div className={classes.FieldControl}>
                        <label>Số đăng ký</label>
                        { hasActions ?
                          <TextField
                            autoComplete="off"
                            value={values.registration_number}
                            placeholder="Nhập..."
                            name="registration_number"
                            error={!editMode && !!errors.registration_number}
                            helperText={originalRegistrationNumber}
                            inputProps={!approved && editMode && isRegistrationNumberDiff ? redColor : empty}
                            onChange={(e) => {
                              handleChange(e);
                              this.handleChange('registration_number', e.target.value);
                            }}
                          /> : (
                            <div className={classes.Info}>
                              <p className={`${!approved && isRegistrationNumberDiff ? 'TextDanger' : ''}`}>{ values.registration_number|| '-' }</p>
                              <p className={classes.OriInfo}>{ originalRegistrationNumber }</p>
                            </div>
                          )
                        }
                      </div>
                      <div className={classes.FieldControl}>
                        <label>Loại hình</label>
                        { hasActions ?
                          <TextField
                            autoComplete="off"
                            value={values.type_pharmacy}
                            placeholder="Nhập..."
                            name="type_pharmacy"
                            error={!editMode && !!errors.type_pharmacy}
                            helperText={originalTypePharmacy}
                            inputProps={!approved && editMode && isTypePharmacyDiff ? redColor : empty}
                            onChange={(e) => {
                              handleChange(e);
                              this.handleChange('type_pharmacy', e.target.value);
                            }}
                          /> : (
                            <div className={classes.Info}>
                              <p className={`${!approved && isTypePharmacyDiff ? 'TextDanger' : ''}`}>{ values.type_pharmacy|| '-' }</p>
                              <p className={classes.OriInfo}>{ originalTypePharmacy }</p>
                            </div>
                          )
                        }
                      </div>
                      <div className={classes.FieldControl}>
                        <label>Tên người đại diện</label>
                        { hasActions ?
                          <TextField
                            autoComplete="off"
                            value={values.representative_name}
                            placeholder="Nhập..."
                            name="representative_name"
                            error={!editMode && !!errors.representative_name}
                            helperText={originalRepresentativeName}
                            inputProps={!approved && editMode && isRepresentativeNameDiff ? redColor : empty}
                            onChange={(e) => {
                              handleChange(e);
                              this.handleChange('representative_name', e.target.value);
                            }}
                          /> : (
                            <div className={classes.Info}>
                              <p className={`${!approved && isRepresentativeNameDiff ? 'TextDanger' : ''}`}>{ values.representative_name|| '-' }</p>
                              <p className={classes.OriInfo}>{ originalRepresentativeName }</p>
                            </div>
                          )
                        }
                      </div>
                      <div className={classes.FieldControl}>
                        <label>Người chiụ trách nhiệm chuyên môn</label>
                        { hasActions ?
                          <TextField
                            autoComplete="off"
                            value={values.expertise_person}
                            placeholder="Nhập..."
                            name="expertise_person"
                            error={!editMode && !!errors.expertise_person}
                            helperText={originalExpertisePerson}
                            inputProps={!approved && editMode && isExpertisePersonDiff ? redColor : empty}
                            onChange={(e) => {
                              handleChange(e);
                              this.handleChange('expertise_person', e.target.value);
                            }}
                          /> : (
                            <div className={classes.Info}>
                              <p className={`${!approved && isExpertisePersonDiff ? 'TextDanger' : ''}`}>{ values.expertise_person|| '-' }</p>
                              <p className={classes.OriInfo}>{ originalExpertisePerson }</p>
                            </div>
                          )
                        }
                      </div>
                      <div className={classes.FieldControl}>
                        <label>Số chứng chỉ hành nghề của NCTNCM</label>
                        { hasActions ?
                          <TextField
                            autoComplete="off"
                            value={values.expertise_number}
                            placeholder="Nhập..."
                            name="expertise_number"
                            error={!editMode && !!errors.expertise_number}
                            helperText={originalExpertiseNumber}
                            inputProps={!approved && editMode && isExpertiseNumberDiff ? redColor : empty}
                            onChange={(e) => {
                              handleChange(e);
                              this.handleChange('expertise_number', e.target.value);
                            }}
                          /> : (
                            <div className={classes.Info}>
                              <p className={`${!approved && isExpertiseNumberDiff ? 'TextDanger' : ''}`}>{ values.expertise_number|| '-' }</p>
                              <p className={classes.OriInfo}>{ originalExpertiseNumber }</p>
                            </div>
                          )
                        }
                      </div>
                      <div className={classes.FieldControl}>
                        <label>Longitude</label>
                        { hasActions ?
                          <TextField
                            autoComplete="off"
                            value={values.longitude}
                            placeholder="Nhập..."
                            name="longitude"
                            error={!!errors.longitude}
                            helperText={errors.longitude}
                            onChange={(e) => {
                              handleChange(e);
                              this.handleChange('longitude', e.target.value);
                            }}
                          /> : (
                            <div className={classes.Info}>
                              <p>{ values.longitude|| '-' }</p>
                            </div>
                          )
                        }
                      </div>
                      <div className={classes.FieldControl}>
                        <label>Latitude</label>
                        { hasActions ?
                          <TextField
                            autoComplete="off"
                            value={values.latitude}
                            placeholder="Nhập..."
                            name="latitude"
                            error={!!errors.latitude}
                            helperText={errors.latitude}
                            onChange={(e) => {
                              handleChange(e);
                              this.handleChange('latitude', e.target.value);
                            }}
                          /> : (
                            <div className={classes.Info}>
                              <p>{ values.latitude|| '-' }</p>
                            </div>
                          )
                        }
                      </div>
                      <div className={classes.FieldControl}>
                        <label>Ghi chú</label>
                        { hasActions ?
                          <TextField
                            multiline
                            rowsMax={5}
                            autoComplete="off"
                            value={values.note}
                            placeholder="Nhập..."
                            name="note"
                            error={!!errors.note}
                            helperText={errors.note}
                            onChange={(e) => {
                              handleChange(e);
                              this.handleChange('note', e.target.value);
                            }}
                          /> : (
                            <div className={classes.Info}>
                              <p>{ values.note|| '-' }</p>
                            </div>
                          )
                        }
                      </div>
                      {editMode && unapproval_reason && (
                        <div className={classes.FieldControl}>
                          <label>Lý do chưa duyệt</label>
                          {unapproval_reason|| '-'}
                        </div>
                      )}
                      {editMode && (
                        <div className={classes.FieldControl}>
                          <label>Ngày dừng hoạt động</label>
                          <div>
                        
                            {(validDate(deactive_date)) && this.state.active === 0  ? <span className={classes.DeactivateDate}>{format(validDate(deactive_date), dateFormat)}</span> : ''}
                            { isEditAllowed && this.state.active !== 0 ?
                              <Button
                                // disabled
                                variant="contained"
                                size="small"
                                color="secondary"
                                onClick={this.handleOpen}
                                style={{ marginLeft: '1rem' }}
                              >
                                Dừng hoạt động
                              </Button>
                              : 
                              <Button
                              disabled
                              variant="contained"
                              size="small"
                              color="secondary"
                              onClick={this.handleOpen}
                              style={{ marginLeft: '1rem' }}
                            >
                              Dừng hoạt động
                            </Button>
                            }
                          </div>
                        </div>
                      )}
                      {editMode && (
                        <div className={classes.FieldControl}>
                          <label>Ngày tạo</label>
                          {validDate(created_at) ? format(validDate(created_at), dateFormat) : '-'}
                        </div>
                      )}
                      {editMode && (
                        <div className={classes.FieldControl}>
                          <label>Ngày sửa</label>
                          {validDate(updated_at) ? format(validDate(updated_at), dateFormat) : '-'}
                        </div>
                      )}
                    </div>
                  </div>
                }
                isBorder
              />
              <Panel
                title="Thông tin xuất hoá đơn"
                panelClassName={classes.Panel}
                contentClassName={classes.PanelContent}
                content={
                  <div className={`${classes.Details} ${classes.IsEditing}`}>
                    <div className={classes.DetailsCol}>
                      <div className={classes.FieldControl}>
                        <label>Tên doanh nghiệp</label>
                        { hasActions ?
                          <TextField
                            autoComplete="off"
                            value={values.registration_company}
                            placeholder="Nhập..."
                            name="registration_company"
                            error={!editMode && !!errors.registration_company}
                            helperText={originalRegistrationCompany}
                            inputProps={!approved && editMode && isRegistrationCompanyDiff ? redColor : empty}
                            onChange={(e) => {
                              handleChange(e);
                              this.handleChange('registration_company', e.target.value);
                            }}
                          /> : (
                            <div className={classes.Info}>
                              <p className={`${!approved && isRegistrationCompanyDiff ? 'TextDanger' : ''}`}>{ values.registration_company|| '-' }</p>
                              <p className={classes.OriInfo}>{ originalRegistrationCompany }</p>
                            </div>
                          )
                        }
                      </div>
                      <div className={classes.FieldControl}>
                        <label>Địa chỉ xuất hoá đơn</label>
                        { hasActions ?
                          <TextField
                            multiline
                            rowsMax={3}
                            autoComplete="off"
                            value={values.tax_address}
                            placeholder="Nhập..."
                            name="tax_address"
                            error={!editMode && !!errors.tax_address}
                            helperText={originalTaxAddress}
                            inputProps={!approved && editMode && isTaxAddressDiff ? redColor : empty}
                            onChange={(e) => {
                              handleChange(e);
                              this.handleChange('tax_address', e.target.value);
                            }}
                          /> : (
                            <div className={classes.Info}>
                              <p className={`${!approved && isTaxAddressDiff ? 'TextDanger' : ''}`}>{ values.tax_address|| '-' }</p>
                              <p className={classes.OriInfo}>{ originalTaxAddress }</p>
                            </div>
                          )
                        }
                      </div>
                    </div>

                    <div className={classes.DetailsCol}>
                      <div className={classes.FieldControl}>
                        <label>Mã số thuế</label>
                        { hasActions ?
                          <TextField
                            autoComplete="off"
                            value={values.tax_code}
                            placeholder="Nhập..."
                            name="tax_code"
                            error={!editMode && !!errors.tax_code}
                            helperText={originalTaxCode}
                            inputProps={!approved && editMode && isTaxCodeDiff ? redColor : empty}
                            onChange={(e) => {
                              handleChange(e);
                              this.handleChange('tax_code', e.target.value);
                            }}
                          /> : (
                            <div className={classes.Info}>
                              <p className={`${!approved && isTaxCodeDiff ? 'TextDanger' : ''}`}>{ values.tax_code|| '-' }</p>
                              <p className={classes.OriInfo}>{ originalTaxCode }</p>
                            </div>
                          )
                        }
                      </div>
                    </div>
                    { isOpen &&
          <DeactiveCustomerDialog
            isOpen={isOpen}
            id={this.state.id}
            onClose={this.handleClose}
          />
        }
                  </div>
                }
                isBorder
              />
            </form>
          )
        }}
      </Formik>
    )
  }
}

StoreInfo.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  active: PropTypes.number,
  address: PropTypes.string,
  approval: PropTypes.number,
  branch_create: PropTypes.string,
  code: PropTypes.string,
  created_at: PropTypes.string,
  deactive_date: PropTypes.string,
  email: PropTypes.string,
  expertise_number: PropTypes.string,
  expertise_person: PropTypes.string,
  getcare_country: PropTypes.object,
  getcare_district: PropTypes.object,
  getcare_province: PropTypes.object,
  getcare_ward: PropTypes.object,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  name: PropTypes.string,
  note: PropTypes.string,
  phone: PropTypes.string,
  registration_number: PropTypes.string,
  representative_name: PropTypes.string,
  type_pharmacy: PropTypes.string,
  tax_code: PropTypes.string,
  tax_address: PropTypes.string,
  updated_at: PropTypes.string,
  registration_company: PropTypes.string,
};

StoreInfo.defaultProps = {
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateStore: (payload) => dispatch(updateStore(payload)),
    createStore: (payload) => dispatch(createStore(payload)),
  };
};
const withConnect = connect(null, mapDispatchToProps);
export default compose(withConnect)(StoreInfo);
