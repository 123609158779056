import React from 'react';
import PropTypes from 'prop-types';
import { filterFields, unapprovedFilterFields, approvalStatusMap } from 'utils/constanst/adminStoreConstants';

import FilterField from 'components/FilterField/FilterField';
import MultiSelect from 'components/FilterField/MultiSelect';
import Checkboxes from 'components/FilterField/Checkboxes';
import customerApi from 'utils/api/admin/customerApi';

import classes from './StoreFilters.module.scss';

class StoreFilters extends React.PureComponent {
  constructor(props) {
    super(props);
    let fields;
    if (props.unapprovedPharmacy || props.unapprovedDoctor) {
      fields = [...unapprovedFilterFields];
    } else {
      fields = [...filterFields];
    }

    this.state = {
      fields,
      customerTypes: []
    }
  }

  async componentDidMount() {
    const { data: response } = await customerApi.getCustomerTypes();
    if (!response.result || !response.data) return;
    this.setState({
      customerTypes: [...response.data],
    });
  }

  _getFieldIndex = (fieldName) => {
    return this.state.fields.findIndex(item => item.name === fieldName);
  }

  handleFieldChange = ({ name, value }) => {
    const newFields = this.state.fields.map(field => field.name === name ? { ...field, value } : field);
    this.setState({
      fields: newFields,
    });
    this.props.onFilterChange(newFields, true);
  }

  render() {
    const { fields, customerTypes } = this.state;

    return (
      <div className={classes.Panel}>
        <h3 className={classes.PanelTitle}>Lọc theo:</h3>
        <div className={classes.PanelContent}>
          {fields.map((field) => (
            <FilterField
              key={field.name}
              {...field}
              defaultValue={['approval', 'getcare_customer_type_id'].includes(field.name) ? [] : ''}
              handleFieldChange={this.handleFieldChange}
              customField={
                field.name === 'approval' ? (
                  <Checkboxes
                    name={field.name}
                    value={field.value}
                    multiple
                    valuesMap={approvalStatusMap}
                    handleFieldChange={this.handleFieldChange}
                  />
                ) : (field.name === 'getcare_customer_type_id' ? (
                  <MultiSelect
                    name={field.name}
                    value={field.value}
                    placeholder={field.placeholder}
                    options={customerTypes}
                    handleFieldChange={this.handleFieldChange}
                  />
                ) : '')
              }
            />
          ))}
        </div>
      </div>
    );
  }
}

StoreFilters.propTypes = {
  onFilterChange: PropTypes.func,
};

export default StoreFilters;
