import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { removeAccents } from 'utils/helper';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { updateGroup, saveGroup } from 'redux/actions/admin/groupActions';
import { ACTIVE, INACTIVE, SALES_TYPE, CUSTOMER_BY_CHANNEL_TYPE } from 'utils/constanst/adminGroupConstants';
import format from 'date-fns/format';
import { validDate } from 'utils/helper';
import { dateFormat } from 'utils/constanst/dateConstants';
import groupApi from 'utils/api/admin/groupApi';
import customerApi from 'utils/api/admin/customerApi';

import Button from '@material-ui/core/Button';
import FieldEditable from 'components/FieldEditable/FieldEditable';
import ReportProblemOutlined from '@material-ui/icons/ReportProblemOutlined';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Tooltip from '@material-ui/core/Tooltip';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import { isEqual, sortBy } from 'lodash';

import classes from './GroupInfo.module.scss';

class GroupInfo extends PureComponent {
  constructor(props) {
    super(props);
    this.defaultState = {
      codeInput: '',
      nameInput: '',
      descriptionInput: '',
      activeInput: ACTIVE,
      selectedGroupType: null,
      groupTypes: [],

      selectedGroupCustomerType: null,
      groupCustomerTypes: [],
      customerTypes: [],
      selectedCustomerTypeIds: [],

      isConfirmDialogOpen: false,
    }
    this.state = {
      ...this.defaultState,
    }
  }
  componentDidMount() {
    const { isEditing, groupInfo } = this.props;
    if (isEditing && groupInfo && groupInfo.id) {
      this._initData();
      this._prepareFilters();
    }
    if (!isEditing && (!groupInfo || !groupInfo.id)) {
      this._resetData();
      this._prepareNewData();
      this._prepareFilters();
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { groupInfo, isEditing } = this.props;
    if (isEditing
      && groupInfo && groupInfo.id
      && groupInfo !== prevProps.groupInfo
      && groupInfo.id !== prevProps.groupInfo.id
    ) {
      this._initData();
      this._prepareFilters();
    }
    if (isEditing !== prevProps.isEditing && isEditing) {
      this._initData();
      this._prepareFilters();
    }
    if (isEditing !== prevProps.isEditing && !isEditing && (!groupInfo || !groupInfo.id)) {
      this._resetData();
      this._prepareNewData();
      this._prepareFilters();
    }

    const { selectedGroupCustomerType, selectedCustomerTypeIds, selectedGroupType } = this.state;
    if (!isEditing && !isEqual(selectedGroupType, prevState.selectedGroupType)) {
      this.props.saveGroup({
        ...this.props.groupInfo,
        getcare_erp_group_type: { ...selectedGroupType },
      });
      this.setState({
        selectedGroupCustomerType: null,
      });
    }
    if (!isEditing && !isEqual(selectedGroupCustomerType, prevState.selectedGroupCustomerType)) {
      if (!selectedGroupCustomerType || selectedGroupCustomerType.id !== CUSTOMER_BY_CHANNEL_TYPE) {
        this.setState({
          selectedCustomerTypeIds: [],
        });
      }
      if (selectedGroupCustomerType && selectedGroupCustomerType.id === CUSTOMER_BY_CHANNEL_TYPE) {
        this.setState({
          selectedCustomerTypeIds: this.state.customerTypes.map(item => item.id),
        });
      }
      this.props.saveGroup({
        ...this.props.groupInfo,
        getcare_erp_group_type_customer: selectedGroupCustomerType ? {...selectedGroupCustomerType} : null,
      });
    }
    if (!isEditing && !isEqual(sortBy(selectedCustomerTypeIds), sortBy(prevState.selectedCustomerTypeIds))) {
      this.props.saveGroup({
        ...this.props.groupInfo,
        getcare_customer_type_ids: [...selectedCustomerTypeIds],
      });
    }
  }
  _prepareFilters = () => {
    this._getCustomerTypes();
    this._getGroupCustomerTypes();
  }
  _resetData = () => {
    this.setState({
      ...this.defaultState,
    });
  }
  _prepareNewData = () => {
    this._getCodeItems();
    this._getTypes();
  }
  _initData = () => {
    const { groupInfo } = this.props;
    this.setState({
      codeInput: groupInfo.code,
      nameInput: groupInfo.name,
      descriptionInput: groupInfo.description,
      activeInput: groupInfo.active,

      selectedGroupType: { ...groupInfo.getcare_erp_group_type },
      selectedGroupCustomerType: { ...groupInfo.getcare_erp_group_type_customer },
      selectedCustomerTypeIds: groupInfo.customer_types?.map(item => item.id),
    });
  }
  _preprocessInputValue = (value) => {
    return removeAccents(value).toUpperCase();
  }
  _getTypes = async () => {
    const { data: response } = await groupApi.getTypes();
    if (!response.result || !response.data) return;
    this.setState({
      groupTypes: response.data,
    });
  }
  _getGroupCustomerTypes = async () => {
    const { data: response } = await groupApi.getGroupCustomerTypes();
    if (!response.result || !response.data) return;
    this.setState({
      groupCustomerTypes: [...response.data],
    });
  }
  _getCustomerTypes = async () => {
    const { data: response } = await customerApi.getCustomerTypes();
    if (!response.result || !response.data) return;
    this.setState({
      customerTypes: [...response.data],
    });
  }
  _getCodeItems = async () => {
    const { data: response } = await groupApi.getCodeItems();
    if (!response.result || !response.data) return;
    this.props.saveGroup({
      ...this.props.groupInfo,
      code_items: response.data,
    });
  }
  _willShowCustomerType = () => {
    const { selectedGroupCustomerType, selectedGroupType } = this.state;
    return selectedGroupType?.id === SALES_TYPE && (selectedGroupCustomerType && selectedGroupCustomerType.id === CUSTOMER_BY_CHANNEL_TYPE);
  }
  _isCustomerTypeDisabled = (type) => {
    const { selectedCustomerTypeIds } = this.state;
    const { isEditing } = this.props;
    return ((selectedCustomerTypeIds.includes(type.id) && selectedCustomerTypeIds.length === 1) || isEditing);
  }
  handleFieldChange = (e, newValue) => {
    this.setState({
      [e.target.name]: newValue,
    });
    if (!this.props.isEditing) {
      this.props.saveGroup({
        ...this.props.groupInfo,
        [e.target.name]: newValue,
      });
    }
  }
  handleBlurCodeField = (e, newValue) => {
    const transformedValue = this._preprocessInputValue(newValue);

    this.handleFieldChange({ target: { name: 'code' } }, transformedValue);
    this.handleSaveField({code: transformedValue});
  }
  handleActiveGroup = () => {
    this.props.updateGroup({
      id: this.props.groupInfo.id,
      params: { active: ACTIVE },
    });
  }
  handleInactiveGroup = () => {
    this.props.updateGroup({
      id: this.props.groupInfo.id,
      params: { active: INACTIVE },
    });
    if (this.state.isConfirmDialogOpen) {
      this.handleConfirmDialogClose();
    }
  }
  handleSaveField = (fieldMap) => {
    if (this.props.isEditing) {
      this.props.updateGroup({
        id: this.props.groupInfo.id,
        params: { ...fieldMap, code_items: this.props.groupInfo.code_items },
      });
      return;
    }
  }
  handleTypeChange = (e, newValue) => {
    if (!newValue || !newValue.id) return;
    this.setState({
      selectedGroupType: { ...newValue },
    });
  }
  handleGroupCustomerTypeChange = (e, newValue) => {
    if (!newValue || !newValue.id) return;
    this.setState({
      selectedGroupCustomerType: { ...newValue },
    });
  }
  handleCustomerTypeChange = (e) => {
    const value = Number(e.target.value);
    let newArr = [];
    if (e.target.checked) {
      newArr = [...this.state.selectedCustomerTypeIds, value];
    }
    if (!e.target.checked) {
      newArr = this.state.selectedCustomerTypeIds.filter(s => s !== value);
    }
    this.setState({
      selectedCustomerTypeIds: [...newArr],
    });
  }
  handleConfirmDialogOpen = () => {
    this.setState({
      isConfirmDialogOpen: true,
    });
  }
  handleConfirmDialogClose = () => {
    this.setState({
      isConfirmDialogOpen: false,
    });
  }

  render() {
    const {
      selectedGroupType,
      groupTypes,
      selectedGroupCustomerType,
      groupCustomerTypes,
      customerTypes,
      selectedCustomerTypeIds,
    } = this.state;
    const { groupInfo, isEditing, isInactive, isGroupEmpty } = this.props;

    return (<>
      <div className={`${classes.Details} ${isEditing ? classes.Editing : ''}`}>
        <div className={classes.FieldControl}>
          <label>Mã nhóm <span className={classes.RequiredMark}>*</span></label>
          { isEditing ? (<div className={classes.GroupCode}>
            { groupInfo?.code }
            { isGroupEmpty && (
              <Tooltip title={`Nhóm đang trống`} arrow placement="bottom">
                <span className={classes.WarningIcon}><ReportProblemOutlined fontSize="small"/></span>
              </Tooltip>
            )}
          </div>) : (<FieldEditable
            editModeOnly
            fieldName="code"
            maxLength={20}
            error={!this.props.isCodeValid}
            value={groupInfo?.code || ''}
            onSave={this.handleSaveField}
            onChange={this.handleFieldChange}
            onBlur={this.handleBlurCodeField}
          />) }
        </div>
        <div className={classes.FieldControl}>
          <label className={classes.Flex}>
            Tên nhóm
            <span className={classes.RequiredMark}>*</span>
          </label>
          { isInactive ? groupInfo?.name : (
              <FieldEditable
                editModeOnly={!isEditing}
                fieldName="name"
                maxLength={55}
                error={!this.props.isNameValid}
                value={groupInfo?.name || ''}
                onSave={this.handleSaveField}
                onChange={this.handleFieldChange}
              />
          )}
        </div>

        <div className={classes.FieldControl}>
          <label>Mô tả</label>
          { isInactive ? groupInfo?.description : (
              <FieldEditable
                error={!this.props.isDescriptionValid}
                editModeOnly={!isEditing}
                maxLength={255}
                fieldName="description"
                value={groupInfo?.description || ''}
                onSave={this.handleSaveField}
                onChange={this.handleFieldChange}
              />
          )}
        </div>
        <div className={`${classes.FieldControl} ${classes.GroupTypeWrap}`}>
          <div className={`${classes.FieldControl}`}>
            <label>Loại nhóm</label>
            { (isEditing || isInactive) ? selectedGroupType?.name : (
              <div className={`${classes.EditInlineCol}`}>
                <Autocomplete
                  openOnFocus
                  disableClearable
                  handleHomeEndKeys={false}
                  value={selectedGroupType || null}
                  options={groupTypes ? groupTypes : []}
                  getOptionLabel={(option) => option?.name || ''}
                  getOptionSelected={(option, value) => option.id === value.id}
                  renderInput={(params) => <TextField error={!this.props.isTypeValid} {...params} />}
                  onChange={this.handleTypeChange}
                />
              </div>
            )}
          </div>
          { selectedGroupType?.id === SALES_TYPE ? (
            <div className={classes.FieldControl}>
              <label className={classes.Auto}>theo</label>
              { (isEditing || isInactive) ? selectedGroupCustomerType?.name : (
                <div className={`${classes.EditInlineCol}`}>
                  <Autocomplete
                    openOnFocus
                    disableClearable
                    handleHomeEndKeys={false}
                    value={selectedGroupCustomerType || null}
                    options={groupCustomerTypes ? groupCustomerTypes : []}
                    getOptionLabel={(option) => option?.name || ''}
                    getOptionSelected={(option, value) => option.id === value.id}
                    renderInput={(params) => <TextField error={!this.props.isTypeValid} {...params} placeholder="- Chọn loại khách hàng -" />}
                    onChange={this.handleGroupCustomerTypeChange}
                  />
                </div>
              )}
            </div>
          ) : '' }
        </div>

        { isEditing && (<>
            <div className={classes.FieldControl}>
              <label>Ngày tạo</label>
              { validDate(groupInfo?.created_at) ? format(validDate(groupInfo?.created_at), dateFormat) : '' }
            </div>
            <div className={classes.FieldControl}>
              <label>Ngày sửa</label>
              { validDate(groupInfo?.updated_at) ? format(validDate(groupInfo?.updated_at), dateFormat) : '' }
            </div>

            { groupInfo?.active === ACTIVE ? (<Button
                variant="outlined"
                size="small"
                color="secondary"
                onClick={this.handleConfirmDialogOpen}
              >Inactive</Button>) : (<>
              <Button
                variant="outlined"
                size="small"
                color="primary"
                onClick={this.handleActiveGroup}
              >Active</Button>
              <div className={classes.FieldControl}>
                <label>Ngày ngừng hoạt động</label>
                { validDate(groupInfo?.deactive_date) ? format(validDate(groupInfo?.deactive_date), dateFormat) : '' }
              </div>
            </>) }
          </>)
        }

        { this._willShowCustomerType() &&
          (<div className={`${classes.FieldControl} ${classes.CustomerTypeWrap}`}>
            <label>Loại khách hàng</label>
            <FormGroup className={classes.FormGroup}>
              { customerTypes && customerTypes.map(type => (
                <FormControlLabel
                  key={`customer-type-${type.id}`}
                  control={<Checkbox size="small" checked={selectedCustomerTypeIds.includes(type.id)} onChange={this.handleCustomerTypeChange} value={type.id} name="getcare_customer_type_ids" />}
                  label={type.name}
                  disabled={this._isCustomerTypeDisabled(type)}
                />
              )) }
            </FormGroup>
          </div>)
        }

      </div>

      { this.state.isConfirmDialogOpen
        && <ConfirmationDialog
          isOpen={this.state.isConfirmDialogOpen}
          title="Xác nhận dừng hoạt động"
          message={(<p>{`Bạn có chắc dừng hoạt động nhóm `} <strong>{this.props.groupInfo.name}</strong>?</p>)}
          onClose={this.handleConfirmDialogClose}
          onSubmit={this.handleInactiveGroup}
        />
      }
    </>);
  }
}

GroupInfo.propTypes = {
  isEditing: PropTypes.bool,
  isNameValid: PropTypes.bool,
  isCodeValid: PropTypes.bool,
  isTypeValid: PropTypes.bool,
  isInactive: PropTypes.bool,
  isDescriptionValid: PropTypes.bool,
  groupInfo: PropTypes.object,
  isGroupEmpty: PropTypes.bool,
};

GroupInfo.defaultProps = {
  isEditing: true,
  isNameValid: true,
  isCodeValid: true,
  isInactive: false,
  groupInfo: null,
  isGroupEmpty: false,
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateGroup: (payload) => dispatch(updateGroup(payload)),
    saveGroup: (payload) => dispatch(saveGroup(payload)),
  };
};
const withConnect = connect(null, mapDispatchToProps);
export default compose(withConnect, withRouter)(GroupInfo);
