import productApi from 'utils/api/admin/productApi';
import { put } from 'redux-saga/effects';
import { adminProductActions } from 'redux/actions';
import { toast } from 'react-toastify';

export function* loadProductDetails(payload) {
  try {
    yield put(adminProductActions.saveProduct(null));

    const { data: response } = yield productApi.get(payload.id);

    if (!response?.result) {
      yield put(adminProductActions.saveProduct({}));
      // todo: error notification
      return;
    }
    yield put(
      adminProductActions.saveProduct(response.data ? { ...response.data } : {})
    );
  } catch (err) {
    console.log(err);
  }
}

export function* loadProductList(payload) {
  const { params } = payload.payload;
  try {
    yield put(adminProductActions.saveLoading(true));

    const { data: response } = yield productApi.getAll({ params });

    if (!response?.result) {
      yield put(adminProductActions.saveLoading(false));
      return;
    }

    yield put(
      adminProductActions.saveProductList(
        response.data ? [...response.data] : []
      )
    );
    yield put(adminProductActions.saveTotal(response.total_records));
    yield put(adminProductActions.saveLoading(false));
  } catch (err) {
    console.log(err);
  }
}

export function* deactiveProducts(payload) {
  const { ids } = payload.payload;
  try {
    yield put(adminProductActions.saveLoading(true));

    const { data: response } = yield productApi.deactiveProducts(ids);

    if (!response?.result) {
      yield put(adminProductActions.saveLoading(false));
      return;
    }
    yield put(adminProductActions.removeProducts(ids));
    yield put(adminProductActions.saveLoading(false));
  } catch (err) {
    console.log(err);
  }
}

export function* loadMedicines(payload) {
  const { params, pharmacyId } = payload.payload;
  try {
    yield put(adminProductActions.saveMedicines(null));

    const { data: response } = yield productApi.getMedicinesByPharmacyId({
      params,
      pharmacyId,
    });

    if (!response?.result) {
      yield put(adminProductActions.saveMedicines([]));
      yield put(adminProductActions.saveMedicinesTotal(0));
      // todo: error notification
      return;
    }
    yield put(
      adminProductActions.saveMedicines(response.data ? [...response.data] : [])
    );
    yield put(adminProductActions.saveMedicinesTotal(response.total_records));
  } catch (err) {
    console.log(err);
  }
}

export function* loadOtherProducts(payload) {
  const { params, pharmacyId } = payload.payload;
  try {
    yield put(adminProductActions.saveOtherProducts(null));

    const { data: response } = yield productApi.getOtherProductsByPharmacyId({
      params,
      pharmacyId,
    });

    if (!response?.result) {
      yield put(adminProductActions.saveOtherProducts([]));
      yield put(adminProductActions.saveOtherProductsTotal(0));
      // todo: error notification
      return;
    }
    yield put(
      adminProductActions.saveOtherProducts(
        response.data ? [...response.data] : []
      )
    );
    yield put(
      adminProductActions.saveOtherProductsTotal(response.total_records)
    );
  } catch (err) {
    console.log(err);
  }
}

export function* loadAwaitingProducts(payload) {
  const { params, pharmacyId } = payload.payload;
  try {
    yield put(adminProductActions.saveAwaitingProducts(null));

    const {
      data: response,
    } = yield productApi.getAwaitingProductsByPharmacyId({
      params,
      pharmacyId,
    });

    if (!response?.result) {
      yield put(adminProductActions.saveAwaitingProducts([]));
      yield put(adminProductActions.saveAwaitingProductsTotal(0));
      return;
    }
    yield put(
      adminProductActions.saveAwaitingProducts(
        response.data ? [...response.data] : []
      )
    );
    yield put(
      adminProductActions.saveAwaitingProductsTotal(response.total_records)
    );
  } catch (err) {
    console.log(err);
  }
}
export function* putAwaitingProduct(payload) {
  const { id, params } = payload.payload;
  try {
    const { data: response } = yield productApi.updateAwaitingProduct({
      id,
      params,
    });
    if (!response?.result) {
      yield put(adminProductActions.saveAwaitingProduct({}));
      return;
    }
    yield put(
      adminProductActions.saveAwaitingProduct(
        response.data ? { ...response.data } : {}
      )
    );
  } catch (err) {
    console.log(err);
  }
}

export function* loadBrands(payload) {
  const { params } = payload.payload;
  try {
    const { data: response } = yield productApi.getProductBrands({ params });

    if (!response?.result) {
      yield put(adminProductActions.saveProductBrands([]));
      return;
    }
    yield put(
      adminProductActions.saveProductBrands(
        response.data ? [...response.data] : []
      )
    );
  } catch (err) {
    console.log(err);
  }
}

export function* loadIndustries(payload) {
  const { params } = payload.payload;
  try {
    const { data: response } = yield productApi.getProductIndustries({
      params,
    });

    if (!response?.result) {
      yield put(adminProductActions.saveProductIndustries([]));
      return;
    }
    yield put(
      adminProductActions.saveProductIndustries(
        response.data ? [...response.data] : []
      )
    );
  } catch (err) {
    console.log(err);
  }
}

export function* exportProductListTemplate(payload) {
  try {
    yield put(adminProductActions.saveLoading(true));

    const { data: response } = yield productApi.exportProductsTemplate();
    if (!response?.result) {
      toast.error(response.message);
      return;
    }

    const anchor = document.createElement('a');
    anchor.setAttribute('href', `${response.data ? response.data : ''}`);
    anchor.click();
    anchor.remove();

    yield put(adminProductActions.saveLoading(false));
  } catch (err) {
    console.log(err);
  }
}
