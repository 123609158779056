import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import { validDate, currencyFormatVN } from 'utils/helper';
import { dateFormat } from 'utils/constanst/dateConstants';
import { operationsMap, POLICY_PRICE_PERCENT_UNIT_NAME } from 'utils/constanst/adminPolicyPriceContants';

import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';

import classes from './Policies.module.scss';

class Policies extends PureComponent {
  _getAmountStr = (row) => {
    return row.policy_unit_name === POLICY_PRICE_PERCENT_UNIT_NAME
    ? `${row.amount}${row.policy_unit_name}`
    : currencyFormatVN(row.amount);
  }

  render() {
    const { list } = this.props;

    return (<div className={`${classes.List}`}>
      <div className={`${classes.Row} ${classes.Header}`}>
        <ListHeaderCol className={classes.Col} label="Đang áp dụng"/>
        <ListHeaderCol className={classes.Col} label="Mô tả"/>
        <ListHeaderCol className={classes.Col} label="Ưu tiên"/>
        <ListHeaderCol className={classes.Col} label="Ngày bắt đầu"/>
        <ListHeaderCol className={classes.Col} label="Ngày kết thúc"/>
        <ListHeaderCol className={classes.Col} label="Giá bán base"/>
        <ListHeaderCol className={classes.Col} label={'\u254B \u2500'}/>
        <ListHeaderCol className={classes.Col} label="Giá bán mới"/>
      </div>
      <div className={`${classes.Body}`}>
        { list.length ? list.map(row => (
          <div key={`price-${row.id}`} className={`${classes.Row} ${classes.Item}`}>
            <div className={classes.Col}>{ row.code }</div>
            <div className={classes.Col}>{ row.description }</div>
            <div className={classes.Col}>{ row.priority_name }</div>
            <div className={classes.Col}>{ validDate(row.start_date) ? format(validDate(row.start_date), dateFormat) : '' }</div>
            <div className={classes.Col}>{ validDate(row.end_date) ? format(validDate(row.end_date), dateFormat) : '' }</div>
            <div className={classes.Col}>{ !['', null, undefined].includes(row.price_sales) ? currencyFormatVN(row.price_sales) : '' }</div>
            <div className={classes.Col}><span className={classes.Operation}>{operationsMap[row.operation]}</span>{` ${this._getAmountStr(row)}`}</div>
            <div className={classes.Col}>{ !['', null, undefined].includes(row.price_sales_policy) ? currencyFormatVN(row.price_sales_policy) : '' }</div>
          </div>
        )) : <p className="NoData">Không có chính sách nào</p>}
      </div>
    </div>);
  }
}

Policies.propTypes = {
  list: PropTypes.array,
};

Policies.defaultProps = {
  list: [],
};

export default Policies;
