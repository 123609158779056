import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import coordinaterApi from 'utils/api/admin/coordinaterApi';
import { suggestionPageSize2 } from 'utils/constanst/common';

import EditOutlined from '@material-ui/icons/EditOutlined';
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import IconButton from '@material-ui/core/IconButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import DeleteOutline from '@material-ui/icons/DeleteOutline';

import gridClasses from '../SalesChannelCoordinatersGrid.module.scss';
import classes from './SalesChannelCoordinaterItem.module.scss';

class SalesChannelCoordinaterItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: !props.id,

      params: this._getInitParams(props),
      userOptions: props.getcare_sales ? [{...props.getcare_sales}] : [],
      userNameInput: '',
      userCodeInput: '',
    }
  }
  componentDidMount() {
    const { isEditing } = this.state;
    if (isEditing) {
      this._loadCoordinaterLeaders({
        code: '',
        name: '',
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { isEditing, userCodeInput, userNameInput } = this.state;
    if (isEditing && isEditing !== prevState.isEditing) {
      this._loadCoordinaterLeaders({
        code: '',
        name: '',
      });
    }
    if (userCodeInput !== prevState.userCodeInput) {
      this._loadCoordinaterLeaders({
        code: userCodeInput,
      });
    }
    if (userNameInput !== prevState.userNameInput) {
      this._loadCoordinaterLeaders({
        name: userNameInput,
      });
    }
  }

  _loadCoordinaterLeaders = async (params) => {
    const { data: response } = await coordinaterApi.getList({
      params: {
        ...params,
        page_size: suggestionPageSize2,
        is_leader: 1,
      }
    });
    if (!response?.result) return;

    const fullArr = response.data ? response.data.map(item => ({ 
      ...item.getcare_user,
      id: item.id,
    })) : [];

    this.setState({
      userOptions: this._filteredUserOptions(fullArr),
    });
  }
  _getInitParams = (params) => {
    return {
      idStr: params.idStr,
      id: params.id,
      getcare_sales: { 
        ...params.getcare_sales,
        id: params.getcare_sales?.id,
      },
    }
  }
  _resetState = () => {
    this.setState({
      isEditing: false,
      params: this._getInitParams(this.props),
    });
  }
  _isUserValid = () => {
    return this.state.params?.getcare_sales?.id || this.state.params?.getcare_sales?.code;
  }
  _filteredUserOptions = (fullArr) => {
    return fullArr.filter(user =>
      (user.code === this.props.getcare_sales?.code) ||
      !this.props.currentList.some(item => item.getcare_sales?.code === user.code)
    );
  }
  _isRowValid = () => {
    return this._isUserValid();
  };
  stopPropagation = (e) => {
    e.stopPropagation();
  }
  handleSaveField = (e, value) => {
    let newValue = value;
    if (e.target.name === 'getcare_sales') {
      newValue = { ...value };
    }
    this.setState({
      params: {
        ...this.state.params,
        [e.target.name]: newValue,
      },
    });
  }
  handleEdit = (e) => {
    e.stopPropagation();
    this.setState({
      isEditing: true,
    });
  }
  handleCancel = (e) => {
    e.stopPropagation();
    this._resetState();
    if (!this.props.id) {
      this.props.removeCoordinater({
        id: this.props.id,
        idStr: this.props.idStr,
      });
    }
  }
  handleSave = (e) => {
    if (e) e.stopPropagation();
    this.props.saveCoordinater({
      ...this.state.params,
      idStr: this.props.idStr,
      id: this.props.id,
    });
    this.setState({
      isEditing: false,
    });
  }
  handleRowBlur = () => {
    if (this.state.isEditing && this._isRowValid()) {
      this.handleSave();
    }
  }
  handleDelete = () => {
    this.props.removeCoordinater({
      idStr: this.props.idStr,
      id: this.props.id,
    });
  };

  render() {
    const { isEditing, params, userOptions } = this.state;
    const { isAssignAllowed } = this.props;

    return (<>
      <ClickAwayListener onClickAway={this.handleRowBlur}>
        <div
          className={`${classes.Item} ${gridClasses.Row} ${isAssignAllowed ? gridClasses.RowHasActions : ''}`}
          onClick={isAssignAllowed ? this.handleEdit : undefined}
        >
          <div className={`${gridClasses.Col}`}>
            {isEditing ? (
              <Autocomplete
                openOnFocus
                selectOnFocus
                disableClearable
                fullWidth
                size="small"
                handleHomeEndKeys={false}
                value={params.getcare_sales || null}
                onChange={(e, newValue) => {
                  this.handleSaveField({target: { name: 'getcare_sales' } }, newValue);
                }}
                options={userOptions || []}
                filterOptions={(x) => x}
                renderOption={(option) => `${option.code} - ${option.name}`}
                getOptionLabel={(option) => option?.code ? `${option.code}` : ''}
                getOptionSelected={(option, value) =>
                  value?.code && option?.code ? option.code === value.code : null
                }
                onInputChange={(e, newInputValue) => {
                  this.setState({
                    userCodeInput: newInputValue,
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} error={!this._isUserValid()} placeholder="- Chọn -" />
                )}
              />
            ) : (
              params.getcare_sales?.code
            )}
          </div>
          <div className={`${gridClasses.Col}`}>
            {isEditing ? (
              <Autocomplete
                openOnFocus
                selectOnFocus
                disableClearable
                size="small"
                fullWidth
                handleHomeEndKeys={false}
                value={params.getcare_sales || null}
                onChange={(e, newValue) => {
                  this.handleSaveField({target: { name: 'getcare_sales' } }, newValue);
                }}
                options={userOptions || []}
                filterOptions={(x) => x}
                renderOption={(option) => `${option.code} - ${option.name}`}
                getOptionLabel={(option) => option?.code ? `${option?.name}` : ''}
                getOptionSelected={(option, value) =>
                  value?.code && option?.code ? option.code === value.code : null
                }
                onInputChange={(e, newInputValue) => {
                  this.setState({
                    userNameInput: newInputValue,
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} error={!this._isUserValid()} placeholder="- Chọn -" />
                )}
              />
            ) : (
              params.getcare_sales?.name
            )}
          </div>
          <div className={`${gridClasses.Col}`}>
            { params.getcare_sales?.phone }
          </div>
          { isAssignAllowed && 
            <div className={`${gridClasses.Col} ${classes.ActionsCol}`}>
              { isEditing ? <>
                <IconButton
                  onClick={this.handleCancel}
                  style={{ padding: '0' }}
                  size="small"
                >
                  <CancelOutlined fontSize="small" color="secondary" />
                </IconButton>
                <IconButton
                  disabled={!this._isRowValid()}
                  onClick={this.handleSave}
                  style={{ padding: '0' }}
                  size="small"
                >
                  <CheckCircleOutlineOutlined
                    fontSize="small"
                    color={this._isRowValid() ? `primary` : `inherit`}
                  />
                </IconButton>
              </> : <>
                <IconButton
                  onClick={this.handleDelete}
                  style={{ padding: '0' }}
                  size="small"
                >
                  <DeleteOutline fontSize="small" />
                </IconButton>
                <IconButton
                  size="small"
                  color="primary"
                  onClick={this.handleEdit}
                >
                  <EditOutlined fontSize="small"/>
                </IconButton>
              </>
              }
            </div>
          }
        </div>
      </ClickAwayListener>
    </>);
  }
}

SalesChannelCoordinaterItem.propTypes = {
  onSaveLocationItem: PropTypes.func,
  onRemoveLocationItems: PropTypes.func,
};

SalesChannelCoordinaterItem.defaultProps = {
};

export default SalesChannelCoordinaterItem;
