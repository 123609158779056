import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from "reselect";
import { makeSelectGroupCustomerMappings } from 'redux/selectors';
import {
  updateGroupCustomerMapping,
  saveGroupCustomerMapping,
  removeGroupCustomerMappings,
  deleteGroupCustomerMappings,
  saveGroupCustomerMappings,
  getGroupCustomerMappings,
  getGroupCustomersByMappings,
} from 'redux/actions/admin/groupActions';
import { getDisplayFields, genID } from 'utils/helper';
import {
  groupCustomerMappingsDisplayFields,
  groupCustomerMappingsParamsMap,
  groupCustomersParamsMap,
} from 'utils/constanst/adminGroupConstants';
import { isEqual, sortBy } from 'lodash';

import GroupCustomerMappingsItem from './GroupCustomerMappingsItem/GroupCustomerMappingsItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';
import Button from '@material-ui/core/Button';

import gridClasses from './GroupCustomerMappingsGrid.module.scss';
import classes from './GroupCustomerMappings.module.scss';

class GroupCustomerMappings extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      listParams: { ...groupCustomerMappingsParamsMap },
    }
  }
  componentDidMount() {
    this.loadCustomerMappings({...groupCustomerMappingsParamsMap});
  }
  componentDidUpdate(prevProps) {
    const { groupCustomerMappings, customerTypeIds, selectedItems, groupId, isEditing } = this.props;
    if (!isEqual(sortBy(groupCustomerMappings), sortBy(prevProps.groupCustomerMappings))) {
      if (selectedItems.length > 0) {
        const remainItems = selectedItems.filter(p => groupCustomerMappings.some(item => {
          const comparedField = item.idStr ? `idStr` : `id`;
          return item[comparedField] === p[comparedField];
        }));
        this.props.onSelectedItemsChange(remainItems);
      }
      this.loadCustomersByMappings({...groupCustomersParamsMap});
    }
    if (
      !isEqual(sortBy(customerTypeIds), sortBy(prevProps.customerTypeIds))
      || (isEditing && groupId && prevProps.groupId && groupId !== prevProps.groupId)
    ) {
      this.loadCustomerMappings(this.state.listParams);
    }
  }
  loadCustomerMappings = (listParams) => {
    this.props.getGroupCustomerMappings({
      params: {
        ...listParams,
        getcare_erp_group_code_items: this.props.groupCodeItems,
        getcare_erp_group_id: this.props.groupId,
        getcare_customer_type_id: this.props.customerTypeIds,
      },
    });
  }
  loadCustomersByMappings = (listParams) => {
    this.props.getGroupCustomersByMappings({
      params: {
        ...listParams,
        getcare_erp_group_code_items: this.props.groupCodeItems,
        getcare_erp_group_id: this.props.groupId,
        getcare_customer_type_id: this.props.customerTypeIds,
      },
    });
  }
  isAllItemSelected = () => {
    const { groupCustomerMappings, selectedItems } = this.props;
    return selectedItems.length > 0 && selectedItems.length >= groupCustomerMappings.length;
  }
  isItemSelected = (item) => {
    return this.props.selectedItems.findIndex(p => {
      const comparedField = p.idStr ? 'idStr' : 'id';
      return p[comparedField] === item[comparedField];
    }) > -1;
  }
  handleAllSelectedToggle = (e) => {
    this.props.onSelectedItemsChange(e.target.checked ? [...this.props.groupCustomerMappings] : []);
  }
  handleItemSelectedToggle = ({ item, isSelected }) => {
    const remainItems = this.props.selectedItems.filter(p => {
      const comparedField = p.idStr ? 'idStr' : 'id';
      return p[comparedField] !== item[comparedField];
    });
    this.props.onSelectedItemsChange(isSelected ? [...remainItems, {...item}] : remainItems);
  }
  handleCreateNewRow = () => {
    this.props.saveGroupCustomerMapping({
      idStr: genID(),
      id: 0,
      sales_channel: null,
      count: 0,
    });
  }
  handleSaveGroupCustomerMapping = ({ groupCustomerMappingId, params }) => {
    this.props.updateGroupCustomerMapping({
      groupCustomerMappingId: groupCustomerMappingId ? groupCustomerMappingId : undefined,
      params: {
        ...params,
        code: groupCustomerMappingId ? undefined : this.props.groupCodeItems,
        getcare_erp_group_id: this.props.groupId ? this.props.groupId : undefined,
      },
      queryParams: {
        getcare_customer_type_id: [...this.props.customerTypeIds],
      },
    });
  }
  handleRemoveGroupCustomerMapping = (params) => {
    this.props.removeGroupCustomerMappings([{...params}]);
  }

  render() {
    const { groupCustomerMappings, isGroupInactive } = this.props;
    const displayFields = getDisplayFields(
      groupCustomerMappingsParamsMap,
      groupCustomerMappingsDisplayFields
    );

    return (<div className={`${classes.Wrap}`}>
      <div className={`${classes.List}`}>
        <div className={`${classes.Body}`}>
          <div className={`${gridClasses.Row} ${classes.Header}`}>
            <div className={gridClasses.Col}>
              <Checkbox
                disabled={isGroupInactive}
                className={classes.Checkbox}
                checked={this.isAllItemSelected()}
                onChange={this.handleAllSelectedToggle}
              />
            </div>
            { displayFields.map((item) => (
              <ListHeaderCol
                key={item.name}
                className={gridClasses.Col}
                {...item}
              />
            )) }
            <div className={`${gridClasses.Col} ${classes.ActionsCol}`}>
              <Button
                disabled={isGroupInactive}
                color="primary"
                variant="contained"
                size="small"
                onClick={this.handleCreateNewRow}
              >
                Thêm dòng
              </Button>
            </div>
          </div>
        
          { groupCustomerMappings.length
            ? groupCustomerMappings.map(item => (
              <GroupCustomerMappingsItem
                key={`customer-${item.id}-${item.idStr}`}
                {...item}
                isGroupInactive={isGroupInactive}
                isSelected={this.isItemSelected(item)}
                currentList={[...groupCustomerMappings]}
                onItemSelectedToggle={this.handleItemSelectedToggle}
                saveGroupCustomerMapping={this.handleSaveGroupCustomerMapping}
                removeGroupCustomerMapping={this.handleRemoveGroupCustomerMapping}
              />))
            : (<p className="NoData">Không có khu vực bán hàng nào</p>)
          }
        </div>
      </div>
    </div>);
  }
}

GroupCustomerMappings.propTypes = {
  groupId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  groupCodeItems: PropTypes.string,
  isGroupInactive: PropTypes.bool,
  customerTypeIds: PropTypes.array,
  onSelectedItemsChange: PropTypes.func,
};

GroupCustomerMappings.defaultProps = {
  isGroupInactive: false,
  customerTypeIds: [],
  groupId: undefined,
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateGroupCustomerMapping: (payload) => dispatch(updateGroupCustomerMapping(payload)),
    saveGroupCustomerMapping: (payload) => dispatch(saveGroupCustomerMapping(payload)),
    removeGroupCustomerMappings: (payload) => dispatch(removeGroupCustomerMappings(payload)),
    deleteGroupCustomerMappings: (payload) => dispatch(deleteGroupCustomerMappings(payload)),
    saveGroupCustomerMappings: (payload) => dispatch(saveGroupCustomerMappings(payload)),
    getGroupCustomerMappings: (payload) => dispatch(getGroupCustomerMappings(payload)),
    getGroupCustomersByMappings: (payload) => dispatch(getGroupCustomersByMappings(payload)),
  };
};
const mapStateToProps = createStructuredSelector({
  groupCustomerMappings: makeSelectGroupCustomerMappings(),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(GroupCustomerMappings);
