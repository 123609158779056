import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from "reselect";
import { makeSelectGroupCustomers, makeSelectGroupCustomersTotal } from 'redux/selectors';
import { getGroupCustomersByMappings } from 'redux/actions/admin/groupActions';
import { getDisplayFields } from 'utils/helper';
import {
  groupCustomersDisplayFields,
  groupCustomersParamsMap,
} from 'utils/constanst/adminGroupConstants';
import { isEqual, sortBy, debounce } from 'lodash';

import GroupMappingCustomersItem from './GroupMappingCustomersItem/GroupMappingCustomersItem';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';
import ListPagination from 'components/ListPagination/ListPagination';

import gridClasses from './GroupMappingCustomersGrid.module.scss';
import classes from './GroupMappingCustomers.module.scss';

class GroupMappingCustomers extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      listParams: {
        ...groupCustomersParamsMap,
        name: props.query,
      },
    }
  }
  componentDidUpdate(prevProps) {
    const { customerTypeIds, query } = this.props;
    if (!isEqual(sortBy(customerTypeIds), sortBy(prevProps.customerTypeIds))) {
      const newParams = {
        ...groupCustomersParamsMap,
        name: this.props.query,
      };
      this.setState({
        listParams: newParams,
      });
      this.loadCustomersByMappings(newParams);
    }
    if (query !== prevProps.query) {
      this.setState({
        listParams: {
          ...groupCustomersParamsMap,
          name: query,
        },
      })
      this.loadCustomersByMappingsWithQuery();
    }
  }
  loadCustomersByMappingsWithQuery = debounce(() => {
    this.loadCustomersByMappings({
      ...groupCustomersParamsMap,
      name: this.props.query,
    });
  }, 500)
  loadCustomersByMappings = (listParams) => {
    this.props.getGroupCustomersByMappings({
      params: {
        ...listParams,
        getcare_erp_group_code_items: this.props.groupCodeItems,
        getcare_erp_group_id: this.props.groupId,
        getcare_customer_type_id: this.props.customerTypeIds,
      },
    });
  }
  handleFilterChange = (filters, forceResetPage: false) => {
    const paramsFromFilters = filters.reduce((memo, item) => {
      memo[item.name] = item.value;
      return memo;
    }, {});
    const newParams = forceResetPage ? {
      ...this.state.listParams,
      ...paramsFromFilters,
      page: 1,
    } : {
      ...this.state.listParams,
      ...paramsFromFilters,
    };
    this.setState({
      listParams: newParams,
    });
    this.loadCustomersByMappings(newParams);
  }

  render() {
    const { groupCustomers, isGroupInactive, groupCustomersTotal } = this.props;
    const { listParams } = this.state;
    const displayFields = getDisplayFields(
      groupCustomersParamsMap,
      groupCustomersDisplayFields
    );

    return (<div className={`${classes.Wrap}`}>
      <div className={`${classes.List}`}>
        <div className={`${classes.Body}`}>
          <div className={`${gridClasses.Row} ${classes.Header}`}>
            { displayFields.map((item) => (
              <ListHeaderCol
                key={item.name}
                className={gridClasses.Col}
                {...item}
              />
            )) }
          </div>
        
          { groupCustomers.length
            ? groupCustomers.map(item => (
              <GroupMappingCustomersItem
                key={`customer-${item.id}-${item.idStr}`}
                {...item}
                isGroupInactive={isGroupInactive}
              />))
            : (<p className="NoData">Không có khách hàng nào</p>)
          }
        </div>
      </div>
      <div className={classes.Footer}>
        <ListPagination
          page={listParams.page}
          pageSize={listParams.page_size}
          listName="khách hàng"
          total={groupCustomersTotal}
          onFilterChange={this.handleFilterChange}
        />
      </div>
    </div>);
  }
}

GroupMappingCustomers.propTypes = {
  groupId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  groupCodeItems: PropTypes.string,
  isGroupInactive: PropTypes.bool,
  customerTypeIds: PropTypes.array,
  query: PropTypes.string,
};

GroupMappingCustomers.defaultProps = {
  isGroupInactive: false,
  customerTypeIds: [],
  query: '',
};

const mapDispatchToProps = (dispatch) => {
  return {
    getGroupCustomersByMappings: (payload) => dispatch(getGroupCustomersByMappings(payload)),
  };
};
const mapStateToProps = createStructuredSelector({
  groupCustomers: makeSelectGroupCustomers(),
  groupCustomersTotal: makeSelectGroupCustomersTotal(),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(GroupMappingCustomers);
