export const APPROVE = 1;
export const UNAPPROVE = -1;
export const listDisplayFields = [
  {
    name: 'id',
    label: 'ID',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'l6_name',
    label: 'Tên hoạt chất',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'l0_code',
    label: 'l0 code',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'l0_name',
    label: 'l0 name',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'l1_code',
    label: 'l1 code',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'l1_name',
    label: 'l1 name',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'l2_code',
    label: 'l2 code',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'l2_name',
    label: 'l2 name',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'l3_code',
    label: 'l3 code',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'l3_name',
    label: 'l3 name',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'l4_code',
    label: 'l4 code',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'l4_name',
    label: 'l4 name',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'l5_code',
    label: 'l5 code',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'l5_name',
    label: 'l5 name',
    sortable: true,
    sortDir: '',
  },
];

export const listParamsMap = {
  page_size: 50,
  page: 1,
  order: '',
  id: '',
  l0_code: '',
  l0_name: '',
  l1_code: '',
  l1_name: '',
  l2_code: '',
  l2_name: '',
  l3_code: '',
  l3_name: '',
  l4_code: '',
  l4_name: '',
  l5_code: '',
  l5_name: '',
  l6_name: '',
};

export const approvedListParamsMap = {
  ...listParamsMap,
  approval: APPROVE,
};

export const queueListParamsMap = {
  ...listParamsMap,
  approval: UNAPPROVE,
};

export const filterFields = [
  {
    name: 'id',
    label: 'ID',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'l6_name',
    label: 'Tên hoạt chất',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'l0_name',
    label: 'l0 name',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'l1_name',
    label: 'l1 name',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'l2_name',
    label: 'l2 name',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'l3_name',
    label: 'l3 name',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'l4_name',
    label: 'l4 name',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'l5_name',
    label: 'l5 name',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
];

export const queueListDisplayFields = [
  {
    name: 'l6_name',
    label: 'Tên hoạt chất',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'l0_code',
    label: 'l0 code',
  },
  {
    name: 'l0_name',
    label: 'l0 name',
  },
  {
    name: 'l1_code',
    label: 'l1 code',
  },
  {
    name: 'l1_name',
    label: 'l1 name',
  },
  {
    name: 'l2_name',
    label: 'l2 name',
  },
  {
    name: 'l3_name',
    label: 'l3 name',
  },
  {
    name: 'l4_name',
    label: 'l4 name',
  },
  {
    name: 'l5_name',
    label: 'l5 name',
  },
];
