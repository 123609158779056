import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import ShipperListItem from './ShipperListItem/ShipperListItem';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';

import gridClasses from './ShipperListGrid.module.scss';
import classes from './ShipperList.module.scss';

class ShipperList extends PureComponent {
  render() {
    const { shipperList, isLoading, displayFields } = this.props;

    return (
      <div className={`${classes.List} ${isLoading && 'OverlayLoading'}`}>
        <div
          className={`${classes.Header}`}
          ref={(el) => (this.rowRef = el)}
        >
          <div className={`${gridClasses.Row} ${classes.Header}`}>
            {displayFields.map((item) => (
              <ListHeaderCol
                key={item.name}
                className={gridClasses.Col}
                {...item}
                onSortChange={this.props.onSortChange}

              />
            ))}
          </div>
        </div>

        <div className={`${classes.Body}`}>

          {(shipperList && shipperList.length)
            ? shipperList.map(item => (
              <ShipperListItem
                key={`shipper-${item.id}`}
                {...item}
              />))
            : (<p className="NoData">Không có nhân viên vận chuyển nào</p>)
          }
        </div>
      </div>
    )
  }
}

ShipperList.propTypes = {
  displayFields: PropTypes.array,
  shipperList: PropTypes.array,
};

ShipperList.defaultProps = {
  displayFields: [],
  shipperList: [],
};

export default ShipperList;
