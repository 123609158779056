import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import StoreListItem from './StoreListItem/StoreListItem';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';

import gridClasses from './StoreListGrid.module.scss';
import classes from './StoreList.module.scss';

class StoreList extends PureComponent {
  render() {
    const { storeList, isLoading, displayFields, unapprovedPharmacy, unapprovedDoctor } = this.props;

    let type;
    if (unapprovedPharmacy) {
      type = 'nhà thuốc';
    } else if (unapprovedDoctor) {
      type = 'bác sĩ';
    } else {
      type = 'khách hàng';
    }
    return <div
      className={`${classes.List} ${isLoading && 'OverlayLoading'}`}
      style={{
        overflowX: this.rowRef ? 'scroll' : '',
      }}
    >
      <div
        className={`${classes.Header}`}
        ref={(el) => (this.rowRef = el)}
        style={{width: (this.rowRef?.offsetWidth) || 'auto'}}
      >
        <div className={`${gridClasses.Row} ${classes.Header}`}>
          { displayFields.map((item) => (
            <ListHeaderCol
              key={item.name}
              className={gridClasses.Col}
              {...item}
              onSortChange={this.props.onSortChange}
            />
          )) }
        </div>
      </div>
      <div
        className={`${classes.Body}`}
        style={{width: (this.rowRef?.offsetWidth) || 'auto'}}
      >
        { (storeList && storeList.length)
          ? storeList.map(item => (
            <StoreListItem
              key={`store-${item.id}`}
              {...item}
              unapprovedPharmacy={unapprovedPharmacy}
              unapprovedDoctor={unapprovedDoctor}
            />))
          : (<p className="NoData">Không có {type} nào</p>)
        }
      </div>
    </div>;
  }
}

StoreList.propTypes = {
  displayFields: PropTypes.array,
  storeList: PropTypes.array,
  isLoading: PropTypes.bool,
  onSortChange: PropTypes.func,
};

StoreList.defaultProps = {
  displayFields: [],
  storeList: [],
  isLoading: false,
};

export default StoreList;
