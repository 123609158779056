import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { currencyFormatVN, validDate, numberFormat, validNumber } from 'utils/helper';
import { dateTimeFormat } from 'utils/constanst/dateConstants';
import format from 'date-fns/format';
import { vatsOptions } from 'utils/constanst/common';
import { isEqual } from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectPriceUnits } from 'redux/selectors';
import { Link } from 'react-router-dom';

import FieldEditable from 'components/FieldEditable/FieldEditable';

import gridClasses from '../VendorPriceListGrid.module.scss';
import classes from './VendorPriceListItem.module.scss';

class VendorPriceListItem extends PureComponent {
  constructor(props) {
    super(props);
    // this state's params is just to validate inputted values
    this.state = {
      params: this.getInitParams(props),
    }
  }

  _isPriceBuyValid = (value) => {
    return !['', null, undefined].includes(value) && Number(value) > 0;
  }
  _isPriceSalesValid = (value) => {
    return !['', null, undefined].includes(value) && Number(value) > 0;
  }
  _isPriceSalesRetailValid = (value) => {
    return ['', null, undefined].includes(value) || Number(value) > 0;
  }
  _isMinimumQuantityValid = (value) => {
    return ['', null, undefined].includes(value) || Number(value) >= 0;
  }
  _isEstimatedQuantityValid = (value) => {
    return ['', null, undefined].includes(value) || Number(value) >= 0;
  }

  getVisibleFieldFromName = (columnName) => {
    return this.props.displayFields.find(field => field.name === columnName);
  }
  getInitParams = (properties) => {
    const { changedItem } = properties;
    return {
      price_buy: changedItem?.price_buy !== undefined ? changedItem.price_buy : properties.price_buy,
      price_sales: changedItem?.price_sales !== undefined ? changedItem.price_sales : properties.price_sales,
      price_sales_retail: changedItem?.price_sales_retail !== undefined ? changedItem.price_sales_retail : properties.price_sales_retail,
      minimum_quantity: changedItem?.minimum_quantity !== undefined ? changedItem.minimum_quantity : properties.minimum_quantity,
      estimated_quantity: changedItem?.estimated_quantity !== undefined ? changedItem.estimated_quantity : properties.estimated_quantity,
    }
  }
  isFieldChanged = (fieldName, subObjectName) => {
    const { changedItem } = this.props;
    if (!changedItem) return false;
    if (!subObjectName) return changedItem[fieldName] !== this.props[fieldName];
    return changedItem[subObjectName][fieldName] !== this.props[subObjectName][fieldName];
  }
  getPriceBuyMargin = (currPrice, prevPrice) => {
    return parseFloat(Math.round(Number(currPrice - prevPrice)));
  }
  getPriceSalesMargin = (currPrice, prevPrice) => {
    return parseFloat(Math.round(Number(currPrice - prevPrice)));
  }
  getContrains = (params) => {
    return {
      price_buy: params.price_buy,
      price_sales: params.price_sales,
      price_sales_retail: params.price_sales_retail,
      vat: params.vat,
      amount: params.amount,
      minimum_quantity: params.minimum_quantity,
      estimated_quantity: params.estimated_quantity,
      ka_days: params.ka_days,
    }
  }
  handleSaveField = (fieldMap) => {
    const { changedItem } = this.props;
    const newParams = {
      id: this.props.id,
      getcare_product_id: this.props.product.id,
      getcare_vendor_id: this.props.vendor.id,
      getcare_uom_base_id: this.props.uom_base.id,

      vat: changedItem?.vat !== undefined ? changedItem.vat : this.props.vat,
      price_buy: changedItem?.price_buy !== undefined ? changedItem.price_buy : this.props.price_buy,
      price_sales: changedItem?.price_sales !== undefined ? changedItem.price_sales : this.props.price_sales,
      price_sales_retail: changedItem?.price_sales_retail !== undefined ? changedItem.price_sales_retail : this.props.price_sales_retail,
      amount: changedItem?.amount !== undefined ? changedItem.amount : this.props.amount,
      operation: changedItem?.operation !== undefined ? changedItem.operation : this.props.operation,
      policy_unit: changedItem?.policy_unit !== undefined ? changedItem.policy_unit : this.props.policy_unit,
      minimum_quantity: changedItem?.minimum_quantity !== undefined ? changedItem.minimum_quantity : this.props.minimum_quantity,
      estimated_quantity: changedItem?.estimated_quantity !== undefined ? changedItem.estimated_quantity : this.props.estimated_quantity,
      ka_days: changedItem?.ka_days !== undefined ? changedItem.ka_days : this.props.ka_days,

      ...fieldMap,
    }
    if (!isEqual(this.getContrains(newParams), this.getContrains(this.props))) {
      this.props.onSavePrice({
        ...newParams,
        updated_at_price_buy: !['', null, undefined].includes(fieldMap['price_buy']) ? (new Date()).toISOString() : this.props.updated_at_price_buy,
        updated_at_price_sales: !['', null, undefined].includes(fieldMap['price_sales']) ? (new Date()).toISOString() : this.props.updated_at_price_sales,
      });
      return;
    }
    if (changedItem) {
      this.props.onRemovePrice({
        id: this.props.id,
      });
      return;
    }
  }
  handleCheckboxChange = (e) => {
    const { id } = this.props;

    this.props.onItemSelectedToggle({
      item: {
        id: id,
      },
      isSelected: !this.props.isSelected,
    });
  };
  handleFieldChange = (e, value) => {
    this.setState((state) => ({
      params: {
        ...state.params,
        [e.target.name]: value,
      },
    }));
  }
  handleCancelFields = (fieldMaps) => {
    this.setState((state) => ({
      params: {
        ...state.params,
        ...fieldMaps,
      },
    }));
  }

  render() {
    const {
      product,
      vendor,
      price_buy,
      price_buy_recently,
      price_sales,
      price_sales_retail,
      price_sales_recently,
      updated_at_price_buy,
      updated_at_price_buy_recently,
      updated_at_price_sales,
      updated_at_price_sales_recently,
      uom_base,
      vat,
      minimum_quantity,
      estimated_quantity,
      changedItem,
    } = this.props;
    const { params } = this.state;

    const isPriceBuyChanged = this.isFieldChanged('price_buy', null);
    const isPriceSalesChanged = this.isFieldChanged('price_sales', null);
    const isPriceSalesRetailChanged = this.isFieldChanged('price_sales_retail', null);
    const isVATChanged = this.isFieldChanged('vat', null);
    const isMinimumQuantityChanged = this.isFieldChanged('minimum_quantity', null);
    const isEstimatedQuantityChanged = this.isFieldChanged('estimated_quantity', null);

    const priceBuy = isPriceBuyChanged ? changedItem.price_buy : price_buy;
    const priceSales = isPriceSalesChanged ? changedItem.price_sales : price_sales;
    const priceBuyUpdatedAt = isPriceBuyChanged ? changedItem.updated_at_price_buy : updated_at_price_buy;
    const priceSalesUpdatedAt = isPriceSalesChanged ? changedItem.updated_at_price_sales : updated_at_price_sales;
    const priceBuyMargin = this.getPriceBuyMargin(priceBuy, isPriceBuyChanged ? price_buy : price_buy_recently);
    const priceSalesMargin = this.getPriceSalesMargin(priceSales, isPriceSalesChanged ? price_sales : price_sales_recently);
    const newPriceSalesRetail = isPriceSalesRetailChanged ? changedItem.price_sales_retail : price_sales_retail;
    const newVAT = isVATChanged ? changedItem.vat : vat;
    const newMinimumQuantity = isMinimumQuantityChanged ? changedItem.minimum_quantity : minimum_quantity;
    const newEstimatedQuantity = isEstimatedQuantityChanged ? changedItem.estimated_quantity : estimated_quantity;

    const priceBuyVisible = this.getVisibleFieldFromName('price_buy');
    const priceSalesVisible = this.getVisibleFieldFromName('price_sales');
    const priceSalesRetailVisible = this.getVisibleFieldFromName('price_sales_retail');
    const vatVisible = this.getVisibleFieldFromName('vat');
    const minimumQuantityVisible = this.getVisibleFieldFromName('minimum_quantity');
    const estimatedQuantityVisible = this.getVisibleFieldFromName('estimated_quantity');

    return <div
      className={`${classes.Item} ${gridClasses.Row}`}
      style={{gridTemplateColumns: `repeat(${this.props.displayFields.length}, 9.375rem)`}}
    >
      { this.getVisibleFieldFromName('getcare_product_getcare_id') &&
        <div className={`${gridClasses.Col}`}>
          { product.getcare_id }
        </div>
      }
      { this.getVisibleFieldFromName('getcare_product_name') &&
        <div className={`${gridClasses.Col}`}>
          { product.name }
        </div>
      }
      { this.getVisibleFieldFromName('getcare_product_registration_number') &&
        <div className={`${gridClasses.Col}`}>
          { product.registration_number }
        </div>
      }
      { this.getVisibleFieldFromName('getcare_vendor_name') &&
        <div className={`${gridClasses.Col}`}>
          <Link to={`/vendor/${vendor?.id}`} className="TextSecondary"> { vendor.name }</Link>
        </div>
      }
      { priceBuyVisible &&
        <div className={`${gridClasses.Col} ${classes.EditInlineCol} ${isPriceBuyChanged ? classes.DirtyCol : ''}`}>
          { priceBuyVisible.editable
            ? <FieldEditable
                fieldName="price_buy"
                value={validNumber(priceBuy)}
                displayedValue={currencyFormatVN(priceBuy)}
                type="number"
                onSave={this.handleSaveField}
                onChange={this.handleFieldChange}
                error={!this._isPriceBuyValid(params.price_buy)}
                onCancel={this.handleCancelFields}
              />
            : currencyFormatVN(priceBuy)
          }
          <span className={classes.Time}>
            { validDate(priceBuyUpdatedAt) && format(validDate(priceBuyUpdatedAt), dateTimeFormat) }
          </span>
          <span className={`${classes.Margin} ${priceBuyMargin >= 0 ? classes.Up : classes.Down}`}>
            { priceBuyMargin >= 0 ? (<span>+{ numberFormat(priceBuyMargin) }</span>) : (<span>{ numberFormat(priceBuyMargin) }</span>) }
          </span>
        </div>
      }
      { priceSalesVisible &&
        <div className={`${gridClasses.Col} ${classes.EditInlineCol} ${isPriceSalesChanged ? classes.DirtyCol : ''}`}>
          { priceSalesVisible.editable
            ? <FieldEditable
                fieldName="price_sales"
                value={validNumber(priceSales)}
                displayedValue={currencyFormatVN(priceSales)}
                type="number"
                onSave={this.handleSaveField}
                onChange={this.handleFieldChange}
                error={!this._isPriceSalesValid(params.price_sales)}
                onCancel={this.handleCancelFields}
              />
            : currencyFormatVN(priceSales)
          }
          <span className={classes.Time}>
            { validDate(priceSalesUpdatedAt) && format(validDate(priceSalesUpdatedAt), dateTimeFormat) }
          </span>
          <span className={`${classes.Margin} ${priceSalesMargin >= 0 ? classes.Up : classes.Down}`}>
            { priceSalesMargin >= 0 ? (<span>+{ numberFormat(priceSalesMargin) }</span>) : (<span>{ numberFormat(priceSalesMargin) }</span>) }
          </span>
        </div>
      }
      { priceSalesRetailVisible &&
        <div className={`${gridClasses.Col} ${classes.EditInlineCol} ${isPriceSalesRetailChanged ? classes.DirtyCol : ''}`}>
          { priceSalesRetailVisible.editable
            ? <FieldEditable
                type="number"
                fieldName="price_sales_retail"
                value={validNumber(newPriceSalesRetail)}
                displayedValue={![null, undefined, ''].includes(newPriceSalesRetail) ? currencyFormatVN(newPriceSalesRetail) : ''}
                onSave={this.handleSaveField}
                onChange={this.handleFieldChange}
                error={!this._isPriceSalesRetailValid(params.price_sales_retail)}
                onCancel={this.handleCancelFields}
              />
            : (![null, undefined, ''].includes(newPriceSalesRetail) ? currencyFormatVN(newPriceSalesRetail) : '')
          }
        </div>
      }
      { this.getVisibleFieldFromName('price_buy_recently') &&
        <div className={`${gridClasses.Col}`}>
          <span className={classes.Price}>{ currencyFormatVN(price_buy_recently) }</span>
          <span className={classes.Time}>{ validDate(updated_at_price_buy_recently) && format(validDate(updated_at_price_buy_recently), dateTimeFormat) }</span>
        </div>
      }
      { this.getVisibleFieldFromName('price_sales_recently') &&
        <div className={`${gridClasses.Col}`}>
          <span className={classes.Price}>{ currencyFormatVN(price_sales_recently) }</span>
          <span className={classes.Time}>{ validDate(updated_at_price_sales_recently) && format(validDate(updated_at_price_sales_recently), dateTimeFormat) }</span>
        </div>
      }
      { this.getVisibleFieldFromName('getcare_uom_base_name') &&
        <div className={`${gridClasses.Col}`}>
          { uom_base.name }
        </div>
      }
      { vatVisible &&
        <div className={`${gridClasses.Col} ${classes.EditInlineCol} ${isVATChanged ? classes.DirtyCol : ''}`}>
          { vatVisible.editable
            ? <FieldEditable
                fieldType="select"
                fieldName="vat"
                value={newVAT}
                displayedValue={`${!['', null, undefined].includes(newVAT) ? newVAT + '%' : ''}`}
                options={[...vatsOptions]}
                onSave={this.handleSaveField}
              />
            : `${!['', null, undefined].includes(newVAT) ? newVAT + '%' : ''}`
          }
        </div>
      }
      { minimumQuantityVisible &&
        <div className={`${gridClasses.Col} ${classes.EditInlineCol} ${isMinimumQuantityChanged ? classes.DirtyCol : ''}`}>
          { minimumQuantityVisible.editable
            ? <FieldEditable
                type="number"
                fieldName="minimum_quantity"
                value={validNumber(newMinimumQuantity)}
                displayedValue={![null, undefined, ''].includes(newMinimumQuantity) ? numberFormat(newMinimumQuantity) : ''}
                onSave={this.handleSaveField}
                onChange={this.handleFieldChange}
                error={!this._isMinimumQuantityValid(params.minimum_quantity)}
                onCancel={this.handleCancelFields}
              />
            : (![null, undefined, ''].includes(newMinimumQuantity) ? numberFormat(newMinimumQuantity) : '')
          }
        </div>
      }
      { estimatedQuantityVisible &&
        <div className={`${gridClasses.Col} ${classes.EditInlineCol} ${isEstimatedQuantityChanged ? classes.DirtyCol : ''}`}>
          { estimatedQuantityVisible.editable
            ? <FieldEditable
                type="number"
                isInteger
                fieldName="estimated_quantity"
                value={validNumber(newEstimatedQuantity)}
                displayedValue={![null, undefined, ''].includes(newEstimatedQuantity) ? numberFormat(newEstimatedQuantity) : ''}
                onSave={this.handleSaveField}
                onChange={this.handleFieldChange}
                error={!this._isEstimatedQuantityValid(params.estimated_quantity)}
                onCancel={this.handleCancelFields}
              />
            : (![null, undefined, ''].includes(newEstimatedQuantity) ? numberFormat(newEstimatedQuantity) : '')
          }
        </div>
      }
    </div>;
  }
}

VendorPriceListItem.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  product: PropTypes.object,
  vendor: PropTypes.object,
  price_buy: PropTypes.number,
  price_buy_recently: PropTypes.number,
  price_sales: PropTypes.number,
  price_sales_recently: PropTypes.number,
  updated_at_price_buy: PropTypes.string,
  updated_at_price_buy_recently: PropTypes.string,
  updated_at_price_sales: PropTypes.string,
  updated_at_price_sales_recently: PropTypes.string,
  uom_base: PropTypes.object,
  operation: PropTypes.number,
  amount: PropTypes.number,
  policy_unit: PropTypes.object,
  vat: PropTypes.number,
  changedItem: PropTypes.object,
  displayFields: PropTypes.array,
  onSavePrice: PropTypes.func,
  onRemovePrice: PropTypes.func,
  onItemSelectedToggle: PropTypes.func,
};

VendorPriceListItem.defaultProps = {
  changedItem: undefined,
  displayFields: [],
};

const mapStateToProps = createStructuredSelector({
  priceUnits: makeSelectPriceUnits(),
});
const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(VendorPriceListItem);
