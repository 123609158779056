import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { format } from 'date-fns';
import { isEqual } from 'lodash';
import { toast } from 'react-toastify';
import userApi from 'utils/api/admin/userApi';

import { dateFormat } from 'utils/constanst/dateConstants';
import { validDate, genID } from 'utils/helper';
import { allInPageSize } from 'utils/constanst/common';
import { STATUS_WORKING, isVendorType, TYPE_SYSTEM, isCustomerType } from 'utils/constanst/adminUserConstants';

import {
  makeSelectOriUserDetails,
  makeSelectUserDetails,
  makeSelectUserLoading,
  makeSelectOriUserRoles,
  makeSelectUserRoles,
  makeSelectRoleList,
  makeSelectUserActionLoading,
} from 'redux/selectors';
import {
  getUser,
  resetUser,
  updateUser,
  getRoleList,
} from 'redux/actions/admin/userActions';

import Button from '@material-ui/core/Button';
import ArrowBack from '@material-ui/icons/ArrowBack';
import CheckOutlined from '@material-ui/icons/CheckOutlined';

import UserDetails from 'components/admin/UserDetails/UserDetails';
import UserRoles from 'components/admin/UserRoles/UserRoles';

import classes from './User.module.scss';

class User extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      newFormId: null,
      resetPwLoading: false,
    }
    this.userDetailsForm = null;
  }
  componentDidMount() {
    const { id } = this.props.match.params;
    this._loadData(id);
    this._loadRoleList();
  }
  componentDidUpdate(prevProps, prevState) {
    const { id } = this.props.match.params;
    if (id !== prevProps.match.params.id) {
      this._loadData(id);
    }
    const { userDetails } = this.props;
    if (userDetails && userDetails.id) {
      if (!prevProps.userDetails || userDetails.id !== prevProps.userDetails.id) {
        if (
          !prevProps.userDetails ||
          !prevProps.userDetails.id ||
          isNaN(prevProps.userDetails.id)
        ) {
          // created successfull
          this.props.history.push(`/user/${userDetails.id}`);
          return;
        }
      }
    }
  }

  _isValid = () => {
    return true;
  }

  _getUserDetailsContrains = (params) => {
    return {
      email: params.email,
      phone: params.phone,
      name: params.name,
      other_name: params.other_name,
      birthday: validDate(params.birthday) ? format(validDate(params.birthday), dateFormat) : '',
      address: params.address,
      gender: params.gender,
      status: params.status,
      user_type: params.user_type,
      getcare_department_id: params.getcare_department_id,
      getcare_vendor_id: params.getcare_vendor_id,
      getcare_customer_id: params.getcare_customer_id,
      approval: params.approval,
    }
  }
  _isDetailsChanged = () => {
    if (!this._isEditing()) return false;
    const { oriUserDetails, userDetails } = this.props;
    if (!oriUserDetails || !userDetails) return false;
    return !isEqual(this._getUserDetailsContrains(userDetails), this._getUserDetailsContrains(oriUserDetails));
  }

  _getRoleContrains = (params) => {
    return {
      id: params.id,
    }
  }
  _isRolesChanged = () => {
    if (!this._isEditing()) return false;

    const { userRoles, oriUserRoles } = this.props;
    if (!userRoles || !oriUserRoles) return false;

    const hasDeletedItem = oriUserRoles.some(item => item.id && !userRoles.some(p => p.id && p.id === item.id));
    if (hasDeletedItem) return true;

    return userRoles.some(tempItem => {
      const tempItemMap = this._getRoleContrains(tempItem);
      return !oriUserRoles.some(item => isEqual(
        tempItemMap,
        this._getRoleContrains(item)
      ))
    });
  }

  _loadData = (id) => {
    if (this._isEditing()) {
      this.props.getUser(id);
      return;
    }
    this.props.resetUser();
    this.setState({
      newFormId: genID(),
    });
  };
  _loadRoleList = () => {
    this.props.getRoleList({
      params: { page_size: allInPageSize },
    });
  }

  _backToList = () => {
    this.props.history.push('/user');
  };
  _isEditing = () => {
    const { id } = this.props.match.params;
    return id && !isNaN(id) && Number(id) > 0;
  };

  _isRolesNotCompleted = () => {
    return this.props.userRoles.some(item => item.isEditing);
  }
  hasNotCompletedForm = () => {
    return this._isRolesNotCompleted();
  }

  isRolesValid = () => {
    let messages = [];
    if (!this.props.userRoles.length) {
      messages.push('• Nhân viên phải có ít nhất một role.');
    }
    return messages;
  }

  hasWarningMessages = () => {
    if (this.hasNotCompletedForm()) {
      toast.error('Có một dòng bạn đang làm dang dở. Hãy hoàn thành để tiếp tục.');
      return true;
    }
    const messages = [
      ...this.isRolesValid(),
    ];
    if (messages.length) {
      toast.error(messages.join('\n'));
      return true;
    }
    return false;
  }

  _prepareData = () => {
    const isEditing = this._isEditing();
    const { userRoles, userDetails } = this.props;
    const userPhone = userDetails?.phone;

    return {
      id: userDetails?.id || null,
      email: userDetails?.email,
      phone: (/^(84)/).test(userPhone) ? userPhone.replace(/^(84)/, '+84') : userPhone,
      name: userDetails?.name,
      other_name: userDetails?.other_name,
      birthday: !!userDetails?.birthday ? userDetails?.birthday : null,
      gender: !!userDetails?.gender ? userDetails?.gender : null,
      address: userDetails?.address,
      status: isEditing ? userDetails?.status : STATUS_WORKING,
      user_type: userDetails?.user_type || TYPE_SYSTEM,
      getcare_department_id: userDetails?.getcare_department_id || null,
      getcare_vendor_id: isVendorType(userDetails?.user_type) ? userDetails?.getcare_vendor_id : null,
      getcare_customer_id: isCustomerType(userDetails?.user_type) ? userDetails?.getcare_customer_id : null,

      getcare_roles: userRoles.filter(role => !!role.id).map((item, index) => ({
        id: item.id || undefined,
      })),
      approval: userDetails.approval || false,
    };
  };

  handleCancelUser = () => {
    this._loadData(this.props.match.params.id);
  }
  handleSaveUser = () => {
    this.userDetailsForm.submitForm();
  }
  handleSubmitUser = (userDetailsFormValues) => {
    const hasErrors = this.hasWarningMessages();
    if (hasErrors) return;
    this.props.updateUser({
      params: {
        ...this._prepareData(),
      },
    });
  }
  handleResetPassword = async () => {
    try {
      this.setState({ resetPwLoading: true });
      const { data: response } = await userApi.resetPassword({
        params: {
          id: this.props.userDetails?.id,
        },
      });
      this.setState({ resetPwLoading: false });
      if (!response?.result) {
        toast.error(response?.message);
        return;
      }
      toast.success(response.message);
    } catch(err) {
      this.setState({ resetPwLoading: false });
      toast.error(err);
    }
  }

  render() {
    const { resetPwLoading } = this.state;
    const isEditing = this._isEditing();
    const isDetailsChanged = this._isDetailsChanged();
    const isRolesChanged = this._isRolesChanged();
    const isChanged = isDetailsChanged || isRolesChanged;
    const isValid = this._isValid();
    const isResetPasswordDisabled = resetPwLoading || !this.props.userDetails?.id;

    return (
      <div
        className={`${classes.PageWrap} ${
          this.props.loading || this.props.actionLoading ? 'OverlayLoading' : ''
        }`}
      >
        <div className={classes.PageHeader}>
          <h1 className={classes.PageTitle}>
            {isEditing ? `Xem chi tiết nhân viên` : `Tạo tài khoản nhân viên`}
          </h1>

          {!isEditing && (
            <Button
              size="small"
              variant="outlined"
              startIcon={<ArrowBack />}
              onClick={this._backToList}
            >
              Huỷ và Trở về
            </Button>
          )}

          {isEditing && (
            <>
              <Button
                size="small"
                variant="outlined"
                startIcon={<ArrowBack />}
                onClick={this._backToList}
              >
                Trở về
              </Button>
              <Button
                size="small"
                variant="outlined"
                color="primary"
                disabled={isResetPasswordDisabled}
                className={resetPwLoading ? 'OverlayLoading': ''}
                onClick={this.handleResetPassword}
              >
                Reset password
              </Button>
              <Button
                size="small"
                disabled={(!isEditing && !isValid) || (isEditing && (!isValid || !isChanged))}
                variant="outlined"
                color="secondary"
                onClick={this.handleCancelUser}
              >
                Huỷ thay đổi
              </Button>
            </>
          )}

          <Button
            size="small"
            variant="contained"
            color="primary"
            disabled={(!isEditing && !isValid) || (isEditing && (!isValid || !isChanged))}
            startIcon={<CheckOutlined />}
            onClick={this.handleSaveUser}
          >
            {isEditing ? `Xác nhận thay đổi` : `Tạo nhân viên`}
          </Button>
        </div>
        <div className={classes.PageMain}>
          <UserDetails
            formRef={(ref) => { this.userDetailsForm = ref }}
            key={`user-${this.props.userDetails?.id || 'new'}`}
            isEditing={isEditing}
            isValid={isValid}
            onSubmit={this.handleSubmitUser}
          />
          <UserRoles
            isEditing={isEditing}
            userId={this.props.userDetails?.id}
            roleList={this.props.roleList}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  oriUserDetails: makeSelectOriUserDetails(),
  userDetails: makeSelectUserDetails(),
  oriUserRoles: makeSelectOriUserRoles(),
  userRoles: makeSelectUserRoles(),
  loading: makeSelectUserLoading(),
  actionLoading: makeSelectUserActionLoading(),
  roleList: makeSelectRoleList(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getUser: (payload) => dispatch(getUser(payload)),
    resetUser: (payload) => dispatch(resetUser(payload)),
    updateUser: (payload) => dispatch(updateUser(payload)),
    getRoleList: (payload) => dispatch(getRoleList(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter)(User);
