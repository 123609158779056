export const productOptions = [
  {
    id: 0,
    name: 'Toàn bộ sản phẩm',
  },
  {
    id: 1,
    name: 'Sản phẩm chưa có Phahub ID',
  }
]

export const productOptionsApproval = [
  {
    id: 1,
    name: 'Sản phẩm chưa có Phahub ID',
  }
]

export const listDisplayFields = [
  {
    name: 'getcare_product_getcare_id',
    authorize_name: 'getcare_product.getcare_id',
    label: 'Phahub ID',
    sortable: true,
    checked: true,
    sortDir: '',
  },
  {
    name: 'getcare_product_name',
    authorize_name: 'getcare_product.name',
    label: 'Tên thuốc\n(theo Phahub)',
    sortable: true,
    checked: true,
    sortDir: '',
  },
  {
    name: 'registration_number',
    authorize_name: 'registration_number',
    label: 'SDK',
    sortable: true,
    checked: true,
    sortDir: '',
  },
  {
    name: 'getcare_vendor_name',
    label: 'Nhà cung cấp',
    sortable: true,
    checked: true,
    sortDir: '',
  },
  {
    name: 'product_vendor_code',
    authorize_name: 'product_vendor_code',
    label: 'Mã hàng',
    sortable: true,
    checked: true,
    sortDir: '',
  },
  {
    name: 'product_vendor_name',
    authorize_name: 'product_vendor_name',
    label: 'Tên hàng',
    checked: true,
    sortable: true,
    sortDir: '',
  },
  {
    name: 'getcare_uom_base_name',
    authorize_name: 'getcare_uom_base.name',
    label: 'Đơn vị tính',
    checked: true,
    sortable: true,
    sortDir: '',
  },
  {
    name: 'price_buy_total',
    authorize_name: 'price_buy_total',
    label: 'Đơn giá\n(VAT inc.)',
    sortable: true,
    checked: true,
    sortDir: '',
  },
  {
    name: 'price_sales_total',
    authorize_name: 'price_sales_total',
    label: 'Giá bán sỉ\n(VAT inc.)',
    sortable: true,
    checked: true,
    sortDir: '',
  },
  {
    name: 'price_sales_retail_total',
    authorize_name: 'price_sales_retail_total',
    label: 'Giá bán lẻ\n(VAT inc.)',
    sortable: true,
    checked: true,
    sortDir: '',
  },
  {
    name: 'commission',
    label: 'Hoa Hồng\n(VNĐ)',
    sortable: true,
    checked: false,
    sortDir: '',
  },
  {
    name: 'vat',
    authorize_name: 'vat',
    label: 'VAT',
    checked: false,
    sortable: true,
    sortDir: '',
  },
  {
    name: 'minimum_quantity',
    authorize_name: 'minimum_quantity',
    label: 'Số lượng\ntối thiểu',
    sortable: true,
    checked: false,
    sortDir: '',
  },
  {
    name: 'remaining_quantity',
    authorize_name: 'remaining_quantity',
    label: 'Số lượng\ncòn lại',
    sortable: true,
    checked: true,
    sortDir: '',
  },
  {
    name: 'type_label',
    authorize_name: 'type_label',
    label: 'Nhãn',
    sortable: true,
    checked: false,
    sortDir: '',
  },
  {
    name: 'weight',
    authorize_name: 'weight',
    label: 'Cân nặng\n(Gram)',
    sortable: true,
    checked: false,
    sortDir: '',
  },
  {
    name: 'ka_days',
    authorize_name: 'ka_days',
    label: 'Số ngày\nKA lock',
    sortable: true,
    checked: false,
    sortDir: '',
  },
  {
    name: 'getcare_category_ecoms',
    authorize_name: 'getcare_category_ecoms',
    label: 'Danh mục',
    sortable: false,
    checked: false,
    sortDir: '',
  },
  {
    name: 'images',
    authorize_name: 'images',
    label: 'Hình ảnh',
    checked: true,
    sortable: false,
    sortDir: '',
  },
  {
    name: 'description',
    label: 'Mô tả sản phẩm',
    sortable: false,
    checked: true,
    sortDir: '',
  },
  {
    name: 'parent_vendor_product_code',
    label: 'Parent product',
    sortable: false,
    checked: true,
    sortDir: '',
  },
  {
    name: 'subsidiary_name',
    label: 'Subname',
    sortable: false,
    checked: true,
    sortDir: '',
  },

  
  {
    name: 'updated_at',
    label: 'Last updated',
    sortable: true,
    checked: true,
    sortDir: '',
  },
]

export const listParamsMap = {
  page_size: 50,
  page: 1,
  order: '',
  getcare_product_getcare_id: '',
  getcare_product_name: '',
  product_vendor_code: '',
  product_vendor_name: '',
  getcare_uom_base_name: '',
  production_name: '',
  getcare_product_is_null: 0,
}
export const filterFields = [
  {
    name: 'getcare_product_getcare_id',
    label: 'Phahub ID',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'getcare_product_name',
    label: 'Tên thuốc\n(theo Phahub)',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'getcare_vendor_name',
    label: 'Nhà cung cấp\n(theo Phahub)',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'product_vendor_code',
    label: 'Mã hàng',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'product_vendor_name',
    label: 'Tên hàng',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'getcare_uom_base_name',
    label: 'Đơn vị tính',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'getcare_manufacturer_name',
    label: 'Nhà sản xuất',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'getcare_category_ecom_id',
    label: 'Danh mục',
    value: '',
    type: 'custom',
    placeholder: 'Nhập...',
  },
  {
    name: 'updated_at',
    label: 'Last updated',
    value: '',
    type: 'custom',
    placeholder: 'Nhập...',
  },
]

export const IMPORT_ADD = 'add';
export const IMPORT_OVERRIDE = 'override';
export const importTypesMap = {
  [IMPORT_ADD]: 'Thêm sản phẩm vào danh sách',
  [IMPORT_OVERRIDE]: 'Ghi đè lên danh sách hiện có',
}
export const importTypes = [
  {
    id: IMPORT_ADD,
    name: importTypesMap[IMPORT_ADD],
  },
  {
    id: IMPORT_OVERRIDE,
    name: importTypesMap[IMPORT_OVERRIDE],
  },
];
