import axiosAuth from 'utils/axios/axiosAuth';

const coordinaterApi = {
  getList: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (![null, undefined, ''].includes(params[key])) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_coordinator`, {
      params: queryParams,
    });
  },
  updateCoordinater: ({ params }) => {
    return axiosAuth.post(`getcare_coordinator`, params);
  },
  deleteCoordinaters: ({ params }) => {
    return axiosAuth({
      method: 'DELETE',
      url: `getcare_coordinator`,
      data: {
        getcare_coordinator_ids: params.getcare_coordinator_ids,
      },
    });
  },
};

export default coordinaterApi;
