import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';

import classes from './ExportGroupBtns.module.scss';

class ExportGroupBtns extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isMoreActionsOpen: false,
    }
    this.moreActionsMenuRef = React.createRef();
  }

  handleToggleDropdown = () => {
    this.setState({
      isMoreActionsOpen: !this.state.isMoreActionsOpen,
    });
  };

  handleCloseDropdown = () => {
    this.setState({
      isMoreActionsOpen: false,
    });
  };

  render() {
    const { isMoreActionsOpen } = this.state;
    const { listLength } = this.props;

    return (<div>
      <Button
        disabled
        ref={this.moreActionsMenuRef}
        className={`${classes.Btn}`}
        aria-controls={isMoreActionsOpen ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        variant="contained"
        onClick={this.handleToggleDropdown}
      >
        Export <ArrowDropDownRoundedIcon/>
      </Button>
      <Popper
        open={isMoreActionsOpen}
        anchorEl={this.moreActionsMenuRef.current}
        role={undefined}
        transition
        disablePortal
        className={classes.Popper}
        style={{
          minWidth: this.moreActionsMenuRef && this.moreActionsMenuRef.current && this.moreActionsMenuRef.current.offsetWidth,
          zIndex: 3,
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'left top' : 'left bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={this.handleCloseDropdown}>
                <MenuList id="menu-list-grow" autoFocusItem={isMoreActionsOpen}>
                  <MenuItem disabled={!listLength} onClick={this.props.exportAndDeleteAllProducts}>Export toàn danh sách và xoá</MenuItem>
                  <MenuItem disabled={!listLength} onClick={this.props.epxortAllProducts}>Export toàn danh sách và giữ lại</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>)
  }
}

ExportGroupBtns.propTypes = {
  exportAndDeleteAllProducts: PropTypes.func,
  epxortAllProducts: PropTypes.func,
  listLength: PropTypes.number,
};

ExportGroupBtns.defaultProps = {
  listLength: 0,
};

export default ExportGroupBtns;
