import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectPolicyPriceDetails,
  makeSelectPolicyPriceProducts,
  makeSelectTempPolicyPriceProducts,
  makeSelectPriceUnits,
} from 'redux/selectors';
import {
  saveTempPolicyPriceProducts,
  saveTempPolicyPriceProductItem,
  removeTempPolicyPriceProductItems,
  getPriceUnits,
  exportPolicyPriceTemplate
} from 'redux/actions/admin/policyPriceActions';
import { getDisplayFields, genID } from 'utils/helper';
import {
  policyPriceProductsParamsMap,
  retailDisplayFields,
  baseDisplayFields,
} from 'utils/constanst/adminPolicyPriceContants';
import { isEqual, sortBy } from 'lodash';
import {
  POLICY_PRICE_PRODUCT_TYPE,
  PLUS_OPERATION,
  POLICY_PRICE_VND_UNIT,
  POLICY_PRICE_GROUP_TYPE,
} from 'utils/constanst/adminPolicyPriceContants';

import PolicyPriceProduct from './PolicyPriceProduct/PolicyPriceProduct';
import Checkbox from '@material-ui/core/Checkbox';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';
import Button from '@material-ui/core/Button';
import FileImportDialog from 'components/admin/PolicyPriceProducts/FileImportDialog/FileImportDialog';

import gridClasses from './PolicyPriceProductsGrid.module.scss';
import classes from './PolicyPriceProducts.module.scss';

class PolicyPriceProducts extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedItems: [],
      isImportDialogOpen: false
    };
  }
  componentDidMount() {
    this.props.getPriceUnits();
  }
  componentDidUpdate(prevProps) {
    const { tempPolicyPriceProducts } = this.props;
    const { selectedItems } = this.state;
    if (
      !isEqual(
        sortBy(tempPolicyPriceProducts),
        sortBy(prevProps.tempPolicyPriceProducts)
      ) &&
      selectedItems.length > 0
    ) {
      const remainItems = selectedItems.filter((p) =>
        tempPolicyPriceProducts.some((item) => {
          const comparedField = item.idStr ? `idStr` : `id`;
          return item[comparedField] === p[comparedField];
        })
      );
      this.setState({
        selectedItems: remainItems,
      });
    }
  }
  _getChangedItem = (price) => {
    const { policyPriceProducts } = this.props;
    const comparedField = price.idStr ? 'idStr' : 'id';
    const theItem = policyPriceProducts.find(
      (item) => item[comparedField] === price[comparedField]
    );
    if (!theItem) return null;
    return !isEqual(theItem, price) ? {...theItem} : null;
  };
  _getHighLightList = () => {
    const { tempPolicyPriceProducts, policyPriceProducts } = this.props;
    if (!tempPolicyPriceProducts.length || !policyPriceProducts.length) {
      return [...tempPolicyPriceProducts];
    }
    return tempPolicyPriceProducts.map((price) => ({
      ...price,
      changedItem: this._getChangedItem(price),
    }));
  };
  _getPriceUnit = (id) => {
    return this.props.priceUnits?.find((item) => item.id === id);
  };
  isAllItemSelected = () => {
    const { tempPolicyPriceProducts } = this.props;
    const { selectedItems } = this.state;
    return (
      selectedItems.length > 0 &&
      selectedItems.length >= tempPolicyPriceProducts.length
    );
  };
  isItemSelected = (item) => {
    return (
      this.state.selectedItems.findIndex((p) => {
        const comparedField = p.idStr ? 'idStr' : 'id';
        return p[comparedField] === item[comparedField];
      }) > -1
    );
  };
  handleAllSelectedToggle = (e) => {
    this.setState({
      selectedItems: e.target.checked
        ? [...this.props.tempPolicyPriceProducts]
        : [],
    });
  };
  handleItemSelectedToggle = ({ item, isSelected }) => {
    const remainItems = this.state.selectedItems.filter((p) => {
      const comparedField = p.idStr ? 'idStr' : 'id';
      return p[comparedField] !== item[comparedField];
    });
    this.setState({
      selectedItems: isSelected ? [...remainItems, { ...item }] : remainItems,
    });
  };
  handleCreateNewRow = () => {
    this.props.saveTempPolicyPriceProductItem({
      idStr: genID(),
      id: 0,
      type_id: POLICY_PRICE_PRODUCT_TYPE,
      operation: PLUS_OPERATION,
      policy_unit: { ...this._getPriceUnit(POLICY_PRICE_VND_UNIT) },
    });
  };
  handleSavePolicyPriceProduct = (params) => {
    this.props.saveTempPolicyPriceProductItem({
      ...params,
    });
  };
  handleRemovePolicyPriceProduct = (params) => {
    this.props.removeTempPolicyPriceProductItems([{ ...params }]);
  };
  handleRemovePolicyPriceProducts = () => {
    this.props.removeTempPolicyPriceProductItems([...this.state.selectedItems]);
  };
  handleDownloadTemplate = () => {
    this.props.exportPolicyPriceTemplate();
  };
  handleImportDialogOpen = () => {
    this.setState({
      isImportDialogOpen: true,
    });
  };
  handleImportDialogClose = () => {
    this.setState({
      isImportDialogOpen: false,
    });
  };
  handleCompleteImport = (importedData) => {
    if (importedData && importedData.length > 0) {
      // Format imported items and prepend them to list
      const newProductItems = importedData
        .map(item => ({
          ...item,
          idStr: genID(),
          group_or_vendor_product_id: item.type_id === POLICY_PRICE_GROUP_TYPE ? item.erp_group?.id : item.product_price_vendor_id,
        }))
        .concat(this.props.tempPolicyPriceProducts);
      this.props.saveTempPolicyPriceProducts(newProductItems);
    }
  };

  render() {
    const { tempPolicyPriceProducts, policyPriceDetails, readOnly } = this.props;
    const { isImportDialogOpen } = this.state;
    const displayFields = getDisplayFields(
      policyPriceProductsParamsMap,
      policyPriceDetails?.is_retail ? retailDisplayFields : baseDisplayFields
    );
    const list = this._getHighLightList();
    const hasSelected = this.state.selectedItems.length > 0;

    return (
      <div className={`${classes.Wrap}`}>
        <div className={classes.Toolbar}>
          <Button
            variant="contained"
            size="small"
            disabled={readOnly}
            onClick={this.handleDownloadTemplate}
          >
            Download template
          </Button>
          <Button
            variant="contained"
            size="small"
            disabled={readOnly}
            onClick={this.handleImportDialogOpen}
          >
            Import
          </Button>
          <Button
            color="primary"
            variant="contained"
            size="small"
            disabled={readOnly}
            onClick={this.handleCreateNewRow}
          >
            Thêm dòng
          </Button>
          <Button
            className={`${classes.MultiActionBtn}`}
            disabled={!hasSelected || readOnly}
            color="secondary"
            variant="contained"
            size="small"
            onClick={this.handleRemovePolicyPriceProducts}
          >
            Xoá
          </Button>
        </div>
        <div className={`${classes.List}`}>
          <div className={`${classes.Body}`}>
            <div className={`${gridClasses.Row} ${classes.Header}`}>
              <div className={`${gridClasses.Col} ${classes.ActionsCol}`}>
                <Checkbox
                  disabled={readOnly}
                  className={classes.Checkbox}
                  checked={this.isAllItemSelected()}
                  onChange={this.handleAllSelectedToggle}
                />
              </div>
              {displayFields.map((item) => (
                <ListHeaderCol
                  key={item.name}
                  className={`${gridClasses.Col} ${item.extraClassName ? classes[item.extraClassName] : ''}`}
                  {...item}
                />
              ))}
            </div>
          
            {list.length ? (
              list.map((item) => (
                <PolicyPriceProduct
                  key={`price-Product-${item.id || item.idStr}`}
                  {...item}
                  readOnly={readOnly}
                  isSelected={this.isItemSelected(item)}
                  currentList={[...tempPolicyPriceProducts]}
                  onItemSelectedToggle={this.handleItemSelectedToggle}
                  savePolicyPriceProduct={this.handleSavePolicyPriceProduct}
                  removePolicyPriceProduct={this.handleRemovePolicyPriceProduct}
                  isRetail={policyPriceDetails?.is_retail}
                />
              ))
            ) : (
              <p className="NoData">Không có data</p>
            )}
          </div>
        </div>
        {isImportDialogOpen && (
          <FileImportDialog
            title="Import danh sách sản phẩm"
            isOpen={isImportDialogOpen}
            onClose={this.handleImportDialogClose}
            onCompleteImport={this.handleCompleteImport}
          />
        )}
      </div>
    );
  }
}

PolicyPriceProducts.propTypes = {
  policyPriceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  readOnly: PropTypes.bool,
};

PolicyPriceProducts.defaultProps = {
  readOnly: false,
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveTempPolicyPriceProductItem: (payload) =>
      dispatch(saveTempPolicyPriceProductItem(payload)),
    removeTempPolicyPriceProductItems: (payload) =>
      dispatch(removeTempPolicyPriceProductItems(payload)),
    getPriceUnits: () => dispatch(getPriceUnits()),
    exportPolicyPriceTemplate: (payload) => dispatch(exportPolicyPriceTemplate(payload)),
    saveTempPolicyPriceProducts: (payload) => dispatch(saveTempPolicyPriceProducts(payload)),
  };
};
const mapStateToProps = createStructuredSelector({
  policyPriceDetails: makeSelectPolicyPriceDetails(),
  policyPriceProducts: makeSelectPolicyPriceProducts(),
  tempPolicyPriceProducts: makeSelectTempPolicyPriceProducts(),
  priceUnits: makeSelectPriceUnits(),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(PolicyPriceProducts);
