import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';

import authApi from 'utils/api/authApi';
import getcareCategoryApi from 'utils/api/admin/getcareCategoryApi';

import { isEqual } from 'lodash';
import { 
  CATEGORY_MAX_LEVEL,
  getGetcareCategoryLevelList,
  getSelectedGetcareCategoryLevel,
  mapGetcareCategoryTreeToList,
} from 'utils/constanst/adminGetcareCategoryConstants';
import { isActionAllowed } from 'utils/helper/authorization';

import { 
  getGetcareCategoryList,
  saveGetcareCategoryAllowedActions,
} from 'redux/actions/admin/getcareCategoryActions';
import { 
  createStructuredSelector 
} from 'reselect';
import {
  makeSelectGetcareCategoryList,
  makeSelectIsLoading,
  makeSelectGetcareCategoryAllowedActions,
} from 'redux/selectors';

import { 
  Button,
  TextField,
  FormControl,
  OutlinedInput,
  InputAdornment,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  IconButton,
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { toast } from 'react-toastify';

import GetcareCategoryFormDialog from 'components/admin/GetcareCategory/FormDialog/FormDialog';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';

import classes from './GetcareCategory.module.scss';

class GetcareCategory extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedCategories: [],
      getcareCategoryDetail: null,
      getcareCategoryFormDialogAction: null,
      getcareCategoryFormDialogOpen: false,
      confirmDialogOpen: false,
      confirmDialogTitle: '',
      confirmDialogMessage: '',
      confirmDialogActions: null,
      confirmDialogLoading: false,
      confirmDialogType: 'info',
      isLoadingAllowedActions: true,
      keyword: '',
    };
  }
  async componentDidMount() {
    this._loadGetcareCategory()
  }
  async componentDidUpdate(prevProps) {
    const { getcareCategoryList } = this.props;
    if ( !isEqual(getcareCategoryList,prevProps.getcareCategoryList) ) {
      this._updateStateSelectedCategories()
    }
  }

  _updateStateSelectedCategories = () => {
    const { getcareCategoryList,isLoadingGetcareCategory } = this.props;
    const { selectedCategories } = this.state;
    const getcareCategories = mapGetcareCategoryTreeToList(getcareCategoryList);
    if ( !isLoadingGetcareCategory ) {
      let reduceSelectedCategories = selectedCategories.length > 0 ? selectedCategories.reduce((previousValue, currentValue, currentIndex ) => { 
        const currentGetcareCategory = getcareCategories.find( (item) => item.id === currentValue?.category_id );
        if ( previousValue.length === 0 ) {
          return [].concat(currentGetcareCategory && currentIndex === 0 ? [{...currentValue }] : [])
        }
        if ( previousValue[previousValue.length - 1]?.level !==  currentValue.level - 1 ) return [...previousValue];
        return [...previousValue].concat(currentGetcareCategory ? [{...currentValue}] : []);
      },[]) : [];
      this.setState({ selectedCategories: [...reduceSelectedCategories] })
    }
  }
  _resetState = () => {
    this.setState({
      selectedCategories: [],
      getcareCategoryDetail: null,
      keyword: '',
    })
  }
  _getAllowedActions = async () => {
    this.setState({ isLoadingAllowedActions: true })
    const { data: response } = await authApi.getAllowedActions({
      component: 'getcare_category',
    });
    this.setState({ isLoadingAllowedActions: false })
    if (!response.result) {
      toast.error(response.message);
      return;
    }
    this.props.saveGetcareCategoryAllowedActions(response.allowed_actions || []);
  }
  _loadGetcareCategory = async () => {
    this._getAllowedActions();
    this.props.getGetcareCategoryList();
  }

  handleSelectCategory = ({ category }) => {
    /**
     * @param {[{ category_id: string, level: number }]} selectedCategories category list đã được select.
     * @param {{ id: number, name: string, level: number, parent_id: number }} category category đang select.
     */
    let selectedCategories = [...this.state.selectedCategories]; // [{ category_id: string, level: number }]
    const existedCategory = selectedCategories.some( item => item.category_id === category.id );
    if ( existedCategory ) {
      selectedCategories = selectedCategories.filter( item => item.category_id !== category.id && item.level < category.level );
    } else {
      /**
       * @param {[{ id: number, name: string, level: number, parent_id: number }]} getcareCategoryList category list đã được select.
       */
      const getcareCategoryList = mapGetcareCategoryTreeToList(this.props.getcareCategoryList);
      selectedCategories = [];
      const loop = (l_category) => {
        selectedCategories.unshift({ category_id: l_category.id, level: l_category.level });
        const getcareCategory = getcareCategoryList.find( item => item.id === l_category.parent_id );
        if ( getcareCategory ) loop(getcareCategory);
      }
      loop(category);
    }
    this.setState({ selectedCategories });
  }
  handleOpenGetcareCategoryFormDialog = ({ action, category }) => {
    this.setState({
      getcareCategoryFormDialogAction: action,
      getcareCategoryDetail: category,
      getcareCategoryFormDialogOpen: true,
    })
  }
  handleCloseGetcareCategoryFormDialog = () => {
    this.setState({
      getcareCategoryFormDialogAction: '',
      getcareCategoryDetail: '',
      getcareCategoryFormDialogOpen: false,
    })
  }
  handleCreateGetcareCategory = () => {
    this.handleOpenGetcareCategoryFormDialog({ action: "CREATE" })
  }
  handleEditGetcareCategory = ({category}) => {
    this.handleOpenGetcareCategoryFormDialog({ action: "EDIT", category: {...category} })
  }
  handleSubmitRemoveGetcareCategory = async ({ category }) => {
    this.setState({
      confirmDialogLoading: true
    })
    await getcareCategoryApi.deleteMultipleGetcareCategory({
      params: { ids: [category.id] }
    })
    this._loadGetcareCategory();
    this.handleCloseConfirmDialog();
  }
  handleRemoveGetcareCategory = async ({ category }) => {
    let title, message,actions;
    if ( category.is_delete === 0 ) {
      title = "Thông báo";
      message = <>Danh mục <b>{category.name}</b> vẫn có sản phẩm. Vui lòng tag lại danh mục cho các sản phẩm trong danh mục này trước (Nếu đã tag lại sản phẩm, vui lòng làm mới lại trang để thực hiện lại)</>;
      actions = <>
        <Button onClick={this.handleCloseConfirmDialog}>
          Huỷ bỏ
        </Button>
        <Button
          variant="contained"
          color="primary"
          autoFocus
          href={`/products?getcare_category_ecom_id=${category.id}`}
          target="_blank"
          style={{color: '#FFF'}}
        >
          Danh sách sản phẩm của danh mục
        </Button>
      </>
    } else {
      title = "Thực hiện xóa danh mục";
      message = <>Thao tác này sẽ xóa danh mục <b>{category.name}</b> ra khỏi hệ thống. Bạn có muốn tiếp tục?</>;
      actions = <>
        <Button onClick={this.handleCloseConfirmDialog}>
          Huỷ bỏ
        </Button>
        <Button
          onClick={() => this.handleSubmitRemoveGetcareCategory({ category })}
          variant="contained"
          color="primary"
          autoFocus
        >
          Tiếp tục
        </Button>
      </>
    }
    this.handleOpenConfirmDialog({ title, message, actions })
  }
  handleOpenConfirmDialog = ({ title, message, actions, loading }) => {
    this.setState({
      confirmDialogOpen: true,
      confirmDialogTitle: title,
      confirmDialogMessage: message,
      confirmDialogActions: actions,
      confirmDialogLoading: loading,
    })
  }
  handleCloseConfirmDialog = () => {
    this.setState({
      confirmDialogOpen: false,
      confirmDialogTitle: '',
      confirmDialogMessage: '',
      confirmDialogActions: null,
      confirmDialogLoading: false,
    })
  }
  handleSubmitSuccessGetcareCategory = () => {
    this.handleCloseGetcareCategoryFormDialog()
    this._loadGetcareCategory()
  }

  render() {
    const { getcareCategoryList, getcareCategoryAllowedActions, isLoadingGetcareCategory } = this.props;
    const { 
      confirmDialogTitle,
      confirmDialogMessage,
      confirmDialogOpen,
      getcareCategoryDetail, 
      getcareCategoryFormDialogAction, 
      selectedCategories, 
      getcareCategoryFormDialogOpen,
      confirmDialogLoading,
      confirmDialogActions,
      isLoadingAllowedActions,
      keyword,
    } = this.state;
    const categoryMaxLevel = CATEGORY_MAX_LEVEL;
    const isEditAllowed = (category_is_delete) => {
      return isActionAllowed(category_is_delete ? 'grant_edit' : 'edit', getcareCategoryAllowedActions);
    }
    const isDeleteAllowed = (category_is_delete) => {
      return isActionAllowed(category_is_delete ? 'grant_delete' : 'delete', getcareCategoryAllowedActions);
    }
    const isLoading = isLoadingGetcareCategory || isLoadingAllowedActions;

    return (
      <React.Fragment>
        <div className={`${classes.PageWrap} ${ isLoading && 'OverlayLoading'}`}>
          <div className={classes.PageHeader}>
            <h1 className={classes.PageTitle}>Danh sách danh mục Phahub</h1>
            <div className={classes.PageAction}>
              <Button onClick={this.handleCreateGetcareCategory} variant="contained" color="primary">Thêm danh mục</Button>
            </div>
          </div>
          <div className={classes.PageMain}>
            <div className={classes.PageCard}>
              <div className={classes['PageCard-Header']}>
                <FormControl size="small" className={classes['PageCard-Searching']}>
                  <OutlinedInput
                    placeholder="Tìm kiếm tên danh mục..."
                    onChange={ (e) => this.setState({ keyword: e.target.value })}
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </div>
              <div className={classes['PageCard-Body']}>
                <Grid container spacing={0} >
                  {
                    [...Array(categoryMaxLevel).keys()].map( level => {
                      return (
                        <Grid key={`CategoryList-${level}`} item xs >
                          <div className={ classes['PageCard-Categ-Item'] }>
                            <h4 className={ classes['PageCard-Title'] }>
                              { level ? `Danh mục ${level}` : 'Danh mục chính' }
                            </h4>
                            <div className={ classes.PageCardBody}>
                              <List className={classes.PageCardList}>
                                { 
                                  getGetcareCategoryLevelList({level, getcareCategoryTrees: getcareCategoryList, selectedCategories, keyword }).map( category => {
                                    const selectedGetcareCategoryLevel = getSelectedGetcareCategoryLevel({ level, selectedCategories });
                                    return (
                                      <ListItem 
                                        key={`CategoryListItem-${category.id}`}
                                        selected={selectedGetcareCategoryLevel?.category_id === category.id ? true : false} 
                                        onClick={() => this.handleSelectCategory({category,level})} button
                                      >
                                        <ListItemText primary={category.name} />
                                        <ListItemSecondaryAction className={classes.PageCardListItemActions}>
                                         
                                          {
                                            category.children_items &&
                                            <ChevronRightIcon fontSize="small" />
                                          }
                                          <div className={classes.PageCardListItemActionsGroup}>
                                            {
                                              isEditAllowed(category.is_delete === 0) &&
                                              <IconButton onClick={() => this.handleEditGetcareCategory({category})} className={classes.PageCardListItemActionsEdit} style={{ padding: '0' }} size="small" edge="end" aria-label="edit">
                                                <EditIcon fontSize="small" />
                                              </IconButton>
                                            }
                                            {
                                              isDeleteAllowed(category.is_delete === 0) &&
                                              <IconButton onClick={() => this.handleRemoveGetcareCategory({category})} className={classes.PageCardListItemActionsRemove} style={{ padding: '0' }} size="small" edge="end" aria-label="delete">
                                                <HighlightOffIcon fontSize="small" />
                                              </IconButton>
                                            }
                                          </div>
                                        </ListItemSecondaryAction>
                                      </ListItem>
                                    )
                                  })
                                }
                              </List>
                            </div>
                          </div>
                        </Grid>
                      )
                    })
                  }
                </Grid>
              </div>
            </div>
          </div>
        </div>
        {
          getcareCategoryFormDialogOpen &&
          <GetcareCategoryFormDialog
            getcareCategory={getcareCategoryDetail}
            action={getcareCategoryFormDialogAction}
            isOpen={getcareCategoryFormDialogOpen}
            onClose={this.handleCloseGetcareCategoryFormDialog}
            onSubmitSuccess={this.handleSubmitSuccessGetcareCategory}
          />
        }
        { confirmDialogOpen &&
          <ConfirmationDialog
            isOpen={confirmDialogOpen}
            isLoading={confirmDialogLoading}
            title={confirmDialogTitle}
            message={confirmDialogMessage}
            onClose={this.handleCloseConfirmDialog}
            type="info"
            dialogAction={confirmDialogActions}
          />
        }
      </React.Fragment>
    );
  }
}

GetcareCategory.propTypes = {
  getcareCategoryList: PropTypes.array,
  isLoadingGetcareCategory: PropTypes.bool,
  getcareCategoryAllowedActions: PropTypes.array,
}

GetcareCategory.defaultProps = {
  getcareCategoryList: [],
  isLoadingGetcareCategory: true,
  getcareCategoryAllowedActions: [],
}

const mapStateToProps = createStructuredSelector({
  getcareCategoryList: makeSelectGetcareCategoryList(),
  isLoadingGetcareCategory: makeSelectIsLoading(),
  getcareCategoryAllowedActions: makeSelectGetcareCategoryAllowedActions(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getGetcareCategoryList: (payload) => dispatch(getGetcareCategoryList(payload)),
    saveGetcareCategoryAllowedActions: (payload) => dispatch(saveGetcareCategoryAllowedActions(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(GetcareCategory);