import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import addMonths from 'date-fns/addMonths';
import isSameDay from 'date-fns/isSameDay';
import isAfter from 'date-fns/isAfter';
import { validDate } from 'utils/helper';
import vendorApi from 'utils/api/admin/vendorApi';
import { dateFormat } from 'utils/constanst/dateConstants';
import { approvalStatusMap, APPROVE, UNAPPROVE } from 'utils/constanst/adminVendorConstants';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from "reselect";
import { makeSelectLoginUser } from 'redux/selectors';

import classes from './VendorApprovalStatus.module.scss';
import { TextField } from '@material-ui/core';

class VendorApprovalStatus extends PureComponent {
  constructor(props) {
    super(props);

    this.defautApprovalParams = {
      status: props.approval,
      note: '',
      expiry_date: addMonths(new Date(), 3).toISOString(),
    };
    this.defaultApprovalInfo = {
      approval: UNAPPROVE,
      note: '',
      approved_by: '',
      approved_at: '',
      expiry_date: '',
    };

    this.state = {
      approvalInfo: {
        ...this.defaultApprovalInfo,
      },
      approvalParams: { ...this.defautApprovalParams },
      isLoading: false,
      isDialogOpen: false,
      isDialogLoading: false,
    };
  }

  componentDidMount() {
    if (this.props.vendorId) {
      this.loadData();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { vendorId } = this.props;
    if (vendorId && vendorId !== prevProps.vendorId) {
      this.loadData();
    }
  }

  loadData = async () => {
    this.setState({
      isLoading: true,
    });
    const { data: response } = await vendorApi.getApprovalInfo({
      id: this.props.vendorId,
    });
    this.setState({
      isLoading: false,
    });
    if (!response?.result) return;
    const info = response.data && response.data.length && response.data[0];
    this.setState({
      approvalInfo: info ? {
        ...this.state.approvalInfo,
        note: info.note,
        approved_by: info.getcare_user?.name,
        approved_at: info.created_at,
        expiry_date: info.expiry_date,
        approval: APPROVE,
      } : {
        ...this.defaultApprovalInfo ,
        approval: UNAPPROVE,
      },
    });
  };
  isExpiryDateValid = () => {
    const { approvalParams } = this.state;
    const date = validDate(approvalParams?.expiry_date);
    if (!date) return false;
    if (isSameDay(date, new Date())) return true;
    return isAfter(date, new Date());
  }

  handleDialogOpen = () => {
    this.setState({
      isDialogOpen: true,
    });
  };

  handleDialogClose = () => {
    this.setState({
      isDialogOpen: false,
      approvalParams: { ...this.defautApprovalParams },
    });
  };

  handleSubmitApproval = async () => {
    this.setState({
      isDialogLoading: true,
    });
    const { approvalParams } = this.state;
    const { data: response } = await vendorApi.saveApprovalInfo({
      id: this.props.vendorId,
      params: {
        getcare_vendor_id: this.props.vendorId,
        note: approvalParams.note,
        expiry_date: approvalParams.expiry_date,
      },
    });
    this.setState({
      isDialogLoading: false,
      isDialogOpen: false,
    });
    if (response?.result) {
      this.setState({
        approvalInfo: {
          ...this.state.approvalInfo,
          note: response.data.note,
          approved_by: this.props.user.name,
          approved_at: response.data.created_at,
          expiry_date: response.data.expiry_date,
          approval: APPROVE,
        },
        approvalParams: { ...this.defautApprovalParams },
      });
    }
  };

  handleApprovalExpireDateChange = (date) => {
    this.setState({
      approvalParams: {
        ...this.state.approvalParams,
        expiry_date: validDate(date) ? date.toISOString() : '',
      },
    });
  };

  handleApprovalNoteChange = (e) => {
    this.setState({
      approvalParams: {
        ...this.state.approvalParams,
        note: e.target.value,
      },
    });
  };

  render() {
    const {
      approvalInfo,
      isLoading,
      isDialogLoading,
      isDialogOpen,
      approvalParams,
    } = this.state;

    return (
      <div className={`${classes.Wrap} ${isLoading && 'OverlayLoading'}`}>
        <div>
          <div className={classes.Info}>
            <label>Tình trạng duyệt</label>
            {approvalStatusMap[approvalInfo.approval]}
          </div>
          {approvalInfo.approval === UNAPPROVE && (
            <Button
              className={classes.ApproveBtn}
              variant="contained"
              color="primary"
              onClick={this.handleDialogOpen}
            >
              Duyệt
            </Button>
          )}
        </div>
        {approvalInfo.approval === APPROVE && (
          <>
            <div>
              <div className={classes.Info}>
                <label>Người duyệt</label>
                {approvalInfo.approved_by}
              </div>
              <div className={classes.Info}>
                <label>Ngày duyệt</label>
                {validDate(approvalInfo?.approved_at)
                  ? format(validDate(approvalInfo?.approved_at), dateFormat)
                  : ''}
              </div>
              <div className={classes.Info}>
                <label>Ngày hết hạn</label>
                {validDate(approvalInfo?.expiry_date)
                  ? format(validDate(approvalInfo?.expiry_date), dateFormat)
                  : ''}
              </div>
            </div>
            <div>
              <div className={`${classes.Info} ${classes.Note}`}>
                <label>Ghi chú</label>
                {approvalInfo.note}
              </div>
            </div>
          </>
        )}
        <Dialog
          open={isDialogOpen}
          onClose={this.handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.Dialog}
        >
          <div className={`${isDialogLoading && 'OverlayLoading'}`}>
            <DialogTitle className={classes.DialogTitle}>
              Xác nhận duyệt nhà cung cấp
            </DialogTitle>
            <DialogContent className={classes.DialogContent}>
              <p className={classes.FormIntro}>Tôi, nhân viên <strong>{this.props.user.name}</strong> xác nhận duyệt nhà cung cấp <strong>{this.props.vendorName}</strong> do đã cung cấp đầy đủ văn bản liên quan và đạt đủ các yếu tố quy định của Get Care.</p>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <div className={classes.FormControl}>
                  <label>Ngày hết hạn</label>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      disablePast
                      autoOk
                      variant="inline"
                      format={dateFormat}
                      id="date-picker-inline"
                      value={validDate(approvalParams?.expiry_date) || ``}
                      onChange={this.handleApprovalExpireDateChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <div className={classes.FormControl}>
                  <label>Ghi chú</label>
                  <TextField
                    rowsMax={3}
                    multiline
                    value={approvalParams.note}
                    placeholder="Nhập..."
                    autoFocus={true}
                    name="note"
                    onChange={this.handleApprovalNoteChange}
                  />
                </div>
              </form>
            </DialogContent>
            <DialogActions className={classes.DialogFooter}>
              <Button onClick={this.handleDialogClose} variant="contained">
                Huỷ
              </Button>
              <Button
                onClick={this.handleSubmitApproval}
                variant="contained"
                color="primary"
                autoFocus
                disabled={!this.isExpiryDateValid()}
              >
                Xác nhận
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      </div>
    );
  }
}

VendorApprovalStatus.propTypes = {
  vendorId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  approval: PropTypes.number,
  vendorName: PropTypes.string,
};

VendorApprovalStatus.defaultProps = {
  vendorName: '',
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectLoginUser(),
});
const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(VendorApprovalStatus);
