import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import gridClasses from '../SalesTeamListGrid.module.scss';
import classes from './SalesTeamListItem.module.scss';

class SalesTeamListItem extends PureComponent {
  goToDetails = (e) => {
    this.props.history.push(`/salesteam/${this.props.id}`);
  };
  getSalesChannels = () => {
    const { sales_channel_teams } = this.props;
    return sales_channel_teams?.length === 1 ? (
      sales_channel_teams[0]?.sales_channel?.code || ''
    ) : (
      <span className="Badge">{sales_channel_teams?.length || 0}</span>
    );
  };

  render() {
    const {
      id,
      code,
      name,
      rsm_names,
      asm_names,
      sales_sup_names,
      sales_names,
      key_accounts,
    } = this.props;

    return (
      <Link
        className={`${classes.ProductItem} ${gridClasses.Row}`}
        to={`/salesteam/${id}`}
      >
        <div className={gridClasses.Col}>{code}</div>
        <div className={gridClasses.Col}>{name}</div>
        <div className={gridClasses.Col}>{rsm_names?.join('\n')}</div>
        <div className={gridClasses.Col}>{asm_names?.join('\n')}</div>
        <div className={gridClasses.Col}>{sales_sup_names?.join('\n')}</div>
        <div className={gridClasses.Col}>{sales_names?.join('\n')}</div>
        <div className={gridClasses.Col}>{this.getSalesChannels()}</div>
        <div className={gridClasses.Col}>
          <span className="Badge">
            {key_accounts ? key_accounts.length : 0}
          </span>
        </div>
      </Link>
    );
  }
}

SalesTeamListItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  code: PropTypes.string,
  name: PropTypes.string,
  rsm_names: PropTypes.array,
  asm_names: PropTypes.array,
  sales_sup_names: PropTypes.array,
  sales_names: PropTypes.array,
  sales_channel_teams: PropTypes.array,
  key_accounts: PropTypes.array,
};

SalesTeamListItem.defaultProps = {
  key_accounts: [],
  sales_channel_teams: [],
};

export default withRouter(SalesTeamListItem);
