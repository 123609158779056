import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { currencyFormatVN, validNumber } from 'utils/helper';
import { isEqual} from 'lodash';

import Checkbox from '@material-ui/core/Checkbox';
import EditOutlined from '@material-ui/icons/EditOutlined';
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import NumberFormatCustom from 'components/NumberFormatCustom/NumberFormatCustom';
import FieldEditable from 'components/FieldEditable/FieldEditable';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import gridClasses from '../ShippingCouponRulesGrid.module.scss';
import classes from './ShippingCouponRule.module.scss';

class ShippingCouponRule extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: props.isEditing,
      params: this._getInitParams(props),
    };
  }
  componentDidUpdate(prevProps, prevState) {
    const { isEditing, params } = this.state;
    if (isEditing !== prevState.isEditing && this._isRowValid(params)) {
      // after clicking save button
      const { index, currentList } = this.props;
      // if editing the amount_gt of the item that not the first one
      if (index > 0 && params.amount_gt !== this.props.amount_gt) {
        // emit the event to update amount_lt of previous row
        this.props.saveShippingCouponRuleAmountTo({
          index: index - 1,
          amount_lt: params.amount_gt,
        });
      }
      // if editing the amount_lt of the item that not the last one
      if (index < currentList.length - 1 && params.amount_lt < this.props.amount_lt) {
        // emit the event to insert the new row
        this.props.insertNewShippingCouponRule({
          index: index + 1,
          amount_gt: params.amount_lt,
          amount_lt: this.props.amount_lt,
        });
      }
      // if editing the amount_lt of last item and make it is not null or undefined
      if (index === currentList.length - 1 && params.amount_lt !== this.props.amount_lt && ![null, undefined].includes(params.amount_lt)) {
        // emit the event to insert the new row
        this.props.saveNewShippingCouponRule({
          amount_gt: params.amount_lt,
        });
      }

      this.props.saveShippingCouponRule({
        ...params,
        idStr: this.props.idStr,
        id: this.props.id,
        isEditing,
      });
    }

    const propsParams = this._getInitParams(this.props);
    const prevPropsParams = this._getInitParams(prevProps);
    if (!isEqual(propsParams, prevPropsParams)) {
      // if props were updated by updating other rows
      this.setState({
        params: { ...propsParams },
      });
    }
  }

  _getInitParams = (params) => {
    return {
      description: params.description,
      amount_gt: params.amount_gt,
      amount_lt: params.amount_lt,
      discount_amount: params.discount_amount,
      freeship: params.freeship,
    }
  }
  _resetState = () => {
    this.setState({
      isEditing: false,
      params: this._getInitParams(this.props),
    });
  }

  _isDescriptionValid = (params) => {
    if (!params) return false;
    return params.description && params.description.trim() !== '';
  }
  _isAmountFromValid = (params) => {
    if (!params) return false;
    const { amount_gt, index } = this.props;
    const min = index === 0 ? 0 : Number(amount_gt);
    return [null, undefined].includes(params.amount_lt)
      ? !isNaN(params.amount_gt)
      : !isNaN(params.amount_gt) && min <= Number(params.amount_gt) && Number(params.amount_lt) > Number(params.amount_gt);
  }
  _isAmountToValid = (params) => {
    if (!params) return false;
    const { amount_lt } = this.props;
    return [null, undefined].includes(params.amount_lt)
      || [null, undefined].includes(amount_lt)
      || (!isNaN(params.amount_lt) && Number(amount_lt) >= Number(params.amount_lt) && Number(params.amount_lt) > Number(params.amount_gt));
  }
  _isDiscountAmountValid = (params) => {
    if (!params) return false;
    return params.freeship
      ? [null, undefined, ''].includes(params.discount_amount)
      : Number(params.discount_amount) > 0;
  }
  _isRowValid = (params) => {
    if (!params) return false;
    return this._isDescriptionValid(params)
      && this._isAmountFromValid(params)
      && this._isAmountToValid(params)
      && this._isDiscountAmountValid(params);
  };

  handleSaveField = (e, value) => {
    let newValue = value;
    if (e.target.type === 'number') {
      newValue = validNumber(value);
    }
    this.setState({
      params: {
        ...this.state.params,
        [e.target.name]: newValue,
      },
    });
  }
  handleEdit = (e) => {
    if (e) e.stopPropagation();
    this.setState({
      isEditing: true,
    });
  }
  handleCancel = (e) => {
    if (e) e.stopPropagation();
    if (this.props.id) {
      // if it was saved to DB or it was used to save on client successful
      this._resetState();
      return;
    }
  }
  handleRemove = (e) => {
    if (e) e.stopPropagation();
    this.props.removeShippingCouponRule({
      id: this.props.id,
      idStr: this.props.idStr,
      index: this.props.index,
      amount_lt: this.props.amount_lt,
    });
  }
  handleSave = (e) => {
    if (e) e.stopPropagation();
    this.setState({
      isEditing: false,
    });
  }
  handleRowClick = () => {
    if (!this.state.isEditing) {
      this.handleEdit();
    }
  }
  handleRowBlur = () => {
    if (this.state.isEditing && this._isRowValid()) {
      this.handleSave();
    }
  }
  handleFreeshipCheckboxChange = (e) => {
    this.setState({
      params: {
        ...this.state.params,
        freeship: e.target.checked,
        discount_amount: e.target.checked ? null : this.state.params.discount_amount,
      },
    });
  }

  render() {
    const {
      description,
      amount_gt,
      amount_lt,
      discount_amount,
      freeship,
      index,
      currentList,
    } = this.props;
    const {
      isEditing,
      params,
    } = this.state;
    const isOnlyItem = currentList.length === 1;

    return (
      <ClickAwayListener onClickAway={this.handleRowBlur}>
        <div
          className={`${classes.Item} ${gridClasses.Row}`}
          onClick={this.handleRowClick}
        >
          <div className={gridClasses.Col}>{`L${index + 1}`}</div>
          <div className={gridClasses.Col}>
            { isEditing ? <FieldEditable
                autoFocus
                editModeOnly
                fieldName="description"
                fieldType="textarea"
                error={!this._isDescriptionValid(params)}
                maxLength={255}
                value={params.description}
                className={classes.Field}
                onChange={(e, newValue) => {
                  this.handleSaveField(e, newValue);
                }}
              /> : <>{ description }</>
            }
          </div>
          <div className={`${gridClasses.Col} TextRight`}>
            { isEditing ? <TextField
                autoComplete="off"
                fullWidth
                className="InputTextRight"
                value={params.amount_gt}
                name="amount_gt"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                error={!this._isAmountFromValid(params)}
                onChange={(e) => {
                  this.handleSaveField(e, e.target.floatValue);
                }}
              /> : <>{ ![null, undefined].includes(amount_gt) ? currencyFormatVN(amount_gt) : '' }</>
            }
          </div>
          <div className={`${gridClasses.Col} TextRight`}>
            { isEditing ? <TextField
                autoComplete="off"
                fullWidth
                className="InputTextRight"
                value={params.amount_lt}
                name="amount_lt"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                error={!this._isAmountToValid(params)}
                onChange={(e) => {
                  this.handleSaveField(e, e.target.floatValue);
                }}
              /> : <>{ ![null, undefined].includes(amount_lt) ? currencyFormatVN(amount_lt) : '-' }</>
            }
          </div>
          <div className={`${gridClasses.Col} TextRight`}>
            { isEditing ? <TextField
                className="InputTextRight"
                fullWidth
                autoComplete="off"
                disabled={params.freeship}
                value={params.discount_amount}
                name="discount_amount"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                error={!this._isDiscountAmountValid(params)}
                onChange={(e) => {
                  this.handleSaveField(e, e.target.floatValue);
                }}
              /> : <>{ ![null, undefined].includes(discount_amount) ? currencyFormatVN(discount_amount) : '' }</>
            }
          </div>
          <div className={`${gridClasses.Col} TextCenter`}>
            {isEditing ? (
              <Checkbox
                size="small"
                name="freeship"
                checked={params.freeship}
                onChange={this.handleFreeshipCheckboxChange}
              />
            ) : (
              freeship ? <CheckBoxIcon fontSize="small"/> : <CheckBoxOutlineBlankIcon fontSize="small"/>
            )}
          </div>

          <div className={`${gridClasses.Col} ${classes.ActionsCol} TextCenter`}>
            {isEditing ? (
              <>
                <IconButton
                  onClick={this.handleCancel}
                  disabled={!this.props.id}
                  style={{ padding: '0' }}
                  size="small"
                >
                  <CancelOutlined
                    fontSize="small"
                    color={this.props.id ? `secondary` : `inherit`}
                  />
                </IconButton>
                <IconButton
                  onClick={this.handleSave}
                  disabled={!this._isRowValid(params)}
                  style={{ padding: '0' }}
                  size="small"
                >
                  <CheckCircleOutlineOutlined
                    fontSize="small"
                    color={this._isRowValid(params) ? `primary` : `inherit`}
                  />
                </IconButton>
              </>
            ) : (
              <>
                <IconButton
                  onClick={this.handleEdit}
                  style={{ padding: '0' }}
                  size="small"
                >
                  <EditOutlined fontSize="small" color="primary" />
                </IconButton>
                <IconButton
                  disabled={isOnlyItem}
                  onClick={this.handleRemove}
                  style={{ padding: '0' }}
                  size="small"
                >
                  <DeleteOutline fontSize="small" />
                </IconButton>
              </>
            )}
          </div>
        </div>
      </ClickAwayListener>
    );
  }
}

ShippingCouponRule.propTypes = {
  idStr: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  index: PropTypes.number,
  saveShippingCouponRule: PropTypes.func,
  saveShippingCouponRuleAmountTo: PropTypes.func,
  insertNewShippingCouponRule: PropTypes.func,
  saveNewShippingCouponRule: PropTypes.func,
  removeShippingCouponRule: PropTypes.func,
};

ShippingCouponRule.defaultProps = {
};

const mapStateToProps = createStructuredSelector({
});
const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(ShippingCouponRule);
