import ingredientApi from 'utils/api/admin/ingredientApi';
import { put } from 'redux-saga/effects';
import { ingredientActions } from 'redux/actions';
import { toast } from 'react-toastify';

export function* loadIngredientList(payload) {
  const { params } = payload.payload;
  try {
    yield put(ingredientActions.saveIngredientList(null));

    const { data: response } = yield ingredientApi.getAll({ params });

    if (!response?.result) {
      yield put(ingredientActions.saveIngredientList([]));
      yield put(ingredientActions.saveIngredientTotal(0));
      toast.error(response.message);
      return;
    }
    yield put(ingredientActions.saveIngredientList(response.data ? [ ...response.data] : []));
    yield put(ingredientActions.saveIngredientTotal(response.total_records));
  } catch (err) {
    console.log(err);
  }
}

export function* loadQueueIngredientList(payload) {
  const { params } = payload.payload;
  try {
    yield put(ingredientActions.saveActionLoading(true));

    const { data: response } = yield ingredientApi.getQueueIngredients({ params });

    yield put(ingredientActions.saveActionLoading(false));
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(ingredientActions.saveQueueIngredientList(response.data ? [ ...response.data] : []));
    yield put(ingredientActions.saveQueueIngredientTotal(response.total_records));
  } catch (err) {
    console.log(err);
  }
}

export function* updateQueueIngredientItem(payload) {
  const { id, params } = payload.payload;
  try {
    const { data: response } = yield ingredientApi.updateIngredient({ id, params });
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(ingredientActions.saveQueueIngredientItem({...params, id: id}));
  } catch (err) {
    console.log(err);
  }
}

export function* approveQueueIngredientItem(payload) {
  const { id, params } = payload.payload;
  try {
    yield put(ingredientActions.saveActionLoading(true));

    const { data: response } = yield ingredientApi.approveIngredient({ id, params });

    yield put(ingredientActions.saveActionLoading(false));
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(ingredientActions.removeQueueIngredientItem({...params, id: id}));
  } catch (err) {
    console.log(err);
  }
}

export function* deleteQueueIngredientItem(payload) {
  const { id, params } = payload.payload;
  try {
    yield put(ingredientActions.saveActionLoading(true));

    const { data: response } = yield ingredientApi.deleteIngredient({ id, params });

    yield put(ingredientActions.saveActionLoading(false));
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(ingredientActions.removeQueueIngredientItem({...params, id: id}));
  } catch (err) {
    console.log(err);
  }
}
