/* list */
export const listParamsMap = {
  page_size: 50,
  page: 1,
  order: '',
};
export const listDisplayFields = [
  {
    name: 'getcare_sales_name',
    label: 'Trình dược viên',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'getcare_vendor_name',
    label: 'Nhà cung cấp',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'getcare_product_name',
    label: 'Sản phẩm',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'getcare_customer_name',
    label: 'Khách hàng',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'start_date',
    label: 'Ngày hiệu lực',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'left_count_days',
    label: 'Số ngày còn lại',
    sortable: true,
    sortDir: '',
    className: 'TextCenter',
  },
];
export const filterFields = [
  {
    name: 'getcare_sales_name',
    label: 'Trình dược viên',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'getcare_vendor_name',
    label: 'Nhà cung cấp',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'getcare_product_name',
    label: 'Sản phẩm',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'getcare_customer_name',
    label: 'Nhà thuốc',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'start_date',
    label: 'Ngày hiệu lực',
    value: ['', ''],
    type: 'custom',
    placeholder: 'Nhập hoặc chọn...',
  },
  {
    name: 'left_count_days',
    label: 'Số ngày còn lại',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
];
