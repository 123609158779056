import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { debounce } from 'lodash';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectUserLoading,
  makeSelectUserList,
  makeSelectUserListTotal,
  makeSelectDepartmentList,
} from 'redux/selectors';
import { withRouter } from 'react-router-dom';
import { getSortsString, getDisplayFields } from 'utils/helper';
import { allInPageSize } from 'utils/constanst/common';
import eventBus from 'eventBus';

import {
  listParamsMap,
  listDisplayFields,
} from 'utils/constanst/adminUserConstants';
import {
  getUserList,
  updateUser,
  saveUserListItem,
  getDepartmentList,
} from 'redux/actions/admin/userActions';
import { userStatusMap } from 'utils/constanst/adminUserConstants';

import UserList from 'components/admin/UserList/UserList';
import UserListFilters from 'components/admin/UserList/UserListFilters/UserListFilters';
import ListPagination from 'components/ListPagination/ListPagination';
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';

import classes from './Users.module.scss';

class Users extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      listParams: { ...listParamsMap },

      isConfirmationOpen: false,

      selectedUserParams: undefined,
    };
  }
  componentDidMount() {
    eventBus.on('onApproveChange',(data) => {
      this.handleApprovalChange(data)
    })
    this._loadDepartmentList();

    const { listParams } = this.state;
    this.props.getUserList({
      params: listParams,
    });
  }
  componentWillUnmount() {
    eventBus.remove("onApproveChange");
  }

  _loadDepartmentList = () => {
    this.props.getDepartmentList({
      params: { page_size: allInPageSize },
    });
  }

  handleSortChange = ({ sortBy, sortDir }) => {
    const newSortString = getSortsString({
      [sortBy]: sortDir,
    });
    this.handleFilterChange([
      {
        name: 'order',
        value: newSortString,
      },
    ]);
  };
  handleFilterChange = (filters, forceResetPage = false) => {
    const paramsFromFilters = filters.reduce((memo, item) => {
      memo[item.name] = item.value;
      return memo;
    }, {});
    const newParams = forceResetPage ? {
      ...this.state.listParams,
      ...paramsFromFilters,
      page: 1,
    } : {
      ...this.state.listParams,
      ...paramsFromFilters,
    };
    this.setState({
      listParams: newParams,
    });
    this.props.getUserList({
      params: newParams,
    });
  };
  handleStatusChange = ({ id, name, status }) => {
    this.setState({
      selectedUserParams: { id, name, status },
    });
    this.handleOpenConfirmation();
  }
  handleUpdateUserStatus = () => {
    const { selectedUserParams } = this.state;
    this.props.saveUserListItem(selectedUserParams);
    this.props.updateUser({
      params: {
        id: selectedUserParams.id,
        status: selectedUserParams.status,
      }
    });
    this.handleCloseConfirmation();
  }

  handleOpenConfirmation = (e) => {
    if (e) e.stopPropagation();
    this.setState({
      isConfirmationOpen: true,
    });
  }
  handleCloseConfirmation = () => {
    this.setState({
      isConfirmationOpen: false,
    });
    this.setState({
      selectedUserParams: undefined,
    });
  }
  handleApprovalChange = async ({ id, approval }) => {
    await this.props.updateUser({
      params: {
        id: id,
        approval: approval,
      }
    });
    console.log("done")
    this.props.getUserList({
      params: this.state.listParams,
    });
  };

  createNewUser = () => {
    this.props.history.push(`/user/new`);
  }

  render() {
    const { userList, userListTotal, loading } = this.props;
    const { listParams, selectedUserParams } = this.state;
    const isListLoading = loading;

    return (
      <React.Fragment>
        <div className={classes.PageWrap}>
          <div className={classes.PageHeader}>
            <h1 className={classes.PageTitle}>Danh sách nhân viên</h1>
            <Button
              size="small"
              variant="contained"
              color="primary"
              startIcon={<Add />}
              onClick={this.createNewUser}
            >Tạo mới</Button>
          </div>
          <div className={classes.PageMain}>
            <UserListFilters
              departmentList={[...this.props.departmentList]}
              onFilterChange={debounce(this.handleFilterChange, 500)}
            />
            <UserList
              displayFields={getDisplayFields(
                listParams,
                listDisplayFields
              )}
              userList={userList}
              isLoading={isListLoading}
              onSortChange={this.handleSortChange}
              onStatusChange={this.handleStatusChange}
            />
          </div>
          <div className={classes.PageFooter}>
            <ListPagination
              page={listParams.page}
              pageSize={listParams.page_size}
              total={userListTotal}
              listName="nhân viên"
              onFilterChange={this.handleFilterChange}
            />
          </div>
        </div>

        { this.state.isConfirmationOpen &&
          <ConfirmationDialog
            isOpen={this.state.isConfirmationOpen}
            title="Xác nhận thay đổi trạng thái"
            message={(<p>{`Thay đổi trạng thái nhân viên `} <strong>{selectedUserParams?.name}</strong>{` thành ${userStatusMap[selectedUserParams?.status]}`}.</p>)}
            onClose={this.handleCloseConfirmation}
            onSubmit={this.handleUpdateUserStatus}
          />
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userList: makeSelectUserList(),
  userListTotal: makeSelectUserListTotal(),
  loading: makeSelectUserLoading(),
  departmentList: makeSelectDepartmentList(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getUserList: (payload) => dispatch(getUserList(payload)),
    updateUser: async (payload) => await Promise.resolve(dispatch(updateUser(payload))),
    saveUserListItem: (payload) => dispatch(saveUserListItem(payload)),
    getDepartmentList: (payload) => dispatch(getDepartmentList(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter)(Users);
