import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { updateVendor } from 'redux/actions/admin/vendorActions';
import format from 'date-fns/format';
import { validDate } from 'utils/helper';
import {
  activeStatusMap,
  DEACTIVE,
} from 'utils/constanst/adminVendorConstants';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { dateFormat } from 'utils/constanst/dateConstants';
import { debounce } from 'lodash';
import locationApi from 'utils/api/locationApi';
import { VN_ID } from 'utils/constanst/locationConstants';
import { toast } from 'react-toastify';

import FieldEditable from 'components/FieldEditable/FieldEditable';
import Button from '@material-ui/core/Button';
import { Tabs, Tab } from '@material-ui/core';
import VendorDocuments from '../VendorDocuments/VendorDocuments';
import VendorApprovalStatus from '../VendorApprovalStatus/VendorApprovalStatus';
import DeactiveCustomerDialog from '../../StoreDetails/DeactiveCustomerDialog/DeactiveCustomerDialog';

import classes from './VendorInfo.module.scss';

class VendorInfo extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabActive: 0,
      provinces: [],
      districts: [],
      wards: [],
      isOpen: false,
      selectedProvince: props.getcare_province,
      selectedDistrict: props.getcare_district,
      selectedWard: props.getcare_ward,
    };
  }
  componentDidMount() {
    const { getcare_province, getcare_district, getcare_ward } = this.props;
    this.loadLocation({ getcare_province, getcare_district, getcare_ward });
  }
  componentDidUpdate(prevProps, prevState) {
    const { selectedDistrict, selectedProvince, selectedCountry } = this.state;
    if (selectedCountry && selectedCountry !== prevState.selectedCountry) {
      this.loadProvinces({ name: '', getcare_country_id: VN_ID });
    }
    if (selectedProvince && selectedProvince !== prevState.selectedProvince) {
      this.loadDistricts({ name: '', getcare_province_id: selectedProvince?.id });
    }
    if (selectedDistrict && selectedDistrict !== prevState.selectedDistrict) {
      this.loadWards({ name: '', getcare_district_id: selectedDistrict?.id });
    }

    const { getcare_country, getcare_province, getcare_district, getcare_ward, address } = this.props;
    if (getcare_country !== prevProps.getcare_country) {
      this.setState({ selectedCountry: {...getcare_country} });
    }
    if (getcare_province !== prevProps.getcare_province) {
      this.setState({ selectedProvince: {...getcare_province} });
    }
    if (getcare_district !== prevProps.getcare_district) {
      this.setState({ selectedDistrict: {...getcare_district} });
    }
    if (getcare_ward !== prevProps.getcare_ward) {
      this.setState({ selectedWard: {...getcare_ward} });
    }
    if (address !== prevProps.address) {
      this.setState({ address: address });
    }
  };
  handleTabChange = (e, value) => {
    this.setState({
      tabActive: value,
    });
  };
  handleSaveField = (fieldMap) => {
    this.props.updateVendor({
      id: this.props.id,
      params: { ...fieldMap },
    });
  };
  handleSaveLocationField = ({getcare_country, getcare_province, getcare_district, getcare_ward, address}) => {
    this.props.updateVendor({
      id: this.props.id,
      params: {
        getcare_country_id: getcare_country?.id || undefined,
        getcare_province_id: getcare_province?.id || undefined,
        getcare_district_id: getcare_district?.id || undefined,
        getcare_ward_id: getcare_ward?.id || undefined,
        registration_address: address,
      },
    });
  }

  handleClose = () => {
    this.setState({
      isOpen: false
    })
  }

  handleOpen = () => {
    this.setState({
      isOpen: true
    })
  }


  loadLocation = ({getcare_province, getcare_district, getcare_ward, ...rest}) => {
    this.loadProvinces({ name: '', getcare_country_id: VN_ID });
    this.loadDistricts({ name: '', getcare_province_id: getcare_province?.id });
    this.loadWards({ name: '', getcare_district_id: getcare_district?.id });
  };

  loadProvinces = debounce(async ({ name, getcare_country_id }) => {
    const { data: response } = await locationApi.getProvinces({ name, getcare_country_id });
    if (!response?.result) return;
    this.setState({ provinces: [...response.data] });
  }, 500);

  loadDistricts = debounce(async ({ name, getcare_province_id }) => {
    const { data: response } = await locationApi.getDistricts({ name, getcare_province_id });
    if (!response?.result) return;
    this.setState({ districts: [...response.data] });
    const selectedItem = response.data.find(
      (item) => item.id === this.state.selectedDistrict?.id
    );
    if (!selectedItem) {
      this.setState({ selectedDistrict: null, selectedWard: null });
    }
  }, 500);

  loadWards = debounce(async ({ name, getcare_district_id }) => {
    const { data: response } = await locationApi.getWards({ name, getcare_district_id });
    if (!response?.result) return;
    this.setState({ wards: [...response.data] });
    const selectedItem = response.data.find(
      (item) => item.id === this.state.selectedWard?.id
    );
    if (!selectedItem) {
      this.setState({ selectedWard: null });
    }
  }, 500);

  handleFieldChange = async (e) => {
    const fieldsMap = {
      [e.target.name]: e.target.checked ? true : false,
    };
    this.props.updateVendor({
      id: this.props.id,
      params: { ...fieldsMap },
    });
    // const { data: response } = await productApi.updateProductsTrend({
    //   params: {
    //     ...fieldsMap,
    //     getcare_product_ids: [productDetails?.id],
    //   },
    // });
    // if (!response.result) {
    //   toast.error(response.message);
    //   return;
    // }
    // this.props.saveProduct({
    //   ...cloneDeep(productDetails),
    //   ...fieldsMap,
    // });
  }

  render() {
    const {
      active,
      code,
      name,
      phone,
      registration_address,
      email,
      registration_company,
      tax_code,
      created_at,
      updated_at,
      note,
      getcare_vendor_type,
      longitude,
      latitude,
      deactive_date,
      approval,
      delivery_by_getcare,
      getcare_province,
      getcare_district,
      getcare_ward,
      getcare_province_id,
      getcare_district_id,
      getcare_ward_id,
      use_getcare_system,
    } = this.props;
    const {isOpen} = this.state;

    return (
      <>
        <div className={classes.Wrap}>
          <div className={classes.ColInner}>
            <div className={classes.Info}>
              <label>Trạng thái</label>
              {activeStatusMap[active]}
            </div>
            <div className={classes.Info}>
              <label>Mã nhà cung cấp</label>
              {code}
            </div>
            <div className={classes.Info}>
              <label>Tên nhà cung cấp</label>
              <FieldEditable
                fieldName="name"
                value={name || ''}
                onChange={() => {
                  return;
                }}
                onSave={this.handleSaveField}
              />
            </div>
            <div className={classes.Info}>
              <label>Địa chỉ</label>
              <FieldEditable
                fieldName="registration_address"
                value={registration_address || ''}
                onChange={() => {
                  return;
                }}
                onSave={this.handleSaveField}
              />
            </div>
            <div className={classes.Info}>
              <label>Tỉnh/TP</label>
              <FieldEditable
                fieldName="getcare_province_id"
                value={getcare_province_id || ''}
                displayedValue={getcare_province?.name}
                fieldType="select"
                options={this.state.provinces}
                onSave={this.handleSaveField}
                onChange={() => {
                  return;
                }}
              />
            </div>
            <div className={classes.Info}>
              <label>Quận/Huyện</label>
              <FieldEditable
                fieldName="getcare_district_id"
                value={getcare_district_id || ''}
                displayedValue={getcare_district?.name}
                fieldType="select"
                options={this.state.districts}
                onSave={this.handleSaveField}
                onChange={() => {
                  return;
                }}
              />
            </div>
            <div className={classes.Info}>
              <label>Phường/Xã</label>
              <FieldEditable
                fieldName="getcare_ward_id"
                value={getcare_ward_id || ''}
                displayedValue={getcare_ward?.name}
                fieldType="select"
                options={this.state.wards}
                onSave={this.handleSaveField}
                onChange={() => {
                  return;
                }}
              />
            </div>
            <div className={classes.Info}>
              <label>Số điện thoại</label>
              <FieldEditable
                fieldName="phone"
                value={phone || ''}
                onChange={() => {
                  return;
                }}
                onSave={(fieldMap) => this.handleSaveField({
                  phone: (/^(84)/).test(fieldMap.phone) ? fieldMap.phone.replace(/^(84)/, '+84') : fieldMap.phone,
                })}
              />
            </div>
            <div className={classes.Info}>
              <label>Email</label>
              <FieldEditable
                fieldName="email"
                value={email || ''}
                type="email"
                onChange={() => {
                  return;
                }}
                onSave={this.handleSaveField}
              />
            </div>
          </div>
          <div className={classes.ColInner}>
            <div className={classes.Info}>
              <label>Tên doanh nghiệp</label>
              <FieldEditable
                fieldName="registration_company"
                value={registration_company || ''}
                onChange={() => {
                  return;
                }}
                onSave={this.handleSaveField}
              />
            </div>
            <div className={classes.Info}>
              <label>Mã số thuế</label>
              <FieldEditable
                fieldName="tax_code"
                value={tax_code || ''}
                onChange={() => {
                  return;
                }}
                onSave={this.handleSaveField}
              />
            </div>
            <div className={classes.Info}>
              <label>Loại vendor</label>
              {getcare_vendor_type?.name}
            </div>
            <div className={classes.Info}>
              <label>Longitude</label>
              {longitude}
            </div>
            <div className={classes.Info}>
              <label>Latitude</label>
              {latitude}
            </div>
            <div className={classes.Info}>
              <label>Ngày tạo</label>
              {validDate(created_at) ? format(validDate(created_at) , dateFormat) : ''}
            </div>
            <div className={classes.Info}>
              <label>Ngày sửa</label>
              {validDate(updated_at) ? format(validDate(updated_at), dateFormat) : ''}
            </div>
            <div className={`${classes.Info} ${classes.InfoMiddle}`}>
              <label>Ngày dừng hoạt động</label>
              <div>
              {(validDate(deactive_date)) && active === 0  ? <span className={classes.DeactivateDate}>{format(validDate(deactive_date), dateFormat)}</span> : ''}
                            { active !== 0 ?
                              <Button
                                // disabled
                                variant="contained"
                                size="small"
                                color="secondary"
                                onClick={this.handleOpen}
                                style={{ marginLeft: '1rem' }}
                              >
                                Dừng hoạt động
                              </Button>
                              : 
                              <Button
                              disabled
                              variant="contained"
                              size="small"
                              color="secondary"
                              onClick={this.handleOpen}
                              style={{ marginLeft: '1rem' }}
                            >
                              Dừng hoạt động
                            </Button>
                            }
                
              </div>
            </div>

            <div className={`${classes.Info} ${classes.InfoMiddle}`}>
              <label>Phahub giao hàng</label>
              <div>
                <FormControlLabel
                  name="delivery_by_getcare"
                  value="delivery_by_getcare"
                  control={<Checkbox color="secondary" />}
                  labelPlacement="start"
                  checked={delivery_by_getcare === true ?  true : false}
                  onChange={this.handleFieldChange}
                />
              </div>
            </div>
            <div className={`${classes.Info} ${classes.InfoMiddle}`}>
              <label>Sử dụng phần mềm quản lý</label>
              <div>
                <FormControlLabel
                  name="use_getcare_system"
                  value="use_getcare_system"
                  control={<Checkbox color="secondary" />}
                  labelPlacement="start"
                  checked={use_getcare_system === true ?  true : false}
                  onChange={this.handleFieldChange}
                />
              </div>
            </div>

          </div>
          <div className={classes.NoteCol}>
            <div className={`${classes.Info} ${classes.Note}`}>
              <label>Ghi chú</label>
              <FieldEditable
                fieldName="note"
                value={note || ''}
                fieldType="textarea"
                type="textarea"
                onChange={() => {
                  return;
                }}
                onSave={this.handleSaveField}
              />
            </div>
          </div>
        </div>
        { isOpen &&
          <DeactiveCustomerDialog
            isOpen={isOpen}
            id={this.props.id}
            isVendor={true}
            onClose={this.handleClose}
          />
        }
        <Tabs
          className={classes.TabsWrap}
          value={this.state.tabActive}
          onChange={this.handleTabChange}
        >
          <Tab label="Trạng thái duyệt" />
          <Tab label="Tài liệu liên quan" />
        </Tabs>
        <div className={classes.TabsPanel}>
          {this.state.tabActive === 0 && (
            <VendorApprovalStatus
              approval={approval}
              vendorId={this.props.id}
              vendorName={this.props.name}
            />
          )}
          {this.state.tabActive === 1 && (
            <VendorDocuments vendorId={this.props.id} />
          )}
        </div>
      </>
    );
  }
}

VendorInfo.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  active: PropTypes.number,
  code: PropTypes.string,
  name: PropTypes.string,
  phone: PropTypes.string,
  registration_address: PropTypes.string,
  email: PropTypes.string,
  registration_company: PropTypes.string,
  tax_code: PropTypes.string,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
  deactive_date: PropTypes.string,
  note: PropTypes.string,
  getcare_vendor_type: PropTypes.object,
  longitude: PropTypes.number,
  latitude: PropTypes.number,
};

VendorInfo.defaultProps = {};

const mapDispatchToProps = (dispatch) => {
  return {
    updateVendor: (payload) => dispatch(updateVendor(payload)),
  };
};
const withConnect = connect(null, mapDispatchToProps);
export default compose(withConnect)(VendorInfo);
