import salesChannelApi from 'utils/api/admin/salesChannelApi';
import { put } from 'redux-saga/effects';
import { salesChannelActions } from 'redux/actions';
import { toast } from 'react-toastify';

export function* loadSalesChannelList(payload) {
  const { params } = payload.payload;
  try {
    yield put(salesChannelActions.saveSalesChannelList(null));

    const { data: response } = yield salesChannelApi.getAll({ params });

    if (!response?.result) {
      yield put(salesChannelActions.saveSalesChannelList([]));
      yield put(salesChannelActions.saveSalesChannelListTotal(0));
      return;
    }
    yield put(salesChannelActions.saveSalesChannelList(response.data ? [...response.data] : []));
    yield put(salesChannelActions.saveSalesChannelListTotal(response.total_records));
  } catch (err) {
    console.log(err);
  }
}
export function* loadSalesChannel(payload) {
  try {
    yield put(salesChannelActions.saveSalesChannel(null));
    yield put(salesChannelActions.saveSalesChannelCoordinaters([]));

    const { data: response } = yield salesChannelApi.get(payload.id);

    if (!response?.result) {
      yield put(salesChannelActions.saveSalesChannel({}));
      return;
    }
    yield put(salesChannelActions.saveSalesChannel(response.data ? {...response.data} : {}));
    yield put(salesChannelActions.saveSalesChannelCoordinaters(response.data?.getcare_sales_items ? [...response.data.getcare_sales_items] : []));
  } catch (err) {
    console.log(err);
  }
}
export function* putNewSalesChannel(payload) {
  const { params } = payload.payload;
  try {
    yield put(salesChannelActions.saveSalesChannel(null));

    const { data: response } = yield salesChannelApi.create({ params });

    if (!response?.result) {
      yield put(salesChannelActions.saveSalesChannel({}));
      return;
    }

    toast.success(`Lưu khu vực bán hàng thành công.`);

    yield put(salesChannelActions.saveSalesChannel(response.data ? {...response.data} : {}));
  } catch (err) {
    console.log(err);
  }
}
export function* editSalesChannel(payload) {
  const { id, params } = payload.payload;
  try {
    yield put(salesChannelActions.saveSalesChannel(null));

    const { data: response } = yield salesChannelApi.update({ id, params });

    if (!response?.result) {
      yield put(salesChannelActions.saveSalesChannel({}));
      return;
    }

    yield put(salesChannelActions.saveSalesChannel(response.data ? {...response.data} : {}));
  } catch (err) {
    console.log(err);
  }
}

export function* loadSalesChannelHistory(payload) {
  const { params } = payload.payload;
  try {
    yield put(salesChannelActions.saveSalesChannelHistory([]));

    const { data: response } = yield salesChannelApi.getSalesChannelHistory({
      params,
    });

    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(salesChannelActions.saveSalesChannelHistory(response.data ? [...response.data] : []));
  } catch (err) {
    console.log(err);
  }
}
