import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import { statusMap } from 'utils/constanst/adminSalesChannelConstants';
import { Link } from 'react-router-dom';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import IconButton from '@material-ui/core/IconButton';
import SalesChannelDeletingDialog from '../SalesChannelDeletingDialog/SalesChannelDeletingDialog';

import gridClasses from '../SalesChannelListGrid.module.scss';
import classes from './SalesChannelListItem.module.scss';

class SalesChannelListItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isConfirmDialogOpen: false,
    }
  }

  goToDetails = (e) => {
    this.props.history.push(`/saleschannel/${this.props.id}`);
  }

  handleConfirmDialogOpen = (e) => {
    this.setState({
      isConfirmDialogOpen: true,
    });
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
  }
  handleConfirmDialogClose = () => {
    this.setState({
      isConfirmDialogOpen: false,
    });
  }
  handleDeleteSuccess = () => {
    this.handleConfirmDialogClose();
    this.props.onDeleteItemSuccess();
  }

  render() {
    const {
      id,
      code,
      active,
      was_set_up,
      coordinator_names,
    } = this.props;

    const { isConfirmDialogOpen } = this.state;

    return <>
      <Link
        className={`${classes.ProductItem} ${gridClasses.Row}`}
        to={`/saleschannel/${id}`}
      >
        <div className={gridClasses.Col}>{ code }</div>
        <div className={`${gridClasses.Col} PreWrap`}>
          { coordinator_names ? coordinator_names.join(`\n`) : `-` }
        </div>
        <div className={gridClasses.Col}>{ statusMap[active] }</div>
        <div className={`${gridClasses.Col} ${classes.ActionsCol} TextCenter`}>
          <IconButton
            onClick={this.handleConfirmDialogOpen}
            style={{ padding: '3px' }}
            size="small"
          >
            <DeleteOutline fontSize="small" />
          </IconButton>
        </div>
      </Link>

      { isConfirmDialogOpen
        && <SalesChannelDeletingDialog
          id={id}
          code={code}
          wasSetUp={was_set_up}
          isOpen={isConfirmDialogOpen}
          onClose={this.handleConfirmDialogClose}
          onDeleteItemSuccess={this.handleDeleteSuccess}
        />
      }
    </>
  }
}

SalesChannelListItem.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  active: PropTypes.number,
  code: PropTypes.string,
  onDeleteItemSuccess: PropTypes.func,
};

SalesChannelListItem.defaultProps = {
};

export default withRouter(SalesChannelListItem);
