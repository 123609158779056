export const GET_PRODUCT_DETAILS = 'getcare/mdm/product/GET_PRODUCT_DETAILS';
export const GET_PRODUCT_LIST = 'getcare/mdm/product/GET_PRODUCT_LIST';
export const DEACTIVE_PRODUCTS = 'getcare/mdm/product/DEACTIVE_PRODUCTS';
export const GET_MEDICINE_BY_PHARMACY_ID = 'getcare/mdm/product/GET_MEDICINE_BY_PHARMACY_ID';
export const GET_OTHERS_BY_PHARMACY_ID = 'getcare/mdm/product/GET_OTHERS_BY_PHARMACY_ID';
export const GET_AWAITING_PRODUCTS_BY_PHARMACY_ID = 'getcare/mdm/product/GET_AWAITING_PRODUCTS_BY_PHARMACY_ID';
export const GET_PRODUCT_BRANDS = 'getcare/mdm/product/GET_PRODUCT_BRANDS';
export const GET_PRODUCT_INDUSTRIES = 'getcare/mdm/product/GET_PRODUCT_INDUSTRIES';
export const UPDATE_AWAITING_PRODUCT = 'getcare/mdm/product/UPDATE_AWAITING_PRODUCT';
export const EXPORT_PRODUCT_LIST_TEMPLATE = 'getcare/mdm/product/EXPORT_PRODUCT_LIST_TEMPLATE';

// mutation
export const SAVE_PRODUCT_DETAILS = 'getcare/mdm/product/SAVE_PRODUCT_DETAILS';
export const SAVE_PRODUCT_LIST = 'getcare/mdm/product/SAVE_PRODUCT_LIST';
export const REMOVE_PRODUCTS = 'getcare/mdm/product/REMOVE_PRODUCTS';
export const SAVE_TOTAL = 'getcare/mdm/product/SAVE_TOTAL';
export const SAVE_LOADING = 'getcare/mdm/product/SAVE_LOADING';
export const SAVE_RELATED_PRODUCTS = 'getcare/mdm/product/SAVE_RELATED_PRODUCTS';
export const SAVE_MEDICINE_BY_PHARMACY_ID = 'getcare/mdm/product/SAVE_MEDICINE_BY_PHARMACY_ID';
export const SAVE_TOTAL_MEDICINES = 'getcare/mdm/product/SAVE_TOTAL_MEDICINES';
export const SAVE_OTHERS_BY_PHARMACY_ID = 'getcare/mdm/product/SAVE_OTHERS_BY_PHARMACY_ID';
export const SAVE_TOTAL_OTHERS = 'getcare/mdm/product/SAVE_TOTAL_OTHERS';
export const SAVE_AWAITING_PRODUCTS_BY_PHARMACY_ID = 'getcare/mdm/product/SAVE_AWAITING_PRODUCTS_BY_PHARMACY_ID';
export const SAVE_TOTAL_AWAITING_PRODUCTS = 'getcare/mdm/product/SAVE_TOTAL_AWAITING_PRODUCTS';
export const SAVE_PRODUCT_BRANDS = 'getcare/mdm/product/SAVE_PRODUCT_BRANDS';
export const SAVE_PRODUCT_INDUSTRIES = 'getcare/mdm/product/SAVE_PRODUCT_INDUSTRIES';
export const SAVE_AWAITING_PRODUCT = 'getcare/mdm/product/SAVE_AWAITING_PRODUCT';

export const SAVE_PRODUCT_ALLOWED_ACTIONS = 'getcare/mdm/product/SAVE_PRODUCT_ALLOWED_ACTIONS';
