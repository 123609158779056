import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { debounce } from 'lodash';
import { getPolicyPriceList, savePolicyPriceItems } from 'redux/actions/admin/policyPriceActions';
import { removeWSEvent } from 'redux/actions/wsActions';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectPolicyPriceList,
  makeSelectPolicyPriceLoading,
  makeSelectPolicyPriceListTotal,
  makeSelectWSEvents,
  makeSelectLoginUser,
} from 'redux/selectors';
import {
  listParamsMap,
  listDisplayFields,
} from 'utils/constanst/adminPolicyPriceContants';
import { getSortsString, getDisplayFields } from 'utils/helper';
import { withRouter } from 'react-router-dom';
import policyPriceApi from 'utils/api/admin/policyPriceApi';
import { toast } from 'react-toastify';
import { FLUSH_CACHE_POLICY_PRICE } from 'utils/constanst/wsConstants';

import PolicyPriceList from 'components/admin/PolicyPriceList/PolicyPriceList';
import PolicyPriceListFilters from 'components/admin/PolicyPriceList/PolicyPriceListFilters/PolicyPriceListFilters';
import ListPagination from 'components/ListPagination/ListPagination';
import Button from '@material-ui/core/Button';

import classes from './PolicyPrices.module.scss';

class PolicyPrices extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      listParams: { ...listParamsMap },
      flushCacheLoading: false,
      selectedItems: [],
      hideFlushCache: false,
      flushCacheEnabled: true,
    };
  }
  componentDidMount() {
    this.props.getPolicyPriceList({
      params: this.state.listParams,
    });
  }
  componentDidUpdate(prevProps) {
    const { events } = this.props;   
    if (events && events.length && events !== prevProps.events) {
      const flushCacheEvent = this.getFlushCachePolicyPriceEvent();
      console.log(events);
      if (flushCacheEvent && flushCacheEvent.data && flushCacheEvent.data.percent >= 100) {
        this.props.removeWSEvent({
          event: FLUSH_CACHE_POLICY_PRICE,
          indexField: 'event',
        });
        this.setState({ flushCacheEnabled: true });
      }
    }
  }

  getFlushCachePolicyPriceEvent = () => {
    return this.props.events.find(e => e.event === FLUSH_CACHE_POLICY_PRICE);
  }

  handleSortChange = ({ sortBy, sortDir }) => {
    const newSortString = getSortsString({
      [sortBy]: sortDir,
    });
    this.handleFilterChange([
      {
        name: 'order',
        value: newSortString,
      },
    ]);
  };

  handleAllSelectedToggle = (e) => {
    this.setState({
      selectedItems: e.target.checked
        ? [...this.state.selectedItems, ...this.props.policyPriceList]
        : [],
    });
  };

  handleItemSelectedToggle = ({ item, isSelected }) => {
    const remainItems = this.state.selectedItems.filter((p) => {
      const comparedField = p.idStr ? 'idStr' : 'id';
      return p[comparedField] !== item[comparedField];
    });
    this.setState({
      selectedItems: isSelected ? [...remainItems, { ...item }] : remainItems,
    });
  };

  
  handleFilterChange = (filters, forceResetPage: false) => {
    const paramsFromFilters = filters.reduce((memo, item) => {
      if (['end_date_group'].includes(item.name)) {
        memo[`end_date`] = item.value[0];
        memo[`end_date_empty`] = Number(item.value[1]) || undefined;
      } else {
        memo[item.name] = item.value;
      }
      return memo;
    }, {});
    const newParams = forceResetPage ? {
      ...this.state.listParams,
      ...paramsFromFilters,
      page: 1,
    } : {
      ...this.state.listParams,
      ...paramsFromFilters,
    };
    this.setState({
      listParams: newParams,
    });
    this.props.getPolicyPriceList({
      params: newParams,
    });
  };
  handleCreate = () => {
    this.props.history.push(`policy/new`);
  };
  handleFlushCache = async () => {
    this.setState({ flushCacheLoading: true, flushCacheEnabled: false, });
    try {
      const { data: response } = await policyPriceApi.flushPricesCache();
      if (!response?.result) {
        toast.error(response.message);
        return;
      }
      this.setState({ flushCacheLoading: false });
      this.props.getPolicyPriceList({
        params: this.state.listParams,
      });
    } catch(err) {
      this.setState({ flushCacheLoading: false, flushCacheEnabled: true, });
      toast.error(err);
    }
  }

  render() {
    const { policyPriceList, policyPriceListTotal, loading } = this.props;
    const { listParams, flushCacheLoading, flushCacheEnabled,selectedItems,hideFlushCache } = this.state;
    const isListLoading = loading;
    const flushCachePercentage = this.getFlushCachePolicyPriceEvent()?.data?.percent;

    return (
      <React.Fragment>
        <div className={`${classes.PageWrap} ${flushCacheLoading ? 'OverlayLoading' : ''}`}>
          <div className={classes.PageHeader}>
            <h1 className={classes.PageTitle}>Danh sách chính sách giá</h1>
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              disabled={flushCachePercentage >= 0 || !flushCacheEnabled}
              onClick={this.handleFlushCache}
            >
              Flush cache
              {  flushCachePercentage >= 0 && (<>
                ...<span className={classes.FlushCachePercentage}>{ `${parseFloat(flushCachePercentage).toFixed(2)}%` }</span>
              </>)}
            </Button>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={this.handleCreate}
            >
              Tạo mới
            </Button>
          </div>
          <div className={classes.PageMain}>
            <PolicyPriceListFilters
              onFilterChange={debounce(this.handleFilterChange, 500)}
            />
            <PolicyPriceList
              displayFields={getDisplayFields(listParams, listDisplayFields)}
              policyPriceList={policyPriceList}
              isLoading={isListLoading}
              selectedItems={selectedItems}
              onItemSelectedToggle={this.handleItemSelectedToggle}
              onAllSelectedToggle={this.handleAllSelectedToggle}
              onSortChange={this.handleSortChange}
            />
          </div>
          <div className={classes.PageFooter}>
            <ListPagination
              page={listParams.page}
              pageSize={listParams.page_size}
              listName="chính sách"
              total={policyPriceListTotal}
              onFilterChange={this.handleFilterChange}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  policyPriceListTotal: makeSelectPolicyPriceListTotal(),
  policyPriceList: makeSelectPolicyPriceList(),
  loading: makeSelectPolicyPriceLoading(),
  events: makeSelectWSEvents(),
  user: makeSelectLoginUser(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getPolicyPriceList: (payload) => dispatch(getPolicyPriceList(payload)),
    savePolicyPriceItems: (payload) => dispatch(savePolicyPriceItems(payload)),
    removeWSEvent: (payload) => dispatch(removeWSEvent(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter)(PolicyPrices);
