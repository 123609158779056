import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import IngredientListItem from './IngredientListItem/IngredientListItem';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';

import gridClasses from './IngredientListGrid.module.scss';
import classes from './IngredientList.module.scss';

class IngredientList extends PureComponent {
  render() {
    const { ingredientList, isLoading, displayFields } = this.props;

    return <div
        className={`${classes.List}`}
        style={{
          overflowX: this.rowRef ? 'scroll' : '',
        }}
      >
      <div
        className={`${classes.Header}`}
        ref={(el) => (this.rowRef = el)}
        style={{width: (this.rowRef?.offsetWidth) || 'auto'}}
      >
        <div className={`${gridClasses.Row} ${classes.Header}`}>
          { displayFields.map((item) => (
            <ListHeaderCol
              key={item.name}
              className={gridClasses.Col}
              {...item}
              onSortChange={this.props.onSortChange}
            />
          )) }
        </div>
      </div>
      <div
        className={`${classes.Body} ${isLoading && 'OverlayLoading'}`}
        style={{width: (this.rowRef?.offsetWidth) || 'auto'}}
      >
        { !isLoading && ingredientList.length
          ? ingredientList.map(item => (
            <IngredientListItem
              key={`ingredient-${item.id}`}
              {...item}
            />))
          : (<p className="NoData">Không có danh mục nào</p>)
        }
      </div>
    </div>;
  }
}

IngredientList.propTypes = {
  displayFields: PropTypes.array,
  ingredientList: PropTypes.array,
  isLoading: PropTypes.bool,
  onSortChange: PropTypes.func,
};

IngredientList.defaultProps = {
  displayFields: [],
  ingredientList: [],
  isLoading: false,
};

export default IngredientList;
