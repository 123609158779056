import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getUomBaseList } from 'redux/actions/admin/uomActions';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectUomBaseList,
  makeSelectUomBaseTotal,
} from 'redux/selectors';
import {
  baseListDisplayFields,
  baseListParamsMap,
} from 'utils/constanst/adminUomConstants';

import UomBaseList from 'components/admin/UomBaseList/UomBaseList';
import ListPagination from 'components/ListPagination/ListPagination';

import classes from './BasicUoms.module.scss';

class BasicUoms extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      baseListParams: { ...baseListParamsMap },
    };
  }

  componentDidMount() {
    this.props.getUomBaseList({
      params: this.state.baseListParams,
    });
  }

  getSortsMap = (sortStr) => {
    const sortArr = sortStr.split(',');
    return sortArr.reduce((memo, item) => {
      const arr = item.split(' ');
      if (arr[0] && arr[0].trim()) {
        memo[arr[0]] = arr[1] ? arr[1] : '';
      }
      return memo;
    }, {});
  };

  getSortsString = (sortMap) => {
    return Object.keys(sortMap)
      .reduce((memo, key) => {
        memo.push(`${key} ${sortMap[key]}`);
        return memo;
      }, [])
      .join(',');
  };

  getDisplayFields = (listParams, displayFields) => {
    const sortsMap = this.getSortsMap(listParams.order);
    return displayFields.map((item) => ({
      ...item,
      sortDir: sortsMap[item.name],
    }));
  };

  handleFilterBaseListChange = (filters) => {
    const paramsFromFilters = filters.reduce((memo, item) => {
      memo[item.name] = item.value;
      return memo;
    }, {});
    const newParams = {
      ...this.state.baseListParams,
      ...paramsFromFilters,
    };
    this.setState({
      baseListParams: newParams,
    });
    this.props.getUomBaseList({
      params: newParams,
    });
  };

  handleSortBaseListChange = ({ sortBy, sortDir }) => {
    const newSortString = this.getSortsString({
      [sortBy]: sortDir,
    });
    this.handleFilterBaseListChange([
      {
        name: 'order',
        value: newSortString,
      },
    ]);
  };

  render() {
    const { uomBaseList, uomBaseTotal } = this.props;
    const { baseListParams } = this.state;
    const isBaseListLoading = !uomBaseList;

    return (
      <React.Fragment>
        <div className={classes.PageWrap}>
          <div className={classes.PageHeader}>
            <h1 className={classes.PageTitle}>Danh sách đơn vị chuẩn</h1>
          </div>
          <div className={classes.UomBasePageMain}>
            <UomBaseList
              displayFields={this.getDisplayFields(
                baseListParams,
                baseListDisplayFields
              )}
              uomBaseList={uomBaseList}
              isLoading={isBaseListLoading}
              onSortChange={this.handleSortBaseListChange}
            />
          </div>
          <div className={classes.PageFooter}>
            <ListPagination
              page={baseListParams.page}
              pageSize={baseListParams.page_size}
              total={uomBaseTotal}
              listName="đơn vị tính"
              onFilterChange={this.handleFilterBaseListChange}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  uomBaseList: makeSelectUomBaseList(),
  uomBaseTotal: makeSelectUomBaseTotal(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getUomBaseList: (payload) => dispatch(getUomBaseList(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(BasicUoms);
