import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { customerPriceHeaderMap } from 'utils/constanst/adminTestPriceConstants';
import { currencyFormatVN } from 'utils/helper';
import { ACTIVE } from 'utils/constanst/common';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';

import classes from './Prices.module.scss';

class Prices extends PureComponent {
  render() {
    const { header, rows } = this.props.list;

    return (<div className={`${classes.List}`}>
      <div className={`${classes.Row} ${classes.Header}`}>
        { header.map((value, index) => (
          <ListHeaderCol key={`header-${index + 1}`} className={classes.Col} label={customerPriceHeaderMap[value] || value}/>
        )) }
      </div>
      <div className={`${classes.Body}`}>
        { rows.length ? rows.map((row, index) => (
          <div key={`price-${index + 1}`} className={`${classes.Row} ${classes.Item}`}>
            <div className={classes.Col}>{ row.priority?.name }</div>
            <div className={classes.Col}>{ row.price_type }</div>
            { row.vendors?.map((vendor, index) => (
              <div key={`price-vendor-${vendor.id}-${index + 1}`} className={classes.Col}>
                { vendor.price_sales
                  ? (<>
                      { currencyFormatVN(vendor.price_sales) }{`/${vendor.uom_base_name}`}
                      { vendor.active === ACTIVE ? <CheckCircleIcon className={classes.CheckIcon} fontSize="small"/> : ''}
                    </>)
                  : ''
                }
              </div>
            )) }
          </div>
        )) : <p className="NoData">Không có data</p>}
      </div>
    </div>);
  }
}

Prices.propTypes = {
  list: PropTypes.object,
};

Prices.defaultProps = {
  list: null,
};

export default Prices;
