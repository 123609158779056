import {
  SAVE_SALES_CHANNEL_LIST,
  SAVE_SALES_CHANNEL_LIST_TOTAL,
  SAVE_SALES_CHANNEL,
  SAVE_SALES_CHANNEL_COORDINATER,
  SAVE_SALES_CHANNEL_COORDINATERS,
  REMOVE_SALES_CHANNEL_COORDINATERS,
  SAVE_SALES_CHANNEL_HISTORY,
  SAVE_SALES_CHANNEL_ALLOWED_ACTIONS,
} from 'redux/constants/admin/salesChannelConstants';
import { genID } from 'utils/helper';

const initialState = {
  salesChannelList: null,
  salesChannelListTotal: 0,
  salesChannel: null,
  salesChannelCoordinaters: [],
  salesChannelHistory: [],
  salesChannelAllowedActions: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_SALES_CHANNEL_LIST: {
      const list = action.salesChannelList;
      return {
        ...state,
        salesChannelList: list ? [ ...list ] : null,
      };
    }
    case SAVE_SALES_CHANNEL_LIST_TOTAL: {
      return {
        ...state,
        salesChannelListTotal: action.salesChannelListTotal,
      };
    }
    case SAVE_SALES_CHANNEL: {
      return {
        ...state,
        salesChannel: action.salesChannel,
      };
    }
    case SAVE_SALES_CHANNEL_COORDINATER: {
      const item = action.salesChannelCoordinater;
      const list = [...state.salesChannelCoordinaters];
      const itemIndex = list.findIndex((m) => m.idStr === item.idStr);
      if (itemIndex > -1) {
        list[itemIndex] = { ...item };
      }
      if (itemIndex < 0) {
        list.unshift({ ...item });
      }
      return {
        ...state,
        salesChannelCoordinaters: list,
      };
    }
    case REMOVE_SALES_CHANNEL_COORDINATERS: {
      const items = action.salesChannelCoordinaters;
      const list = state.salesChannelCoordinaters.filter(mem => !items.some(item => item.idStr === mem.idStr));
      return {
        ...state,
        salesChannelCoordinaters: list,
      };
    }
    case SAVE_SALES_CHANNEL_COORDINATERS: {
      const members = action.salesChannelCoordinaters
        ? [...action.salesChannelCoordinaters]
        : [];
      return {
        ...state,
        salesChannelCoordinaters: members.map((m) => ({
          ...m,
          idStr: genID(),
        })),
      };
    }
    case SAVE_SALES_CHANNEL_HISTORY: {
      const list = action.salesChannelHistory;
      return {
        ...state,
        salesChannelHistory: list ? [...list] : [],
      };
    }
    case SAVE_SALES_CHANNEL_ALLOWED_ACTIONS: {
      return {
        ...state,
        salesChannelAllowedActions: action.salesChannelAllowedActions ? [...action.salesChannelAllowedActions] : [],
      };
    }
    default:
      return state;
  }
}

export default reducer;
