import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import SalesChannelListItem from './SalesChannelListItem/SalesChannelListItem';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';

import gridClasses from './SalesChannelListGrid.module.scss';
import classes from './SalesChannelList.module.scss';

class SalesChannelList extends PureComponent {
  render() {
    const { salesChannelList, isLoading, displayFields } = this.props;

    return <div className={`${classes.List} ${isLoading && 'OverlayLoading'}`}>
      <div
        className={`${classes.Header}`}
        ref={(el) => (this.rowRef = el)}
      >
            <div className={`${gridClasses.Row} ${classes.Header}`}>
          { displayFields.map((item) => (
            <ListHeaderCol
              key={item.name}
              className={gridClasses.Col}
              {...item}
              onSortChange={this.props.onSortChange}
            />
          )) }
        </div>
        </div>

      <div className={`${classes.Body}`}>
      
        { !isLoading && salesChannelList.length
          ? salesChannelList.map(item => (
            <SalesChannelListItem
              key={`medicine-${item.id}`}
              {...item}
              onDeleteItemSuccess={this.props.onDeleteItemSuccess}
            />))
          : (<p className="NoData">Không có data</p>)
        }
      </div>
    </div>;
  }
}

SalesChannelList.propTypes = {
  displayFields: PropTypes.array,
  salesChannelList: PropTypes.array,
  isLoading: PropTypes.bool,
  onSortChange: PropTypes.func,
  onDeleteItemSuccess: PropTypes.func,
};

SalesChannelList.defaultProps = {
  displayFields: [],
  salesChannelList: [],
  isLoading: false,
};

export default SalesChannelList;
