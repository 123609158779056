import {
  GET_KEY_ACCOUNT_LOCK_LIST,

  SAVE_LOADING,

  SAVE_KEY_ACCOUNT_LOCK_LIST,
  DELETE_KEY_ACCOUNT_LOCK_LIST,
  SAVE_KEY_ACCOUNT_LOCK_LIST_TOTAL,
} from 'redux/constants/admin/keyAccountLockConstants';

export function getKeyAccountLockList(payload) {
  return {
    type: GET_KEY_ACCOUNT_LOCK_LIST,
    payload,
  };
}

// mutation
export function saveLoading(loading) {
  return {
    type: SAVE_LOADING,
    loading,
  };
}
export function saveKeyAccountLockList(keyAccountLockList) {
  return {
    type: SAVE_KEY_ACCOUNT_LOCK_LIST,
    keyAccountLockList,
  };
}
export function saveKeyAccountLockListTotal(keyAccountLockListTotal) {
  return {
    type: SAVE_KEY_ACCOUNT_LOCK_LIST_TOTAL,
    keyAccountLockListTotal,
  };
}

export function deleteKeyAccountLockList(payload) {
  return {
    type: DELETE_KEY_ACCOUNT_LOCK_LIST,
    payload,
  };
}
