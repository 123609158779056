import storeApi from 'utils/api/admin/storeApi';
import customerApi from 'utils/api/admin/customerApi';
import { put } from 'redux-saga/effects';
import { storeActions } from 'redux/actions';
import { toast } from 'react-toastify';

export function* loadStoreDetails(payload) {
  const { id, params } = payload.payload;
  try {
    yield put(storeActions.saveStore(null));

    const { data: response } = yield storeApi.get({ id, params });

    if (!response?.result) {
      yield put(storeActions.saveStore({}));
      return;
    }
    yield put(
      storeActions.saveStore(response.data ? { ...response.data } : {})
    );
  } catch (err) {
    console.log(err);
  }
}

export function* loadStoreList(payload) {
  const { params } = payload.payload;
  try {
    yield put(storeActions.saveStoreListLoading(true));

    const { data: response } = yield storeApi.getAll({ params });

    yield put(storeActions.saveStoreListLoading(false));

    if (!response?.result) {
      yield put(storeActions.saveStoreList([]));
      yield put(storeActions.saveStoreListTotal(0));
      return;
    }
    yield put(
      storeActions.saveStoreList(response.data ? [...response.data] : [])
    );
    yield put(storeActions.saveStoreListTotal(response.total_records));
  } catch (err) {
    console.log(err);
  }
}

export function* putStore(payload) {
  const { id, params, onDataUpdated } = payload.payload;
  try {
    const { data: response } = yield storeApi.updateStore({ id, params });
    if (!response?.result) {
      toast.error(response.message);
      yield put(storeActions.saveStore({}));
      return;
    }

    toast.success(`Lưu thông tin khách hàng thành công.`);

    yield put(
      storeActions.saveStore(response.data ? { ...response.data } : {})
    );

    onDataUpdated && onDataUpdated();
  } catch (err) {
    console.log(err);
  }
}

export function* exportStoreListTemplate(payload) {
  try {
    const { data: response } = yield storeApi.exportStoresTemplate();
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    const anchor = document.createElement('a');
    anchor.setAttribute('href', `${response.data ? response.data : ''}`);
    anchor.click();
    anchor.remove();
  } catch (err) {
    console.log(err);
  }
}

export function* loadStoreDeliveryAddressList(payload) {
  const { params } = payload.payload;
  try {
    yield put(storeActions.saveStoreActionLoading(true));

    const { data: response } = yield customerApi.getAddressBook({ params });

    yield put(storeActions.saveStoreActionLoading(false));
    if (!response?.result) {
      yield put(storeActions.saveStoreDeliveryAddressList([]));
      return;
    }
    yield put(
      storeActions.saveStoreDeliveryAddressList(response.data ? [...response.data] : [])
    );
  } catch (err) {
    console.log(err);
  }
}

export function* updateStoreDeliveryAddress(payload) {
  const { params } = payload.payload;
  try {
    yield put(storeActions.saveStoreActionLoading(true));

    const { data: response } = yield customerApi.postAddress({ params });

    yield put(storeActions.saveStoreActionLoading(false));
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(
      storeActions.saveStoreDeliveryAddressList(response.data ? response.data : [])
    );
  } catch (err) {
    console.log(err);
  }
}

export function* deleteStoreDeliveryAddress(payload) {
  const { params } = payload.payload;
  try {
    yield put(storeActions.saveStoreActionLoading(true));

    const { data: response } = yield customerApi.deleteAddress({ params });

    yield put(storeActions.saveStoreActionLoading(false));
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(storeActions.removeStoreDeliveryAddress(params));
  } catch (err) {
    console.log(err);
  }
}

export function* postStore(payload) {
  const { params } = payload.payload;
  try {
    const { data: response } = yield storeApi.createStore({ params });
    if (!response?.result) {
      toast.error(response.message);
      return;
    }

    toast.success(`Thêm khách hàng thành công.`);

    yield put(
      storeActions.saveStore(response.data ? { ...response.data } : {})
    );
  } catch (err) {
    console.log(err);
  }
}