export const GET_SALES_TEAM_LIST = 'getcare/mdm/salesTeam/GET_SALES_TEAM_LIST';
export const GET_SALES_TEAM = 'getcare/mdm/salesTeam/GET_SALES_TEAM';
export const CREATE_SALES_TEAM = 'getcare/mdm/salesTeam/CREATE_SALES_TEAM';
export const UPDATE_SALES_TEAM = 'getcare/mdm/salesTeam/UPDATE_SALES_TEAM';
export const GET_SALES_ROLES = 'getcare/mdm/salesTeam/GET_SALES_ROLES';

// mutation
export const SAVE_SALES_TEAM_LIST = 'getcare/mdm/salesTeam/SAVE_SALES_TEAM_LIST';
export const SAVE_SALES_TEAM_LIST_TOTAL = 'getcare/mdm/salesTeam/SAVE_SALES_TEAM_LIST_TOTAL';
export const SAVE_SALES_TEAM_DETAILS = 'getcare/mdm/salesTeam/SAVE_SALES_TEAM_DETAILS';
export const SAVE_SALES_TEAM_MEMBERS = 'getcare/mdm/salesTeam/SAVE_SALES_TEAM_MEMBERS';
export const SAVE_SALES_TEAM_MEMBER = 'getcare/mdm/salesTeam/SAVE_SALES_TEAM_MEMBER';
export const REMOVE_SALES_TEAM_MEMBER = 'getcare/mdm/salesTeam/REMOVE_SALES_TEAM_MEMBER';
export const SAVE_SALES_TEAM_CHANNELS = 'getcare/mdm/salesTeam/SAVE_SALES_TEAM_CHANNELS';
export const SAVE_SALES_TEAM_CHANNEL = 'getcare/mdm/salesTeam/SAVE_SALES_TEAM_CHANNEL';
export const REMOVE_SALES_TEAM_CHANNEL = 'getcare/mdm/salesTeam/REMOVE_SALES_TEAM_CHANNEL'
export const SAVE_SALES_TEAM_KEY_ACCOUNTS = 'getcare/mdm/salesTeam/SAVE_SALES_TEAM_KEY_ACCOUNTS';
export const SAVE_SALES_ROLES = 'getcare/mdm/salesTeam/SAVE_SALES_ROLES';
export const RESET_SALES_TEAM = 'getcare/mdm/salesTeam/RESET_SALES_TEAM';
