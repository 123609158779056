import {
  SAVE_INGREDIENT_LIST,
  SAVE_INGREDIENT_TOTAL,
  SAVE_QUEUE_INGREDIENT_LIST,
  SAVE_QUEUE_INGREDIENT_TOTAL,
  SAVE_QUEUE_INGREDIENT_ITEM,
  REMOVE_QUEUE_INGREDIENT_ITEM,
  SAVE_LOADING,
} from 'redux/constants/admin/ingredientConstants';

const initialState = {
  ingredientList: [],
  ingredientTotal: 0,
  queueIngredientList: [],
  queueIngredientTotal: 0,
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_LOADING: {
      return {
        ...state,
        loading: action.loading,
      };
    }
    case SAVE_INGREDIENT_LIST: {
      const list = action.ingredientList;
      return {
        ...state,
        ingredientList: list ? [...list] : null,
      };
    }
    case SAVE_INGREDIENT_TOTAL: {
      return {
        ...state,
        ingredientTotal: action.ingredientTotal,
      };
    }
    case SAVE_QUEUE_INGREDIENT_LIST: {
      const list = action.queueIngredientList;
      return {
        ...state,
        queueIngredientList: list ? [...list] : null,
      };
    }
    case SAVE_QUEUE_INGREDIENT_TOTAL: {
      return {
        ...state,
        queueIngredientTotal: action.queueIngredientTotal,
      };
    }
    case SAVE_QUEUE_INGREDIENT_ITEM: {
      const list = [...state.queueIngredientList];
      const params = action.queueIngredient;
      const index = list.findIndex(ing => ing.id === params.id);
      if (index > -1) {
        const item = { ...list[index] };
        list[index] = { ...item, ...params };
        return {
          ...state,
          queueIngredientList: list,
        };
      }
      return state;
    }
    case REMOVE_QUEUE_INGREDIENT_ITEM: {
      const item = {...action.queueIngredient};
      const list = [...state.queueIngredientList];
      const index = list.findIndex(ing => ing.id === item.id);
      if (index > -1) {
        list[index] = item;
        return {
          ...state,
          queueIngredientList: list.filter(l => l.id !== item.id),
          queueIngredientTotal: state.queueIngredientTotal - 1,
        };
      }
      return state;
    }
    default:
      return state;
  }
}

export default reducer;
