import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectTestShippingCouponAvailables,
  makeSelectTestShippingCouponAmount,
} from 'redux/selectors';
import { FREESHIP_TEXT } from 'utils/constanst/adminTestShippingCouponConstants';
import { currencyFormatVN } from 'utils/helper';

import ShippingCouponAvailable from './ShippingCouponAvailable/ShippingCouponAvailable';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';

import gridClasses from './ShippingCouponAvailablesGrid.module.scss';
import classes from './ShippingCouponAvailables.module.scss';

class ShippingCouponAvailables extends PureComponent {
  render() {
    const { shippingCouponAvailables, shippingCouponAmount } = this.props;

    return (
      <div className={`${classes.Wrap}`}>
        <h4 className={`${classes.CouponAmount} TextRight TextSecondary`}>
          Shipping coupon: {` `}
          { shippingCouponAmount?.freeship
            ? FREESHIP_TEXT
            : ![null, undefined, ''].includes(shippingCouponAmount?.shipping_coupon_amount) ? currencyFormatVN(shippingCouponAmount?.shipping_coupon_amount) : `` }
        </h4>
        <div className={`${classes.List}`}>
          <div className={`${gridClasses.Row} ${classes.Header}`}>
            <ListHeaderCol
              className={gridClasses.Col}
              label="Mã vendor"
            />
            <ListHeaderCol
              className={gridClasses.Col}
              label="Tên vendor"
            />
            <ListHeaderCol
              className={`${gridClasses.Col} TextRight`}
              label="Giá trị trong đơn hàng"
            />
            <ListHeaderCol
              className={gridClasses.Col}
              label="Mã coupon áp dụng"
            />
            <ListHeaderCol
              className={`${gridClasses.Col} TextRight`}
              label="Discount amount"
            />
          </div>
          <div className={`${classes.Body}`}>
            {shippingCouponAvailables.length ? (
              shippingCouponAvailables.map((item) => (
                <ShippingCouponAvailable
                  key={`shipping-coupon-active-${item.id}`}
                  {...item}
                />
              ))
            ) : (
              <p className="NoData">Không có data</p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

ShippingCouponAvailables.propTypes = {
};

ShippingCouponAvailables.defaultProps = {
};

const mapStateToProps = createStructuredSelector({
  shippingCouponAvailables: makeSelectTestShippingCouponAvailables(),
  shippingCouponAmount: makeSelectTestShippingCouponAmount(),
});
const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(ShippingCouponAvailables);
