export const GET_GROUP_LIST = 'GET_GROUP_LIST';
export const GET_GROUP = 'GET_GROUP';
export const UPDATE_GROUP = 'UPDATE_GROUP';

export const UPDATE_GROUP_PRODUCT = 'UPDATE_GROUP_PRODUCT';
export const DELETE_GROUP_PRODUCTS = 'DELETE_GROUP_PRODUCTS';
export const EXPORT_GROUP_PRODUCTS = 'EXPORT_GROUP_PRODUCTS';

export const UPDATE_GROUP_VENDOR = 'UPDATE_GROUP_VENDOR';
export const DELETE_GROUP_VENDORS = 'DELETE_GROUP_VENDORS';
export const EXPORT_GROUP_VENDORS = 'EXPORT_GROUP_VENDORS';

export const GET_GROUP_CUSTOMER_MAPPINGS = 'GET_GROUP_CUSTOMER_MAPPINGS';
export const UPDATE_GROUP_CUSTOMER_MAPPING = 'UPDATE_GROUP_CUSTOMER_MAPPING';
export const DELETE_GROUP_CUSTOMER_MAPPINGS = 'DELETE_GROUP_CUSTOMER_MAPPINGS';
export const GET_GROUP_CUSTOMERS_BY_MAPPINGS =
  'GET_GROUP_CUSTOMERS_BY_MAPPINGS';

export const GET_GROUP_CUSTOMERS = 'GET_GROUP_CUSTOMERS';
export const UPDATE_GROUP_CUSTOMER = 'UPDATE_GROUP_CUSTOMER';
export const DELETE_GROUP_CUSTOMERS = 'DELETE_GROUP_CUSTOMERS';
export const EXPORT_GROUP_CUSTOMERS = 'EXPORT_GROUP_CUSTOMERS';

export const GET_CUSTOMERS_TYPES = 'GET_CUSTOMERS_TYPES';

// mutation
export const SAVE_GROUP_LIST = 'SAVE_GROUP_LIST';
export const SAVE_GROUP_LIST_TOTAL = 'SAVE_GROUP_LIST_TOTAL';

export const SAVE_LOADING = 'SAVE_LOADING';

export const SAVE_GROUP = 'SAVE_GROUP';
export const RESET_GROUP = 'RESET_GROUP';

export const SAVE_GROUP_PRODUCTS = 'SAVE_GROUP_PRODUCTS';
export const SAVE_GROUP_PRODUCT = 'SAVE_GROUP_PRODUCT';
export const REMOVE_GROUP_PRODUCTS = 'REMOVE_GROUP_PRODUCTS';

export const SAVE_GROUP_VENDORS = 'SAVE_GROUP_VENDORS';
export const SAVE_GROUP_VENDOR = 'SAVE_GROUP_VENDOR';
export const REMOVE_GROUP_VENDORS = 'REMOVE_GROUP_VENDORS';

export const SAVE_GROUP_CUSTOMER_MAPPINGS = 'SAVE_GROUP_CUSTOMER_MAPPINGS';
export const SAVE_GROUP_CUSTOMER_MAPPING = 'SAVE_GROUP_CUSTOMER_MAPPING';
export const REMOVE_GROUP_CUSTOMER_MAPPINGS = 'REMOVE_GROUP_CUSTOMER_MAPPINGS';

export const SAVE_GROUP_CUSTOMERS = 'SAVE_GROUP_CUSTOMERS';
export const SAVE_GROUP_CUSTOMER = 'SAVE_GROUP_CUSTOMER';
export const REMOVE_GROUP_CUSTOMERS = 'REMOVE_GROUP_CUSTOMERS';
export const SAVE_GROUP_CUSTOMERS_TOTAL = 'SAVE_GROUP_CUSTOMERS_TOTAL';

export const SAVE_CUSTOMERS_TYPES = 'SAVE_CUSTOMERS_TYPES';
