import policyPriceApi from 'utils/api/admin/policyPriceApi';
import { put } from 'redux-saga/effects';
import { policyPriceActions } from 'redux/actions';
import { toast } from 'react-toastify';
import { POLICY_PRICE_GROUP_TYPE } from 'utils/constanst/adminPolicyPriceContants';

export function* loadPricePriorities(payload) {
  try {
    yield put(policyPriceActions.savePricePiorities([]));

    const { data: response } = yield policyPriceApi.getPricePriorities();

    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(policyPriceActions.savePricePiorities(response.data ? [...response.data] : []));
  } catch (err) {
    console.log(err);
  }
}
export function* loadPriceUnits(payload) {
  try {
    yield put(policyPriceActions.savePriceUnits([]));

    const { data: response } = yield policyPriceApi.getPriceUnits();

    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(policyPriceActions.savePriceUnits(response.data ? [...response.data] : []));
  } catch (err) {
    console.log(err);
  }
}

export function* loadPolicyPriceList(payload) {
  const { params } = payload.payload;
  try {
    yield put(policyPriceActions.saveActionLoading(true));

    const { data: response } = yield policyPriceApi.getList({ params });

    yield put(policyPriceActions.saveActionLoading(false));
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(policyPriceActions.savePolicyPriceList(response.data ? [...response.data] : []));
    yield put(policyPriceActions.savePolicyPriceListTotal(response.total_records));
  } catch (err) {
    console.log(err);
  }
}

export function* loadPolicyPrice(payload) {
  try {
    yield put(policyPriceActions.saveActionLoading(true));

    yield put(policyPriceActions.saveOriginalPolicyPriceDetails(null));
    yield put(policyPriceActions.savePolicyPriceDetails(null));

    yield put(policyPriceActions.savePolicyPriceProducts([]));
    yield put(policyPriceActions.saveTempPolicyPriceProducts([]));

    yield put(policyPriceActions.savePolicyPriceCustomers([]));
    yield put(policyPriceActions.saveTempPolicyPriceCustomers([]));

    yield put(policyPriceActions.savePolicyPriceChannels([]));
    yield put(policyPriceActions.saveTempPolicyPriceChannels([]));

    yield put(policyPriceActions.savePolicyPriceCustomerTypes([]));
    yield put(policyPriceActions.saveTempPolicyPriceCustomerTypes([]));

    const { data: response } = yield policyPriceApi.get(payload.id);

    yield put(policyPriceActions.saveActionLoading(false));

    if (!response?.result) {
      toast.error(response.message);
      return;
    }

    yield put(
      policyPriceActions.saveOriginalPolicyPriceDetails(
        response.data
          ? {
              ...response.data,
              getcare_policy_price_products: undefined,
              getcare_policy_price_customers: undefined,
              getcare_policy_price_sales_channels: undefined,
              getcare_policy_price_customer_types: undefined,
            }
          : null
      )
    );
    yield put(
      policyPriceActions.savePolicyPriceDetails(
        response.data
          ? {
              ...response.data,
              getcare_policy_price_products: undefined,
              getcare_policy_price_customers: undefined,
              getcare_policy_price_sales_channels: undefined,
              getcare_policy_price_customer_types: undefined,
            }
          : null
      )
    );

    const policyPriceProducts = response.data?.getcare_policy_price_products.map(
      item => ({
        ...item,
        group_or_vendor_product_id: item.type_id === POLICY_PRICE_GROUP_TYPE ? item.erp_group?.id : item.product_price_vendor_id,
      })
    ) || [];
    yield put(policyPriceActions.savePolicyPriceProducts(policyPriceProducts));
    yield put(policyPriceActions.saveTempPolicyPriceProducts(policyPriceProducts));

    yield put(policyPriceActions.savePolicyPriceCustomers(response.data?.getcare_policy_price_customers || []));
    yield put(policyPriceActions.saveTempPolicyPriceCustomers(response.data?.getcare_policy_price_customers || []));

    yield put(policyPriceActions.savePolicyPriceChannels(response.data?.getcare_policy_price_sales_channels || []));
    yield put(policyPriceActions.saveTempPolicyPriceChannels(response.data?.getcare_policy_price_sales_channels || []));

    yield put(policyPriceActions.savePolicyPriceCustomerTypes(response.data?.getcare_policy_price_customer_types || []));
    yield put(policyPriceActions.saveTempPolicyPriceCustomerTypes(response.data?.getcare_policy_price_customer_types || []));
  } catch (err) {
    console.log(err);
  }
}
export function* postPolicyPrice(payload) {
  const { params } = payload.payload;
  try {
    yield put(policyPriceActions.saveActionLoading(true));

    const { data: response } = yield policyPriceApi.update({ params });

    yield put(policyPriceActions.saveActionLoading(false));
    if (!response?.result) {
      toast.error(response.message);
      return;
    }

    toast.success(`Lưu chính sách giá thành công.`);

    yield put(policyPriceActions.saveOriginalPolicyPriceDetails(response.data ? { ...response.data } : null));
    yield put(policyPriceActions.savePolicyPriceDetails(response.data ? { ...response.data } : null));
    if (params.id) {
      // is editing success full
      const policyPriceProducts = response.data?.getcare_policy_price_products.map(
        item => ({
          ...item,
          group_or_vendor_product_id: item.type_id === POLICY_PRICE_GROUP_TYPE ? item.erp_group?.id : item.product_price_vendor_id,
        })
      ) || [];
      yield put(policyPriceActions.savePolicyPriceProducts(policyPriceProducts));
      yield put(policyPriceActions.saveTempPolicyPriceProducts(policyPriceProducts));

      yield put(policyPriceActions.savePolicyPriceCustomers(response.data?.getcare_policy_price_customers || []));
      yield put(policyPriceActions.saveTempPolicyPriceCustomers(response.data?.getcare_policy_price_customers || []));

      yield put(policyPriceActions.savePolicyPriceChannels(response.data?.getcare_policy_price_sales_channels || []));
      yield put(policyPriceActions.saveTempPolicyPriceChannels(response.data?.getcare_policy_price_sales_channels || []));

      yield put(policyPriceActions.savePolicyPriceCustomerTypes(response.data?.getcare_policy_price_customer_types || []));
      yield put(policyPriceActions.saveTempPolicyPriceCustomerTypes(response.data?.getcare_policy_price_customer_types || []));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* loadPolicyPriceHistory(payload) {
  const { params } = payload.payload;
  try {
    yield put(policyPriceActions.savePolicyPriceHistory([]));

    const { data: response } = yield policyPriceApi.getPolicyPriceHistory({
      params,
    });

    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(policyPriceActions.savePolicyPriceHistory(response.data ? [...response.data] : []));
  } catch (err) {
    console.log(err);
  }
}

export function* exportPolicyPriceTemplate(payload) {
  try {
    yield put(policyPriceActions.saveActionLoading(true));

    const { data: response } = yield policyPriceApi.exportPolicyPriceTemplate();
    if (!response?.result) {
      toast.error(response.message);
      return;
    }

    const anchor = document.createElement('a');
    anchor.setAttribute('href', `${response.data ? response.data : ''}`);
    anchor.click();
    anchor.remove();

    yield put(policyPriceActions.saveActionLoading(false));
  } catch (err) {
    console.log(err);
  }
}

export function* loadPolicyPriceCounts(payload) {
  const { params } = payload.payload;
  try {
    const { data: response } = yield policyPriceApi.getPolicyPriceCounts({ params });
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(policyPriceActions.savePolicyPriceCounts({
      count_customer: response.data?.count_customer,
      count_product: response.data?.count_product,
    }));
  } catch (err) {
    console.log(err);
  }
}
