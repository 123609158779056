import React, { PureComponent } from 'react';
import axios from 'axios';
import { debounce, isEqual } from 'lodash';

import vendorApi from 'utils/api/admin/vendorApi';

import PropTypes from 'prop-types';

import {
  TextField,
  CircularProgress,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

class AutocompleteVendor extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      value: this._mapPropsValue(),
      loading: false,
    }
    this.cancelToken = null;
  }
  componentDidMount() {
    if ( ( Array.isArray(this.props.value) && this.props.value.length > 0 ) || ( !Array.isArray(this.props.value) && this.props.value ) ) {
      this._updateStateValue();
    }
  }
  componentDidUpdate(prevProps,prevState) {
    const stateIdValue = this._mapStateValue();
    if ( !isEqual(prevProps.value,this.props.value) && !isEqual(stateIdValue,this.props.value) ) {
      this._updateStateValue();
    }
  }

  _mapStateValue = () => {
    return this.props.multiple ? 
      Array.isArray(this.state.value) ? 
        this.state.value.map(v => v.value) : [] : 
      (this.state.value?.value || '');
  }

  _mapPropsValue = () => {
    return this.props.multiple ? 
      Array.isArray(this.props.value) ? 
        this.props.value.map(v=>({ value: v, label: v, data: null })) : 
          [] : 
      this.props.value ? 
        { value: this.props.value, label: this.props.value, data: null} : 
          null;
  }

  _selectedValue = () => {
    return this.props.multiple ? 
      Array.isArray(this.state.value) ? 
        this.state.value.value : [] : 
      this.state.value;
  }

  _updateStateValue = async () => {
    const value = await this._loadVendor(this.props.value);
    this.setState({
      value,
    })
  }

  _loadOptions = async (params) => {
    this.cancelToken && this.cancelToken.cancel();
    this.setState({
      loading: true,
      options: [],
    },() => {
      this._debounceGetVendorOptions(params || {});
    })
  }

  _debounceGetVendorOptions = debounce(async (params) => {
    const options = await this._loadVendors(params);
    if (!options) return;
    this.setState({
      loading: false,
      options,
    });
  },250)

  _loadVendors = async ({ params }) => {
    this.cancelToken = axios.CancelToken.source();
    try {
      const { data: response } = await vendorApi.getAll({
        params: {
          page: 1,
          page_size: 10,
          ...params
        },
        cancelToken: this.cancelToken.token
      });
      if (!response?.result) return [];
      return response.data
      ? response.data.map( data => ({ value: data.id, label: data.name, data: data }))
      : [];
    } catch (error) {
      return null;
    }
  }

  _loadVendor = async (id) => {
    try {
      const { data: response } = await vendorApi.get(id);
      if (!response?.result || !response.data) return;
      return response.data
      ? {
        value: response.data.id, 
        label: response.data.name, 
        data: response.data,
      }
      : null;
    } catch (error) {
      return null;
    }
  }

  handleChange = (e,value) => {
    e.preventDefault();
    this.setState({ value: value });
    this.props.onChange(e,this.props.multiple ? 
      Array.isArray(value) ? value.map(v=>v.value) : [] : 
        (value?.value || '')
    );
  }

  render() {
    const { 
      name,
      placeholder,
      multiple,
      disableCloseOnSelect,
    } = this.props;
    const {
      options, 
      loading,
    } = this.state;

    return (
      <Autocomplete
        disableCloseOnSelect={disableCloseOnSelect}
        fullWidth
        autoComplete
        filterOptions={(x) => x}
        multiple={multiple}
        name={name}
        handleHomeEndKeys={false}
        value={this._selectedValue()}
        options={loading ? [] : options}
        getOptionLabel={(option) => String(option.label) || ''}
        getOptionSelected={(option, value) => option.value === value?.value}
        renderOption={(option) => option.label || ''}
        onInputChange={(event, newInputValue,reason) => {
          if ( ['input','clear'].includes(reason) ) this._loadOptions({ params: { name: newInputValue }});
        }}
        renderOption={(option) => option.label}
        loading={loading}
        loadingText="Đang tìm kiếm..."
        renderInput={(params) => (
            <TextField
              placeholder={placeholder || 'Chọn...'}
              name={name}
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
        )}
        onOpen={() => this._loadOptions()}
        onChange={(e, newValue) => {
          this.handleChange(e,newValue)
        }}
      />
    );
  }
}

AutocompleteVendor.propTypes = {
  value: PropTypes.node,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  disableCloseOnSelect: PropTypes.bool,
};

AutocompleteVendor.defaultProps = {
  value: null,
  placeholder: '',
  multiple: false,
  disableCloseOnSelect: false,
};

export default AutocompleteVendor;
