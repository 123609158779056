import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import KeyAccountListItem from './KeyAccountListItem/KeyAccountListItem';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';

import gridClasses from './KeyAccountListGrid.module.scss';
import classes from './KeyAccountList.module.scss';

class KeyAccountList extends PureComponent {
  render() {
    const { keyAccountList, isLoading, displayFields } = this.props;

    return <div className={`${classes.List} ${isLoading && 'OverlayLoading'}`}>
         <div
        className={`${classes.Header}`}
        ref={(el) => (this.rowRef = el)}
      >
            <div className={`${gridClasses.Row} ${classes.Header}`}>
          { displayFields.map((item) => (
            <ListHeaderCol
              key={item.name}
              className={gridClasses.Col}
              {...item}
              onSortChange={this.props.onSortChange}
            />
          )) }
        </div>
        </div>
      <div className={`${classes.Body}`}>
        { !isLoading && keyAccountList.length
          ? keyAccountList.map(item => (
            <KeyAccountListItem
              key={`keyAccount-${item.id}`}
              {...item}
              onStatusChange={this.props.onStatusChange}
            />))
          : (<p className="NoData">Không có data</p>)
        }
      </div>
    </div>;
  }
}

KeyAccountList.propTypes = {
  displayFields: PropTypes.array,
  keyAccountList: PropTypes.array,
  isLoading: PropTypes.bool,
  onSortChange: PropTypes.func,
  onStatusChange: PropTypes.func,
};

KeyAccountList.defaultProps = {
  displayFields: [],
  keyAccountList: [],
  isLoading: false,
};

export default KeyAccountList;
