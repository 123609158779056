import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectKeyAccountProducts,
  makeSelectOriKeyAccountProducts,
  makeSelectKeyAccountDetails,
  makeSelectKeyAccountActionLoading,
} from 'redux/selectors';
import {
  getKeyAccountProducts,
  saveKeyAccountProductItem,
  saveOriKeyAccountProducts,
  saveKeyAccountProducts,
} from 'redux/actions/admin/keyAccountActions';
import { getDisplayFields } from 'utils/helper';
import {
  keyAccountProductsDisplayFields,
  keyAccountProductsParamsMap,
} from 'utils/constanst/adminKeyAccountConstants';
import { isEqual } from 'lodash';

import KeyAccountProductItem from './KeyAccountProductItem/KeyAccountProductItem';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';

import gridClasses from './KeyAccountProductListGrid.module.scss';
import classes from 'views/admin/KeyAccount/KeyAccount.module.scss';

class KeyAccountProducts extends PureComponent {
  componentDidMount() {
    const { isKACreating, keyAccountDetails } = this.props;
    if (isKACreating) {
      this.props.saveOriKeyAccountProducts([]);
      this.props.saveKeyAccountProducts([]);
    }
    if (!isKACreating && keyAccountDetails) {
      this.loadKeyAccountProducts();
    }
  }
  componentDidUpdate(prevProps) {
    const { keyAccountDetails } = this.props;
    if (keyAccountDetails && prevProps.keyAccountDetails && !isEqual(keyAccountDetails, prevProps.keyAccountDetails)) {
      if (keyAccountDetails.getcare_vendor?.id !== prevProps.keyAccountDetails.getcare_vendor?.id) {
        this.loadKeyAccountProducts();
      }
    }
  }

  loadKeyAccountProducts = () => {
    const { keyAccountDetails } = this.props;
    if (!keyAccountDetails?.getcare_vendor?.id) return;
    // will not load products when editting or vendor was not declared yet
    this.props.getKeyAccountProducts({
      params: {
        getcare_vendor_id: keyAccountDetails.getcare_vendor.id,
        getcare_key_account_id: keyAccountDetails.id || null,
      }
    });
  }

  handleSaveKeyAccountProduct = (params) => {
    this.props.saveKeyAccountProductItem({
      ...params,
    });
  };
  render() {
    const {
      keyAccountProducts,
      keyAccountDetails,
      actionLoading,
    } = this.props;

    const displayFields = getDisplayFields(keyAccountProductsParamsMap, keyAccountProductsDisplayFields);

    return (
      <div className={`${classes.SectionWrap}`}>
        <h4 className={classes.SectionTitle}>Sản phẩm</h4>

        <div className={classes.FieldControl}>
          <label>{ keyAccountDetails?.getcare_vendor?.name }</label>
        </div>

        <div className={`${classes.List} ${actionLoading ? 'OverlayLoading' : ''}`} style={{ marginTop: 0 }}>
          <div className={`${gridClasses.Row} ${classes.Header}`}>
            {displayFields.map((item) => (
              <ListHeaderCol
                key={item.name}
                {...item}
                className={`${gridClasses.Col} ${item.className}`}
              />
            ))}
            <div className={`${gridClasses.Col} ${classes.HeaderCol} TextCenter`}/>
          </div>
          <div className={`${classes.Body}`}>
            {keyAccountProducts && keyAccountProducts.length ? (<>
              { keyAccountProducts.map((item, index) => (
                <KeyAccountProductItem
                  key={`product-${item.idStr || item.id}`}
                  {...item}
                  onSaveKeyAccountProductItem={this.handleSaveKeyAccountProduct}
                />
              )) }
            </>) : (
              <p className="NoData">Không có data</p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

KeyAccountProducts.propTypes = {
  isKACreating: PropTypes.bool,
};

KeyAccountProducts.defaultProps = {
  isKACreating: true,
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveKeyAccountProductItem: (payload) => dispatch(saveKeyAccountProductItem(payload)),
    getKeyAccountProducts: (payload) => dispatch(getKeyAccountProducts(payload)),
    saveOriKeyAccountProducts: (payload) => dispatch(saveOriKeyAccountProducts(payload)),
    saveKeyAccountProducts: (payload) => dispatch(saveKeyAccountProducts(payload)),
  };
};
const mapStateToProps = createStructuredSelector({
  keyAccountProducts: makeSelectKeyAccountProducts(),
  oriKeyAccountProducts: makeSelectOriKeyAccountProducts(),
  keyAccountDetails: makeSelectKeyAccountDetails(),
  actionLoading: makeSelectKeyAccountActionLoading(),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(KeyAccountProducts);
