import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { validDate } from 'utils/helper';
import { dateFormat } from 'utils/constanst/dateConstants';
import format from 'date-fns/format';
import { activeStatusMap } from 'utils/constanst/common';
import { applyStatusMap } from 'utils/constanst/adminPolicyPriceContants';
import { Link } from 'react-router-dom';

import gridClasses from '../PolicyPriceListGrid.module.scss';
import classes from './PolicyPriceListItem.module.scss';

class PolicyPriceListItem extends PureComponent {
  
  goToDetails = (e) => {
    this.props.history.push(`/policy/${this.props.id}`);
  };


  render() {
    const {
      id,
      code,
      is_retail,
      description,
      count_product,
      count_customer,
      isSelected,
      start_date,
      end_date,
      active,
      apply,
    } = this.props;

    return (
      <Link
        className={`${classes.Item} ${gridClasses.Row}`}
        to={`policy/${id}` } 
      >
        <div className={`${gridClasses.Col}`}>{code}</div>
        <div className={`${gridClasses.Col}`}>{description}</div>
        <div className={`${gridClasses.Col}`}>{is_retail ? 'Giá bán lẻ' : 'Giá bán base'}</div>
        <div className={`${gridClasses.Col}`}>{count_customer}</div>
        <div className={`${gridClasses.Col}`}>{count_product}</div>
        <div className={`${gridClasses.Col}`}>
          {validDate(start_date)
            ? format(validDate(start_date), dateFormat)
            : ''}
        </div>
        <div className={`${gridClasses.Col}`}>
          {validDate(end_date) ? format(validDate(end_date), dateFormat) : ''}
        </div>
        <div className={`${gridClasses.Col}`}>
          {apply ? applyStatusMap[apply] : ''}
        </div>
        <div className={`${gridClasses.Col}`}>
          {active ? activeStatusMap[active] : ''}
        </div>
      </Link>
    );
  }
}

PolicyPriceListItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  code: PropTypes.string,
  description: PropTypes.string,
  start_date: PropTypes.string,
  end_date: PropTypes.string,
  active: PropTypes.number,
  apply: PropTypes.number,
  count_product: PropTypes.number,
  count_customer: PropTypes.number,
};

PolicyPriceListItem.defaultProps = {};

export default withRouter(PolicyPriceListItem);
