import {
  GET_DEPARTMENT_LIST,
  SAVE_DEPARTMENT_LIST,

  GET_ROLE_LIST,
  SAVE_ROLE_LIST,

  GET_USER_LIST,
  SAVE_USER_LIST,
  SAVE_USER_LIST_ITEM,
  SAVE_USER_LIST_TOTAL,

  GET_SALES_USER_LIST,
  SAVE_SALES_USER_LIST,
  SAVE_SALES_USER_LIST_TOTAL,

  SAVE_LOADING,
  SAVE_ACTION_LOADING,

  GET_USER,
  UPDATE_USER,
  RESET_USER,

  SAVE_ORI_USER_DETAILS,
  SAVE_USER_DETAILS,

  SAVE_ORI_USER_ROLES,
  SAVE_USER_ROLES,
  SAVE_USER_ROLE_ITEM,
  REMOVE_USER_ROLE_ITEMS,
} from 'redux/constants/admin/userConstants';

export function getDepartmentList(payload) {
  return {
    type: GET_DEPARTMENT_LIST,
    payload,
  };
}
export function getRoleList(payload) {
  return {
    type: GET_ROLE_LIST,
    payload,
  };
}

export function getUserList(payload) {
  return {
    type: GET_USER_LIST,
    payload,
  };
}

export function getUser(id) {
  return {
    type: GET_USER,
    id,
  };
}

export function updateUser(payload) {
  return {
    type: UPDATE_USER,
    payload,
  };
}

export function getSalesUserList(payload) {
  return {
    type: GET_SALES_USER_LIST,
    payload,
  };
}

// mutation
export function saveDepartmentList(departmentList) {
  return {
    type: SAVE_DEPARTMENT_LIST,
    departmentList,
  };
}
export function saveRoleList(roleList) {
  return {
    type: SAVE_ROLE_LIST,
    roleList,
  };
}

export function saveLoading(loading) {
  return {
    type: SAVE_LOADING,
    loading,
  };
}
export function saveActionLoading(actionLoading) {
  return {
    type: SAVE_ACTION_LOADING,
    actionLoading,
  };
}
export function saveUserList(userList) {
  return {
    type: SAVE_USER_LIST,
    userList,
  };
}
export function saveUserListItem(userListItem) {
  return {
    type: SAVE_USER_LIST_ITEM,
    userListItem,
  };
}
export function saveUserListTotal(userListTotal) {
  return {
    type: SAVE_USER_LIST_TOTAL,
    userListTotal,
  };
}

export function saveSalesUserList(salesUserList) {
  return {
    type: SAVE_SALES_USER_LIST,
    salesUserList,
  };
}
export function saveSalesUserListTotal(salesUserListTotal) {
  return {
    type: SAVE_SALES_USER_LIST_TOTAL,
    salesUserListTotal,
  };
}

export function resetUser(payload) {
  return {
    type: RESET_USER,
    payload,
  };
}

export function saveOriUserDetails(oriUserDetails) {
  return {
    type: SAVE_ORI_USER_DETAILS,
    oriUserDetails,
  };
}
export function saveUserDetails(userDetails) {
  return {
    type: SAVE_USER_DETAILS,
    userDetails,
  };
}

export function saveOriUserRoles(oriUserRoles) {
  return {
    type: SAVE_ORI_USER_ROLES,
    oriUserRoles,
  };
}
export function saveUserRoles(userRoles) {
  return {
    type: SAVE_USER_ROLES,
    userRoles,
  };
}
export function saveUserRoleItem(userRoleItem) {
  return {
    type: SAVE_USER_ROLE_ITEM,
    userRoleItem,
  };
}
export function removeUserRoleItems(userRoleItems) {
  return {
    type: REMOVE_USER_ROLE_ITEMS,
    userRoleItems,
  };
}
