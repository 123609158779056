export const ACTIVE = 1;
export const DEACTIVE = -1;
export const APPROVE = 1;
export const UNAPPROVE = -1;

export const statusMap = {
  [ACTIVE]: 'Đang hoạt động',
  [DEACTIVE]: 'Ngừng hoạt động',
}

export const listDisplayFields = [
  {
    name: 'code',
    label: 'Mã quản lý',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'name',
    label: 'Tên loại vận chuyển',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'type',
    label: 'Loại vận chuyển',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'date',
    label: 'Ngày cập nhật cuối',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'status',
    label: 'Trạng thái',
    sortable: false,
    sortDir: '',
  },
]
