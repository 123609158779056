import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { debounce } from 'lodash';

import {
  getAssetComponentList,
  saveAssetComponentAllowedActions,
  saveAssetComponentVisibility,
} from 'redux/actions/admin/assetComponentActions';
import {
  makeSelectAssetComponentList,
  makeSelectAssetComponentListTotal,
  makeSelectAssetComponentLoading,
  makeSelectAssetComponentActionLoading,
  makeSelectAssetComponentAllowedActions,
  makeSelectAssetComponentVisibility,
} from 'redux/selectors';
import {
  listParamsMap,
  listDisplayFields,
} from 'utils/constanst/adminAssetComponentConstants';
import {
  getSortsString,
  getDisplayFields,
} from 'utils/helper';
import { withRouter } from 'react-router-dom';
import { isActionAllowed } from 'utils/helper/authorization';
import authApi from 'utils/api/authApi';
import assetComponentApi from 'utils/api/admin/assetComponentApi';

import AssetComponentListFilters from 'components/admin/AssetComponent/List/Filters/AssetComponentListFilters';
import AssetComponentList from 'components/admin/AssetComponent/List/AssetComponentList';
import AssetComponentFormDialog from 'components/admin/AssetComponent/FormDialog/AssetComponentFormDialog';
import ListPagination from 'components/ListPagination/ListPagination';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import Button from '@material-ui/core/Button';

import classes from './AssetComponents.module.scss';
import { toast } from 'react-toastify';

class AssetComponents extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      listParams: { ...listParamsMap },
      assetComponentFormDialogOpen: false,
      assetComponentFormDialogData: null,
      assetComponentFormDialogAction: null,
      confirmDialogOpen: false,
      confirmDialogTitle: '',
      confirmDialogMessage: '',
      confirmDialogSubmit: null,
      confirmDialogSubmitParams: null,
      confirmDialogLoading: false,
    };
  }
  async componentDidMount() {
    const { id } = this.props.match.params;
    await this.getAllowedActions();
    this._loadData();
  }

  getAllowedActions = async () => {
    const { data: response } = await authApi.getAllowedActions({
      component: 'asset_components',
    });
    if (!response.result) {
      toast.error(response.message);
      return;
    }
    this.props.saveAssetComponentAllowedActions(response.allowed_actions || []);
    this.props.saveAssetComponentVisibility(response.visibility || null);
  };
  getVisibleColumns = () => {
    const { assetComponentVisibility } = this.props;
    const readableFields = assetComponentVisibility?.read;
    const editableFields = assetComponentVisibility?.edit;
    return listDisplayFields.filter(field =>
      ( readableFields === undefined || [null, undefined].includes(field.authorize_name) || readableFields.includes(field.authorize_name) )
    ).map(field => ({
      ...field,
      editable: editableFields && editableFields.includes(field.authorize_name),
    }));
  }
  _loadData = () => {
    this.props.getAssetComponentList({
      params: this.state.listParams,
    });
  };
  _removeAssetComponent = async (assetComponentId) => {
    this.setState({ confirmDialogLoading: true });
    const { data: response } = await assetComponentApi.deleteAssetComponentMultiple({
      params: {
        ids: [assetComponentId]
      }
    })
    this.setState({ confirmDialogOpen: false, confirmDialogLoading: false });
    if (!response.result) {
      toast.error(response.message);
      return;
    }
    toast.success(`Đã xóa thành công asset component`);
    this._loadData();
  }

  handleSortChange = ({ sortBy, sortDir }) => {
    const newSortString = getSortsString({
      [sortBy]: sortDir,
    });
    this.handleFilterChange([
      {
        name: 'order',
        value: newSortString,
      },
    ]);
  };
  handleFilterChange = (filters, forceResetPage = false) => {
    const paramsFromFilters = filters.reduce((memo, item) => {
      memo[item.name] = item.value;
      return memo;
    }, {});
    const newParams = forceResetPage
      ? {
          ...this.state.listParams,
          ...paramsFromFilters,
          page: 1,
        }
      : {
          ...this.state.listParams,
          ...paramsFromFilters,
        };
    this.setState({
      listParams: newParams,
    });
    this.props.getAssetComponentList({
      params: newParams,
    });
  }
  handleOpenAssetComponentsDialog = (assetComponent) => {
    this.setState({ 
      assetComponentFormDialogOpen: true,
      assetComponentFormDialogData: {...assetComponent},
      assetComponentFormDialogAction: assetComponent?.id ? 'UPDATE' : 'CREATE',
    })
  }
  handleCloseAssetComponentsDialog = () => {
    this.setState({ 
      assetComponentFormDialogOpen: false,
    })
  }
  handleSubmitSuccessAssetComponentsDialog = () => {
    this._loadData();
  }
  handleRemoveAssetComponentsDialog = (assetComponent) => {
    this.setState({
      confirmDialogOpen: true,
      confirmDialogTitle: `Xóa asset branding`,
      confirmDialogMessage: `Bạn chắc muốn xóa ${assetComponent.name} chứ? Sẽ không thể hoàn tác lại hành động này.`,
      confirmDialogSubmit: this._removeAssetComponent,
      confirmDialogSubmitParams: assetComponent.id,
    })
  }
  handleConfirmDialogClose = () => {
    this.setState({ confirmDialogOpen: false })
  }
  

  render() {
    const {
      assetComponentListTotal,
      assetComponentList,
      loading,
      actionLoading,
      assetComponentAllowedActions,
    } = this.props;
    const { 
      listParams,
      assetComponentFormDialogOpen,
      assetComponentFormDialogData,
      assetComponentFormDialogAction,
    } = this.state;
    const isListLoading = loading || actionLoading;
    const isEditAllowed = isActionAllowed('edit', assetComponentAllowedActions);
    const isDeleteAllowed = isActionAllowed('delete', assetComponentAllowedActions);
    const visibleColumns = this.getVisibleColumns();

    return (
      <React.Fragment>
        <div className={classes.PageWrap}>
          <div className={classes.PageHeader}>
            <h1 className={classes.PageTitle}>
              Asset Component
            </h1>
            <div className={classes.PageHeaderToolbar}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={this.handleOpenAssetComponentsDialog}
              >
                Tạo mới
              </Button>
            </div>
          </div>
          <div className={classes.PageMain}>
            <AssetComponentListFilters onFilterChange={debounce(this.handleFilterChange, 500)}/>
            <AssetComponentList
              displayFields={getDisplayFields(
                listParams,
                visibleColumns
              )}
              assetComponentList={assetComponentList}
              isLoading={isListLoading}
              isEditAllowed={isEditAllowed}
              isDeleteAllowed={isDeleteAllowed}
              onSortChange={this.handleSortChange}
              onEditAssetComponents={this.handleOpenAssetComponentsDialog}
              onRemoveAssetComponents={this.handleRemoveAssetComponentsDialog}
            />
          </div>
          <div className={classes.PageFooter}>
            <ListPagination
              page={listParams.page}
              pageSize={listParams.page_size}
              total={assetComponentListTotal}
              listName="asset branding"
              onFilterChange={this.handleFilterChange}
            />
          </div>
        </div>
        {
          assetComponentFormDialogOpen && 
          <AssetComponentFormDialog
            open={assetComponentFormDialogOpen}
            data={assetComponentFormDialogData}
            action={assetComponentFormDialogAction}
            onClose={this.handleCloseAssetComponentsDialog}
            onSubmitSuccess={this.handleSubmitSuccessAssetComponentsDialog}
          />
        }

        { this.state.confirmDialogOpen
          && <ConfirmationDialog
            isOpen={this.state.confirmDialogOpen}
            title={this.state.confirmDialogTitle}
            message={this.state.confirmDialogMessage}
            onClose={this.handleConfirmDialogClose}
            isLoading={this.state.confirmDialogLoading}
            onSubmit={() => {
              this.state.confirmDialogSubmit && this.state.confirmDialogSubmit(this.state.confirmDialogSubmitParams);
            }}
          />
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  assetComponentListTotal: makeSelectAssetComponentListTotal(),
  assetComponentList: makeSelectAssetComponentList(),
  loading: makeSelectAssetComponentLoading(),
  actionLoading: makeSelectAssetComponentActionLoading(),
  assetComponentAllowedActions: makeSelectAssetComponentAllowedActions(),
  assetComponentVisibility: makeSelectAssetComponentVisibility(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getAssetComponentList: (payload) => dispatch(getAssetComponentList(payload)),
    saveAssetComponentAllowedActions: (payload) => dispatch(saveAssetComponentAllowedActions(payload)),
    saveAssetComponentVisibility: (payload) => dispatch(saveAssetComponentVisibility(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter)(AssetComponents);
