import {
  SAVE_SHIPPING_COUPON_LIST,
  SAVE_SHIPPING_COUPON_LIST_TOTAL,

  SAVE_LOADING,

  RESET_SHIPPING_COUPON,

  SAVE_ORI_SHIPPING_COUPON_DETAILS,
  SAVE_SHIPPING_COUPON_DETAILS,

  SAVE_ORI_SHIPPING_COUPON_RULES,
  SAVE_SHIPPING_COUPON_RULES,
  SAVE_SHIPPING_COUPON_RULE_ITEM,
  REMOVE_SHIPPING_COUPON_RULE_ITEMS,

  SAVE_ORI_SHIPPING_COUPON_CUSTOMERS,
  SAVE_SHIPPING_COUPON_CUSTOMERS,
  SAVE_SHIPPING_COUPON_CUSTOMER_ITEM,
  REMOVE_SHIPPING_COUPON_CUSTOMER_ITEMS,

  SAVE_ORI_SHIPPING_COUPON_SALES_CHANNELS,
  SAVE_SHIPPING_COUPON_SALES_CHANNELS,
  SAVE_SHIPPING_COUPON_SALES_CHANNEL_ITEM,
  REMOVE_SHIPPING_COUPON_SALES_CHANNEL_ITEMS,

  SAVE_SHIPPING_COUPON_HISTORY,
} from 'redux/constants/admin/shippingCouponConstants';

const initialState = {
  shippingCouponList: [],
  shippingCouponListTotal: 0,

  loading: false,

  oriShippingCouponDetails: null,
  shippingCouponDetails: null,

  oriShippingCouponRules: [],
  shippingCouponRules: [],

  oriShippingCouponCustomers: [],
  shippingCouponCustomers: [],

  oriShippingCouponSalesChannels: [],
  shippingCouponSalesChannels: [],

  shippingCouponHistory: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_LOADING: {
      return {
        ...state,
        loading: action.loading,
      };
    }

    case SAVE_SHIPPING_COUPON_LIST: {
      const list = action.shippingCouponList;
      return {
        ...state,
        shippingCouponList: list ? [...list] : [],
      };
    }
    case SAVE_SHIPPING_COUPON_LIST_TOTAL: {
      return {
        ...state,
        shippingCouponListTotal: action.shippingCouponListTotal,
      };
    }

    case RESET_SHIPPING_COUPON: {
      return {
        ...state,
        oriShippingCouponDetails: null,
        shippingCouponDetails: null,
        oriShippingCouponRules: [],
        shippingCouponRules: [],
        oriShippingCouponSalesChannels: [],
        shippingCouponSalesChannels: [],
        oriShippingCouponCustomers: [],
        shippingCouponCustomers: [],
      };
    }

    case SAVE_ORI_SHIPPING_COUPON_DETAILS: {
      return {
        ...state,
        oriShippingCouponDetails: action.oriShippingCouponDetails,
      };
    }
    case SAVE_SHIPPING_COUPON_DETAILS: {
      return {
        ...state,
        shippingCouponDetails: action.shippingCouponDetails,
      };
    }

    case SAVE_ORI_SHIPPING_COUPON_RULES: {
      return {
        ...state,
        oriShippingCouponRules: [...action.oriShippingCouponRules],
      };
    }
    case SAVE_SHIPPING_COUPON_RULES: {
      return {
        ...state,
        shippingCouponRules: [...action.shippingCouponRules],
      };
    }
    case SAVE_SHIPPING_COUPON_RULE_ITEM: {
      const list = [...state.shippingCouponRules];
      const comparedField = action.shippingCouponRuleItem.idStr
        ? 'idStr'
        : 'id';
      const index = list.findIndex((p) => p[comparedField] === action.shippingCouponRuleItem[comparedField]);
      if (index > -1) {
        const tempItem = { ...list[index] };
        list[index] = { ...tempItem, ...action.shippingCouponRuleItem };
      } else {
        list.push({ ...action.shippingCouponRuleItem });
      }
      return {
        ...state,
        shippingCouponRules: list,
      };
    }
    case REMOVE_SHIPPING_COUPON_RULE_ITEMS: {
      const list = state.shippingCouponRules.filter(
        (p) =>
          !action.shippingCouponRuleItems.some((item) => {
            const comparedField = item.idStr ? 'idStr' : 'id';
            return item[comparedField] === p[comparedField];
          })
      );
      return {
        ...state,
        shippingCouponRules: list,
      };
    }

    case SAVE_ORI_SHIPPING_COUPON_SALES_CHANNELS: {
      return {
        ...state,
        oriShippingCouponSalesChannels: [...action.oriShippingCouponSalesChannels],
      };
    }
    case SAVE_SHIPPING_COUPON_SALES_CHANNELS: {
      return {
        ...state,
        shippingCouponSalesChannels: [...action.shippingCouponSalesChannels],
      };
    }
    case SAVE_SHIPPING_COUPON_SALES_CHANNEL_ITEM: {
      const list = [...state.shippingCouponSalesChannels];
      const comparedField = action.shippingCouponSalesChannelItem.idStr
        ? 'idStr'
        : 'id';
      const index = list.findIndex((p) => p[comparedField] === action.shippingCouponSalesChannelItem[comparedField]);
      if (index > -1) {
        const tempItem = { ...list[index] };
        list[index] = { ...tempItem, ...action.shippingCouponSalesChannelItem };
      } else {
        list.push({ ...action.shippingCouponSalesChannelItem });
      }
      return {
        ...state,
        shippingCouponSalesChannels: list,
      };
    }
    case REMOVE_SHIPPING_COUPON_SALES_CHANNEL_ITEMS: {
      const list = state.shippingCouponSalesChannels.filter(
        (p) =>
          !action.shippingCouponSalesChannelItems.some((item) => {
            const comparedField = item.idStr ? 'idStr' : 'id';
            return item[comparedField] === p[comparedField];
          })
      );
      return {
        ...state,
        shippingCouponSalesChannels: list,
      };
    }

    case SAVE_ORI_SHIPPING_COUPON_CUSTOMERS: {
      return {
        ...state,
        oriShippingCouponCustomers: [...action.oriShippingCouponCustomers],
      };
    }
    case SAVE_SHIPPING_COUPON_CUSTOMERS: {
      return {
        ...state,
        shippingCouponCustomers: [...action.shippingCouponCustomers],
      };
    }
    case SAVE_SHIPPING_COUPON_CUSTOMER_ITEM: {
      const list = [...state.shippingCouponCustomers];
      const comparedField = action.shippingCouponCustomerItem.idStr
        ? 'idStr'
        : 'id';
      const index = list.findIndex((p) => p[comparedField] === action.shippingCouponCustomerItem[comparedField]);
      if (index > -1) {
        const tempItem = { ...list[index] };
        list[index] = { ...tempItem, ...action.shippingCouponCustomerItem };
      } else {
        list.push({ ...action.shippingCouponCustomerItem });
      }
      return {
        ...state,
        shippingCouponCustomers: list,
      };
    }
    case REMOVE_SHIPPING_COUPON_CUSTOMER_ITEMS: {
      const list = state.shippingCouponCustomers.filter(
        (p) =>
          !action.shippingCouponCustomerItems.some((item) => {
            const comparedField = item.idStr ? 'idStr' : 'id';
            return item[comparedField] === p[comparedField];
          })
      );
      return {
        ...state,
        shippingCouponCustomers: list,
      };
    }

    case SAVE_SHIPPING_COUPON_HISTORY: {
      return {
        ...state,
        shippingCouponHistory: [...action.shippingCouponHistory],
      };
    }

    default:
      return state;
  }
};

export default reducer;
