import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  getStoreDetails,
  saveStoreAllowedActions,
} from 'redux/actions/admin/storeActions';
import { createStructuredSelector } from "reselect";
import {
  makeSelectStoreDetails,
  makeSelectStoreList,
  makeSelectStoreAllowedActions,
} from 'redux/selectors';
import { withRouter } from 'react-router-dom';
import { relatedPageSize } from 'utils/constanst/adminStoreConstants';
import { isActionAllowed } from 'utils/helper/authorization';
import authApi from 'utils/api/authApi';
import userApi from 'utils/api/admin/userApi';
import storeApi from 'utils/api/admin/storeApi';
import { toast } from 'react-toastify';

import StoreDetails from 'components/admin/StoreDetails/StoreDetails';
import DetailsPageHeader from 'components/DetailsPageHeader/DetailsPageHeader';
import RelatedStores from 'components/admin/StoreDetails/RelatedStores/RelatedStores';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import Button from '@material-ui/core/Button';
import PageNotFound from 'views/PageNotFound/PageNotFound';

import classes from './Store.module.scss';

class Store extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      hasChanges: false,
      nextStoreId: null,
      prevStoreId: null,
      relatedStores: [],
      editMode: false,
      resetPwLoading: false,

      isConfirmDialogOpen: false,
      isDeleting: false,
    }
  }

  async componentDidMount() {
    await this.getAllowedActions();

    const { id } = this.props.match.params;
    this.prepareDetailsData(id);
  }

  async componentDidUpdate(prevProps) {
    const { id } = this.props.match.params;
    if (id && id !== prevProps.match.params.id) {
      await this.getAllowedActions();

      this.prepareDetailsData(id);
    }

    const { storeDetails } = this.props;
    if (storeDetails && storeDetails.id) {
      if (!prevProps.storeDetails || storeDetails.id !== prevProps.storeDetails.id) {
        if (
          !prevProps.storeDetails ||
          !prevProps.storeDetails.id ||
          isNaN(prevProps.storeDetails.id)
        ) {
          const baseLink = this.getBaseLink();
          // Redirect to edit mode after successfully creating a store
          this.props.history.replace(`${baseLink}/${storeDetails.id}`);
          return;
        }
      }
    }
  }

  getAllowedActions = async () => {
    const { data: response } = await authApi.getAllowedActions({
      component: this.props.unapprovedPharmacy ? 'unapproved_pharmacy' : this.props.unapprovedDoctor ? 'unapproved_doctor' : 'customer',
    });
    if (!response.result) {
      toast.error(response.message);
      return;
    }
    this.props.saveStoreAllowedActions(response.allowed_actions || []);
  }

  prepareDetailsData = (id) => {
    const isCreateAllowed = isActionAllowed('create', this.props.storeAllowedActions);
    const storeId = Number(id);
    if (!isNaN(storeId) && storeId > 0) {
      this.setState({
        editMode: true
      })
      this.loadData(storeId);
      this.setRelatedStores(storeId);
      return;
    }
    // is creating
    if (!isCreateAllowed) {
      window.location.replace(`/403`);
    }
  }

  loadData = (id) => {
    this.props.getStoreDetails({
      id,
      params: {},
    });
  }

  getBaseLink = () => {
    const { unapprovedPharmacy, unapprovedDoctor } = this.props;
    let baseLink;
    if (unapprovedPharmacy) {
      baseLink = '/unapproved-pharmacy';
    } else if (unapprovedDoctor) {
      baseLink = '/unapproved-doctor';
    } else {
      baseLink = '/customer';
    }
    return baseLink;
  }

  backToList = () => {
    const backLink = this.getBaseLink();
    this.props.history.push(backLink);
  }

  goToPrevStore = () => {
    const baseLink = this.getBaseLink();
    this.props.history.push(`${baseLink}/${this.state.prevStoreId}`);
  }

  goToNextStore = () => {
    const baseLink = this.getBaseLink();
    this.props.history.push(`${baseLink}/${this.state.nextStoreId}`);
  }

  setRelatedStores = (id) => {
    const { storeList } = this.props;
    const index = storeList.findIndex(p => p.id.toString() === id.toString());
    if (index < 0) {
      this.setState({
        relatedStores: [],
        nextStoreId: null,
        prevStoreId: null,
      });
      return;
    }

    const nextStores = storeList.slice(index + 1, index + relatedPageSize + 1);
    const prevIndex = (index - 1) > -1 ? (index - 1) : storeList.length - 1
    if (nextStores.length === relatedPageSize) {
      this.setState({
        relatedStores: nextStores,
        nextStoreId: nextStores[0]?.id,
        prevStoreId: storeList[prevIndex]?.id,
      });
      return;
    }

    const lastIndex = Math.min(relatedPageSize - nextStores.length, index);
    const prevStores = storeList.slice(0, lastIndex);
    const relatedStores = [...nextStores, ...prevStores];
    this.setState({
      relatedStores: relatedStores,
      nextStoreId: relatedStores[0]?.id,
      prevStoreId: storeList[prevIndex]?.id,
    });
    return;
  }

  onChange = (hasChanges) => {
    this.setState({
      hasChanges
    })
  }

  onCancel = () => {
    this.storeInfoRef.revertChanges();
    this.setState({
      hasChanges: false
    })
  }

  onSave = () => {
    const saved = this.storeInfoRef.saveChanges();
    saved && this.setState({
      hasChanges: false
    })
  }

  onDataRefresh = () => {
    this.state.editMode && this.props.storeDetails?.id && this.loadData(this.props.storeDetails.id);
    this.storeChangeRef && this.storeChangeRef.loadData();
  }

  handleResetPassword = async () => {
    try {
      this.setState({ resetPwLoading: true });
      const { data: response } = await userApi.resetPassword({
        params: {
          id: this.props.storeDetails?.getcare_user_id,
        },
      });
      this.setState({ resetPwLoading: false });
      if (!response?.result) {
        toast.error(response?.message);
        return;
      }
      toast.success(response.message);
    } catch(err) {
      this.setState({ resetPwLoading: false });
      toast.error(err);
    }
  }

  handleDelete = async () => {
    const { storeDetails } = this.props;
    this.setState({ isDeleting: true });
    try {
      const { data: response } = await storeApi.deleteStore({
        params: { id: storeDetails?.id },
      });
      this.setState({ isDeleting: false });
      if (!response?.result) {
        toast.error(response.message);
        return;
      }
      toast.success(`Khách hàng ${storeDetails?.name} đã được xoá.`);
      this.handleDeleteSuccess();
    } catch(err) {
      this.setState({ isDeleting: false });
      toast.error(err);
    }
  }

  handleConfirmDialogOpen = (e) => {
    this.setState({
      isConfirmDialogOpen: true,
    });
  }
  handleConfirmDialogClose = () => {
    this.setState({
      isConfirmDialogOpen: false,
    });
  }
  handleDeleteSuccess = () => {
    this.handleConfirmDialogClose();
    this.backToList();
  }

  render() {
    const { storeDetails, storeAllowedActions } = this.props;
    const { prevStoreId, nextStoreId, relatedStores, hasChanges, editMode, isConfirmDialogOpen, isDeleting, resetPwLoading } = this.state;
    const isLoading = editMode && !storeDetails;
    const wasStoreSetup = storeDetails?.was_setup;

    const title = editMode ? storeDetails && storeDetails.name : 'Thông tin khách hàng';
    const isCreateAllowed = !editMode && isActionAllowed('create', storeAllowedActions);
    const isEditAllowed = editMode && isActionAllowed('edit', storeAllowedActions);
    const isApproveAllowed = isActionAllowed('approve', storeAllowedActions);
    const isResetPasswordAllowed = isActionAllowed('reset_password', storeAllowedActions);

    const isResetPasswordDisabled = resetPwLoading || !storeDetails?.getcare_user_id;

    if (storeDetails && !storeDetails.id) {
      return <PageNotFound/>;
    }

    return (
      <div className={`${classes.PageMain} ${isLoading && 'OverlayLoading'}`}>
        <div className={classes.PageMainHeader}>
          <DetailsPageHeader
            isLoading={isLoading}
            title={title}
            prevItemId={prevStoreId}
            nextItemId={nextStoreId}
            editMode={editMode}
            isCreateAllowed={isCreateAllowed}
            isEditAllowed={isEditAllowed}
            isDeleteAllowed={true}
            isResetPasswordAllowed={isResetPasswordAllowed}
            isResetPasswordDisabled={isResetPasswordDisabled}
            resetPwLoading={resetPwLoading}
            hasChanges={hasChanges}
            saveLabel={editMode ? 'Xác nhận thay đổi' : 'Thêm mới'}
            backToList={this.backToList}
            goToPrevItem={this.goToPrevStore}
            goToNextItem={this.goToNextStore}
            onCancel={editMode && this.onCancel}
            onSave={this.onSave}
            onDelete={this.handleConfirmDialogOpen}
            onResetPassword={this.handleResetPassword}

          />
        </div>
        {relatedStores.length > 0 &&
          <div className={classes.PageSide}>
            <div className={classes.PanelWrap}>
              <div className={classes.PageSideContent}>
                <RelatedStores relatedStores={relatedStores} baseUrl={this.getBaseLink()} />
              </div>
            </div>
          </div>
        }
        <div className={classes.PageContent}>
          <StoreDetails
            storeDetails={storeDetails}
            isCreateAllowed={isCreateAllowed}
            isEditAllowed={isEditAllowed}
            isApproveAllowed={isApproveAllowed}
            setRef={(ref) => this.storeInfoRef = ref}
            setStoreChangeRef={(ref) => this.storeChangeRef = ref}
            onChange={this.onChange}
            onSave={this.onSave}
            editMode={editMode}
            onDataUpdated={this.onDataRefresh}
          />
        </div>

        { isConfirmDialogOpen && (
          <ConfirmationDialog
            isOpen={isConfirmDialogOpen}
            isLoading={isDeleting}
            type={wasStoreSetup ? 'info' : 'normal'}
            title="Xoá khách hàng"
            message={wasStoreSetup
              ? <>
                  <p>Khách hàng <strong>{storeDetails?.name}</strong> đã được cấu hình áp dụng trên hệ thống.</p>
                  <p className="TextPrimary">Bạn không thể xoá khách hàng này.</p>
                </>
              : <p>Bạn có chắc xoá khách hàng <strong>{storeDetails?.name}</strong>?</p>
            }
            dialogAction={wasStoreSetup
              ? <Button variant="contained" onClick={this.handleConfirmDialogClose}>
                  Đóng
                </Button>
              : undefined
            }
            onClose={wasStoreSetup ? undefined: this.handleConfirmDialogClose}
            onSubmit={wasStoreSetup ? undefined: this.handleDelete}
          />
        ) }
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  storeDetails: makeSelectStoreDetails(),
  storeList: makeSelectStoreList(),
  storeAllowedActions: makeSelectStoreAllowedActions(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getStoreDetails: (payload) => dispatch(getStoreDetails(payload)),
    saveStoreAllowedActions: (payload) => dispatch(saveStoreAllowedActions(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter)(Store);