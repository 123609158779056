import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Button, IconButton } from '@material-ui/core';
import {
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  ArrowBack,
  CheckOutlined,
} from '@material-ui/icons';

import classes from './DetailsPageHeader.module.scss';

class DetailsHeader extends PureComponent {
  render() {
    const {
      title,
      prevItemId,
      nextItemId,
      onCancel,
      onSave,
      hasChanges,
      goToPrevItem,
      goToNextItem,
      saveLabel,
      editMode,
      isCreateAllowed,
      isEditAllowed,
      isDeleteAllowed,
      isResetPasswordAllowed,
      isResetPasswordDisabled,
      resetPwLoading,
    } = this.props;

    return (
      <div className={classes.Wrap}>
        <div className={classes.NameWrap}>
          {prevItemId && goToPrevItem && (
            <IconButton disabled={!prevItemId} size="small" onClick={goToPrevItem}>
              <ArrowBackIosOutlined fontSize="small" />
            </IconButton>
          )}
          {nextItemId && goToNextItem && (
            <IconButton disabled={!nextItemId} size="small" onClick={goToNextItem}>
              <ArrowForwardIosOutlined fontSize="small" />
            </IconButton>
          )}
          <h1 className={classes.Name}>{title ? title : '...'}</h1>
        </div>
        <Button
          variant="outlined"
          size="small"
          startIcon={<ArrowBack />}
          onClick={this.props.backToList}>
          Trở về
        </Button>
        { editMode && isDeleteAllowed && (
          <Button
            variant="outlined"
            size="small"
            color="secondary"
            onClick={this.props.onDelete}
          >Xoá khách hàng</Button>
        )}
        {editMode && isResetPasswordAllowed && (
          <Button
            variant="outlined"
            size="small"
            color="primary"
            disabled={isResetPasswordDisabled}
            onClick={this.props.onResetPassword}
            className={resetPwLoading ? 'OverlayLoading': ''}
          >
            Reset password
          </Button>
        )}
        {((!editMode && isCreateAllowed) || (editMode && isEditAllowed)) && onCancel && (
          <Button
            size="small"
            disabled={!hasChanges}
            variant="outlined"
            color="secondary"
            onClick={onCancel}>
            Huỷ thay đổi
          </Button>
        )}
        {((!editMode && isCreateAllowed) || (editMode && isEditAllowed)) && onSave && (
          <Button
            size="small"
            variant="contained"
            color="primary"
            disabled={!hasChanges}
            startIcon={<CheckOutlined />}
            onClick={onSave}>
            {saveLabel ? saveLabel : 'Xác nhận thay đổi'}
          </Button>
        )}
      </div>
    );
  }
}

DetailsHeader.propTypes = {
  isLoading: PropTypes.bool,
  hasChanges: PropTypes.bool,
  title: PropTypes.string,
  isCreateAllowed: PropTypes.bool,
  isEditAllowed: PropTypes.bool,
  prevItemId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  nextItemId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  backToList: PropTypes.func,
  goToPrevItem: PropTypes.func,
  goToNextItem: PropTypes.func,
};

DetailsHeader.defaultProps = {
  title: '',
  isLoading: false,
  hasChanges: false,
  isCreateAllowed: true,
  isEditAllowed: true,
};

export default DetailsHeader;
