export const GET_ASSET_BRANDING_LIST = 'getcare/mdm/assetBranding/GET_ASSET_BRANDING_LIST';
export const UPDATE_ASSET_BRANDING_LIST = 'getcare/mdm/assetBranding/UPDATE_ASSET_BRANDING_LIST';

// mutation
export const SAVE_ASSET_BRANDING_LIST_TOTAL = 'getcare/mdm/assetBranding/SAVE_ASSET_BRANDING_LIST_TOTAL';
export const SAVE_LOADING = 'getcare/mdm/assetBranding/SAVE_LOADING';
export const SAVE_ACTION_LOADING = 'getcare/mdm/assetBranding/SAVE_ACTION_LOADING';

export const SAVE_ASSET_BRANDING_LIST = 'getcare/mdm/assetBranding/SAVE_ASSET_BRANDING_LIST';
export const REMOVE_ASSET_BRANDING_ITEMS = 'getcare/mdm/assetBranding/REMOVE_ASSET_BRANDING_ITEMS';
export const SAVE_ASSET_BRANDING_ITEM = 'getcare/mdm/assetBranding/SAVE_ASSET_BRANDING_ITEM';

export const SAVE_ASSET_BRANDING_ALLOWED_ACTIONS = 'getcare/mdm/assetBranding/SAVE_ASSET_BRANDING_ALLOWED_ACTIONS';
export const SAVE_ASSET_BRANDING_VISIBILITY = 'getcare/mdm/assetBranding/SAVE_ASSET_BRANDING_VISIBILITY';
