import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import productApi from 'utils/api/admin/productApi';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ProductListMapping from 'components/admin/ProductList/ProductListMapping/ProductListMapping';

import classes from './VendorProductMappingDialog.module.scss';

class VendorProductMappingDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedProduct: null,
      willRemoveProductIds: [],
      willForceResetList: false,
      isLoading: false,
    };
  }
  handleSubmit = async () => {
    const { selectedProduct, willRemoveProductIds } = this.state;
    if (willRemoveProductIds.length > 0) {
      this.setState({
        isLoading: true,
      });
      const { data: response } = await productApi.deleteProducts({
        params: {
          getcare_product_ids: [...willRemoveProductIds],
        },
      });
      this.setState({
        isLoading: false,
      });
      if (!response?.result) return;
      if (!selectedProduct) {
        this.setState({
          willRemoveProductIds: [],
          willForceResetList: true,
        });
      }
    }
    if (selectedProduct) {
      this.props.onSubmitProductMapping(selectedProduct);
    }
  };
  onClose = () => {
    this.props.onClose();
  };
  onEntered = () => {
    this.setState({
      selectedProduct: null,
      willRemoveProductIds: [],
    });
  };
  handleProductSelectedToggle = ({ productItem, isSelected }) => {
    this.setState({
      selectedProduct: isSelected ? { ...productItem } : null,
    });
  }
  handleProductRemovedToggle = ({ productId, willRemove }) => {
    const tempRemovedItems = [...this.state.willRemoveProductIds];
    this.setState({
      willRemoveProductIds: willRemove
        ? [...tempRemovedItems, productId]
        : tempRemovedItems.filter(id => id !== productId),
    });
  }

  render() {
    const { isOpen, productName } = this.props;
    const { selectedProduct, willRemoveProductIds, isLoading, willForceResetList } = this.state;

    return (
      <Dialog
        open={isOpen}
        onClose={this.onClose}
        onEntered={this.onEntered}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.Dialog}
        maxWidth={false}
        fullWidth
      >
        <div className={`${classes.DialogContentWrap}`}>
          <div className={classes.DialogTitle}>
            <DialogTitle className={classes.DialogTitleInner}>Tìm sản phẩm để map cho <strong>{ productName }</strong></DialogTitle>
            <IconButton size="small" onClick={this.props.onClose}><Close/></IconButton>
          </div>
          <DialogContent className={`${classes.DialogContent} ${isLoading ? 'OverlayLoading' : ''}`}>
            <ProductListMapping
              selectedProduct={selectedProduct}
              willForceResetList={willForceResetList}
              willRemoveProductIds={willRemoveProductIds}
              onItemSelectedToggle={this.handleProductSelectedToggle}
              onItemRemovedToggle={this.handleProductRemovedToggle}
            />
          </DialogContent>
          <DialogActions className={classes.DialogActions}>
            <Button variant="outlined" onClick={this.props.onClose}>
              Huỷ bỏ
            </Button>
            <Button
              disabled={!selectedProduct && !willRemoveProductIds.length}
              variant="contained"
              onClick={this.handleSubmit}
              color="primary"
              autoFocus
            >
              Xác nhận
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    );
  }
}

VendorProductMappingDialog.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  productName: PropTypes.string,
  onSubmitProductMapping: PropTypes.func,
};

VendorProductMappingDialog.defaultProps = {
  isOpen: false,
  title: '',
  productName: '',
};

export default VendorProductMappingDialog;
