import {
  GET_ASSET_COMPONENT_LIST,
  SAVE_ASSET_COMPONENT_LIST,
  SAVE_ASSET_COMPONENT_LIST_TOTAL,
  SAVE_LOADING,
  REMOVE_ASSET_COMPONENT_ITEMS,
  SAVE_ASSET_COMPONENT_ITEM,
  UPDATE_ASSET_COMPONENT_LIST,
  SAVE_ACTION_LOADING,
  SAVE_ASSET_COMPONENT_ALLOWED_ACTIONS,
  SAVE_ASSET_COMPONENT_VISIBILITY,
} from 'redux/constants/admin/assetComponentConstants';

export function getAssetComponentList(payload) {
  return {
    type: GET_ASSET_COMPONENT_LIST,
    payload
  };
}
export function updateAssetComponentList(payload) {
  return {
    type: UPDATE_ASSET_COMPONENT_LIST,
    payload
  };
}

// mutation
export function saveLoading(loading) {
  return {
    type: SAVE_LOADING,
    loading
  };
}
export function saveActionLoading(actionLoading) {
  return {
    type: SAVE_ACTION_LOADING,
    actionLoading
  };
}
export function saveAssetComponentList(assetComponentList) {
  return {
    type: SAVE_ASSET_COMPONENT_LIST,
    assetComponentList
  };
}
export function saveAssetComponentListTotal(assetComponentListTotal) {
  return {
    type: SAVE_ASSET_COMPONENT_LIST_TOTAL,
    assetComponentListTotal
  };
}
export function removeAssetComponentItems(assetComponentItems) {
  return {
    type: REMOVE_ASSET_COMPONENT_ITEMS,
    assetComponentItems
  };
}

export function saveAssetComponentItem(assetComponentItem) {
  return {
    type: SAVE_ASSET_COMPONENT_ITEM,
    assetComponentItem
  };
}

export function saveAssetComponentAllowedActions(assetComponentAllowedActions) {
  return {
    type: SAVE_ASSET_COMPONENT_ALLOWED_ACTIONS,
    assetComponentAllowedActions
  };
}
export function saveAssetComponentVisibility(assetComponentVisibility) {
  return {
    type: SAVE_ASSET_COMPONENT_VISIBILITY,
    assetComponentVisibility
  };
}
