import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import AssetBrandingListItem from './Item/AssetBrandingListItem';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';

import gridClasses from './AssetBrandingListGrid.module.scss';
import classes from './AssetBrandingList.module.scss';

class AssetBrandingList extends PureComponent {
  render() {
    const { 
      assetBrandingList, 
      isLoading, 
      displayFields, 
      onEditAssetBrandings, 
      onRemoveAssetBrandings,
      isEditAllowed,
      isDeleteAllowed,
    } = this.props;
    return <div className={`${classes.List} ${isLoading && 'OverlayLoading'}`} >
      <div className={`${classes.Body}`}>
        <div className={`${gridClasses.Row} ${classes.Header} ${gridClasses[`Row__column--${displayFields.length}`]}`}>
          { displayFields.map((item,index) => (
            <ListHeaderCol
              key={index}
              className={gridClasses.Col}
              {...item}
              onSortChange={this.props.onSortChange}
            />
          )) }
        </div>
      
        { (assetBrandingList && assetBrandingList.length)
          ? assetBrandingList.map(item => (
            <AssetBrandingListItem
              displayFields={displayFields}
              key={`asset-branding-list-item-${item.id}`}
              {...item}
              isEditAllowed={isEditAllowed}
              isDeleteAllowed={isDeleteAllowed}
              onEditAssetBrandings={() => onEditAssetBrandings({...item})}
              onRemoveAssetBrandings={() => onRemoveAssetBrandings({...item})}
            />))
          : (<p className="NoData">Không cấu hình nào</p>)
        }
      </div>
    </div>;
  }
}

AssetBrandingList.propTypes = {
  displayFields: PropTypes.array,
  assetBrandingList: PropTypes.array,
  isLoading: PropTypes.bool,
  isEditAllowed: PropTypes.bool,
  onSortChange: PropTypes.func,
  onEditAssetBrandings: PropTypes.func,
  onRemoveAssetBrandings: PropTypes.func,
};

AssetBrandingList.defaultProps = {
  displayFields: [],
  assetBrandingList: [],
  isLoading: false,
  isEditAllowed: false,
};

export default AssetBrandingList;
