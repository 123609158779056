import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectSalesChannelCoordinaters } from 'redux/selectors';
import { saveSalesChannelCoordinater, removeSalesChannelCoordinaters } from 'redux/actions/admin/salesChannelActions';
import { getDisplayFields, genID } from 'utils/helper';
import {
  salesChannelCoordinaterListParamsMap,
  salesChannelCoordinaterDisplayFields,
} from 'utils/constanst/adminSalesChannelConstants';

import SalesChannelCoordinaterItem from './SalesChannelCoordinaterItem/SalesChannelCoordinaterItem';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';
import Button from '@material-ui/core/Button';

import gridClasses from './SalesChannelCoordinatersGrid.module.scss';
import classes from './SalesChannelCoordinaters.module.scss';

class SalesChannelCoordinaters extends PureComponent {
  handleCreateNewRow = () => {
    this.props.saveSalesChannelCoordinater({
      idStr: genID(),
      id: 0,
      getcare_sales: null,
    });
  }
  handleSaveCoordinater = (params) => {
    this.props.saveSalesChannelCoordinater(params);
  }
  handleRemoveCoordinater = (params) => {
    this.props.removeSalesChannelCoordinaters([{
      ...params,
    }]);
  }

  render() {
    const { salesChannelCoordinaters, isAssignAllowed } = this.props;
    const isLoading = !salesChannelCoordinaters;
    const displayFields = getDisplayFields(
      { ...salesChannelCoordinaterListParamsMap },
      [...salesChannelCoordinaterDisplayFields]
    );

    return (<div className={classes.ListWrap}>
      <div className={`${classes.List} ${isLoading && 'OverlayLoading'}`}>
        <div
          className={`${classes.Header}`}
          ref={(el) => (this.rowRef = el)}
        >
          <div className={`${gridClasses.Row} ${isAssignAllowed ? gridClasses.RowHasActions : ''} ${classes.Header}`}>
            {displayFields.map((item, index) => (
              <ListHeaderCol
                key={`${item.name}-${index}`}
                className={`${gridClasses.Col} ${classes.HeaderCol}`}
                {...item}
              />
            ))}
            {isAssignAllowed &&
              <div className={`${gridClasses.Col} ${classes.HeaderCol} ${classes.ActionCol}`}>
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  onClick={this.handleCreateNewRow}
                >
                  Thêm dòng
                </Button>
              </div>
            }
          </div>
        </div>
        <div className={`${classes.Body}`}>
          {(salesChannelCoordinaters && salesChannelCoordinaters.length)
            ? salesChannelCoordinaters.map(item => (
              <SalesChannelCoordinaterItem
                key={`coordinater-${item.id || item.idStr}`}
                {...item}
                currentList={[...salesChannelCoordinaters]}
                isAssignAllowed={isAssignAllowed}
                saveCoordinater={this.handleSaveCoordinater}
                removeCoordinater={this.handleRemoveCoordinater}
              />))
            : (<p className="NoData">Không có data</p>)
          }
        </div>
      </div>
    </div>);
  }
}

SalesChannelCoordinaters.propTypes = {
  onSaveCoordinater: PropTypes.func,
  onRemoveCoordinater: PropTypes.func,
};

SalesChannelCoordinaters.defaultProps = {
};

const mapStateToProps = createStructuredSelector({
  salesChannelCoordinaters: makeSelectSalesChannelCoordinaters(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    saveSalesChannelCoordinater: (payload) => dispatch(saveSalesChannelCoordinater(payload)),
    removeSalesChannelCoordinaters: (payload) => dispatch(removeSalesChannelCoordinaters(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(SalesChannelCoordinaters);
