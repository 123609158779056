import {
  GET_UOM_LIST,
  GET_UOM_BASE_LIST,
  SAVE_UOM_LIST,
  SAVE_UOM_BASE_LIST,
  SAVE_UOM_TOTAL,
  SAVE_UOM_BASE_TOTAL,
  GET_PRODUCT_UNIT_LIST,
  SAVE_PRODUCT_UNIT_LIST,
} from 'redux/constants/admin/uomConstants';

export function getUomList(payload) {
  return {
    type: GET_UOM_LIST,
    payload
  };
}
export function getUomBaseList(payload) {
  return {
    type: GET_UOM_BASE_LIST,
    payload
  };
}
export function getProductUnitList(payload) {
  return {
    type: GET_PRODUCT_UNIT_LIST,
    payload
  };
}

// mutation
export function saveUomList(uomList) {
  return {
    type: SAVE_UOM_LIST,
    uomList
  };
}
export function saveUomBaseList(uomBaseList) {
  return {
    type: SAVE_UOM_BASE_LIST,
    uomBaseList
  };
}
export function saveUomTotal(uomTotal) {
  return {
    type: SAVE_UOM_TOTAL,
    uomTotal
  };
}
export function saveUomBaseTotal(uomBaseTotal) {
  return {
    type: SAVE_UOM_BASE_TOTAL,
    uomBaseTotal
  };
}
export function saveProductUnitList(productUnits) {
  return {
    type: SAVE_PRODUCT_UNIT_LIST,
    productUnits
  };
}
