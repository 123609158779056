import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { statusMap } from 'utils/constanst/adminDeliveryConstants';
import { validDate } from 'utils/helper';
import { dateFormat } from 'utils/constanst/dateConstants';
import format from 'date-fns/format';
import gridClasses from '../DeliveryListGrid.module.scss';
import classes from './DeliveryListItem.module.scss';

class DeliveryListItem extends PureComponent {
  notImplemented = (e) => {
    e.preventDefault();
    alert('Feature đang được phát triển');
  }

  render() {
    const {
      id,
      code,
      name,
      status,
      updated_at,
      getcare_logistic_provider_type,
    } = this.props;

    return (
      <Link to={`/delivery/${id}`} onClick={this.notImplemented} className={`${classes.ProductItem} ${gridClasses.Row}`}>
        <div className={gridClasses.Col}>{code}</div>
        <div className={gridClasses.Col}>{name}</div>
        <div className={gridClasses.Col}>{getcare_logistic_provider_type?.type}</div>
        <div className={gridClasses.Col}>{validDate(updated_at) ? format(validDate(updated_at), dateFormat) : ''}</div>
        <div className={gridClasses.Col}>{statusMap[status]}</div>
      </Link>
    )
  }
}

DeliveryListItem.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  name: PropTypes.string,
  code: PropTypes.string,
  getcare_logistic_provider_type: PropTypes.object,
  updated_at: PropTypes.string,
  status: PropTypes.number,
};

export default withRouter(DeliveryListItem);
