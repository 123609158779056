export const GET_STORE_DETAILS = 'getcare/mdm/store/GET_STORE_DETAILS';
export const GET_STORE_LIST = 'getcare/mdm/store/GET_STORE_LIST';
export const UPDATE_STORE = 'getcare/mdm/store/UPDATE_STORE';
export const CREATE_STORE = 'getcare/mdm/store/CREATE_STORE';

export const EXPORT_STORE_LIST_TEMPLATE = 'getcare/mdm/store/EXPORT_STORE_LIST_TEMPLATE';

export const GET_STORE_DELIVERY_ADDRESS_LIST = 'getcare/mdm/store/GET_STORE_DELIVERY_ADDRESS_LIST';
export const UPDATE_STORE_DELIVERY_ADDRESS = 'getcare/mdm/store/UPDATE_STORE_DELIVERY_ADDRESS';
export const DELETE_STORE_DELIVERY_ADDRESS = 'getcare/mdm/store/DELETE_STORE_DELIVERY_ADDRESS';

// mutation
export const SAVE_STORE_DETAILS = 'getcare/mdm/store/SAVE_STORE_DETAILS';
export const SAVE_STORE_LIST = 'getcare/mdm/store/SAVE_STORE_LIST';
export const SAVE_STORE_LIST_LOADING = 'getcare/mdm/store/SAVE_STORE_LIST_LOADING';
export const SAVE_STORE_ACTION_LOADING = 'getcare/mdm/store/SAVE_STORE_ACTION_LOADING';
export const SAVE_STORE_LIST_TOTAL = 'getcare/mdm/store/SAVE_STORE_LIST_TOTAL';

export const APPROVE_STORE_INFO = 'getcare/mdm/store/APPROVE_STORE_INFO';

export const SAVE_STORE_DELIVERY_ADDRESS_LIST = 'getcare/mdm/store/SAVE_STORE_DELIVERY_ADDRESS_LIST';
export const SAVE_STORE_DELIVERY_ADDRESS = 'getcare/mdm/store/SAVE_STORE_DELIVERY_ADDRESS';
export const REMOVE_STORE_DELIVERY_ADDRESS = 'getcare/mdm/store/REMOVE_STORE_DELIVERY_ADDRESS';

export const SAVE_STORE_ALLOWED_ACTIONS = 'getcare/mdm/store/SAVE_STORE_ALLOWED_ACTIONS';
