import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { 
  makeSelectGetcareCategoryList,
} from 'redux/selectors';

import productApi from 'utils/api/admin/productApi';
import vendorProductApi from 'utils/api/admin/vendorProductApi';
import getcareCategoryApi from 'utils/api/admin/getcareCategoryApi';
import { 
  mapGetcareCategoryTrees,
} from 'utils/constanst/adminGetcareCategoryConstants';

import { toast } from 'react-toastify';
import {
  DialogActions,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core/';
import GetcareCategoryFormAutocomplete from 'components/admin/GetcareCategory/FormAutocomplete/FormAutocomplete';
import GetcareCategoryFormSelectTree from 'components/admin/GetcareCategory/FormSelect/FormSelectTree';

import classes from './TagGetcareCategory.module.scss';

class TagGetcareCategoryDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      selectedGetcareCategoryId: null,
      getcareCategoryList: [],
    }
  }
  componentDidMount() {
    this._loadGetcareCategory();
  }

  _loadGetcareCategory = async () => {
    this.setState({ isLoading: true })
    const { data: response } = await getcareCategoryApi.getAll({});
    if (!response?.result) { return; }
    this.setState({ isLoading: false,   getcareCategoryList: mapGetcareCategoryTrees(response.data) })
  }

  onClose = () => {
    this.props.onClose();
  };

  handleTagGetcareCategoryForProducts = async (params) => {
    const { selectedGetcareCategoryId } = this.state;
    const { productIds } = this.props;
    if ( !selectedGetcareCategoryId ) {
      alert("Vui lòng chọn 1 danh mục")
      return;
    }
    this.setState({
      isLoading: true,
    });
    let tagCategoryApi = productApi.pushGetcareCategoryForProducts;
    let requestParams  = {
      id: selectedGetcareCategoryId,
      getcare_product_ids: productIds,
    }
    if ( this.props.isTagForVendor ) {
      tagCategoryApi = vendorProductApi.pushCategoryEcomForVendorProduct;
      requestParams = {
        id: selectedGetcareCategoryId,
        getcare_vendor_product_ids: productIds
      }
    }
    const { data: response } = await tagCategoryApi({
      params: {...requestParams}
    });
    if (!response.result) {
      toast.error(response.message);
      this.setState({ isLoading: false });
      return;
    }
    this.setState({
      isLoading: false,
    }, () => {
      this.props.onClose();
      this.props.onSubmitSuccess();
    });
  }
  handleSaveGetcareCategory = () => {
    this.innerFormRef.submitForm();
  }
  
  render() {
    const { isOpen } = this.props;
    const { isLoading, selectedGetcareCategoryId, getcareCategoryList } = this.state;

    return (
      <Dialog
        open={isOpen}
        onClose={this.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.Dialog}
        fullWidth
        disableBackdropClick={isLoading}
        disableEscapeKeyDown={isLoading}
      >
        <div className={`${isLoading && 'OverlayLoading'}`}>
          <DialogTitle className={classes.DialogTitle}></DialogTitle>
          <DialogContent className={classes.DialogContent}>
              <div className={classes.FieldControl}>
                <label>Tìm kiếm danh mục</label>
                <GetcareCategoryFormAutocomplete
                  loadFromOutside
                  value={selectedGetcareCategoryId}
                  getcareCategoryList={getcareCategoryList}
                  name="id"
                  onChange={(e,value) => {
                    this.setState({ selectedGetcareCategoryId: value })
                  }}
                />
              </div>
              <GetcareCategoryFormSelectTree 
                value={selectedGetcareCategoryId}
                getcareCategoryList={getcareCategoryList}
                onChange={(value) => this.setState({ selectedGetcareCategoryId: value })}
              />
          </DialogContent>
          <DialogActions>
            <Button type="button" variant="outlined" onClick={this.props.onClose}>
              Huỷ bỏ
            </Button>
            <Button
              type="button"
              variant="contained"
              onClick={this.handleTagGetcareCategoryForProducts}
              color="primary"
              autoFocus
            >
              Lưu
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    );
  }
}

TagGetcareCategoryDialog.propTypes = {
  onClose: PropTypes.array,
  isOpen: PropTypes.bool,
  getcareCategory: PropTypes.object,
  action: PropTypes.string,
  onClose: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  productIds: PropTypes.array,
}

TagGetcareCategoryDialog.defaultProps = {
  isOpen: true,
  productIds: [],
}

const mapStateToProps = createStructuredSelector({
  getcareCategoryList: makeSelectGetcareCategoryList(),
});
const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(TagGetcareCategoryDialog);
