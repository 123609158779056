import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import VendorPriceListItem from './VendorPriceListItem/VendorPriceListItem';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';

import gridClasses from './VendorPriceListGrid.module.scss';
import classes from './VendorPriceList.module.scss';

class VendorPriceList extends PureComponent {
  constructor(props) {
    super(props);
    this.rowRef = null;
  }
  isAllItemSelected = () => {
    const { vendorPriceList, selectedItems } = this.props;
    return (
      selectedItems.length > 0 &&
      selectedItems.length >= vendorPriceList.length
    );
  };
  isItemSelected = (item) => {
    return (
      this.props.selectedItems.findIndex((p) => {
        const comparedField = p.idStr ? 'idStr' : 'id';
        return p[comparedField] === item[comparedField];
      }) > -1
    );
  };

  render() {
    const { vendorPriceList, isLoading, displayFields } = this.props;

    return <div
      className={`${classes.List}`}
      style={{
        overflowX: this.rowRef ? 'scroll' : '',
      }}
    >
      <div
        className={`${classes.Header}`}
        ref={(el) => (this.rowRef = el)}
        style={{width: displayFields.length ? `${displayFields.length * 9.375}rem` : 'auto'}}
      >
        <div
          className={`${gridClasses.Row} ${classes.Header}`}
          style={{gridTemplateColumns: `repeat(${displayFields.length}, 9.375rem)`}}
        >
          { displayFields.map((item) => (
            <ListHeaderCol
              key={item.name}
              className={gridClasses.Col}
              {...item}
              onSortChange={this.props.onSortChange}
            />
          )) }
        </div>
      </div>
      <div
        className={`${classes.Body} ${isLoading && 'OverlayLoading'}`}
        style={{width: (this.rowRef?.offsetWidth) || 'auto'}}
      >
        { !isLoading && vendorPriceList.length
          ? vendorPriceList.map((item) => (
            <VendorPriceListItem
              key={`group-${item.id}`}
              {...item}
              isSelected={this.isItemSelected(item)}
              displayFields={[...displayFields]}
              onSavePrice={this.props.onSavePrice}
              onRemovePrice={this.props.onRemovePrice}
              onItemSelectedToggle={this.props.onItemSelectedToggle}
            />))
          : (<p className="NoData">Không có data</p>)
        }
      </div>
    </div>;
  }
}

VendorPriceList.propTypes = {
  displayFields: PropTypes.array,
  vendorPriceList: PropTypes.array,
  selectedItems: PropTypes.array,
  isLoading: PropTypes.bool,
  onSortChange: PropTypes.func,
  onSavePrice: PropTypes.func,
  onRemovePrice: PropTypes.func,
  onItemSelectedToggle: PropTypes.func,
  onAllSelectedToggle: PropTypes.func,
};

VendorPriceList.defaultProps = {
  displayFields: [],
  vendorPriceList: [],
  isLoading: false,
  selectedItems: [],
};

export default VendorPriceList;
