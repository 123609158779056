import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import format from 'date-fns/format';
import { validDate } from 'utils/helper';
import { dateFormat } from 'utils/constanst/dateConstants';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  img: {
    maxWidth: '100%',
    height: 'auto',
  },
  title: {
    fontWeight: '600',
  },
  subTitle: {
    lineHeight: '1.4',
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
}));

function StoreImages(props) {
  const classes = useStyles();

  const { customerImages } = props;
  return (
    <div className={classes.root}>
      { (customerImages && customerImages.length)
        ? <GridList className={classes.gridList} cols={5.5}>
            {customerImages.map((tile) => (
              <GridListTile key={tile.id}>
                <img className={classes.img} src={tile.url} alt={tile.note} />
                <GridListTileBar
                  title={tile.note}
                  subtitle={<span className={classes.subTitle}>Ngày tạo: { validDate(tile.created_at) ? format(validDate(tile.created_at), dateFormat) : '' }</span>}
                  classes={{
                    root: classes.titleBar,
                    title: classes.title,
                  }}
                />
              </GridListTile>
            ))}
          </GridList>
        : (<p className="NoData">Chưa có hình ảnh nào</p>)
      }
    </div>
  );
}

StoreImages.propTypes = {
};
StoreImages.defaultProps = {
};

export default StoreImages;
