import axiosAuth from 'utils/axios/axiosAuth';
import qs from 'querystring';

const groupApi = {
  getGroupCustomerTypes: () => {
    return axiosAuth.get(`getcare_erp_group_type_customer/`);
  },

  getTypes: () => {
    return axiosAuth.get(`getcare_erp_group_type/`);
  },
  getCodeItems: () => {
    return axiosAuth.post(`getcare_erp_group_item_code/`);
  },
  getAll: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_erp_group/`, {
      params: queryParams,
    });
  },
  get: (id) => {
    return axiosAuth.get(`getcare_erp_group/${id}`);
  },
  getNewItemCode: () => {
    return axiosAuth.get(`getcare_erp_group_item_code/`);
  },
  updateGroup: ({ id, params }) => {
    if (!id) {
      // create group
      return axiosAuth.post(`getcare_erp_group/`, params);
    }
    return axiosAuth.put(`getcare_erp_group/${id}`, params);
  },

  // group products
  updateGroupProduct: ({ groupProductId, params }) => {
    if (groupProductId) {
      // update
      return axiosAuth.put(`getcare_erp_group_product/${groupProductId}`, params);
    }
    // create new group product
    return axiosAuth.post(`getcare_erp_group_product/`, params);
  },
  deleteGroupProducts: ({ id, code, groupProductIds }) => {
    return axiosAuth({
      method: 'DELETE',
      url: `getcare_erp_group_product_delete/`,
      data: {
        id,
        code,
        erp_group_product_ids: groupProductIds,
      },
    });
  },
  exportGroupProducts: ({ id, code }) => {
    return axiosAuth.post(`export/erp_group_product/`, qs.stringify({code: code}), {
      headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  },
  importGroupProducts: ({ id, code, params }) => {
    const formData = new FormData();
    formData.append('code', code);
    Object.keys(params).forEach(key => {
      if (params[key]) formData.append(key, params[key])
    });
    return axiosAuth.post(`import/erp_group_product/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  // group vendors
  updateGroupVendor: ({ groupVendorId, params }) => {
    if (groupVendorId) {
      // update
      return axiosAuth.put(`getcare_erp_group_vendor/${groupVendorId}`, params);
    }
    // create new group vendor
    return axiosAuth.post(`getcare_erp_group_vendor/`, params);
  },
  deleteGroupVendors: ({ id, code, groupVendorIds }) => {
    return axiosAuth({
      method: 'DELETE',
      url: `getcare_erp_group_vendor_delete/`,
      data: {
        id,
        code,
        erp_group_vendor_ids: groupVendorIds,
      },
    });
  },
  exportGroupVendors: ({ id, code }) => {
    return axiosAuth.post(`export/erp_group_vendor/`, qs.stringify({code: code}), {
      headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  },
  importGroupVendors: ({ id, code, params }) => {
    const formData = new FormData();
    formData.append('code', code);
    Object.keys(params).forEach(key => {
      if (params[key]) formData.append(key, params[key])
    });
    return axiosAuth.post(`import/erp_group_vendor/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  // group customers
  getGroupCustomers: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_erp_group_customer/`, {
      params: queryParams,
    });
  },
  updateGroupCustomer: ({ groupCustomerId, params }) => {
    if (groupCustomerId) {
      // update
      return axiosAuth.put(`getcare_erp_group_customer/${groupCustomerId}`, params);
    }
    // create new group customer
    return axiosAuth.post(`getcare_erp_group_customer/`, params);
  },
  deleteGroupCustomers: ({ id, code, groupCustomerIds }) => {
    return axiosAuth({
      method: 'DELETE',
      url: `getcare_erp_group_customer_delete/`,
      data: {
        id,
        code,
        erp_group_customer_ids: groupCustomerIds,
      },
    });
  },
  exportGroupCustomers: ({ id, code }) => {
    return axiosAuth.post(`export/erp_group_customer/`, qs.stringify({code: code}), {
      headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  },
  importGroupCustomers: ({ id, code, params }) => {
    const formData = new FormData();
    formData.append('code', code);
    Object.keys(params).forEach(key => {
      if (params[key]) formData.append(key, params[key])
    });
    return axiosAuth.post(`import/erp_group_customer/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  // group customer channels
  getGroupCustomerMappings: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_erp_group_customer_mapping/`, {
      params: queryParams,
    });
  },
  getGroupCustomersByMappings: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_erp_group_customer_channel/`, {
      params: queryParams,
    });
  },
  updateGroupCustomerMapping: ({ groupCustomerMappingId, params, queryParams }) => {
    const qrParams = Object.keys(queryParams).reduce((memo, key) => {
      if (!!queryParams[key]) memo[key] = queryParams[key];
      return memo;
    }, {});

    if (groupCustomerMappingId) {
      return axiosAuth.put(`getcare_erp_group_customer_channel/${groupCustomerMappingId}`, params, {
        params: qrParams,
      });
    }

    // create new group customer channel
    return axiosAuth.post(`getcare_erp_group_customer_channel/`, params, {
      params: qrParams,
    });
  },
  deleteGroupCustomerMappings: ({ id, code, groupCustomerMappingIds }) => {
    return axiosAuth({
      method: 'DELETE',
      url: `getcare_erp_group_customer_channel_delete/`,
      data: {
        id,
        code,
        erp_group_customer_channel_ids: groupCustomerMappingIds,
      },
    });
  },
};

export default groupApi;
