import React, { PureComponent } from 'react';
import {
  listDisplayFields,
} from 'utils/constanst/adminDeliveryConstants';

import DeliveryList from 'components/admin/DeliveryList/DeliveryList';
import deliveryApi from 'utils/api/admin/deliveryApi';
import { toast } from 'react-toastify';

import classes from './Deliveries.module.scss';

class Deliveries extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      deliveries: [],
    }
  }

  async componentDidMount() {
    try {
      const response = await deliveryApi.getDeliveries();
      if (response?.result && response?.data) {
        this.setState({
          deliveries: response.data
        })
      } else if (response?.message) {
        toast.error(response.message);
      }
    } catch (err) {
      toast.error('Có lỗi xảy ra khi tải dữ liệu.');
    }
  }

  render() {
    const { deliveries } = this.state;
    return (
      <React.Fragment>
        <div className={classes.PageWrap}>
          <div className={classes.PageHeader}>
            <h1 className={classes.PageTitle}>Danh sách loại hình vận chuyển</h1>
          </div>
          <div className={classes.PageMain}>
            <DeliveryList
              displayFields={listDisplayFields}
              deliveryList={deliveries}
            />
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default Deliveries;
