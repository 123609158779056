import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { makeSelectShippingCouponDetails } from 'redux/selectors';
import { saveShippingCouponDetails } from 'redux/actions/admin/shippingCouponActions';

import { format, max } from 'date-fns';
import { debounce } from 'lodash';
import { dateFormat, dateTimeFormat } from 'utils/constanst/dateConstants';
import { validDate } from 'utils/helper';
import vendorApi from 'utils/api/admin/vendorApi';
import shippingCouponApi from 'utils/api/admin/shippingCouponApi';
import { allInPageSize, ACTIVE } from 'utils/constanst/common';
import {
  isAwaitingProgress,
  shippingCouponStatusMap,
  applyStatusMap,
  isStoppedProgress,
} from 'utils/constanst/adminShippingCouponConstants';

import FieldEditable from 'components/FieldEditable/FieldEditable';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import ReportProblemOutlined from '@material-ui/icons/ReportProblemOutlined';

import classes from './ShippingCouponDetails.module.scss';

class ShippingCouponDetails extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      vendorOptions: this._initVendorOptions(),
      shippingCouponsBySelectedVendor: [],
    }
  }
  componentDidMount() {
    if (!this.props.isEditing) {
      this._initCreateForm();
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { shippingCouponDetails, isEditing } = this.props;
    if (!shippingCouponDetails && !isEditing) {
      this._initCreateForm();
    }
    if (!isEditing && shippingCouponDetails?.getcare_vendor?.id !== prevProps.shippingCouponDetails?.getcare_vendor?.id) {
      this._loadShippingCouponsByVendor(shippingCouponDetails?.getcare_vendor?.code);
    }
  }
  _initCreateForm = () => {
    this.handleSaveField({
      start_date: new Date().toISOString(),
    });
    this._loadSuggestedVendors({params: {}});
  }

  _initVendorOptions = () => {
    const { shippingCouponVendors } = this.props;
    if (!shippingCouponVendors) return [];
    return shippingCouponVendors.filter(vendor => !!vendor.getcare_vendor).map(vendor => ({...vendor.getcare_vendor}));
  }
  _loadSuggestedVendors = debounce(async ({ params }) => {
    const { data: response } = await vendorApi.getAll({
      params: {
        ...params,
        active: ACTIVE,
        page_size: allInPageSize,
      },
    });
    if (!response?.result) return;
    this.setState({
      vendorOptions: response.data
        ? [...response.data]
        : [],
    });
  }, 500);
  _getSelectedVendor = (id) => {
    const { vendorOptions } = this.state;
    const { shippingCouponDetails } = this.props;
    const vendorId = id || shippingCouponDetails?.getcare_vendor?.id;
    return (vendorOptions && vendorOptions.find(item => item.id === vendorId)) || null;
  };

  _loadShippingCouponsByVendor = async (vendorCode) => {
    const { data: response } = await shippingCouponApi.getList({
      params: {
        active: [ACTIVE],
        getcare_vendor_code: vendorCode,
        page_size: 1,
      },
    });
    if (!response?.result) return;
    this.setState({
      shippingCouponsBySelectedVendor: response.data
        ? [...response.data]
        : [],
    });
  }

  handleChangeField = (e, value) => {
    if (!this.props.isEditing) {
      this.handleSaveField({ [e.target.name]: value });
    }
  };
  handleSaveField = (fieldMap) => {
    let params;
    if (fieldMap['getcare_vendor']) {
      params = {
        ...this.props.shippingCouponDetails,
        getcare_vendor: this._getSelectedVendor(fieldMap['getcare_vendor']),
      };
    } else {
      params = {
        ...this.props.shippingCouponDetails,
        ...fieldMap,
      };
    }
    this.props.saveShippingCouponDetails(params);
  };

  render() {
    const {
      shippingCouponDetails,
      isEditing,
      isValid,
    } = this.props;
    const { shippingCouponsBySelectedVendor } = this.state;
    const minEndDate = (shippingCouponDetails && shippingCouponDetails.start_date) ? max([new Date(shippingCouponDetails.start_date), new Date()]) : new Date();

    return (
      <div className={`${classes.Details} ${isEditing ? classes.IsEditing : ''}`}>
        <div className={classes.DetailsCol}>
          <div className={classes.FieldControl}>
            <label>Nhà cung cấp</label>
            { isEditing ? (
                shippingCouponDetails?.getcare_vendor?.name
              ) : (
                <FieldEditable
                  disableClearable
                  error={!this.props.isVendorValid}
                  fieldType="select"
                  editModeOnly={!isEditing}
                  fieldName="getcare_vendor"
                  value={this._getSelectedVendor()?.id}
                  displayedValue={this._getSelectedVendor()?.name}
                  options={this.state.vendorOptions || []}
                  renderOption={(option) => `${option.code} - ${option.name}`}
                  getOptionLabel={(option) => ((option && option.name) || '')}
                  onSave={this.handleSaveField}
                  onChange={this.handleChangeField}
                />
              )
            }
          </div>
          <div className={classes.FieldControl}>
            <label>Mã nhà cung cấp</label>
            <div className={classes.VendorCodeWrap}>
              { shippingCouponDetails?.getcare_vendor?.code }
              { shippingCouponsBySelectedVendor.length > 0 && (
                <Tooltip title={`Vendor này đang có shipping coupon hoạt động`} arrow placement="bottom">
                  <span className={classes.WarningIcon}><ReportProblemOutlined fontSize="small"/></span>
                </Tooltip>
              )}
            </div>
          </div>

          <div className={classes.FieldControl}>
            <label>
              Ngày bắt đầu <span className={classes.RequiredMark}>*</span>
            </label>
            {isEditing && !isAwaitingProgress(shippingCouponDetails) ? (
              validDate(shippingCouponDetails?.start_date) ? (
                format(validDate(shippingCouponDetails?.start_date), dateFormat)
              ) : ('')
            ) : (
              <FieldEditable
                fieldType="date"
                editModeOnly={!isEditing}
                displayedValue={
                  validDate(shippingCouponDetails?.start_date)
                    ? format(validDate(shippingCouponDetails?.start_date), dateFormat)
                    : ''
                }
                error={!this.props.isStartDateValid}
                minValue={(new Date()).toISOString()}
                fieldName="start_date"
                value={shippingCouponDetails?.start_date || ''}
                onSave={this.handleSaveField}
                onChange={this.handleChangeField}
              />
            )}
          </div>
        </div>

        <div className={classes.DetailsCol}>
          <div className={classes.FieldControl}>
            { isEditing && <>
              <label>Mã coupon</label>
              { shippingCouponDetails?.code }
            </> }
          </div>
          <div className={classes.FieldControl}>
            <label>Mô tả</label>
            <FieldEditable
              fieldType="textarea"
              editModeOnly={!isEditing}
              fieldName="description"
              maxLength={255}
              rowsMax={1}
              value={shippingCouponDetails?.description || ''}
              onSave={this.handleSaveField}
              onChange={this.handleChangeField}
            />
          </div>
          <div className={classes.FieldControl}>
            <label>Ngày kết thúc</label>
            {isEditing && isStoppedProgress(shippingCouponDetails) ? (
              validDate(shippingCouponDetails?.end_date) ? (
                format(validDate(shippingCouponDetails?.end_date), dateFormat)
              ) : ('')
            ) : (
              <FieldEditable
                fieldType="date"
                editModeOnly={!isEditing}
                displayedValue={
                  validDate(shippingCouponDetails?.end_date)
                    ? format(validDate(shippingCouponDetails?.end_date), dateFormat)
                    : ''
                }
                error={!this.props.isEndDateValid}
                fieldName="end_date"
                minValue={minEndDate ? minEndDate.toISOString() : ''}
                value={shippingCouponDetails?.end_date || ''}
                onSave={this.handleSaveField}
                onChange={this.handleChangeField}
              />
            )}
          </div>
        </div>

        { isEditing && <>
          <div className={classes.DetailsCol}>
            <div className={`${classes.FieldControl}`}>
              <label className={classes.AutoLabel}>Thời gian tạo</label>
              {validDate(shippingCouponDetails?.created_at)
                ? format(
                    validDate(shippingCouponDetails?.created_at),
                    dateTimeFormat
                  )
                : ''}
            </div>
            <div className={`${classes.FieldControl}`}>
              <label className={classes.AutoLabel}>Thời gian sửa</label>
              {validDate(shippingCouponDetails?.updated_at)
                ? format(
                    validDate(shippingCouponDetails?.updated_at),
                    dateTimeFormat
                  )
                : ''}
            </div>
            <div className={`${classes.FieldControl}`}>
              <label className={classes.AutoLabel}>Thay đổi bởi</label>
              {shippingCouponDetails?.user_name}
            </div>
          </div>

          <div className={classes.DetailsCol}>
            <div className={`${classes.FieldControl}`}>
              <label className={classes.AutoLabel}>Trạng thái</label>
              {shippingCouponStatusMap[shippingCouponDetails?.active]}
            </div>
            <div className={`${classes.FieldControl}`}>
              <label className={classes.AutoLabel}>Áp dụng</label>
              {applyStatusMap[shippingCouponDetails?.apply]}
            </div>
            <div className={`${classes.FieldControl} ${classes.ActivityBtnControl}`}>
              {!isStoppedProgress(shippingCouponDetails) &&
                <Button
                  disabled={!isValid}
                  variant="outlined"
                  size="small"
                  color="secondary"
                  onClick={this.props.onDeactiveShippingCoupon}
                >
                  Dừng hoạt động
                </Button>
              }
            </div>
          </div>
        </> }
      </div>
    );
  }
}

ShippingCouponDetails.propTypes = {
  isEditing: PropTypes.bool,
  isValid: PropTypes.bool,
  onDeactiveShippingCoupon: PropTypes.func,
};

ShippingCouponDetails.defaultProps = {
  isEditing: false,
  isValid: true,
};

const mapStateToProps = createStructuredSelector({
  shippingCouponDetails: makeSelectShippingCouponDetails(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    saveShippingCouponDetails: (payload) => dispatch(saveShippingCouponDetails(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter)(ShippingCouponDetails);
