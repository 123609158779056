import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import policyPriceApi from 'utils/api/admin/policyPriceApi';
import { debounce } from 'lodash';
import { getFullAddressStr } from 'utils/helper';
import { 
  CUSTOMER_TYPE_ID,
  GROUP_CUSTOMER_TYPE_ID,
  customerTypesMap 
} from 'utils/constanst/adminKeyAccountConstants';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import EditOutlined from '@material-ui/icons/EditOutlined';
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import IconButton from '@material-ui/core/IconButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import DeleteOutline from '@material-ui/icons/DeleteOutline';

import classes from './KeyAccountCustomerItem.module.scss';
import gridClasses from '../KeyAccountCustomerListGrid.module.scss';

class KeyAccountCustomerItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: props.isEditing,

      params: this.getInitParams(props),

      customerOptions: this.initCustomerOptions(props),
      loadingCustomerInput: false,
      customerCodeInput: '',
      customerNameInput: '',
    };
  }
  componentDidUpdate(prevProps, prevState) {
    const { customerCodeInput, customerNameInput, isEditing, params } = this.state;
    if (isEditing !== prevState.isEditing && this.isRowValid(params)) {
      this.props.onSaveKeyAccountCustomerItem({
        ...params,
        idStr: this.props.idStr,
        id: params.id,
        isEditing,
      });
    }

    if (customerCodeInput !== prevState.customerCodeInput) {
      this.loadSuggestedCustomers({
        params: {
          code: customerCodeInput,
        },
      });
    }
    if (customerNameInput !== prevState.customerNameInput) {
      this.loadSuggestedCustomers({
        params: {
          name: customerNameInput,
        },
      });
    }
  }

  getTypeValueKey = () => {
    return this.state.params.type_id === CUSTOMER_TYPE_ID ? 'getcare_customer' : 'getcare_erp_group';
  }
  initCustomerOptions = (params) =>{
    return params.getcare_customer ? [{...params.getcare_customer}] : [];
  }
  loadSuggestedCustomers = debounce(async ({ params }) => {
    this.setState({ loadingCustomerInput: true })
    const { data: response } = await policyPriceApi.getGroupCustomers({
      params: {
        ...params,
        type_id: this.state.params.type_id,
      },
    });
    if (!response?.result) return;
    this.setState({
      customerOptions: response.data
        ? ( 
            this.state.params.type_id === CUSTOMER_TYPE_ID ? 
              this._filteredCustomerOptions(response.data.customers) : 
                this._filteredGroupCustomerOptions(response.data.erp_groups) 
          )
        : [],
      loadingCustomerInput: false,
    });
  }, 500);
  _filteredCustomerOptions = (fullArr) => {
    return fullArr.filter(p =>
      (p.id === this.props.getcare_customer?.id) ||
      !this.props.currentList.some(
        (item) => item.getcare_customer?.id === p.id
      )
    );
  };
  _filteredGroupCustomerOptions = (fullArr) => {
    return fullArr.filter(p =>
      (p.id === this.props.getcare_erp_group?.id) ||
      !this.props.currentList.some(
        (item) => item.getcare_erp_group?.id === p.id
      )
    );
  };
  getCustomerLabel = (option) => {
    return <Box>{option.code} - {option.name} <Box fontWeight={600} component="span" color={option.type_id === CUSTOMER_TYPE_ID ? "primary.main" : "secondary.main"}>({customerTypesMap[option.type_id]})</Box></Box>
  }
  getInitParams = (params) => {
    return {
      idStr: params?.idStr,
      id: params?.id,
      getcare_customer: params?.getcare_customer,
      getcare_erp_group: params?.getcare_erp_group,
      type_id: params.type_id || 1
    }
  }
  _resetState = () => {
    this.setState({
      isEditing: false,
      params: this.getInitParams(this.props),
      customerOptions: this.initCustomerOptions(this.props),
      customerCodeInput: '',
      customerNameInput: '',
    });
  }
  hasCustomer = (params) => {
    return !!params?.id || !!params[this.getTypeValueKey()]?.id;
  }
  isRowValid = (params) => {
    return this.hasCustomer(params);
  };

  stopPropagation = (e) => {
    e.stopPropagation();
  }

  handleSaveField = (e, value) => {
    this.setState({
      params: {
        ...this.state.params,
        [e.target.name]: value,
      },
    });
  }
  handleRowClick = () => {
    if (!this.state.isEditing) {
      this.handleEdit();
    }
  }
  handleEdit = (e) => {
    if (e) e.stopPropagation();
    this.setState({
      isEditing: true,
    });
  }
  handleCancel = (e) => {
    if (e) e.stopPropagation();
    if (this.isRowValid(this.props)) {
      // if it was saved to DB or it was used to save on client successful
      this._resetState();
      return;
    }
    this.props.onRemoveKeyAccountCustomerItem({
      id: this.props.id,
      idStr: this.props.idStr,
    });
  }
  handleSave = (e) => {
    if (e) e.stopPropagation();
    this.setState({
      isEditing: false,
    });
  }
  handleRemove = (e)=> {
    if (e) e.stopPropagation();
    this.props.onRemoveKeyAccountCustomerItem({
      idStr: this.props.idStr,
      id: this.props.id,
    });
  }
  handleSelectCustomerChange = (e, newValue) => {
    this.setState({
      params: {
        ...this.state.params,
        getcare_customer: this.state.params.type_id === CUSTOMER_TYPE_ID ? newValue : null,
        getcare_erp_group: this.state.params.type_id === GROUP_CUSTOMER_TYPE_ID ? newValue : null,
      },
    });
  };
  handleTypeChange = (e) => {
    if ( this.state.params.type_id !== e.target.value ) {
      this.setState((state) => ({
        params: {
          ...state.params,
          type_id: e.target.value,
          getcare_customer: null,
          getcare_erp_group: null,
        },
        customerOptions: [],
      }))
    }
  }

  render() {
    // const { getcare_customer, getcare_erp_group } = this.props;
    const {
      isEditing,
      params,
      customerOptions,
    } = this.state;
    const typeValueKey = this.getTypeValueKey();

    return (
      <>
        <div
          className={`${classes.Item} ${gridClasses.Row}`}
          onClick={this.handleRowClick}
        >
          <div className={`${gridClasses.Col}`}>
            {isEditing ? (
              <Autocomplete
                openOnFocus
                selectOnFocus
                disableClearable
                fullWidth
                size="small"
                handleHomeEndKeys={false}
                value={params[typeValueKey]}
                onChange={this.handleSelectCustomerChange}
                options={this.state.loadingCustomerInput ? [] : (customerOptions || [])}
                filterOptions={(x) => x}
                loading={this.state.loadingCustomerInput}
                renderOption={(option) => this.getCustomerLabel(option)}
                getOptionLabel={(option) => ((option && option.code) || '')}
                getOptionSelected={(option, value) =>
                  value && value.id && option ? option.id === value.id : null
                }
                onInputChange={(e, newInputValue,reason) => {
                  this.setState({
                    customerCodeInput: newInputValue,
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="- Chọn -" />
                )}
                onOpen={() => {
                  if ( customerOptions.length === 0 ) {
                    this.loadSuggestedCustomers({
                      params: {
                        code: this.state.customerCodeInput,
                      },
                    });
                  }
                }}
              />
            ) : (
              this.props[typeValueKey]?.code
            )}
          </div>
          <div className={`${gridClasses.Col}`}>
            {isEditing ? (
              <Autocomplete
                openOnFocus
                selectOnFocus
                disableClearable
                fullWidth
                size="small"
                handleHomeEndKeys={false}
                value={params[typeValueKey]}
                onChange={this.handleSelectCustomerChange}
                options={customerOptions || []}
                filterOptions={(x) => x}
                renderOption={(option) => this.getCustomerLabel(option)}
                getOptionLabel={(option) => ((option && option.name) || '')}
                getOptionSelected={(option, value) =>
                  value && value.id && option ? option.id === value.id : null
                }
                onInputChange={(e, newInputValue) => {
                  this.setState({
                    customerNameInput: newInputValue,
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="- Chọn -" />
                )}
                onOpen={() => {
                  if ( customerOptions.length === 0 ) {
                    this.loadSuggestedCustomers({
                      params: {
                        name: this.state.customerNameInput,
                      },
                    });
                  }
                }}
              />
            ) : (
              this.props[typeValueKey]?.name
            )}
          </div>
          <div className={`${gridClasses.Col}`}>
            {isEditing ? (
              <Select
                value={params.type_id}
                onChange={this.handleTypeChange}
              >
                <MenuItem value={CUSTOMER_TYPE_ID}>Khách hàng</MenuItem>
                <MenuItem value={GROUP_CUSTOMER_TYPE_ID}>Nhóm khách hàng</MenuItem>
              </Select>
            ) : (
              <Chip
                color={params.type_id === CUSTOMER_TYPE_ID ? "primary" : "secondary"} 
                size="small"
                label={customerTypesMap[params.type_id]}
              />
            )}
          </div>
          <div className={`${gridClasses.Col}`}>
             { params.getcare_customer?.phone }
          </div>
          <div className={`${gridClasses.Col}`}>
            { getFullAddressStr(
                params.getcare_customer?.address,
                params.getcare_customer?.getcare_ward,
                params.getcare_customer?.getcare_district,
                params.getcare_customer?.getcare_province,
                params.getcare_customer?.getcare_country
              ) }
          </div>
          <div className={`${gridClasses.Col} TextCenter`}>
            {isEditing ? (
              <>
                <IconButton
                  onClick={this.handleCancel}
                  style={{ padding: '0' }}
                  size="small"
                >
                  <CancelOutlined fontSize="small" color="secondary" />
                </IconButton>
                <IconButton
                  onClick={this.handleSave}
                  disabled={!this.isRowValid(params)}
                  style={{ padding: '0' }}
                  size="small"
                >
                  <CheckCircleOutlineOutlined
                    fontSize="small"
                    color={this.isRowValid(params) ? `primary` : `inherit`}
                  />
                </IconButton>
              </>
            ) : (
              <>
                <IconButton
                  onClick={this.handleRemove}
                  style={{ padding: '0' }}
                  size="small"
                >
                  <DeleteOutline fontSize="small" />
                </IconButton>
                <IconButton
                  onClick={this.handleEdit}
                  style={{ padding: '0' }}
                  size="small"
                >
                  <EditOutlined fontSize="small" color="primary" />
                </IconButton>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

KeyAccountCustomerItem.propTypes = {
  idStr: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currentList: PropTypes.array,
  onSaveKeyAccountCustomerItem: PropTypes.func,
  onRemoveKeyAccountCustomerItem: PropTypes.func,
};

KeyAccountCustomerItem.defaultProps = {
  currentList: [],
};

export default KeyAccountCustomerItem;
