import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import DeliveryListItem from './DeliveryListItem/DeliveryListItem';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';

import gridClasses from './DeliveryListGrid.module.scss';
import classes from './DeliveryList.module.scss';

class DeliveryList extends PureComponent {
  render() {
    const { deliveryList, isLoading, displayFields } = this.props;

    return (
      <div className={`${classes.List} ${isLoading && 'OverlayLoading'}`}>
        <div className={`${classes.Body}`}>
          <div className={`${classes.Header}`}>
            <div className={`${gridClasses.Row} ${classes.Header}`}>
              {displayFields.map((item) => (
                <ListHeaderCol
                  key={item.name}
                  className={gridClasses.Col}
                  {...item}
                />
              ))}
            </div>
          </div>
        
          {(deliveryList && deliveryList.length)
            ? deliveryList.map(item => (
              <DeliveryListItem
                key={`delivery-${item.id}`}
                {...item}
              />))
            : (<p className="NoData">Không có loại hình vận chuyển nào</p>)
          }
        </div>
      </div>
    )
  }
}

DeliveryList.propTypes = {
  displayFields: PropTypes.array,
  deliveryList: PropTypes.array,
};

DeliveryList.defaultProps = {
  displayFields: [],
  deliveryList: [],
};

export default DeliveryList;
