export const MAX_FILE_SIZE = 5*1024*1024;

export const ACTIVE = 1;
export const INACTIVE = -1;
export const groupStatusMap = {
  [ACTIVE]: 'Đang hoạt động',
  [INACTIVE]: 'Ngừng hoạt động',
};

export const PRODUCT_TYPE = 1;
export const SALES_TYPE = 3;
export const VENDOR_TYPE = 2;
export const groupTypesMap = {
  [PRODUCT_TYPE]: 'Sản phẩm',
  [SALES_TYPE]: 'Khách hàng',
  [VENDOR_TYPE]: 'Nhà cung cấp',
};

export const CUSTOMER_BY_LIST_TYPE = 2;
export const CUSTOMER_BY_CHANNEL_TYPE = 1;
export const CUSTOMER_BY_CONDITION_TYPE = 3;
export const groupCustomerTypesMap = {
  [CUSTOMER_BY_CHANNEL_TYPE]: 'Khu vực bán hàng',
  [CUSTOMER_BY_LIST_TYPE]: 'Danh sách',
  [CUSTOMER_BY_CONDITION_TYPE]: 'Điều kiện',
};
export const groupCustomerTypes = [
  { id: CUSTOMER_BY_CHANNEL_TYPE, name: groupCustomerTypesMap[CUSTOMER_BY_CHANNEL_TYPE] },
  { id: CUSTOMER_BY_LIST_TYPE, name: groupCustomerTypesMap[CUSTOMER_BY_LIST_TYPE] },
  { id: CUSTOMER_BY_CONDITION_TYPE, name: groupCustomerTypesMap[CUSTOMER_BY_CONDITION_TYPE] },
]

export const STORE_TYPE = 1;
export const DOCTOR_TYPE = 2;
export const HOSPITAL_TYPE = 3;
export const customerTypesMap = {
  [STORE_TYPE]: 'Nhà thuốc',
  [DOCTOR_TYPE]: 'Bác sĩ',
  [HOSPITAL_TYPE]: 'Cơ sở khám bệnh',
};

export const listDisplayFields = [
  {
    name: 'code',
    label: 'Mã nhóm',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'name',
    label: 'Tên nhóm',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'getcare_erp_group_type_name',
    label: 'Loại nhóm',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'description',
    label: 'Mô tả',
    sortable: true,
    sortDir: '',
  },
];
export const listParamsMap = {
  page_size: 50,
  page: 1,
  order: '',
  active: [ACTIVE],
};
export const filterFields = [
  {
    name: 'active',
    label: 'Trạng thái',
    value: [ACTIVE],
    type: 'custom',
    placeholder: 'Nhập...',
  },
  {
    name: 'code',
    label: 'Mã nhóm',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'name',
    label: 'Tên nhóm',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'getcare_erp_group_type_id',
    label: 'Loại nhóm',
    value: [],
    type: 'custom',
    placeholder: '- Chọn -',
  },
  {
    name: 'description',
    label: 'Mô tả',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
];

export const groupProductsDisplayFields = [
  {
    name: 'product_getcare_id',
    label: 'Phahub ID',
    sortable: true,
    sortDir: ''
  },
  {
    name: 'product_code',
    label: 'Mã thuốc',
  },
  {
    name: 'product_name',
    label: 'Tên thuốc',
  },
  {
    name: 'product_registration_number',
    label: 'Số đăng ký',
  },
  {
    name: 'vendor_code',
    label: 'Mã nhà cung cấp',
  },
  {
    name: 'vendor_name',
    label: 'Tên nhà cung cấp',
  },
  {
    name: 'uom_base_name',
    label: 'Đơn vị tính',
  },
  {
    name: 'ratio',
    label: 'Tỉ trọng',
  },
]
export const groupProductsParamsMap = {
  order: '',
}

export const groupVendorsDisplayFields = [
  {
    name: 'code',
    label: 'Mã nhà cung cấp',
  },
  {
    name: 'name',
    label: 'Tên nhà cung cấp',
  },
  {
    name: 'phone',
    label: 'Số điện thoại',
  },
  {
    name: 'registration_address',
    label: 'Địa chỉ',
  },
]
export const groupVendorsParamsMap = {
  order: '',
}

export const groupCustomersDisplayFields = [
  {
    name: 'code',
    label: 'Mã khách hàng',
  },
  {
    name: 'name',
    label: 'Tên khách hàng',
  },
  {
    name: 'phone',
    label: 'Số điện thoại',
  },
  {
    name: 'registration_address',
    label: 'Địa chỉ',
  },
]
export const groupCustomersParamsMap = {
  page_size: 50,
  page: 1,
  order: '',
}

export const groupCustomerMappingsDisplayFields = [
  {
    name: 'code',
    label: 'Mã khu vực bán hàng',
  },
  {
    name: 'count',
    label: 'Số lượng mapping',
  },
]
export const groupCustomerMappingsParamsMap = {
  order: '',
}
