import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  getSalesChannelList,
  getSalesChannel,
  createSalesChannel,
  saveSalesChannelAllowedActions,
} from 'redux/actions/admin/salesChannelActions';
import { createStructuredSelector } from "reselect";
import {
  makeSelectSalesChannelList,
  makeSelectSalesChannel,
  makeSelectSalesChannelCoordinaters,
  makeSelectSalesChannelAllowedActions,
} from 'redux/selectors';
import { withRouter } from 'react-router-dom';
import { genID } from 'utils/helper';
import { VN_ID } from 'utils/constanst/locationConstants';
import { toast } from 'react-toastify';
import { suggestionPageSize } from 'utils/constanst/common';
import authApi from 'utils/api/authApi';
import { isActionAllowed } from 'utils/helper/authorization';

import Button from '@material-ui/core/Button';
import CheckOutlined from '@material-ui/icons/CheckOutlined';
import ArrowBack from '@material-ui/icons/ArrowBack';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import SalesChannelSelect from 'components/admin/SalesChannelDetails/SalesChannelSelect/SalesChannelSelect';
import SalesChannelLocations from 'components/admin/SalesChannelDetails/SalesChannelLocations/SalesChannelLocations';
import SalesChannelDeletingDialog from 'components/admin/SalesChannelList/SalesChannelDeletingDialog/SalesChannelDeletingDialog';
import SalesChannelCoordinaters from 'components/admin/SalesChannelDetails/SalesChannelCoordinaters/SalesChannelCoordinaters';
import SalesChannelHistory from 'components/admin/SalesChannelDetails/SalesChannelHistory/SalesChannelHistory';
import { Tabs, Tab } from '@material-ui/core';

import classes from './SalesChannel.module.scss';

class SalesChannel extends PureComponent {
  constructor(props) {
    super(props);
    this.defaultParams = {
      newCode: '',
      locations: [],
    }
    this.state = {
      newCode: this.defaultParams.newCode,
      locations: [...this.defaultParams.locations],
      isNewCodeValid: false,

      isConfirmDialogOpen: false,
      tabActive: 0,
    }
  }
  async componentDidMount() {
    await this.getAllowedActions();

    const { id } = this.props.match.params;
    this.loadData(id);
  }
  async componentDidUpdate(prevProps) {
    const { id } = this.props.match.params;
    if (id !== prevProps.match.params.id) {
      await this.getAllowedActions();

      this.loadData(id);
    }
    const { salesChannel } = this.props;
    if (salesChannel && salesChannel.id) {
      if (!prevProps.salesChannel || salesChannel.id !== prevProps.salesChannel.id) {

        this.setState({
          locations: [...salesChannel.getcare_sales_channel_items],
        });
        // created successfull
        if (!prevProps.salesChannel || !prevProps.salesChannel.id) {
          this.props.history.push(`/saleschannel/${salesChannel.id}`);
          return;
        }
      }
    }
  }

  getAllowedActions = async () => {
    const { data: response } = await authApi.getAllowedActions({
      component: 'saleschannel',
    });
    if (!response.result) {
      toast.error(response.message);
      return;
    }
    this.props.saveSalesChannelAllowedActions(response.allowed_actions || []);
  };
  loadData = (id) => {
    this.props.getSalesChannelList({ params: {
      page: 1,
      page_size: suggestionPageSize,
    } });
    if (id && id !== `-1`) {
      this.props.getSalesChannel(id);
    }
  }
  backToList = () => {
    this.props.history.push('/saleschannel');
  }
  handleSubmit = () => {
    const { salesChannel, salesChannelCoordinaters } = this.props;
    const params = {
      code: this.isEditing() ? salesChannel.code : this.state.newCode,
      id: this.isEditing() ? salesChannel.id : undefined,
      items: this.state.locations.filter(loc => !!loc.getcare_province?.id).map(loc => ({
        id: isNaN(loc.id) ? undefined : loc.id,
        getcare_country_id: VN_ID,
        getcare_province_id: loc.getcare_province.id,
        getcare_district_id: loc.getcare_district?.id,
        getcare_ward_id: loc.getcare_ward?.id,
      })),
      sales_items: salesChannelCoordinaters.filter(item => !!item.getcare_sales?.id).map(item => ({
        id: item.id || undefined,
        getcare_sales_id: item.getcare_sales?.id,
      })),
    }
    this.props.createSalesChannel({ params });
  }
  handleCreateNewCode = ({newCode, isNewCodeValid}) => {
    this.setState({
      newCode,
      isNewCodeValid,
    });
  }
  handleCreateNewLocation = () => {
    const newLocation = {
      id: genID(),
      getcare_province: null,
      getcare_district: null,
      getcare_ward: null,
    };
    this.setState({
      locations: [newLocation, ...this.state.locations],
    });
  }
  handleRemoveLocationItems = (locationItems) => {
    const list = this.state.locations.filter(
      (p) => !locationItems.some((item) => item.id === p.id));
    this.setState({
      locations: list,
    });
  }
  handleSaveLocationItem = (params) => {
    const itemIndex = this.state.locations.findIndex(item => item.id === params.id);
    if (itemIndex < 0) return;
    const list = [...this.state.locations];
    list[itemIndex] = { ...params };
    this.setState({
      locations: list,
    });
    if (this.hasDupplicatedLocations([...list])) {
      toast.warning(`Có ít nhất một khu vực bị trùng lặp.`);
    }
  }
  handleSelectedIdChange = (newId) => {
    if (newId && newId !== this.props.match.params.id) {
      this.props.history.push(`/saleschannel/${newId}`);
    }
  }
  isEditing = () => {
    const { id } = this.props.match.params;
    return id && id !== `-1`;
  }
  isCodeValid = () => {
    if (this.isEditing()) return true;
    return this.state.isNewCodeValid;
  }
  hasAtLeastOneLocation = () => {
    return this.getLocationsLength() > 0;
  }
  getLocationsLength = () => {
    return this.state.locations.filter(item => !!(item.getcare_province && item.getcare_province.id)).length;
  }

  hasDupplicatedLocations = (locationList) => {
    const dupplicatedLocs = locationList.reduce((memo, loc, index) => {
      const idStr = `${loc.getcare_province?.id}${loc.getcare_district?.id}${loc.getcare_ward?.id}`;
      memo[idStr] = ++memo[idStr] || 0;
      return memo;
    }, {});
    return Object.keys(dupplicatedLocs).some(key => dupplicatedLocs[key] > 0);
  }

  handleConfirmDialogOpen = (e) => {
    this.setState({
      isConfirmDialogOpen: true,
    });
  }
  handleConfirmDialogClose = () => {
    this.setState({
      isConfirmDialogOpen: false,
    });
  }
  handleDeleteSuccess = () => {
    this.handleConfirmDialogClose();
    this.backToList();
  }

  handleTabChange = (e, value) => {
    this.setState({
      tabActive: value,
    });
  };

  render() {
    const { salesChannelList, salesChannel, salesChannelAllowedActions } = this.props;
    const { locations, isConfirmDialogOpen } = this.state;
    const { id } = this.props.match.params;
    const isEditing = this.isEditing();
    const isFormValid = this.hasAtLeastOneLocation() && this.isCodeValid();
    const isEditAllowed = isActionAllowed('edit', salesChannelAllowedActions);
    const isAssignAllowed = isActionAllowed('assign_coordinater', salesChannelAllowedActions);

    return (<>
      <div className={classes.PageWrap}>
        <div className={classes.PageHeader}>
          <h1 className={classes.PageTitle}>
            { isEditing ? `Xem khu vực bán hàng` : `Tạo mới khu vực bán hàng` }
          </h1>
          <Button
            size="small"
            variant="outlined"
            startIcon={<ArrowBack />}
            onClick={this.backToList}
          >Trở về</Button>
          { isEditing &&
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              startIcon={<DeleteOutline/>}
              onClick={this.handleConfirmDialogOpen}
            >Xoá</Button>
          }
          <Button
            size="small"
            variant="contained"
            color="primary"
            startIcon={<CheckOutlined />}
            disabled={!isFormValid}
            onClick={this.handleSubmit}
          >Lưu</Button>
        </div>
        <div className={classes.PageMain}>
          <SalesChannelSelect
            isEditing={isEditing}
            selectedId={id}
            list={salesChannelList}
            onSelectedIdChange={this.handleSelectedIdChange}
            onCreateNewCode={this.handleCreateNewCode}
          />
          <Tabs
            className={classes.TabsWrap}
            value={this.state.tabActive}
            onChange={this.handleTabChange}
          >
            <Tab label="Khu vực" />
            <Tab label="Người điều phối KVBH" />
            <Tab label="Lịch sử" />
          </Tabs>
          <div className={classes.TabsPanel}>
            {this.state.tabActive === 0 && (
              <SalesChannelLocations
                selectedId={id}
                list={locations}
                hasOneLocation={this.getLocationsLength() === 1}
                isEditAllowed={isEditAllowed}
                onCreateNewLocation={this.handleCreateNewLocation}
                onSaveLocationItem={this.handleSaveLocationItem}
                onRemoveLocationItems={this.handleRemoveLocationItems}
              />
            )}
            {this.state.tabActive === 1 && (
              <SalesChannelCoordinaters
                isEditing={this.isEditing()}
                isAssignAllowed={isAssignAllowed}
              />
            )}
            {this.state.tabActive === 2 && (
              <SalesChannelHistory salesChannelId={salesChannel?.id}/>
            )}
          </div>
        </div>
      </div>

      { isConfirmDialogOpen
        && <SalesChannelDeletingDialog
          id={salesChannel?.id}
          code={salesChannel?.code}
          wasSetUp={salesChannel?.was_set_up}
          isOpen={isConfirmDialogOpen}
          onClose={this.handleConfirmDialogClose}
          onDeleteItemSuccess={this.handleDeleteSuccess}
        />
      }
    </>);
  }
}

const mapStateToProps = createStructuredSelector({
  salesChannelList: makeSelectSalesChannelList(),
  salesChannel: makeSelectSalesChannel(),
  salesChannelCoordinaters: makeSelectSalesChannelCoordinaters(),
  salesChannelAllowedActions: makeSelectSalesChannelAllowedActions(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getSalesChannelList: (payload) => dispatch(getSalesChannelList(payload)),
    getSalesChannel: (payload) => dispatch(getSalesChannel(payload)),
    createSalesChannel: (payload) => dispatch(createSalesChannel(payload)),
    saveSalesChannelAllowedActions: (payload) => dispatch(saveSalesChannelAllowedActions(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter)(SalesChannel);
