import LayoutAdmin from 'layouts/LayoutAdmin/LayoutAdmin';
import Products from 'views/admin/Products/Products';
import Product from 'views/admin/Product/Product';
import Ingredients from 'views/admin/Ingredients/Ingredients';
import QueueIngredients from 'views/admin/QueueIngredients/QueueIngredients';
import GetcareCategory from 'views/admin/GetcareCategory/GetcareCategory';
import ProductUoms from 'views/admin/ProductUoms/ProductUoms';
import BasicUoms from 'views/admin/BasicUoms/BasicUoms';
import ConsumerGoods from 'views/admin/ConsumerGoods/ConsumerGoods';
import Vendors from 'views/admin/Vendors/Vendors';
import Vendor from 'views/admin/Vendor/Vendor';
import Stores from 'views/admin/Stores/Stores';
import Store from 'views/admin/Store/Store';
import SalesChannels from 'views/admin/SalesChannels/SalesChannels';
import SalesChannel from 'views/admin/SalesChannel/SalesChannel';
import SalesTeams from 'views/admin/SalesTeams/SalesTeams';
import SalesTeam from 'views/admin/SalesTeam/SalesTeam';
import Groups from 'views/admin/Groups/Groups';
import Group from 'views/admin/Group/Group';
import VendorPrices from 'views/admin/VendorPrices/VendorPrices';
import PolicyPrices from 'views/admin/PolicyPrices/PolicyPrices';
import PolicyPrice from 'views/admin/PolicyPrice/PolicyPrice';
import PriceTest from 'views/admin/PriceTest/PriceTest';
import VendorProducts from 'views/admin/VendorProducts/VendorProducts';
import ProductBonuses from 'views/admin/ProductBonuses/ProductBonuses';
import ShippingCoupons from 'views/admin/ShippingCoupons/ShippingCoupons';
import ShippingCoupon from 'views/admin/ShippingCoupon/ShippingCoupon';
import TestShippingCoupon from 'views/admin/TestShippingCoupon/TestShippingCoupon';
import Users from 'views/admin/Users/Users';
import User from 'views/admin/User/User';
import KeyAccounts from 'views/admin/KeyAccounts/KeyAccounts';
import KeyAccount from 'views/admin/KeyAccount/KeyAccount';
import SalesUsers from 'views/admin/SalesUsers/SalesUsers';
import KeyAccountLocks from 'views/admin/KeyAccountLocks/KeyAccountLocks';
import Deliveries from 'views/admin/Deliveries/Deliveries';
import Shippers from 'views/admin/Shippers/Shippers';
import Shipper from 'views/admin/Shipper/Shipper';
import Coordinaters from 'views/admin/Coordinaters/Coordinaters';
import Pharmacies from 'views/admin/UnapprovedCustomers/Pharmacies';
import Pharmacy from 'views/admin/UnapprovedCustomers/Pharmacy';
import Doctors from 'views/admin/UnapprovedCustomers/Doctors';
import Doctor from 'views/admin/UnapprovedCustomers/Doctor';

// ******************************** Asset ********************************
// Asset Brandings 
import AssetBrandings from 'views/admin/Asset/Brandings/AssetBrandings';
import AssetComponents from 'views/admin/Asset/Components/AssetComponents';
// ******************************** Asset ********************************
import PermissionDenied from 'views/PermissionDenied/PermissionDenied';
import PageNotFound from 'views/PageNotFound/PageNotFound';

export const routes = [
  {
    path: '/',
    component: LayoutAdmin,
    routes: [
      {
        path: '/asset/brandings',
        component: AssetBrandings,
        exact: true,
        userComponent: 'asset_brand',
      },
      {
        path: '/asset/components',
        component: AssetComponents,
        exact: true,
        userComponent: 'asset_components',
      },
      {
        path: '/products',
        component: Products,
        exact: true,
        userComponent: 'products',
      },
      {
        path: '/products/:id',
        component: Product,
        exact: true,
        userComponent: 'products',
      },
      {
        path: '/products-approval',
        component: VendorProducts,
        exact: true,
        userComponent: 'vendor',
      },
      {
        path: '/customer-goods',
        component: ConsumerGoods,
        exact: true,
        userComponent: 'customer_goods',
      },
      {
        path: '/product-bonus',
        component: ProductBonuses,
        exact: true,
        userComponent: 'product_bonus',
      },
      {
        path: '/productuom',
        component: ProductUoms,
        exact: true,
        userComponent: 'productuom',
      },
      {
        path: '/basicuom',
        component: BasicUoms,
        exact: true,
        userComponent: 'basicuom',
      },
      {
        path: '/category',
        component: Ingredients,
        exact: true,
        userComponent: 'category',
      },
      {
        path: '/queuecategory',
        component: QueueIngredients,
        exact: true,
        userComponent: 'queuecategory',
      },
      {
        path: '/getcare-category/:brand',
        component: GetcareCategory,
        exact: true,
        userComponent: 'getcare_category',
      },
      {
        path: '/vendor',
        component: Vendors,
        exact: true,
        userComponent: 'vendor',
      },
      {
        path: '/vendor/:id',
        component: Vendor,
        exact: true,
        userComponent: 'vendor',
      },
      {
        path: '/vendor/:id/products',
        component: VendorProducts,
        exact: true,
        userComponent: 'vendor',
      },
      {
        path: '/customer',
        component: Stores,
        exact: true,
        userComponent: 'customer',
      },
      {
        path: '/customer/:id',
        component: Store,
        exact: true,
        userComponent: 'customer',
      },
      {
        path: '/saleschannel',
        component: SalesChannels,
        exact: true,
        userComponent: 'saleschannel',
      },
      {
        path: '/saleschannel/:id',
        component: SalesChannel,
        exact: true,
        userComponent: 'saleschannel',
      },
      {
        path: '/salesteam',
        component: SalesTeams,
        exact: true,
        userComponent: 'salesteam',
      },
      {
        path: '/salesteam/:id',
        component: SalesTeam,
        exact: true,
        userComponent: 'salesteam',
      },
      {
        path: '/group',
        component: Groups,
        exact: true,
        userComponent: 'group',
      },
      {
        path: '/group/:id',
        component: Group,
        exact: true,
        userComponent: 'group',
      },
      {
        path: '/price',
        component: VendorPrices,
        exact: true,
        userComponent: 'price',
      },
      {
        path: '/policy',
        component: PolicyPrices,
        exact: true,
        userComponent: 'policy',
      },
      {
        path: '/policy/:id',
        component: PolicyPrice,
        exact: true,
        userComponent: 'policy',
      },
      {
        path: '/testprice',
        component: PriceTest,
        exact: true,
        userComponent: 'testprice',
      },
      {
        path: '/shipping-coupon',
        component: ShippingCoupons,
        exact: true,
        userComponent: 'shipping_coupon',
      },
      {
        path: '/shipping-coupon/:id',
        component: ShippingCoupon,
        exact: true,
        userComponent: 'shipping_coupon',
      },
      {
        path: '/test-shipping-coupon',
        component: TestShippingCoupon,
        exact: true,
        userComponent: 'test_shipping_coupon',
      },
      {
        path: '/user',
        component: Users,
        exact: true,
        userComponent: 'user',
      },
      {
        path: '/user/:id',
        component: User,
        exact: true,
        userComponent: 'user',
      },
      {
        path: '/keyaccount',
        component: KeyAccounts,
        exact: true,
        userComponent: 'keyaccount',
      },
      {
        path: '/keyaccount/:id',
        component: KeyAccount,
        exact: true,
        userComponent: 'keyaccount',
      },
      {
        path: '/salesuser',
        component: SalesUsers,
        exact: true,
        userComponent: 'salesuser',
      },
      {
        path: '/keyaccountlock',
        component: KeyAccountLocks,
        exact: true,
        userComponent: 'keyaccountlock',
      },
      {
        path: '/delivery',
        component: Deliveries,
        exact: true,
        userComponent: 'delivery',
      },
      {
        path: '/shipper',
        component: Shippers,
        exact: true,
        userComponent: 'shipper',
      },
      {
        path: '/shipper/:id',
        component: Shipper,
        exact: true,
        userComponent: 'shipper',
      },
      {
        path: '/coordinater',
        component: Coordinaters,
        exact: true,
        userComponent: 'coordinater',
      },
      {
        path: '/unapproved-pharmacy',
        component: Pharmacies,
        exact: true,
        userComponent: 'unapproved_pharmacy',
      },
      {
        path: '/unapproved-pharmacy/:id',
        component: Pharmacy,
        exact: true,
        userComponent: 'unapproved_pharmacy',
      },
      {
        path: '/unapproved-doctor',
        component: Doctors,
        exact: true,
        userComponent: 'unapproved_doctor',
      },
      {
        path: '/unapproved-doctor/:id',
        component: Doctor,
        exact: true,
        userComponent: 'unapproved_doctor',
      },
      {
        path: '/403',
        component: PermissionDenied,
        exact: true,
      },
      {
        path: '/*',
        component: PageNotFound,
      },
    ],
  },
];
