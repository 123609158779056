import axiosAuth from 'utils/axios/axiosAuth';

const vendorPriceApi = {
  getList: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (![null, undefined, ''].includes(params[key])) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_product_price_vendor/`, {
      params: queryParams,
    });
  },
  updateVendorPrices: ({ params }) => {
    return axiosAuth.post(`getcare_product_price_vendor_multiple/`, params);
  }
};

export default vendorPriceApi;
