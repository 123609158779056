import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getVendorDetails } from 'redux/actions/admin/vendorActions';
import { createStructuredSelector } from "reselect";
import {
  makeSelectVendorDetails,
  makeSelectVendorList,
} from 'redux/selectors';
import { withRouter } from 'react-router-dom';
import { relatedPageSize } from 'utils/constanst/adminVendorConstants';

import Button from '@material-ui/core/Button';
import VendorDetails from 'components/admin/VendorDetails/VendorDetails';
import DetailsPageHeader from 'components/DetailsPageHeader/DetailsPageHeader';
import RelatedVendors from 'components/admin/VendorDetails/RelatedVendors/RelatedVendors';
import classes from './Vendor.module.scss';

class Vendor extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      nextVendorId: null,
      prevVendorId: null,
      relatedVendors: [],
    }
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.loadData(id);
    this.setRelatedVendors(id);
  }

  componentDidUpdate(prevProps) {
    const { id } = this.props.match.params;
    if (id && id !== prevProps.match.params.id) {
      this.loadData(id);
      this.setRelatedVendors(id);
    }
  }

  loadData = (id) => {
    this.props.getVendorDetails(id);
  }

  backToList = () => {
    this.props.history.push('/vendor');
  }

  goToPrevVendor = () => {
    this.props.history.push(`/vendor/${this.state.prevVendorId}`);
  }

  goToNextVendor = () => {
    this.props.history.push(`/vendor/${this.state.nextVendorId}`);
  }

  goToVendorProducts = () => {
    const { id } = this.props.match.params;
    this.props.history.push(`/vendor/${id}/products`);
  }

  setRelatedVendors = (id) => {
    const { vendorList } = this.props;
    const index = vendorList.findIndex(p => p.id.toString() === id.toString());
    if (index < 0) {
      this.setState({
        relatedVendors: [],
        nextVendorId: null,
        prevVendorId: null,
      });
      return;
    }

    const nextVendors = vendorList.slice(index + 1, index + relatedPageSize + 1);
    const prevIndex = (index - 1) > -1 ? (index - 1) : vendorList.length - 1
    if (nextVendors.length === relatedPageSize) {
      this.setState({
        relatedVendors: nextVendors,
        nextVendorId: nextVendors[0]?.id,
        prevVendorId: vendorList[prevIndex]?.id,
      });
      return;
    }

    const lastIndex = Math.min(relatedPageSize - nextVendors.length, index);
    const prevVendors = vendorList.slice(0, lastIndex);
    const relatedVendors = [...nextVendors, ...prevVendors];
    this.setState({
      relatedVendors: relatedVendors,
      nextVendorId: relatedVendors[0]?.id,
      prevVendorId: vendorList[prevIndex]?.id,
    });
    return;
  }

  render() {
    const { vendorDetails } = this.props;
    const { prevVendorId, nextVendorId, relatedVendors } = this.state;
    const isLoading = !vendorDetails;

    return (
      <div className={classes.PageMain}>
        <div className={classes.PageMainHeader}>
          <DetailsPageHeader
            isLoading={isLoading}
            title={vendorDetails && vendorDetails.name}
            prevItemId={prevVendorId}
            nextItemId={nextVendorId}
            backToList={this.backToList}
            goToPrevItem={this.goToPrevVendor}
            goToNextItem={this.goToNextVendor}
          />
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={this.goToVendorProducts}
          >Danh sách sản phẩm</Button>
        </div>
        {relatedVendors.length > 0 &&
          <div className={classes.PageSide}>
            <div className={classes.PanelWrap}>
              <div className={classes.PageSideContent}>
                <RelatedVendors relatedVendors={relatedVendors}/>
              </div>
            </div>
          </div>
        }
        <div className={classes.PageContent}>
          <VendorDetails
            className={isLoading && 'OverlayLoading'}
            vendorDetails={vendorDetails}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  vendorDetails: makeSelectVendorDetails(),
  vendorList: makeSelectVendorList(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getVendorDetails: (payload) => dispatch(getVendorDetails(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter)(Vendor);
