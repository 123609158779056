export const GET_ASSET_COMPONENT_LIST = 'getcare/mdm/assetComponent/GET_ASSET_COMPONENT_LIST';
export const UPDATE_ASSET_COMPONENT_LIST = 'getcare/mdm/assetComponent/UPDATE_ASSET_COMPONENT_LIST';

// mutation
export const SAVE_ASSET_COMPONENT_LIST_TOTAL = 'getcare/mdm/assetComponent/SAVE_ASSET_COMPONENT_LIST_TOTAL';
export const SAVE_LOADING = 'getcare/mdm/assetComponent/SAVE_LOADING';
export const SAVE_ACTION_LOADING = 'getcare/mdm/assetComponent/SAVE_ACTION_LOADING';

export const SAVE_ASSET_COMPONENT_LIST = 'getcare/mdm/assetComponent/SAVE_ASSET_COMPONENT_LIST';
export const REMOVE_ASSET_COMPONENT_ITEMS = 'getcare/mdm/assetComponent/REMOVE_ASSET_COMPONENT_ITEMS';
export const SAVE_ASSET_COMPONENT_ITEM = 'getcare/mdm/assetComponent/SAVE_ASSET_COMPONENT_ITEM';

export const SAVE_ASSET_COMPONENT_ALLOWED_ACTIONS = 'getcare/mdm/assetComponent/SAVE_ASSET_COMPONENT_ALLOWED_ACTIONS';
export const SAVE_ASSET_COMPONENT_VISIBILITY = 'getcare/mdm/assetComponent/SAVE_ASSET_COMPONENT_VISIBILITY';
