import axiosAuth from 'utils/axios/axiosAuth';

const salesChannelApi = {
  get: (id) => {
    return axiosAuth.get(`getcare_sales_channel/${id}`);
  },
  getAll: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_sales_channel`, {
      params: queryParams,
    });
  },
  create: ({ params }) => {
    return axiosAuth.post(`getcare_sales_channel`, params);
  },
  update: ({ params }) => {
    return axiosAuth.post(`getcare_sales_channel`, params);
  },
  delete: ({ params }) => {
    return axiosAuth({
      method: 'DELETE',
      url: `getcare_sales_channel/${params.id}`,
    });
  },
  getSalesChannelHistory: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (![null, undefined, ''].includes(params[key])) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_sales_channel_history`, {
      params: queryParams,
    });
  },
};

export default salesChannelApi;
