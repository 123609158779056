import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from "reselect";
import { makeSelectGroupCustomersTotal } from 'redux/selectors';
import { deleteGroupCustomerMappings } from 'redux/actions/admin/groupActions';

import { Tabs, Tab } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Search from '@material-ui/icons/Search';
import GroupCustomerMappings from 'components/admin/GroupDetails/GroupCustomerMappings/GroupCustomerMappings';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import GroupMappingCustomers from 'components/admin/GroupDetails/GroupMappingCustomers/GroupMappingCustomers';

import classes from './GroupChannelCustomers.module.scss';

class GroupChannelCustomers extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabGroupCustomersActive: 0,
      isConfirmDialogOpen: false,
      selectedItems: [],
      mappingCustomersQuery: '',
    };
  }
  handleTabGroupCustomersChange = (e, value) => {
    this.setState({
      tabGroupCustomersActive: value,
      mappingCustomersQuery: '',
    });
  }
  handleConfirmDialogOpen = () => {
    this.setState({
      isConfirmDialogOpen: true,
    });
  }
  handleConfirmDialogClose = () => {
    this.setState({
      isConfirmDialogOpen: false,
    });
  }
  handleDeleteGroupCustomerMappings = () => {
    this.props.deleteGroupCustomerMappings({
      id: isNaN(this.props.groupId) ? undefined : this.props.groupId,
      code: !this.props.groupCodeItems ? undefined : this.props.groupCodeItems,
      groupCustomerMappings: [...this.state.selectedItems],
    });
    this.handleConfirmDialogClose();
  }
  handleSelectedItemsChange = (items) => {
    this.setState({
      selectedItems: [...items],
    });
  }
  handleMappingCustomersQueryChange = (e) => {
    this.setState({
      mappingCustomersQuery: e.target.value,
    });
  }

  render() {
    const hasSelected = this.state.selectedItems.length > 0;

    return (<div className={classes.GroupCustomersWrap}>
      <Tabs
        className={classes.TabsWrap}
        value={this.state.tabGroupCustomersActive}
        onChange={this.handleTabGroupCustomersChange}
      >
        <Tab label="Mapping" />
        <Tab label={
            <label className={classes.TabLabel}>
              List{' '}
              <span className={`${classes.Number} Badge`}>{this.props.groupCustomersTotal}</span>
            </label>
          }
        />
      </Tabs>
      <div className={classes.Toolbar}>
        { this.state.tabGroupCustomersActive === 0 && (
          <Button
            className={`${classes.MultiActionBtn} ${hasSelected && classes.Active}`}
            color="secondary"
            variant="contained"
            size="small"
            onClick={this.handleConfirmDialogOpen}
          >
            Xoá
          </Button>
        )}
        { this.state.tabGroupCustomersActive === 1 && (
          <TextField
            value={this.state.mappingCustomersQuery}
            placeholder="Tìm..."
            className={classes.ToolbarSearch}
            onChange={this.handleMappingCustomersQueryChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        )}
      </div>
      <div className={classes.TabsPanel}>
        {this.state.tabGroupCustomersActive === 0 && (
          <GroupCustomerMappings
            isGroupInactive={this.props.isGroupInactive}
            isEditing={this.props.isEditing}
            groupId={this.props.groupId}
            groupCodeItems={this.props.groupCodeItems}
            customerTypeIds={this.props.customerTypeIds}
            selectedItems={this.state.selectedItems}
            onSelectedItemsChange={this.handleSelectedItemsChange}
          />
        )}
        {this.state.tabGroupCustomersActive === 1 && (
          <GroupMappingCustomers
            isGroupInactive={this.props.isGroupInactive}
            groupId={this.props.groupId}
            groupCodeItems={this.props.groupCodeItems}
            customerTypeIds={this.props.customerTypeIds}
            query={this.state.mappingCustomersQuery}
          />
        )}
      </div>
      { this.state.isConfirmDialogOpen
        && <ConfirmationDialog
          isOpen={this.state.isConfirmDialogOpen}
          title="Xác nhận xoá"
          message="Bạn có chắc xoá các mã khu vực đã chọn ra khỏi nhóm?"
          onClose={this.handleConfirmDialogClose}
          onSubmit={this.handleDeleteGroupCustomerMappings}
        />
      }
    </div>);
  }
}

GroupChannelCustomers.propTypes = {
  isGroupInactive: PropTypes.bool,
  isEditing: PropTypes.bool,
  groupId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  groupCodeItems: PropTypes.string,
  customerTypeIds: PropTypes.array,
};

GroupChannelCustomers.defaultProps = {
  isGroupInactive: false,
  isEditing: false,
  customerTypeIds: [],
};

const mapStateToProps = createStructuredSelector({
  groupCustomersTotal: makeSelectGroupCustomersTotal(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    deleteGroupCustomerMappings: (payload) => dispatch(deleteGroupCustomerMappings(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(GroupChannelCustomers);
