import {
    GET_PRODUCT_CATEGORY_LIST,
    SAVE_STATE_PRODUCT_CATEGORY_LIST,
    SAVE_LOADING_ACTION,
    SAVE_PRODUCT_CATEGORY_ALLOWED_ACTIONS,
} from 'redux/constants/admin/getcareCategoryConstants';
  
export function getGetcareCategoryList(brand) {
    return {
        type: GET_PRODUCT_CATEGORY_LIST,
        brand
    };
}
  

export function saveLoadingAction(isLoading) {
    return {
        type: SAVE_LOADING_ACTION,
        isLoading
    };
}

export function saveStateGetcareCategoryList(getcareCategoryList) {
    return {
        type: SAVE_STATE_PRODUCT_CATEGORY_LIST,
        getcareCategoryList
    };
}

export function saveGetcareCategoryAllowedActions(getcareCategoryAllowedActions) {
    return {
      type: SAVE_PRODUCT_CATEGORY_ALLOWED_ACTIONS,
      getcareCategoryAllowedActions,
    };
}