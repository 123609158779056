import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { 

} from 'utils/constanst/adminAssetComponentConstants';

import IconButton from '@material-ui/core/IconButton';
import EditOutlined from '@material-ui/icons/EditOutlined';
import CloseIcon from '@material-ui/icons/Close';

import gridClasses from '../AssetComponentListGrid.module.scss';
import classes from './AssetComponentListItem.module.scss';

class AssetComponentListItem extends PureComponent {
  render() {
    const {
      displayFields,
      isEditAllowed,
      isDeleteAllowed,
    } = this.props;

    return (
      <div
        className={`${classes.AssetComponentListItem} ${gridClasses.Row} ${gridClasses[`Row__column--${displayFields.length}`]}`}
      >
        {
          displayFields.map((field, index) => {
            let displayValue = <div key={index} className={gridClasses.Col}>
              {this.props[field.name]}
            </div>;
            switch (field.name) {
              case "action": {
                displayValue = <div key={index} className={`${classes.ActionsCol} ${gridClasses.Col}`}>
                  {
                    isEditAllowed && <IconButton
                      size="small"
                      color="primary"
                      onClick={this.props.onEditAssetComponents}
                    ><EditOutlined fontSize="small"/></IconButton>
                  }
                  {
                    isDeleteAllowed && <IconButton
                      size="small"
                      color="secondary"
                      onClick={this.props.onRemoveAssetComponents}
                    ><CloseIcon fontSize="small"/></IconButton>
                  }
                </div>
                break;
              }
              case "link": {
                displayValue = <div key={index} className={gridClasses.Col}>
                  <a href={this.props[field.name]} target="_blank">{this.props[field.name]}</a>
                </div>
                break;
              }
            }
            return displayValue
          })
        }
      </div>
    );
  }
}

AssetComponentListItem.propTypes = {
  displayFields: PropTypes.array,
  isEditAllowed: PropTypes.bool,
  isDeleteAllowed: PropTypes.bool,
};

export default AssetComponentListItem;
