import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import Checkbox from '@material-ui/core/Checkbox';
import { APPROVE } from 'utils/constanst/adminIngredientConstants';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Delete from '@material-ui/icons/Delete';

import gridClasses from '../ProductListMappingGrid.module.scss';
import classes from './ProductListMappingItem.module.scss';

class ProductListMappingItem extends PureComponent {
  handleClickItem = (e) => {
    e.preventDefault();
    this.handleCheckboxChange(e);
  }
  handleCheckboxChange = (e) => {
    e.stopPropagation();

    if (this.props.willRemove) return;

    this.props.onItemSelectedToggle({
      id: this.props.id,
      isSelected: !this.props.isSelected,
    });
  };
  stopPropagation = (e) => {
    e.stopPropagation();
  };
  handleToggleRemoveProduct = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (!this.props.willRemove && this.props.isSelected) {
      // uncheck mapping if this item will be removed
      this.props.onItemSelectedToggle({
        id: this.props.id,
        isSelected: false,
      });
    }
    this.props.onRemoveProductToggle({
      id: this.props.id,
      willRemove: !this.props.willRemove,
    });
  }
  getIngredients = () => {
    const { getcare_product_active_ingredients } = this.props;
    return getcare_product_active_ingredients?.filter(ing => !!ing.getcare_active_ingredients).map(ing => ({
        name: ing.getcare_active_ingredients.l6_name,
        isApproval: ing.getcare_active_ingredients.approval === APPROVE,
      })
    ) || [];
  }

  render() {
    const {
      getcare_id,
      name,
      registration_number,
      dosage_forms,
      getcare_uom,
      active_ingredients_main,
      isSelected,
      getcare_manufacturer,
      id,
      willRemove,
    } = this.props;

    return (
      <Link
        to={`/products/${id}`}
        className={`${classes.ProductItem} ${gridClasses.Row} ${willRemove ? classes.MarkedWillRemove : ''}`}
        onClick={this.handleClickItem}
      >
        <div className={gridClasses.Col}>
          <Checkbox
            className={classes.Checkbox}
            checked={isSelected}
            onClick={this.stopPropagation}
            onChange={this.handleCheckboxChange}
          />
        </div>
        <div className={gridClasses.Col}>{getcare_id}</div>
        <div className={gridClasses.Col}>{name}</div>
        <div className={gridClasses.Col}>{registration_number}</div>
        <div className={gridClasses.Col}>{this.props.package}</div>
        <div className={gridClasses.Col}>{getcare_uom.name}</div>
        <div className={gridClasses.Col}>{dosage_forms}</div>
        <div className={gridClasses.Col}>
          { getcare_manufacturer?.name }
        </div>
        <div className={gridClasses.Col}>{active_ingredients_main}</div>
        <div className={`${gridClasses.Col} ${classes.Tags}`}>
          {this.getIngredients().map((ing, index) => (
            <span key={index} className={`Badge BlueGrey Text ${ing.isApproval ? '' : classes.Unapproved}`}>
              {ing.name}
            </span>
          ))}
        </div>
        <div className={`${gridClasses.Col} ${classes.ActionsCol}`}>
          <IconButton
            onClick={this.handleToggleRemoveProduct}
            style={{ padding: '0' }}
            size="small"
          >
            { willRemove ? <Delete fontSize="small" /> : <DeleteOutline fontSize="small" /> }
          </IconButton>
        </div>
      </Link>
    );
  }
}

ProductListMappingItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  getcare_id: PropTypes.string,
  code: PropTypes.string,
  name: PropTypes.string,
  registration_number: PropTypes.string,
  dosage_forms: PropTypes.string,
  expiry_date: PropTypes.number,
  expiry_date_unit: PropTypes.string,
  getcare_uom: PropTypes.object,
  getcare_manufacturer: PropTypes.object,
  active_ingredients_main: PropTypes.string,
  product_active_ingredients_names: PropTypes.array,
  thumbnail: PropTypes.string,
  isSelected: PropTypes.bool,
  willRemove: PropTypes.bool,
  onItemSelectedToggle: PropTypes.func,
  onRemoveProductToggle: PropTypes.func,
};

ProductListMappingItem.defaultProps = {
  isSelected: false,
  active_ingredients_main: '',
  willRemove: false,
  product_active_ingredients_names: [],
};

export default withRouter(ProductListMappingItem);
