export const ACTIVE = 1;
export const INACTIVE = -1;
export const productBonusStatusMap = {
  [ACTIVE]: 'Đang hoạt động',
  [INACTIVE]: 'Ngừng hoạt động',
};

export const listDisplayFields = [
  {
    name: 'code',
    label: 'Mã hàng khuyến mãi',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'name',
    label: 'Tên hàng khuyến mãi',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'model',
    label: 'Số hiệu/Model',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'getcare_product_unit_name',
    label: 'Đơn vị',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'estimate_quantity',
    label: 'Số lượng\ncó thể tặng',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'getcare_vendor_name',
    label: 'Nhà cung cấp',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'description',
    label: 'Mô tả',
    sortable: true,
    sortDir: '',
  },
]
export const listParamsMap = {
  page_size: 50,
  page: 1,
  order: '',
  active: [ACTIVE],
  code: '',
  name: '',
  model: '',
  getcare_product_unit_name: '',
  getcare_vendor_name: '',
  description: '',
  estimate_quantity_min: '',
  estimate_quantity_max: '',
}
export const filterFields = [
  {
    name: 'active',
    label: 'Trạng thái',
    value: [ACTIVE],
    type: 'custom',
    placeholder: 'Nhập...',
  },
  {
    name: 'code',
    label: 'Mã hàng khuyến hàng',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'name',
    label: 'Tên hàng khuyến mãi',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'model',
    label: 'Số hiệu/Model',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'getcare_product_unit_name',
    label: 'Đơn vị',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'estimate_quantity',
    label: 'Số lượng có thể tặng',
    value: ['', ''],
    type: 'custom',
    placeholder: 'Nhập...',
  },
  {
    name: 'getcare_vendor_name',
    label: 'Nhà cung cấp',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'description',
    label: 'Mô tả',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
]

