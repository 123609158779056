import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { filterFields } from 'utils/constanst/adminGroupConstants';
import groupApi from 'utils/api/admin/groupApi';
import { activeStatusMap } from 'utils/constanst/common';

import FilterField from 'components/FilterField/FilterField';
import Checkboxes from 'components/FilterField/Checkboxes';
import MultiSelect from 'components/FilterField/MultiSelect';

import classes from './GroupListFilters.module.scss';

class GroupListFilters extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fields: [...filterFields],
      groupTypes: [],
    };
  }
  async componentDidMount() {
    const { data: response } = await groupApi.getTypes();
    if (!response.result || !response.data) return;
    this.setState({
      groupTypes: response.data,
    });
  }
  _getFieldIndex = (fieldName) => {
    return this.state.fields.findIndex((item) => item.name === fieldName);
  };
  handleFieldChange = ({ name, value }) => {
    const newFields = this.state.fields.map(field => field.name === name ? { ...field, value } : field);
    this.setState({
      fields: newFields,
    });
    this.props.onFilterChange(newFields, true);
  };

  render() {
    const { fields, groupTypes } = this.state;

    return (
      <div className={classes.Panel}>
        <h3 className={classes.PanelTitle}>Lọc theo:</h3>
        <div className={classes.PanelContent}>
          {fields.map((field) => (
            <FilterField
              key={field.name}
              {...field}
              defaultValue={['active', 'getcare_erp_group_type_id'].includes(field.name) ? [] : ''}
              customField={
                field.name === 'active' ? (
                  <Checkboxes
                    name={field.name}
                    value={field.value}
                    multiple
                    valuesMap={activeStatusMap}
                    handleFieldChange={this.handleFieldChange}
                  />
                ) : ( field.name === 'getcare_erp_group_type_id' ? (
                  <MultiSelect
                    name={field.name}
                    value={field.value}
                    placeholder={field.placeholder}
                    options={groupTypes}
                    handleFieldChange={this.handleFieldChange}
                  />
                ) : '')
              }
              handleFieldChange={this.handleFieldChange}
            />
          ))}
        </div>
      </div>
    );
  }
}

GroupListFilters.propTypes = {
  onFilterChange: PropTypes.func,
};

export default GroupListFilters;
