import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getSalesTeamList } from 'redux/actions/admin/salesTeamActions';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectSalesTeamList,
  makeSelectSalesTeamListTotal,
} from 'redux/selectors';
import {
  listDisplayFields,
  listParamsMap,
  statusDropdownList,
} from 'utils/constanst/adminSalesTeamConstants';
import { getSortsString, getDisplayFields } from 'utils/helper';
import { withRouter } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import SalesTeamList from 'components/admin/SalesTeamList/SalesTeamList';
import ListPagination from 'components/ListPagination/ListPagination';

import classes from './SalesTeams.module.scss';

class SalesTeams extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      listParams: { ...listParamsMap },
    };
  }
  componentDidMount() {
    this.props.getSalesTeamList({
      params: this.state.listParams,
    });
  }
  handleFilterListChange = (filters) => {
    const paramsFromFilters = filters.reduce((memo, item) => {
      memo[item.name] = item.value;
      return memo;
    }, {});
    const newParams = {
      ...this.state.listParams,
      ...paramsFromFilters,
    };
    this.setState({
      listParams: newParams,
    });
    this.props.getSalesTeamList({
      params: newParams,
    });
  };
  handleSortListChange = ({ sortBy, sortDir }) => {
    const newSortString = getSortsString({
      [sortBy]: sortDir,
    });
    this.handleFilterListChange([
      {
        name: 'order',
        value: newSortString,
      },
    ]);
  };
  createNewSalesTeam = () => {
    this.props.history.push(`/salesteam/-1`);
  }
  handleActiveSelectChange = (e) => {
    this.handleFilterListChange([
      {
        name: e.target.name,
        value: e.target.value,
      },
    ]);
  }

  render() {
    const { salesTeamList, salesTeamListTotal } = this.props;
    const { listParams } = this.state;
    const isListLoading = !salesTeamList;

    return (
      <React.Fragment>
        <div className={classes.PageWrap}>
          <div className={classes.PageHeader}>
            <h1 className={classes.PageTitle}>Danh sách Sales Team</h1>
            <Select
              className={classes.SelectWrap}
              value={listParams.active}
              name="active"
              displayEmpty
              autoWidth
              onChange={this.handleActiveSelectChange}
            >
              {
                statusDropdownList.map(item => <MenuItem key={`status-${item.value}`} value={item.value}>{ item.label }</MenuItem>)
              }
            </Select>
            <Button
              size="small"
              variant="contained"
              color="primary"
              startIcon={<Add />}
              onClick={this.createNewSalesTeam}
            >Tạo mới</Button>
          </div>
          <div className={classes.PageMain}>
            <SalesTeamList
              displayFields={getDisplayFields(
                listParams,
                listDisplayFields
              )}
              salesTeamList={salesTeamList}
              isLoading={isListLoading}
              onSortChange={this.handleSortListChange}
            />
          </div>
          <div className={classes.PageFooter}>
            <ListPagination
              page={listParams.page}
              pageSize={listParams.page_size}
              total={salesTeamListTotal}
              listName="sales team"
              onFilterChange={this.handleFilterListChange}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  salesTeamList: makeSelectSalesTeamList(),
  salesTeamListTotal: makeSelectSalesTeamListTotal(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getSalesTeamList: (payload) => dispatch(getSalesTeamList(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter)(SalesTeams);
