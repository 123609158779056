import React, { PureComponent } from 'react';
import { dateFormat } from 'utils/constanst/dateConstants';
import { validDate } from 'utils/helper';
import priceTestApi from 'utils/api/admin/priceTestApi';
import { toast } from 'react-toastify';
import isEqual from 'lodash/isEqual';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import CustomerSelection from './CustomerSelection/CustomerSelection';
import ProductSelection from './ProductSelection/ProductSelection';
import Prices from './Prices/Prices';
import Button from '@material-ui/core/Button';
import DateFnsUtils from '@date-io/date-fns';
import Checkbox from '@material-ui/core/Checkbox';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import classes from './CustomerPrices.module.scss';

class CustomerPrices extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      orderDate: new Date(),
      selectedCustomer: null,
      selectedProduct: null,
      priceListMap: null,
      isRetail: false,
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { orderDate, selectedCustomer, selectedProduct, priceListMap } = this.state;
    if (priceListMap
      && ((orderDate && !isEqual(orderDate, prevState.orderDate))
        || (selectedCustomer && !isEqual(selectedCustomer, prevState.selectedCustomer))
        || (selectedProduct && !isEqual(selectedProduct, prevState.selectedProduct)))
    ) {
      this.setState({ priceListMap: null });
    }
  }
  _isAbleToCheck = () => {
    const { orderDate, selectedCustomer, selectedProduct } = this.state;
    return validDate(orderDate) && selectedCustomer && selectedProduct;
  }
  _loadPrice = async () => {
    const { data: response } = await priceTestApi.getProductCustomerPrices({
      params: {
        is_retail: this.state.isRetail ? 1 : 0,
        getcare_customer_id: this.state.selectedCustomer?.id,
        getcare_product_id: this.state.selectedProduct?.id,
        date: validDate(this.state.orderDate) && this.state.orderDate.toISOString(),
      },
    });
    if (!response?.result || !response.data) {
      toast.error(response.message);
      return;
    }
    this.setState({
      priceListMap: response.data,
    });
  }
  handleChangeCustomer = (newValue) => {
    this.setState({
      selectedCustomer: { ...newValue },
    });
  }
  handleChangeProduct = (newValue) => {
    this.setState({
      selectedProduct: { ...newValue },
    });
  }
  handleCheckPrices = () => {
    this._loadPrice();
  }

  render() {
    const { isRetail } = this.state;
    return (<>
      <FormControlLabel
        control={
          <Checkbox
            checked={isRetail}
            onChange={(e) => this.setState({ isRetail: e.target.checked })}
            name="isRetail"
            color="primary"
          />
        }
        label="Khách hàng lẻ"
      />
      <CustomerSelection selectedCustomer={this.state.selectedCustomer} onChangeCustomer={this.handleChangeCustomer}/>
      <ProductSelection selectedProduct={this.state.selectedProduct} onChangeProduct={this.handleChangeProduct}/>
      <div className={`${classes.ControlWrap} ${classes.DateControlWrap}`}>
        <label>Ngày đơn hàng</label>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            fullWidth
            disableToolbar
            autoOk
            variant="inline"
            format={dateFormat}
            value={validDate(this.state.orderDate) ? validDate(this.state.orderDate) : ''}
            onChange={(date) => {
              this.setState({
                orderDate: date,
              });
            }}
            placeholder="Chọn hoặc nhập ngày đơn hàng"
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>
      </div>
      <Button
        disabled={!this._isAbleToCheck()}
        variant="contained"
        color="primary"
        onClick={this.handleCheckPrices}
      >
        Check giá
      </Button>
      { this._isAbleToCheck() && this.state.priceListMap?.header && <Prices list={this.state.priceListMap} /> }
    </>);
  }
}

export default CustomerPrices;
