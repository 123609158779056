import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getAwaitingProductsByPharmacyId } from 'redux/actions/admin/productActions';
import { createStructuredSelector } from "reselect";
import {
  makeSelectAwaitingProductList,
  makeSelectAwaitingProductsTotal,
} from 'redux/selectors';
import { awaitingListParamsMap, awaitingListDisplayFields } from 'utils/constanst/adminProductConstants';
import productApi from 'utils/api/admin/productApi';
import { getSortsString, getDisplayFields } from 'utils/helper';

import AwaitingProductListItem from './AwaitingProductListItem/AwaitingProductListItem';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';
import ListPagination from 'components/ListPagination/ListPagination';
import Checkbox from '@material-ui/core/Checkbox';
import ApproveGroupBtns from './ApproveGroupBtns/ApproveGroupBtns';
import DeleteGroupBtns from './DeleteGroupBtns/DeleteGroupBtns';
import ExportGroupBtns from './ExportGroupBtns/ExportGroupBtns';

import gridClasses from './AwaitingProductListGrid.module.scss';
import classes from './AwaitingProductList.module.scss';

class AwaitingProductList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      listParams: { ...awaitingListParamsMap },
      isLoading: false,
      selectedIds: [],
    }
  }
  componentDidMount() {
    this.loadData({ params: this.state.listParams });
  }
  componentDidUpdate(prevProps) {
    const { awaitingProducts, pharmacyId } = this.props;
    if (awaitingProducts !== prevProps.awaitingProducts && !prevProps.awaitingProducts) {
      this.setState({
        isLoading: false,
      });
    }
    if (pharmacyId !== prevProps.pharmacyId) {
      this.resetAndReloadList();
    }
    const { selectedIds } = this.state;
    if (awaitingProducts !== prevProps.awaitingProducts && selectedIds.length > 0) {
      const remainIds = selectedIds.filter(pId => awaitingProducts.some(item => item.id === pId));
      this.setState({
        selectedIds: remainIds,
      });
    }
  }
  resetAndReloadList = () => {
    this.setState({
      listParams: { ...awaitingListParamsMap },
      selectedIds: [],
      isLoading: false,
    });
    this.loadData({
      params: { ...awaitingListParamsMap },
    });
  }
  loadData = ({ params }) => {
    const { pharmacyId } = this.props;
    if (!pharmacyId) return;
    const payload = {
      params: {...params},
      pharmacyId: pharmacyId,
    };
    this.setState({
      isLoading: true,
    });
    this.props.getAwaitingProductsByPharmacyId(payload);
  }
  handleSortChange = ({ sortBy, sortDir }) => {
    const newSortString = getSortsString({[sortBy]: sortDir});
    this.handleFilterChange([{
      name: 'order',
      value: newSortString,
    }]);
  }
  handleFilterChange = (filters, forceResetPage: false) => {
    const paramsFromFilters = filters.reduce((memo, item) => {
      memo[item.name] = item.value;
      return memo;
    }, {});
    const newParams = forceResetPage ? {
      ...this.state.listParams,
      ...paramsFromFilters,
      page: 1,
    } : {
      ...this.state.listParams,
      ...paramsFromFilters,
    };
    this.setState({
      listParams: newParams,
    });
    this.loadData({ params: this.state.listParams });
  }
  isAllProductSelected = () => {
    const { awaitingProducts } = this.props;
    const { selectedIds } = this.state;
    return selectedIds.length > 0 && selectedIds.length >= awaitingProducts.length;
  }
  isProductSelected = (id) => {
    return this.state.selectedIds.findIndex(item => item === id) > -1;
  }
  handleAllSelectedToggle = (e) => {
    const allIds = this.props.awaitingProducts.map(item => item.id);
    this.setState({
      selectedIds: e.target.checked ? allIds : [],
    });
  }
  handleItemSelectedToggle = ({ id, isSelected }) => {
    const remainIds = this.state.selectedIds.filter(item => item !== id);
    this.setState({
      selectedIds: isSelected ? [...remainIds, id] : remainIds,
    });
  }
  handleExportAndDeleteAllProducts = () => {

  }
  handleEpxortAllProducts = () => {

  }
  handleApproveAllProducts = async () => {
    try {
      const { data: response } = await productApi.approveProducts({ params: { all: true } });
      if (!response?.result) return;
      this.resetAndReloadList();
    } catch(err) {
      console.log(err);
    }
  }
  handleApproveSelectedProducts = async () => {
    try {
      const { data: response } = await productApi.approveProducts({
        params: {
          all: false,
          product_other_ids: [...this.state.selectedIds],
        },
      });
      if (!response?.result) return;
      this.resetAndReloadList();
    } catch(err) {
      console.log(err);
    }
  }
  handleDeleteAllProducts = async () => {
    try {
      const { data: response } = await productApi.deleteOtherProducts({ params: { all: true } });
      if (!response?.result) return;
      this.resetAndReloadList();
    } catch(err) {
      console.log(err);
    }
  }
  handleDeleteSelectedProducts = async () => {
    try {
      const { data: response } = await productApi.deleteOtherProducts({
        params: {
          all: false,
          product_other_ids: [...this.state.selectedIds],
        },
      });
      if (!response?.result) return;
      this.resetAndReloadList();
    } catch(err) {
      console.log(err);
    }
  }

  render() {
    const { awaitingProducts, awaitingProductsTotal } = this.props;
    const { isLoading, listParams, selectedIds } = this.state;
    const displayFields = getDisplayFields(this.state.listParams, awaitingListDisplayFields);
    const selectedIdsLength = selectedIds.length;

    return <div className={classes.ListWrap}>
      <div className={classes.Toolbar}>
        <ExportGroupBtns
          exportAndDeleteAllProducts={this.handleExportAndDeleteAllProducts}
          epxortAllProducts={this.handleEpxortAllProducts}
          listLength={awaitingProductsTotal}
        />
        <ApproveGroupBtns
          approveAllProducts={this.handleApproveAllProducts}
          approveSelectedProducts={this.handleApproveSelectedProducts}
          selectedLength={selectedIdsLength}
          listLength={awaitingProductsTotal}
        />
        <DeleteGroupBtns
          deleteAllProducts={this.handleDeleteAllProducts}
          deleteSelectedProducts={this.handleDeleteSelectedProducts}
          selectedLength={selectedIdsLength}
          listLength={awaitingProductsTotal}
        />
      </div>
      <div
        className={`${classes.List} ${isLoading && 'OverlayLoading'}`}
        style={{
          overflowX: this.rowRef ? 'scroll' : '',
        }}
      >
        <div
          className={`${classes.Header}`}
          ref={(el) => (this.rowRef = el)}
          style={{width: (this.rowRef?.offsetWidth) || 'auto'}}
        >
          <div className={`${gridClasses.Row} ${classes.Header}`}>
            <div className={gridClasses.Col}>
              <Checkbox
                className={classes.Checkbox}
                checked={this.isAllProductSelected()}
                onChange={this.handleAllSelectedToggle}
              />
            </div>
            { displayFields.map((item, index) => (
              <ListHeaderCol
                key={`${item.name}-${index}`}
                className={gridClasses.Col}
                {...item}
                onSortChange={this.handleSortChange}
              />
            )) }
          </div>
        </div>
        <div
          className={`${classes.Body}`}
          style={{width: (this.rowRef?.offsetWidth) || 'auto'}}
        >
          { (awaitingProducts && awaitingProducts.length)
            ? awaitingProducts.map(item => (
              <AwaitingProductListItem
                key={`medicine-${item.id}`}
                {...item}
                brands={this.props.brands}
                industries={this.props.industries}
                isSelected={this.isProductSelected(item.id)}
                onItemSelectedToggle={this.handleItemSelectedToggle}
              />))
            : (<p className="NoData">Không có data</p>)
          }
        </div>
      </div>
      <div className={classes.Footer}>
        <ListPagination
          page={listParams.page}
          pageSize={listParams.page_size}
          listName="sản phẩm"
          total={awaitingProductsTotal}
          onFilterChange={this.handleFilterChange}
        />
      </div>
    </div>;
  }
}

AwaitingProductList.propTypes = {
  pharmacyId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  brands: PropTypes.array,
  industries: PropTypes.array,
};

AwaitingProductList.defaultProps = {
  brands: [],
  industries: [],
};

const mapStateToProps = createStructuredSelector({
  awaitingProducts: makeSelectAwaitingProductList(),
  awaitingProductsTotal: makeSelectAwaitingProductsTotal(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getAwaitingProductsByPharmacyId: (payload) => dispatch(getAwaitingProductsByPharmacyId(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(AwaitingProductList);
