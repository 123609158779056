export const ACTIVE = 1;
export const DEACTIVE = -1;
export const APPROVE = 1;
export const UNAPPROVE = -1;
export const approvalStatusMap = {
  '1': 'Đã duyệt',
  '-1': 'Chưa duyệt',
};
export const activeStatusMap = {
  '1': 'Đang hoạt động',
  '-1': 'Ngừng hoạt động',
};
export const relatedPageSize = 20;
export const listDisplayFields = [
  {
    name: 'code',
    label: 'Mã nhà cung cấp',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'name',
    label: 'Tên nhà cung cấp',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'phone',
    label: 'Số điện thoại',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'tax_code',
    label: 'Mã số thuế',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'registration_company',
    label: 'Tên doanh nghiệp',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'registration_address',
    label: 'Địa chỉ',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'ranking',
    label: 'Ranking',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'active',
    label: 'Trạng thái duyệt',
    sortable: true,
    sortDir: '',
  },
]

export const listParamsMap = {
  page_size: 50,
  page: 1,
  order: '',
  code: '',
  name: '',
  phone: '',
  tax_code: '',
  registration_company: '',
  registration_address: '',
  active: '',
}

export const filterFields = [
  {
    name: 'code',
    label: 'Mã nhà cung cấp',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'name',
    label: 'Tên nhà cung cấp',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'phone',
    label: 'Số điện thoại',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'tax_code',
    label: 'Mã số thuế',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'registration_company',
    label: 'Người đại diện',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'registration_address',
    label: 'Địa chỉ',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'approval',
    label: 'Trạng thái duyệt',
    value: [],
    type: 'custom',
    placeholder: 'Nhập...',
  },
]
