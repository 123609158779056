import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import authApi from 'utils/api/authApi';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { toast } from 'react-toastify';

import classes from './DescriptionImportDialog.module.scss';

class DescriptionImportDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      description: '',
      images: [],
    };
  }

  onClose = () => {
    this.props.onClose();
  };

  handleSubmitImport = async () => {
    this.setState({
      isLoading: true,
    })

    const {data} = this.state;
    if ( !data ) return;
    this.setState({
      isLoading: false,
    })
    this.props.onCompleteImportDescription(data);

    this.props.onClose();
  };


  render() {
    const { isOpen, title ,dataDescription} = this.props;
    const {  isLoading } = this.state;
    return (
      <Dialog
        open={isOpen}
        maxWidth="lg"
        fullWidth
        onClose={this.onClose}
        // onEntered={this.onEntered}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.Dialog}
        disableBackdropClick
      >
        <div className={`${classes.DialogContentWrap} ${isLoading && 'OverlayLoading'}`}>
          <DialogTitle>{title}</DialogTitle>
            <DialogContent className={classes.FieldContent}>
              <div className={classes.FieldControl}>
              <CKEditor
          editor={ClassicEditor}
          data={dataDescription}
          config={{
            toolbar: [
              "heading",
              "code",
              "|",
              "bold",
              "italic",
              "link",
              "bulletedList",
              "numberedList",
              "blockQuote",
              "ckfinder",
              "|",
              "imageTextAlternative",
              "imageUpload",
              "imageStyle:full",
              "imageStyle:side",
              "|",
              "mediaEmbed",
              "insertTable",
              "tableColumn",
              "tableRow",
              "mergeTableCells",
              "|",
              "undo",
              "redo"
            ]
          }}
          onInit={(editor,event) => {
            // You can store the "editor" and use when it is needed.
            editor.editing.view.change( writer => {
              writer.setStyle( 'height', '400px', editor.editing.view.document.getRoot() );
          } );
            editor.plugins.get( 'FileRepository' ).createUploadAdapter = function( loader ) {
              return new UploadAdapter( loader );
            };


          }}
          onChange={(event, editor) => {
            const data = editor.getData();  
             this.setState({data});
          }}
         
        />
              </div>
            </DialogContent>
            <DialogActions className={`${classes.DialogActions}`}>
              <Button variant="outlined" onClick={this.onClose}>
                Huỷ
              </Button>
              <Button
                // disabled={!this.isFormValid()}
                variant="contained"
                onClick={this.handleSubmitImport}
                color="primary"
                autoFocus
              >
                Import
              </Button>
            </DialogActions>
        </div>
      </Dialog>
    );
  }
  
}

class UploadAdapter {
  constructor( loader ) {
    this.loader = loader;
    this.upload = this.upload.bind(this)
    this.abort = this.abort.bind(this)
  }

  upload() {
    return new Promise(async (resolve, reject) => {
      this.loader.file.then( async( file) => {
        const fileUpload = {
           file: [file]
         };
         const { data: response } = await authApi.uploadMultipleFiles({
          params: fileUpload
        });
          resolve({ default: response.data[0].url});
      });
  });

  }

  abort() {
    // Reject promise returned from upload() method.
  }
}          

DescriptionImportDialog.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  onCompleteImport: PropTypes.func,
};

DescriptionImportDialog.defaultProps = {
  isOpen: false,
  isLoading: false,
};

export default DescriptionImportDialog;
