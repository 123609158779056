import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import salesChannelApi from 'utils/api/admin/salesChannelApi';

import Checkbox from '@material-ui/core/Checkbox';
import EditOutlined from '@material-ui/icons/EditOutlined';
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import IconButton from '@material-ui/core/IconButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import classes from './ShippingCouponSalesChannel.module.scss';
import gridClasses from '../ShippingCouponSalesChannelsGrid.module.scss';

class ShippingCouponSalesChannel extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: props.isEditing,

      params: this._getInitParams(props),

      salesChannelOptions: this._initSalesChannelOptions(props),
      salesChannelCodeInput: '',
    };
  }
  componentDidUpdate(prevProps, prevState) {
    const { salesChannelCodeInput, isEditing, params } = this.state;
    if (salesChannelCodeInput && salesChannelCodeInput !== prevState.salesChannelCodeInput) {
      this._loadSuggestedSalesChannels({
        params: {
          code: salesChannelCodeInput,
        },
      });
    }
    if (isEditing !== prevState.isEditing && this._isRowValid(params)) {
      this.props.saveShippingCouponSalesChannel({
        ...params,
        idStr: this.props.idStr,
        id: this.props.id,
        isEditing,
      });
    }
  }

  _initSalesChannelOptions = (params) =>{
    return params.getcare_sales_channel ? [{...params.getcare_sales_channel}] : [];
  }
  _loadSuggestedSalesChannels = debounce(async ({ params }) => {
    const { data: response } = await salesChannelApi.getAll({
      params: params,
    });
    if (!response?.result) return;
    this.setState({
      salesChannelOptions: response.data
        ? this._filteredOptions([...response.data])
        : [],
    });
  }, 500);
  _filteredOptions = (fullArr) => {
    return fullArr.filter(p =>
      (p.id === this.props.getcare_sales_channel?.id) ||
      !this.props.currentList.some(
        (item) => item.getcare_sales_channel?.id === p.id
      )
    );
  };
  _getSalesChannelOptionLabel = (option) => {
    return `${option.code}`
  }
  _getInitParams = (params) => {
    return {
      idStr: params?.idStr,
      id: params?.id,
      getcare_sales_channel: params?.id ? { ...params } : params?.getcare_sales_channel,
    }
  }
  _resetState = () => {
    this.setState({
      isEditing: false,
      params: this._getInitParams(this.props),
      salesChannelOptions: this._initSalesChannelOptions(this.props),
      salesChannelCodeInput: '',
    });
  }
  _hasSalesChannel = (params) => {
    return !!params.getcare_sales_channel?.id;
  }
  _isRowValid = (params) => {
    return this._hasSalesChannel(params);
  };

  stopPropagation = (e) => {
    e.stopPropagation();
  }

  handleCheckboxChange = (e) => {
    e.stopPropagation();
    this.props.onItemSelectedToggle({
      item: {
        id: this.props.id,
        idStr: this.props.idStr,
        getcare_sales_channel: { ...this.props.getcare_sales_channel },
      },
      isSelected: !this.props.isSelected,
    });
  };
  handleSaveField = (e, value) => {
    this.setState({
      params: {
        ...this.state.params,
        [e.target.name]: value,
      },
    });
  }
  handleRowClick = () => {
    if (!this.state.isEditing) {
      this.handleEdit();
    }
  }
  handleEdit = (e) => {
    if (e) e.stopPropagation();
    this.setState({
      isEditing: true,
    });
  }
  handleCancel = (e) => {
    if (e) e.stopPropagation();
    if (this._isRowValid(this.props)) {
      // if it was saved to DB or it was used to save on client successful
      this._resetState();
      return;
    }
    this.props.removeShippingCouponSalesChannel({
      id: this.props.id,
      idStr: this.props.idStr,
    });
  }
  handleSave = (e) => {
    if (e) e.stopPropagation();
    this.setState({
      isEditing: false,
    });
  }
  handleSelectSalesChannelChange = (e, newValue) => {
    this.setState({
      params: {
        ...this.state.params,
        getcare_sales_channel: newValue,
      },
    });
  };

  render() {
    const { getcare_sales_channel, isSelected } = this.props;
    const { isEditing, params, salesChannelOptions } = this.state;

    return (
      <>
        <div
          className={`${classes.Item} ${gridClasses.Row}`}
          onClick={this.handleRowClick}
        >
          <div className={gridClasses.Col}>
            <Checkbox
              className={classes.Checkbox}
              checked={isSelected}
              onClick={this.stopPropagation}
              onChange={this.handleCheckboxChange}
            />
          </div>
          <div className={`${gridClasses.Col}`}>
            {isEditing ? (
              <Autocomplete
                openOnFocus
                selectOnFocus
                disableClearable
                fullWidth
                size="small"
                handleHomeEndKeys={false}
                value={params.getcare_sales_channel}
                onChange={this.handleSelectSalesChannelChange}
                options={salesChannelOptions || []}
                filterOptions={(x) => x}
                renderOption={(option) => this._getSalesChannelOptionLabel(option)}
                getOptionLabel={(option) => ((option && option.code) || '')}
                getOptionSelected={(option, value) =>
                  value && value.id && option ? option.id === value.id : null
                }
                onInputChange={(e, newInputValue) => {
                  this.setState({
                    salesChannelCodeInput: newInputValue,
                  });
                }}
                renderInput={(parms) => (
                  <TextField {...parms} error={!this._isRowValid(params)} placeholder="- Chọn -" />
                )}
              />
            ) : (
              getcare_sales_channel?.code
            )}
          </div>
          <div className={`${gridClasses.Col} ${classes.ActionsCol}`}>
            {isEditing ? (
              <>
                <IconButton
                  onClick={this.handleCancel}
                  style={{ padding: '0' }}
                  size="small"
                >
                  <CancelOutlined fontSize="small" color="secondary" />
                </IconButton>
                <IconButton
                  onClick={this.handleSave}
                  disabled={!this._isRowValid(params)}
                  style={{ padding: '0' }}
                  size="small"
                >
                  <CheckCircleOutlineOutlined
                    fontSize="small"
                    color={this._isRowValid(params) ? `primary` : `inherit`}
                  />
                </IconButton>
              </>
            ) : (
              <>
                <IconButton
                  onClick={this.handleEdit}
                  style={{ padding: '0' }}
                  size="small"
                >
                  <EditOutlined fontSize="small" color="primary" />
                </IconButton>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

ShippingCouponSalesChannel.propTypes = {
  idStr: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currentList: PropTypes.array,
  isSelected: PropTypes.bool,
  saveShippingCouponSalesChannel: PropTypes.func,
  removeShippingCouponSalesChannel: PropTypes.func,
  onItemSelectedToggle: PropTypes.func,
};

ShippingCouponSalesChannel.defaultProps = {
  currentList: [],
  isSelected: false,
};

export default ShippingCouponSalesChannel;
