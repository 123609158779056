export const GET_KEY_ACCOUNT_LIST = 'getcare/admin/keyAccount/GET_KEY_ACCOUNT_LIST';
export const GET_KEY_ACCOUNT = 'getcare/admin/keyAccount/GET_KEY_ACCOUNT';

export const UPDATE_KEY_ACCOUNT = 'getcare/admin/keyAccount/UPDATE_KEY_ACCOUNT';

export const GET_KEY_ACCOUNT_PRODUCTS = 'getcare/admin/keyAccount/GET_KEY_ACCOUNT_PRODUCTS';
export const GET_KEY_ACCOUNT_HISTORY = 'getcare/admin/keyAccount/GET_KEY_ACCOUNT_HISTORY';

// mutation
export const SAVE_LOADING = 'getcare/admin/keyAccount/SAVE_LOADING';
export const SAVE_ACTION_LOADING = 'getcare/admin/keyAccount/SAVE_ACTION_LOADING';

export const SAVE_KEY_ACCOUNT_LIST = 'getcare/admin/keyAccount/SAVE_KEY_ACCOUNT_LIST';
export const SAVE_KEY_ACCOUNT_LIST_TOTAL = 'getcare/admin/keyAccount/SAVE_KEY_ACCOUNT_LIST_TOTAL';

export const RESET_KEY_ACCOUNT = 'getcare/admin/keyAccount/RESET_KEY_ACCOUNT';

export const SAVE_ORI_KEY_ACCOUNT_DETAILS = 'getcare/admin/keyAccount/SAVE_ORI_KEY_ACCOUNT_DETAILS';
export const SAVE_KEY_ACCOUNT_DETAILS = 'getcare/admin/keyAccount/SAVE_KEY_ACCOUNT_DETAILS';

export const SAVE_ORI_KEY_ACCOUNT_PRODUCTS = 'getcare/admin/keyAccount/SAVE_ORI_KEY_ACCOUNT_PRODUCTS';
export const SAVE_KEY_ACCOUNT_PRODUCTS = 'getcare/admin/keyAccount/SAVE_KEY_ACCOUNT_PRODUCTS';
export const SAVE_KEY_ACCOUNT_PRODUCT_ITEM = 'getcare/admin/keyAccount/SAVE_KEY_ACCOUNT_PRODUCT_ITEM';

export const SAVE_ORI_KEY_ACCOUNT_SALES_USERS = 'getcare/admin/keyAccount/SAVE_ORI_KEY_ACCOUNT_SALES_USERS';
export const SAVE_KEY_ACCOUNT_SALES_USERS = 'getcare/admin/keyAccount/SAVE_KEY_ACCOUNT_SALES_USERS';
export const SAVE_KEY_ACCOUNT_SALES_USER_ITEM = 'getcare/admin/keyAccount/SAVE_KEY_ACCOUNT_SALES_USER_ITEM';
export const REMOVE_KEY_ACCOUNT_SALES_USER_ITEMS = 'getcare/admin/keyAccount/REMOVE_KEY_ACCOUNT_SALES_USER_ITEMS';

export const SAVE_ORI_KEY_ACCOUNT_CUSTOMERS = 'getcare/admin/keyAccount/SAVE_ORI_KEY_ACCOUNT_CUSTOMERS';
export const SAVE_KEY_ACCOUNT_CUSTOMERS = 'getcare/admin/keyAccount/SAVE_KEY_ACCOUNT_CUSTOMERS';
export const SAVE_KEY_ACCOUNT_CUSTOMER_ITEM = 'getcare/admin/keyAccount/SAVE_KEY_ACCOUNT_CUSTOMER_ITEM';
export const REMOVE_KEY_ACCOUNT_CUSTOMER_ITEMS = 'getcare/admin/keyAccount/REMOVE_KEY_ACCOUNT_CUSTOMER_ITEMS';

export const SAVE_KEY_ACCOUNT_HISTORY = 'getcare/admin/keyAccount/SAVE_KEY_ACCOUNT_HISTORY';
