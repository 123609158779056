import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getSalesTeam } from 'redux/actions/admin/salesTeamActions';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectSalesTeamMembers,
  makeSelectSalesTeamDetails,
  makeSelectSalesTeamChannels,
} from 'redux/selectors';
import {
  updateSalesTeam,
  resetSalesTeam,
} from 'redux/actions/admin/salesTeamActions';
import { debounce } from 'lodash';
import { toast } from 'react-toastify';

import Button from '@material-ui/core/Button';
import ArrowBack from '@material-ui/icons/ArrowBack';
import CheckOutlined from '@material-ui/icons/CheckOutlined';
import { Tabs, Tab } from '@material-ui/core';
import SalesTeamDetails from 'components/admin/SalesTeamDetails/SalesTeamDetails';
import SalesTeamMembers from 'components/admin/SalesTeamMembers/SalesTeamMembers';
import SalesTeamChannels from 'components/admin/SalesTeamChannels/SalesTeamChannels';
import SalesTeamKeyAccounts from 'components/admin/SalesTeamKeyAccounts/SalesTeamKeyAccounts';

import classes from './SalesTeam.module.scss';

class SalesTeam extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabActive: 0,
      error: null,
      codeOptions: [],
    };
    this.validate = {
      code: {
        template: /^[a-zA-Z0-9\-_\s]*$/,
        required: true,
        maxLength: 20,
        duplicated: false,
      },
      name: {
        required: true,
        maxLength: 55,
      },
      description: {
        maxLength: 255,
      },
      membersLength: 1,
    };
  }
  componentDidMount() {
    const { id } = this.props.match.params;
    this.loadData(id);
  }
  componentDidUpdate(prevProps) {
    const { id } = this.props.match.params;
    if (id !== prevProps.match.params.id) {
      this.loadData(id);
    }
    const { salesTeamDetails } = this.props;
    if (salesTeamDetails && salesTeamDetails.id) {
      if (
        !prevProps.salesTeamDetails ||
        salesTeamDetails.id !== prevProps.salesTeamDetails.id
      ) {
        // created successfull
        if (!prevProps.salesTeamDetails || !prevProps.salesTeamDetails.id) {
          this.props.history.push(`/salesteam/${salesTeamDetails.id}`);
          return;
        }
      }
    }
  }
  loadData = (id) => {
    if (id && id !== `-1` && id !== -1) {
      this.props.getSalesTeam(id);
      return;
    }
    this.props.resetSalesTeam();
  };
  backToList = () => {
    this.props.history.push('/salesteam');
  };
  isEditing = () => {
    const { id } = this.props.match.params;
    return id && id !== `-1`;
  };
  isCodeDuplicated = () => {
    if (this.isEditing()) return [];

    const { salesTeamDetails } = this.props;
    if (!salesTeamDetails || !salesTeamDetails.code) return [];

    let messages = [];
    const { codeOptions } = this.state;
    const anotherCode = codeOptions &&
      codeOptions.length > 0 &&
      codeOptions.find(
        (option) =>
          option.code.toLowerCase() === salesTeamDetails.code.toLowerCase()
      );
    if (anotherCode) {
      messages.push('• Mã sales team bị trùng');
    }
    return messages;
  };
  isCodeValid = () => {
    if (this.isEditing()) return true;

    const code = this.props.salesTeamDetails?.code || '';
    return (
      code.trim() !== '' &&
      this.validate.code.template.test(code)
    );
  };
  isNameValid = () => {
    const name = this.props.salesTeamDetails?.name || '';
    return (
      name.trim() !== '' && name.trim().length <= this.validate.name.maxLength
    );
  };
  isMembersValid = () => {
    const { salesTeamMembers } = this.props;
    return (
      salesTeamMembers &&
      salesTeamMembers.length > 0 &&
      salesTeamMembers.some((item) => !!item.getcare_sales?.id)
    );
  };
  isChannelsValid = () => {
    const { salesTeamChannels } = this.props;
    return (
      salesTeamChannels &&
      salesTeamChannels.length > 0 &&
      salesTeamChannels.some((item) => !!item.sales_channel?.id)
    );
  };
  isValid = () => {
    return (
      this.isNameValid() &&
      this.isCodeValid() &&
      this.isMembersValid() &&
      this.isChannelsValid()
    );
  };
  handleTabChange = (e, value) => {
    this.setState({
      tabActive: value,
    });
  };
  handleCheckCodeDupplication = (codeOptions) => {
    this.setState({
      codeOptions,
    });
  };
  handleCancel = () => {
    this.loadData(this.props.match.params.id);
  };
  hasWarningMessages = () => {
    const codeDuplicatedMsgs = this.isCodeDuplicated(this.props.salesTeamDetails?.code);
    const messages = [
      ...codeDuplicatedMsgs,
    ];
    if (messages.length) {
      toast.error(messages.join('\n'));
      return true;
    }
    return false;
  }
  handleSave = (isSavingList: false, paramDetails: null) => {
    const isEditing = this.isEditing();
    if (isSavingList && !isEditing) return;

    const hasErrors = this.hasWarningMessages();
    if (hasErrors) return;

    const {
      salesTeamMembers,
      salesTeamDetails,
      salesTeamChannels,
    } = this.props;
    const params = {
      id: isEditing ? salesTeamDetails.id : undefined,
      code: isEditing ? paramDetails?.code : salesTeamDetails?.code,
      name: isEditing ? paramDetails?.name : salesTeamDetails?.name,
      description: isEditing ? paramDetails?.description : salesTeamDetails?.description,
      getcare_sales_teams: salesTeamMembers
        .filter((member) => !!member.getcare_sales?.id)
        .map((member) => ({
          id: member.id ? member.id : undefined,
          getcare_sales: {
            id: member.getcare_sales.id || undefined,
            name: member.getcare_sales.name,
          },
          getcare_sales_role: member.getcare_sales_role
            ? {
                id: member.getcare_sales_role.id,
                name: member.getcare_sales_role.name,
              }
            : undefined,
        })),
      getcare_sales_channel_teams: salesTeamChannels
        .filter((item) => !!item.sales_channel?.id)
        .map((item) => ({
          id: item.id ? item.id : undefined,
          getcare_sales_channel_id: item.sales_channel.id,
        })),
    };
    this.props.updateSalesTeam({ params });
  };
  handleSaveLists = debounce(() => {
    this.handleSave(true);
  }, 1000);
  handleSaveDetails = (params) => {
    this.handleSave(true, params);
  };

  render() {
    return (
      <div className={`${classes.PageWrap}`}>
        <div className={classes.PageHeader}>
          <h1 className={classes.PageTitle}>
            {this.isEditing() ? `Chi tiết Sales Team` : `Tạo mới Sales Team`}
          </h1>
          <Button
            size="small"
            variant="outlined"
            startIcon={<ArrowBack />}
            onClick={this.backToList}
          >
            Trở về
          </Button>
          {!this.isEditing() && <Button
            size="small"
            variant="contained"
            color="primary"
            disabled={!this.isValid()}
            startIcon={<CheckOutlined />}
            onClick={() => this.handleSave()}
          >
            Lưu
          </Button>}
        </div>
        <div className={classes.PageMain}>
          <SalesTeamDetails
            key={this.props.salesTeamDetails?.id || -1}
            isEditing={this.isEditing()}
            isNameValid={this.isNameValid()}
            isCodeValid={this.isCodeValid()}
            checkCodeDupplication={this.handleCheckCodeDupplication}
            onSaveDetails={this.handleSaveDetails}
          />
          <Tabs
            className={classes.TabsWrap}
            value={this.state.tabActive}
            onChange={this.handleTabChange}
          >
            <Tab label="Thành viên" />
            <Tab label="Khu vực bán hàng" />
            <Tab label="Key account" />
          </Tabs>
          <div className={classes.TabsPanel}>
            {this.state.tabActive === 0 && (
              <SalesTeamMembers isEditing={this.isEditing()} onSaveLists={this.handleSaveLists} />
            )}
            {this.state.tabActive === 1 && (
              <SalesTeamChannels isEditing={this.isEditing()} onSaveLists={this.handleSaveLists} />
            )}
            {this.state.tabActive === 2 && (
              <SalesTeamKeyAccounts isEditing={this.isEditing()} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  salesTeamChannels: makeSelectSalesTeamChannels(),
  salesTeamMembers: makeSelectSalesTeamMembers(),
  salesTeamDetails: makeSelectSalesTeamDetails(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getSalesTeam: (payload) => dispatch(getSalesTeam(payload)),
    updateSalesTeam: (payload) => dispatch(updateSalesTeam(payload)),
    resetSalesTeam: (payload) => dispatch(resetSalesTeam(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter)(SalesTeam);
