import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import { validDate } from 'utils/helper';
import vendorApi from 'utils/api/admin/vendorApi';
import { dateFormat } from 'utils/constanst/dateConstants';
import classes from './VendorDocuments.module.scss';

class VendorDocuments extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      documents: [],
      productImages: [],
      isLoadingDocuments: false,
      isLoadingProductImages: false,
    }
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    this.loadDataDocuments();
    this.loadDataProductImages();
  }
  loadDataProductImages = async () => {
    this.setState({
      isLoadingProductImages: true,
    });
    const { data: response } = await vendorApi.getProductImages({
      id: this.props.vendorId,
      params: {},
    });
    if (response?.result) {
      this.setState({
        isLoadingProductImages: false,
        productImages: response.data,
      });
    }
  }
  loadDataDocuments = async () => {
    this.setState({
      isLoadingDocuments: true,
    });
    const { data: response } = await vendorApi.getDocuments({
      id: this.props.vendorId,
      params: {},
    });
    if (response?.result) {
      this.setState({
        isLoadingDocuments: false,
        documents: response.data,
      });
    }
  }

  render() {
    const { documents, productImages, isLoadingDocuments, isLoadingProductImages } = this.state;
    const isLoading = isLoadingDocuments || isLoadingProductImages;
    return (
      <div className={`${classes.Docs} ${isLoading && "OverlayLoading"}`}>
        { (documents?.length && documents.length > 0) || (productImages?.length && productImages.length > 0) ? <>
          { (documents?.length && documents.length > 0 ) ?
            documents.map(doc => (
              <div key={`ListDocument-${doc.id}`} className={classes.DocItem}>
                <div className={classes.Info}>
                  <a className={classes.DocLink} href={doc.url} target="_blank" rel="noopener noreferrer">{ doc.filename }</a>
                </div>
                <div className={classes.Info}>
                  <label>Người tạo</label>
                  { doc.getcare_user?.name }
                </div>
                <div className={classes.Info}>
                  <label>Ngày tạo</label>
                  { validDate(doc?.created_at) ? format(validDate(doc?.created_at), dateFormat) : '' }
                </div>
              </div>
            )) : ""
          }
          { (productImages?.length && productImages.length > 0) ?
            productImages.map( (productImage,productImageIndex) => (
              <div key={productImageIndex} style={{ marginBottom: ".75rem"}}>
                <h3 className={classes.ProductImageTitle}>{productImage.product_code} - {productImage.product_name}</h3>
                {productImage.getcare_vendor_product_image.map((gvpi) => (
                  <div key={`ListProductImage-${gvpi.id}`} className={classes.DocItem}>
                    <div className={classes.Info}>
                      <a href={gvpi.url} target="_blank">
                        <div className={classes.ListProductImageItem}>
                          <img src={gvpi.url}/>
                        </div>
                      </a>
                    </div>
                    <div className={classes.Info}>
                      <label>Người tạo</label>
                      { gvpi.getcare_user?.name }
                    </div>
                    <div className={classes.Info}>
                      <label>Ngày tạo</label>
                      { validDate(gvpi?.created_at) ? format(validDate(gvpi?.created_at), dateFormat) : '' }
                    </div>
                  </div>
                ))}
              </div>
            )) : ""
          } 
        </> : 
        (<p className="NoData">Không có data</p>) }
      </div>
    );
  }
}

VendorDocuments.propTypes = {
  vendorId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

VendorDocuments.defaultProps = {
};

export default VendorDocuments;
