export const GET_PROVINCE_LIST = 'oms/location/GET_PROVINCE_LIST';
export const GET_DISTRICT_LIST = 'oms/location/GET_DISTRICT_LIST';
export const GET_WARD_LIST = 'oms/location/GET_WARD_LIST';
export const GET_COUNTRY_LIST = 'sso/location/GET_COUNTRY_LIST';

// mutation
export const SAVE_COUNTRY_LIST = 'sso/location/SAVE_COUNTRY_LIST';
export const SAVE_PROVINCE_LIST = 'oms/location/SAVE_PROVINCE_LIST';
export const SAVE_DISTRICT_LIST = 'oms/location/SAVE_DISTRICT_LIST';
export const SAVE_WARD_LIST = 'oms/location/SAVE_WARD_LIST';
