import axiosAuth from 'utils/axios/axiosAuth';

const deliveryApi = {
  getDeliveries: async () => {
    const response = await axiosAuth.get('getcare_logistic_provider/');
    return response?.data;
  },
}

export default deliveryApi;
