import axiosAuth from 'utils/axios/axiosAuth';

const shippingCouponApi = {
  getList: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (![null, undefined, ''].includes(params[key])) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_shipping_coupon/`, {
      params: queryParams,
    });
  },
  get: (id) => {
    return axiosAuth.get(`getcare_shipping_coupon/${id}`);
  },

  create: ({ params }) => {
    return axiosAuth.post(`getcare_shipping_coupon`, params);
  },
  update: ({ params }) => {
    return axiosAuth.put(`getcare_shipping_coupon/${params.id}`, params);
  },

  checkShippingCoupon: ({ params }) => {
    return axiosAuth.post(`getcare_shipping_coupon_check`, params);
  },
  flush_cache: () => {
    return axiosAuth.post(`flush_cache/shipping_coupon`);
  }
};

export default shippingCouponApi;
