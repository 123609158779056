import keyAccountApi from 'utils/api/admin/keyAccountApi';
import { put } from 'redux-saga/effects';
import { keyAccountActions } from 'redux/actions';
import { toast } from 'react-toastify';
import { genID } from 'utils/helper';

export function* loadKeyAccountList(payload) {
  const { params } = payload.payload;
  try {
    yield put(keyAccountActions.saveLoading(true));

    const { data: response } = yield keyAccountApi.getList({ params });

    yield put(keyAccountActions.saveLoading(false));
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(keyAccountActions.saveKeyAccountList(response.data ? [...response.data] : []));
    yield put(keyAccountActions.saveKeyAccountListTotal(response.total_records));
  } catch (err) {
    console.log(err);
  }
}
export function* loadKeyAccount(payload) {
  try {
    yield put(keyAccountActions.saveLoading(true));
    yield put(keyAccountActions.resetKeyAccount());

    const { data: response } = yield keyAccountApi.get(payload.id);

    yield put(keyAccountActions.saveLoading(false));

    if (!response?.result || !response.data) {
      toast.error(response.message);
      return;
    }

    const oriDetails = {
      ...response.data,
      getcare_key_account_product_items: null,
      getcare_key_account_sales_items: null,
      getcare_key_account_customer_items: null,
    }
    yield put(keyAccountActions.saveOriKeyAccountDetails(oriDetails));
    yield put(keyAccountActions.saveKeyAccountDetails(oriDetails));

    const oriSalesUsers = response.data.getcare_key_account_sales_items?.map(item => ({...item, idStr: genID()})) || [];
    yield put(keyAccountActions.saveOriKeyAccountSalesUsers([...oriSalesUsers]));
    yield put(keyAccountActions.saveKeyAccountSalesUsers([...oriSalesUsers]));

    const oriCustomers = response.data.getcare_key_account_customer_items?.map(item => ({...item, idStr: genID()})) || [];
    yield put(keyAccountActions.saveOriKeyAccountCustomers([...oriCustomers]));
    yield put(keyAccountActions.saveKeyAccountCustomers([...oriCustomers]));

  } catch (err) {
    console.log(err);
  }
}
export function* loadKeyAccountProductsByVendor(payload) {
  const { params } = payload.payload;
  try {
    yield put(keyAccountActions.saveActionLoading(true));

    const { data: response } = yield keyAccountApi.getKeyAccountProductsByVendor({ params });

    yield put(keyAccountActions.saveActionLoading(false));

    if (!response?.result || !response.data) {
      toast.error(response.message);
      return;
    }

    const oriProducts = response.data.map(item => ({...item, idStr: genID()})) || [];
    yield put(keyAccountActions.saveOriKeyAccountProducts([...oriProducts]));
    yield put(keyAccountActions.saveKeyAccountProducts([...oriProducts]));

  } catch (err) {
    console.log(err);
  }
}
export function* loadKeyAccountHistory(payload) {
  const { params } = payload.payload;
  try {
    yield put(keyAccountActions.saveActionLoading(true));

    const { data: response } = yield keyAccountApi.getKeyAccountHistory({ params });

    yield put(keyAccountActions.saveActionLoading(false));

    if (!response?.result || !response.data) {
      toast.error(response.message);
      return;
    }

    yield put(keyAccountActions.saveKeyAccountHistory(response.data));

  } catch (err) {
    console.log(err);
  }
}

export function* postKeyAccount(payload) {
  const { params } = payload.payload;
  try {
    yield put(keyAccountActions.saveLoading(true));

    const { data: response } = yield keyAccountApi.update({ params });

    yield put(keyAccountActions.saveLoading(false));
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    toast.success(`Lưu đặc quyền bán thành công.`);

    const oriDetails = {
      ...response.data,
      getcare_key_account_product_items: null,
      getcare_key_account_sales_items: null,
      getcare_key_account_customer_items: null,
    }
    yield put(keyAccountActions.saveOriKeyAccountDetails(oriDetails));
    yield put(keyAccountActions.saveKeyAccountDetails(oriDetails));

    const oriSalesUsers = response.data.getcare_key_account_sales_items?.map(item => ({...item, idStr: genID()})) || [];
    yield put(keyAccountActions.saveOriKeyAccountSalesUsers([...oriSalesUsers]));
    yield put(keyAccountActions.saveKeyAccountSalesUsers([...oriSalesUsers]));

    const oriCustomers = response.data.getcare_key_account_customer_items?.map(item => ({...item, idStr: genID()})) || [];
    yield put(keyAccountActions.saveOriKeyAccountCustomers([...oriCustomers]));
    yield put(keyAccountActions.saveKeyAccountCustomers([...oriCustomers]));

  } catch (err) {
    console.log(err);
  }
}
