import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectPolicyPriceChannels,
  makeSelectTempPolicyPriceChannels,
  makeSelectTempPolicyPriceCustomerTypes,
  makeSelectPolicyPriceCustomerTypes,
} from 'redux/selectors';
import {
  saveTempPolicyPriceChannelItem,
  removeTempPolicyPriceChannelItems,
  saveTempPolicyPriceCustomerTypes,
} from 'redux/actions/admin/policyPriceActions';
import { getDisplayFields, genID } from 'utils/helper';
import {
  policyPriceChannelsDisplayFields,
  policyPriceChannelsParamsMap,
} from 'utils/constanst/adminPolicyPriceContants';
import { isEqual, sortBy } from 'lodash';
import { customerTypesMap } from 'utils/constanst/adminGroupConstants';

import PolicyPriceChannel from './PolicyPriceChannel/PolicyPriceChannel';
import Checkbox from '@material-ui/core/Checkbox';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import gridClasses from './PolicyPriceChannelsGrid.module.scss';
import classes from './PolicyPriceChannels.module.scss';

class PolicyPriceChannels extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedItems: [],
    };
  }
  componentDidUpdate(prevProps) {
    const { tempPolicyPriceChannels } = this.props;
    const { selectedItems } = this.state;
    if (
      !isEqual(
        sortBy(tempPolicyPriceChannels),
        sortBy(prevProps.tempPolicyPriceChannels)
      ) &&
      selectedItems.length > 0
    ) {
      const remainItems = selectedItems.filter((p) =>
        tempPolicyPriceChannels.some((item) => {
          const comparedField = item.idStr ? `idStr` : `id`;
          return item[comparedField] === p[comparedField];
        })
      );
      this.setState({
        selectedItems: remainItems,
      });
    }
  }
  _getChangedItem = (price) => {
    const { policyPriceChannels } = this.props;
    const comparedField = price.idStr ? 'idStr' : 'id';
    const theItem = policyPriceChannels.find(
      (item) => item[comparedField] === price[comparedField]
    );
    if (!theItem) return null;
    return !isEqual(theItem.getcare_sales_channel, price.getcare_sales_channel) ? {...theItem} : null;
  };
  _getHighLightList = () => {
    const { tempPolicyPriceChannels, policyPriceChannels } = this.props;
    if (!tempPolicyPriceChannels.length || !policyPriceChannels.length) {
      return [...tempPolicyPriceChannels];
    }
    return tempPolicyPriceChannels.map((price) => ({
      ...price,
      changedItem: this._getChangedItem(price),
    }));
  };
  _isCustomerTypeDisabled = (typeId) => {
    const { policyPriceChannels, policyPriceCustomerTypes } = this.props;
    const isSaved = policyPriceCustomerTypes.some(
      (item) => item.getcare_customer_type?.id === typeId
    );
    if (isSaved && policyPriceChannels.length > 0) return true;

    const { tempPolicyPriceCustomerTypes } = this.props;
    return (
      tempPolicyPriceCustomerTypes.some(
        (item) => item.getcare_customer_type?.id === typeId
      ) && tempPolicyPriceCustomerTypes.length === 1
    );
  };
  _isCustomerTypeChecked = (typeId) => {
    return this.props.tempPolicyPriceCustomerTypes.some(
      (item) => item.getcare_customer_type?.id === typeId
    );
  };
  _getSelectedCustomerTypeIds = () => {
    return this.props.tempPolicyPriceCustomerTypes.map(
      (item) => item.getcare_customer_type?.id
    );
  };
  isAllItemSelected = () => {
    const { tempPolicyPriceChannels } = this.props;
    const { selectedItems } = this.state;
    return (
      selectedItems.length > 0 &&
      selectedItems.length >= tempPolicyPriceChannels.length
    );
  };
  isItemSelected = (item) => {
    return (
      this.state.selectedItems.findIndex((p) => {
        const comparedField = p.idStr ? 'idStr' : 'id';
        return p[comparedField] === item[comparedField];
      }) > -1
    );
  };
  handleAllSelectedToggle = (e) => {
    this.setState({
      selectedItems: e.target.checked
        ? [...this.props.tempPolicyPriceChannels]
        : [],
    });
  };
  handleItemSelectedToggle = ({ item, isSelected }) => {
    const remainItems = this.state.selectedItems.filter((p) => {
      const comparedField = p.idStr ? 'idStr' : 'id';
      return p[comparedField] !== item[comparedField];
    });
    this.setState({
      selectedItems: isSelected ? [...remainItems, { ...item }] : remainItems,
    });
  };
  handleCreateNewRow = () => {
    this.props.saveTempPolicyPriceChannelItem({
      idStr: genID(),
      id: 0,
      count: '',
      getcare_sales_channel: null,
    });
  };
  handleSavePolicyPriceChannel = (params) => {
    this.props.saveTempPolicyPriceChannelItem({
      ...params,
    });
  };
  handleRemovePolicyPriceChannel = (params) => {
    this.props.removeTempPolicyPriceChannelItems([{ ...params }]);
  };
  handleRemovePolicyPriceChannels = () => {
    this.props.removeTempPolicyPriceChannelItems([...this.state.selectedItems]);
  };
  handleCustomerTypeChange = (e) => {
    const value = Number(e.target.value);
    const { tempPolicyPriceCustomerTypes } = this.props;
    let newArr = [];
    if (e.target.checked) {
      newArr = [
        ...tempPolicyPriceCustomerTypes,
        {
          getcare_customer_type: { id: value, name: customerTypesMap[value] },
        },
      ];
    }
    if (!e.target.checked) {
      newArr = tempPolicyPriceCustomerTypes.filter(
        (s) => s.getcare_customer_type.id !== value
      );
    }
    this.props.saveTempPolicyPriceCustomerTypes(newArr);
  };

  render() {
    const { tempPolicyPriceChannels, customerTypes, readOnly } = this.props;
    const displayFields = getDisplayFields(
      policyPriceChannelsParamsMap,
      policyPriceChannelsDisplayFields
    );
    const list = this._getHighLightList();
    const hasSelected = this.state.selectedItems.length > 0;
    const selectedCustomerTypeIds = this._getSelectedCustomerTypeIds();

    return (
      <div className={`${classes.Wrap}`}>
        <div className={classes.Toolbar}>
          <div className={classes.CustomerTypeWrap}>
            <label>Áp dụng cho </label>
            <FormGroup className={classes.FormGroup}>
              {customerTypes &&
                customerTypes.map((type) => (
                  <FormControlLabel
                    key={`customer-type-${type.id}`}
                    control={
                      <Checkbox
                        size="small"
                        checked={this._isCustomerTypeChecked(type.id)}
                        onChange={this.handleCustomerTypeChange}
                        value={type.id}
                        name="getcare_customer_type_ids"
                      />
                    }
                    label={type.name}
                    disabled={this._isCustomerTypeDisabled(type.id) || readOnly}
                  />
                ))}
            </FormGroup>
          </div>
          <Button
            className={`${classes.MultiActionBtn}`}
            disabled={!hasSelected || readOnly}
            color="secondary"
            variant="contained"
            size="small"
            onClick={this.handleRemovePolicyPriceChannels}
          >
            Xoá
          </Button>
        </div>
        <div className={`${classes.List}`}>
          <div className={`${classes.Body}`}>
            <div className={`${gridClasses.Row} ${classes.Header}`}>
              <div className={gridClasses.Col}>
                <Checkbox
                  disabled={readOnly}
                  className={classes.Checkbox}
                  checked={this.isAllItemSelected()}
                  onChange={this.handleAllSelectedToggle}
                />
              </div>
              {displayFields.map((item) => (
                <ListHeaderCol
                  key={item.name}
                  className={gridClasses.Col}
                  {...item}
                />
              ))}
              <div
                className={`${gridClasses.Col} ${classes.HeaderCol} ${classes.ActionsCol}`}
              >
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  disabled={readOnly}
                  onClick={this.handleCreateNewRow}
                >
                  Thêm dòng
                </Button>
              </div>
            </div>
          
            {list.length ? (
              list.map((item) => (
                <PolicyPriceChannel
                  key={`price-Channel-${item.id || item.idStr}`}
                  {...item}
                  readOnly={readOnly}
                  isSelected={this.isItemSelected(item)}
                  selectedCustomerTypeIds={[...selectedCustomerTypeIds]}
                  currentList={[...tempPolicyPriceChannels]}
                  onItemSelectedToggle={this.handleItemSelectedToggle}
                  savePolicyPriceChannel={this.handleSavePolicyPriceChannel}
                  removePolicyPriceChannel={this.handleRemovePolicyPriceChannel}
                />
              ))
            ) : (
              <p className="NoData">Không có data</p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

PolicyPriceChannels.propTypes = {
  policyPriceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  readOnly: PropTypes.bool,
  customerTypes: PropTypes.array,
};

PolicyPriceChannels.defaultProps = {
  readOnly: false,
  customerTypes: [],
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveTempPolicyPriceChannelItem: (payload) => dispatch(saveTempPolicyPriceChannelItem(payload)),
    removeTempPolicyPriceChannelItems: (payload) => dispatch(removeTempPolicyPriceChannelItems(payload)),
    saveTempPolicyPriceCustomerTypes: (payload) => dispatch(saveTempPolicyPriceCustomerTypes(payload)),
  };
};
const mapStateToProps = createStructuredSelector({
  policyPriceChannels: makeSelectPolicyPriceChannels(),
  tempPolicyPriceChannels: makeSelectTempPolicyPriceChannels(),
  tempPolicyPriceCustomerTypes: makeSelectTempPolicyPriceCustomerTypes(),
  policyPriceCustomerTypes: makeSelectPolicyPriceCustomerTypes(),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(PolicyPriceChannels);
