import React, { PureComponent } from 'react';

import {
  MODE_ALL_OPTIONS,
  MODE_SOME_OPTIONS,
  modeOptionsMap,
} from 'utils/constanst/common';
import { currencyFormatVN } from 'utils/helper';

import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';

import gridClasses from '../SalesUserListGrid.module.scss';
import classes from './SalesUserListItem.module.scss';

class SalesUserListItem extends PureComponent {
  getListStringDisplayed = ({ listMode, list }) => {
    if (listMode === MODE_ALL_OPTIONS) return modeOptionsMap[MODE_ALL_OPTIONS];
    const listCount = list?.length;
    if (!listCount) return `-`;
    if (listCount > 1) return parseFloat(listCount);
    return list[0].name;
  }
  getActiveProducts = () => {
    const { getcare_key_account_product_items } = this.props;
    return getcare_key_account_product_items.filter(item => item.active);
  }
  getTooltipContent = ({ list, itemObjectName, isKeyAccount }) => {
    return list.map(item => {
      return (<p
        key={item.id}
        className={`${classes.TooltipListItem}`}
      >
        { isKeyAccount
          ? <Link to={`/keyaccount/${item.id}`} className={classes.Link}>
              { itemObjectName ? item[itemObjectName]?.name : item.name }
            </Link>
          : itemObjectName ? item[itemObjectName]?.name : item.name
        }

      </p>)
    });
  }

  render() {
    const {
      code,
      name,
      getcare_team_items,
      role_name,
      user_type,
      phone,
      email,
      getcare_key_account_items,
      amount,
    } = this.props;

    const teamsDisplayed = this.getListStringDisplayed({
      listMode: MODE_SOME_OPTIONS,
      list: getcare_team_items,
    });

    const keyAccountsDisplayed = this.getListStringDisplayed({
      listMode: MODE_SOME_OPTIONS,
      list: getcare_key_account_items,
    });

    return <div className={`${classes.Item} ${gridClasses.Row}`}>
      <div className={`${gridClasses.Col}`}>{ code }</div>
      <div className={`${gridClasses.Col}`}>{ name }</div>
      <div className={`${gridClasses.Col}`}>
        { Number.isInteger(teamsDisplayed)
          ? <Tooltip
              title={<>
                { this.getTooltipContent({
                    list: getcare_team_items,
                    itemObjectName: '',
                    isKeyAccount: false,
                  })
                }
              </>}
              arrow
              placement="bottom"
              interactive
            >
              <span className="Badge">{ teamsDisplayed }</span>
            </Tooltip>
          : teamsDisplayed
        }
      </div>
      <div className={`${gridClasses.Col}`}>{ role_name }</div>
      <div className={`${gridClasses.Col}`}>{ phone }</div>
      <div className={`${gridClasses.Col}`}>{ user_type }</div>
      <div className={`${gridClasses.Col}`}>{ email }</div>
      <div className={`${gridClasses.Col} TextCenter`}>
        { Number.isInteger(keyAccountsDisplayed)
          ? <Tooltip
              title={<>
                { this.getTooltipContent({
                    list: getcare_key_account_items,
                    itemObjectName: '',
                    isKeyAccount: true,
                  })
                }
              </>}
              arrow
              placement="bottom"
              interactive
              leaveDelay={10000}
            >
              <span className="Badge">{ keyAccountsDisplayed }</span>
            </Tooltip>
          : keyAccountsDisplayed
        }
      </div>
      <div className={`${gridClasses.Col} TextRight`}>{ !['', null, undefined].includes(amount) ? currencyFormatVN(amount) : `-` }</div>
    </div>;
  }
}

export default SalesUserListItem;
