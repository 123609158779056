import { takeLatest } from 'redux-saga/effects';

import { CHECK_AUTH, SIGN_OUT } from 'redux/constants/authConstants';
import { checkAuthentication, signMeOut } from 'saga/authSaga';

import {
  GET_COUNTRY_LIST,
  GET_PROVINCE_LIST,
  GET_DISTRICT_LIST,
  GET_WARD_LIST,
} from 'redux/constants/locationConstants';
import {
  loadCountryList,
  loadProvinceList,
  loadDistrictList,
  loadWardList,
} from 'saga/locationSaga';

import {
  GET_PRODUCT_LIST,
  DEACTIVE_PRODUCTS,
  GET_PRODUCT_DETAILS,
  GET_MEDICINE_BY_PHARMACY_ID,
  GET_OTHERS_BY_PHARMACY_ID,
  GET_AWAITING_PRODUCTS_BY_PHARMACY_ID,
  GET_PRODUCT_BRANDS,
  GET_PRODUCT_INDUSTRIES,
  UPDATE_AWAITING_PRODUCT,
  EXPORT_PRODUCT_LIST_TEMPLATE,
} from 'redux/constants/admin/productConstants';
import {
  loadProductDetails,
  loadProductList,
  deactiveProducts,
  loadMedicines,
  loadOtherProducts,
  loadAwaitingProducts,
  loadBrands,
  loadIndustries,
  putAwaitingProduct,
  exportProductListTemplate,
} from 'saga/admin/productSaga';

import {
  GET_INGREDIENT_LIST,
  GET_QUEUE_INGREDIENT_LIST,
  APPROVE_QUEUE_INGREDIENT_ITEM,
  DELETE_QUEUE_INGREDIENT_LIST,
  UPDATE_QUEUE_INGREDIENT_ITEM,
} from 'redux/constants/admin/ingredientConstants';
import {
  loadIngredientList,
  loadQueueIngredientList,
  approveQueueIngredientItem,
  deleteQueueIngredientItem,
  updateQueueIngredientItem,
} from 'saga/admin/ingredientSaga';

import {
  GET_UOM_LIST,
  GET_UOM_BASE_LIST,
  GET_PRODUCT_UNIT_LIST,
} from 'redux/constants/admin/uomConstants';
import {
  loadUomList,
  loadUomBaseList,
  loadProductUnits,
} from 'saga/admin/uomSaga';

import {
  GET_VENDOR_LIST,
  GET_VENDOR_DETAILS,
  UPDATE_VENDOR,
  EXPORT_VENDOR_LIST_TEMPLATE,
} from 'redux/constants/admin/vendorConstants';
import {
  exportVendorListTemplate,
  loadVendorDetails,
  loadVendorList,
  putVendor,
} from 'saga/admin/vendorSaga';

import {
  GET_STORE_LIST,
  GET_STORE_DETAILS,
  UPDATE_STORE,
  CREATE_STORE,
  EXPORT_STORE_LIST_TEMPLATE,
  GET_STORE_DELIVERY_ADDRESS_LIST,
  UPDATE_STORE_DELIVERY_ADDRESS,
  DELETE_STORE_DELIVERY_ADDRESS,
} from 'redux/constants/admin/storeConstants';
import {
  exportStoreListTemplate,
  loadStoreDetails,
  loadStoreList,
  putStore,
  postStore,
  loadStoreDeliveryAddressList,
  updateStoreDeliveryAddress,
  deleteStoreDeliveryAddress,
} from 'saga/admin/storeSaga';

import {
  GET_SALES_CHANNEL_LIST,
  GET_SALES_CHANNEL,
  CREATE_SALES_CHANNEL,
  UPDATE_SALES_CHANNEL,
  GET_SALES_CHANNEL_HISTORY,
} from 'redux/constants/admin/salesChannelConstants';
import {
  loadSalesChannelList,
  loadSalesChannel,
  putNewSalesChannel,
  editSalesChannel,
  loadSalesChannelHistory,
} from 'saga/admin/salesChannelSaga';

import {
  GET_SALES_TEAM_LIST,
  GET_SALES_TEAM,
  CREATE_SALES_TEAM,
  UPDATE_SALES_TEAM,
  GET_SALES_ROLES,
} from 'redux/constants/admin/salesTeamConstants';
import {
  loadSalesTeamList,
  loadSalesTeam,
  putNewSalesTeam,
  editSalesTeam,
  loadSalesRoles,
} from 'saga/admin/salesTeamSaga';

import {
  GET_GROUP_LIST,
  GET_GROUP,
  UPDATE_GROUP,
  UPDATE_GROUP_PRODUCT,
  DELETE_GROUP_PRODUCTS,
  EXPORT_GROUP_PRODUCTS,
  UPDATE_GROUP_VENDOR,
  DELETE_GROUP_VENDORS,
  EXPORT_GROUP_VENDORS,
  GET_GROUP_CUSTOMERS,
  UPDATE_GROUP_CUSTOMER,
  DELETE_GROUP_CUSTOMERS,
  EXPORT_GROUP_CUSTOMERS,
  GET_GROUP_CUSTOMER_MAPPINGS,
  UPDATE_GROUP_CUSTOMER_MAPPING,
  DELETE_GROUP_CUSTOMER_MAPPINGS,
  GET_GROUP_CUSTOMERS_BY_MAPPINGS,
  GET_CUSTOMERS_TYPES,
} from 'redux/constants/admin/groupConstants';
import {
  loadGroupList,
  loadGroup,
  putGroup,
  putGroupProduct,
  deleteGroupProducts,
  exportGroupProducts,
  putGroupVendor,
  deleteGroupVendors,
  exportGroupVendors,
  loadGroupCustomers,
  putGroupCustomer,
  deleteGroupCustomers,
  exportGroupCustomers,
  loadGroupCustomerMappings,
  loadGroupCustomersByMappings,
  putGroupCustomerMapping,
  deleteGroupCustomerMappings,
  loadCustomerTypes,
} from 'saga/admin/groupSaga';

import {
  GET_VENDOR_PRICE_LIST,
  UPDATE_VENDOR_PRICE_LIST,
} from 'redux/constants/admin/vendorPriceConstants';
import {
  loadVendorPriceList,
  putVendorPriceList,
} from 'saga/admin/vendorPriceSaga';

import {
  GET_POLICY_PRICE_LIST,
  GET_POLICY_PRICE,
  GET_PRICE_PIORITIES,
  GET_PRICE_UNITS,
  UPDATE_POLICY_PRICE,
  GET_POLICY_PRICE_HISTORY,
  EXPORT_POLICY_PRICE_TEMPLATE,
  GET_POLICY_PRICE_COUNTS,
} from 'redux/constants/admin/policyPriceConstants';
import {
  loadPolicyPriceList,
  loadPricePriorities,
  loadPriceUnits,
  loadPolicyPrice,
  postPolicyPrice,
  loadPolicyPriceHistory,
  exportPolicyPriceTemplate,
  loadPolicyPriceCounts,
} from 'saga/admin/policyPriceSaga';

import {
  GET_VENDOR_PRODUCT_LIST,
  UPDATE_VENDOR_PRODUCT_LIST,
  EXPORT_VENDOR_PRODUCT_LIST_TEMPLATE,
  APPROVAL_VENDOR_PRODUCT_ITEMS,
  UNMAP_VENDOR_PRODUCT_ITEMS,
  EXPORT_VENDOR_PRODUCT_LIST,
} from 'redux/constants/admin/vendorProductConstants';
import {
  loadVendorProductList,
  approvalVendorProductItems,
  unmapVendorProductItems,
  postVendorProductList,
  exportVendorProductListTemplate,
  exportVendorProductList,
} from 'saga/admin/vendorProductSaga';

import {
  GET_PRODUCT_BONUS_LIST,
  UPDATE_PRODUCT_BONUS_ITEM,
  EXPORT_PRODUCT_BONUS_LIST_TEMPLATE,
} from 'redux/constants/admin/productBonusConstants';
import {
  loadProductBonusList,
  postProductBonus,
  exportProductBonusListTemplate,
} from 'saga/admin/productBonusSaga';

import {
  GET_SHIPPING_COUPON_LIST,
  GET_SHIPPING_COUPON,
  UPDATE_SHIPPING_COUPON,
} from 'redux/constants/admin/shippingCouponConstants';
import {
  loadShippingCouponList,
  loadShippingCoupon,
  postShippingCoupon,
} from 'saga/admin/shippingCouponSaga';

import { CHECK_SHIPPING_COUPON } from 'redux/constants/admin/testShippingCouponConstants';
import { testShippingCoupon } from 'saga/admin/testShippingCouponSaga';

import {
  GET_USER_LIST,
  GET_USER,
  UPDATE_USER,
  GET_DEPARTMENT_LIST,
  GET_ROLE_LIST,
  GET_SALES_USER_LIST,
} from 'redux/constants/admin/userConstants';
import {
  loadUserList,
  loadUser,
  postUser,
  loadDepartmentList,
  loadRoleList,
  loadSalesUserList,
} from 'saga/admin/userSaga';

import {
  GET_KEY_ACCOUNT_LIST,
  GET_KEY_ACCOUNT,
  GET_KEY_ACCOUNT_PRODUCTS,
  GET_KEY_ACCOUNT_HISTORY,
  UPDATE_KEY_ACCOUNT,
} from 'redux/constants/admin/keyAccountConstants';
import {
  loadKeyAccountList,
  loadKeyAccount,
  loadKeyAccountProductsByVendor,
  loadKeyAccountHistory,
  postKeyAccount,
} from 'saga/admin/keyAccountSaga';

import { GET_KEY_ACCOUNT_LOCK_LIST, DELETE_KEY_ACCOUNT_LOCK_LIST } from 'redux/constants/admin/keyAccountLockConstants';
import { loadKeyAccountLockList,deleteKeyAccountLockList } from 'saga/admin/keyAccountLockSaga';

import {
  GET_COORDINATER_LIST,
  UPDATE_COORDINATER_ITEM,
  DELETE_COORDINATER_ITEMS,
} from 'redux/constants/admin/coordinaterConstants';
import {
  loadCoordinaterList,
  postCoordinater,
  deleteCoordinaterItems,
} from 'saga/admin/coordinaterSaga';

import {
  GET_PRODUCT_CATEGORY_LIST
} from 'redux/constants/admin/getcareCategoryConstants';
import {
  loadGetcareCategoryList,
} from 'saga/admin/getcareCategorySaga';

// Asset branding
import {
  GET_ASSET_BRANDING_LIST
} from 'redux/constants/admin/assetBrandingConstants';
import {
  loadAssetBrandingList
} from 'saga/admin/assetBrandingSaga';

// Asset Component
import {
  GET_ASSET_COMPONENT_LIST
} from 'redux/constants/admin/assetComponentConstants';
import {
  loadAssetComponentList
} from 'saga/admin/assetComponentSaga';

/**
 * Root saga manages watcher lifecycle
 */
export default function* defaultSaga() {
  // Watches for LOAD_REPOS actions and calls getRepos when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount

  // Asset Component
  yield takeLatest(GET_ASSET_COMPONENT_LIST, loadAssetComponentList);
  // Asset branding
  yield takeLatest(GET_ASSET_BRANDING_LIST, loadAssetBrandingList);

  // auth
  yield takeLatest(CHECK_AUTH, checkAuthentication);
  yield takeLatest(SIGN_OUT, signMeOut);

  // location
  yield takeLatest(GET_COUNTRY_LIST, loadCountryList);
  yield takeLatest(GET_PROVINCE_LIST, loadProvinceList);
  yield takeLatest(GET_DISTRICT_LIST, loadDistrictList);
  yield takeLatest(GET_WARD_LIST, loadWardList);

  // product
  yield takeLatest(GET_PRODUCT_DETAILS, loadProductDetails);
  yield takeLatest(GET_PRODUCT_LIST, loadProductList);
  yield takeLatest(DEACTIVE_PRODUCTS, deactiveProducts);
  yield takeLatest(GET_MEDICINE_BY_PHARMACY_ID, loadMedicines);
  yield takeLatest(GET_OTHERS_BY_PHARMACY_ID, loadOtherProducts);
  yield takeLatest(GET_AWAITING_PRODUCTS_BY_PHARMACY_ID, loadAwaitingProducts);
  yield takeLatest(UPDATE_AWAITING_PRODUCT, putAwaitingProduct);
  yield takeLatest(GET_PRODUCT_BRANDS, loadBrands);
  yield takeLatest(GET_PRODUCT_INDUSTRIES, loadIndustries);
  yield takeLatest(EXPORT_PRODUCT_LIST_TEMPLATE, exportProductListTemplate);

  // ingredient
  yield takeLatest(GET_INGREDIENT_LIST, loadIngredientList);
  yield takeLatest(GET_QUEUE_INGREDIENT_LIST, loadQueueIngredientList);
  yield takeLatest(APPROVE_QUEUE_INGREDIENT_ITEM, approveQueueIngredientItem);
  yield takeLatest(DELETE_QUEUE_INGREDIENT_LIST, deleteQueueIngredientItem);
  yield takeLatest(UPDATE_QUEUE_INGREDIENT_ITEM, updateQueueIngredientItem);

  // uom
  yield takeLatest(GET_UOM_LIST, loadUomList);
  yield takeLatest(GET_UOM_BASE_LIST, loadUomBaseList);
  yield takeLatest(GET_PRODUCT_UNIT_LIST, loadProductUnits);

  // vendor
  yield takeLatest(EXPORT_VENDOR_LIST_TEMPLATE, exportVendorListTemplate);
  yield takeLatest(GET_VENDOR_DETAILS, loadVendorDetails);
  yield takeLatest(GET_VENDOR_LIST, loadVendorList);
  yield takeLatest(UPDATE_VENDOR, putVendor);

  // store
  yield takeLatest(EXPORT_STORE_LIST_TEMPLATE, exportStoreListTemplate);
  yield takeLatest(GET_STORE_DETAILS, loadStoreDetails);
  yield takeLatest(GET_STORE_LIST, loadStoreList);
  yield takeLatest(UPDATE_STORE, putStore);
  yield takeLatest(CREATE_STORE, postStore);
  yield takeLatest(GET_STORE_DELIVERY_ADDRESS_LIST, loadStoreDeliveryAddressList);
  yield takeLatest(UPDATE_STORE_DELIVERY_ADDRESS, updateStoreDeliveryAddress);
  yield takeLatest(DELETE_STORE_DELIVERY_ADDRESS, deleteStoreDeliveryAddress);

  // sales channel
  yield takeLatest(GET_SALES_CHANNEL_LIST, loadSalesChannelList);
  yield takeLatest(GET_SALES_CHANNEL, loadSalesChannel);
  yield takeLatest(CREATE_SALES_CHANNEL, putNewSalesChannel);
  yield takeLatest(UPDATE_SALES_CHANNEL, editSalesChannel);
  yield takeLatest(GET_SALES_CHANNEL_HISTORY, loadSalesChannelHistory);

  // sales team
  yield takeLatest(GET_SALES_TEAM_LIST, loadSalesTeamList);
  yield takeLatest(GET_SALES_TEAM, loadSalesTeam);
  yield takeLatest(CREATE_SALES_TEAM, putNewSalesTeam);
  yield takeLatest(UPDATE_SALES_TEAM, editSalesTeam);
  yield takeLatest(GET_SALES_ROLES, loadSalesRoles);

  // group
  yield takeLatest(GET_GROUP_LIST, loadGroupList);
  yield takeLatest(GET_GROUP, loadGroup);
  yield takeLatest(UPDATE_GROUP, putGroup);
  yield takeLatest(UPDATE_GROUP_PRODUCT, putGroupProduct);
  yield takeLatest(DELETE_GROUP_PRODUCTS, deleteGroupProducts);
  yield takeLatest(EXPORT_GROUP_PRODUCTS, exportGroupProducts);
  yield takeLatest(UPDATE_GROUP_VENDOR, putGroupVendor);
  yield takeLatest(DELETE_GROUP_VENDORS, deleteGroupVendors);
  yield takeLatest(EXPORT_GROUP_VENDORS, exportGroupVendors);
  yield takeLatest(GET_GROUP_CUSTOMERS, loadGroupCustomers);
  yield takeLatest(UPDATE_GROUP_CUSTOMER, putGroupCustomer);
  yield takeLatest(DELETE_GROUP_CUSTOMERS, deleteGroupCustomers);
  yield takeLatest(EXPORT_GROUP_CUSTOMERS, exportGroupCustomers);
  yield takeLatest(GET_GROUP_CUSTOMER_MAPPINGS, loadGroupCustomerMappings);
  yield takeLatest(
    GET_GROUP_CUSTOMERS_BY_MAPPINGS,
    loadGroupCustomersByMappings
  );
  yield takeLatest(UPDATE_GROUP_CUSTOMER_MAPPING, putGroupCustomerMapping);
  yield takeLatest(DELETE_GROUP_CUSTOMER_MAPPINGS, deleteGroupCustomerMappings);
  yield takeLatest(GET_CUSTOMERS_TYPES, loadCustomerTypes);

  // vendor price
  yield takeLatest(GET_VENDOR_PRICE_LIST, loadVendorPriceList);
  yield takeLatest(UPDATE_VENDOR_PRICE_LIST, putVendorPriceList);

  // policy price
  yield takeLatest(GET_PRICE_PIORITIES, loadPricePriorities);
  yield takeLatest(GET_PRICE_UNITS, loadPriceUnits);
  yield takeLatest(GET_POLICY_PRICE_LIST, loadPolicyPriceList);
  yield takeLatest(GET_POLICY_PRICE, loadPolicyPrice);
  yield takeLatest(UPDATE_POLICY_PRICE, postPolicyPrice);
  yield takeLatest(GET_POLICY_PRICE_HISTORY, loadPolicyPriceHistory);
  yield takeLatest(EXPORT_POLICY_PRICE_TEMPLATE, exportPolicyPriceTemplate);
  yield takeLatest(GET_POLICY_PRICE_COUNTS, loadPolicyPriceCounts);

  // vendor product
  yield takeLatest(GET_VENDOR_PRODUCT_LIST, loadVendorProductList);
  yield takeLatest(UPDATE_VENDOR_PRODUCT_LIST, postVendorProductList);
  yield takeLatest(APPROVAL_VENDOR_PRODUCT_ITEMS, approvalVendorProductItems);
  yield takeLatest(UNMAP_VENDOR_PRODUCT_ITEMS, unmapVendorProductItems);
  yield takeLatest(
    EXPORT_VENDOR_PRODUCT_LIST_TEMPLATE,
    exportVendorProductListTemplate
  );
  yield takeLatest(EXPORT_VENDOR_PRODUCT_LIST, exportVendorProductList);

  // product bonus
  yield takeLatest(GET_PRODUCT_BONUS_LIST, loadProductBonusList);
  yield takeLatest(UPDATE_PRODUCT_BONUS_ITEM, postProductBonus);
  yield takeLatest(EXPORT_PRODUCT_BONUS_LIST_TEMPLATE, exportProductBonusListTemplate);

  // shipping coupon
  yield takeLatest(GET_SHIPPING_COUPON_LIST, loadShippingCouponList);
  yield takeLatest(GET_SHIPPING_COUPON, loadShippingCoupon);
  yield takeLatest(UPDATE_SHIPPING_COUPON, postShippingCoupon);

  // test shipping coupon
  yield takeLatest(CHECK_SHIPPING_COUPON, testShippingCoupon);

  // user
  yield takeLatest(GET_DEPARTMENT_LIST, loadDepartmentList);
  yield takeLatest(GET_ROLE_LIST, loadRoleList);

  yield takeLatest(GET_USER_LIST, loadUserList);
  yield takeLatest(GET_SALES_USER_LIST, loadSalesUserList);
  yield takeLatest(GET_USER, loadUser);
  yield takeLatest(UPDATE_USER, postUser);

  // key account
  yield takeLatest(GET_KEY_ACCOUNT_LIST, loadKeyAccountList);
  yield takeLatest(GET_KEY_ACCOUNT, loadKeyAccount);
  yield takeLatest(GET_KEY_ACCOUNT_PRODUCTS, loadKeyAccountProductsByVendor);
  yield takeLatest(GET_KEY_ACCOUNT_HISTORY, loadKeyAccountHistory);
  yield takeLatest(UPDATE_KEY_ACCOUNT, postKeyAccount);

  // key account lock
  yield takeLatest(GET_KEY_ACCOUNT_LOCK_LIST, loadKeyAccountLockList);
  yield takeLatest(DELETE_KEY_ACCOUNT_LOCK_LIST, deleteKeyAccountLockList);


  // coordinater
  yield takeLatest(GET_COORDINATER_LIST, loadCoordinaterList);
  yield takeLatest(UPDATE_COORDINATER_ITEM, postCoordinater);
  yield takeLatest(DELETE_COORDINATER_ITEMS, deleteCoordinaterItems);

  // product category
  yield takeLatest(GET_PRODUCT_CATEGORY_LIST, loadGetcareCategoryList);
}
