import {
  SAVE_DEPARTMENT_LIST,
  SAVE_ROLE_LIST,

  SAVE_USER_LIST,
  SAVE_USER_LIST_ITEM,
  SAVE_USER_LIST_TOTAL,

  SAVE_LOADING,
  SAVE_ACTION_LOADING,

  RESET_USER,

  SAVE_ORI_USER_DETAILS,
  SAVE_USER_DETAILS,

  SAVE_ORI_USER_ROLES,
  SAVE_USER_ROLES,
  SAVE_USER_ROLE_ITEM,
  REMOVE_USER_ROLE_ITEMS,

  SAVE_SALES_USER_LIST,
  SAVE_SALES_USER_LIST_TOTAL,
} from 'redux/constants/admin/userConstants';

const initialState = {
  departmentList: [],
  roleList: [],

  userList: [],
  userListTotal: 0,

  loading: false,
  actionLoading: false,

  oriUserDetails: null,
  userDetails: null,
  oriUserRoles: [],
  userRoles: [],

  salesUserList: [],
  salesUserListTotal: 0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_DEPARTMENT_LIST: {
      const list = action.departmentList;
      return {
        ...state,
        departmentList: list ? [...list] : [],
      };
    }
    case SAVE_ROLE_LIST: {
      const list = action.roleList;
      return {
        ...state,
        roleList: list ? [...list] : [],
      };
    }

    case SAVE_LOADING: {
      return {
        ...state,
        loading: action.loading,
      };
    }
    case SAVE_ACTION_LOADING: {
      return {
        ...state,
        actionLoading: action.actionLoading,
      };
    }

    case SAVE_USER_LIST: {
      const list = action.userList;
      return {
        ...state,
        userList: list ? [...list] : [],
      };
    }
    case SAVE_USER_LIST_ITEM: {
      const list = [...state.userList];
      const comparedField = action.userListItem.idStr
        ? 'idStr'
        : 'id';
      const index = list.findIndex((p) => p[comparedField] === action.userListItem[comparedField]);
      if (index > -1) {
        const tempItem = { ...list[index] };
        list[index] = { ...tempItem, ...action.userListItem };
      } else {
        list.push({ ...action.userListItem });
      }
      return {
        ...state,
        userList: list,
      };
    }
    case SAVE_USER_LIST_TOTAL: {
      return {
        ...state,
        userListTotal: action.userListTotal,
      };
    }

    case RESET_USER: {
      return {
        ...state,
        oriUserDetails: null,
        userDetails: null,
        oriUserRoles: [],
        userRoles: [],
      };
    }

    case SAVE_ORI_USER_DETAILS: {
      return {
        ...state,
        oriUserDetails: action.oriUserDetails,
      };
    }
    case SAVE_USER_DETAILS: {
      return {
        ...state,
        userDetails: action.userDetails,
      };
    }

    case SAVE_ORI_USER_ROLES: {
      return {
        ...state,
        oriUserRoles: action.oriUserRoles,
      };
    }
    case SAVE_USER_ROLES: {
      return {
        ...state,
        userRoles: action.userRoles,
      };
    }
    case SAVE_USER_ROLE_ITEM: {
      const list = [...state.userRoles];
      const comparedField = action.userRoleItem.idStr
        ? 'idStr'
        : 'id';
      const index = list.findIndex((p) => p[comparedField] === action.userRoleItem[comparedField]);
      if (index > -1) {
        const tempItem = { ...list[index] };
        list[index] = { ...tempItem, ...action.userRoleItem };
      } else {
        list.push({ ...action.userRoleItem });
      }
      return {
        ...state,
        userRoles: list,
      };
    }
    case REMOVE_USER_ROLE_ITEMS: {
      const list = state.userRoles.filter(
        (p) =>
          !action.userRoleItems.some((item) => {
            const comparedField = item.idStr ? 'idStr' : 'id';
            return item[comparedField] === p[comparedField];
          })
      );
      return {
        ...state,
        userRoles: list,
      };
    }

    case SAVE_SALES_USER_LIST: {
      const list = action.salesUserList;
      return {
        ...state,
        salesUserList: list ? [...list] : [],
      };
    }
    case SAVE_SALES_USER_LIST_TOTAL: {
      return {
        ...state,
        salesUserListTotal: action.salesUserListTotal,
      };
    }

    default:
      return state;
  }
};

export default reducer;
