import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from "reselect";
import { makeSelectGroupCustomers, makeSelectGroupCustomersTotal } from 'redux/selectors';
import {
  updateGroupCustomer,
  saveGroupCustomer,
  removeGroupCustomers,
  deleteGroupCustomers,
  exportGroupCustomers,
  saveGroupCustomers,
  getGroupCustomers,
  saveGroupCustomersTotal,
} from 'redux/actions/admin/groupActions';
import { getDisplayFields, genID } from 'utils/helper';
import {
  groupCustomersDisplayFields,
  groupCustomersParamsMap,
} from 'utils/constanst/adminGroupConstants';
import groupApi from 'utils/api/admin/groupApi';
import { isEqual, sortBy } from 'lodash';

import GroupCustomersItem from './GroupCustomersItem/GroupCustomersItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';
import Button from '@material-ui/core/Button';
import FileImportDialog from '../FileImportDialog/FileImportDialog';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import ListPagination from 'components/ListPagination/ListPagination';

import gridClasses from './GroupCustomersGrid.module.scss';
import classes from './GroupCustomers.module.scss';

class GroupCustomers extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedItems: [],
      isDialogOpen: false,
      isImportLoading: false,
      importResult: null,
      isConfirmDialogOpen: false,
      listParams: { ...groupCustomersParamsMap },
    }
  }
  componentDidMount() {
    if (this.props.isEditing) {
      this.loadCustomers(this.state.listParams);
    }
  }
  componentDidUpdate(prevProps) {
    const { groupCustomers, groupId, isEditing } = this.props;
    const { selectedItems } = this.state;
    if (!isEqual(sortBy(groupCustomers), sortBy(prevProps.groupCustomers)) && selectedItems.length > 0) {
      const remainItems = selectedItems.filter(p => groupCustomers.some(item => {
        const comparedField = item.idStr ? `idStr` : `id`;
        return item[comparedField] === p[comparedField];
      }));
      this.setState({
        selectedItems: remainItems,
      });
    }
    if (isEditing && groupId && prevProps.groupId && groupId !== prevProps.groupId) {
      this.loadCustomers(this.state.listParams);
    }
  }
  loadCustomers = (listParams) => {
    this.props.getGroupCustomers({
      params: {
        ...listParams,
        getcare_erp_group_code_items: this.props.groupCodeItems,
        getcare_erp_group_id: this.props.groupId
      },
    });
  }
  isAllItemSelected = () => {
    const { groupCustomers } = this.props;
    const { selectedItems } = this.state;
    return selectedItems.length > 0 && selectedItems.length >= groupCustomers.length;
  }
  isItemSelected = (item) => {
    return this.state.selectedItems.findIndex(p => {
      const comparedField = p.idStr ? 'idStr' : 'id';
      return p[comparedField] === item[comparedField];
    }) > -1;
  }
  handleAllSelectedToggle = (e) => {
    this.setState({
      selectedItems: e.target.checked ? [...this.props.groupCustomers] : [],
    });
  }
  handleItemSelectedToggle = ({ item, isSelected }) => {
    const remainItems = this.state.selectedItems.filter(p => {
      const comparedField = p.idStr ? 'idStr' : 'id';
      return p[comparedField] !== item[comparedField];
    });
    this.setState({
      selectedItems: isSelected ? [...remainItems, {...item}] : remainItems,
    });
  }
  handleCreateNewRow = () => {
    this.props.saveGroupCustomer({
      idStr: genID(),
      id: 0,
      customer: null,
      phone: '',
      address: '',
    });
  }
  handleSaveGroupCustomer = ({ groupCustomerId, params }) => {
    this.props.updateGroupCustomer({
      groupCustomerId: groupCustomerId ? groupCustomerId : undefined,
      params: {
        ...params,
        code: groupCustomerId ? undefined : this.props.groupCodeItems,
        getcare_erp_group_id: this.props.groupId ? this.props.groupId : undefined,
      },
    });
  }
  handleRemoveGroupCustomer = (params) => {
    this.props.removeGroupCustomers([{...params}]);
  }
  handleDeleteGroupCustomers = () => {
    this.props.deleteGroupCustomers({
      id: isNaN(this.props.groupId) ? undefined : this.props.groupId,
      code: !this.props.groupCodeItems ? undefined : this.props.groupCodeItems,
      groupCustomers: [...this.state.selectedItems],
    });
    this.handleConfirmDialogClose();
  }
  handleExportGroupCustomers = () => {
    this.props.exportGroupCustomers({
      id: isNaN(this.props.groupId) ? undefined : this.props.groupId,
      code: !this.props.groupCodeItems ? undefined : this.props.groupCodeItems,
    });
  }
  handleDialogOpen = () => {
    this.setState({
      isDialogOpen: true,
    });
  }
  handleDialogClose = () => {
    this.setState({
      isDialogOpen: false,
      isImportLoading: false,
      importResult: null,
    });
  }
  handleConfirmDialogOpen = () => {
    this.setState({
      isConfirmDialogOpen: true,
    });
  }
  handleConfirmDialogClose = () => {
    this.setState({
      isConfirmDialogOpen: false,
    });
  }
  handleSubmitImport = async ({file, start_row}) => {
    this.setState({
      isImportLoading: true,
    });
    const { data: response } = await groupApi.importGroupCustomers({
      id: undefined,
      code: this.props.groupCodeItems,
      params: {
        file: file,
        start_row: start_row,
      }
    });
    this.setState({
      isImportLoading: false,
    });

    const isSuccess = response.result && response.data;
    this.setState({
      importResult: {
        isSuccess: isSuccess,
        message: isSuccess ? '' : response.message,
        totalRecords: response.rows_total,
        successRecords: isSuccess ? response.rows_affected : 0,
      },
    });
    if (isSuccess) {
      this.props.saveGroupCustomers([...response.data]);
      this.props.saveGroupCustomersTotal(response.rows_affected);
      this.setState({
        listParams: { ...this.state.listParams, page_size: 50, page: 1 },
      });
    }
  };
  handleFilterChange = (filters, forceResetPage: false) => {
    const paramsFromFilters = filters.reduce((memo, item) => {
      memo[item.name] = item.value;
      return memo;
    }, {});
    const newParams = forceResetPage ? {
      ...this.state.listParams,
      ...paramsFromFilters,
      page: 1,
    } : {
      ...this.state.listParams,
      ...paramsFromFilters,
    };
    this.setState({
      listParams: newParams,
    });
    this.loadCustomers(newParams);
  }

  render() {
    const { groupCustomers, isGroupInactive, groupCustomersTotal } = this.props;
    const { listParams } = this.state;
    const displayFields = getDisplayFields(
      groupCustomersParamsMap,
      groupCustomersDisplayFields
    );
    const hasSelected = this.state.selectedItems.length > 0;

    return (<div className={`${classes.Wrap}`}>
      <div className={classes.Toolbar}>
        <div>
          <Button
            disabled={isGroupInactive}
            variant="contained"
            color="primary"
            size="small"
            onClick={this.handleDialogOpen}
          >
            Import File
          </Button>
          <Button
            disabled={!this.props.groupCustomers.length}
            variant="contained"
            size="small"
            onClick={this.handleExportGroupCustomers}
          >
            Export danh sách ra .CSV
          </Button>
        </div>
        <Button
          className={`${classes.MultiActionBtn} ${hasSelected && classes.Active}`}
          color="secondary"
          variant="contained"
          size="small"
          onClick={this.handleConfirmDialogOpen}
        >
          Xoá
        </Button>
      </div>
      <div className={`${classes.List}`}>
        <div className={`${classes.Body}`}>
          <div className={`${gridClasses.Row} ${classes.Header}`}>
            <div className={gridClasses.Col}>
              <Checkbox
                disabled={isGroupInactive}
                className={classes.Checkbox}
                checked={this.isAllItemSelected()}
                onChange={this.handleAllSelectedToggle}
              />
            </div>
            { displayFields.map((item) => (
              <ListHeaderCol
                key={item.name}
                className={gridClasses.Col}
                {...item}
              />
            )) }
            <div className={`${gridClasses.Col} ${classes.HeaderCol} ${classes.ActionsCol}`}>
              <Button
                disabled={isGroupInactive}
                color="primary"
                variant="contained"
                size="small"
                onClick={this.handleCreateNewRow}
              >
                Thêm dòng
              </Button>
            </div>
          </div>
        
          { groupCustomers.length
            ? groupCustomers.map(item => (
              <GroupCustomersItem
                key={`customer-${item.id}-${item.idStr}`}
                {...item}
                isGroupInactive={isGroupInactive}
                isSelected={this.isItemSelected(item)}
                currentList={[...groupCustomers]}
                onItemSelectedToggle={this.handleItemSelectedToggle}
                saveGroupCustomer={this.handleSaveGroupCustomer}
                removeGroupCustomer={this.handleRemoveGroupCustomer}
              />))
            : (<p className="NoData">Không có khách hàng nào</p>)
          }
        </div>
      </div>
      <div className={classes.Footer}>
        <ListPagination
          page={listParams.page}
          pageSize={listParams.page_size}
          total={groupCustomersTotal}
          listName="khách hàng"
          onFilterChange={this.handleFilterChange}
        />
      </div>
      { this.state.isDialogOpen
        &&  <FileImportDialog
            title="Import danh sách khách hàng"
            isOpen={this.state.isDialogOpen}
            onClose={this.handleDialogClose}
            onSubmitImport={this.handleSubmitImport}
            isLoading={this.state.isImportLoading}
            importResult={this.state.importResult}
          />
      }
      { this.state.isConfirmDialogOpen
        && <ConfirmationDialog
          isOpen={this.state.isConfirmDialogOpen}
          title="Xác nhận xoá"
          message="Bạn có chắc xoá các khách hàng đã chọn ra khỏi nhóm?"
          onClose={this.handleConfirmDialogClose}
          onSubmit={this.handleDeleteGroupCustomers}
        />
      }
    </div>);
  }
}

GroupCustomers.propTypes = {
  groupId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  groupCodeItems: PropTypes.string,
  isGroupInactive: PropTypes.bool,
  viewModeOnly: PropTypes.bool,
};

GroupCustomers.defaultProps = {
  isGroupInactive: false,
  viewModeOnly: false,
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateGroupCustomer: (payload) => dispatch(updateGroupCustomer(payload)),
    saveGroupCustomer: (payload) => dispatch(saveGroupCustomer(payload)),
    removeGroupCustomers: (payload) => dispatch(removeGroupCustomers(payload)),
    deleteGroupCustomers: (payload) => dispatch(deleteGroupCustomers(payload)),
    exportGroupCustomers: (payload) => dispatch(exportGroupCustomers(payload)),
    saveGroupCustomers: (payload) => dispatch(saveGroupCustomers(payload)),
    saveGroupCustomersTotal: (payload) => dispatch(saveGroupCustomersTotal(payload)),
    getGroupCustomers: (payload) => dispatch(getGroupCustomers(payload)),
  };
};
const mapStateToProps = createStructuredSelector({
  groupCustomers: makeSelectGroupCustomers(),
  groupCustomersTotal: makeSelectGroupCustomersTotal(),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(GroupCustomers);
