import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import NameSelector from './NameSelector';

import gridClasses from '../QueueIngredientListGrid.module.scss';
import classes from './QueueIngredientListItem.module.scss';

const EmptyPairLevel = (props) => {
  return (<>
    { props.showCode && <div className={gridClasses.Col}></div> }
    <div className={gridClasses.Col}></div>
  </>);
}

class QueueIngredientListItem extends PureComponent {
  _isExisted = (fieldName) => {
    return !!this.props[fieldName];
  }
  handleDelete = () => {
    this.props.onDeleteItem({...this.props});
  }
  handleApprove = () => {
    this.props.onApproveItem({...this.props});
  }

  render() {
    return <div className={`${classes.ProductItem} ${gridClasses.Row}`}>
      <div className={`${gridClasses.Col}`}>{this.props.l6_name}</div>
      <NameSelector
        fieldName="l0"
        id={this.props.id}
        code={this.props.l0_code}
        name={this.props.l0_name}
        showCode
      />
      { this._isExisted('l0_name')
        ? (<NameSelector
            fieldName="l1"
            id={this.props.id}
            code={this.props.l1_code}
            name={this.props.l1_name}
            isEditing
            showCode
          />)
        : <EmptyPairLevel showCode/>
      }
      { this._isExisted('l1_name')
        ? (<NameSelector
            fieldName="l2"
            id={this.props.id}
            code={this.props.l2_code}
            name={this.props.l2_name}
            isEditing
          />)
        : <EmptyPairLevel/>
      }
      { this._isExisted('l2_name')
        ? (<NameSelector
            fieldName="l3"
            id={this.props.id}
            code={this.props.l3_code}
            name={this.props.l3_name}
            isEditing
          />)
        : <EmptyPairLevel/>
      }
      { this._isExisted('l3_name')
        ? (<NameSelector
            fieldName="l4"
            freeSolo
            id={this.props.id}
            code={this.props.l4_code}
            name={this.props.l4_name}
            isEditing
          />)
        : <EmptyPairLevel/>
      }
      { this._isExisted('l4_name')
        ? (<NameSelector
            fieldName="l5"
            freeSolo
            id={this.props.id}
            code={this.props.l5_code}
            name={this.props.l5_name}
            isEditing
          />)
        : <EmptyPairLevel/>
      }

      <div className={`${gridClasses.Col} ${classes.ActionsCol}`}>
        <Button size="small" color="secondary" onClick={this.handleDelete}>Xoá</Button>
        <Button
          disabled={!this.props.l0_code || !this.props.l1_code}
          color="primary"
          size="small"
          variant="contained"
          onClick={this.handleApprove}
        >Duyệt</Button>
      </div>
    </div>;
  }
}

QueueIngredientListItem.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  l0_code: PropTypes.string,
  l0_name: PropTypes.string,
  l1_code: PropTypes.string,
  l1_name: PropTypes.string,
  l2_code: PropTypes.string,
  l2_name: PropTypes.string,
  l3_code: PropTypes.string,
  l3_name: PropTypes.string,
  l4_code: PropTypes.string,
  l4_name: PropTypes.string,
  l5_code: PropTypes.string,
  l5_name: PropTypes.string,
  l6_name: PropTypes.string,
  onDeleteItem: PropTypes.func,
  onApproveItem: PropTypes.func,
};

QueueIngredientListItem.defaultProps = {
};

export default QueueIngredientListItem;
