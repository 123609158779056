import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { validDate } from 'utils/helper';
import { dateTimeFormat } from 'utils/constanst/dateConstants';
import format from 'date-fns/format';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectKeyAccountHistory } from 'redux/selectors';
import { getKeyAccountHistory } from 'redux/actions/admin/keyAccountActions';

import Panel from 'components/Panel/Panel';

import classes from './KeyAccountHistory.module.scss';

class KeyAccountHistory extends PureComponent {
  componentDidMount() {
    this.loadData();
  }
  componentDidUpdate(prevProps) {
    const { keyAccountId } = this.props;
    if (keyAccountId && keyAccountId !== prevProps.keyAccountId) {
      this.loadData();
    }
  }
  loadData = () => {
    if (this.props.keyAccountId) {
      this.props.getKeyAccountHistory({
        params: { getcare_key_account_id: this.props.keyAccountId },
      });
    }
  };

  render() {
    const { keyAccountHistory } = this.props;

    return (
      <Panel
        title="Lịch sử"
        panelClassName={classes.Panel}
        content={<div className={`${classes.Wrap}`}>
          <div className={`${classes.Inner}`}>
            <ul className={classes.EventList}>
              {keyAccountHistory.map((event) => (
                <li key={`event-${event.id}`} className={classes.EventItem}>
                  <span className={classes.Bullet}></span>
                  <div className={classes.EventDetails}>
                    <p className={classes.EventTime}>
                      {validDate(event.updated_at)
                        ? format(validDate(event.updated_at), dateTimeFormat)
                        : ''}
                    </p>
                    <p
                      className={classes.EventTitle}
                    >{`${event.user_name}: ${event.action}`}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>}
        isBorder
      />
    );
  }
}

KeyAccountHistory.propTypes = {
  keyAccountId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

KeyAccountHistory.defaultProps = {};

const mapStateToProps = createStructuredSelector({
  keyAccountHistory: makeSelectKeyAccountHistory(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getKeyAccountHistory: (payload) => dispatch(getKeyAccountHistory(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(KeyAccountHistory);
