import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import VendorInfo from './VendorInfo/VendorInfo';
import Panel from 'components/Panel/Panel';
import classes from './VendorDetails.module.scss';

class VendorDetails extends PureComponent {
  render() {
    const { vendorDetails } = this.props;

    return vendorDetails && (
      <Panel
        title="Thông tin chung"
        panelClassName={classes.Panel}
        contentClassName={classes.PanelContent}
        content={
          <VendorInfo {...vendorDetails}/>
        }
        isBorder
      />
    );
  }
}

VendorDetails.propTypes = {
  vendorDetails: PropTypes.object,
};

VendorDetails.defaultProps = {
  vendorDetails: null,
};

export default VendorDetails;
