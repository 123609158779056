import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { saveSalesTeamChannel, removeSalesTeamChannel } from 'redux/actions/admin/salesTeamActions';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { debounce } from 'lodash';

import EditOutlined from '@material-ui/icons/EditOutlined';
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import IconButton from '@material-ui/core/IconButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import gridClasses from '../SalesTeamChannelsGrid.module.scss';
import classes from './SalesTeamChannel.module.scss';
import salesChannelApi from 'utils/api/admin/salesChannelApi';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';

class SalesTeamChannel extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: !props.sales_channel?.id,
      isDialogOpen: false,
      channelOptions: [],
      inputChannelCode: '',
      selectedChannel: {
        id: props.sales_channel?.id,
        code: props.sales_channel?.code,
        coordinator_names: props.coordinator_names,
      },
    };
  }
  componentDidUpdate(prevProps, prevState) {
    const { inputChannelCode } = this.state;
    if (inputChannelCode !== prevState.inputChannelCode) {
      this.loadChannels({
        params: {
          code: inputChannelCode,
        },
      });
    }
  }
  loadChannels = debounce(async ({ params }) => {
    const { data: response } = await salesChannelApi.getAll({ params });
    if (!response?.result) return;
    this.setState({
      channelOptions: response.data
        ? this.filteredChannels([...response.data])
        : [],
    });
  }, 500);
  filteredChannels = (fullArr) => {
    return fullArr.filter((user) => {
      return !this.props.currentList.some(
        (item) => item.sales_channel?.id === user.id
      );
    });
  };
  isCodeValid = () => {
    return !!this.state.selectedChannel.id;
  };
  isRowValid = () => {
    return this.isCodeValid();
  };
  handleEdit = () => {
    this.setState({
      isEditing: true,
    });
  };
  handleCancel = () => {
    this.setState({
      isEditing: false,
    });
  };
  handleSave = () => {
    const { selectedChannel } = this.state;
    this.props.saveSalesTeamChannel({
      idStr: this.props.idStr,
      id: this.props.id,
      sales_channel: {
        id: selectedChannel.id,
        code: selectedChannel.code,
      },
    });
    this.setState({
      isEditing: false,
    });
    this.props.onSaveLists();
  };
  handleDelete = () => {
    this.props.removeSalesTeamChannel({
      idStr: this.props.idStr,
      id: this.props.id,
    });
    this.props.onSaveLists();
  };
  handleRowClick = () => {
    if (!this.state.isEditing) {
      this.handleEdit();
    }
  };
  handleRowBlur = () => {
    if (this.state.isEditing && this.isRowValid()) {
      this.handleSave();
    }
  };
  handleOpenDialog = (e) => {
    if (e) e.stopPropagation();
    this.setState({
      isDialogOpen: true,
    });
  }
  handleCloseDialog = () => {
    this.setState({
      isDialogOpen: false,
    });
  }
  _hasOneItem = () => {
    return this.props.currentList.filter(item => !!item.sales_channel).length <= 1;
  }

  render() {
    const { sales_channel } = this.props;
    const { isEditing, selectedChannel, channelOptions } = this.state;
    const isDeleteDisabled = this._hasOneItem();

    return (<>
      <ClickAwayListener onClickAway={this.handleRowBlur}>
        <div
          className={`${classes.ProductItem} ${gridClasses.Row}`}
          onClick={this.handleRowClick}
        >
          <div className={gridClasses.Col}>
            {isEditing ? (
              <Autocomplete
                selectOnFocus
                size="small"
                handleHomeEndKeys={false}
                value={selectedChannel || null}
                onChange={(e, value) => {
                  this.setState({
                    selectedChannel: { ...value },
                  });
                }}
                options={channelOptions || []}
                filterOptions={(x) => x}
                getOptionLabel={(option) => option?.code || ''}
                getOptionSelected={(option, value) =>
                  value && value.id && option ? option.id === value.id : null
                }
                onInputChange={(e, newInputValue) => {
                  this.setState({
                    inputChannelCode: newInputValue,
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="- Chọn -" />
                )}
              />
            ) : (
              sales_channel?.code
            )}
          </div>
          <div className={`${gridClasses.Col} PreWrap`}>
            { selectedChannel?.coordinator_names ? selectedChannel.coordinator_names.join(`\n`) : `-` }
          </div>
          <div className={`${gridClasses.Col} ${classes.ActionsCol}`}>
            {isEditing ? (
              <>
                <IconButton
                  disabled={!this.isRowValid()}
                  onClick={this.handleCancel}
                  style={{ padding: '3px' }}
                  size="small"
                >
                  <CancelOutlined
                    fontSize="small"
                    color={this.isRowValid() ? `secondary` : `inherit`}
                  />
                </IconButton>
                <IconButton
                  onClick={this.handleSave}
                  style={{ padding: '3px' }}
                  size="small"
                  disabled={!this.isRowValid()}
                >
                  <CheckCircleOutlineOutlined
                    fontSize="small"
                    color={this.isRowValid() ? `primary` : `inherit`}
                  />
                </IconButton>
              </>
            ) : (
              <>
                <IconButton
                  disabled={isDeleteDisabled}
                  onClick={this.handleOpenDialog}
                  style={{ padding: '3px' }}
                  size="small"
                >
                  <DeleteOutline fontSize="small" />
                </IconButton>
                <IconButton
                  onClick={this.handleEdit}
                  style={{ padding: '3px' }}
                  size="small"
                >
                  <EditOutlined fontSize="small" color="primary" />
                </IconButton>
              </>
            )}
          </div>
        </div>
      </ClickAwayListener>
      { this.state.isDialogOpen &&
        <ConfirmationDialog
          isOpen={this.state.isDialogOpen}
          title="Xác nhận xoá"
          message={(<p>{`Xoá khu vực: `} <strong>{sales_channel?.code}</strong> ra khỏi đội ngũ bán hàng.</p>)}
          onClose={this.handleCloseDialog}
          onSubmit={this.handleDelete}
        />
      }
    </>);
  }
}

SalesTeamChannel.propTypes = {
  idStr: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sales_channel: PropTypes.object,
  currentList: PropTypes.array,
  onSaveLists: PropTypes.func,
};

SalesTeamChannel.defaultProps = {
  currentList: [],
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveSalesTeamChannel: (payload) => dispatch(saveSalesTeamChannel(payload)),
    removeSalesTeamChannel: (payload) => dispatch(removeSalesTeamChannel(payload)),
  };
};
const withConnect = connect(null, mapDispatchToProps);
export default compose(withConnect)(SalesTeamChannel);
