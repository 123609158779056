import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { validNumber } from 'utils/helper';

import EditOutlined from '@material-ui/icons/EditOutlined';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import classes from './FieldEditInline.module.scss';

class FieldEditInline extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      params: {
        [props.fieldName]: props.value,
      },
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { fieldName, value } = this.props;
    if (value !== prevProps.value) {
      this.setState({
        isEditing: false,
        params: {
          ...this.state.params,
          [fieldName]: value,
        }
      });
    }
  }

  handleEdit = (fieldName) => {
    this.setState({
      isEditing: true,
    });
  }
  handleChange = (e) => {
    this.setState({
      params: {
        ...this.state.params,
        [e.target.name]: e.target.type === 'number' ? validNumber(e.target.value) : e.target.value,
      },
    });
  };
  handleSave = () => {
    const { fieldName } = this.props;
    this.setState({
      isEditing: false,
    });
    this.props.onSave({
      [fieldName]: this.state.params[fieldName],
    });
  };
  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.handleSave();
    }
  };

  render() {
    const { value, fieldName, type, fieldType, displayedValue } = this.props;
    const { isEditing, params } = this.state;

    return (<>
      {!isEditing ? (
        <div className={classes.EditInlineCol}>
          <label onClick={this.handleEdit}>{displayedValue ? displayedValue : value}{' '}</label>
          <IconButton
            onClick={this.handleEdit}
            style={{ padding: '3px' }}
          >
            <EditOutlined fontSize="small" color="primary"/>
          </IconButton>
        </div>
      ) : (<>
        { fieldType === 'textarea' && <div className={classes.FieldWrap}>
            <TextField
              autoComplete="off"
              rowsMax={3}
              multiline
              value={params[fieldName]}
              placeholder="Nhập..."
              autoFocus={true}
              name={fieldName}
              onChange={this.handleChange}
              onBlur={this.handleSave}
              onKeyDown={this.handleKeyDown}
            />
          </div>
        }
        { fieldType === 'select' && <Select
            placeholder="- Chọn -"
            value={params[fieldName]}
            autoFocus={true}
            name={fieldName}
            onChange={this.handleChange}
            onBlur={this.handleSave}
          >
            {
              this.props.options.map(item => <MenuItem key={item.id} value={item.id}>{ item.name }</MenuItem>)
            }
          </Select>
        }
        { fieldType === 'text' && <TextField
            autoComplete="off"
            className={classes.FieldWrap}
            value={params[fieldName]}
            placeholder="Nhập..."
            autoFocus={true}
            name={fieldName}
            type={type}
            onChange={this.handleChange}
            onBlur={this.handleSave}
            onKeyDown={this.handleKeyDown}
          />
        }
      </>)}
    </>);
  }
}

FieldEditInline.propTypes = {
  fieldName: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  type: PropTypes.string,
  fieldType: PropTypes.oneOf(['text', 'textarea', 'select']),
  onSave: PropTypes.func,
  options: PropTypes.array,
  displayedValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

FieldEditInline.defaultProps = {
  value: '',
  type: '',
  fieldType: 'text',
  options: [],
};

export default FieldEditInline;
