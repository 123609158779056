import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { debounce } from 'lodash';
import { getProductList } from 'redux/actions/admin/productActions';
import { createStructuredSelector } from "reselect";
import {
  makeSelectProductList,
  makeSelectProductTotal,
  makeSelectProductIsLoading,
} from 'redux/selectors';
import { listParamsMap, listDisplayFields } from 'utils/constanst/adminProductConstants';
import { getSortsString, getDisplayFields } from 'utils/helper';

import ProductListMappingItem from './ProductListMappingItem/ProductListMappingItem';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';
import ProductFilters from 'components/admin/ProductList/ProductFilters/ProductFilters';
import ListPagination from 'components/ListPagination/ListPagination';

import gridClasses from './ProductListMappingGrid.module.scss';
import classes from './ProductListMapping.module.scss';

class ProductListMapping extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      listParams: { ...listParamsMap },
    }
  }
  componentDidMount() {
    this.loadProductList();
  }
  componentDidUpdate(prevProps, prevState) {
    const { willForceResetList } = this.props;
    if (willForceResetList && willForceResetList !== prevProps.willForceResetList) {
      // reset list
      this.props.getProductList({
        params: this.state.listParams,
      });
    }
  }
  loadProductList = () => {
    const payload = {
      params: this.state.listParams,
    };
    this.props.getProductList(payload);
  }
  _isProductSelected = (id) => {
    return this.props.selectedProduct?.id === id;
  }
  _willProductRemoved = (id) => {
    return this.props.willRemoveProductIds.includes(id);
  }

  handleFilterChange = (filters, forceResetPage: false) => {
    const paramsFromFilters = filters.reduce((memo, item) => {
      memo[item.name] = item.value;
      return memo;
    }, {});
    const newParams = forceResetPage ? {
      ...this.state.listParams,
      ...paramsFromFilters,
      page: 1,
    } : {
      ...this.state.listParams,
      ...paramsFromFilters,
    };
    this.setState({
      listParams: newParams,
    });
    this.props.getProductList({
      params: newParams,
    });
  }
  handleSortChange = ({ sortBy, sortDir }) => {
    const newSortString = getSortsString({[sortBy]: sortDir});
    this.handleFilterChange([{
      name: 'order',
      value: newSortString,
    }]);
  }
  handleItemSelectedToggle = ({ id, isSelected }) => {
    const productItem = this.props.productList.find(item => item.id === id);
    this.props.onItemSelectedToggle({
      productItem: {...productItem},
      isSelected: isSelected,
    });
  }
  handleRemoveProductToggle = ({ id, willRemove }) => {
    this.props.onItemRemovedToggle({
      productId: id,
      willRemove,
    });
  }

  render() {
    const { total, isLoading, productList } = this.props;
    const { listParams } = this.state;
    const displayFields = getDisplayFields(listParams, listDisplayFields);

    return (<>
      <div className={classes.PageMain}>
        <ProductFilters onFilterChange={debounce(this.handleFilterChange, 500)}/>
        <div className={`${classes.List} ${isLoading && 'OverlayLoading'}`}>
          <div className={`${classes.Body}`}>
            <div className={`${gridClasses.Row} ${classes.Header}`}>
              <div className={gridClasses.Col}>{` `}</div>
              { displayFields.map((item) => (
                <ListHeaderCol
                  key={item.name}
                  className={gridClasses.Col}
                  {...item}
                  onSortChange={this.handleSortChange}
                />
              )) }
            </div>
          
            { productList.length
              ? productList.map(item => (
                <ProductListMappingItem
                  key={`product-mapping-${item.id}`}
                  {...item}
                  isSelected={this._isProductSelected(item.id)}
                  willRemove={this._willProductRemoved(item.id)}
                  onItemSelectedToggle={this.handleItemSelectedToggle}
                  onRemoveProductToggle={this.handleRemoveProductToggle}
                />))
              : (<p className="NoData">Không có sản phẩm nào</p>)
            }
          </div>
        </div>
      </div>
      <div className={classes.PageFooter}>
        <ListPagination
          page={listParams.page}
          pageSize={listParams.page_size}
          total={total}
          listName="sản phẩm"
          onFilterChange={this.handleFilterChange}
        />
      </div>
    </>);
  }
}

const mapStateToProps = createStructuredSelector({
  productList: makeSelectProductList(),
  total: makeSelectProductTotal(),
  isLoading: makeSelectProductIsLoading(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getProductList: (payload) => dispatch(getProductList(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(ProductListMapping);