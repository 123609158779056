import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import vendorApi from 'utils/api/admin/vendorApi';
import { allInPageSize } from 'utils/constanst/common';
import { isEqual, sortBy } from 'lodash';
import { numberFormat, validNumber } from 'utils/helper';
import { Link } from 'react-router-dom';

import FieldEditable from 'components/FieldEditable/FieldEditable';
import EditOutlined from '@material-ui/icons/EditOutlined';
import IconButton from '@material-ui/core/IconButton';
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import gridClasses from '../ProductBonusListGrid.module.scss';
import classes from './ProductBonusListItem.module.scss';

class ProductBonusListItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: !props.id,
      params: this._getInitParams(props),
      vendorOptions: props.getcare_vendor ? [{...props.getcare_vendor}] : null,
    }
  }

  componentDidMount() {
    const { isEditing } = this.state;
    if (isEditing) {
      this._loadVendors();
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { isEditing, vendorOptions, params } = this.state;
    if (isEditing && isEditing !== prevState.isEditing) {
      this._loadVendors();
    }
    if (
      isEditing
      && !(params.getcare_vendor || params.getcare_vendor?.id)
      && vendorOptions
      && vendorOptions.length > 0
      && !isEqual(sortBy(vendorOptions), sortBy(prevState.vendorOptions))
    ) {
      // set default vendor is the first option (GETCARE vendor)
      this.handleSaveField({target: { name: 'getcare_vendor' } }, {...vendorOptions[0]});
    }
  }

  _getInitParams = (params) => {
    return {
      idStr: params.idStr,
      id: params.id,
      code: params.code,
      name: params.name,
      model: params.model,
      getcare_product_unit: params.getcare_product_unit,
      estimate_quantity: params.estimate_quantity,
      getcare_vendor: params.getcare_vendor,
      description: params.description,
      active: params.active,
    }
  }
  _resetState = () => {
    this.setState({
      isEditing: false,
      params: this._getInitParams(this.props),
      vendorOptions: this.props.getcare_vendor ? [{...this.props.getcare_vendor}] : null,
      vendorInput: this.props.getcare_vendor ? this.props.getcare_vendor.name : '',
    });
  }
  _loadVendors = async () => {
    const { data: response } = await vendorApi.getAll({
      params: {
        page_size: allInPageSize,
        mode: 'CREATE_BONUS',
      }
    });
    if (!response?.result) return;
    this.setState({
      vendorOptions: response.data ? [...response.data] : [],
    });
  }
  _getUnitSelected = (unitId) => {
    return this.props.productUnits.find(item => item.id === unitId);
  }
  _isUnitValid = () => {
    return !!this.state.params?.getcare_product_unit?.id;
  }
  _isNameValid = () => {
    return !!this.state.params?.name;
  }
  _isRowValid = () => {
    return this._isUnitValid() && this._isNameValid();
  };
  stopPropagation = (e) => {
    e.stopPropagation();
  }
  handleSaveField = (e, value) => {
    let newValue = value;
    if (e.target.name === 'getcare_product_unit') {
      newValue = { ...this._getUnitSelected(value) };
    }
    if (e.target.name === 'getcare_vendor') {
      newValue = { id: value?.id, name: value?.name };
    }
    this.setState({
      params: {
        ...this.state.params,
        [e.target.name]: newValue,
      },
    });
  }
  handleEdit = (e) => {
    e.stopPropagation();
    this.setState({
      isEditing: true,
    });
  }
  handleCancel = (e) => {
    e.stopPropagation();
    this._resetState();
    if (!this.props.id) {
      this.props.removeProductBonuses([{
        id: this.props.id,
        idStr: this.props.idStr,
      }]);
    }
  }
  handleSave = (e) => {
    if (e) e.stopPropagation();
    this.props.saveProductBonus({
      ...this.state.params,
      idStr: this.props.idStr,
      id: this.props.id,
    });
    this.setState({
      isEditing: false,
    });
  }
  handleRowBlur = () => {
  }
  handleInputVendorChange = (e, newValue) => {
    this.setState({
      vendorInput: newValue,
    });
  }

  render() {
    const {
      code,
      name,
      model,
      getcare_product_unit,
      estimate_quantity,
      getcare_vendor,
      description,
      productUnits,
    } = this.props;
    const { isEditing, vendorOptions, params } = this.state;

    return (<>
      <ClickAwayListener onClickAway={this.handleRowBlur}>
        <div
          className={`${classes.Item} ${gridClasses.Row}`}
          onClick={this.handleEdit}
        >
          <div className={gridClasses.Col}>{ code }</div>
          <div className={gridClasses.Col}>
            { isEditing ? <FieldEditable
              fieldName="name"
              value={name || ''}
              displayedValue={name}
              editModeOnly
              error={!this._isNameValid()}
              onSave={() => {return;}}
              onChange={this.handleSaveField}
            /> : name }
          </div>
          <div className={gridClasses.Col}>
            { isEditing ? <FieldEditable
              fieldName="model"
              value={model || ''}
              displayedValue={model}
              editModeOnly
              onSave={() => {return;}}
              onChange={this.handleSaveField}
            /> : model }
          </div>
          <div className={gridClasses.Col}>
            { isEditing ? <FieldEditable
                fieldType="select"
                fieldName="getcare_product_unit"
                value={getcare_product_unit?.id || ''}
                displayedValue={getcare_product_unit?.name}
                editModeOnly
                error={!this._isUnitValid()}
                options={productUnits || []}
                onSave={() => {return;}}
                onChange={this.handleSaveField}
              /> : getcare_product_unit?.name }
          </div>
          <div className={gridClasses.Col}>
            { isEditing ? <FieldEditable
                fieldName="estimate_quantity"
                value={validNumber(estimate_quantity)}
                displayedValue={![null, undefined, ''].includes(estimate_quantity) ? numberFormat(estimate_quantity) : ''}
                type="number"
                editModeOnly
                onSave={() => {return;}}
                onChange={this.handleSaveField}
              /> : estimate_quantity }
          </div>
          <div className={gridClasses.Col}>
            { isEditing ? <Autocomplete
                openOnFocus
                selectOnFocus
                size="small"
                handleHomeEndKeys={false}
                value={params.getcare_vendor || null}
                onChange={(e, newValue) => {
                  this.handleSaveField({target: { name: 'getcare_vendor' } }, newValue);
                }}
                options={vendorOptions || []}
                renderOption={(option) => `${option.name}`}
                getOptionLabel={(option) => (option && option.name) || ''}
                getOptionSelected={(option, value) => (value && value.id && option) ? option.id === value.id : null}
                renderInput={(params) => <TextField {...params} placeholder="- Chọn -" />}
              /> : <Link to={`/vendor/${getcare_vendor?.id}`} className="TextSecondary">{ getcare_vendor?.name}</Link>
               }
          </div>
          <div className={gridClasses.Col}>
            { isEditing ? <FieldEditable
              fieldName="description"
              value={description || ''}
              displayedValue={description}
              editModeOnly
              onSave={() => {return;}}
              onChange={this.handleSaveField}
            /> : description }
          </div>
          <div className={`${gridClasses.Col} ${classes.ActionsCol}`}>
            { isEditing ? <>
              <IconButton
                onClick={this.handleCancel}
                style={{ padding: '0' }}
                size="small"
              >
                <CancelOutlined fontSize="small" color="secondary" />
              </IconButton>
              <IconButton
                disabled={!this._isRowValid()}
                onClick={this.handleSave}
                style={{ padding: '0' }}
                size="small"
              >
                <CheckCircleOutlineOutlined
                  fontSize="small"
                  color={this._isRowValid() ? `primary` : `inherit`}
                />
              </IconButton>
            </> : <IconButton
                size="small"
                color="primary"
                onClick={this.handleEdit}
              ><EditOutlined fontSize="small"/></IconButton>
            }
          </div>
        </div>
      </ClickAwayListener>
    </>);
  }
}

ProductBonusListItem.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  code: PropTypes.string,
  name: PropTypes.string,
  model: PropTypes.string,
  getcare_product_unit: PropTypes.object,
  getcare_vendor: PropTypes.object,
  description: PropTypes.string,
  productUnits: PropTypes.array,
};

ProductBonusListItem.defaultProps = {
  code: '',
  name: '',
  model: '',
  getcare_product_unit: null,
  getcare_vendor: null,
  description: '',
  productUnits: [],
}

export default ProductBonusListItem;
