import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import Checkbox from '@material-ui/core/Checkbox';
import { APPROVE } from 'utils/constanst/adminIngredientConstants';
import { Link } from 'react-router-dom';

import gridClasses from '../ProductListGrid.module.scss';
import classes from './ProductListItem.module.scss';

class ProductListItem extends PureComponent {
  goToDetails = (e) => {
    this.props.history.push(`/products/${this.props.id}`);
  };
  handleCheckboxChange = (e) => {
    e.preventDefault();
    this.props.onItemSelectedToggle({
      id: this.props.id,
      isSelected: !this.props.isSelected,
    });
  };

  getIngredients = () => {
    const { getcare_product_active_ingredients } = this.props;
    return getcare_product_active_ingredients?.filter(ing => !!ing.getcare_active_ingredients).map(ing => ({
        name: ing.getcare_active_ingredients.l6_name,
        isApproval: ing.getcare_active_ingredients.approval === APPROVE,
      })
    ) || [];
  }

  render() {
    const {
      getcare_id,
      name,
      registration_number,
      dosage_forms,
      getcare_uom,
      active_ingredients_main,
      isSelected,
      getcare_manufacturer,
      id,
      getcare_product_category_ecom_items,
      isEditAllowed,
    } = this.props;

    return (
      <Link
        to={`/products/${id}`}
        className={`${classes.ProductItem} ${gridClasses.Row} ${isEditAllowed ? gridClasses.HasCheckboxCol : ''}`}
      >
        { isEditAllowed && (
          <div className={gridClasses.Col}>
            <Checkbox
              className={classes.Checkbox}
              checked={isSelected}
              onClick={this.handleCheckboxChange}
            />
          </div>
        )}
        <div className={gridClasses.Col}>{getcare_id}</div>
        <div className={gridClasses.Col}>{name}</div>
        <div className={gridClasses.Col}>{registration_number}</div>
        <div className={gridClasses.Col}>{this.props.package}</div>
        <div className={gridClasses.Col}>{getcare_uom.name}</div>
        <div className={gridClasses.Col}>{dosage_forms}</div>
        <div className={gridClasses.Col}>
          { getcare_manufacturer?.name }
        </div>
        <div className={gridClasses.Col}>{active_ingredients_main}</div>
        <div className={`${gridClasses.Col} ${classes.Tags}`}>
          {this.getIngredients().map((ing, index) => (
            <span key={index} className={`Badge BlueGrey Text ${ing.isApproval ? '' : classes.Unapproved}`}>
              {ing.name}
            </span>
          ))}
        </div>
        <div className={gridClasses.Col}>
          <div className={classes.GetcareCategoryList}>
            {
              getcare_product_category_ecom_items.map( item => (
                <span key={item.id} className={`Badge BlueGrey Text`}>
                  {item.getcare_category_ecom.name}
                </span>
              ))
            }
          </div>
        </div>
      </Link>
    );
  }
}

ProductListItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  getcare_id: PropTypes.string,
  code: PropTypes.string,
  name: PropTypes.string,
  registration_number: PropTypes.string,
  dosage_forms: PropTypes.string,
  expiry_date: PropTypes.number,
  expiry_date_unit: PropTypes.string,
  getcare_uom: PropTypes.object,
  getcare_manufacturer: PropTypes.object,
  active_ingredients_main: PropTypes.string,
  product_active_ingredients_names: PropTypes.array,
  thumbnail: PropTypes.string,
  isSelected: PropTypes.bool,
  enablecClickRowToSelect: PropTypes.bool,
  onItemSelectedToggle: PropTypes.func,
};

ProductListItem.defaultProps = {
  isSelected: false,
  enablecClickRowToSelect: false,
  active_ingredients_main: '',
  product_active_ingredients_names: [],
};

export default withRouter(ProductListItem);
