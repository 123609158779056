import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import { validDate } from 'utils/helper';
import storeApi from 'utils/api/admin/storeApi';
import { dateFormat } from 'utils/constanst/dateConstants';
import classes from './StoreDocuments.module.scss';

class StoreDocuments extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      documents: [],
      isLoading: false,
    }
  }

  componentDidMount() {
    this.props.storeId && this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.storeId !== prevProps.storeId) {
      this.loadData();
    }
  }

  loadData = async () => {
    this.setState({
      isLoading: true,
    });
    const { data: response } = await storeApi.getDocuments({
      id: this.props.storeId,
      params: {},
    });
    if (response?.result) {
      this.setState({
        isLoading: false,
        documents: response.data,
      });
    }
  }

  render() {
    const { documents, isLoading } = this.state;

    return (
      <div className={`${classes.Docs} ${isLoading && "OverlayLoading"}`}>
        { (documents && documents.length)
          ? documents.map(doc => (
              <div key={doc.id} className={classes.DocItem}>
                <div className={classes.Info}>
                  <a className={classes.DocLink} href={doc.url} target="_blank" rel="noopener noreferrer">{ doc.filename }</a>
                </div>
                <div className={classes.Info}>
                  <label>Người tạo</label>
                  { doc.getcare_user?.name }
                </div>
                <div className={classes.Info}>
                  <label>Ngày tạo</label>
                  { validDate(doc?.created_at) ? format(validDate(doc?.created_at), dateFormat) : '' }
                </div>
              </div>
            ))
          : (<p className="NoData">Không có data</p>)
        }
      </div>
    );
  }
}

StoreDocuments.propTypes = {
  storeId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

StoreDocuments.defaultProps = {
};

export default StoreDocuments;
