import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { debounce } from 'lodash';
import {
  getVendorPriceList,
  saveTempVendorPriceItem,
  removeTempVendorPriceItem,
  saveTempVendorPriceList,
  updateVendorPriceList,
  saveVendorPriceItem,
  saveVendorPriceVisibility,
} from 'redux/actions/admin/vendorPriceActions';
import { getPriceUnits } from 'redux/actions/admin/policyPriceActions';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectVendorPriceList,
  makeSelectVendorPriceLoading,
  makeSelectVendorPriceListTotal,
  makeSelectTempVendorPriceList,
  makeSelectPriceUnits,
  makeSelectVendorPriceVisibility,
} from 'redux/selectors';
import {
  listParamsMap,
  listDisplayFields,
} from 'utils/constanst/adminVendorPriceConstants';
import { getSortsString, getDisplayFields } from 'utils/helper';
import { withRouter } from 'react-router-dom';
import { isEqual, sortBy } from 'lodash';
import authApi from 'utils/api/authApi';
import { toast } from 'react-toastify';

import VendorPriceList from 'components/admin/VendorPriceList/VendorPriceList';
import VendorPriceListFilters from 'components/admin/VendorPriceList/VendorPriceListFilters/VendorPriceListFilters';
import ListPagination from 'components/ListPagination/ListPagination';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import UpdateProductQuantitiesDialog from './UpdateProductQuantitiesDialog/UpdateProductQuantitiesDialog';

import classes from './VendorPrices.module.scss';

class VendorPrices extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      listParams: { ...listParamsMap },
      selectedItems: [],
      isMoreActionsOpen: false,
      isUpdateMultipleProductsOpen: false,
    };
    this.moreActionsMenuRef = React.createRef();
  }

  async componentDidMount() {
    await this.getAllowedVisibility();
    this.props.getVendorPriceList({
      params: this.state.listParams,
    });
    this.props.getPriceUnits();
  }
  componentDidUpdate(prevProps) {
    const { vendorPriceList } = this.props;

    const { selectedItems } = this.state;
    if (!isEqual(sortBy(vendorPriceList), sortBy(prevProps.vendorPriceList)) && selectedItems.length > 0) {
      const remainItems = selectedItems.filter(p => vendorPriceList.some(item => {
        const comparedField = item.idStr ? `idStr` : `id`;
        return item[comparedField] === p[comparedField];
      }));
      this.setState({
        selectedItems: remainItems,
      });
    }
  }

  getAllowedVisibility = async () => {
    const { data: response } = await authApi.getAllowedActions({
      component: 'price',
    });
    if (!response.result) {
      toast.error(response.message);
      return;
    }
    this.props.saveVendorPriceVisibility(response.visibility || null);
  }
  getVisibleColumns = () => {
    const { vendorPriceVisibility } = this.props;
    const readableFields = vendorPriceVisibility?.read;
    const editableFields = vendorPriceVisibility?.edit;
    if (!readableFields || readableFields.length === 0) return [];
    return listDisplayFields.filter(field =>
      ([null, undefined].includes(field.authorize_name) || readableFields.includes(field.authorize_name))
    ).map(field => ({
      ...field,
      editable: editableFields && editableFields.includes(field.authorize_name),
    }));
  }
  _getHighLightList = () => {
    const { tempVendorPriceList, vendorPriceList } = this.props;
    if (!tempVendorPriceList.length || !vendorPriceList.length) {
      return [...vendorPriceList];
    }
    return vendorPriceList.map(price => ({
      ...price,
      changedItem: tempVendorPriceList.find(item => item.id === price.id),
    }));
  }
  handleSortChange = ({ sortBy, sortDir }) => {
    const newSortString = getSortsString({
      [sortBy]: sortDir,
    });
    this.handleFilterChange([
      {
        name: 'order',
        value: newSortString,
      },
    ]);
  };
  handleFilterChange = (filters, forceResetPage: false) => {
    const paramsFromFilters = filters.reduce((memo, item) => {
      if (['price_buy', 'price_sales'].includes(item.name)) {
        const numArr = item.value.map(val => val.trim() !== '' ? Number(val) : '');
        memo[`${item.name}_min`] = numArr[numArr.length - 1] && numArr[0] ? Math.min.apply(null, [...numArr]) : numArr[0];
        memo[`${item.name}_max`] = numArr[numArr.length - 1] && numArr[0] ? Math.max.apply(null, [...numArr]) : numArr[numArr.length - 1];
      } else {
        memo[item.name] = item.value;
      }
      return memo;
    }, {});

    const newParams = forceResetPage ? {
      ...this.state.listParams,
      ...paramsFromFilters,
      page: 1,
    } : {
      ...this.state.listParams,
      ...paramsFromFilters,
    };

    this.setState({
      listParams: newParams,
    });
    this.props.getVendorPriceList({
      params: newParams,
    });
  };
  handleSavePrice = (tempItem) => {
    this.props.saveTempVendorPriceItem(tempItem);
  }
  handleRemovePrice = (tempItem) => {
    this.props.removeTempVendorPriceItem(tempItem);
  }
  handleCancelPricesUpdating = () => {
    this.props.saveTempVendorPriceList([]);
  }
  handleSubmitPricesUpdating = () => {
    const params = {
      channel_source: 'ERP',
      items: this.props.tempVendorPriceList.map(item => ({
        ...item,
        getcare_policy_unit_id: item.policy_unit?.id,
        policy_unit: undefined,
        updated_at_price_buy: undefined,
        updated_at_price_sales: undefined,
      }))
    }
    this.props.updateVendorPriceList({
      params,
      listParams: { ...this.state.listParams },
    });
  }

  handleAllSelectedToggle = (e) => {
    this.setState({
      selectedItems: e.target.checked
        ? [...this.state.selectedItems, ...this.props.vendorPriceList.map(item => ({ id: item.id }))]
        : [],
    });
  }
  handleItemSelectedToggle = ({ item, isSelected }) => {
    const remainItems = this.state.selectedItems.filter((p) => {
      const comparedField = p.idStr ? 'idStr' : 'id';
      return p[comparedField] !== item[comparedField];
    });
    this.setState({
      selectedItems: isSelected ? [...remainItems, { ...item }] : remainItems,
    });
  }
  handleToggleDropdown = () => {
    this.setState({
      isMoreActionsOpen: !this.state.isMoreActionsOpen,
    });
  };
  handleCloseDropdown = () => {
    this.setState({
      isMoreActionsOpen: false,
    });
  };
  handleUpdateMultiItemsDialogOpen = () => {
    this.setState({
      isUpdateMultipleProductsOpen: true,
    });
    this.handleCloseDropdown();
  }
  handleUpdateMultiItemsDialogClose = () => {
    this.setState({
      isUpdateMultipleProductsOpen: false,
    });
  }
  handleUpdateProductQuantitiesSuccess = ({ getcare_product_ids, estimated_quantity, ka_days }) => {
    // update to items' props and changed item;
  }

  render() {
    const { vendorPriceListTotal, loading, tempVendorPriceList, updatePriceLoading } = this.props;
    const { listParams, selectedItems, isMoreActionsOpen } = this.state;
    const isListLoading = loading || updatePriceLoading;
    const hasSelected = selectedItems.length > 0;
    const visibleColumns = this.getVisibleColumns();

    return (
      <React.Fragment>
        <div className={classes.PageWrap}>
          <div className={classes.PageHeader}>
            <h1 className={classes.PageTitle}>Danh sách giá mua - bán theo nhà cung cấp</h1>

            { tempVendorPriceList.length > 0 && <>
              <Button
                size="small"
                variant="outlined"
                color="secondary"
                onClick={this.handleCancelPricesUpdating}
              >Huỷ cập nhật giá</Button>
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={this.handleSubmitPricesUpdating}
              >Xác nhận cập nhật giá</Button>
            </> }

            <IconButton
              size="small"
              ref={this.moreActionsMenuRef}
              className={`${classes.MoreActionBtn} ${hasSelected && classes.Active}`}
              aria-controls={isMoreActionsOpen ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={this.handleToggleDropdown}
              style={{ padding: '6px' }}
            >
              <MoreVertIcon/>
            </IconButton>
            <Popper
              open={isMoreActionsOpen}
              anchorEl={this.moreActionsMenuRef.current}
              role={undefined}
              transition
              disablePortal
              style={{ zIndex: 3 }}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{ transformOrigin: placement === 'bottom' ? 'left top' : 'left bottom' }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={this.handleCloseDropdown}>
                      <MenuList id="menu-list-grow" autoFocusItem={isMoreActionsOpen}>
                        <MenuItem onClick={this.handleUpdateMultiItemsDialogOpen}>Cập nhật hàng loạt</MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>

          </div>
          <div className={classes.PageMain}>
            <VendorPriceListFilters
              onFilterChange={debounce(this.handleFilterChange, 500)}
            />
            <VendorPriceList
              displayFields={getDisplayFields(
                listParams,
                visibleColumns
              )}
              vendorPriceList={this._getHighLightList()}
              selectedItems={selectedItems}
              isLoading={isListLoading}
              onSortChange={this.handleSortChange}
              onSavePrice={this.handleSavePrice}
              onRemovePrice={this.handleRemovePrice}
              onItemSelectedToggle={this.handleItemSelectedToggle}
              onAllSelectedToggle={this.handleAllSelectedToggle}
            />
          </div>
          <div className={classes.PageFooter}>
            <ListPagination
              page={listParams.page}
              pageSize={listParams.page_size}
              total={vendorPriceListTotal}
              listName="giá"
              onFilterChange={this.handleFilterChange}
            />
          </div>
        </div>

        {this.state.isUpdateMultipleProductsOpen &&
          <UpdateProductQuantitiesDialog
            isOpen={this.state.isUpdateMultipleProductsOpen}
            selectedItems={selectedItems}
            onClose={this.handleUpdateMultiItemsDialogClose}
            onSaveSuccess={this.handleUpdateProductQuantitiesSuccess}
          />
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  vendorPriceListTotal: makeSelectVendorPriceListTotal(),
  vendorPriceList: makeSelectVendorPriceList(),
  tempVendorPriceList: makeSelectTempVendorPriceList(),
  loading: makeSelectVendorPriceLoading(),
  priceUnits: makeSelectPriceUnits(),
  vendorPriceVisibility: makeSelectVendorPriceVisibility(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getVendorPriceList: (payload) => dispatch(getVendorPriceList(payload)),
    updateVendorPriceList: (payload) => dispatch(updateVendorPriceList(payload)),
    saveTempVendorPriceItem: (payload) => dispatch(saveTempVendorPriceItem(payload)),
    removeTempVendorPriceItem: (payload) => dispatch(removeTempVendorPriceItem(payload)),
    saveTempVendorPriceList: (payload) => dispatch(saveTempVendorPriceList(payload)),
    saveVendorPriceItem: (payload) => dispatch(saveVendorPriceItem(payload)),
    getPriceUnits: () => dispatch(getPriceUnits()),
    saveVendorPriceVisibility: (payload) => dispatch(saveVendorPriceVisibility(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter)(VendorPrices);
