import axiosAuth from 'utils/axios/axiosAuth';

const assetComponentApi = {
  getAssetComponentList: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_asset/`, {
      params: queryParams,
    });
  },
  createOrUpdateAssetComponent: ({ params }) => {
    return axiosAuth.post(`getcare_asset`, params);
  },
  deleteAssetComponentMultiple: ({ params }) => {
    return axiosAuth({
      url: "getcare_asset_delete_multiple",
      method: "DELETE",
      data: params,
    });
  },
};

export default assetComponentApi;
