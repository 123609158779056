import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import policyPriceApi from 'utils/api/admin/policyPriceApi';
import {
  customerGroupTypes,
  POLICY_PRICE_CUSTOMER_TYPE,
} from 'utils/constanst/adminPolicyPriceContants';

import Checkbox from '@material-ui/core/Checkbox';
import EditOutlined from '@material-ui/icons/EditOutlined';
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import IconButton from '@material-ui/core/IconButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import gridClasses from '../PolicyPriceCustomersGrid.module.scss';
import classes from './PolicyPriceCustomer.module.scss';

class PolicyPriceCustomer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isEditing: !props.id && !props.getcare_erp_group && !props.getcare_customer,

      params: this._getInitParams(props),
      customerOptions: this._initCustomerOptions(props),
      codeInput: '',
      nameInput: '',
    };
  }
  componentDidUpdate(prevProps, prevState) {
    const { codeInput, nameInput } = this.state;
    if (codeInput && codeInput !== prevState.codeInput) {
      this.loadSuggestedCustomers({
        params: {
          code: codeInput,
        },
      });
    }
    if (nameInput && nameInput !== prevState.nameInput) {
      this.loadSuggestedCustomers({
        params: {
          name: nameInput,
        },
      });
    }
  }

  _getInitParams = (params) => {
    return {
      idStr: params?.idStr,
      id: params?.id,
      type_id: params?.type_id,
      getcare_customer: params?.getcare_customer ? {...params?.getcare_customer} : null,
      getcare_erp_group: params?.getcare_erp_group ? {...params?.getcare_erp_group} : null,
    }
  }
  _initCustomerOptions = (params) => {
    const selectedCustomer = params.type_id === POLICY_PRICE_CUSTOMER_TYPE ? params.getcare_customer : params.getcare_erp_group;
    return selectedCustomer ? [{...selectedCustomer}] : [];
  }
  _resetState = () => {
    this.setState({
      isEditing: !this.props.id && !this.props.getcare_erp_group && !this.props.getcare_customer,

      params: this._getInitParams(this.props),
      customerOptions: this._initCustomerOptions(this.props),
      codeInput: '',
      nameInput: '',
    });
  };
  _resetFormAfterChangeTypeId = (typeIdValue) => {
    this.setState({
      isEditing: true,
      params: this._getInitParams({
        ...this.state.params,
        type_id: typeIdValue,
        getcare_customer: null,
        getcare_erp_group: null,
      }),
      customerOptions: [],
      codeInput: '',
      nameInput: '',
    });
  }
  loadSuggestedCustomers = debounce(async ({ params }) => {
    const { data: response } = await policyPriceApi.getGroupCustomers({
      params: {
        ...params,
        type_id: this.state.params.type_id,
      },
    });
    if (!response?.result) return;

    const fullList = this.state.params.type_id === POLICY_PRICE_CUSTOMER_TYPE
      ? response.data.customers || []
      : response.data.erp_groups || [];

    this.setState({
      customerOptions: response.data
        ? this.filteredOptions(fullList)
        : [],
    });
  }, 500);
  filteredOptions = (fullArr) => {
    const { params } = this.state;
    return fullArr.filter((p) => {
      return params.type_id === POLICY_PRICE_CUSTOMER_TYPE
        ? ((p.code === this.props.getcare_customer?.code)
          || !this.props.currentList.some(
              (item) => item.getcare_customer?.code === p.code
            )
          ) : (p.code === this.props.getcare_erp_group?.code)
              || !this.props.currentList.some(
                (item) => item.getcare_erp_group?.code === p.code
              )
    });
  };
  isRowValid = (params) => {
    return !!params.getcare_customer?.id || !!params.getcare_erp_group?.id;
  };

  stopPropagation = (e) => {
    e.stopPropagation();
  };
  handleCheckboxChange = (e) => {
    e.stopPropagation();
    this.props.onItemSelectedToggle({
      item: {
        id: this.props.id,
        idStr: this.props.idStr,
      },
      isSelected: !this.props.isSelected,
    });
  };
  handleEdit = () => {
    this.setState({
      isEditing: true,
    });
  };
  handleCancel = () => {
    this._resetState();
    if (!this.props.id) {
      this.props.removePolicyPriceCustomer({
        id: this.props.id,
        idStr: this.props.idStr,
      });
    }
  };
  handleSave = () => {
    const { params } = this.state;
    if (!this.isRowValid(params)) return;
    this.props.savePolicyPriceCustomer({
      ...params,
      id: this.props.id,
      idStr: this.props.idStr,
    });
    this.setState({
      isEditing: false,
    });
  };
  handleRowClick = () => {
    if (this.props.readOnly) {
      return;
    }

    if (!this.state.isEditing) {
      this.handleEdit();
    }
  };
  handleRowBlur = () => {
    if (this.props.readOnly) {
      return;
    }

    if (this.state.isEditing && this.isRowValid(this.state.params)) {
      this.handleSave();
    }
  };
  handleSelectGroupChange = (e, newValue) => {
    this._resetFormAfterChangeTypeId(e.target.value);
  };
  handleSelectCustomerChange = (e, newValue) => {
    const { params } = this.state;
    this.setState({
      params: {
        ...this.state.params,
        getcare_erp_group: params.type_id === POLICY_PRICE_CUSTOMER_TYPE ? null : newValue,
        getcare_customer: params.type_id === POLICY_PRICE_CUSTOMER_TYPE ? newValue : null,
      },
    });
  };


  render() {
    const { isSelected, readOnly, type_id, getcare_erp_group, getcare_customer } = this.props;
    const { isEditing, params, customerOptions } = this.state;

    return (
      <>
        <ClickAwayListener onClickAway={this.handleRowBlur}>
          <div
            className={`${classes.Item} ${gridClasses.Row}`}
            onClick={this.handleRowClick}
          >
            <div className={gridClasses.Col}>
              <Checkbox
                className={classes.Checkbox}
                checked={isSelected}
                disabled={readOnly}
                onClick={this.stopPropagation}
                onChange={this.handleCheckboxChange}
              />
            </div>
            <div className={gridClasses.Col}>
              <Select
                disabled={readOnly}
                value={params.type_id || ''}
                name="type_id"
                size="small"
                fullWidth
                onChange={this.handleSelectGroupChange}
              >
                {
                  customerGroupTypes.map(item => <MenuItem key={`type-${item.id}`} value={item.id}>{ item.name }</MenuItem>)
                }
              </Select>
            </div>
            <div className={`${gridClasses.Col}`}>
              {isEditing ? (
                <Autocomplete
                  openOnFocus
                  selectOnFocus
                  disableClearable
                  size="small"
                  fullWidth
                  handleHomeEndKeys={false}
                  value={params.type_id === POLICY_PRICE_CUSTOMER_TYPE ? params.getcare_customer : params.getcare_erp_group}
                  onChange={this.handleSelectCustomerChange}
                  options={customerOptions || []}
                  filterOptions={(x) => x}
                  renderOption={(option) => `${option.code} - ${option.name}`}
                  getOptionLabel={(option) => (option && option.code) || ''}
                  getOptionSelected={(option, value) =>
                    value && value.id && option ? option.id === value.id : null
                  }
                  onInputChange={(e, newInputValue) => {
                    this.setState({
                      codeInput: newInputValue,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} error={!this.isRowValid(params)} placeholder="- Chọn -" />
                  )}
                />
              ) : (
                type_id === POLICY_PRICE_CUSTOMER_TYPE ? getcare_customer?.code : getcare_erp_group?.code
              )}
            </div>
            <div className={`${gridClasses.Col}`}>
              {isEditing ? (
                <Autocomplete
                  openOnFocus
                  selectOnFocus
                  disableClearable
                  size="small"
                  fullWidth
                  handleHomeEndKeys={false}
                  value={params.type_id === POLICY_PRICE_CUSTOMER_TYPE ? params.getcare_customer : params.getcare_erp_group}
                  onChange={this.handleSelectCustomerChange}
                  options={customerOptions || []}
                  filterOptions={(x) => x}
                  renderOption={(option) => `${option.code} - ${option.name}`}
                  getOptionLabel={(option) => (option && option.name) || ''}
                  getOptionSelected={(option, value) =>
                    value && value.id && option ? option.id === value.id : null
                  }
                  onInputChange={(e, newInputValue) => {
                    this.setState({
                      nameInput: newInputValue,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} error={!this.isRowValid(params)} placeholder="- Chọn -" />
                  )}
                />
              ) : (
                type_id === POLICY_PRICE_CUSTOMER_TYPE ? getcare_customer?.name : getcare_erp_group?.name
              )}
            </div>
            <div className={`${gridClasses.Col} ${classes.ActionsCol}`}>
              {isEditing ? (
                <>
                  <IconButton
                    onClick={this.handleCancel}
                    style={{ padding: '0' }}
                    size="small"
                  >
                    <CancelOutlined fontSize="small" color="secondary" />
                  </IconButton>
                  <IconButton
                    onClick={this.handleSave}
                    disabled={!this.isRowValid(params)}
                    style={{ padding: '0' }}
                    size="small"
                  >
                    <CheckCircleOutlineOutlined
                      fontSize="small"
                      color={this.isRowValid(params) ? `primary` : `inherit`}
                    />
                  </IconButton>
                </>
              ) : (
                <>
                  <IconButton
                    onClick={this.handleEdit}
                    disabled={readOnly}
                    style={{ padding: '0' }}
                    size="small"
                  >
                    <EditOutlined fontSize="small" color={readOnly ? 'inherit' : 'primary'} />
                  </IconButton>
                </>
              )}
            </div>
          </div>
        </ClickAwayListener>
      </>
    );
  }
}

PolicyPriceCustomer.propTypes = {
  idStr: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currentList: PropTypes.array,
  isSelected: PropTypes.bool,
  changedItem: PropTypes.object,
  savePolicyPriceCustomer: PropTypes.func,
  removePolicyPriceCustomer: PropTypes.func,
  onItemSelectedToggle: PropTypes.func,
};

PolicyPriceCustomer.defaultProps = {
  currentList: [],
  isSelected: false,
  changedItem: null,
};

export default PolicyPriceCustomer;
