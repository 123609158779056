import {
  GET_STORE_DETAILS,
  GET_STORE_LIST,
  SAVE_STORE_DETAILS,
  SAVE_STORE_LIST,
  SAVE_STORE_LIST_TOTAL,
  SAVE_STORE_LIST_LOADING,
  UPDATE_STORE,
  CREATE_STORE,
  EXPORT_STORE_LIST_TEMPLATE,
  APPROVE_STORE_INFO,
  GET_STORE_DELIVERY_ADDRESS_LIST,
  UPDATE_STORE_DELIVERY_ADDRESS,
  SAVE_STORE_DELIVERY_ADDRESS_LIST,
  SAVE_STORE_DELIVERY_ADDRESS,
  SAVE_STORE_ACTION_LOADING,
  DELETE_STORE_DELIVERY_ADDRESS,
  REMOVE_STORE_DELIVERY_ADDRESS,
  SAVE_STORE_ALLOWED_ACTIONS,
} from 'redux/constants/admin/storeConstants';

export function getStoreDetails(payload) {
  return {
    type: GET_STORE_DETAILS,
    payload,
  };
}
export function getStoreList(payload) {
  return {
    type: GET_STORE_LIST,
    payload,
  };
}
export function updateStore(payload) {
  return {
    type: UPDATE_STORE,
    payload,
  };
}
export function createStore(payload) {
  return {
    type: CREATE_STORE,
    payload,
  };
}
export function exportStoreListTemplate(payload) {
  return {
    type: EXPORT_STORE_LIST_TEMPLATE,
    payload,
  };
}

export function getStoreDeliveryAddressList(payload) {
  return {
    type: GET_STORE_DELIVERY_ADDRESS_LIST,
    payload,
  };
}
export function updateStoreDeliveryAddress(payload) {
  return {
    type: UPDATE_STORE_DELIVERY_ADDRESS,
    payload,
  };
}
export function deleteStoreDeliveryAddress(payload) {
  return {
    type: DELETE_STORE_DELIVERY_ADDRESS,
    payload,
  };
}

// mutation
export function saveStore(storeDetails) {
  return {
    type: SAVE_STORE_DETAILS,
    storeDetails,
  };
}
export function saveStoreList(storeList) {
  return {
    type: SAVE_STORE_LIST,
    storeList,
  };
}
export function saveStoreListLoading(isStoreListLoading) {
  return {
    type: SAVE_STORE_LIST_LOADING,
    isStoreListLoading,
  };
}
export function saveStoreActionLoading(isStoreActionLoading) {
  return {
    type: SAVE_STORE_ACTION_LOADING,
    isStoreActionLoading,
  };
}
export function saveStoreListTotal(storeListTotal) {
  return {
    type: SAVE_STORE_LIST_TOTAL,
    storeListTotal,
  };
}
export function approveStoreInfo(payload) {
  return {
    type: APPROVE_STORE_INFO,
    payload,
  };
}

export function saveStoreDeliveryAddressList(deliveryAddressList) {
  return {
    type: SAVE_STORE_DELIVERY_ADDRESS_LIST,
    deliveryAddressList,
  };
}
export function saveStoreDeliveryAddress(deliveryAddress) {
  return {
    type: SAVE_STORE_DELIVERY_ADDRESS,
    deliveryAddress,
  };
}
export function removeStoreDeliveryAddress(deliveryAddress) {
  return {
    type: REMOVE_STORE_DELIVERY_ADDRESS,
    deliveryAddress,
  };
}

export function saveStoreAllowedActions(storeAllowedActions) {
  return {
    type: SAVE_STORE_ALLOWED_ACTIONS,
    storeAllowedActions,
  };
}
