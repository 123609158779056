import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { filterFields, userStatusMap, userApprovalMap } from 'utils/constanst/adminUserConstants';

import FilterField from 'components/FilterField/FilterField';
import Checkboxes from 'components/FilterField/Checkboxes';
import MultiSelect from 'components/FilterField/MultiSelect';

import classes from './UserListFilters.module.scss';

class UserListFilters extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fields: [...filterFields],
    };
  }

  _getFieldIndex = (fieldName) => {
    return this.state.fields.findIndex((item) => item.name === fieldName);
  };

  handleFieldChange = ({ name, value }) => {
    const newFields = this.state.fields.map(field => field.name === name ? { ...field, value } : field);
    this.setState({
      fields: newFields,
    });
    this.props.onFilterChange(newFields, true);
  };

  render() {
    const { fields } = this.state;
    return (
      <div className={classes.Panel}>
        <h3 className={classes.PanelTitle}>Lọc theo:</h3>
        <div className={classes.PanelContent}>
          {fields.map((field) => (
            <FilterField
              key={field.name}
              {...field}
              defaultValue={['status'].includes(field.name) ? [] : ''}
              customField={
                field.name === 'status' ? (
                  <Checkboxes
                    name={field.name}
                    value={field.value}
                    multiple
                    valuesMap={userStatusMap}
                    handleFieldChange={this.handleFieldChange}
                  />
                ) : field.name === 'getcare_department_id' ? (
                  <MultiSelect
                    name={field.name}
                    value={field.value}
                    placeholder={field.placeholder}
                    options={this.props.departmentList}
                    handleFieldChange={this.handleFieldChange}
                  />
                ) : field.name === 'approval' ? (
                  <Checkboxes
                    name={field.name}
                    value={field.value}
                    type="boolean"
                    valuesMap={{
                      [true]: userApprovalMap[true],
                      [false]: userApprovalMap[false],
                    }}
                    handleFieldChange={this.handleFieldChange}
                  />
                ) : ''
              }
              handleFieldChange={this.handleFieldChange}
            />
          ))}
        </div>
      </div>
    );
  }
}

UserListFilters.propTypes = {
  onFilterChange: PropTypes.func,
};

export default UserListFilters;
