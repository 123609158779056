import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import GroupListItem from './GroupListItem/GroupListItem';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';

import gridClasses from './GroupListGrid.module.scss';
import classes from './GroupList.module.scss';

class GroupList extends PureComponent {
  render() {
    const { groupList, isLoading, displayFields } = this.props;

    return <div className={`${classes.List} ${isLoading && 'OverlayLoading'}`}>
        <div
        className={`${classes.Header}`}
        ref={(el) => (this.rowRef = el)}
      >
            <div className={`${gridClasses.Row} ${classes.Header}`}>
          { displayFields.map((item) => (
            <ListHeaderCol
              key={item.name}
              className={gridClasses.Col}
              {...item}
              onSortChange={this.props.onSortChange}
            />
          )) }
        </div>
        </div>

      <div className={`${classes.Body}`}>
      
        { !isLoading && groupList.length
          ? groupList.map(item => (
            <GroupListItem
              key={`group-${item.id}`}
              {...item}
            />))
          : (<p className="NoData">Không có data</p>)
        }
      </div>
    </div>;
  }
}

GroupList.propTypes = {
  displayFields: PropTypes.array,
  groupList: PropTypes.array,
  isLoading: PropTypes.bool,
  onSortChange: PropTypes.func,
};

GroupList.defaultProps = {
  displayFields: [],
  groupList: [],
  isLoading: false,
};

export default GroupList;
