export const FREESHIP_TEXT = 'Freeship';

export const orderListDisplayFields = [
  {
    name: 'getcare_id',
    label: 'Phahub ID',
  },
  {
    name: 'name',
    label: 'Sản phẩm',
  },
  {
    name: 'code',
    label: 'Mã hàng',
  },
  {
    name: 'vendor_name',
    label: 'Vendor',
  },
  {
    name: 'unit',
    label: 'Đơn vị',
  },
  {
    name: 'price',
    label: 'Đơn giá',
    className: 'TextRight',
  },
  {
    name: 'quantiy_number',
    label: 'Số lượng',
  },
  {
    name: 'amount',
    label: 'Thành tiền',
    className: 'TextRight',
  },
  {
    name: 'discount_amount',
    label: 'KM tiền',
    className: 'TextRight',
  },
];
export const orderListParamsmap = {
  page_size: 150,
  page: 1,
  order: '',
}
