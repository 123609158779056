import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectVendorList } from 'redux/selectors';
import { getVendorList } from 'redux/actions/admin/vendorActions';
import { allInPageSize } from 'utils/constanst/common';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import classes from 'views/admin/KeyAccount/KeyAccount.module.scss';

class KeyAccountVendor extends PureComponent {
  componentDidMount() {
    this.loadVendorList();
  }

  loadVendorList = () => {
    this.props.getVendorList({
      params: {
        page_size: allInPageSize,
      },
    });
  }

  render() {
    const {
      values,
      errors,
      setFieldValue,
      handleChange,
      vendorList,
    } = this.props;

    return (
      <div className={classes.SectionWrap}>
        <h4 className={classes.SectionTitle}>Nhà cung cấp</h4>
        <div className={classes.Details}>
          <div className={classes.DetailsCol}>
            <div className={classes.FieldControl}>
              <label>Chỉ định nhà cung cấp *</label>
              <Autocomplete
                disableClearable
                name="getcare_vendor"
                handleHomeEndKeys={false}
                value={values.getcare_vendor}
                options={vendorList || []}
                getOptionLabel={(option) => option?.name || ''}
                renderOption={(option) => option?.name}
                getOptionSelected={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    placeholder="Chọn..."
                    {...params}
                    error={!!errors.getcare_vendor_id}
                    helperText={errors.getcare_vendor_id}
                  />
                )}
                onChange={(e, newValue) => {
                  setFieldValue('getcare_vendor', newValue || null);
                  this.props.onFieldChange({
                    'getcare_vendor_id': newValue?.id || '',
                    'getcare_vendor': newValue || null
                  });
                  handleChange({target: { name: 'getcare_vendor_id', value: newValue?.id || '' }});
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

KeyAccountVendor.propTypes = {
  onFieldChange: PropTypes.func,
};

KeyAccountVendor.defaultProps = {
};

const mapDispatchToProps = (dispatch) => {
  return {
    getVendorList: (payload) => dispatch(getVendorList(payload)),
  };
};
const mapStateToProps = createStructuredSelector({
  vendorList: makeSelectVendorList(),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(KeyAccountVendor);
