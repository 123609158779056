import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { debounce } from 'lodash';
import {
  getProductList,
  deactiveProducts,
  exportProductListTemplate,
  saveProductAllowedActions,
} from 'redux/actions/admin/productActions';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectProductList,
  makeSelectProductTotal,
  makeSelectProductIsLoading,
  makeSelectProductAllowedActions,
} from 'redux/selectors';
import {
  listParamsMap,
  listDisplayFields,
} from 'utils/constanst/adminProductConstants';
import ingredientApi from 'utils/api/admin/ingredientApi';
import uomApi from 'utils/api/admin/uomApi';
import { getSortsString, getDisplayFields } from 'utils/helper';
import authApi from 'utils/api/authApi';
import { isActionAllowed } from 'utils/helper/authorization';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ProductList from 'components/admin/ProductList/ProductList';
import ProductFilters from 'components/admin/ProductList/ProductFilters/ProductFilters';
import ListPagination from 'components/ListPagination/ListPagination';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import TagCategoryDialog from './TagCategoryDialog/TagCategoryDialog';
import TagUOMDialog from './TagUOMDialog/TagUOMDialog';
import FileImportDialog from 'components/admin/ProductList/FileImportDialog/FileImportDialog';
import TagGetcareCategory from 'components/admin/ProductList/TagGetcareCategory/TagGetcareCategory';

import classes from './Products.module.scss';
import { toast } from 'react-toastify';

class Products extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      listParams: { ...listParamsMap },
      selectedIds: [],
      isMoreActionsOpen: false,
      isDialogOpen: false,
      isDialogLoading: false,
      isUOMDialogOpen: false,
      isUOMDialogLoading: false,
      isImportDialogOpen: false,
      isTagGetcareCategoryDialogOpen: false,
    };
    this.moreActionsMenuRef = React.createRef();
  }

  async componentDidMount() {
    await this.getAllowedActions();

    const payload = {
      params: this.state.listParams,
    };
    this.props.getProductList(payload);
  }

  componentDidUpdate(prevProps) {
    const { isLoading, productList } = this.props;
    if (
      !isLoading &&
      isLoading !== prevProps.isLoading &&
      this.state.isDialogOpen
    ) {
      this.handleDialogClose();
    }
    if (
      !isLoading &&
      isLoading !== prevProps.isLoading &&
      this.state.isUOMDialogOpen
    ) {
      this.handleUOMDialogClose();
    }
    const { selectedIds } = this.state;
    if (productList !== prevProps.productList && selectedIds.length > 0) {
      const remainIds = selectedIds.filter((pId) =>
        productList.some((item) => item.id === pId)
      );
      this.setState({
        selectedIds: remainIds,
      });
    }
  }

  getAllowedActions = async () => {
    const { data: response } = await authApi.getAllowedActions({
      component: 'products',
    });
    if (!response.result) {
      toast.error(response.message);
      return;
    }
    this.props.saveProductAllowedActions(response.allowed_actions || []);
  };

  handleFilterChange = (filters, forceResetPage = false) => {
    const paramsFromFilters = filters.reduce((memo, item) => {
      memo[item.name] = item.value;
      return memo;
    }, {});
    const newParams = forceResetPage
      ? {
          ...this.state.listParams,
          ...paramsFromFilters,
          page: 1,
        }
      : {
          ...this.state.listParams,
          ...paramsFromFilters,
        };
    this.setState({
      listParams: newParams,
    });
    this.props.getProductList({
      params: newParams,
    });
  };

  handleSortChange = ({ sortBy, sortDir }) => {
    const newSortString = getSortsString({ [sortBy]: sortDir });
    this.handleFilterChange([
      {
        name: 'order',
        value: newSortString,
      },
    ]);
  };

  handleAllSelectedToggle = (e) => {
    const allIds = this.props.productList.map((item) => item.id);
    this.setState({
      selectedIds: e.target.checked ? allIds : [],
    });
  };

  handleItemSelectedToggle = ({ id, isSelected }) => {
    const remainIds = this.state.selectedIds.filter((item) => item !== id);
    this.setState({
      selectedIds: isSelected ? [...remainIds, id] : remainIds,
    });
  };

  handleDialogOpen = () => {
    this.setState({
      isDialogOpen: true,
    });
    this.handleCloseDropdown();
  };
  handleDialogClose = () => {
    this.setState({
      isDialogOpen: false,
    });
  };
  handleSubmitTagCategory = async ({ id, name, content }) => {
    const { selectedIds } = this.state;
    if (!selectedIds.length) return;
    this.setState({
      isDialogLoading: true,
    });
    const { data: response } = await ingredientApi.tagCategoryToProducts({
      params: {
        id,
        name,
        content,
        getcare_product_ids: [...selectedIds],
      },
    });
    this.setState({
      isDialogLoading: false,
    });
    if (!response || !response.result) {
      toast.error(response.message);
      return;
    }
    this.setState({
      isDialogOpen: false,
    });
    this.props.getProductList({
      params: this.state.listParams,
    });
  };

  handleToggleDropdown = () => {
    this.setState({
      isMoreActionsOpen: !this.state.isMoreActionsOpen,
    });
  };
  handleCloseDropdown = () => {
    this.setState({
      isMoreActionsOpen: false,
    });
  };

  handleProductCategorySelectDialogOpen = () => {
    this.setState({
      isTagGetcareCategoryDialogOpen: true,
    });
  }
  handleTagGetcareCategoryDialogClose = () => {
    this.setState({
      isTagGetcareCategoryDialogOpen: false,
    });
  }
  handleSubmitSuccessTagGetcareCategory = () => {
    this.props.getProductList({
      params: this.state.listParams,
    });
  }

  handleUOMDialogOpen = () => {
    this.setState({
      isUOMDialogOpen: true,
    });
    this.handleCloseDropdown();
  };
  handleUOMDialogClose = () => {
    this.setState({
      isUOMDialogOpen: false,
    });
  };
  handleSubmitTagUOM = async (params) => {
    const { selectedIds } = this.state;
    if (!selectedIds.length) return;
    this.setState({
      isUOMDialogLoading: true,
    });
    const { data: response } = await uomApi.tagUOMSToProducts({
      params: {
        ...params,
        product_ids: [...selectedIds],
      },
    });
    this.setState({
      isUOMDialogLoading: false,
    });
    if (!response || !response.result) {
      toast.error(response.message);
      return;
    }
    this.setState({
      isUOMDialogLoading: false,
    });
    this.props.getProductList({
      params: this.state.listParams,
    });
  };
  handleDownloadTemplate = () => {
    this.props.exportProductListTemplate();
  };
  handleImportDialogOpen = () => {
    this.setState({
      isImportDialogOpen: true,
    });
  };
  handleImportDialogClose = () => {
    this.setState({
      isImportDialogOpen: false,
    });
  };
  handleCompleteImport = () => {
    // Reload the page with current settings (e.g.. filters)
    this.props.getProductList({
      params: this.state.listParams,
    });
  };

  render() {
    const { productList, total, isLoading, productAllowedActions } = this.props;
    const {
      listParams,
      selectedIds,
      isMoreActionsOpen,
      isDialogOpen,
      isDialogLoading,
      isUOMDialogOpen,
      isUOMDialogLoading,
      isImportDialogOpen,
      isTagGetcareCategoryDialogOpen,
    } = this.state;
    const hasSelected = selectedIds.length > 0;
    const isListLoading = isLoading && !isDialogOpen && !isUOMDialogOpen;
    //const isCreateAllowed = isActionAllowed('create', productAllowedActions);
    const isEditAllowed = isActionAllowed('edit', productAllowedActions);
    const isImportAllowed = isActionAllowed('import', productAllowedActions);
    
    return (
      <React.Fragment>
        <div className={classes.PageWrap}>
          <div className={classes.PageHeader}>
            <h1 className={classes.PageTitle}>
              Danh sách sản phẩm đang hoạt động
            </h1>
            <div className={classes.PageActions}>
              { isImportAllowed && (<>
                <Button size="small" variant="contained" onClick={this.handleDownloadTemplate}>
                  Download template
                </Button>
                <Button size="small" variant="contained" onClick={this.handleImportDialogOpen}>
                  Import File
                </Button>
              </>)}
              { isEditAllowed && (<>
                <IconButton
                  size="small"
                  ref={this.moreActionsMenuRef}
                  className={`${classes.MoreActionBtn} ${
                    hasSelected && classes.Active
                  }`}
                  aria-controls={isMoreActionsOpen ? 'menu-list-grow' : undefined}
                  aria-haspopup="true"
                  onClick={this.handleToggleDropdown}
                  style={{ padding: '6px' }}
                >
                  <MoreVertIcon />
                </IconButton>
                <Popper
                  open={isMoreActionsOpen}
                  anchorEl={this.moreActionsMenuRef.current}
                  role={undefined}
                  transition
                  disablePortal
                  style={{ zIndex: 3 }}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === 'bottom' ? 'left top' : 'left bottom',
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={this.handleCloseDropdown}>
                          <MenuList
                            id="menu-list-grow"
                            autoFocusItem={isMoreActionsOpen}
                          >
                            <MenuItem disabled>Dừng hoạt động</MenuItem>
                            <MenuItem onClick={this.handleDialogOpen}>
                              Tag hoạt chất
                            </MenuItem>
                            <MenuItem onClick={this.handleUOMDialogOpen}>
                              Tag đơn vị tính
                            </MenuItem>
                            <MenuItem onClick={this.handleProductCategorySelectDialogOpen}>
                              Tag danh mục
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </>)}
            </div>
          </div>
          <div className={classes.PageMain}>
            <ProductFilters
              onFilterChange={debounce(this.handleFilterChange, 50)}
            />
            <ProductList
              displayFields={getDisplayFields(listParams, listDisplayFields)}
              productList={productList}
              isLoading={isListLoading}
              selectedIds={selectedIds}
              isEditAllowed={isEditAllowed}
              onSortChange={this.handleSortChange}
              onAllSelectedToggle={this.handleAllSelectedToggle}
              onItemSelectedToggle={this.handleItemSelectedToggle}
            />
          </div>
          <div className={classes.PageFooter}>
            <ListPagination
              page={listParams.page}
              pageSize={listParams.page_size}
              total={total}
              listName="sản phẩm"
              onFilterChange={this.handleFilterChange}
            />
          </div>
        </div>
        {isDialogOpen && (
          <TagCategoryDialog
            isOpen={isDialogOpen}
            isLoading={isDialogLoading}
            onClose={this.handleDialogClose}
            category={this.state.listParams.l6_name || ''}
            onSubmit={this.handleSubmitTagCategory}
          />
        )}
        {isUOMDialogOpen && (
          <TagUOMDialog
            isOpen={isUOMDialogOpen}
            isLoading={isUOMDialogLoading}
            onClose={this.handleUOMDialogClose}
            onSubmit={this.handleSubmitTagUOM}
          />
        )}
        {isImportDialogOpen && (
          <FileImportDialog
            title="Import danh sách sản phẩm"
            isOpen={isImportDialogOpen}
            onClose={this.handleImportDialogClose}
            onCompleteImport={this.handleCompleteImport}
          />
        )}
        {isTagGetcareCategoryDialogOpen && (
          <TagGetcareCategory
            isOpen={isTagGetcareCategoryDialogOpen}
            onClose={this.handleTagGetcareCategoryDialogClose}
            productIds={selectedIds}
            onSubmitSuccess={this.handleSubmitSuccessTagGetcareCategory}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  productList: makeSelectProductList(),
  total: makeSelectProductTotal(),
  isLoading: makeSelectProductIsLoading(),
  productAllowedActions: makeSelectProductAllowedActions(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getProductList: (payload) => dispatch(getProductList(payload)),
    deactiveProducts: (payload) => dispatch(deactiveProducts(payload)),
    exportProductListTemplate: (payload) => dispatch(exportProductListTemplate(payload)),
    saveProductAllowedActions: (payload) => dispatch(saveProductAllowedActions(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(Products);
