import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';

import classes from 'views/admin/KeyAccount/KeyAccount.module.scss';

class KeyAccountDetails extends PureComponent {
  render() {
    const {
      values,
      errors,
      handleChange,
    } = this.props;

    return (
      <div className={classes.Details}>
        <div className={classes.DetailsCol}>
          <div className={classes.FieldControl}>
            <label>Tên đặc quyền bán *</label>
            <TextField
              autoComplete="off"
              value={values.name}
              placeholder="Nhập..."
              autoFocus={true}
              name="name"
              error={!!errors.name}
              helperText={errors.name}
              onChange={(e) => {
                handleChange(e);
                this.props.onFieldChange({'name': e.target.value});
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

KeyAccountDetails.propTypes = {
  isEditing: PropTypes.bool,
  onFieldChange: PropTypes.func,
};

KeyAccountDetails.defaultProps = {
  isEditing: false,
};

export default KeyAccountDetails;
