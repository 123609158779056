import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import ingredientApi from 'utils/api/admin/ingredientApi';
import { saveQueueIngredientItem } from 'redux/actions/admin/ingredientActions';
import { uniqBy } from 'lodash';
import { suggestionPageSize } from 'utils/constanst/common';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import EditOutlined from '@material-ui/icons/EditOutlined';
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import IconButton from '@material-ui/core/IconButton';

import gridClasses from '../QueueIngredientListGrid.module.scss';
import classes from './QueueIngredientListItem.module.scss';

class NameSelector extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      categoryOptions: [],
      selectedCategory: null,
      categoryInput: '',
      isEditing: props.isEditing,
      highlightedOption: null,
    }
  }
  componentDidMount() {
    this._initState(this.props);
  }
  _initState = (params) => {
    const { code, name, fieldName, isEditing } = params;
    if (code || name) {
      this.setState({
        isEditing: isEditing,
        categoryInput: name,
        categoryOptions: [{
          [`${fieldName}_code`]: code,
          [`${fieldName}_name`]: name,
        }],
        selectedCategory: {
          [`${fieldName}_code`]: code,
          [`${fieldName}_name`]: name,
        },
      });
    }
  }
  _isCategorySelected = () => {
    const { categoryOptions, categoryInput } = this.state;
    const { fieldName } = this.props;
    return !categoryInput || categoryOptions.some(
      (item) => item[`${fieldName}_name`].toLowerCase() === categoryInput.trim().toLowerCase()
    );
  }
  componentDidUpdate(prevProps, prevState) {
    const { categoryInput, isEditing } = this.state;
    if (isEditing && categoryInput && categoryInput !== prevState.categoryInput) {
      this.loadCategories({ [`${this.props.fieldName}_name`]: categoryInput });
    }
  }
  loadCategories = debounce(async (params) => {
    const { data: response } = await ingredientApi.getAll({
      params: { page: 1, page_size: suggestionPageSize, ...params },
    });
    if (!response?.result) return;
    const dataArr = uniqBy(response.data, `${this.props.fieldName}_name`);
    this.setState({
      categoryOptions: response.data ? dataArr : [],
    });
  }, 500);
  isValid = () => {
    const { freeSolo } = this.props;
    return !!this.state.selectedCategory || (freeSolo && !!this.state.categoryInput);
  }
  handleEdit = () => {
    this.setState({
      isEditing: true,
    });
  }
  handleKeyDown = (e) => {
    const { categoryInput, highlightedOption, selectedCategory } = this.state;
    if (e.key === 'Enter') {
      if (this.props.freeSolo && categoryInput) {
        // prevent clear the category input in autocomplete component
        if (!highlightedOption) {
          e.stopPropagation();
          if (this.state.isEditing && this.isValid()) {
            this.handleSave();
          }
        }
        if (highlightedOption && selectedCategory && this.state.isEditing && this.isValid()) {
          this.handleSave();
        }
        return;
      }
      if (!this.props.freeSolo && selectedCategory && this.state.isEditing && this.isValid()) {
        this.handleSave();
      }
    }
  }
  handleCancel = () => {
    const { code, name, fieldName } = this.props;
    this.setState({
      isEditing: false,
      categoryOptions: (code || name) ? [{
        [`${fieldName}_code`]: code,
        [`${fieldName}_name`]: name,
      }] : [],
      selectedCategory: (code || name) ? {
        [`${fieldName}_code`]: code,
        [`${fieldName}_name`]: name,
      } : null,
    });
  }
  handleChange = (e, value) => {
    this.setState({
      selectedCategory: value,
    });
  }
  handleBlur = () => {
    if (this.state.isEditing && this.isValid()) {
      this.handleSave();
    }
  }
  handleSave = () => {
    const { fieldName, freeSolo, showCode } = this.props;
    const { selectedCategory, categoryInput } = this.state;
    if (!selectedCategory && !categoryInput) return;
    if (freeSolo
      && categoryInput
      && (!selectedCategory || (selectedCategory && categoryInput !== selectedCategory[`${fieldName}_name`]))
    ) {
      // free name was inputed without item selected
      this.setState({
        selectedCategory: {
          [`${fieldName}_code`]: '',
          [`${fieldName}_name`]: categoryInput,
        }
      });
      this.props.saveQueueIngredientItem({
        id: this.props.id,
        [`${fieldName}_code`]: '',
        [`${fieldName}_name`]: categoryInput,
      });
      this.setState({
        isEditing: false,
      });
      return;
    }
    // name was selected from the suggestion list
    this.props.saveQueueIngredientItem({
      id: this.props.id,
      [`${fieldName}_code`]: showCode ? selectedCategory[`${fieldName}_code`] : '',
      [`${fieldName}_name`]: selectedCategory[`${fieldName}_name`],
    });
    this.setState({
      isEditing: false,
    });
  };
  handleHighlightChange = (e, option) => {
    this.setState({
      highlightedOption: option,
    });
  }

  render() {
    const {
      isEditing,
      selectedCategory,
      categoryOptions,
    } = this.state;
    const { fieldName, freeSolo, showCode } = this.props;

    return (<>
      { showCode && <div className={gridClasses.Col}>{ (selectedCategory && selectedCategory[`${fieldName}_code`]) || '' }</div> }
      <ClickAwayListener onClickAway={this.handleBlur}>
        <div className={`${gridClasses.Col} ${gridClasses.EditInlineCol}`}>
          { isEditing ? (<>
            <Autocomplete
              className={gridClasses.EditInlineField}
              freeSolo={freeSolo}
              selectOnFocus
              disableClearable
              size="small"
              handleHomeEndKeys={false}
              value={selectedCategory || null}
              onInputChange={(e, newInputValue) => {
                this.setState({
                  categoryInput: newInputValue,
                });
              }}
              options={categoryOptions || []}
              filterOptions={(x) => x}
              getOptionLabel={(option) => (option && option[`${fieldName}_name`]) || ''}
              renderOption={(option) => (option
                && `${showCode ? option[`${fieldName}_code`] + '-' : ''}${option[`${fieldName}_name`]}`
              )}
              getOptionSelected={(option, value) =>
                (value
                  && value[`${fieldName}_name`] === option[`${fieldName}_name`]
                  && value[`${fieldName}_code`] === option[`${fieldName}_code`])
                || null
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!this.isValid()}
                  className={
                    this._isCategorySelected() ? '' : classes.InValid
                  }
                  autoFocus
                  placeholder="- Chọn -"
                  onKeyDown={this.handleKeyDown}
                />
              )}
              onChange={this.handleChange}
              onHighlightChange={this.handleHighlightChange}
              onOpen={() => {
                this.setState({
                  highlightedOption: null,
                });
              }}
            />

            <IconButton
              onClick={this.handleCancel}
              style={{ padding: '0' }}
              size="small"
            ><CancelOutlined fontSize="small" color="secondary"/></IconButton>
            <IconButton
              onClick={this.handleSave}
              style={{ padding: '0' }}
              size="small"
              disabled={!this.isValid()}
            ><CheckCircleOutlineOutlined fontSize="small" color={ this.isValid() ? `primary` : `inherit`}/></IconButton>
          </>)
          : (<>
            <label
              className={`${gridClasses.EditInlineField} ${this._isCategorySelected() ? '' : classes.InValid}`}
              onClick={this.handleEdit}
              style={{ cursor: 'pointer' }}
            >
              { selectedCategory && selectedCategory[`${fieldName}_name`] }
            </label>
            <IconButton
              onClick={this.handleEdit}
              style={{ padding: '0' }}
              size="small"
            ><EditOutlined fontSize="small" color="primary"/></IconButton>
          </>)
        }
      </div>
    </ClickAwayListener>
    </>);
  }
}

NameSelector.propTypes = {
  fieldName: PropTypes.string,
  name: PropTypes.string,
  code: PropTypes.string,
  id: PropTypes.number,
  freeSolo: PropTypes.bool,
  isEditing: PropTypes.bool,
  showCode: PropTypes.bool,
};

NameSelector.defaultProps = {
  freeSolo: false,
  isEditing: false,
  showCode: false,
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveQueueIngredientItem: (payload) => dispatch(saveQueueIngredientItem(payload)),
  };
};
const withConnect = connect(null, mapDispatchToProps);
export default compose(withConnect)(NameSelector);

