export const GET_INGREDIENT_LIST = 'GET_INGREDIENT_LIST';
export const GET_QUEUE_INGREDIENT_LIST = 'GET_QUEUE_INGREDIENT_LIST';
export const UPDATE_QUEUE_INGREDIENT_ITEM = 'UPDATE_QUEUE_INGREDIENT_ITEM';
export const APPROVE_QUEUE_INGREDIENT_ITEM = 'APPROVE_QUEUE_INGREDIENT_ITEM';
export const DELETE_QUEUE_INGREDIENT_LIST = 'DELETE_QUEUE_INGREDIENT_LIST';

// mutation
export const SAVE_INGREDIENT_LIST = 'SAVE_INGREDIENT_LIST';
export const SAVE_INGREDIENT_TOTAL = 'SAVE_INGREDIENT_TOTAL';
export const SAVE_QUEUE_INGREDIENT_LIST = 'SAVE_QUEUE_INGREDIENT_LIST';
export const SAVE_QUEUE_INGREDIENT_TOTAL = 'SAVE_QUEUE_INGREDIENT_TOTAL';
export const SAVE_QUEUE_INGREDIENT_ITEM = 'SAVE_QUEUE_INGREDIENT_ITEM';
export const REMOVE_QUEUE_INGREDIENT_ITEM = 'REMOVE_QUEUE_INGREDIENT_ITEM';
export const SAVE_LOADING = 'SAVE_LOADING';