import shippingCouponApi from 'utils/api/admin/shippingCouponApi';
import { put } from 'redux-saga/effects';
import { testShippingCouponActions } from 'redux/actions';
import { toast } from 'react-toastify';

export function* testShippingCoupon(payload) {
  const { params } = payload.payload;
  try {
    yield put(testShippingCouponActions.saveLoading(true));

    const { data: response } = yield shippingCouponApi.checkShippingCoupon({ params });
    yield put(testShippingCouponActions.saveLoading(false));
    if (!response?.result || !response?.data) {
      toast.error(response.message);
      return;
    }
    yield put(
      testShippingCouponActions.saveShippingCouponAvailables(response.data.shipping_coupon_order_vendor_items)
    );
    yield put(
      testShippingCouponActions.saveShippingCouponAmount({
        shipping_coupon_amount: response.data.shipping_coupon_amount,
        freeship: response.data.freeship,
      })
    );
  } catch (err) {
    console.log(err);
  }
}
