import {
  SAVE_LOADING,
  SAVE_ORDER_LIST,
  SAVE_ORDER_LIST_ITEM,
  SAVE_SHIPPING_COUPON_AVAILABLES,
  REMOVE_ORDER_LIST_ITEMS,
  SAVE_SHIPPING_COUPON_AMOUNT,
} from 'redux/constants/admin/testShippingCouponConstants';
import { genID } from 'utils/helper';

const initialState = {
  loading: false,
  orderList: [],
  shippingCouponAvailables: [],
  shippingCouponAmount: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_LOADING: {
      return {
        ...state,
        loading: action.loading,
      };
    }

    case SAVE_ORDER_LIST: {
      const newList = action.orderList.map(item => ({
        ...item,
        idStr: genID(),
      }));
      return {
        ...state,
        orderList: newList ? [...newList] : [],
      };
    }
    case SAVE_ORDER_LIST_ITEM: {
      const list = [...state.orderList];
      const comparedField = action.orderListItem.idStr
        ? 'idStr'
        : 'id';
      const index = list.findIndex((p) => p[comparedField] === action.orderListItem[comparedField]);
      if (index > -1) {
        const tempItem = { ...list[index] };
        list[index] = { ...tempItem, ...action.orderListItem };
      } else {
        list.push({ ...action.orderListItem });
      }
      return {
        ...state,
        orderList: list,
      };
    }
    case REMOVE_ORDER_LIST_ITEMS: {
      const list = state.orderList.filter(
        (p) =>
          !action.orderListItems.some((item) => {
            const comparedField = item.idStr ? 'idStr' : 'id';
            return item[comparedField] === p[comparedField];
          })
      );
      return {
        ...state,
        orderList: list,
      };
    }

    case SAVE_SHIPPING_COUPON_AVAILABLES: {
      const list = action.shippingCouponAvailables;
      return {
        ...state,
        shippingCouponAvailables: list ? [...list] : [],
      };
    }

    case SAVE_SHIPPING_COUPON_AMOUNT: {
      return {
        ...state,
        shippingCouponAmount: {...action.shippingCouponAmount},
      };
    }

    default:
      return state;
  }
};

export default reducer;
