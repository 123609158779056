import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import Panel from 'components/Panel/Panel';
import CommonInfo from './CommonInfo/CommonInfo';
import UOMInfo from './UOMInfo/UOMInfo';
import ProductIngredients from './ProductIngredients/ProductIngredients';
import ProductManufacturer from './ProductManufacturer/ProductManufacturer';
import OtherInfo from './OtherInfo/OtherInfo';

import classes from './ProductDetails.module.scss';

class ProductDetails extends PureComponent {
  openInfoPage = () => {
    const query = this.props.productDetails.getcare_product_active_ingredients?.reduce((memo, item) => {
      const name = item?.getcare_active_ingredients?.l6_name || '';
      return `${memo}+${name.replace(/\s/gi, '+')}`;
    }, '') || '';
    if (!query) {
      window.open(`https://vn.download.trobz.com/getcare/uptodate/index.htm`, '_blank');
      return;
    }
    window.open(`https://vn.download.trobz.com/getcare/uptodate/d/search.htm?s=${query}`, '_blank');
  }

  render() {
    const { productDetails, isEditAllowed } = this.props;

    return productDetails && (
      <div className={classes.ProductDetails}>
        <Panel
          title="Thông tin chung"
          titleAction={
            <Button
              size="small"
              variant="contained"
              color="default"
              startIcon={<InfoOutlined />}
              onClick={this.openInfoPage}
            >
              Thông tin thuốc
            </Button>
          }
          content={
            <CommonInfo
              thumbnail={productDetails.thumbnail}
              registration_number={productDetails.registration_number}
              getcare_product_price_decralations={productDetails.getcare_product_price_decralations}
              name={productDetails.name}
              expiry_date={productDetails.expiry_date}
              expiry_date_unit={productDetails.expiry_date_unit}
              dosage_forms={productDetails.dosage_forms}
              getcare_id={productDetails.getcare_id}
              id={productDetails.id}
              code={productDetails.code}
              getcare_product_category_ecom_items={productDetails.getcare_product_category_ecom_items}
              isEditAllowed={isEditAllowed}
            />
          }
          isBorder
        />
        <Panel
          title="Thông tin đóng gói"
          content={
            <UOMInfo
              getcare_uom={productDetails.getcare_uom}
              isEditAllowed={isEditAllowed}
            />
          }
          isBorder
        />
        <div className={classes.TwoCols}>
          <Panel
            title="Thành phần"
            content={
              <ProductIngredients
                productId={productDetails.id}
                active_ingredients_main={productDetails.active_ingredients_main}
                getcare_product_active_ingredients={
                  productDetails.getcare_product_active_ingredients
                }
                isEditAllowed={isEditAllowed}
              />
            }
            isBorder
          />
          <Panel
            title="Thông tin sản xuất"
            content={<ProductManufacturer getcare_manufacturer={productDetails.getcare_manufacturer} />}
            isBorder
          />
        </div>
        <Panel
          title="Thông tin khác"
          content={
            <OtherInfo
              product_id={productDetails.id}
              is_new={productDetails.is_new}
              is_selling={productDetails.is_selling}
              is_popular={productDetails.is_popular}
              isEditAllowed={isEditAllowed}
            />
          }
          isBorder
        />
      </div>
    );
  }
}

ProductDetails.propTypes = {
  productDetails: PropTypes.object,
};

ProductDetails.defaultProps = {
  productDetails: null,
};

export default ProductDetails;
