import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FileUpload from 'components/FileUpload/FileUpload';

import classes from './FileImportDialog.module.scss';

class FileImportDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isFirstRowIncluded: false,
      file: null,
    };
  }
  _resetState = () => {
    this.setState({
      isFirstRowIncluded: false,
      file: null,
    });
  }
  isFormValid = () => {
    return !!this.state.file;
  }
  handleFirstRowCheckboxChange = (e) => {
    this.setState({
      isFirstRowIncluded: e.target.checked,
    });
  }
  handleSubmit = async () => {
    this.props.onSubmitImport({
      file: this.state.file,
      start_row: this.state.isFirstRowIncluded ? 1 : undefined,
    });
  };
  onClose = () => {
    this.props.onClose();
  };
  onEntered = () => {
    this._resetState();
  };
  handleFilesAdded = (files) => {
    this.setState({
      file: files[0],
    });
  }

  render() {
    const { isOpen, title, isLoading, importResult } = this.props;
    const { isFirstRowIncluded } = this.state;

    return (
      <Dialog
        open={isOpen}
        onClose={this.onClose}
        onEntered={this.onEntered}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.Dialog}
        disableBackdropClick
      >
        <div className={`${classes.DialogContentWrap} ${isLoading && 'OverlayLoading'}`}>
          { !importResult ? (<>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
              <div className={classes.FieldControl}>
                <FileUpload onFilesAdded={this.handleFilesAdded}/>

                <div className={classes.HelpBlock}>
                  <p className={classes.FileName}>{this.state.file?.name}</p>
                  <ul className={classes.HelpText}>
                    <li>- Hỗ trợ import file xlsx/xsl và csv. Tối đa 5MB</li>
                    <li>- Sau khi import thành công, danh sách import sẽ ghi đè lên danh sách hiện có</li>
                    <li>- Vui lòng kiểm tra kỹ data trên file trước khi thực hiện import</li>
                  </ul>
                </div>
              </div>
              <div className={classes.FieldControl}>
                <FormControlLabel
                  control={<Checkbox checked={isFirstRowIncluded} onChange={this.handleFirstRowCheckboxChange} value={true} name="active" />}
                  label="Import cả dòng đầu tiên"
                />
              </div>
            </DialogContent>
            <DialogActions className={classes.DialogActions}>
              <Button variant="outlined" onClick={this.props.onClose}>
                Huỷ
              </Button>
              <Button
                disabled={!this.isFormValid()}
                variant="contained"
                onClick={this.handleSubmit}
                color="primary"
                autoFocus
              >
                Import
              </Button>
            </DialogActions>
          </>) : (<>
            <DialogTitle>
              {`${title} ${importResult.isSuccess ? 'thành công' : 'thất bại'}`}
            </DialogTitle>
            <DialogContent>
              <p className={classes.Info}><label>Tổng số dòng xử lý: </label> { importResult.totalRecords }</p>
              { importResult.isSuccess ? (
                  <p className={`${classes.Info} ${classes.Success}`}><label>Tổng số dòng import thành công:</label> { importResult.successRecords }</p>
                ) : (<p className={`${classes.Info} ${classes.Error}`}>Không thể xử lý file này. Xin hãy kiểm tra bộ dữ liệu này.</p>)
              }
            </DialogContent>
            <DialogActions className={classes.DialogActions}>
              <Button variant="contained" color="primary" onClick={this.props.onClose}>
                OK
              </Button>
            </DialogActions>
          </>) }
        </div>
      </Dialog>
    );
  }
}

FileImportDialog.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  isLoading: PropTypes.bool,
  importResult: PropTypes.object,
  onSubmitImport: PropTypes.func,
};

FileImportDialog.defaultProps = {
  isOpen: false,
  title: '',
  isLoading: false,
  importResult: null,
};

export default FileImportDialog;
