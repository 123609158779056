import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import ShippingCouponListItem from './ShippingCouponListItem/ShippingCouponListItem';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';

import gridClasses from './ShippingCouponListGrid.module.scss';
import classes from './ShippingCouponList.module.scss';

class ShippingCouponList extends PureComponent {
  render() {
    const { shippingCouponList, isLoading, displayFields } = this.props;

    return <div className={`${classes.List} ${isLoading && 'OverlayLoading'}`}>
            <div
        className={`${classes.Header}`}
        ref={(el) => (this.rowRef = el)}
      >
            <div className={`${gridClasses.Row} ${classes.Header}`}>
          { displayFields.map((item) => (
            <ListHeaderCol
              key={item.name}
              className={gridClasses.Col}
              {...item}
              onSortChange={this.props.onSortChange}
              
            />
          )) }
        </div>
        </div>

      <div className={`${classes.Body}`}>
      
        { !isLoading && shippingCouponList.length
          ? shippingCouponList.map(item => (
            <ShippingCouponListItem
              key={`promotion-${item.id}`}
              {...item}
            />))
          : (<p className="NoData">Không có data</p>)
        }
      </div>
    </div>;
  }
}

ShippingCouponList.propTypes = {
  displayFields: PropTypes.array,
  shippingCouponList: PropTypes.array,
  isLoading: PropTypes.bool,
  onSortChange: PropTypes.func,
};

ShippingCouponList.defaultProps = {
  displayFields: [],
  shippingCouponList: [],
  isLoading: false,
};

export default ShippingCouponList;
