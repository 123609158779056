import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { toast } from 'react-toastify';

import {
  getGroup,
  resetGroup,
  updateGroup,
} from 'redux/actions/admin/groupActions';
import {
  PRODUCT_TYPE,
  SALES_TYPE,
  VENDOR_TYPE,
  INACTIVE,
  CUSTOMER_BY_LIST_TYPE,
  CUSTOMER_BY_CHANNEL_TYPE,
} from 'utils/constanst/adminGroupConstants';
import {
  makeSelectGroupInfo,
  makeSelectGroupLoading,
  makeSelectGroupProducts,
  makeSelectGroupVendors,
  makeSelectGroupCustomers,
  makeSelectGroupCustomerMappings,
} from 'redux/selectors';
import groupApi from 'utils/api/admin/groupApi';

import Button from '@material-ui/core/Button';
import ArrowBack from '@material-ui/icons/ArrowBack';
import CheckOutlined from '@material-ui/icons/CheckOutlined';
import GroupInfo from 'components/admin/GroupDetails/GroupInfo/GroupInfo';
import GroupProducts from 'components/admin/GroupDetails/GroupProducts/GroupProducts';
import GroupVendors from 'components/admin/GroupDetails/GroupVendors/GroupVendors';
import GroupCustomers from 'components/admin/GroupDetails/GroupCustomers/GroupCustomers';
import GroupChannelCustomers from 'components/admin/GroupDetails/GroupChannelCustomers/GroupChannelCustomers';

import classes from './Group.module.scss';

class Group extends PureComponent {
  constructor(props) {
    super(props);
    this.validate = {
      code: {
        template: /^[a-zA-Z0-9]*$/,
        required: true,
        maxLength: 20,
      },
      name: {
        required: true,
        maxLength: 55,
      },
      description: {
        maxLength: 255,
      },
      getcare_erp_group_type: {
        required: true,
      },
    };
  }
  componentDidMount() {
    const { id } = this.props.match.params;
    this.loadData(id);
  }
  componentDidUpdate(prevProps) {
    const { id } = this.props.match.params;
    if (id !== prevProps.match.params.id) {
      this.loadData(id);
    }

    const { groupInfo } = this.props;
    if (groupInfo && groupInfo.id) {
      if (!prevProps.groupInfo || groupInfo.id !== prevProps.groupInfo.id) {
        if (
          !prevProps.groupInfo ||
          !prevProps.groupInfo.id ||
          isNaN(prevProps.groupInfo.id)
        ) {
          // created successfull
          this.props.history.push(`/group/${groupInfo.id}`);
          return;
        }
      }
    }
  }
  loadData = (id) => {
    if (id && !isNaN(id) && Number(id) > 0) {
      this.props.getGroup(id);
    }
    if (!id || isNaN(id) || Number(id) <= 0) {
      this.props.resetGroup();
    }
  };
  backToList = () => {
    this.props.history.push('/group');
  };
  isEditing = () => {
    const { id } = this.props.match.params;
    return id && !isNaN(id) && Number(id) > 0;
  };
  isCodeValid = () => {
    if (this.isEditing()) return true;
    const value = this.props.groupInfo?.code || '';
    return (
      value.trim() !== '' &&
      this.validate.code.template.test(value) &&
      value.trim().length <= this.validate.code.maxLength
    );
  };
  isCodeDuplicated = async (codeStr) => {
    if (this.isEditing() || !codeStr) return [];
    let messages = [];
    const value = codeStr.trim();
    const { data: response } = await groupApi.getAll({
      params: {
        code: value,
        page_size: 1,
        page: 1,
      }
    });
    if (
      response?.result &&
      response.data?.filter(item => item.code.toLowerCase() === value.toLowerCase()).length > 0
    ) {
      messages.push('• Mã nhóm bị trùng');
    }
    return messages;
  }
  isNameValid = () => {
    const value = this.props.groupInfo?.name || '';
    return (
      value.trim() !== '' && value.trim().length <= this.validate.name.maxLength
    );
  };
  isDescriptionValid = () => {
    const value = this.props.groupInfo?.description || '';
    return value.trim().length <= this.validate.description.maxLength;
  };
  isTypeValid = () => {
    const value = this.props.groupInfo?.getcare_erp_group_type || null;
    return !!value;
  };
  _getGroupType = () => {
    return this.props.groupInfo?.getcare_erp_group_type?.id || null;
  }
  _getGroupCustomerType = () => {
    return this.props.groupInfo?.getcare_erp_group_type_customer?.id || null;
  }
  isGroupEmpty = () => {
    const {
      groupProducts,
      groupVendors,
      groupCustomers,
      groupCustomerMappings,
    } = this.props;
    const groupType = this._getGroupType();
    const groupCustomerType = this._getGroupCustomerType();
    return !groupType || !(
      (groupType === PRODUCT_TYPE && groupProducts.length) ||
      (groupType === VENDOR_TYPE && groupVendors.length) ||
      (groupType === SALES_TYPE && groupCustomerType === CUSTOMER_BY_LIST_TYPE && groupCustomers.length) ||
      (groupType === SALES_TYPE && groupCustomerType === CUSTOMER_BY_CHANNEL_TYPE && groupCustomerMappings.length)
    );
  };
  isValid = () => {
    return (
      this.isNameValid() &&
      this.isCodeValid() &&
      this.isDescriptionValid() &&
      this.isTypeValid() &&
      !this.isGroupEmpty()
    );
  };
  isInactive = () => {
    return this.props.groupInfo?.active === INACTIVE || false;
  };
  hasWarningMessages = async () => {
    const codeDuplicatedMsgs = await this.isCodeDuplicated(this.props.groupInfo?.code);
    const messages = [
      ...codeDuplicatedMsgs,
    ];
    if (messages.length) {
      toast.error(messages.join('\n'));
      return true;
    }
    return false;
  }
  handleSave = async () => {
    const hasErrors = await this.hasWarningMessages();
    if (hasErrors) return;

    const { groupInfo } = this.props;

    this.props.updateGroup({
      id: groupInfo?.id || undefined,
      params: {
        code_items: groupInfo?.code_items,
        name: groupInfo?.name,
        code: groupInfo?.code,
        description: groupInfo?.description,
        getcare_erp_group_type_id: groupInfo?.getcare_erp_group_type?.id,
        getcare_erp_group_type_customer_id:
          groupInfo?.getcare_erp_group_type_customer?.id,
        getcare_customer_type_ids:
          groupInfo?.getcare_customer_type_ids?.length > 0
            ? [...groupInfo.getcare_customer_type_ids]
            : undefined,
      },
    });
  };
  handleTabGroupCustomersChange = (e, value) => {
    this.setState({
      tabGroupCustomersActive: value,
    });
  };
  _getCustomerTypeIds = () => {
    const { groupInfo } = this.props;
    if (!groupInfo) return [];
    if (groupInfo.getcare_customer_type_ids)
      return [...groupInfo.getcare_customer_type_ids];
    if (groupInfo.customer_types)
      return groupInfo.customer_types.map((item) => item.id);
  };

  render() {
    const isEditing = this.isEditing();
    const groupType = this._getGroupType();
    const groupCustomerType = this._getGroupCustomerType();
    const customerTypeIds = this._getCustomerTypeIds();

    return (
      <div
        className={`${classes.PageWrap} ${
          this.props.loading ? 'OverlayLoading' : ''
        }`}
      >
        <div className={classes.PageHeader}>
          <h1 className={classes.PageTitle}>
            {isEditing ? `Xem chi tiết nhóm` : `Tạo nhóm mới`}
          </h1>
          <Button
            size="small"
            variant="outlined"
            startIcon={<ArrowBack />}
            onClick={this.backToList}
          >
            {isEditing ? `Trở về` : `Huỷ và Trở về`}
          </Button>
          {!isEditing && (
            <Button
              size="small"
              variant="contained"
              color="primary"
              disabled={!this.isValid()}
              startIcon={<CheckOutlined />}
              onClick={this.handleSave}
            >
              Tạo nhóm
            </Button>
          )}
        </div>
        <div className={classes.PageMain}>
          <GroupInfo
            key={this.props.groupInfo?.id || -1}
            groupInfo={this.props.groupInfo}
            isGroupEmpty={this.isGroupEmpty()}
            isInactive={this.isInactive()}
            isEditing={isEditing}
            isNameValid={this.isNameValid()}
            isCodeValid={this.isCodeValid()}
            isDescriptionValid={this.isDescriptionValid()}
            isTypeValid={this.isTypeValid()}
          />
          {groupType && groupType === PRODUCT_TYPE && (
            <GroupProducts
              isGroupInactive={this.isInactive()}
              isEditing={isEditing}
              groupId={this.props.groupInfo?.id}
              groupCodeItems={this.props.groupInfo?.code_items}
            />
          )}
          {groupType && groupType === VENDOR_TYPE && (
            <GroupVendors
              isGroupInactive={this.isInactive()}
              isEditing={isEditing}
              groupId={this.props.groupInfo?.id}
              groupCodeItems={this.props.groupInfo?.code_items}
            />
          )}
          {groupType &&
            groupType === SALES_TYPE &&
            groupCustomerType === CUSTOMER_BY_LIST_TYPE && (
              <GroupCustomers
                isGroupInactive={this.isInactive()}
                isEditing={isEditing}
                groupId={this.props.groupInfo?.id}
                groupCodeItems={this.props.groupInfo?.code_items}
              />
            )}
          {groupType &&
            groupType === SALES_TYPE &&
            groupCustomerType === CUSTOMER_BY_CHANNEL_TYPE && (
              <GroupChannelCustomers
                isGroupInactive={this.isInactive()}
                isEditing={isEditing}
                groupId={this.props.groupInfo?.id}
                groupCodeItems={this.props.groupInfo?.code_items}
                customerTypeIds={customerTypeIds}
              />
            )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  groupInfo: makeSelectGroupInfo(),
  loading: makeSelectGroupLoading(),
  groupProducts: makeSelectGroupProducts(),
  groupVendors: makeSelectGroupVendors(),
  groupCustomers: makeSelectGroupCustomers(),
  groupCustomerMappings: makeSelectGroupCustomerMappings(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    updateGroup: (payload) => dispatch(updateGroup(payload)),
    getGroup: (payload) => dispatch(getGroup(payload)),
    resetGroup: () => dispatch(resetGroup()),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter)(Group);
