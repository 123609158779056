import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import productApi from 'utils/api/admin/productApi';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';

import classes from './ProductSelection.module.scss';

class ProductSelection extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      queryInput: '',
      autocompleteValue: null,
      productOptions: [],
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { queryInput } = this.state;
    if (queryInput && queryInput !== prevState.queryInput) {
      this._loadSuggestedOptions({
        params: {
          keyword: queryInput,
        },
      });
    }
  }
  _resetState = () => {
    this.setState({
      queryInput: '',
      autocompleteValue: null,
      productOptions: [],
    });
  }
  _getProductOptionLabel = (option) => {
    return `${option.getcare_id} - ${option.name}`
  }
  _loadSuggestedOptions = debounce(async ({ params }) => {
    const { data: response } = await productApi.getAll({ params });
    if (!response?.result) return;
    this.setState({
      productOptions: response.data || [],
    });
  }, 500);
  handleSelectedItemChange = (e, newValue) => {
    this._resetState();
    this.props.onChangeProduct(newValue);
  }

  render() {
    const { productOptions, queryInput, autocompleteValue } = this.state;
    const { selectedProduct } = this.props;

    return (<>
      <div className={classes.ControlWrap}>
        <Autocomplete
          fullWidth
          disableClearable
          handleHomeEndKeys={false}
          value={autocompleteValue}
          inputValue={queryInput}
          onChange={this.handleSelectedItemChange}
          options={productOptions}
          filterOptions={(x) => x}
          renderOption={(option) => this._getProductOptionLabel(option)}
          getOptionLabel={(option) => this._getProductOptionLabel(option)}
          getOptionSelected={(option, value) =>
            value && value.id && option ? option.id === value.id : null
          }
          onInputChange={(e, newInputValue) => {
            this.setState({
              queryInput: newInputValue,
            });
          }}
          renderInput={(params) => (
            <TextField {...params} placeholder="- Chọn sản phẩm -" />
          )}
        />
      </div>
      { selectedProduct && (
        <div className={`${classes.List}`}>
          <div className={`${classes.Row} ${classes.Header}`}>
            <ListHeaderCol className={classes.Col} label="Phahub ID"/>
            <ListHeaderCol className={classes.Col} label="Tên sản phẩm"/>
            <ListHeaderCol className={classes.Col} label="Số đăng ký"/>
          </div>
          <div className={`${classes.Body}`}>
            <div className={`${classes.Row} ${classes.Item}`}>
              <div className={classes.Col}>{ selectedProduct.getcare_id }</div>
              <div className={classes.Col}>{ selectedProduct.name }</div>
              <div className={classes.Col}>{ selectedProduct.registration_number }</div>
            </div>
          </div>
        </div>
      )}

    </>);
  }
}

ProductSelection.propTypes = {
  selectedProduct: PropTypes.object,
  onChangeProduct: PropTypes.func,
};

ProductSelection.defaultProps = {
  selectedProduct: null,
};

export default ProductSelection;
