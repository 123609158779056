import React, { PureComponent } from 'react';

import { validDate } from 'utils/helper';
import { dateFormat } from 'utils/constanst/dateConstants';
import format from 'date-fns/format';
import { Link } from 'react-router-dom';
import { currencyFormatVN } from 'utils/helper';

import gridClasses from '../ShippingCouponListGrid.module.scss';
import classes from './ShippingCouponListItem.module.scss';

class ShippingCouponListItem extends PureComponent {
  render() {
    const {
      id,
      code,
      description,
      getcare_vendor,
      start_date,
      end_date,
      amount_freeship,
    } = this.props;

    return <Link
      className={`${classes.Item} ${gridClasses.Row}`}
      to={`/shipping-coupon/${id}`}
    >
      <div className={`${gridClasses.Col}`}>{ getcare_vendor?.code }</div>
      <div className={gridClasses.Col}>   <Link to={`/vendor/${getcare_vendor?.id }`} className="TextSecondary"> { getcare_vendor?.name }</Link></div>
      <div className={gridClasses.Col}>{ code }</div>
      <div className={gridClasses.Col}>{ description }</div>
      <div className={gridClasses.Col}>{ validDate(start_date) ? format(validDate(start_date), dateFormat) : '' }</div>
      <div className={gridClasses.Col}>{ validDate(end_date) ? format(validDate(end_date), dateFormat) : '' }</div>
      <div className={`${gridClasses.Col} TextRight`}>{ !['', null, undefined].includes(amount_freeship) ? `> ${currencyFormatVN(amount_freeship)}` : `` }</div>
    </Link>;
  }
}

export default ShippingCouponListItem;
