import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import CoordinaterListItem from './CoordinaterListItem/CoordinaterListItem';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';
import Button from '@material-ui/core/Button';

import gridClasses from './CoordinaterListGrid.module.scss';
import classes from './CoordinaterList.module.scss';

class CoordinaterList extends PureComponent {
  render() {
    const { 
      coordinaterList, 
      isLoading, 
      displayFields, 
      coordinaterRoles, 
      deleteLoading, 
      isCreateAllowed,
      isEditAllowed,
    } = this.props;
    const hasActions = isCreateAllowed || isEditAllowed;

    return <div className={`${classes.List} ${isLoading && 'OverlayLoading'}`}>
        <div
        className={`${classes.Header}`}
        ref={(el) => (this.rowRef = el)}
      >
            <div className={`${gridClasses.Row} ${hasActions ? gridClasses.RowHasActions : ''} ${classes.Header}`}>
          { displayFields.map((item) => (
            <ListHeaderCol
              key={item.name}
              className={gridClasses.Col}
              {...item}
              onSortChange={this.props.onSortChange}
            />
          )) }
          {hasActions && 
            <div className={`${gridClasses.Col} ${classes.ActionsCol}`}>
              { isCreateAllowed && 
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={this.props.onAddNewRow}
                >Thêm dòng</Button>
              }
            </div>
          }
        </div>
        </div>

      <div className={`${classes.Body}`}>

      
        { (coordinaterList && coordinaterList.length)
          ? coordinaterList.map(item => (
            <CoordinaterListItem
              key={`product-${item.id || item.idStr}`}
              {...item}
              deleteLoading={deleteLoading}
              coordinaterRoles={[...coordinaterRoles]}
              isCreateAllowed={isCreateAllowed}
              isEditAllowed={isEditAllowed}
              saveCoordinater={this.props.onSaveCoordinater}
              removeCoordinater={this.props.onRemoveCoordinater}
            />))
          : (<p className="NoData">Không có người điều phối nào</p>)
        }
      </div>
    </div>;
  }
}

CoordinaterList.propTypes = {
  onSortChange: PropTypes.func,
  onSaveCoordinater: PropTypes.func,
  onRemoveCoordinater: PropTypes.func,
  onAddNewRow: PropTypes.func,
};

CoordinaterList.defaultProps = {
  displayFields: [],
  coordinaterList: [],
  isLoading: false,
};

export default CoordinaterList;
