export const STATUS_WORKING = 1;
export const STATUS_MATERNITY_LEAVE = 2;
export const STATUS_HAS_RETIRED = 3;
export const userStatusMap = {
  [STATUS_WORKING]: 'Đang làm việc',
  [STATUS_HAS_RETIRED]: 'Đã nghỉ việc',
};

export const APPROVAL_NOT_YET_ACCEPTED = false;
export const APPROVAL_ACCEPTED = true;
export const userApprovalMap = {
  [APPROVAL_NOT_YET_ACCEPTED]: 'Chưa duyệt',
  [APPROVAL_ACCEPTED]: 'Đã duyệt',
};
export const userStatuses = [
  {
    id: STATUS_WORKING,
    name: userStatusMap[STATUS_WORKING],
  },
  {
    id: STATUS_HAS_RETIRED,
    name: userStatusMap[STATUS_HAS_RETIRED],
  },
];

export const GENDER_FEMALE = 'female';
export const GENDER_MALE = 'male';
export const GENDER_OTHER = 'other';
export const userGenderMap = {
  [GENDER_FEMALE]: 'Nữ',
  [GENDER_MALE]: 'Nam',
  [GENDER_OTHER]: 'Khác',
};
export const userGenders = [
  {
    id: GENDER_FEMALE,
    name: userGenderMap[GENDER_FEMALE],
  },
  {
    id: GENDER_MALE,
    name: userGenderMap[GENDER_MALE],
  },
  {
    id: GENDER_OTHER,
    name: userGenderMap[GENDER_OTHER],
  },
];

export const TYPE_SYSTEM = 1;
export const TYPE_VENDOR = 2;
export const TYPE_CUSTOMER = 3;
export const userTypeMap = {
  [TYPE_SYSTEM]: 'Hệ thống tổng',
  [TYPE_VENDOR]: 'Nhà cung cấp',
  [TYPE_CUSTOMER]: 'Khách hàng',
};
export const userTypes = [
  {
    id: TYPE_SYSTEM,
    name: userTypeMap[TYPE_SYSTEM],
  },
  {
    id: TYPE_VENDOR,
    name: userTypeMap[TYPE_VENDOR],
  },
  {
    id: TYPE_CUSTOMER,
    name: userTypeMap[TYPE_CUSTOMER],
  },
];

/* common user list */
export const listDisplayFields = [
  {
    name: 'code',
    label: 'Mã nhân viên',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'name',
    label: 'Tên nhân viên',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'user_type',
    label: 'Trực thuộc',
  },
  {
    name: 'email',
    label: 'Email',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'phone',
    label: 'Điện thoại',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'getcare_department_name',
    label: 'Phòng ban',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'approval',
    label: 'Trạng thái duyệt',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'status',
    label: 'Trạng thái',
    sortable: true,
    sortDir: '',
  },
];
export const listParamsMap = {
  page_size: 50,
  page: 1,
  order: '',
};

export const filterFields = [
  {
    name: 'code',
    label: 'Mã nhân viên',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'name',
    label: 'Tên nhân viên',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'email',
    label: 'Email',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'phone',
    label: 'Điện thoại',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'getcare_department_id',
    label: 'Phòng ban',
    value: [],
    type: 'custom',
    placeholder: '- Chọn -',
  },
  {
    name: 'approval',
    label: 'Trạng thái duyệt',
    value: [],
    type: 'custom',
    placeholder: '- Chọn -',
  },
  {
    name: 'status',
    label: 'Trạng thái',
    value: [],
    type: 'custom',
    placeholder: '- Chọn -',
  },
];

/* sales user list */
export const salesUserListDisplayFields = [
  {
    name: 'code',
    label: 'Mã nhân viên',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'name',
    label: 'Tên nhân viên',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'getcare_team_name',
    label: 'Sales teams',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'role_name',
    label: 'Vị trí',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'phone',
    label: 'Điện thoại',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'user_type',
    label: 'Trực thuộc',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'email',
    label: 'Email',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'getcare_key_account_name',
    label: 'Đặc quyền bán',
    sortable: true,
    sortDir: '',
    className: 'TextCenter',
  },
  {
    name: 'amount',
    label: 'Doanh số trong tháng',
    sortable: true,
    sortDir: '',
    className: 'TextRight',
  },
];
export const salesUserListParamsMap = {
  page_size: 50,
  page: 1,
  order: '',
};
export const salesUserFilterFields = [
  {
    name: 'code',
    label: 'Mã nhân viên',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'name',
    label: 'Tên nhân viên',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'getcare_team_name',
    label: 'Sales team',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'phone',
    label: 'Điện thoại',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'user_type',
    label: 'Trực thuộc',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'email',
    label: 'Email',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'getcare_key_account_name',
    label: 'Đặc quyền bán',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
];

/* user roles */
export const defaultRoleParams = {
  id: 0,
  getcare_role: null,

  isEditing: true,
}
export const userRolesParamsMap = {
  order: '',
};
export const userRolesDisplayFields = [
  {
    name: 'name',
    label: 'Chức danh',
  },
  {
    name: 'details',
    label: 'Quyền',
  },
];

/* function */
export function isWorking(statusId) {
  return [STATUS_WORKING].includes(statusId);
}
export function getStatus(statusId) {
  return userStatuses.find(item => item.id === statusId);
}
export function isSystemType(typeId) {
  return [TYPE_SYSTEM, TYPE_SYSTEM + ``].includes(typeId);
}
export function isVendorType(typeId) {
  return [TYPE_VENDOR, TYPE_VENDOR + ``].includes(typeId);
}
export function isCustomerType(typeId) {
  return [TYPE_CUSTOMER, TYPE_CUSTOMER + ``].includes(typeId);
}
