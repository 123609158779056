import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { productStatusMap } from 'utils/constanst/adminUomConstants';
import { currencyFormatVN } from 'utils/helper';

import gridClasses from '../MedicineListGrid.module.scss';
import classes from './MedicineListItem.module.scss';

class MedicineListItem extends PureComponent {
  render() {
    const {
      getcare_id,
      code,
      name,
      registration_number,
      active,
      getcare_uom,
      price,
    } = this.props;

    return <div
      className={`${classes.ProductItem} ${gridClasses.Row}`}
    >
      <div className={gridClasses.Col}>{ getcare_id }</div>
      <div className={gridClasses.Col}>{ code }</div>
      <div className={gridClasses.Col}>{ name }</div>
      <div className={gridClasses.Col}>{ registration_number }</div>
      <div className={gridClasses.Col}>{ productStatusMap[active] }</div>
      <div className={gridClasses.Col}>{ getcare_uom.name }</div>
      <div className={gridClasses.Col}>{ getcare_uom.uom_base.name }</div>
      <div className={gridClasses.Col}>{ getcare_uom.smallest_mou }</div>
      <div className={gridClasses.Col}>{ currencyFormatVN(price) }</div>
      <div className={gridClasses.Col}>{ currencyFormatVN(price) }</div>
    </div>;
  }
}

MedicineListItem.propTypes = {
  getcare_id: PropTypes.string,
  code: PropTypes.string,
  name: PropTypes.string,
  active: PropTypes.number,
  registration_number: PropTypes.string,
  getcare_uom: PropTypes.object,
  price: PropTypes.number,
};

MedicineListItem.defaultProps = {
};

export default MedicineListItem;
