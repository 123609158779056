import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getUomBaseList } from 'redux/actions/admin/uomActions';
import { createStructuredSelector } from 'reselect';
import { makeSelectUomBaseList } from 'redux/selectors';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TagUOMItem from './TagUOMItem/TagUOMItem';
import FieldEditable from 'components/FieldEditable/FieldEditable';

import classes from './TagUOMDialog.module.scss';

class TagUOMDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this._initState(),
    };
    this.startUOMSId = 2;
  }
  componentDidMount() {
    this.props.getUomBaseList({
      params: {
        page_size: 1000,
        page: 1,
      },
    });
  }
  _initState = () => {
    return {
      uomBaseId: 0,
      uom1Id: 0,
      uoms: [{
        uomIndex: 0,
        uomId: 0,
        uomNumber: 1,
        uomNextId: 0,
      }],
    };
  }
  _getUOMItem = (id) => {
    return this.props.uomBaseList ? this.props.uomBaseList.find(item => item.id === id) : null;
  }
  _allChildValid = () => {
    return !this.state.uoms.some(item => !item.uomId || item.uomNumber <= 0 || !item.uomNextId);
  }
  _isFormValid = () => {
    const { uomBaseId, uom1Id } = this.state;
    return !!uomBaseId && !!uom1Id && this._allChildValid();
  };
  _prepareData = () => {
    const { uoms, uomBaseId, uom1Id } = this.state;
    const childUOMSMap = uoms.reduce((memo, item) => {
      memo[`uom_${item.uomIndex + this.startUOMSId}_id`] = item.uomId;
      memo[`uom_${item.uomIndex + this.startUOMSId}_number`] = Number(item.uomNumber);
      return memo;
    }, {});
    return {
      uom_base_id: uomBaseId,
      uom_1_id: uom1Id,
      ...childUOMSMap,
    }
  }
  _updateLastChild = (uomId) => {
    const lastChild = this.state.uoms[this.state.uoms.length - 1];
    if (!lastChild) return;
    this.handleSaveUOMItem({
      ...lastChild,
      uomNextId: uomId,
    });
  }
  handleChangeField = (e, value) => {
    this.setState({
      [e.target.name]: value,
    });
    // also save the uomNextId of last child
    if (e.target.name === 'uom1Id') {
      this._updateLastChild(value);
    }
  }
  handleSaveField = (fieldMap) => {
    this.setState({
      ...fieldMap,
    });
  }
  handleSaveUOMItem = (uom) => {
    const list = [...this.state.uoms];
    const itemIndex = uom.uomIndex;
    if (itemIndex > -1) {
      list[itemIndex] = {...list[itemIndex], ...uom};
      this.setState({
        uoms: list,
      });
      return;
    }
    // new item
    this.setState({
      uoms: [...list, {...uom}],
    });
  }
  handleRemoveUOMItem = (uom) => {
    const itemIndex = uom.uomIndex;
    if (itemIndex > -1) {
      const list = this.state.uoms.filter((item, index) => item.uomIndex !== uom.uomIndex);
      this.setState({
        uoms: list,
      });
      return;
    }
  }
  onClose = () => {
    this.props.onClose();
  };
  onEntered = () => {
    this.setState({
      ...this._initState(),
    });
  };
  handleAddUOMItem = () => {
    const lastChild = this.state.uoms[this.state.uoms.length - 1];
    this.handleSaveUOMItem({
      uomIndex: this.state.uoms.length,
      uomId: lastChild && lastChild.uomNextId ? lastChild.uomNextId : 0,
      uomNumber: 1,
      uomNextId: this.state.uom1Id,
    });
  }
  handleUpdateUOMItem = ({ uomIndex, fieldMap }) => {
    this.handleSaveUOMItem({
      uomIndex,
      ...fieldMap,
    });
  }
  handleSubmit = () => {
    if (this._isFormValid()) {
      this.props.onSubmit(this._prepareData());
    }
  };

  render() {
    const { isOpen, isLoading, uomBaseList } = this.props;
    const { uomBaseId, uom1Id, uoms } = this.state;

    return (
      <Dialog
        open={isOpen}
        onClose={this.onClose}
        onEntered={this.onEntered}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.Dialog}
      >
        <div className={`${classes.DialogContentWrap} ${isLoading && 'OverlayLoading'}`}>
          <DialogTitle>Tag đơn vị tính của sản phẩm</DialogTitle>
          <DialogContent>
            <div className={classes.FieldControl}>
              <label>
                Đơn vị cơ bản <span className={classes.RequiredMark}>*</span>
              </label>
              <FieldEditable
                editModeOnly
                fieldType="select"
                fieldName="uomBaseId"
                value={uomBaseId}
                displayedValue={this._getUOMItem(uomBaseId)?.name}
                options={uomBaseList || []}
                error={!uomBaseId}
                onSave={() => {return;}}
                onChange={this.handleChangeField}
              />
            </div>
            <div className={classes.FieldControl}>
              <label>
                Đơn vị bán lẻ <span className={classes.RequiredMark}>*</span>
              </label>
              <FieldEditable
                editModeOnly
                fieldType="select"
                fieldName="uom1Id"
                value={uom1Id}
                error={!uom1Id}
                displayedValue={this._getUOMItem(uom1Id)?.name}
                options={uomBaseList || []}
                onSave={() => {return;}}
                onChange={this.handleChangeField}
              />
            </div>
            <div className={classes.ChildrenUOMS}>
              {
                uoms.map((uom, index) => (
                  <TagUOMItem
                    key={`uom-child-${index}`}
                    {...uom}
                    startId={this.startUOMSId}
                    uomIndex={index}
                    uomBaseList={uomBaseList ? [...uomBaseList] : []}
                    uomsLength={this.state.uoms.length}
                    onSaveUOMItem={this.handleSaveUOMItem}
                    onRemoveUOMItem={this.handleRemoveUOMItem}
                    onUpdateUOMItem={this.handleUpdateUOMItem}
                    onUpdateUOMParent={this.handleSaveField}
                  />
                ))
              }
            </div>
            <div className={classes.Actions}>
              <Button disabled={this.state.uoms.length >= 8} variant="outlined" color="primary" size="small" onClick={this.handleAddUOMItem}>
                Thêm dòng
              </Button>
            </div>
          </DialogContent>
          <DialogActions className={classes.DialogActions}>
            <Button variant="outlined" onClick={this.props.onClose}>
              Huỷ bỏ
            </Button>
            <Button
              disabled={!this._isFormValid()}
              variant="contained"
              onClick={this.handleSubmit}
              color="primary"
              autoFocus
            >
              Xác nhận
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  uomBaseList: makeSelectUomBaseList(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getUomBaseList: (payload) => dispatch(getUomBaseList(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(TagUOMDialog);
