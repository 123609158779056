import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectGetcareCategoryList,
} from 'redux/selectors';
import Autocomplete from '@material-ui/lab/Autocomplete';

import productApi from 'utils/api/admin/productApi';
import getcareCategoryApi from 'utils/api/admin/getcareCategoryApi';
import {
  mapGetcareCategoryTrees,
} from 'utils/constanst/adminGetcareCategoryConstants';
import TextField from '@material-ui/core/TextField';

import { toast } from 'react-toastify';
import {
  DialogActions,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core/';

import classes from './ParentProduct.module.scss';

class ParentProduct extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      selectedGetcareCategoryId: null,
      selectedVendor: null,
      getcareCategoryList: [],
      productInput: null,
      suggettionProductList: []
    }
  }

  _loadGetcareCategory = async () => {
    this.setState({ isLoading: true })
    const { data: response } = await getcareCategoryApi.getAll({});
    if (!response?.result) { return; }
    this.setState({ isLoading: false, getcareCategoryList: mapGetcareCategoryTrees(response.data) })
  }

  componentDidUpdate(prevProps, prevState) {
    const { productInput } = this.state;
    if (productInput && productInput !== prevState.productInput) {
      this._loadSuggetionByProduct(productInput);
    }

  }

  _loadSuggetionByProduct = async (nameProduct) => {
    const params = {
      ...this.props.filterUrl,
      getcare_vendor_id: this.props.vendorId,
      product_vendor_name: nameProduct
    }
    const { data: response } = await productApi.getVendorProductList({
      params
    });
    this.setState({
      suggettionProductList: response.data || [],
    });
  }

  onClose = () => {
    this.props.onClose();
  };

  _isNameVendorValid = () => {
    return !!this.state.productInput;
  }

  handleTagGetcareCategoryForProducts = async () => {
    const { selectedVendor } = this.state;
    if (selectedVendor) {
      const { productIds } = this.props;
      const dataMapping = productIds.map(x => ({ id: x ,}))
      this.setState({
        isLoading: true,
      });
      const params = {
        parent_vendor_product_code: selectedVendor.product_vendor_code,
        getcare_vendor_products: dataMapping
      }
      const { data: response } = await productApi.updateParentProduct({ params });
      this.setState({
        isLoading: false,
      }, () => {
        this.props.onClose();
        this.props.onSubmitSuccess();
      });
    } else {
      alert("Vui lòng chọn 1 parent products");
    }
  }


  render() {
    const { isOpen } = this.props;
    const { isLoading, selectedVendor, suggettionProductList } = this.state;

    return (
      <Dialog
        open={isOpen}
        onClose={this.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.Dialog}
        fullWidth
        disableBackdropClick={isLoading}
        disableEscapeKeyDown={isLoading}
      >
        <div className={`${isLoading && 'OverlayLoading'}`}>
          <DialogTitle className={classes.DialogTitle}></DialogTitle>
          <DialogContent className={classes.DialogContent}>
            <div className={classes.FieldControl}>
              <label>Tìm kiếm sản phẩm parent</label>
              <Autocomplete
                freeSolo
                selectOnFocus
                openOnFocus
                size="small"
                handleHomeEndKeys={false}
                value={selectedVendor || ''}
                onInputChange={(e, newInputValue) => {
                  this.setState({
                    productInput: newInputValue,
                  });
                }}
                name='getcare_vendor_name'
                options={suggettionProductList || []}
                filterOptions={(x) => x}
                getOptionLabel={(option) => option?.product_vendor_name || ''}
                getOptionSelected={(option, value) =>
                  value && value.product_vendor_name && option ? option.product_vendor_name === value.product_vendor_name : null
                }
                onChange={(e, value) => {
                  if (value) {
                    this.setState({
                      selectedVendor: value,
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!this._isNameVendorValid()}
                    placeholder={'-- Chọn ---'}
                  />
                )}
              />
            </div>

          </DialogContent>
          <DialogActions>
            <Button type="button" variant="outlined" onClick={this.props.onClose}>
              Huỷ bỏ
            </Button>
            <Button
              type="button"
              variant="contained"
              onClick={this.handleTagGetcareCategoryForProducts}
              color="primary"
              autoFocus
            >
              Lưu
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    );
  }
}

ParentProduct.propTypes = {
  onClose: PropTypes.array,
  isOpen: PropTypes.bool,
  getcareCategory: PropTypes.object,
  action: PropTypes.string,
  onClose: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  productIds: PropTypes.array,
}

ParentProduct.defaultProps = {
  isOpen: true,
  productIds: [],
}

const mapStateToProps = createStructuredSelector({
  getcareCategoryList: makeSelectGetcareCategoryList(),
});
const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(ParentProduct);
