import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import salesChannelApi from 'utils/api/admin/salesChannelApi';

import Checkbox from '@material-ui/core/Checkbox';
import EditOutlined from '@material-ui/icons/EditOutlined';
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import IconButton from '@material-ui/core/IconButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import gridClasses from '../PolicyPriceChannelsGrid.module.scss';
import classes from './PolicyPriceChannel.module.scss';

class PolicyPriceChannel extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: !props.id && !props.getcare_sales_channel,

      codeInput: props.getcare_sales_channel?.code || '',
      selectedChannel: props.getcare_sales_channel
        ? { ...this._initSelectedChannel(props) }
        : null,
      channelOptions: props.getcare_sales_channel
        ? [
            {
              ...props.getcare_sales_channel,
            },
          ]
        : [],
    };
  }
  _resetState = () => {
    this.setState({
      isEditing: !this.props.id && !this.props.getcare_sales_channel,

      codeInput: this.props.getcare_sales_channel?.code || '',
      selectedChannel: this.props.getcare_sales_channel
        ? { ...this._initSelectedChannel(this.props) }
        : null,
      channelOptions: this.props.getcare_sales_channel
        ? [
            {
              ...this.props.getcare_sales_channel,
            },
          ]
        : [],
    });
  };
  _initSelectedChannel = (params) => {
    return {
      ...params.getcare_sales_channel,
      count: params.count,
    }
  }
  _isChangedField = (fieldName, subObjectName) => {
    const { changedItem } = this.props;
    if (!changedItem) return false;
    if (!subObjectName) return changedItem[fieldName] !== this.props[fieldName];
    return changedItem[subObjectName][fieldName] !== this.props[subObjectName][fieldName];
  }
  stopPropagation = (e) => {
    e.stopPropagation();
  };
  handleCheckboxChange = (e) => {
    e.stopPropagation();
    this.props.onItemSelectedToggle({
      item: {
        id: this.props.id,
        idStr: this.props.idStr,
        getcare_sales_channel: { ...this.props.getcare_sales_channel },
      },
      isSelected: !this.props.isSelected,
    });
  };
  componentDidUpdate(prevProps, prevState) {
    const { codeInput } = this.state;
    if (codeInput && codeInput !== prevState.codeInput) {
      this.loadSuggestedChannels({
        params: {
          code: codeInput,
        },
      });
    }
  }
  loadSuggestedChannels = debounce(async ({ params }) => {
    const { selectedCustomerTypeIds } = this.props;
    const { data: response } = await salesChannelApi.getAll({
      params: {
        ...params,
        getcare_customer_type_id: selectedCustomerTypeIds,
      },
    });
    if (!response?.result) return;
    this.setState({
      channelOptions: response.data
        ? this.filteredOptions([...response.data])
        : [],
    });
  }, 500);
  filteredOptions = (fullArr) => {
    return fullArr.filter((p) => {
      return (
        p.id === this.props.getcare_sales_channel?.id ||
        !this.props.currentList.some(
          (item) => item.getcare_sales_channel?.id === p.id
        )
      );
    });
  };
  isRowValid = () => {
    return !!this.state.selectedChannel;
  };
  handleEdit = () => {
    this.setState({
      isEditing: true,
    });
  };
  handleCancel = () => {
    this._resetState();
    if (!this.props.id) {
      const { selectedChannel } = this.state;
      this.props.removePolicyPriceChannel({
        id: this.props.id,
        idStr: this.props.idStr,
        count: selectedChannel?.count,
        getcare_sales_channel: { ...selectedChannel },
      });
    }
  };
  handleSave = () => {
    const { selectedChannel } = this.state;
    if (!this.isRowValid()) return;
    this.props.savePolicyPriceChannel({
      id: this.props.id,
      idStr: this.props.idStr,
      count: selectedChannel?.count,
      getcare_sales_channel: { ...selectedChannel },
    });
    this.setState({
      isEditing: false,
    });
  };
  handleRowClick = () => {
    if (this.props.readOnly) {
      return;
    }

    if (!this.state.isEditing) {
      this.handleEdit();
    }
  };
  handleRowBlur = () => {
    if (this.props.readOnly) {
      return;
    }

    if (this.state.isEditing && this.isRowValid()) {
      this.handleSave();
    }
  };
  handleSelectedChannelChange = (e, newValue) => {
    this.setState({
      selectedChannel: newValue,
    });
  };

  render() {
    const { getcare_sales_channel, isSelected, count, readOnly } = this.props;
    const { isEditing, selectedChannel, channelOptions } = this.state;

    return (
      <>
        <ClickAwayListener onClickAway={this.handleRowBlur}>
          <div
            className={`${classes.Item} ${gridClasses.Row}`}
            onClick={this.handleRowClick}
          >
            <div className={gridClasses.Col}>
              <Checkbox
                className={classes.Checkbox}
                checked={isSelected}
                disabled={readOnly}
                onClick={this.stopPropagation}
                onChange={this.handleCheckboxChange}
              />
            </div>
            <div className={`${gridClasses.Col} ${ this._isChangedField('id', 'getcare_sales_channel') ? classes.Highlighted : '' }`}>
              {isEditing ? (
                <Autocomplete
                  openOnFocus
                  selectOnFocus
                  disableClearable
                  size="small"
                  fullWidth
                  handleHomeEndKeys={false}
                  value={selectedChannel || null}
                  onChange={this.handleSelectedChannelChange}
                  options={channelOptions || []}
                  filterOptions={(x) => x}
                  renderOption={(option) => `${option.code}`}
                  getOptionLabel={(option) => (option && option.code) || ''}
                  getOptionSelected={(option, value) =>
                    value && value.id && option ? option.id === value.id : null
                  }
                  onInputChange={(e, newInputValue) => {
                    this.setState({
                      codeInput: newInputValue,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} error={!this.isRowValid()} placeholder="- Chọn -" />
                  )}
                />
              ) : (
                getcare_sales_channel?.code
              )}
            </div>
            <div className={`${gridClasses.Col} ${ this._isChangedField('count', null) ? classes.Highlighted : '' }`}>
              {isEditing ? selectedChannel?.count : count}
            </div>
            <div className={`${gridClasses.Col} ${classes.ActionsCol}`}>
              {isEditing ? (
                <>
                  <IconButton
                    onClick={this.handleCancel}
                    style={{ padding: '0' }}
                    size="small"
                  >
                    <CancelOutlined fontSize="small" color="secondary" />
                  </IconButton>
                  <IconButton
                    onClick={this.handleSave}
                    disabled={!this.isRowValid()}
                    style={{ padding: '0' }}
                    size="small"
                  >
                    <CheckCircleOutlineOutlined
                      fontSize="small"
                      color={this.isRowValid() ? `primary` : `inherit`}
                    />
                  </IconButton>
                </>
              ) : (
                <>
                  <IconButton
                    onClick={this.handleEdit}
                    style={{ padding: '0' }}
                    disabled={readOnly}
                    size="small"
                  >
                    <EditOutlined fontSize="small" color={readOnly ? 'inherit' : 'primary'} />
                  </IconButton>
                </>
              )}
            </div>
          </div>
        </ClickAwayListener>
      </>
    );
  }
}

PolicyPriceChannel.propTypes = {
  idStr: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  getcare_sales_channel: PropTypes.object,
  currentList: PropTypes.array,
  selectedCustomerTypeIds: PropTypes.array,
  isSelected: PropTypes.bool,
  changedItem: PropTypes.object,
  savePolicyPriceChannel: PropTypes.func,
  removePolicyPriceChannel: PropTypes.func,
  onItemSelectedToggle: PropTypes.func,
};

PolicyPriceChannel.defaultProps = {
  currentList: [],
  isSelected: false,
};

export default PolicyPriceChannel;
