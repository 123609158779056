import React, { PureComponent } from 'react';
import { compose } from 'redux';

import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import {
  MODE_ALL_OPTIONS,
  MODE_SOME_OPTIONS,
  modeOptionsMap,
  TOOLTIP_ROW_MAX,
} from 'utils/constanst/common';

import Tooltip from '@material-ui/core/Tooltip';

import gridClasses from '../KeyAccountListGrid.module.scss';
import classes from './KeyAccountListItem.module.scss';

class KeyAccountListItem extends PureComponent {
  getListStringDisplayed = ({ listMode, list, itemObjectName }) => {
    if (listMode === MODE_ALL_OPTIONS) return modeOptionsMap[MODE_ALL_OPTIONS];
    const listCount = list?.length;
    if (!listCount) return `-`;
    if (listCount > 1) return parseFloat(listCount);
    return list[0][itemObjectName]?.name;
  }
  getActiveProducts = () => {
    const { getcare_key_account_product_items } = this.props;
    return getcare_key_account_product_items.filter(item => item.active);
  }
  getTooltipContent = ({ list, itemObjectName }) => {
    return list.filter((item, idx) => idx <= TOOLTIP_ROW_MAX).map((item, idx) => {
      const isExceed = idx === TOOLTIP_ROW_MAX;
      return (<p
        key={item.id}
        className={`${classes.TooltipListItem} ${ isExceed ? classes.TooltipListItemLink : ''}`}
      >
        { isExceed
          ? <Link to={`/keyaccount/${this.props.id}`} className="TextSecondary">Xem thêm ở chi tiết</Link>
          : item[itemObjectName]?.name
        }
      </p>)
    });
  }

  render() {
    const {
      id,
      name,
      sales_mode,
      getcare_key_account_sales_items,
      getcare_vendor,
      customer_mode,
      getcare_key_account_customer_items,
    } = this.props;

    const salesDisplayed = this.getListStringDisplayed({
      listMode: sales_mode,
      list: getcare_key_account_sales_items,
      itemObjectName: 'getcare_sales',
    });

    const activeProducts = this.getActiveProducts();
    const productsDisplayed = this.getListStringDisplayed({
      listMode: MODE_SOME_OPTIONS,
      list: activeProducts,
      itemObjectName: 'getcare_product',
    });

    const customersDisplayed = this.getListStringDisplayed({
      listMode: customer_mode,
      list: getcare_key_account_customer_items,
      itemObjectName: 'getcare_customer',
    });

    return <Link
      className={`${classes.Item} ${gridClasses.Row}`}
      to={`/keyaccount/${id}`}
    >
      <div className={`${gridClasses.Col}`}>{ name }</div>
      <div className={`${gridClasses.Col}`}>
        { Number.isInteger(salesDisplayed)
          ? <Tooltip
              title={<>
                { this.getTooltipContent({
                    list: getcare_key_account_sales_items,
                    itemObjectName: 'getcare_sales',
                  })
                }
              </>}
              arrow
              placement="bottom"
              interactive
            >
              <span className="Badge">{ salesDisplayed }</span>
            </Tooltip>
          : salesDisplayed
        }
      </div>
      <div className={`${gridClasses.Col}`}>
      <Link to={`/vendor/${getcare_vendor?.id }`} className="TextSecondary"> { getcare_vendor?.name }</Link>
       </div>
      <div className={`${gridClasses.Col}`}>
        { Number.isInteger(productsDisplayed)
          ? <Tooltip
              title={<>
                { this.getTooltipContent({
                    list: activeProducts,
                    itemObjectName: 'getcare_product',
                  })
                }
              </>}
              arrow
              placement="bottom"
              interactive
            >
              <span className="Badge">{ productsDisplayed }</span>
            </Tooltip>
          : productsDisplayed
        }
      </div>
      <div className={`${gridClasses.Col}`}>
        { Number.isInteger(customersDisplayed)
          ? <Tooltip
              title={<>
                { this.getTooltipContent({
                    list: getcare_key_account_customer_items,
                    itemObjectName: 'getcare_customer',
                  })
                }
              </>}
              arrow
              placement="bottom"
              interactive
            >
              <span className="Badge">{ customersDisplayed }</span>
            </Tooltip>
          : customersDisplayed
        }
      </div>
    </Link>;
  }
}

export default compose(withRouter)(KeyAccountListItem);
