import React, { PureComponent } from 'react';
import format from 'date-fns/format';

import { validDate } from 'utils/helper';
import { dateTimeFormat } from 'utils/constanst/dateConstants';
import { approvalStatusMap } from 'utils/constanst/adminStoreConstants';

import storeApi from 'utils/api/admin/storeApi';

import classes from './StoreInfoChanges.module.scss';

class StoreInfoChanges extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      changes: []
    }
  }

  async componentDidMount() {
    const { setStoreChangeRef } = this.props;
    setStoreChangeRef(this);

    this.props.storeId && await this.loadData();
  }

  componentWillUnmount() {
    const { setStoreChangeRef } = this.props;
    setStoreChangeRef(undefined);
  }

  async componentDidUpdate(prevProps) {
    if (this.props.storeId !== prevProps.storeId) {
      await this.loadData();
    }
  }

  loadData = async () => {
    this.setState({
      isLoading: true,
    });
    const { data: response } = await storeApi.getChanges({
      id: this.props.storeId,
      params: {},
    });
    if (response?.result) {
      this.setState({
        isLoading: false,
        changes: response.data ? response.data : [],
      });
    }
  }

  getFieldLabel = (field) => {
    switch (field) {
      case 'getcare_district':
        return 'Quận/Huyện';
      case 'getcare_province':
        return 'Tỉnh/TP';
      case 'getcare_ward':
        return 'Phường/Xã';
      case 'address':
        return 'Địa chỉ';
      case 'name':
        return 'Tên khách hàng';
      case 'code':
        return 'Mã khách hàng';
      case 'getcare_customer_type':
        return 'Loại khách hàng';
      case 'phone':
        return 'Điện thoại';
      case 'email':
        return 'Email';
      case 'registration_company':
        return 'Tên doanh nghiệp';
      case 'registration_number':
        return 'Số đăng ký';
      case 'tax_code':
        return 'Mã số thuế';
      case 'type_pharmacy':
        return 'Loại hình';
      case 'representative_name':
        return 'Tên người đại diện';
      case 'expertise_person':
        return 'Người chiụ trách nhiệm chuyên môn';
      case 'expertise_number':
        return 'Số chứng chỉ hành nghề của NCTNCM';
      case 'latitude':
        return 'Latitude';
      case 'longitude':
        return 'Longitude';
      default:
        return '';
    }
  }

  render() {
    const { changes } = this.state;
    if (changes.length === 0) {
      return (
        <p>Không có thay đổi nào.</p>
      )
    }

    return (
      <ul className={classes.ChangeList}>
        {changes.map((change, index) => (
          <li key={`changes-${index}`} className={classes.ChangeItem}>
            <span className={classes.Bullet}></span>
            <div className={classes.ChangeDetails}>
              <p className={classes.ChangeTime}>
                {change.active === 0 ? <span> {validDate(change.created_at)
                  ? format(validDate(change.created_at), dateTimeFormat)
                  : ''}
                &nbsp;&rarr;&nbsp;Dừng hoạt động</span>: <span> {validDate(change.created_at)
                  ? format(validDate(change.created_at), dateTimeFormat)
                  : ''}
                &nbsp;&rarr;&nbsp;{approvalStatusMap[change.approval === 1 ? 1 : -1]}</span>}
             
              </p>
              <p className={classes.ChangeTitle}>{`${change.user_name}: ${change.reason}`}</p>
              {Object.keys(change.log_change_data).map((field, index) => {
                const value = change.log_change_data[field];
                const oldValue = typeof value['old'] === 'object' ? value['old']?.name : value['old'];
                const newValue = typeof value['new'] === 'object' ? value['new']?.name : value['new'];
                return (
                  <p key={`index-${index}`} className={classes.ChangeField}>+ {`${this.getFieldLabel(field)}: ${oldValue ? oldValue : '...'} → ${newValue}`}</p>
                )
              })}
            </div>
          </li>
        ))}
      </ul>
    )
  }
}

export default StoreInfoChanges;
