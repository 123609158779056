import React, { PureComponent } from 'react';
import ingredientApi from 'utils/api/admin/ingredientApi';
import { debounce } from 'lodash';
import { suggestionPageSize } from 'utils/constanst/common';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import classes from './TagCategoryDialog.module.scss';

class TagCategoryDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedCategory: null,
      categoryInput: '',
      categoryOptions: [],
      content: '',
    };
  }
  componentDidMount() {
    this.setState({
      categoryInput: this.props.category,
    });
  }
  componentDidUpdate(prevProps, prevState) {
    const { categoryInput } = this.state;
    if (categoryInput !== prevState.categoryInput) {
      this.loadCategories({ l6_name: categoryInput });
    }
  }
  loadCategories = debounce(async (params) => {
    const { data: response } = await ingredientApi.getAll({
      params: { page: 1, page_size: suggestionPageSize, ...params },
    });
    if (!response?.result) return;
    this.setState({
      categoryOptions: response.data ? [...response.data] : [],
    });
  }, 500);
  isCategoryValid = () => {
    const { categoryOptions, categoryInput } = this.state;
    return categoryOptions.some(
      (item) => item.l6_name.toLowerCase() === categoryInput.trim().toLowerCase()
    );
  };
  handleSubmit = () => {
    const { selectedCategory, categoryInput, content } = this.state;
    this.props.onSubmit({
      id: selectedCategory?.id || undefined,
      name: selectedCategory?.l6_name || categoryInput,
      content: content,
    });
  };
  handleInputChange = (e, newValue) => {
    this.setState({
      categoryInput: newValue,
    });
  };
  isFormValid = () => {
    return this.state.categoryInput.trim() !== '';
  };
  onClose = () => {
    this.props.onClose();
  };
  onEntered = () => {
    this.setState({
      categoryInput: this.props.category,
      selectedCategory: null,
      categoryOptions: [],
      content: '',
    });
  };

  render() {
    const { isOpen, isLoading } = this.props;
    const {
      selectedCategory,
      categoryInput,
      categoryOptions,
      content,
    } = this.state;

    return (
      <Dialog
        open={isOpen}
        onClose={this.onClose}
        onEntered={this.onEntered}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.Dialog}
      >
        <div className={`${classes.DialogContentWrap} ${isLoading && 'OverlayLoading'}`}>
          <DialogTitle>Tag thành phần vào hoạt chất của sản phẩm</DialogTitle>
          <DialogContent>
            <div className={classes.FieldControl}>
              <label>
                Thành phần <span className={classes.RequiredMark}>*</span>
              </label>
              <Autocomplete
                freeSolo
                openOnFocus
                selectOnFocus
                handleHomeEndKeys={false}
                value={selectedCategory || null}
                inputValue={categoryInput}
                onInputChange={this.handleInputChange}
                options={categoryOptions || []}
                getOptionLabel={(option) => option?.l6_name || ''}
                renderOption={(option) => {
                  const {
                    l1_name,
                    l2_name,
                    l6_name,
                  } = option;
                  return `${l1_name ? l1_name + '-' : ''}${l2_name ? l2_name + '-' : ''}${l6_name ? l6_name : ''}`;
                }}
                getOptionSelected={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    autoFocus
                    className={
                      this.isCategoryValid() ? classes.Valid : classes.InValid
                    }
                    {...params}
                  />
                )}
                onChange={(e, newValue) => {
                  this.setState({
                    selectedCategory: newValue,
                  });
                }}
              />
            </div>
            <div className={classes.FieldControl}>
              <label>Nhập hàm lượng</label>
              <TextField
                className={classes.FieldWrap}
                value={content}
                placeholder="Nhập..."
                name="content"
                onChange={(e) => {
                  this.setState({
                    content: e.target.value,
                  });
                }}
              />
            </div>
          </DialogContent>
          <DialogActions className={classes.DialogActions}>
            <Button variant="outlined" onClick={this.props.onClose}>
              Huỷ bỏ
            </Button>
            <Button
              disabled={!this.isFormValid()}
              variant="contained"
              onClick={this.handleSubmit}
              color="primary"
              autoFocus
            >
              Xác nhận
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    );
  }
}

export default TagCategoryDialog;
