import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classes from './RelatedVendors.module.scss';

class RelatedVendors extends PureComponent {
  render() {
    const { relatedVendors } = this.props;

    return (
      <>
        {relatedVendors.length > 0 ? (
          <ul className={classes.List}>
            { relatedVendors.map(vendor => (
              <li key={vendor.id}>
                <Link to={`/vendor/${vendor.id}`}>
                  <h4 className={classes.Heading}>{ vendor.name }</h4>
                  <p className={classes.Address}>{ vendor.registration_address }</p>
                  <p className={classes.Phone}>{ vendor.phone }</p>
                </Link>
              </li>)
            ) }
          </ul>)
          : (<p className="NoData">Không có nhà cung cấp nào</p>)
        }
      </>
    );
  }
}

RelatedVendors.propTypes = {
  relatedVendors: PropTypes.array,
};

RelatedVendors.defaultProps = {
  relatedVendors: [],
};

export default RelatedVendors;
