import {
  SAVE_VENDOR_DETAILS,
  SAVE_VENDOR_LIST,
  SAVE_VENDOR_LIST_TOTAL,
  SAVE_VENDOR_LIST_LOADING,
} from 'redux/constants/admin/vendorConstants';

const initialState = {
  vendorList: [],
  vendorListTotal: 0,
  vendorDetails: null,
  isVendorListLoading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_VENDOR_DETAILS: {
      return {
        ...state,
        vendorDetails: { ...action.vendorDetails },
      };
    }
    case SAVE_VENDOR_LIST: {
      return {
        ...state,
        vendorList: [ ...action.vendorList ],
      };
    }
    case SAVE_VENDOR_LIST_TOTAL: {
      return {
        ...state,
        vendorListTotal: action.vendorListTotal,
      };
    }
    case SAVE_VENDOR_LIST_LOADING: {
      return {
        ...state,
        isVendorListLoading: action.isVendorListLoading,
      };
    }
    default:
      return state;
  }
}

export default reducer;
