import groupApi from 'utils/api/admin/groupApi';
import customerApi from 'utils/api/admin/customerApi';
import { put } from 'redux-saga/effects';
import { groupActions } from 'redux/actions';
import { toast } from 'react-toastify';
import { groupCustomersParamsMap, INACTIVE } from 'utils/constanst/adminGroupConstants';

export function* loadGroupList(payload) {
  const { params } = payload.payload;
  try {
    yield put(groupActions.saveActionLoading(true));

    const { data: response } = yield groupApi.getAll({ params });

    yield put(groupActions.saveActionLoading(false));
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(
      groupActions.saveGroupList(response.data ? [...response.data] : [])
    );
    yield put(groupActions.saveGroupListTotal(response.total_records));
  } catch (err) {
    console.log(err);
  }
}
export function* loadGroup(payload) {
  try {
    yield put(groupActions.saveActionLoading(true));
    yield put(groupActions.saveGroup(null));

    const { data: response } = yield groupApi.get(payload.id);

    yield put(groupActions.saveActionLoading(false));
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    if (!response.data) return;
    yield put(
      groupActions.saveGroup({
        ...response.data,
        erp_group_products: undefined,
        erp_group_vendors: undefined,
      })
    );

    yield put(
      groupActions.saveGroupProducts(
        response.data.erp_group_products
          ? [...response.data.erp_group_products]
          : []
      )
    );
    yield put(
      groupActions.saveGroupVendors(
        response.data.erp_group_vendors
          ? [...response.data.erp_group_vendors]
          : []
      )
    );
  } catch (err) {
    console.log(err);
  }
}
export function* putGroup(payload) {
  const { id, params } = payload.payload;
  try {
    if (id && !isNaN(id) && Number(id) > 0) {
      // update group
      // update the UI first for smoothly :D
      yield put(groupActions.saveGroup({ ...params, deactive_date: params.active === INACTIVE ? new Date() : undefined }));
    }
    const { data: response } = yield groupApi.updateGroup({ id, params });
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    if (!id || isNaN(id) || Number(id) <= 0) {
      // create group successfull
      toast.success('Tạo nhóm thành công!');
      yield put(
        groupActions.saveGroup(
          response.data
            ? {
                ...response.data,
                erp_group_products: undefined,
                erp_group_vendors: undefined,
              }
            : null
        )
      );
    }
  } catch (err) {
    console.log(err);
  }
}

// group products
export function* putGroupProduct(payload) {
  const { groupProductId, params } = payload.payload;
  try {
    const { data: response } = yield groupApi.updateGroupProduct({
      groupProductId,
      params,
    });
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(
      groupActions.saveGroupProduct(
        response.data
          ? { ...response.data, idStr: params.idStr || undefined }
          : null
      )
    );
  } catch (err) {
    console.log(err);
  }
}
export function* deleteGroupProducts(payload) {
  const { id, code, groupProducts } = payload.payload;
  try {
    const groupProductIds = groupProducts.filter((p) => p.id).map((p) => p.id);
    if (groupProductIds.length > 0) {
      yield put(groupActions.saveActionLoading(true));

      const { data: response } = yield groupApi.deleteGroupProducts({
        id,
        code,
        groupProductIds,
      });

      yield put(groupActions.saveActionLoading(false));
      if (!response?.result) {
        toast.error(response.message);
        return;
      }
    }
    yield put(groupActions.removeGroupProducts([...groupProducts]));
  } catch (err) {
    console.log(err);
  }
}
export function* exportGroupProducts(payload) {
  const { id, code } = payload.payload;
  try {
    yield put(groupActions.saveActionLoading(true));

    const { data: response } = yield groupApi.exportGroupProducts({ id, code });
    if (!response?.result) {
      toast.error(response.message);
      return;
    }

    const anchor = document.createElement('a');
    anchor.setAttribute('href', `${response.data ? response.data : ''}`);
    anchor.click();
    anchor.remove();

    yield put(groupActions.saveActionLoading(false));
  } catch (err) {
    console.log(err);
  }
}

// group vendors
export function* putGroupVendor(payload) {
  const { groupVendorId, params } = payload.payload;
  try {
    const { data: response } = yield groupApi.updateGroupVendor({
      groupVendorId,
      params,
    });
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(
      groupActions.saveGroupVendor(
        response.data
          ? { ...response.data, idStr: params.idStr || undefined }
          : null
      )
    );
  } catch (err) {
    console.log(err);
  }
}
export function* deleteGroupVendors(payload) {
  const { id, code, groupVendors } = payload.payload;
  try {
    const groupVendorIds = groupVendors.filter((p) => p.id).map((p) => p.id);
    if (groupVendorIds.length > 0) {
      yield put(groupActions.saveActionLoading(true));

      const { data: response } = yield groupApi.deleteGroupVendors({
        id,
        code,
        groupVendorIds,
      });

      yield put(groupActions.saveActionLoading(false));
      if (!response?.result) {
        toast.error(response.message);
        return;
      }
    }
    yield put(groupActions.removeGroupVendors([...groupVendors]));
  } catch (err) {
    console.log(err);
  }
}
export function* exportGroupVendors(payload) {
  const { id, code } = payload.payload;
  try {
    yield put(groupActions.saveActionLoading(true));

    const { data: response } = yield groupApi.exportGroupVendors({ id, code });
    if (!response?.result) {
      toast.error(response.message);
      return;
    }

    const anchor = document.createElement('a');
    anchor.setAttribute('href', `${response.data ? response.data : ''}`);
    anchor.click();
    anchor.remove();

    yield put(groupActions.saveActionLoading(false));
  } catch (err) {
    console.log(err);
  }
}

// group customers
export function* loadGroupCustomers(payload) {
  const { params } = payload.payload;
  try {
    yield put(groupActions.saveActionLoading(true));

    const { data: response } = yield groupApi.getGroupCustomers({ params });

    yield put(groupActions.saveActionLoading(false));
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(
      groupActions.saveGroupCustomers(response.data ? [...response.data] : [])
    );
    yield put(groupActions.saveGroupCustomersTotal(response.total_records));
  } catch (err) {
    console.log(err);
  }
}
export function* putGroupCustomer(payload) {
  const { groupCustomerId, params } = payload.payload;
  try {
    const { data: response } = yield groupApi.updateGroupCustomer({
      groupCustomerId,
      params,
    });
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(
      groupActions.saveGroupCustomer(
        response.data
          ? { ...response.data, idStr: params.idStr || undefined }
          : null
      )
    );
  } catch (err) {
    console.log(err);
  }
}
export function* deleteGroupCustomers(payload) {
  const { id, code, groupCustomers } = payload.payload;
  try {
    const groupCustomerIds = groupCustomers
      .filter((p) => p.id)
      .map((p) => p.id);
    if (groupCustomerIds.length > 0) {
      yield put(groupActions.saveActionLoading(true));

      const { data: response } = yield groupApi.deleteGroupCustomers({
        id,
        code,
        groupCustomerIds,
      });

      if (!response?.result) {
        yield put(groupActions.saveActionLoading(false));
        toast.error(response.message);
        return;
      }
    }
    yield put(groupActions.removeGroupCustomers([...groupCustomers]));

    // reload list of customers because it has the paging
    const { data: listResponse } = yield groupApi.getGroupCustomers({
      params: {
        ...groupCustomersParamsMap,
        getcare_erp_group_code_items: code,
        getcare_erp_group_id: id,
      },
    });
    yield put(groupActions.saveActionLoading(false));
    if (!listResponse?.result) {
      toast.error(listResponse.message);
      return;
    }
    yield put(
      groupActions.saveGroupCustomers(
        listResponse.data ? [...listResponse.data] : []
      )
    );
    yield put(groupActions.saveGroupCustomersTotal(listResponse.total_records));
  } catch (err) {
    console.log(err);
  }
}
export function* exportGroupCustomers(payload) {
  const { id, code } = payload.payload;
  try {
    yield put(groupActions.saveActionLoading(true));

    const { data: response } = yield groupApi.exportGroupCustomers({
      id,
      code,
    });
    if (!response?.result) {
      toast.error(response.message);
      return;
    }

    const anchor = document.createElement('a');
    anchor.setAttribute('href', `${response.data ? response.data : ''}`);
    anchor.click();
    anchor.remove();

    yield put(groupActions.saveActionLoading(false));
  } catch (err) {
    console.log(err);
  }
}

// group customer channels (mappings)
export function* loadGroupCustomerMappings(payload) {
  const { params } = payload.payload;
  try {
    const { data: response } = yield groupApi.getGroupCustomerMappings({
      params,
    });
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(
      groupActions.saveGroupCustomerMappings(
        response.data ? [...response.data] : []
      )
    );
  } catch (err) {
    console.log(err);
  }
}
export function* loadGroupCustomersByMappings(payload) {
  const { params } = payload.payload;
  try {
    const { data: response } = yield groupApi.getGroupCustomersByMappings({
      params,
    });

    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(
      groupActions.saveGroupCustomers(response.data ? [...response.data] : [])
    );
    yield put(groupActions.saveGroupCustomersTotal(response.total_records));
  } catch (err) {
    console.log(err);
  }
}
export function* putGroupCustomerMapping(payload) {
  const { groupCustomerMappingId, params, queryParams } = payload.payload;
  try {
    const { data: response } = yield groupApi.updateGroupCustomerMapping({
      groupCustomerMappingId,
      params,
      queryParams,
    });
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(
      groupActions.saveGroupCustomerMapping(
        response.data
          ? { ...response.data, idStr: params.idStr || undefined }
          : null
      )
    );
  } catch (err) {
    console.log(err);
  }
}
export function* deleteGroupCustomerMappings(payload) {
  const { id, code, groupCustomerMappings } = payload.payload;
  try {
    const groupCustomerMappingIds = groupCustomerMappings
      .filter((p) => p.id)
      .map((p) => p.id);
    if (groupCustomerMappingIds.length > 0) {
      yield put(groupActions.saveActionLoading(true));

      const { data: response } = yield groupApi.deleteGroupCustomerMappings({
        id,
        code,
        groupCustomerMappingIds,
      });

      yield put(groupActions.saveActionLoading(false));
      if (!response?.result) {
        toast.error(response.message);
        return;
      }
    }
    yield put(
      groupActions.removeGroupCustomerMappings([...groupCustomerMappings])
    );
  } catch (err) {
    console.log(err);
  }
}

export function* loadCustomerTypes() {
  try {
    yield put(
      groupActions.saveCustomerTypes([])
    );
    const { data: response } = yield customerApi.getCustomerTypes();
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(
      groupActions.saveCustomerTypes(response.data ? [...response.data] : [])
    );
  } catch (err) {
    console.log(err);
  }
}
