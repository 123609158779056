import {
  GET_ASSET_BRANDING_LIST,
  SAVE_ASSET_BRANDING_LIST,
  SAVE_ASSET_BRANDING_LIST_TOTAL,
  SAVE_LOADING,
  REMOVE_ASSET_BRANDING_ITEMS,
  SAVE_ASSET_BRANDING_ITEM,
  UPDATE_ASSET_BRANDING_LIST,
  SAVE_ACTION_LOADING,
  SAVE_ASSET_BRANDING_ALLOWED_ACTIONS,
  SAVE_ASSET_BRANDING_VISIBILITY,
} from 'redux/constants/admin/assetBrandingConstants';

export function getAssetBrandingList(payload) {
  return {
    type: GET_ASSET_BRANDING_LIST,
    payload
  };
}
export function updateAssetBrandingList(payload) {
  return {
    type: UPDATE_ASSET_BRANDING_LIST,
    payload
  };
}

// mutation
export function saveLoading(loading) {
  return {
    type: SAVE_LOADING,
    loading
  };
}
export function saveActionLoading(actionLoading) {
  return {
    type: SAVE_ACTION_LOADING,
    actionLoading
  };
}
export function saveAssetBrandingList(assetBrandingList) {
  return {
    type: SAVE_ASSET_BRANDING_LIST,
    assetBrandingList
  };
}
export function saveAssetBrandingListTotal(assetBrandingListTotal) {
  return {
    type: SAVE_ASSET_BRANDING_LIST_TOTAL,
    assetBrandingListTotal
  };
}
export function removeAssetBrandingItems(assetBrandingItems) {
  return {
    type: REMOVE_ASSET_BRANDING_ITEMS,
    assetBrandingItems
  };
}

export function saveAssetBrandingItem(assetBrandingItem) {
  return {
    type: SAVE_ASSET_BRANDING_ITEM,
    assetBrandingItem
  };
}

export function saveAssetBrandingAllowedActions(assetBrandingAllowedActions) {
  return {
    type: SAVE_ASSET_BRANDING_ALLOWED_ACTIONS,
    assetBrandingAllowedActions
  };
}
export function saveAssetBrandingVisibility(assetBrandingVisibility) {
  return {
    type: SAVE_ASSET_BRANDING_VISIBILITY,
    assetBrandingVisibility
  };
}
