import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectKeyAccountCustomers, makeSelectOriKeyAccountCustomers } from 'redux/selectors';
import {
  removeKeyAccountCustomerItems,
  saveKeyAccountCustomerItem,
} from 'redux/actions/admin/keyAccountActions';
import { getDisplayFields, genID } from 'utils/helper';
import {
  keyAccountCustomersDisplayFields,
  keyAccountCustomersParamsMap,
  defaultKeyAccountCustomerParams,
} from 'utils/constanst/adminKeyAccountConstants';
import { CUSTOMER_MODE_ALL, CUSTOMER_MODE_OPTION, customerModesMap } from 'utils/constanst/adminKeyAccountConstants';

import KeyAccountCustomerItem from './KeyAccountCustomerItem/KeyAccountCustomerItem';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import gridClasses from './KeyAccountCustomerListGrid.module.scss';
import classes from 'views/admin/KeyAccount/KeyAccount.module.scss';

class KeyAccountCustomers extends PureComponent {
  handleCreateNewRow = (params) => {
    this.props.saveKeyAccountCustomerItem({
      ...defaultKeyAccountCustomerParams,
      idStr: genID(),
    });
  };
  handleSaveKeyAccountCustomer = (params) => {
    this.props.saveKeyAccountCustomerItem({
      ...params,
    });
  };
  handleRemoveKeyAccountCustomer = (params) => {
    this.props.removeKeyAccountCustomerItems([{...params}]);
  };

  render() {
    const {
      values,
      handleChange,
      keyAccountCustomers,
    } = this.props;
    // console.log("keyAccountCustomers",keyAccountCustomers)
    const displayFields = getDisplayFields(keyAccountCustomersParamsMap, keyAccountCustomersDisplayFields);

    return (
      <div className={`${classes.SectionWrap}`}>
        <h4 className={classes.SectionTitle}>Khách hàng</h4>

        <RadioGroup
          className={classes.RadioGroupWrap}
          name="customer_mode"
          value={values.customer_mode + ``}
          onChange={(e) => {
            handleChange(e);
            this.props.onFieldChange({'customer_mode': e.target.value});
          }}
        >
          <div className={`${classes.RadioGroupRow}`}>
            <FormControlLabel
              value={CUSTOMER_MODE_ALL + ``}
              size="small"
              control={<Radio size="small" />}
              label={customerModesMap[CUSTOMER_MODE_ALL]}
            />
          </div>
          <div className={`${classes.RadioGroupRow}`}>
            <FormControlLabel
              value={CUSTOMER_MODE_OPTION + ``}
              size="small"
              control={<Radio size="small" />}
              label={customerModesMap[CUSTOMER_MODE_OPTION]}
            />
          </div>
        </RadioGroup>

        <div className={`${classes.List} ${[CUSTOMER_MODE_ALL, CUSTOMER_MODE_ALL + ``].includes(values.customer_mode) ? classes.ListHidden : ''}`}>
          <div className={`${gridClasses.Row} ${classes.Header}`}>
            {displayFields.map((item) => (
              <ListHeaderCol
                key={item.name}
                {...item}
                className={`${gridClasses.Col} ${item.className}`}
              />
            ))}
            <div className={`${gridClasses.Col} ${classes.HeaderCol} TextCenter`}>
              <Button
                color="primary"
                variant="contained"
                size="small"
                onClick={this.handleCreateNewRow}
              >
                Thêm dòng
              </Button>
            </div>
          </div>
          <div className={`${classes.Body}`}>
            {keyAccountCustomers && keyAccountCustomers.length ? (<>
              { keyAccountCustomers.map((item, index) => (
                <KeyAccountCustomerItem
                  key={`user-${item.idStr || item.id}`}
                  {...item}
                  index={index}
                  currentList={[...keyAccountCustomers]}
                  onSaveKeyAccountCustomerItem={this.handleSaveKeyAccountCustomer}
                  onRemoveKeyAccountCustomerItem={this.handleRemoveKeyAccountCustomer}
                />
              )) }
            </>) : (
              <p className="NoData">Không có data</p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

KeyAccountCustomers.propTypes = {
  isEditing: PropTypes.bool,
  onFieldChange: PropTypes.func,
};

KeyAccountCustomers.defaultProps = {
  isEditing: false,
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveKeyAccountCustomerItem: (payload) => dispatch(saveKeyAccountCustomerItem(payload)),
    removeKeyAccountCustomerItems: (payload) => dispatch(removeKeyAccountCustomerItems(payload)),
  };
};
const mapStateToProps = createStructuredSelector({
  keyAccountCustomers: makeSelectKeyAccountCustomers(),
  oriKeyAccountCustomers: makeSelectOriKeyAccountCustomers(),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(KeyAccountCustomers);
