import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  getStoreDeliveryAddressList,
  updateStoreDeliveryAddress,
  deleteStoreDeliveryAddress,
} from 'redux/actions/admin/storeActions';
import { createStructuredSelector } from "reselect";
import {
  makeSelectStoreDeliveryAddressList,
  makeSelectStoreActionLoading,
} from 'redux/selectors';
import { getFullAddressStr } from 'utils/helper';
import { suggestionPageSize } from 'utils/constanst/common';

import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import EditOutlined from '@material-ui/icons/EditOutlined';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import StoreDeliveryAddressForm from './StoreDeliveryAddressForm/StoreDeliveryAddressForm';

import classes from './StoreDeliveryInfo.module.scss';

class StoreDeliveryInfo extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedAddress: null,
      showAddressForm: false,

      isConfirmDialogOpen: false,
    };
  }

  componentDidMount() {
    if (this.props.storeId) {
      this.loadData();
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { storeId } = this.props;
    if (storeId && storeId !== prevProps.storeId) {
      this.loadData();
    }
  }

  loadData = () => {
    this.props.getStoreDeliveryAddressList({
      params: {
        getcare_customer_code: this.props.customerCode,
        page_size: suggestionPageSize,
      },
    });
  };

  handleDeleteDeliveryAddress = (addressItem) => {
    this.setState({
      showAddressForm: false,
      selectedAddress: {
        ...addressItem,
      },
    });
    this.handleConfirmDialogOpen();
  }
  handleEditDeliveryAddress = (addressItem) => {
    this.setState({
      showAddressForm: true,
      selectedAddress: {
        ...addressItem,
      },
    });
  }
  handleCancel = () => {
    this.setState({
      showAddressForm: false,
      selectedAddress: null,
    });
  }
  handleSubmit = (addressItem) => {
    this.props.updateStoreDeliveryAddress({
      params: {
        ...addressItem,
        save: addressItem.id ? false : true,
        getcare_customer_code: this.props.customerCode,
      }
    });
    this.setState({
      showAddressForm: false,
      selectedAddress: null,
    });
  }

  handleConfirmDialogOpen = () => {
    this.setState({
      isConfirmDialogOpen: true,
    });
  }
  handleConfirmDialogClose = () => {
    this.setState({
      isConfirmDialogOpen: false,
    });
  }
  handleSubmitDeleteDeliveryAddress = () => {
    this.props.deleteStoreDeliveryAddress({
      params: {
        id: this.state.selectedAddress.id,
      },
    });
    this.setState({
      selectedAddress: null,
      showAddressForm: false,
    });
    this.handleConfirmDialogClose();
  }
  handleAddNewAddress = () => {
    this.setState({
      showAddressForm: true,
      selectedAddress: null,
    });
  }

  render() {
    const { isStoreActionLoading, storeDeliveryAddressList, isEditAllowed } = this.props;
    const { selectedAddress, isConfirmDialogOpen, showAddressForm } = this.state;

    return (
      <div className={`${classes.Wrap} ${isStoreActionLoading && 'OverlayLoading'}`}>
        { isEditAllowed &&
          <div className={classes.NewBlock}>
            { (showAddressForm && !selectedAddress)
              ? <StoreDeliveryAddressForm
                  isNew
                  onCancel={this.handleCancel}
                  onSubmit={this.handleSubmit}
                />
              : <Button
                  type="button"
                  variant="contained"
                  onClick={this.handleAddNewAddress}
                  color="primary"
                >
                  Thêm địa chỉ mới
                </Button>
            }
          </div>
        }
        { storeDeliveryAddressList && storeDeliveryAddressList.length
          ? storeDeliveryAddressList.map(addressItem => (
              <div
                key={`address-${addressItem.id}`}
                className={classes.DeliveryAddress}
              >
                { (showAddressForm && selectedAddress && selectedAddress.id === addressItem.id)
                  ? <StoreDeliveryAddressForm
                      selectedAddress={addressItem}
                      onCancel={this.handleCancel}
                      onSubmit={this.handleSubmit}
                    />
                  : <>
                      <div>
                        <div className={`${classes.Info} ${classes.MainInfo}`}>
                          <label>Cơ sở nhận hàng</label>
                          <div>
                            { addressItem.label || `-` }
                            { addressItem.default && <span className={`${classes.Badge} Badge`}>Mặc định</span> }
                          </div>
                        </div>
                        <div className={classes.GroupInfo}>
                          <p className={classes.Info}>
                            <label><PersonOutlineIcon fontSize="small"/></label>
                            { addressItem.name || `-` }
                          </p>
                          <p className={classes.Info}>
                            <label><PhoneOutlinedIcon fontSize="small"/></label>
                            { addressItem.phone || `-` }
                          </p>
                          <p className={classes.Info}>
                            <label><RoomOutlinedIcon fontSize="small"/></label>
                            { getFullAddressStr(
                                addressItem.address,
                                addressItem.getcare_ward,
                                addressItem.getcare_district,
                                addressItem.getcare_province,
                                addressItem.getcare_country
                            ) }
                          </p>
                        </div>
                      </div>
                      { isEditAllowed && 
                        <div className={classes.ActionIcons}>
                          <IconButton onClick={(e) => this.handleDeleteDeliveryAddress(addressItem)}>
                            <DeleteOutline fontSize="small" />
                          </IconButton>
                          <IconButton onClick={(e) => this.handleEditDeliveryAddress(addressItem)}>
                            <EditOutlined fontSize="small" color="primary" />
                          </IconButton>
                        </div>
                      }
                    </>
                }
              </div>
            ))
          : <p className="NoData">Không có địa chỉ nào</p>
        }

        { isConfirmDialogOpen
          && <ConfirmationDialog
            isOpen={isConfirmDialogOpen}
            title="Xác nhận xoá"
            isLoading={isStoreActionLoading}
            message={<>
              Bạn có chắc xoá địa chỉ nhận hàng{` `}
              <strong>{selectedAddress?.label}</strong>{` `}
              ra khỏi khách hàng này?
            </>}
            onClose={this.handleConfirmDialogClose}
            onSubmit={this.handleSubmitDeleteDeliveryAddress}
          />
        }
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  storeDeliveryAddressList: makeSelectStoreDeliveryAddressList(),
  isStoreActionLoading: makeSelectStoreActionLoading(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getStoreDeliveryAddressList: (payload) => dispatch(getStoreDeliveryAddressList(payload)),
    updateStoreDeliveryAddress: (payload) => dispatch(updateStoreDeliveryAddress(payload)),
    deleteStoreDeliveryAddress: (payload) => dispatch(deleteStoreDeliveryAddress(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(StoreDeliveryInfo);