import {
  GET_VENDOR_PRODUCT_LIST,
  SAVE_VENDOR_PRODUCT_LIST,
  SAVE_VENDOR_PRODUCT_LIST_TOTAL,
  SAVE_LOADING,
  SAVE_UNMAP,
  SAVE_APPROVAL,
  REMOVE_VENDOR_PRODUCT_ITEMS,
  SAVE_VENDOR_PRODUCT_ITEM,
  SAVE_ORI_VENDOR_PRODUCT_LIST,
  UPDATE_VENDOR_PRODUCT_LIST,
  SAVE_ACTION_LOADING,
  UNMAP_VENDOR_PRODUCT_ITEMS,
  APPROVAL_VENDOR_PRODUCT_ITEMS,
  EXPORT_VENDOR_PRODUCT_LIST,
  EXPORT_VENDOR_PRODUCT_LIST_TEMPLATE,
  SAVE_VENDOR_PRODUCT_ALLOWED_ACTIONS,
  SAVE_VENDOR_PRODUCT_VISIBILITY,
} from 'redux/constants/admin/vendorProductConstants';

export function getVendorProductList(payload) {
  return {
    type: GET_VENDOR_PRODUCT_LIST,
    payload
  };
}
export function updateVendorProductList(payload) {
  return {
    type: UPDATE_VENDOR_PRODUCT_LIST,
    payload
  };
}
export function exportVendorProductList(payload) {
  return {
    type: EXPORT_VENDOR_PRODUCT_LIST,
    payload
  };
}
export function exportVendorProductListTemplate(payload) {
  return {
    type: EXPORT_VENDOR_PRODUCT_LIST_TEMPLATE,
    payload
  };
}

// mutation
export function saveLoading(loading) {
  return {
    type: SAVE_LOADING,
    loading
  };
}
export function saveUnMap(unmap) {
  return {
    type: SAVE_UNMAP,
    unmap
  };
}

export function saveApproval(approval) {
  return {
    type: SAVE_APPROVAL,
    approval
  };
}

export function saveActionLoading(actionLoading) {
  return {
    type: SAVE_ACTION_LOADING,
    actionLoading
  };
}
export function saveVendorProductList(vendorProductList) {
  return {
    type: SAVE_VENDOR_PRODUCT_LIST,
    vendorProductList
  };
}
export function saveVendorProductListTotal(vendorProductListTotal) {
  return {
    type: SAVE_VENDOR_PRODUCT_LIST_TOTAL,
    vendorProductListTotal
  };
}
export function removeVendorProductItems(vendorProductItems) {
  return {
    type: REMOVE_VENDOR_PRODUCT_ITEMS,
    vendorProductItems
  };
}

export function unMapVendorProductItems(payload) {
  return {
    type: UNMAP_VENDOR_PRODUCT_ITEMS,
    payload
  };
}


export function approvalVendorProductItems(payload) {
  return {
    type: APPROVAL_VENDOR_PRODUCT_ITEMS,
    payload
  };
}

export function saveVendorProductItem(vendorProductItem) {
  return {
    type: SAVE_VENDOR_PRODUCT_ITEM,
    vendorProductItem
  };
}

export function saveOriVendorProductList(oriVendorProductList) {
  return {
    type: SAVE_ORI_VENDOR_PRODUCT_LIST,
    oriVendorProductList
  };
}

export function saveVendorProductAllowedActions(vendorProductAllowedActions) {
  return {
    type: SAVE_VENDOR_PRODUCT_ALLOWED_ACTIONS,
    vendorProductAllowedActions
  };
}
export function saveVendorProductVisibility(vendorProductVisibility) {
  return {
    type: SAVE_VENDOR_PRODUCT_VISIBILITY,
    vendorProductVisibility
  };
}
