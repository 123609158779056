import {
  GET_SALES_TEAM_LIST,
  SAVE_SALES_TEAM_LIST,
  SAVE_SALES_TEAM_LIST_TOTAL,
  GET_SALES_TEAM,
  CREATE_SALES_TEAM,
  UPDATE_SALES_TEAM,
  SAVE_SALES_TEAM_DETAILS,
  SAVE_SALES_TEAM_MEMBER,
  SAVE_SALES_TEAM_MEMBERS,
  SAVE_SALES_TEAM_CHANNELS,
  SAVE_SALES_TEAM_CHANNEL,
  SAVE_SALES_TEAM_KEY_ACCOUNTS,
  GET_SALES_ROLES,
  SAVE_SALES_ROLES,
  RESET_SALES_TEAM,
  REMOVE_SALES_TEAM_MEMBER,
  REMOVE_SALES_TEAM_CHANNEL,
} from 'redux/constants/admin/salesTeamConstants';

export function getSalesTeamList(payload) {
  return {
    type: GET_SALES_TEAM_LIST,
    payload
  };
}
export function getSalesTeam(id) {
  return {
    type: GET_SALES_TEAM,
    id
  };
}
export function createSalesTeam(payload) {
  return {
    type: CREATE_SALES_TEAM,
    payload
  };
}
export function updateSalesTeam(payload) {
  return {
    type: UPDATE_SALES_TEAM,
    payload
  };
}
export function getSalesRoles(payload) {
  return {
    type: GET_SALES_ROLES,
    payload
  };
}

// mutation
export function saveSalesTeamList(salesTeamList) {
  return {
    type: SAVE_SALES_TEAM_LIST,
    salesTeamList
  };
}
export function saveSalesTeamListTotal(salesTeamListTotal) {
  return {
    type: SAVE_SALES_TEAM_LIST_TOTAL,
    salesTeamListTotal
  };
}
export function resetSalesTeam(payload) {
  return {
    type: RESET_SALES_TEAM,
    payload
  };
}
export function saveSalesTeamDetails(salesTeamDetails) {
  return {
    type: SAVE_SALES_TEAM_DETAILS,
    salesTeamDetails
  };
}

export function saveSalesTeamMember(salesTeamMember) {
  return {
    type: SAVE_SALES_TEAM_MEMBER,
    salesTeamMember
  };
}
export function removeSalesTeamMember(salesTeamMember) {
  return {
    type: REMOVE_SALES_TEAM_MEMBER,
    salesTeamMember
  };
}
export function saveSalesTeamMembers(salesTeamMembers) {
  return {
    type: SAVE_SALES_TEAM_MEMBERS,
    salesTeamMembers
  };
}

export function saveSalesTeamChannels(salesTeamChannels) {
  return {
    type: SAVE_SALES_TEAM_CHANNELS,
    salesTeamChannels
  };
}
export function saveSalesTeamChannel(salesTeamChannel) {
  return {
    type: SAVE_SALES_TEAM_CHANNEL,
    salesTeamChannel
  };
}
export function removeSalesTeamChannel(salesTeamChannel) {
  return {
    type: REMOVE_SALES_TEAM_CHANNEL,
    salesTeamChannel
  };
}

export function saveSalesTeamKeyAccounts(salesTeamKeyAccounts) {
  return {
    type: SAVE_SALES_TEAM_KEY_ACCOUNTS,
    salesTeamKeyAccounts
  };
}
export function saveSalesRoles(salesRoles) {
  return {
    type: SAVE_SALES_ROLES,
    salesRoles
  };
}
