import React, { PureComponent } from 'react';
import { compose } from 'redux';

import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import {
  userStatusMap,
  userApprovalMap,
  isWorking,
  STATUS_HAS_RETIRED,
  STATUS_WORKING,
  userTypeMap,
  isVendorType,
} from 'utils/constanst/adminUserConstants';
import eventBus from 'eventBus';

import {
  Box,
  Checkbox,
  Tooltip,
} from '@material-ui/core';
import EditOutlined from '@material-ui/icons/EditOutlined';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import CheckIcon from '@material-ui/icons/Check';

import gridClasses from '../UserListGrid.module.scss';
import classes from './UserListItem.module.scss';

class UserListItem extends PureComponent {
  _goToDetails = () => {
    this.props.history.push(`/user/${this.props.id}`);
  }
  _stopPropagation = (e) => {
    e.stopPropagation();
  }

  handleStatusChange = (e) => {
    this.props.onStatusChange({
      id: this.props.id,
      name: this.props.name,
      status: isWorking(this.props.status) ? STATUS_HAS_RETIRED : STATUS_WORKING,
    });
  }
  handleApproveChange = (e) => {
    e.stopPropagation();
    e.preventDefault();
    eventBus.dispatch("onApproveChange",{
      id: this.props.id,
      approval: !this.props.approval,
    })
  }

  render() {
    const {
      id,
      code,
      name,
      email,
      phone,
      getcare_department,
      status,
      user_type,
      getcare_vendor,
      approval,
    } = this.props;

    return <Link
      className={`${classes.Item} ${gridClasses.Row}`}
      to={`/user/${id}`}
    >
      <div className={`${gridClasses.Col}`}>{ code }</div>
      <div className={gridClasses.Col}>{ name }</div>
      <div className={gridClasses.Col}>
        { isVendorType(user_type) && getcare_vendor ? getcare_vendor.name : userTypeMap[user_type] }
      </div>
      <div className={gridClasses.Col}>{ email }</div>
      <div className={gridClasses.Col}>{ phone }</div>
      <div className={gridClasses.Col}>{ getcare_department?.name }</div>
      <div className={gridClasses.Col} >
        <Box display="flex" flexWrap="nowrap" alignItems="center">
          <Box whiteSpace="nowrap">{ userApprovalMap[approval] }</Box>
          <Box>
            <Tooltip placement="top" title="Duyệt nhân viên">
              <Checkbox
                checked={approval}
                onClick={this.handleApproveChange}
              />
            </Tooltip>
          </Box>
        </Box>
      </div>
      <div className={gridClasses.Col}>{ userStatusMap[status] }</div>
      <div className={`${gridClasses.Col} TextRight`}>
        <IconButton size="small" onClick={this._goToDetails}>
          <EditOutlined fontSize="small" color="primary"/>
        </IconButton>
        <Switch
          size="small"
          checked={isWorking(status)}
          onClick={this._stopPropagation}
          onChange={this.handleStatusChange}
          color="primary"
          name="status"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      </div>
    </Link>;
  }
}

export default compose(withRouter)(UserListItem);
