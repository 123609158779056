import {
  SAVE_ASSET_BRANDING_LIST,
  SAVE_ASSET_BRANDING_LIST_TOTAL,
  SAVE_LOADING,
  REMOVE_ASSET_BRANDING_ITEMS,
  SAVE_ASSET_BRANDING_ITEM,
  SAVE_ACTION_LOADING,
  SAVE_ASSET_BRANDING_ALLOWED_ACTIONS,
  SAVE_ASSET_BRANDING_VISIBILITY,
} from 'redux/constants/admin/assetBrandingConstants';

const initialState = {
  assetBrandingList: [],
  assetBrandingListTotal: 0,
  loading: false,
  actionLoading: false,
  assetBrandingAllowedActions: [],
  assetBrandingVisibility: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_LOADING: {
      return {
        ...state,
        loading: action.loading,
      };
    }
    case SAVE_ACTION_LOADING: {
      return {
        ...state,
        actionLoading: action.actionLoading,
      };
    }
    case SAVE_ASSET_BRANDING_ITEM: {
      const list = [...state.assetBrandingList];
      let newTotal = state.assetBrandingListTotal;
      const comparedField = action.assetBrandingItem.idStr
        ? 'idStr'
        : 'id'; // ???
      const index = list.findIndex((p) => p[comparedField] === action.assetBrandingItem[comparedField]);
      if (index > -1) {
        const tempItem = { ...list[index] };
        list[index] = { ...tempItem, ...action.assetBrandingItem };
      } else {
        list.unshift({ ...action.assetBrandingItem });
        newTotal++;
      }
      return {
        ...state,
        assetBrandingList: list,
        assetBrandingListTotal: newTotal,
      };
    }
    case REMOVE_ASSET_BRANDING_ITEMS: {
      const newTotal = state.assetBrandingListTotal - action.assetBrandingItems.length;
      const list = state.assetBrandingList.filter(
        (p) =>
          !action.assetBrandingItems.some((item) => {
            const comparedField = item.idStr ? 'idStr' : 'id';
            return item[comparedField] === p[comparedField];
          })
      );
      return {
        ...state,
        assetBrandingList: list,
        assetBrandingListTotal: newTotal,
      };
    }
    case SAVE_ASSET_BRANDING_LIST: {
      const list = action.assetBrandingList;
      return {
        ...state,
        assetBrandingList: list ? [...list] : [],
      };
    }
    case SAVE_ASSET_BRANDING_LIST_TOTAL: {
      return {
        ...state,
        assetBrandingListTotal: action.assetBrandingListTotal,
      };
    }

    // case SAVE_ORI_VENDOR_PRODUCT_LIST: {
    //   const list = action.oriVendorProductList;
    //   return {
    //     ...state,
    //     oriVendorProductList: list ? [...list] : [],
    //   };
    // }

    case SAVE_ASSET_BRANDING_ALLOWED_ACTIONS: {
      return {
        ...state,
        assetBrandingAllowedActions: [...action.assetBrandingAllowedActions],
      };
    }
    case SAVE_ASSET_BRANDING_VISIBILITY: {
      return {
        ...state,
        assetBrandingVisibility: action.assetBrandingVisibility ? {...action.assetBrandingVisibility} : null,
      };
    }

    default:
      return state;
  }
}

export default reducer;
