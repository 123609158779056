export const thumbnailResize = 320;
export const relatedProductsPageSize = 20;
export const listDisplayFields = [
  {
    name: 'getcare_id',
    label: 'Phahub ID',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'name',
    label: 'Tên thuốc',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'registration_number',
    label: 'Số đăng ký',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'package',
    label: 'Quy cách đóng gói\nraw data',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'getcare_uom_name',
    label: 'Quy cách đóng gói',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'dosage_forms',
    label: 'Dạng bào chế',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'getcare_manufacturer_name',
    label: 'Nhà sản xuất',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'active_ingredients_main',
    label: 'Hoạt chất\nraw data',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'getcare_product_active_ingredients',
    label: 'Thành phần',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'getcare_product_category_ecom_items',
    label: 'Danh mục',
    sortable: false,
    sortDir: '',
  },
]

export const listParamsMap = {
  page_size: 50,
  page: 1,
  order: '',
  getcare_id: '',
  name: '',
  registration_number: '',
  getcare_uom_name: '',
  package: '',
  dosage_forms: '',
  expiry_date: '',
  getcare_manufacturer_name: '',
  getcare_manufacturer_getcare_country_name: '',
  l6_name: '',
  active_ingredients_main: '',
  getcare_product_active_ingredients: '',
  getcare_product_category_ecom_items: '',
}

export const filterFields = [
  {
    name: 'getcare_id',
    label: 'Phahub ID',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'name',
    label: 'Tên thuốc',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'active_ingredients_main',
    label: 'Hoạt chất raw data',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'l6_name',
    label: 'Thành phần',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'registration_number',
    label: 'Số đăng ký',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'package',
    label: 'Quy cách đóng gói raw data',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'getcare_uom_name',
    label: 'Quy cách đóng gói',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'dosage_forms',
    label: 'Dạng bào chế',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'expiry_date',
    label: 'Hạn sử dụng',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'getcare_manufacturer_name',
    label: 'Nhà sản xuất',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'getcare_manufacturer_getcare_country_name',
    label: 'Sản xuất tại',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'getcare_category_ecom_id',
    label: 'Danh mục',
    value: '',
    type: 'custom',
    placeholder: 'Nhập...',
  },
]

export const medicineListParamsMap = {
  page_size: 50,
  page: 1,
  order: '',
  getcare_pharmacy_id: '',
}
export const medicineListDisplayFields = [
  {
    name: 'getcare_id',
    label: 'Phahub ID',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'code',
    label: 'Mã sản phẩm',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'name',
    label: 'Tên sản phẩm',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'registration_number',
    label: 'Số đăng ký',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'active',
    label: 'Tình trạng',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'getcare_uom_name',
    label: 'Quy cách đóng gói',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'getcare_uom_uom_base_name',
    label: 'Đơn vị cơ bản',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'getcare_uom_smallest_mou',
    label: 'Đơn vị bán lẻ',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'getcare_uom_uom_base_name',
    label: 'Giá bán cơ bản',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'getcare_uom_uom_base_name',
    label: 'Giá bán lẻ',
    sortable: true,
    sortDir: '',
  },
]

export const otherListParamsMap = {
  page_size: 50,
  page: 1,
  order: '',
  getcare_pharmacy_id: '',
}
export const otherListDisplayFields = [
  {
    name: 'id',
    label: 'ID',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'name',
    label: 'Tên sản phẩm',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'code',
    label: 'Mã/Model',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'getcare_product_industry_id',
    label: 'Ngành hàng',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'getcare_product_brand_id',
    label: 'Nhãn hàng',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'attributes',
    label: 'Đặc điểm/Thuộc tính SP',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'unit',
    label: 'ĐVT',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'quantity',
    label: 'Số lượng',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'description',
    label: 'Bộ sản phẩm bao gồm',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'price',
    label: 'Giá',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'seller_sku',
    label: 'Seller SKU',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'guarantee',
    label: 'Bảo hành/HSD',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'size',
    label: 'Kích thước',
    sortable: true,
    sortDir: '',
  },
]

export const awaitingListParamsMap = {
  page_size: 50,
  page: 1,
  order: '',
  getcare_pharmacy_id: '',
}
export const awaitingListDisplayFields = [
  {
    name: 'name',
    label: 'Tên sản phẩm',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'code',
    label: 'Mã/Model',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'getcare_product_industry_id',
    label: 'Ngành hàng',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'getcare_product_brand_id',
    label: 'Nhãn hàng',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'attributes',
    label: 'Đặc điểm/Thuộc tính SP',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'unit',
    label: 'ĐVT',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'description',
    label: 'Bộ sản phẩm bao gồm',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'price',
    label: 'Giá bán lẻ',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'seller_sku',
    label: 'Seller SKU',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'guarantee',
    label: 'Bảo hành/HSD',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'size',
    label: 'Kích thước',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'unit',
    label: 'Bao bì',
    sortable: true,
    sortDir: '',
  },
]
