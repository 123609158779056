import axiosAuth from 'utils/axios/axiosAuth';

const SHIPPER_API = 'getcare_transporter_user/';

const shipperApi = {
  getShippers: async (filters) => {
    let params = null;
    if (filters && filters.length) {
      // Build query string in case of having filters given
      params = filters
        .filter(item => !!item.value)
        .reduce((obj, item) => Object.assign(obj, {
          [item.name]: item.value
        }), {});
    }
    const response = await axiosAuth.get(SHIPPER_API, { params });
    return response?.data;
  },

  getShipper: async (id) => {
    const response = await axiosAuth.get(`${SHIPPER_API}${id}`);
    return response?.data;
  },

  createShipper: async (data) => {
    const response = await axiosAuth.post(SHIPPER_API, data);
    return response?.data;
  },

  updateShipper: async (id, data) => {
    const response = await axiosAuth.put(`${SHIPPER_API}${id}`, data);
    return response?.data;
  },
}

export default shipperApi;
