import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { currencyFormatVN } from 'utils/helper';

import gridClasses from '../OtherProductListGrid.module.scss';
import classes from './OtherProductListItem.module.scss';

class OtherProductListItem extends PureComponent {
  findBrandById = (id) => {
    return this.props.brands.find(item => item.id === id);
  }

  findIndustryById = (id) => {
    return this.props.industries.find(item => item.id === id);
  }

  render() {
    const {
      id,
      name,
      code,
      getcare_product_industry_id,
      getcare_product_brand_id,
      attributes,
      unit,
      quantity,
      description,
      price,
      seller_sku,
      guarantee,
      size,
    } = this.props;
    const brand = this.findBrandById(getcare_product_brand_id);
    const industry = this.findIndustryById(getcare_product_industry_id);

    return <div
      className={`${classes.ProductItem} ${gridClasses.Row}`}
    >
      <div className={gridClasses.Col}>{ id }</div>
      <div className={gridClasses.Col}>{ name }</div>
      <div className={gridClasses.Col}>{ code }</div>
      <div className={gridClasses.Col}>{ industry?.name }</div>
      <div className={gridClasses.Col}>{ brand?.name }</div>
      <div className={gridClasses.Col}>{ attributes }</div>
      <div className={gridClasses.Col}>{ unit }</div>
      <div className={gridClasses.Col}>{ quantity }</div>
      <div className={gridClasses.Col}>{ description }</div>
      <div className={gridClasses.Col}>{ currencyFormatVN(price) }</div>
      <div className={gridClasses.Col}>{ seller_sku }</div>
      <div className={gridClasses.Col}>{ guarantee }</div>
      <div className={gridClasses.Col}>{ size }</div>
    </div>;
  }
}

OtherProductListItem.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  code: PropTypes.string,
  getcare_product_industry_id: PropTypes.number,
  getcare_product_brand_id: PropTypes.number,
  attributes: PropTypes.string,
  unit: PropTypes.string,
  quantity: PropTypes.number,
  description: PropTypes.string,
  price: PropTypes.number,
  seller_sku: PropTypes.string,
  guarantee: PropTypes.string,
  size: PropTypes.string,
  brands: PropTypes.array,
  industries: PropTypes.array,
};

OtherProductListItem.defaultProps = {
  brands: [],
  industries: [],
};

export default OtherProductListItem;
