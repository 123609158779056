import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { filterFields } from 'utils/constanst/adminKeyAccountLockConstants';

import FilterField from 'components/FilterField/FilterField';
import DateRange from 'components/FilterField/DateRange/DateRange';

import classes from './KeyAccountLockListFilters.module.scss';

class KeyAccountLockListFilters extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fields: [...filterFields],
    };
  }

  _getFieldIndex = (fieldName) => {
    return this.state.fields.findIndex((item) => item.name === fieldName);
  };

  handleFieldChange = ({ name, value }) => {
    const newFields = this.state.fields.map(field => field.name === name ? { ...field, value } : field);
    this.setState({
      fields: newFields,
    });
    this.props.onFilterChange(newFields, true);
  };

  render() {
    const { fields } = this.state;

    return (
      <div className={classes.Panel}>
        <h3 className={classes.PanelTitle}>Lọc theo:</h3>
        <div className={classes.PanelContent}>
          {fields.map((field) => (
            ['start_date'].includes(field.name) ? (
              <FilterField
                key={field.name}
                {...field}
                defaultValue={['', '']}
                customField={<DateRange
                  name={field.name}
                  value={field.value}
                  handleFieldChange={this.handleFieldChange}
                />}
                handleFieldChange={this.handleFieldChange}
              />
            ) : <FilterField
              key={field.name}
              {...field}
              handleFieldChange={this.handleFieldChange}
            />
          ))}
        </div>
      </div>
    );
  }
}

KeyAccountLockListFilters.propTypes = {
  onFilterChange: PropTypes.func,
};

export default KeyAccountLockListFilters;
