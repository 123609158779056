import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { debounce } from 'lodash';
import { withRouter } from 'react-router';
import { getIngredientList } from 'redux/actions/admin/ingredientActions';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectIngredientList,
  makeSelectIngredientTotal,
} from 'redux/selectors';
import {
  approvedListParamsMap,
  listDisplayFields,
} from 'utils/constanst/adminIngredientConstants';

import IngredientList from 'components/admin/IngredientList/IngredientList';
import IngredientFilters from 'components/admin/IngredientList/IngredientFilters/IngredientFilters';
import ListPagination from 'components/ListPagination/ListPagination';

import classes from './Ingredients.module.scss';

class Ingredients extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      listParams: { ...approvedListParamsMap },
    };
  }

  componentDidMount() {
    let params = { ...this.state.listParams };
    const { location, history } = this.props;
    if (location && location.state && location.state.ingredientId) {
      params.id = location.state.ingredientId;

      this.setState({
        listParams: { ...params },
      });

      // reset the state of location
      history.replace();
    }
    this.props.getIngredientList({ params: params });
  }

  getSortsMap = (sortStr) => {
    const sortArr = sortStr.split(',');
    return sortArr.reduce((memo, item) => {
      const arr = item.split(' ');
      if (arr[0] && arr[0].trim()) {
        memo[arr[0]] = arr[1] ? arr[1] : '';
      }
      return memo;
    }, {});
  };

  getSortsString = (sortMap) => {
    return Object.keys(sortMap)
      .reduce((memo, key) => {
        memo.push(`${key} ${sortMap[key]}`);
        return memo;
      }, [])
      .join(',');
  };

  getDisplayFields = () => {
    const sortsMap = this.getSortsMap(this.state.listParams.order);
    return listDisplayFields.map((item) => ({
      ...item,
      sortDir: sortsMap[item.name],
    }));
  };

  handleFilterChange = (filters, forceResetPage: false) => {
    const paramsFromFilters = filters.reduce((memo, item) => {
      memo[item.name] = item.value;
      return memo;
    }, {});
    const newParams = forceResetPage ? {
      ...this.state.listParams,
      ...paramsFromFilters,
      page: 1,
    } : {
      ...this.state.listParams,
      ...paramsFromFilters,
    };
    this.setState({
      listParams: newParams,
    });
    this.props.getIngredientList({
      params: newParams,
    });
  };

  handleSortChange = ({ sortBy, sortDir }) => {
    const newSortString = this.getSortsString({ [sortBy]: sortDir });
    this.handleFilterChange([
      {
        name: 'order',
        value: newSortString,
      },
    ]);
  };

  render() {
    const { ingredientList, ingredientTotal } = this.props;
    const { listParams } = this.state;
    const isListLoading = !ingredientList;

    return (
      <React.Fragment>
        <div className={classes.PageWrap}>
          <div className={classes.PageHeader}>
            <h1 className={classes.PageTitle}>Danh mục</h1>
          </div>
          <div className={classes.PageMain}>
            <IngredientFilters
              listParams={listParams}
              onFilterChange={debounce(this.handleFilterChange, 500)}
            />
            <IngredientList
              displayFields={this.getDisplayFields()}
              ingredientList={ingredientList}
              isLoading={isListLoading}
              onSortChange={this.handleSortChange}
            />
          </div>
          <div className={classes.PageFooter}>
            <ListPagination
              page={listParams.page}
              pageSize={listParams.page_size}
              total={ingredientTotal}
              listName="danh mục"
              onFilterChange={this.handleFilterChange}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  ingredientList: makeSelectIngredientList(),
  ingredientTotal: makeSelectIngredientTotal(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getIngredientList: (payload) => dispatch(getIngredientList(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter)(Ingredients);
