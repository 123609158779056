import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { filterFields } from 'utils/constanst/adminPolicyPriceContants';
import { ACTIVE, activeStatusMap } from 'utils/constanst/common';

import FilterField from 'components/FilterField/FilterField';
import Checkboxes from 'components/FilterField/Checkboxes';
import EndDateGroupFilter from './EndDateGroupFilter/EndDateGroupFilter';

import classes from './PolicyPriceListFilters.module.scss';

class PolicyPriceListFilters extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fields: [...filterFields],
    };
  }
  _getFieldIndex = (fieldName) => {
    return this.state.fields.findIndex((item) => item.name === fieldName);
  };
  handleFieldChange = ({ name, value }) => {
    const newFields = this.state.fields.map(field => field.name === name ? { ...field, value } : field);
    this.setState({
      fields: newFields,
    });
    this.props.onFilterChange(newFields, true);
  };

  render() {
    const { fields } = this.state;
    const statusFilterMap = {
      [ACTIVE]: activeStatusMap[ACTIVE],
    };

    return (
      <div className={classes.Panel}>
        <h3 className={classes.PanelTitle}>Lọc theo:</h3>
        <div className={classes.PanelContent}>
          {fields.map((field) => (
            <FilterField
              key={field.name}
              {...field}
              defaultValue={['end_date_group'].includes(field.name) ? ['', ''] : ''}
              customField={
                field.name === 'active' ? (
                  <Checkboxes
                    name={field.name}
                    value={field.value}
                    valuesMap={statusFilterMap}
                    handleFieldChange={this.handleFieldChange}
                  />
                ) : field.name === 'end_date_group' ? (
                  <EndDateGroupFilter
                    name={field.name}
                    value={field.value}
                    handleFieldChange={this.handleFieldChange}
                  />
                ) : ''
              }
              handleFieldChange={this.handleFieldChange}
            />
          ))}
        </div>
      </div>
    );
  }
}

PolicyPriceListFilters.propTypes = {
  onFilterChange: PropTypes.func,
};

export default PolicyPriceListFilters;
