import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { makeSelectLoginUser } from 'redux/selectors';
import storeApi from 'utils/api/admin/storeApi';
import { toast } from 'react-toastify';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Close from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import classes from './DeactiveCustomerDialog.module.scss';
import {withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);


class DeactiveCustomerDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      id: this.props.id,
      listReasond: []
    }
  }


 async componentDidMount() {
  const { data: response } = await storeApi.getReason();
    this.setState({
      listReasond:response.data,
    })
  }


  handleSubmit = async (values) => {
    const {listReasond} = this.state;
    const valueReasond = listReasond.find( x => x.id === values.selectMethod);
    if (valueReasond.id === -1 ){
      const params = {
          id : this.state.id,
          getcare_deactive_reason_id: -1,
          deactive_reason: values.otherReason
      }
      if (this.props.isVendor) {
        const { data: response } = await storeApi.updateVendorReason({params});
        if (response?.result ===  false) {
          toast.error(response?.message );
        }else{
          toast.success('Update thành công ');
          window.location.reload();
        }
      }else{
        const { data: response } = await storeApi.updateCustomerReason({params});
        if (response?.result ===  false) {
          toast.error(response?.message );
        }else{
          toast.success('Update thành công ');
          window.location.reload();
        }
      }
    }else{
      const params = {
        id : this.state.id,
        getcare_deactive_reason_id: values.selectMethod,
        deactive_reason: valueReasond.reason
    }
    if (this.props.isVendor) {
      const { data: response } = await storeApi.updateVendorReason({params});
      if (response?.result ===  false) {
        toast.error(response?.message );
      }else{
        toast.success('Update thành công ');
        window.location.reload();
      }
    }else{
      const { data: response } = await storeApi.updateCustomerReason({params});
      if (response?.result ===  false) {
        toast.error(response?.message );
      }else{
        toast.success('Update thành công ');
        window.location.reload();
      }
    }
  }
  };
  onClose = () => {
    this.props.onClose();
  };


  render() {
    const { isOpen } = this.props;
    const { isLoading,listReasond} = this.state;

    return (
      <Dialog
        open={isOpen}
        onClose={this.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.Dialog}
      >
        <div className={`${classes.DialogContentWrap} ${isLoading && 'OverlayLoading'}`}>
          <div className={classes.DialogTitle}>
            <DialogTitle>Dừng hoạt động</DialogTitle>
            <IconButton size="small" onClick={this.props.onClose}><Close/></IconButton>
          </div>
          <Formik
            initialValues={{
              selectMethod: 1,
              otherReason: ''
            }}
            enableReinitialize={true}
            validationSchema={Yup.object().shape({
              selectMethod: Yup.number().required("Vui lòng chọn"),
              otherReason: Yup.string().trim().when('selectMethod', { 
                is: value => value && value === -1,
                then: Yup.string().trim().max(255, 'Tối đa 255 ký tự').required("Vui lòng nhập")
              })
            })}
            onSubmit={(values) => {
              console.log(values);
              this.handleSubmit(values);
            }}
          >
            {(props) => {
              const {
                values,
                errors,
                touched,
                handleSubmit,
                setFieldValue,
              } = props;
              return (
                <form noValidate autoComplete="off">
                  <DialogContent className={classes.DialogContent}>
                
  <div className={classes.ProductOptions}>
                    <span className={classes.ProductOptionsLabel}>Hoạt động</span>
                    <FormControl className={classes.margin}>
        <Select
          labelId="demo-customized-select-label"
          id="demo-customized-select"
          value={values.selectMethod}
          onChange={(e, newValue) => {
            setFieldValue('selectMethod', e.target.value ? e.target.value  : '');
          }}
          input={<BootstrapInput />}
        >
           { listReasond && listReasond.map( item => {
                        return <MenuItem key={item.id} value={item.id}>{item.reason}</MenuItem>
                      })}
          
        </Select>
        <FormHelperText>{(errors.selectMethod && touched.selectMethod) && errors.selectMethod}</FormHelperText>
      </FormControl>
            </div>
            {
                    values.selectMethod === -1 ? 
                   <>
                    <TextField 
                     fullWidth
                     placeholder="Nhập lý do khác...."
                     error={!!errors.otherReason}
                     helperText={errors.otherReason}
                     value={values.otherReason}
                      onChange={(e) => {
                      setFieldValue('otherReason', e.target.value ? e.target.value  : '');
                    }}
                    />
                   </>
                      : null
                  }
                  </DialogContent>
                  <DialogActions className={classes.DialogActions}>
                    <Button type="button" variant="outlined" onClick={this.props.onClose}>
                      Huỷ bỏ
                    </Button>
                    <Button
                      type="button"
                      variant="contained"
                      // disabled={!isEmpty(errors) || !list.length}
                      onClick={handleSubmit}
                      color="primary"
                    >
                      Xác nhận
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          </Formik>
        </div>
      </Dialog>
    );
  }
}



const mapStateToProps = createStructuredSelector({
  user: makeSelectLoginUser(),
});

const withConnect = connect(mapStateToProps, null);
export default compose(withConnect, withRouter)(DeactiveCustomerDialog);

