import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { toast } from 'react-toastify';
import { isEqual } from 'lodash';
import { Formik } from 'formik';
import * as Yup from 'yup';

import {
  makeSelectOriKeyAccountDetails,
  makeSelectKeyAccountDetails,
  makeSelectOriKeyAccountSalesUsers,
  makeSelectKeyAccountSalesUsers,
  makeSelectOriKeyAccountCustomers,
  makeSelectKeyAccountCustomers,
  makeSelectOriKeyAccountProducts,
  makeSelectKeyAccountProducts,
  makeSelectKeyAccountLoading,
} from 'redux/selectors';
import {
  getKeyAccount,
  resetKeyAccount,
  updateKeyAccount,
  saveKeyAccountDetails,
} from 'redux/actions/admin/keyAccountActions';
import { CUSTOMER_TYPE_ID, GROUP_CUSTOMER_TYPE_ID, SALES_USER_MODE_ALL, CUSTOMER_MODE_ALL } from 'utils/constanst/adminKeyAccountConstants';

import { genID } from 'utils/helper';

import Button from '@material-ui/core/Button';
import ArrowBack from '@material-ui/icons/ArrowBack';
import CheckOutlined from '@material-ui/icons/CheckOutlined';

import KeyAccountDetails from 'components/admin/KeyAccountDetails/KeyAccountDetails';
import KeyAccountSalesUsers from 'components/admin/KeyAccountSalesUsers/KeyAccountSalesUsers';
import KeyAccountVendor from 'components/admin/KeyAccountVendor/KeyAccountVendor';
import KeyAccountProducts from 'components/admin/KeyAccountProducts/KeyAccountProducts';
import KeyAccountCustomers from 'components/admin/KeyAccountCustomers/KeyAccountCustomers';
import KeyAccountHistory from 'components/admin/KeyAccountHistory/KeyAccountHistory';

import classes from './KeyAccount.module.scss';

class KeyAccount extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      newFormId: null,
    }
    this.formRef = null;
  }
  componentDidMount() {
    const { id } = this.props.match.params;
    this.loadData(id);
  }
  componentDidUpdate(prevProps, prevState) {
    const { id } = this.props.match.params;
    if (id !== prevProps.match.params.id) {
      this.loadData(id);
    }
    const { keyAccountDetails } = this.props;
    if (keyAccountDetails && keyAccountDetails.id) {
      if (!prevProps.keyAccountDetails || keyAccountDetails.id !== prevProps.keyAccountDetails.id) {
        if (
          !prevProps.keyAccountDetails ||
          !prevProps.keyAccountDetails.id ||
          isNaN(prevProps.keyAccountDetails.id)
        ) {
          // created successfull
          this.props.history.push(`/keyaccount/${keyAccountDetails.id}`);
          return;
        }
      }
    }
  }

  isValid = () => {
    return true;
  }

  getKeyAccountDetailsContrains = (params) => {
    return {
      name: params.name,
      getcare_vendor_id: params.getcare_vendor?.id,
      sales_mode: params.sales_mode,
      customer_mode: params.customer_mode,
    }
  }
  isDetailsChanged = () => {
    if (!this.isEditing()) return false;
    const { oriKeyAccountDetails, keyAccountDetails } = this.props;
    if (!oriKeyAccountDetails || !keyAccountDetails) return false;
    return !isEqual(this.getKeyAccountDetailsContrains(keyAccountDetails), this.getKeyAccountDetailsContrains(oriKeyAccountDetails));
  }

  getSalesUserContrains = (params) => {
    return {
      id: params.getcare_sales?.id,
    }
  }
  isSalesUsersChanged = () => {
    if (!this.isEditing()) return false;

    const{ keyAccountSalesUsers, oriKeyAccountSalesUsers } = this.props;
    if (!keyAccountSalesUsers || !oriKeyAccountSalesUsers) return false;

    const hasDeletedItem = oriKeyAccountSalesUsers.some(item => item.id && !keyAccountSalesUsers.some(p => p.id && p.id === item.id));
    if (hasDeletedItem) return true;

    return keyAccountSalesUsers.some(tempItem => {
      const tempItemMap = this.getSalesUserContrains(tempItem);
      return !oriKeyAccountSalesUsers.some(item => isEqual(
        tempItemMap,
        this.getSalesUserContrains(item)
      ))
    });
  }

  getCustomerContrains = (params) => {
    return {
      id: params.getcare_customer?.id,
    }
  }
  isCustomersChanged = () => {
    if (!this.isEditing()) return false;

    const{ keyAccountCustomers, oriKeyAccountCustomers } = this.props;
    if (!keyAccountCustomers || !oriKeyAccountCustomers) return false;

    const hasDeletedItem = oriKeyAccountCustomers.some(item => item.id && !keyAccountCustomers.some(p => p.id && p.id === item.id));
    if (hasDeletedItem) return true;

    return keyAccountCustomers.some(tempItem => {
      const tempItemMap = this.getCustomerContrains(tempItem);
      return !oriKeyAccountCustomers.some(item => isEqual(
        tempItemMap,
        this.getCustomerContrains(item)
      ))
    });
  }

  getProductContrains = (params) => {
    return {
      id: params.getcare_product?.id,
      active: params.active,
    }
  }
  isProductsChanged = () => {
    if (!this.isEditing()) return false;

    const{ keyAccountProducts, oriKeyAccountProducts } = this.props;
    if (!keyAccountProducts || !oriKeyAccountProducts) return false;

    const hasDeletedItem = oriKeyAccountProducts.some(item => item.id && !keyAccountProducts.some(p => p.id && p.id === item.id));
    if (hasDeletedItem) return true;

    return keyAccountProducts.some(tempItem => {
      const tempItemMap = this.getProductContrains(tempItem);
      return !oriKeyAccountProducts.some(item => isEqual(
        tempItemMap,
        this.getProductContrains(item)
      ))
    });
  }

  loadData = (id) => {
    if (this.isEditing()) {
      this.props.getKeyAccount(id);
      return;
    }
    this.props.resetKeyAccount({
      ...this.getInitialValues(),
    });
    this.setState({
      newFormId: genID(),
    });
  };

  backToList = () => {
    this.props.history.push('/keyaccount');
  };
  isEditing = () => {
    const { id } = this.props.match.params;
    return id && !isNaN(id) && Number(id) > 0;
  };

  isSalesUsersNotCompleted = () => {
    return this.props.keyAccountSalesUsers.some(item => item.isEditing);
  }
  isSalesUsersValid = () => {
    let messages = [];
    if (!this.isSalesUserModeAll() && !this.props.keyAccountSalesUsers.length) {
      messages.push('• Đặc quyền bán phải có ít nhất một trình dược viên.');
    }
    return messages;
  }
  isSalesUserModeAll = () => {
    return [SALES_USER_MODE_ALL, SALES_USER_MODE_ALL + ``].includes(this.props.keyAccountDetails.sales_mode);
  }

  isCustomersNotCompleted = () => {
    return this.props.keyAccountCustomers.some(item => item.isEditing);
  }
  isCustomersValid = () => {
    let messages = [];
    if (!this.isCustomerModeAll() && !this.props.keyAccountCustomers.length) {
      messages.push('• Đặc quyền bán phải có ít nhất một nhà thuốc.');
    }
    return messages;
  }
  isCustomerModeAll = () => {
    return [CUSTOMER_MODE_ALL, CUSTOMER_MODE_ALL + ``].includes(this.props.keyAccountDetails.customer_mode);
  }

  hasNotCompletedForm = () => {
    return this.isSalesUsersNotCompleted() || this.isCustomersNotCompleted();
  }
  hasWarningMessages = () => {
    if (this.hasNotCompletedForm()) {
      toast.error('Có một dòng bạn đang làm dang dở. Hãy hoàn thành để tiếp tục.');
      return true;
    }
    const messages = [
      ...this.isSalesUsersValid(),
      ...this.isCustomersValid(),
    ];
    if (messages.length) {
      toast.error(messages.join('\n'));
      return true;
    }
    return false;
  }

  prepareData = () => {
    const { keyAccountSalesUsers, keyAccountDetails, keyAccountCustomers, keyAccountProducts } = this.props;
    
    return {
      id: keyAccountDetails?.id,
      name: keyAccountDetails?.name,
      getcare_vendor_id: keyAccountDetails?.getcare_vendor?.id,
      sales_mode: parseFloat(keyAccountDetails?.sales_mode),
      customer_mode: parseFloat(keyAccountDetails?.customer_mode),

      getcare_key_account_sales_items: this.isSalesUserModeAll()
        ? []
        : keyAccountSalesUsers.filter(item => !!item.getcare_sales?.id).map((item, index) => ({
          id: item.id || undefined,
          getcare_sales_id: item.getcare_sales.id,
        })),

      getcare_key_account_product_items: keyAccountProducts.filter(item => item.active).map((item, index) => ({
        id: item.id || undefined,
        getcare_product_id: item.getcare_product?.id,
      })),

      getcare_key_account_customer_items: this.isCustomerModeAll() ? 
        [] : 
          keyAccountCustomers.filter(item => (
            ( item.type_id === CUSTOMER_TYPE_ID && !!item.getcare_customer?.id ) || ( item.type_id === GROUP_CUSTOMER_TYPE_ID && !!item.getcare_erp_group?.id )
          )).map((item, index) => {
            return {
              // id: item.id || undefined,
              type_id: item.type_id,
              getcare_erp_group_id: item.type_id === GROUP_CUSTOMER_TYPE_ID ? item.getcare_erp_group?.id : null,
              getcare_customer_id: item.type_id === CUSTOMER_TYPE_ID ? item.getcare_customer?.id : null,
            }
          }),
    };
  };

  getValidationShape = () => {
    return {
      name: Yup.string().required("Vui lòng nhập").max(255, 'Tối đa 255 ký tự'),
      getcare_vendor_id: Yup.string().required("Vui lòng chọn"),
    };
  }
  getInitialValues = () => {
    if (this.isEditing()) {
      const { keyAccountDetails } = this.props;
      return {
        name: keyAccountDetails?.name,
        sales_mode: keyAccountDetails?.sales_mode,
        getcare_vendor: keyAccountDetails?.getcare_vendor,
        getcare_vendor_id: keyAccountDetails?.getcare_vendor?.id,
        customer_mode: keyAccountDetails?.customer_mode,
      }
    }
    return {
      name: '',
      sales_mode: SALES_USER_MODE_ALL,
      getcare_vendor: null,
      getcare_vendor_id: '',
      customer_mode: CUSTOMER_MODE_ALL,
    }
  }

  handleCanceKeyAccount = () => {
    this.loadData(this.props.match.params.id);
  }
  handleSaveKeyAccount = () => {
    this.formRef.submitForm();
  }
  handleSubmitKeyAccount = (keyAccountDetailsFormValues) => {
    const hasErrors = this.hasWarningMessages();
    if (hasErrors) return;
    this.props.updateKeyAccount({
      params: {
        ...this.prepareData(),
      },
    });
  }
  handleFieldChange = (fieldMap) => {
    this.props.saveKeyAccountDetails({
      ...this.props.keyAccountDetails,
      ...fieldMap,
    });
  }

  render() {
    const { keyAccountDetails } = this.props;
    const isEditing = this.isEditing();
    const isDetailsChanged = this.isDetailsChanged();
    const isSalesUsersChanged = this.isSalesUsersChanged();
    const isCustomersChanged = this.isCustomersChanged();
    const isProductsChanged = this.isProductsChanged();
    const isChanged = isDetailsChanged || isSalesUsersChanged || isCustomersChanged || isProductsChanged;
    const isValid = this.isValid();

    return (
      <div className={`${classes.PageWrap} ${this.props.loading ? 'OverlayLoading' : ''}`}>
        <div className={classes.PageHeader}>
          <h1 className={classes.PageTitle}>
            {isEditing ? `Xem chi tiết đặc quyền bán` : `Tạo đặc quyền bán`}
          </h1>

          {!isEditing && (
            <Button
              size="small"
              variant="outlined"
              startIcon={<ArrowBack />}
              onClick={this.backToList}
            >
              Huỷ và Trở về
            </Button>
          )}

          {isEditing && (
            <>
              <Button
                size="small"
                variant="outlined"
                startIcon={<ArrowBack />}
                onClick={this.backToList}
              >
                Trở về
              </Button>
              <Button
                size="small"
                disabled={(!isEditing && !isValid) || (isEditing && (!isValid || !isChanged))}
                variant="outlined"
                color="secondary"
                onClick={this.handleCanceKeyAccount}
              >
                Huỷ thay đổi
              </Button>
            </>
          )}

          <Button
            size="small"
            variant="contained"
            color="primary"
            disabled={(!isEditing && !isValid) || (isEditing && (!isValid || !isChanged))}
            startIcon={<CheckOutlined />}
            onClick={this.handleSaveKeyAccount}
          >
            {isEditing ? `Xác nhận thay đổi` : `Tạo đặc quyền bán`}
          </Button>
        </div>
        <div
          key={`keyaccount-form-${keyAccountDetails?.id || 'new'}`}
          className={classes.PageMain}
        >
          <Formik
            innerRef={(ref) => this.formRef = ref}
            initialValues={this.getInitialValues()}
            validationSchema={Yup.object().shape(this.getValidationShape())}
            onSubmit={this.handleSubmitKeyAccount}
          >
            {(props) => {
              return (<form noValidate autoComplete="off">
                <KeyAccountDetails
                  {...props}
                  onFieldChange={this.handleFieldChange}
                />
                <KeyAccountSalesUsers
                  {...props}
                  onFieldChange={this.handleFieldChange}
                />
                <KeyAccountVendor
                  {...props}
                  onFieldChange={this.handleFieldChange}
                />
                <KeyAccountProducts isKACreating={!isEditing} />
                <KeyAccountCustomers
                  {...props}
                  onFieldChange={this.handleFieldChange}
                />
              </form>);
            }}
          </Formik>
          { isEditing && <KeyAccountHistory keyAccountId={keyAccountDetails?.id}/> }
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  oriKeyAccountDetails: makeSelectOriKeyAccountDetails(),
  keyAccountDetails: makeSelectKeyAccountDetails(),
  oriKeyAccountSalesUsers: makeSelectOriKeyAccountSalesUsers(),
  keyAccountSalesUsers: makeSelectKeyAccountSalesUsers(),
  oriKeyAccountCustomers: makeSelectOriKeyAccountCustomers(),
  keyAccountCustomers: makeSelectKeyAccountCustomers(),
  oriKeyAccountProducts: makeSelectOriKeyAccountProducts(),
  keyAccountProducts: makeSelectKeyAccountProducts(),
  loading: makeSelectKeyAccountLoading(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getKeyAccount: (payload) => dispatch(getKeyAccount(payload)),
    resetKeyAccount: (payload) => dispatch(resetKeyAccount(payload)),
    updateKeyAccount: (payload) => dispatch(updateKeyAccount(payload)),
    saveKeyAccountDetails: (payload) => dispatch(saveKeyAccountDetails(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter)(KeyAccount);
