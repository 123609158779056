import {
  SAVE_SALES_TEAM_LIST,
  SAVE_SALES_TEAM_LIST_TOTAL,
  SAVE_SALES_TEAM_DETAILS,
  SAVE_SALES_TEAM_MEMBER,
  SAVE_SALES_TEAM_MEMBERS,
  SAVE_SALES_TEAM_CHANNELS,
  SAVE_SALES_TEAM_CHANNEL,
  SAVE_SALES_TEAM_KEY_ACCOUNTS,
  SAVE_SALES_ROLES,
  RESET_SALES_TEAM,
  REMOVE_SALES_TEAM_MEMBER,
  REMOVE_SALES_TEAM_CHANNEL,
} from 'redux/constants/admin/salesTeamConstants';
import { genID } from 'utils/helper';

const initialState = {
  salesTeamList: null,
  salesTeamListTotal: 0,
  salesTeamDetails: null,
  salesTeamMembers: [],
  salesTeamChannels: [],
  salesTeamKeyAccounts: [],
  salesRoles: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_SALES_TEAM_LIST: {
      const list = action.salesTeamList;
      return {
        ...state,
        salesTeamList: list ? [...list] : null,
      };
    }
    case SAVE_SALES_TEAM_LIST_TOTAL: {
      return {
        ...state,
        salesTeamListTotal: action.salesTeamListTotal,
      };
    }
    case RESET_SALES_TEAM: {
      return {
        ...state,
        salesTeamDetails: {},
        salesTeamMembers: [],
        salesTeamChannels: [],
        salesTeamKeyAccounts: [],
      };
    }
    case SAVE_SALES_TEAM_DETAILS: {
      return {
        ...state,
        salesTeamDetails: { ...action.salesTeamDetails },
      };
    }
    case SAVE_SALES_TEAM_MEMBER: {
      const item = action.salesTeamMember;
      const members = [...state.salesTeamMembers];
      const itemIndex = members.findIndex((m) => m.idStr === item.idStr);
      if (itemIndex > -1) {
        members[itemIndex] = { ...item };
      }
      if (itemIndex < 0) {
        members.unshift({ ...item });
      }
      return {
        ...state,
        salesTeamMembers: members,
      };
    }
    case REMOVE_SALES_TEAM_MEMBER: {
      const item = action.salesTeamMember;
      const members = state.salesTeamMembers.filter(mem => mem.idStr !== item.idStr);
      return {
        ...state,
        salesTeamMembers: members,
      };
    }
    case SAVE_SALES_TEAM_MEMBERS: {
      const members = action.salesTeamMembers
        ? [...action.salesTeamMembers]
        : [];
      return {
        ...state,
        salesTeamMembers: members.map((m) => ({
          ...m,
          idStr: genID(),
        })),
      };
    }
    case SAVE_SALES_TEAM_CHANNEL: {
      const item = action.salesTeamChannel;
      const list = [...state.salesTeamChannels];
      const itemIndex = list.findIndex((m) => m.idStr === item.idStr);
      if (itemIndex > -1) {
        list[itemIndex] = { ...item };
      }
      if (itemIndex < 0) {
        list.unshift({ ...item });
      }
      return {
        ...state,
        salesTeamChannels: list,
      };
    }
    case REMOVE_SALES_TEAM_CHANNEL: {
      const item = action.salesTeamChannel;
      const list = state.salesTeamChannels.filter(channel => channel.idStr !== item.idStr);
      return {
        ...state,
        salesTeamChannels: list,
      };
    }
    case SAVE_SALES_TEAM_CHANNELS: {
      const list = action.salesTeamChannels
        ? [...action.salesTeamChannels]
        : [];
      return {
        ...state,
        salesTeamChannels: list.map((m) => ({
          ...m,
          idStr: genID(),
        })),
      };
    }
    case SAVE_SALES_TEAM_KEY_ACCOUNTS: {
      return {
        ...state,
        salesTeamKeyAccounts: action.salesTeamKeyAccounts
          ? [...action.salesTeamKeyAccounts]
          : [],
      };
    }
    case SAVE_SALES_ROLES: {
      const list = action.salesRoles;
      return {
        ...state,
        salesRoles: list ? [...list] : null,
      };
    }
    default:
      return state;
  }
};

export default reducer;
