import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { debounce } from 'lodash';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectKeyAccountLoading,
  makeSelectKeyAccountList,
  makeSelectKeyAccountListTotal,
} from 'redux/selectors';
import { withRouter } from 'react-router-dom';
import { getSortsString, getDisplayFields } from 'utils/helper';

import {
  listParamsMap,
  listDisplayFields,
} from 'utils/constanst/adminKeyAccountConstants';
import { getKeyAccountList } from 'redux/actions/admin/keyAccountActions';

import ListPagination from 'components/ListPagination/ListPagination';
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';
import KeyAccountList from 'components/admin/KeyAccountList/KeyAccountList';
import KeyAccountListFilters from 'components/admin/KeyAccountList/KeyAccountListFilters/KeyAccountListFilters';

import classes from './KeyAccounts.module.scss';

class KeyAccounts extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      listParams: { ...listParamsMap },
    };
  }
  componentDidMount() {
    const { listParams } = this.state;
    this.props.getKeyAccountList({
      params: listParams,
    });
  }

  handleSortChange = ({ sortBy, sortDir }) => {
    const newSortString = getSortsString({
      [sortBy]: sortDir,
    });
    this.handleFilterChange([
      {
        name: 'order',
        value: newSortString,
      },
    ]);
  };
  handleFilterChange = (filters, forceResetPage: false) => {
    const paramsFromFilters = filters.reduce((memo, item) => {
      memo[item.name] = item.value;
      return memo;
    }, {});
    const newParams = forceResetPage ? {
      ...this.state.listParams,
      ...paramsFromFilters,
      page: 1,
    } : {
      ...this.state.listParams,
      ...paramsFromFilters,
    };
    this.setState({
      listParams: newParams,
    });
    this.props.getKeyAccountList({
      params: newParams,
    });
  };

  createNewKeyAccount = () => {
    this.props.history.push(`/keyaccount/new`);
  }

  render() {
    const { keyAccountList, keyAccountListTotal, loading } = this.props;
    const { listParams } = this.state;
    const isListLoading = loading;

    return (
      <React.Fragment>
        <div className={classes.PageWrap}>
          <div className={classes.PageHeader}>
            <h1 className={classes.PageTitle}>Danh sách đặc quyền bán</h1>
            <Button
              size="small"
              variant="contained"
              color="primary"
              startIcon={<Add />}
              onClick={this.createNewKeyAccount}
            >Tạo mới</Button>
          </div>
          <div className={classes.PageMain}>
            <KeyAccountListFilters onFilterChange={debounce(this.handleFilterChange, 500)}/>
            <KeyAccountList
              displayFields={getDisplayFields(
                listParams,
                listDisplayFields
              )}
              keyAccountList={keyAccountList}
              isLoading={isListLoading}
              onSortChange={this.handleSortChange}
            />
          </div>
          <div className={classes.PageFooter}>
            <ListPagination
              page={listParams.page}
              pageSize={listParams.page_size}
              total={keyAccountListTotal}
              listName="đặc quyền bán"
              onFilterChange={this.handleFilterChange}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  keyAccountList: makeSelectKeyAccountList(),
  keyAccountListTotal: makeSelectKeyAccountListTotal(),
  loading: makeSelectKeyAccountLoading(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getKeyAccountList: (payload) => dispatch(getKeyAccountList(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter)(KeyAccounts);
