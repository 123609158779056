import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import AssetComponentListItem from './Item/AssetComponentListItem';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';

import gridClasses from './AssetComponentListGrid.module.scss';
import classes from './AssetComponentList.module.scss';

class AssetComponentList extends PureComponent {
  render() {
    const { 
      assetComponentList, 
      isLoading, 
      displayFields, 
      onEditAssetComponents, 
      onRemoveAssetComponents,
      isEditAllowed,
      isDeleteAllowed,
    } = this.props;
    return <div className={`${classes.List} ${isLoading && 'OverlayLoading'}`} >
      <div className={`${classes.Body}`}>
        <div className={`${gridClasses.Row} ${classes.Header} ${gridClasses[`Row__column--${displayFields.length}`]}`}>
          { displayFields.map((item,index) => (
            <ListHeaderCol
              key={index}
              className={gridClasses.Col}
              {...item}
              onSortChange={this.props.onSortChange}
            />
          )) }
        </div>
      
        { (assetComponentList && assetComponentList.length)
          ? assetComponentList.map(item => (
            <AssetComponentListItem
              displayFields={displayFields}
              key={`asset-branding-list-item-${item.id}`}
              {...item}
              isEditAllowed={isEditAllowed}
              isDeleteAllowed={isDeleteAllowed}
              onEditAssetComponents={() => onEditAssetComponents({...item})}
              onRemoveAssetComponents={() => onRemoveAssetComponents({...item})}
            />))
          : (<p className="NoData">Không cấu hình nào</p>)
        }
      </div>
    </div>;
  }
}

AssetComponentList.propTypes = {
  displayFields: PropTypes.array,
  assetComponentList: PropTypes.array,
  isLoading: PropTypes.bool,
  isEditAllowed: PropTypes.bool,
  onSortChange: PropTypes.func,
  onEditAssetComponents: PropTypes.func,
  onRemoveAssetComponents: PropTypes.func,
};

AssetComponentList.defaultProps = {
  displayFields: [],
  assetComponentList: [],
  isLoading: false,
  isEditAllowed: false,
};

export default AssetComponentList;
