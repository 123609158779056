import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { debounce } from 'lodash';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectKeyAccountLockLoading,
  makeSelectKeyAccountLockList,
  makeSelectKeyAccountLockListTotal,
  makeSelectProductAllowedActions
} from 'redux/selectors';
import { withRouter } from 'react-router-dom';
import { getSortsString, getDisplayFields } from 'utils/helper';

import {
  listParamsMap,
  listDisplayFields,
} from 'utils/constanst/adminKeyAccountLockConstants';
import { getKeyAccountLockList,deleteKeyAccountLockList } from 'redux/actions/admin/keyAccountLockActions';
import { isActionAllowed } from 'utils/helper/authorization';
import ListPagination from 'components/ListPagination/ListPagination';
import KeyAccountLockList from 'components/admin/KeyAccountLockList/KeyAccountLockList';
import KeyAccountLockListFilters from 'components/admin/KeyAccountLockList/KeyAccountLockListFilters/KeyAccountLockListFilters';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import classes from './KeyAccountLocks.module.scss';

class KeyAccountLocks extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      listParams: { ...listParamsMap },
      isMoreActionsOpen: false,
      selectedIds: [],
    };
    this.moreActionsMenuRef = React.createRef();

  }
  componentDidMount() {
    const { listParams } = this.state;
    this.props.getKeyAccountLockList({
      params: listParams,
    });
  }

  handleSortChange = ({ sortBy, sortDir }) => {
    const newSortString = getSortsString({
      [sortBy]: sortDir,
    });
    this.handleFilterChange([
      {
        name: 'order',
        value: newSortString,
      },
    ]);
  };

  handleDialogOpen = () => {
    console.log(this.state.selectedIds);
    // deleteKeyAccountLockList
    this.props.deleteKeyAccountLockList({
      params: {
        ids: this.state.selectedIds,
      }
    });
    this.setState({
      isDialogOpen: true,
    });
    this.handleCloseDropdown();
  };

  handleCloseDropdown = () => {
    this.setState({
      isMoreActionsOpen: false,
    });
  };

  
  handleDialogClose = () => {
    this.setState({
      isDialogOpen: false,
    });
  };


  handleAllSelectedToggle = (e) => {
    const allIds = this.props.keyAccountLockList.map((item) => item.id);
    this.setState({
      selectedIds: e.target.checked ? allIds : [],
    });
  };

  handleItemSelectedToggle = ({ id, isSelected }) => {
    const remainIds = this.state.selectedIds.filter((item) => item !== id);
    this.setState({
      selectedIds: isSelected ? [...remainIds, id] : remainIds,
    });
  };

  
  handleToggleDropdown = () => {
    this.setState({
      isMoreActionsOpen: !this.state.isMoreActionsOpen,
    });
  };

  componentDidUpdate(prevProps) {
    const {  keyAccountLockList } = this.props;

    const { selectedIds } = this.state;
    if (keyAccountLockList !== prevProps.keyAccountLockList && selectedIds.length > 0) {
      const remainIds = selectedIds.filter((pId) =>
      keyAccountLockList.some((item) => item.id === pId)
      );
      this.setState({
        selectedIds: remainIds,
      });
    }
  }

  handleFilterChange = (filters, forceResetPage: false) => {
    const paramsFromFilters = filters.reduce((memo, item) => {
      if (['start_date'].includes(item.name)) {
        memo[`${item.name}_from`] = item.value[0];
        memo[`${item.name}_to`] = item.value[1];
      } else {
        memo[item.name] = item.value;
      }
      return memo;
    }, {});
    const newParams = forceResetPage ? {
      ...this.state.listParams,
      ...paramsFromFilters,
      page: 1,
    } : {
      ...this.state.listParams,
      ...paramsFromFilters,
    };
    this.setState({
      listParams: newParams,
    });
    this.props.getKeyAccountLockList({
      params: newParams,
    });
  };

  render() {
    const { keyAccountLockList, keyAccountLockListTotal, loading ,productAllowedActions} = this.props;
    const { listParams,selectedIds ,isMoreActionsOpen } = this.state;
    const isListLoading = loading;
    const hasSelected = selectedIds.length > 0;
    const isEditAllowed = isActionAllowed('edit', productAllowedActions);

    return (
      <React.Fragment>
        <div className={classes.PageWrap}>
          <div className={classes.PageHeader}>
            <h1 className={classes.PageTitle}>Danh sách key account lock</h1>
            {hasSelected > 0 ? 
            
            <div className={classes.PageActions}>
          
            <IconButton
              size="small"
              ref={this.moreActionsMenuRef}
              className={`${classes.MoreActionBtn} ${
                hasSelected && classes.Active
              }`}
              aria-controls={isMoreActionsOpen ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={this.handleToggleDropdown}
              style={{ padding: '6px' }}
            >
              <MoreVertIcon />
            </IconButton>
            <Popper
              open={isMoreActionsOpen}
              anchorEl={this.moreActionsMenuRef.current}
              role={undefined}
              transition
              disablePortal
              style={{ zIndex: 3 }}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'bottom' ? 'left top' : 'left bottom',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={this.handleCloseDropdown}>
                      <MenuList
                        id="menu-list-grow"
                        autoFocusItem={isMoreActionsOpen}
                      >
                        <MenuItem onClick={this.handleDialogOpen}>
                          Xóa key
                        </MenuItem>
                
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
        </div>
            :null}
         
          </div>
          <div className={classes.PageMain}>
            <KeyAccountLockListFilters onFilterChange={debounce(this.handleFilterChange, 500)}/>
            <KeyAccountLockList
              displayFields={getDisplayFields(
                listParams,
                listDisplayFields
              )}
              isEditAllowed={isEditAllowed}
              selectedIds={selectedIds}
              keyAccountLockList={keyAccountLockList}
              isLoading={isListLoading}
              onSortChange={this.handleSortChange}
              onAllSelectedToggle={this.handleAllSelectedToggle}
              onItemSelectedToggle={this.handleItemSelectedToggle}
            />
          </div>
          <div className={classes.PageFooter}>
            <ListPagination
              page={listParams.page}
              pageSize={listParams.page_size}
              total={keyAccountLockListTotal}
              listName="key account lock"
              onFilterChange={this.handleFilterChange}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  keyAccountLockList: makeSelectKeyAccountLockList(),
  keyAccountLockListTotal: makeSelectKeyAccountLockListTotal(),
  loading: makeSelectKeyAccountLockLoading(),
  productAllowedActions: makeSelectProductAllowedActions(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getKeyAccountLockList: (payload) => dispatch(getKeyAccountLockList(payload)),
    deleteKeyAccountLockList: (payload) => dispatch(deleteKeyAccountLockList(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter)(KeyAccountLocks);
