export const CUSTOMER_TYPE_ID = 1;
export const GROUP_CUSTOMER_TYPE_ID = 2;
export const customerTypesMap = {
   [CUSTOMER_TYPE_ID]: 'Khách hàng',
   [GROUP_CUSTOMER_TYPE_ID]: 'Nhóm khách hàng',
}

export const SALES_USER_MODE_ALL = 1;
export const SALES_USER_MODE_OPTION = 2;
export const salesUserModesMap = {
   [SALES_USER_MODE_ALL]: 'Tất cả',
   [SALES_USER_MODE_OPTION]: 'Một số trình dược viên cụ thể',
}
export const salesUserModes = [
  {
    id: SALES_USER_MODE_ALL,
    name: salesUserModesMap[SALES_USER_MODE_ALL],
  },
  {
    id: SALES_USER_MODE_OPTION,
    name: salesUserModesMap[SALES_USER_MODE_OPTION],
  },
]

export const CUSTOMER_MODE_ALL = 1;
export const CUSTOMER_MODE_OPTION = 2;
export const customerModesMap = {
  [CUSTOMER_MODE_ALL]: 'Tất cả',
  [CUSTOMER_MODE_OPTION]: 'Một số khách hàng cụ thể',
}
export const customerModes = [
  {
    id: CUSTOMER_MODE_ALL,
    name: customerModesMap[CUSTOMER_MODE_ALL],
  },
  {
    id: CUSTOMER_MODE_OPTION,
    name: customerModesMap[CUSTOMER_MODE_OPTION],
  },
]

/* list */
export const listParamsMap = {
  page_size: 50,
  page: 1,
  order: '',
};
export const listDisplayFields = [
  {
    name: 'name',
    label: 'Tên đặc quyền bán',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'getcare_sales_name',
    label: 'Trình dược viên',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'getcare_vendor_name',
    label: 'Nhà cung cấp',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'getcare_product_name',
    label: 'Sản phẩm',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'getcare_customer_name',
    label: 'Khách hàng',
    sortable: true,
    sortDir: '',
  },
];
export const filterFields = [
  {
    name: 'name',
    label: 'Tên đặc quyền bán',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'getcare_sales_name',
    label: 'Trình dược viên',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'getcare_vendor_name',
    label: 'Nhà cung cấp',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'getcare_product_name',
    label: 'Sản phẩm',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'getcare_customer_name',
    label: 'Khách hàng',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
];

/* sales users */
export const defaultKeyAccountSalesUserParams = {
  id: 0,
  getcare_sales: null,

  isEditing: true,
}
export const keyAccountSalesUsersParamsMap = {
  order: '',
};
export const keyAccountSalesUsersDisplayFields = [
  {
    name: 'code',
    label: 'Mã nhân viên',
  },
  {
    name: 'name',
    label: 'Tên nhân viên',
  },
  {
    name: 'sales_team_name',
    label: 'Sales team',
  },
  {
    name: 'phone',
    label: 'Số điện thoại',
  },
];

/* customers */
export const defaultKeyAccountCustomerParams = {
  id: 0,
  getcare_customer: null,

  isEditing: true,
}
export const keyAccountCustomersParamsMap = {
  order: '',
};
export const keyAccountCustomersDisplayFields = [
  {
    name: 'code',
    label: 'Mã KH/ Nhóm KH',
  },
  {
    name: 'name',
    label: 'Tên KH/ Nhóm KH',
  },
  {
    name: 'type',
    label: 'Loại',
  },
  {
    name: 'phone',
    label: 'Số điện thoại',
  },
  {
    name: 'address',
    label: 'Địa chỉ',
  },
];

/* products */
export const keyAccountProductsParamsMap = {
  order: '',
};
export const keyAccountProductsDisplayFields = [
  {
    name: 'getcare_id',
    label: 'Phahub ID',
  },
  {
    name: 'name',
    label: 'Tên sản phẩm',
  },
];
