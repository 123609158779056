import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectProvinceList,
  makeSelectDistrictList,
  makeSelectWardList,
} from 'redux/selectors';
import {
  getProvinceList,
  getDistrictList,
  getWardList,
} from 'redux/actions/locationActions';
import { VN_ID } from 'utils/constanst/locationConstants';
import { getFullAddressStr } from 'utils/helper';

import EditOutlined from '@material-ui/icons/EditOutlined';
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import IconButton from '@material-ui/core/IconButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';

import gridClasses from '../SalesChannelLocationsGrid.module.scss';
import classes from './SalesChannelLocationItem.module.scss';

class SalesChannelLocationItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: !props.getcare_province,

      selectedProvince: props.getcare_province,
      selectedDistrict: props.getcare_district,
      selectedWard: props.getcare_ward,

      isDialogOpen: false,
    }
  }
  componentDidMount() {
    if (this.state.isEditing) {
      this.loadInitData();
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { selectedProvince, selectedDistrict, isEditing } = this.state;
    if (isEditing && isEditing !== prevState.isEditing) {
      this.loadInitData();
    }
    if (selectedProvince?.id !== prevState.selectedProvince?.id) {
      this.props.getDistrictList({
        params: { name: '', getcare_province_id: selectedProvince?.id },
      });
    }
    if (selectedDistrict?.id !== prevState.selectedDistrict?.id) {
      this.props.getWardList({
        params: { name: '', getcare_district_id: selectedDistrict?.id },
      });
    }
  }

  loadInitData = () => {
    const { selectedProvince, selectedDistrict } = this.state;
    this.props.getProvinceList({
      params: { name: '', getcare_country_id: VN_ID },
    });
    this.props.getDistrictList({
      params: {
        name: '',
        getcare_province_id: selectedProvince?.id,
      },
    });
    this.props.getWardList({
      params: {
        name: '',
        getcare_district_id: selectedDistrict?.id,
      },
    });
  }
  handleEdit = () => {
    this.setState({
      isEditing: true,
    });
  }
  handleCancel = () => {
    this.setState({
      isEditing: false,
    });
    if (!this.isRowValid()) {
      this.props.onRemoveLocationItems([{
        id: this.props.id,
      }]);
    }
  }
  handleSave = () => {
    // dispatch to parent to  update this row
    this.props.onSaveLocationItem({
      id: this.props.id,
      getcare_province: this.state.selectedProvince,
      getcare_district: this.state.selectedDistrict,
      getcare_ward: this.state.selectedWard,
    });
    this.setState({
      isEditing: false,
    });
  }
  isProvinceValid = () => {
    const { selectedProvince } = this.state;
    return !!selectedProvince;
  }
  isRowValid = () => {
    return this.isProvinceValid();
  }
  handleRowClick = () => {
    if (!this.state.isEditing) {
      this.handleEdit();
    }
  }
  handleRowBlur = () => {
    if (this.state.isEditing && this.isRowValid()) {
      this.handleSave();
    }
  }

  handleOpenDialog = (e) => {
    if (e) e.stopPropagation();
    this.setState({
      isDialogOpen: true,
    });
  }
  handleCloseDialog = () => {
    this.setState({
      isDialogOpen: false,
    });
  }
  handleDelete = () => {
    this.props.onRemoveLocationItems([{
      idStr: this.props.idStr,
      id: this.props.id,
    }]);
  };

  render() {
    const {
      getcare_province,
      getcare_district,
      getcare_ward,
      hasOneLocation,
      provinceList,
      districtList,
      wardList,
      isEditAllowed,
    } = this.props;
    const {
      isEditing,
      selectedProvince,
      selectedDistrict,
      selectedWard,
    } = this.state;

    return (<>
      <ClickAwayListener onClickAway={this.handleRowBlur}>
        <div 
          className={`${classes.ProductItem} ${gridClasses.Row} ${isEditAllowed ? gridClasses.RowHasActions : ''}`} 
          onClick={isEditAllowed ? this.handleRowClick : undefined}
        >
          <div className={`${gridClasses.Col} ${classes.EditInlineCol}`}>
            { isEditing
              ? (<Autocomplete
                  openOnFocus
                  size="small"
                  handleHomeEndKeys={false}
                  value={(provinceList?.length && selectedProvince) || null}
                  onChange={(e, value) => {
                    this.setState({
                      selectedProvince: value,
                      selectedDistrict: null,
                      selectedWard: null,
                    });
                  }}
                  options={provinceList || []}
                  getOptionLabel={(option) => option?.name || ''}
                  getOptionSelected={(option, value) => option.id === value.id}
                  renderInput={(params) => <TextField {...params} error={!this.isProvinceValid()} placeholder="- Chọn -" />}
                />)
              : getcare_province?.name
            }
          </div>
          <div className={`${gridClasses.Col} ${classes.EditInlineCol}`}>
            { isEditing
              ? (<Autocomplete
                  openOnFocus
                  size="small"
                  handleHomeEndKeys={false}
                  value={(districtList?.length && selectedDistrict) || null}
                  onChange={(e, value) => {
                    this.setState({
                      selectedDistrict: value,
                      selectedWard: null,
                    });
                  }}
                  options={districtList || []}
                  getOptionLabel={(option) => option?.name || ''}
                  getOptionSelected={(option, value) => option.id === value.id}
                  renderInput={(params) => <TextField {...params} placeholder="- Chọn -" />}
                />)
              : getcare_district?.name
            }
          </div>
          <div className={`${gridClasses.Col} ${classes.EditInlineCol}`}>
            { isEditing
              ? (<Autocomplete
                  openOnFocus
                  size="small"
                  handleHomeEndKeys={false}
                  value={(wardList?.length && selectedWard) || null}
                  onChange={(e, value) => {
                    this.setState({
                      selectedWard: value,
                    });
                  }}
                  options={wardList || []}
                  getOptionLabel={(option) => option?.name || ''}
                  getOptionSelected={(option, value) => option.id === value.id}
                  renderInput={(params) => <TextField {...params} placeholder="- Chọn -" />}
                />)
              : getcare_ward?.name
            }
          </div>
          { isEditAllowed && 
            <div className={`${gridClasses.Col} ${classes.ActionsCol}`}>
              {
                isEditing
                ? (<>
                    <IconButton
                      onClick={this.handleCancel}
                      style={{ padding: '3px' }}
                      size="small"
                    ><CancelOutlined fontSize="small" color="secondary"/></IconButton>
                    <IconButton
                      onClick={this.handleSave}
                      style={{ padding: '3px' }}
                      size="small"
                      disabled={!this.isRowValid()}
                    ><CheckCircleOutlineOutlined fontSize="small" color={ this.isRowValid() ? `primary` : `inherit`}/></IconButton>

                  </>)
                : (<>
                    <IconButton
                      disabled={hasOneLocation}
                      onClick={this.handleOpenDialog}
                      style={{ padding: '3px' }}
                      size="small"
                    >
                      <DeleteOutline fontSize="small" />
                    </IconButton>
                    <IconButton
                      onClick={this.handleEdit}
                      style={{ padding: '3px' }}
                      size="small"
                    ><EditOutlined fontSize="small" color="primary"/></IconButton>
                  </>)
              }
            </div>
          }
        </div>
      </ClickAwayListener>
      { this.state.isDialogOpen &&
        <ConfirmationDialog
          isOpen={this.state.isDialogOpen}
          title="Xác nhận xoá"
          message={
            <p>{`Bạn có chắc xoá khu vực `}
              <strong>{ getFullAddressStr(``, getcare_ward, getcare_district, getcare_province) }</strong> ra khỏi khu vực bán hàng?
            </p>
          }
          onClose={this.handleCloseDialog}
          onSubmit={this.handleDelete}
        />
      }
    </>);
  }
}

SalesChannelLocationItem.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  getcare_province: PropTypes.object,
  getcare_district: PropTypes.object,
  getcare_ward: PropTypes.object,
  hasOneLocation: PropTypes.bool,
  onSaveLocationItem: PropTypes.func,
  onRemoveLocationItems: PropTypes.func,
};

SalesChannelLocationItem.defaultProps = {
  hasOneLocation: false,
};

const mapStateToProps = createStructuredSelector({
  provinceList: makeSelectProvinceList(),
  districtList: makeSelectDistrictList(),
  wardList: makeSelectWardList(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getProvinceList: (payload) => dispatch(getProvinceList(payload)),
    getDistrictList: (payload) => dispatch(getDistrictList(payload)),
    getWardList: (payload) => dispatch(getWardList(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(SalesChannelLocationItem);
