import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { debounce } from 'lodash';
import {
  getProductBonusList,
  saveProductBonusItem,
  removeProductBonusItems,
  updateProductBonusItem,
  exportProductBonusListTemplate,
} from 'redux/actions/admin/productBonusActions';
import { getProductUnitList } from 'redux/actions/admin/uomActions';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectProductBonusList,
  makeSelectProductBonusListTotal,
  makeSelectProductBonusLoading,
  makeSelectProductUnits,
} from 'redux/selectors';
import {
  listParamsMap,
  listDisplayFields,
  ACTIVE,
} from 'utils/constanst/adminProductBonusConstants';
import { getSortsString, getDisplayFields, genID, validNumber } from 'utils/helper';
import { withRouter } from 'react-router-dom';
import { suggestionPageSize } from 'utils/constanst/common';

import Button from '@material-ui/core/Button';
import ProductBonusList from 'components/admin/ProductBonusList/ProductBonusList';
import ProductBonusFilters from 'components/admin/ProductBonusList/ProductBonusFilters/ProductBonusFilters';
import ListPagination from 'components/ListPagination/ListPagination';
import FileImportDialog from 'components/admin/ProductBonusList/FileImportDialog/FileImportDialog';

import classes from './ProductBonuses.module.scss';

class ProductBonuses extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      listParams: { ...listParamsMap },
      isImportDialogOpen: false,
    };
  }
  componentDidMount() {
    this._loadData();
  }
  _loadData = (id) => {
    this.props.getProductBonusList({
      params: this.state.listParams,
    });
    this.props.getProductUnitList({
      params: {
        page_size: suggestionPageSize,
        page: 1,
      },
    });
  }
  _prepareItemData = (params) => {
    return {
      id: params.id,
      name: params.name,
      model: params.model,
      description: params.description,
      getcare_product_unit_id: params.getcare_product_unit?.id || undefined,
      estimate_quantity: validNumber(params.estimate_quantity),
      getcare_vendor_id: params.getcare_vendor?.id || undefined,
    }
  }
  handleSortChange = ({ sortBy, sortDir }) => {
    const newSortString = getSortsString({
      [sortBy]: sortDir,
    });
    this.handleFilterChange([
      {
        name: 'order',
        value: newSortString,
      },
    ]);
  };
  handleFilterChange = (filters, forceResetPage: false) => {
    const paramsFromFilters = filters.reduce((memo, item) => {
      if (['estimate_quantity'].includes(item.name)) {
        const numArr = item.value.map(val => val.trim() !== '' ? Number(val) : '');
        memo[`${item.name}_min`] = numArr[numArr.length - 1] && numArr[0] ? Math.min.apply(null, [...numArr]) : numArr[0];
        memo[`${item.name}_max`] = numArr[numArr.length - 1] && numArr[0] ? Math.max.apply(null, [...numArr]) : numArr[numArr.length - 1];
      } else {
        memo[item.name] = item.value;
      }
      return memo;
    }, {});
    const newParams = forceResetPage ? {
      ...this.state.listParams,
      ...paramsFromFilters,
      page: 1,
    } : {
      ...this.state.listParams,
      ...paramsFromFilters,
    };
    this.setState({
      listParams: newParams,
    });
    this.props.getProductBonusList({
      params: newParams,
    });
  };
  handleCreateNewRow = () => {
    this.props.saveProductBonusItem({
      idStr: genID(),
      id: 0,
      code: '',
      name: '',
      model: '',
      getcare_product_unit: null,
      estimate_quantity: null,
      getcare_vendor: null,
      description: '',
      active: ACTIVE,
    });
  }
  handleSaveProductBonus = (params) => {
    this.props.saveProductBonusItem({
      ...params,
    });
    this.props.updateProductBonusItem({
      params: {
        ...this._prepareItemData(params),
        idStr: params.idStr,
      },
    });
  };
  handleRemoveProductBonuses = (items) => {
    this.props.removeProductBonusItems(items);
  }

  handleDownloadTemplate = () => {
    this.props.exportProductBonusListTemplate();
  };
  handleImportDialogOpen = () => {
    this.setState({
      isImportDialogOpen: true,
    });
  };
  handleImportDialogClose = () => {
    this.setState({
      isImportDialogOpen: false,
    });
  };
  handleCompleteImport = () => {
    // Reload the page with current settings (e.g.. filters)
    this.props.getProductBonusList({
      params: this.state.listParams,
    });
  };

  render() {
    const { productBonusListTotal, loading, productUnits, productBonusList } = this.props;
    const { listParams, isImportDialogOpen } = this.state;
    const isListLoading = loading;

    return (
      <React.Fragment>
        <div className={classes.PageWrap}>
          <div className={classes.PageHeader}>
            <h1 className={classes.PageTitle}>Danh sách hàng khuyến mãi</h1>
            <div className={classes.PageActions}>
              <Button size="small" variant="contained" onClick={this.handleImportDialogOpen}>
                Import File
              </Button>
              <Button size="small" variant="contained" onClick={this.handleDownloadTemplate}>
                Download template
              </Button>
            </div>
          </div>
          <div className={classes.PageMain}>
            <ProductBonusFilters
              onFilterChange={debounce(this.handleFilterChange, 500)}
            />
            <ProductBonusList
              displayFields={getDisplayFields(
                listParams,
                listDisplayFields
              )}
              productBonusList={productBonusList}
              productUnits={productUnits}
              isLoading={isListLoading}
              onAddNewRow={this.handleCreateNewRow}
              onSortChange={this.handleSortChange}
              onSaveProductBonus={this.handleSaveProductBonus}
              onRemoveProductBonuses={this.handleRemoveProductBonuses}
            />
          </div>
          <div className={classes.PageFooter}>
            <ListPagination
              page={listParams.page}
              pageSize={listParams.page_size}
              total={productBonusListTotal}
              listName="sản phẩm"
              onFilterChange={this.handleFilterChange}
            />
          </div>
        </div>
        {isImportDialogOpen && (
          <FileImportDialog
            title="Import danh sách hàng khuyến mãi"
            isOpen={isImportDialogOpen}
            onClose={this.handleImportDialogClose}
            onCompleteImport={this.handleCompleteImport}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  productBonusListTotal: makeSelectProductBonusListTotal(),
  productBonusList: makeSelectProductBonusList(),
  loading: makeSelectProductBonusLoading(),
  productUnits: makeSelectProductUnits(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getProductBonusList: (payload) => dispatch(getProductBonusList(payload)),
    saveProductBonusItem: (payload) => dispatch(saveProductBonusItem(payload)),
    updateProductBonusItem: (payload) => dispatch(updateProductBonusItem(payload)),
    removeProductBonusItems: (payload) => dispatch(removeProductBonusItems(payload)),
    getProductUnitList: (payload) => dispatch(getProductUnitList(payload)),
    exportProductBonusListTemplate: (payload) => dispatch(exportProductBonusListTemplate(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter)(ProductBonuses);
