import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import UomListItem from './UomListItem/UomListItem';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';

import gridClasses from './UomListGrid.module.scss';
import classes from './UomList.module.scss';

class UomList extends PureComponent {
  render() {
    const { uomList, isLoading, displayFields } = this.props;

    return <div className={`${classes.List} ${isLoading && 'OverlayLoading'}`}>
       <div
        className={`${classes.Header}`}
        ref={(el) => (this.rowRef = el)}
      >
            <div className={`${gridClasses.Row} ${classes.Header}`}>
          { displayFields.map((item) => (
            <ListHeaderCol
              key={item.name}
              className={gridClasses.Col}
              {...item}
              onSortChange={this.props.onSortChange}
            />
          )) }
        </div>
      
        </div>

      <div className={`${classes.Body}`}>
        { !isLoading && uomList.length
          ? uomList.map(item => (
            <UomListItem
              key={`medicine-${item.id}`}
              {...item}
            />))
          : (<p className="NoData">Không có data</p>)
        }
      </div>
    </div>;
  }
}

UomList.propTypes = {
  displayFields: PropTypes.array,
  uomList: PropTypes.array,
  isLoading: PropTypes.bool,
  onSortChange: PropTypes.func,
};

UomList.defaultProps = {
  displayFields: [],
  uomList: [],
  isLoading: false,
};

export default UomList;
