import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import gridClasses from '../UomBaseListGrid.module.scss';
import classes from './UomBaseListItem.module.scss';

class UomBaseListItem extends PureComponent {
  render() {
    const {
      id,
      name,
    } = this.props;

    return <div
      className={`${classes.ProductItem} ${gridClasses.Row}`}
    >
      <div className={gridClasses.Col}>{ id }</div>
      <div className={gridClasses.Col}>{ name }</div>
    </div>;
  }
}

UomBaseListItem.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  name: PropTypes.string,
};

UomBaseListItem.defaultProps = {
};

export default UomBaseListItem;
