import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FieldEditable from 'components/FieldEditable/FieldEditable';
import EditOutlined from '@material-ui/icons/EditOutlined';
import IconButton from '@material-ui/core/IconButton';
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { Link } from 'react-router-dom';
import VendorProductMappingDialog from '../VendorProductMappingDialog/VendorProductMappingDialog';
import GetcareCategoryFormAutocomplete from 'components/admin/GetcareCategory/FormAutocomplete/FormAutocomplete';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import uomApi from 'utils/api/admin/uomApi';
import vendorProductApi from 'utils/api/admin/vendorProductApi';
import { validDate } from 'utils/helper';
import { dateFormat } from 'utils/constanst/dateConstants';
import format from 'date-fns/format';
import { vatsOptions } from 'utils/constanst/common';
import { currencyFormatVN, numberFormat, validNumber,currencyFormatPercent } from 'utils/helper';
import { allInPageSize } from 'utils/constanst/common';

import gridClasses from '../VendorProductListGrid.module.scss';
import classes from './VendorProductListItem.module.scss';

const min = 0;
const max = 100;
class VendorProductListItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: !props.id,
      params: this._initParams(props),
      isDialogOpen: false,
      commission:props.commission,
      uomList: [],
      categoryInput: '',
      vendorInput: '',
      suggettionList: [],
      suggettionVendorList: props.suggettionVendorList
    }
  }

  componentDidMount() {
    this.setState({
      selectedCategory: this.props.product_vendor_name
        ? {
            id: this.props.id,
            name: this.props.product_vendor_name,
          }
        : null,
        selectedVendor: this.props.getcare_vendor_name
        ? {
            id: this.props.id,
            name: this.props.getcare_vendor_name,
          }
        : null,
    }
    );
  }
  componentDidUpdate(prevProps, prevState) {

    const { categoryInput, vendorInput } = this.state;
    if (categoryInput && categoryInput !== prevState.categoryInput) {
      this._loadSuggetionByProduct(categoryInput);
      this._loadUOMListByProduct(this.props.getcare_product?.id);
    }
    if (vendorInput && vendorInput !== prevState.vendorInput) {
        this._loadSuggetionByVendor(vendorInput);
    }
  }

  _initParams = (params) => {
    return {
      product_vendor_code: params.product_vendor_code ? params.product_vendor_code : '' ,
      getcare_vendor_name: params.getcare_vendor_name,
      getcare_vendor_id: params.getcare_vendor_id,
      product_vendor_name: params.product_vendor_name,
      registration_number: params.registration_number,
      getcare_uom_base: { ...params.getcare_uom_base },
      price_buy_total: params.price_buy_total,
      commission: params.commission,
      subsidiary_name: params.subsidiary_name,
      description: params.description,
      updated_at: params.updated_at,
      getcare_product_id: params.getcare_product?.id !== 0 ? params.getcare_product?.id : -1,
      price_sales_total: params.price_sales_total,
      price_sales_retail_total: params.price_sales_retail_total,
      vat: params.vat,
      minimum_quantity: params.minimum_quantity,
      estimated_quantity: params.estimated_quantity,
      remaining_quantity: params.remaining_quantity,
      parent_vendor_product_code: params.parent_vendor_product_code,
      type_label: params.type_label,
      ka_days: params.ka_days,
      images: params.images,
      getcare_category_ecoms: params.getcare_category_ecoms || [],
      weight: params.weight,  
    }
  }
  _resetState = () => {
    this.setState({
      isEditing: false,
      params: this._initParams(this.props),
    });
  }
  _getUomBaseSelected = (uomBaseId) => {
    return this.state.uomList.find(item => item.id === uomBaseId);
  }
  _getNameVendorSelected = (nameVendor) => {
    const vendorSelect = this.state.suggettionVendorList.find(item => item.id === nameVendor);
    return vendorSelect.name;
  }
  _getSuggetProductSelected = (suggetionID) => {
    const filter  = this.state.suggettionList.find(item => item.id === suggetionID);
    return  filter.name;
  }

  _getSuggetProductIDSelected = (suggetionID) => {
    const filter  = this.state.suggettionList.find(item => item.id === suggetionID);
    return  filter.id;
  }
  
  _getVATSelected = (vatId) => {
    return [...vatsOptions].find(item => item.id === vatId);
  }
  _isCodeValid = () => {
    return !!this.state.params?.product_vendor_code;
  }
  _isNameValid = () => {
    return !!this.state.categoryInput;
  }
  _isNameVendorValid =() => {
    return !!this.state.vendorInput;
  }
  _isUomValid = () => {
    return !!this.state.params?.getcare_uom_base?.id;
  }
  _isVendorValid = () => {
    return !!this.state.params?.getcare_vendor_id;
  }
  _isPriceBuyValid = () => {
    const value = this.state.params?.price_buy_total;
    return ['', null, undefined].includes(value) || Number(value) > 0;
  }
  _isComissionValid = () => {
    const value = this.state.params?.commission;
    return ['', null, undefined].includes(value) || (Number(value) > 0 ) ;
  }
  _isPriceSalesValid = () => {
    return (!!this.state.params?.price_sales_total && parseFloat(this.state.params?.price_sales_total) > 0);
  }
  _isPriceSalesRetailValid = () => {
    const value = this.state.params?.price_sales_retail_total;
    return ['', null, undefined].includes(value) || Number(value) > 0;
  }
  _isMinimumQuantityValid = () => {
    const value = this.state.params?.minimum_quantity;
    return ['', null, undefined].includes(value) || Number(value) >= 0;
  }
  _isEstimatedQuantityValid = () => {
    const value = this.state.params?.estimated_quantity;
    return ['', null, undefined].includes(value) || Number(value) >= 0;
  }
  _isRemainingQuantityValid = () => {
    const value = this.state.params?.remaining_quantity;
    return ['', null, undefined].includes(value) || Number(value) >= 0;
  }
  _isKADaysValid = () => {
    const value = this.state.params?.ka_days;
    return ['', null, undefined].includes(value) || (Number(value) >= 0 && Number(value) <= 9999);
  }
  _isWeightValid = () => {
    const value = this.state.params?.weight;
    return ['', null, undefined].includes(value) || ( Number(value) >= 0 && Number(value) <= 9999999999 );
  } 
  _isRowValid = () => {
      if (this.getVisibleFieldFromName('getcare_vendor_name')) {
        return this._isNameValid()
        && this._isUomValid()
        && this._isNameVendorValid()
        && this._isPriceBuyValid()
        && this._isPriceSalesValid()
        && this._isComissionValid()
        && this._isMinimumQuantityValid()
        && this._isRemainingQuantityValid()
        && this._isKADaysValid()
        && this._isWeightValid()
      }else{
        return this._isNameValid()
        && this._isUomValid()
        && this._isPriceBuyValid()
        && this._isPriceSalesValid()
        && this._isComissionValid()
        && this._isMinimumQuantityValid()
        && this._isRemainingQuantityValid()
        && this._isKADaysValid()
        && this._isWeightValid()
      } 
  };
  _isHighlighted = () => {
    // highlighted if it is mapped
    const { oriItem, getcare_product } = this.props;
    return (!!getcare_product && !!getcare_product.id)
      && (!oriItem || !oriItem.getcare_product || oriItem.getcare_product.id !== getcare_product.id);
  }
 _loadUOMListByProduct = async (value) => {
    const { data: response } = await uomApi.getAllUomBase({
      params: {
        page_size: allInPageSize,
        getcare_product_id: value !== 'undefined' ? value : this.props.getcare_product?.id
      },
    });
    this.setState({
      uomList: response.data || [],
    });
  }

  _loadUOMList = async (params) => {
    const { data: response } = await uomApi.getAllUomBase({
      params: {
        page_size: allInPageSize,
        getcare_product_id: this.state.getcare_product_id !== null ? this.state.getcare_product_id :  this.props.id
      },
    });
    this.setState({
      uomList: response.data || [],
    });
  }

  _loadSuggetionByProduct = async (params) => {
    const { data: response } = await uomApi.getProductSuggestions({
      params: {
        getcare_product_sugget: params
      },
    });
    this.setState({
      suggettionList: response.data || [],
    });
  }

  _loadSuggetionByVendor = async (params) => {
    const { data: response } = await vendorProductApi.getVendorSuggestions({
      params: {
        getcare_vendor_suggestion: params
      },
    });
    this.setState({
      suggettionVendorList: response.data || [],
    });
  }


  getVisibleFieldFromName = (columnName) => {
    return this.props.displayFields.find(field => field.name === columnName);
  }

  stopPropagation = (e) => {
    e.stopPropagation();
  }

  handleCheckboxChange = (e) => {
    e.stopPropagation();
    this.props.onItemSelectedToggle({
      item: { ...this.props },
      isSelected: !this.props.isSelected,
    });
  };
  handleSaveField = (e, value) => {
    let newValue = value;
    if (e.target.name === 'getcare_uom_base') {
      newValue = { ...this._getUomBaseSelected(value) };
    }

    if (e.target.name === 'type_label') {
      newValue = (value + ``).toUpperCase();
    }

    
    if (e.target.name === 'getcare_category_ecoms') {
      this.setState({
        params: {
          ...this.state.params,
          getcare_category_ecoms: value.map( item => ({
            id: item.id,
            name: item.name,
          })),
        },
      });
    }
    if (e.target.name === 'getcare_vendor_name') {
      this.setState({
        params: {
          ...this.state.params,
          getcare_vendor_id: newValue,
          getcare_vendor_name: this._getNameVendorSelected(value)
        },
      });
    }else{
      this.setState({
        params: {
          ...this.state.params,
          [e.target.name]: newValue
        },
      });
    }
  }
  
  handleEdit = (e) => {
    e.stopPropagation();
    this.setState({
      isEditing: true,
    });
  }
  handleCancel = (e) => {
    e.stopPropagation();
    this._resetState();
    if (!this.props.id) {
      this.props.removeVendorProducts([{
        id: this.props.id,
        idStr: this.props.idStr,
      }]);
    }
  }
  handleSave = (e) => {
    if (e) e.stopPropagation();
    this.props.saveVendorProduct({
      ...this.state.params,
      idStr: this.props.idStr,
      id: this.props.id,
    });
    this.setState({
      isEditing: false,
    });
  }
  handleRowBlur = () => {
  }
  handleClickMapping = (e) => {
    e.stopPropagation();
    this.handleDialogOpen();
  }
  handleDialogOpen = () => {
    this.setState({
      isDialogOpen: true,
    });
  }
  handleDialogClose = () => {
    this.setState({
      isDialogOpen: false,
    });
  }
  handleSubmitProductMapping = (selectedProduct) => {
    this.props.saveVendorProduct({
      ...this.state.params,
      getcare_product: {
        id: selectedProduct.id,
        name: selectedProduct.name,
        getcare_id: selectedProduct.getcare_id,
      },
      idStr: this.props.idStr,
      id: this.props.id,
    });
    this.handleDialogClose();
  }
  handleSaveFieldImages = ({ action, urls, index }) => {
    let images = Array.isArray(this.state.params?.images) ? [...this.state.params.images] : [];
    switch (action) {
      case "ADD": {
        images = images.concat(urls)
        break;
      }
      case "REMOVE": {
        images.splice(index,1);
        break;
      }
    }
    this.setState({
      params: {
        ...this.state.params,
        "images": images
      },
    });
  }

  handleSaveFieldDescription = ({ action, params, index }) => {
    this.setState({
      params: {
        ...this.state.params,
        "description": params
      },
    });
  }


  render() {
    const {
      product_vendor_code,
      product_vendor_name,
      getcare_vendor_name,
      getcare_vendor_id,
      getcare_product,
      getcare_uom_base,
      price_buy_total,
      price_sales_total,
      price_sales_retail_total,
      vat,
      subsidiary_name,
      registration_number,
      minimum_quantity,
      remaining_quantity,
      updated_at,
      type_label,
      ka_days,
      isSelected,
      isMappingAllowed,
      isEditAllowed,
      templateColumnsMap,
      weight,
      getcare_category_ecoms,
      parent_vendor_product_code,
      images,
      description
    } = this.props;

    const { isEditing,suggettionList,selectedCategory,commission,suggettionVendorList,selectedVendor} = this.state;

    return (<>
      <ClickAwayListener onClickAway={this.handleRowBlur}>
        <div
          className={`${classes.Item} ${gridClasses.Row} ${this._isHighlighted() ? classes.Highlighted : ''}`}
          style={{gridTemplateColumns: templateColumnsMap.template}}
          onClick={isEditAllowed ? this.handleEdit : undefined}
        >
          { isEditAllowed && (
            <div className={gridClasses.Col}>
              <Checkbox
                className={classes.Checkbox}
                checked={isSelected}
                onClick={this.stopPropagation}
                onChange={this.handleCheckboxChange}
              />
            </div>
          )}
          { this.getVisibleFieldFromName('getcare_product_getcare_id') &&
            <div className={gridClasses.Col}>{ getcare_product?.getcare_id }</div>
          }
          { this.getVisibleFieldFromName('getcare_product_name') &&
            <div className={gridClasses.Col}>
              <Link to={`/products/${getcare_product?.id}`} className="TextSecondary">{ getcare_product?.name }</Link>
            </div>
          }
           { this.getVisibleFieldFromName('registration_number') &&
              <div className={gridClasses.Col}>
              { isEditing ? <FieldEditable
                fieldName="registration_number"
                value={registration_number || ''}
                displayedValue={registration_number}
                onSave={() => {return;}}
                editModeOnly
                // error={!this._isCodeValid()}
                onChange={this.handleSaveField}
              /> : registration_number }
            </div>
          }
          { this.getVisibleFieldFromName('getcare_vendor_name') &&
          <div className={gridClasses.Col}>
              { isEditing ? 
                <Autocomplete
                freeSolo
                selectOnFocus
                openOnFocus
                size="small"
                handleHomeEndKeys={false}
                value={selectedVendor|| ''}
                onInputChange={(e, newInputValue) => {
                  this.setState({
                    vendorInput: newInputValue,
                  });
                }}
                name='getcare_vendor_name'
                options={suggettionVendorList || []}
                filterOptions={(x) => x}
                getOptionLabel={(option) => option?.name || ''}
                getOptionSelected={(option, value) =>
                  value && value.id && option ? option.id === value.id : null
                }
                onChange={(e, value) => {
                  if (value){
                    this.setState({
                      params: {
                        ...this.state.params,
                        getcare_vendor_id: value?.id,
                        getcare_vendor_name: this._getNameVendorSelected(value?.id)
                      },
                      selectedVendor:value
                  });
                  }    
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!this._isNameVendorValid()}
                    placeholder={'-- Chọn ---'}
                  />
                )}        
              />
                : <Link to={`/vendor/${getcare_vendor_id}/products`} className="TextSecondary">{ getcare_vendor_name }</Link> }
        </div>
          }
          { this.getVisibleFieldFromName('product_vendor_code') &&
            <div className={gridClasses.Col}>
              { isEditing ? <FieldEditable
                fieldName="product_vendor_code"
                value={product_vendor_code || ''}
                displayedValue={product_vendor_code}
                onSave={() => {return;}}
                editModeOnly
                // error={!this._isCodeValid()}
                onChange={this.handleSaveField}
              /> : product_vendor_code }
            </div>
          }
          { this.getVisibleFieldFromName('product_vendor_name') &&
            <div className={gridClasses.Col}>
              { isEditing ? 
              <Autocomplete
                freeSolo
                selectOnFocus
                openOnFocus
                size="small"
                handleHomeEndKeys={false}
                value={selectedCategory|| ''}
                onInputChange={(e, newInputValue) => {
                  this.setState({
                    categoryInput: newInputValue,
                  });
                }}
                name='product_vendor_name'
                options={suggettionList || []}
                filterOptions={(x) => x}
                getOptionLabel={(option) => option?.name || ''}
                getOptionSelected={(option, value) =>
                  value && value.id && option ? option.id === value.id : null
                }
                onChange={(e, value) => {
                        this.setState({
                          params: {
                            ...this.state.params,
                            product_vendor_name: value?.name,
                            getcare_product_id: value?.id
                          },
                          selectedCategory:value
                      });
                      this._loadUOMListByProduct(value?.id);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!this._isNameValid()}
                    placeholder={'-- Chọn ---'}
                    onChange={(e, value) => {
                      this.setState({
                        params: {
                          ...this.state.params,
                          product_vendor_name: e.target.value,
                          getcare_product_id: -1,
                          getcare_product: {
                            id: 0,
                            name: "",
                            getcare_id: null,
                          },
                        },
                    });
                    this._loadUOMListByProduct(0);

                    }}
                 
                  />
                )}        
              />
              :  product_vendor_name}
            </div>
          }
          { this.getVisibleFieldFromName('getcare_uom_base_name') &&
            <div className={gridClasses.Col}>
              { isEditing ? <FieldEditable
                  disableClearable
                  fieldType="select"
                  fieldName="getcare_uom_base"
                  value={getcare_uom_base?.id || ''}
                  displayedValue={getcare_uom_base?.name}
                  editModeOnly
                  options={this.state.uomList || []}
                  error={!this._isUomValid()}
                  onSave={() => {return;}}
                  onChange={this.handleSaveField}
                  onOpen={this.handleOpenUOMSelect}
                />: getcare_uom_base?.name }
            </div>
          }
          { this.getVisibleFieldFromName('price_buy_total') &&
            <div className={gridClasses.Col}>
              { isEditing ? <FieldEditable
                fieldName="price_buy_total"
                value={validNumber(price_buy_total)}
                displayedValue={![null, undefined, ''].includes(price_buy_total) ? currencyFormatVN(price_buy_total) : ''}
                type="number"
                editModeOnly
                error={!this._isPriceBuyValid()}
                onSave={() => {return;}}
                onChange={this.handleSaveField}
              />: (![null, undefined, ''].includes(price_buy_total) ? currencyFormatVN(price_buy_total) : '') }
            </div>
          }
          { this.getVisibleFieldFromName('price_sales_total') &&
            <div className={gridClasses.Col}>
              { isEditing ? <FieldEditable
                fieldName="price_sales_total"
                value={validNumber(price_sales_total)}
                displayedValue={![null, undefined, ''].includes(price_sales_total) ? currencyFormatVN(price_sales_total) : ''}
                type="number"
                editModeOnly
                error={!this._isPriceSalesValid()}
                onSave={() => {return;}}
                onChange={this.handleSaveField}
              />: (![null, undefined, ''].includes(price_sales_total) ? currencyFormatVN(price_sales_total) : '') }
            </div>
          }

          { this.getVisibleFieldFromName('price_sales_retail_total') &&
            <div className={gridClasses.Col}>
              { isEditing ? <FieldEditable
                fieldName="price_sales_retail_total"
                value={validNumber(price_sales_retail_total)}
                displayedValue={![null, undefined, ''].includes(price_sales_retail_total) ? currencyFormatVN(price_sales_retail_total) : ''}
                type="number"
                editModeOnly
                error={!this._isPriceSalesRetailValid()}
                onSave={() => {return;}}
                onChange={this.handleSaveField}
              />: (![null, undefined, ''].includes(price_sales_retail_total) ? currencyFormatVN(price_sales_retail_total) : '') }
            </div>
          }
             { this.getVisibleFieldFromName('commission') &&
            <div className={gridClasses.Col}>
              { isEditing ? <FieldEditable
              fieldName="commission"
              value={validNumber(commission)}
              displayedValue={![null, undefined, ''].includes(commission) ? currencyFormatVN(commission) : ''}
              type="number"
              editModeOnly
              error={!this._isComissionValid()}
              onSave={() => {return;}}
              onChange={this.handleSaveField}
            />: (![null, undefined, ''].includes(commission) ? currencyFormatVN(commission) : '') }
            </div>
          }

          { this.getVisibleFieldFromName('vat') &&
            <div className={gridClasses.Col}>
              { isEditing ? <FieldEditable
                editModeOnly
                fieldType="select"
                fieldName="vat"
                value={vat}
                displayedValue={`${![null, undefined, ''].includes(vat) ? vat + `%` : ``}`}
                options={[...vatsOptions]}
                onSave={() => {return;}}
                onChange={this.handleSaveField}
              />: (![null, undefined, ''].includes(vat) ? vat + `%` : ``) }
            </div>
          }
          { this.getVisibleFieldFromName('minimum_quantity') &&
            <div className={gridClasses.Col}>
              { isEditing ? <FieldEditable
                fieldName="minimum_quantity"
                value={validNumber(minimum_quantity)}
                displayedValue={![null, undefined, ''].includes(minimum_quantity) ? numberFormat(minimum_quantity) : ''}
                type="number"
                isInteger
                editModeOnly
                error={!this._isMinimumQuantityValid()}
                onSave={() => {return;}}
                onChange={this.handleSaveField}
              />: (![null, undefined, ''].includes(minimum_quantity) ? numberFormat(minimum_quantity) : '') }
            </div>
          }
          { this.getVisibleFieldFromName('remaining_quantity') &&
            <div className={gridClasses.Col}>
              { isEditing ? <FieldEditable
                fieldName="remaining_quantity"
                isInteger
                value={validNumber(remaining_quantity)}
                displayedValue={![null, undefined, ''].includes(remaining_quantity) ? numberFormat(remaining_quantity) : ''}
                type="number"
                editModeOnly
                error={!this._isEstimatedQuantityValid()}
                onSave={() => {return;}}
                onChange={this.handleSaveField}
              />: (![null, undefined, ''].includes(remaining_quantity) ? numberFormat(remaining_quantity) : '') }
            </div>
          }
          { this.getVisibleFieldFromName('type_label') &&
            <div className={gridClasses.Col}>
              { isEditing ? <FieldEditable
                fieldName="type_label"
                value={type_label}
                displayedValue={type_label}
                editModeOnly
                maxLength={1}
                onSave={() => {return;}}
                onChange={this.handleSaveField}
              />: type_label }
            </div>
          }
          { this.getVisibleFieldFromName('weight') &&
            <div className={gridClasses.Col}>
              { isEditing ? <FieldEditable
                fieldName="weight"
                value={validNumber(weight)}
                displayedValue={![null, undefined, ''].includes(weight) ? numberFormat(weight) : ''}
                type="number"
                editModeOnly
                error={!this._isWeightValid()}
                onSave={() => {return;}}
                onChange={this.handleSaveField}
              />: (![null, undefined, ''].includes(weight) ? numberFormat(weight) : '') }
            </div>
          }
          { this.getVisibleFieldFromName('ka_days') &&
            <div className={gridClasses.Col}>
              { isEditing ? <FieldEditable
                fieldName="ka_days"
                value={validNumber(ka_days)}
                displayedValue={![null, undefined, ''].includes(ka_days) ? numberFormat(ka_days) : ''}
                type="number"
                editModeOnly
                error={!this._isKADaysValid()}
                onSave={() => {return;}}
                onChange={this.handleSaveField}
              />: (![null, undefined, ''].includes(ka_days) ? numberFormat(ka_days) : '') }
            </div>
          }
          { 
            // Getcare category
            this.getVisibleFieldFromName('getcare_category_ecoms') &&
            <div className={gridClasses.Col}>
              { isEditing ? <GetcareCategoryFormAutocomplete
                name="getcare_category_ecoms"
                multiple
                disableCloseOnSelect
                getFullData
                value={this.state.params.getcare_category_ecoms.map( item => item.id)}
                onChange={(e,value) => {
                  this.handleSaveField({
                    target: { name: 'getcare_category_ecoms' }
                  },value)
                }}
              /> : 
                <Box display="flex" flexWrap="wrap" style={{ gap: '.5rem' }}>
                  {
                    getcare_category_ecoms.map( item => (
                      <span key={item.id} className={`Badge BlueGrey Text`} style={{whiteSpace: 'nowrap'}}>
                        {item.name}
                      </span>
                    ))
                  }
                </Box>
              }
            </div>
          }
          { this.getVisibleFieldFromName('images') &&
            <div className={gridClasses.Col}>
              { isEditing ? <>
                {Array.isArray(this.state.params.images) && this.state.params.images.map( (url,index) => (
                  <div key={index} className={classes.RemoveImages}>
                    <span><a href={url} target="_blank">{url}</a><IconButton
                      style={{ padding: '0' }}
                      size="small"
                      onClick={() => this.handleSaveFieldImages({ action: 'REMOVE', index })}
                    >
                      <CancelOutlined fontSize="small" color="secondary" />
                    </IconButton></span>
                  </div> )
                )} 
                <Button onClick={() => this.props.addImagesProducts(this.handleSaveFieldImages)} variant="contained" size="small"  color="primary" >Upload</Button>
              </> : <div className={classes.ViewImages}>
                      {Array.isArray(images) ? 
                        images.map( (url,index) => (
                          <span key={index}><a onClick={(e) => e.stopPropagation() } href={url} target="_blank">{url}</a></span> 
                        )) :
                        ''
                      }
                    </div> 
              }
            </div>
          }

          { this.getVisibleFieldFromName('description') &&
            <div className={gridClasses.Col}>
                { isEditing ? <> 
                  <Button onClick={() => {
                 this.props.addDescriptionProducts(this.handleSaveFieldDescription)
                 this.props.getDescription(description)
              }
              } variant="contained" size="small"  color="secondary" >Thêm thông tin</Button>
              </> :   <div  className={gridClasses.Description}>
                   {description}
                </div>
              }
            </div>
          }
        { this.getVisibleFieldFromName('parent_vendor_product_code') &&
            <div className={gridClasses.Col}>
            { isEditing ? <TextField
                fieldName="parent_vendor_product_code"
                disableCloseOnSelect
                value={parent_vendor_product_code}
                disabled
              />: parent_vendor_product_code }
            </div>
          }

          { this.getVisibleFieldFromName('subsidiary_name') &&
              <div className={gridClasses.Col}>
              { isEditing ? <FieldEditable
                fieldName="subsidiary_name"
                value={subsidiary_name}
                displayedValue={subsidiary_name}
                editModeOnly
                onSave={() => {return;}}
                onChange={this.handleSaveField}
              />: subsidiary_name }
            </div>
          }
        


        { this.getVisibleFieldFromName('updated_at') &&
            <div className={gridClasses.Col}>
              { isEditing ? <TextField
                fieldName="updated_at"
                editModeOnly
                // displayedValue={format(validDate(updated_at), dateFormat)}
                disableCloseOnSelect
                value={updated_at ? format(validDate(updated_at), dateFormat) : ''}
                disabled
              />: validDate(updated_at) ? format(validDate(updated_at), dateFormat) : ''}
            </div>
          }

          { (isEditAllowed || isMappingAllowed) && (
            <div className={`${gridClasses.Col} ${classes.ActionsCol}`}>
              { isEditing ? <>
                <IconButton
                  onClick={this.handleCancel}
                  style={{ padding: '0' }}
                  size="small"
                >
                  <CancelOutlined fontSize="small" color="secondary" />
                </IconButton>
                <IconButton
                  disabled={!this._isRowValid()}
                  onClick={this.handleSave}
                  style={{ padding: '0' }}
                  size="small"
                >
                  <CheckCircleOutlineOutlined
                    fontSize="small"
                    color={this._isRowValid() ? `primary` : `inherit`}
                  />
                </IconButton>
              </> : <>
                { isEditAllowed && (
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={this.handleEdit}
                  ><EditOutlined fontSize="small"/></IconButton>
                )}
                { isMappingAllowed && (
                  <Button color="primary" size="small" onClick={this.handleClickMapping}>Mapping</Button>
                )}
              </>}
            </div>
          )}
        </div>
      </ClickAwayListener>
      { this.state.isDialogOpen &&
        <VendorProductMappingDialog
          isOpen={this.state.isDialogOpen}
          onClose={this.handleDialogClose}
          productName={this.props.product_vendor_name}
          onSubmitProductMapping={this.handleSubmitProductMapping}
        />
      }
    </>);
  }
}

VendorProductListItem.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  product_vendor_code: PropTypes.string,
  product_vendor_name: PropTypes.any,
  getcare_product: PropTypes.object,
  getcare_uom_base: PropTypes.object,
  price_buy_total: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  isSelected: PropTypes.bool,
  saveVendorProduct: PropTypes.func,
  removeVendorProducts: PropTypes.func,
  oriItem: PropTypes.object,
};

VendorProductListItem.defaultProps = {
  product_vendor_code: '',
  product_vendor_name: null,
  getcare_product: null,
  getcare_uom_base: null,
  price_buy_total: 0,
  isSelected: false,
  oriItem: null,
}

export default VendorProductListItem;
