import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { debounce } from 'lodash';

import {
  getAssetBrandingList,
  saveAssetBrandingAllowedActions,
  saveAssetBrandingVisibility,
} from 'redux/actions/admin/assetBrandingActions';
import {
  makeSelectAssetBrandingList,
  makeSelectAssetBrandingListTotal,
  makeSelectAssetBrandingLoading,
  makeSelectAssetBrandingActionLoading,
  makeSelectAssetBrandingAllowedActions,
  makeSelectAssetBrandingVisibility,
} from 'redux/selectors';
import {
  listParamsMap,
  listDisplayFields,
} from 'utils/constanst/adminAssetBrandingConstants';
import {
  getSortsString,
  getDisplayFields,
} from 'utils/helper';
import { withRouter } from 'react-router-dom';
import { isActionAllowed } from 'utils/helper/authorization';
import authApi from 'utils/api/authApi';
import assetBrandingApi from 'utils/api/admin/assetBrandingApi';

import AssetBrandingListFilters from 'components/admin/AssetBranding/List/Filters/AssetBrandingListFilters';
import AssetBrandingList from 'components/admin/AssetBranding/List/AssetBrandingList';
import AssetBrandingFormDialog from 'components/admin/AssetBranding/FormDialog/AssetBrandingFormDialog';
import ListPagination from 'components/ListPagination/ListPagination';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import Button from '@material-ui/core/Button';

import classes from './AssetBrandings.module.scss';
import { toast } from 'react-toastify';

class AssetBrandings extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      listParams: { ...listParamsMap },
      assetBrandingFormDialogOpen: false,
      assetBrandingFormDialogData: null,
      assetBrandingFormDialogAction: null,
      confirmDialogOpen: false,
      confirmDialogTitle: '',
      confirmDialogMessage: '',
      confirmDialogSubmit: null,
      confirmDialogSubmitParams: null,
      confirmDialogLoading: false,
    };
  }
  async componentDidMount() {
    const { id } = this.props.match.params;
    await this.getAllowedActions();
    this._loadData();
  }

  getAllowedActions = async () => {
    const { data: response } = await authApi.getAllowedActions({
      component: 'asset_brand',
    });
    if (!response.result) {
      toast.error(response.message);
      return;
    }
    this.props.saveAssetBrandingAllowedActions(response.allowed_actions || []);
    this.props.saveAssetBrandingVisibility(response.visibility || null);
  };
  getVisibleColumns = () => {
    const { assetBrandingVisibility } = this.props;
    const readableFields = assetBrandingVisibility?.read;
    const editableFields = assetBrandingVisibility?.edit;
    return listDisplayFields.filter(field =>
      ( readableFields === undefined || [null, undefined].includes(field.authorize_name) || readableFields.includes(field.authorize_name) )
    ).map(field => ({
      ...field,
      editable: editableFields && editableFields.includes(field.authorize_name),
    }));
  }
  _loadData = () => {
    this.props.getAssetBrandingList({
      params: this.state.listParams,
    });
  };
  _removeAssetBranding = async (assetBrandingId) => {
    this.setState({ confirmDialogLoading: true });
    const { data: response } = await assetBrandingApi.deleteAssetBrandingMultiple({
      params: {
        ids: [assetBrandingId]
      }
    })
    this.setState({ confirmDialogOpen: false, confirmDialogLoading: false });
    if (!response.result) {
      toast.error(response.message);
      return;
    }
    toast.success(`Đã xóa thành công asset branding`);
    this._loadData();
  }

  handleSortChange = ({ sortBy, sortDir }) => {
    const newSortString = getSortsString({
      [sortBy]: sortDir,
    });
    this.handleFilterChange([
      {
        name: 'order',
        value: newSortString,
      },
    ]);
  };
  handleFilterChange = (filters, forceResetPage = false) => {
    const paramsFromFilters = filters.reduce((memo, item) => {
      memo[item.name] = item.value;
      return memo;
    }, {});
    const newParams = forceResetPage
      ? {
          ...this.state.listParams,
          ...paramsFromFilters,
          page: 1,
        }
      : {
          ...this.state.listParams,
          ...paramsFromFilters,
        };
    this.setState({
      listParams: newParams,
    });
    this.props.getAssetBrandingList({
      params: newParams,
    });
  }
  handleOpenAssetBrandingsDialog = (assetBranding) => {
    this.setState({ 
      assetBrandingFormDialogOpen: true,
      assetBrandingFormDialogData: {...assetBranding},
      assetBrandingFormDialogAction: assetBranding?.id ? 'UPDATE' : 'CREATE',
    })
  }
  handleCloseAssetBrandingsDialog = () => {
    this.setState({ 
      assetBrandingFormDialogOpen: false,
    })
  }
  handleSubmitSuccessAssetBrandingsDialog = () => {
    this._loadData();
  }
  handleRemoveAssetBrandingsDialog = (assetBranding) => {
    this.setState({
      confirmDialogOpen: true,
      confirmDialogTitle: `Xóa asset branding`,
      confirmDialogMessage: `Bạn chắc muốn xóa ${assetBranding.name} chứ? Sẽ không thể hoàn tác lại hành động này.`,
      confirmDialogSubmit: this._removeAssetBranding,
      confirmDialogSubmitParams: assetBranding.id,
    })
  }
  handleConfirmDialogClose = () => {
    this.setState({ confirmDialogOpen: false })
  }
  

  render() {
    const {
      assetBrandingListTotal,
      assetBrandingList,
      loading,
      actionLoading,
      assetBrandingAllowedActions,
    } = this.props;
    // const { id } = this.props.match.params;
    const { 
      listParams,
      assetBrandingFormDialogOpen,
      assetBrandingFormDialogData,
      assetBrandingFormDialogAction,
    } = this.state;
    const isListLoading = loading || actionLoading;
    // const hasSelected = selectedItems.length > 0;
    // const isChanged = this._isAssetBrandingsChanged();
    const isEditAllowed = isActionAllowed('edit', assetBrandingAllowedActions);
    const isDeleteAllowed = isActionAllowed('delete', assetBrandingAllowedActions);
    // const isApprovalAllowed = isActionAllowed('approve_product', assetBrandingAllowedActions);
    // const isImportAllowed = isActionAllowed('import', assetBrandingAllowedActions);
    // const isExportAllowed = isActionAllowed('export', assetBrandingAllowedActions);
    const visibleColumns = this.getVisibleColumns();

    return (
      <React.Fragment>
        <div className={classes.PageWrap}>
          <div className={classes.PageHeader}>
            <h1 className={classes.PageTitle}>
              Asset Branding
            </h1>
            <div className={classes.PageHeaderToolbar}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={this.handleOpenAssetBrandingsDialog}
              >
                Tạo mới
              </Button>
            </div>
          </div>
          <div className={classes.PageMain}>
            <AssetBrandingListFilters onFilterChange={debounce(this.handleFilterChange, 500)}/>
            <AssetBrandingList
              displayFields={getDisplayFields(
                listParams,
                visibleColumns
              )}
              assetBrandingList={assetBrandingList}
              isLoading={isListLoading}
              isEditAllowed={isEditAllowed}
              isDeleteAllowed={isDeleteAllowed}
              onSortChange={this.handleSortChange}
              onEditAssetBrandings={this.handleOpenAssetBrandingsDialog}
              onRemoveAssetBrandings={this.handleRemoveAssetBrandingsDialog}
            />
          </div>
          <div className={classes.PageFooter}>
            <ListPagination
              page={listParams.page}
              pageSize={listParams.page_size}
              total={assetBrandingListTotal}
              listName="asset branding"
              onFilterChange={this.handleFilterChange}
            />
          </div>
        </div>
        {
          assetBrandingFormDialogOpen && 
          <AssetBrandingFormDialog
            open={assetBrandingFormDialogOpen}
            data={assetBrandingFormDialogData}
            action={assetBrandingFormDialogAction}
            onClose={this.handleCloseAssetBrandingsDialog}
            onSubmitSuccess={this.handleSubmitSuccessAssetBrandingsDialog}
          />
        }

        { this.state.confirmDialogOpen
          && <ConfirmationDialog
            isOpen={this.state.confirmDialogOpen}
            title={this.state.confirmDialogTitle}
            message={this.state.confirmDialogMessage}
            onClose={this.handleConfirmDialogClose}
            isLoading={this.state.confirmDialogLoading}
            onSubmit={() => {
              this.state.confirmDialogSubmit && this.state.confirmDialogSubmit(this.state.confirmDialogSubmitParams);
            }}
          />
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  assetBrandingListTotal: makeSelectAssetBrandingListTotal(),
  assetBrandingList: makeSelectAssetBrandingList(),
  loading: makeSelectAssetBrandingLoading(),
  actionLoading: makeSelectAssetBrandingActionLoading(),
  assetBrandingAllowedActions: makeSelectAssetBrandingAllowedActions(),
  assetBrandingVisibility: makeSelectAssetBrandingVisibility(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getAssetBrandingList: (payload) => dispatch(getAssetBrandingList(payload)),
    saveAssetBrandingAllowedActions: (payload) => dispatch(saveAssetBrandingAllowedActions(payload)),
    saveAssetBrandingVisibility: (payload) => dispatch(saveAssetBrandingVisibility(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter)(AssetBrandings);
