import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getProductDetails, saveProductAllowedActions } from 'redux/actions/admin/productActions';
import { createStructuredSelector } from "reselect";
import {
  makeSelectProductDetails,
  makeSelectProductList,
  makeSelectProductAllowedActions,
} from 'redux/selectors';
import { withRouter } from 'react-router-dom';
import { relatedProductsPageSize } from 'utils/constanst/adminProductConstants';
import authApi from 'utils/api/authApi';
import { isActionAllowed } from 'utils/helper/authorization';
import { toast } from 'react-toastify';

import ProductDetails from 'components/admin/ProductDetails/ProductDetails';
import DetailsPageHeader from 'components/DetailsPageHeader/DetailsPageHeader';
import RelatedProducts from 'components/admin/ProductDetails/RelatedProducts/RelatedProducts';
import Panel from 'components/Panel/Panel';
import classes from './Product.module.scss';

class Product extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      nextProductId: null,
      prevProductId: null,
      relatedProducts: [],
    }
  }

  async componentDidMount() {
    await this.getAllowedActions();

    const { id } = this.props.match.params;
    this.prepareDetailsData(id);
  }

  async componentDidUpdate(prevProps) {
    const { id } = this.props.match.params;
    if (id && id !== prevProps.match.params.id) {
      await this.getAllowedActions();

      this.prepareDetailsData(id);
    }
  }

  getAllowedActions = async () => {
    const { data: response } = await authApi.getAllowedActions({
      component: 'products',
    });
    if (!response.result) {
      toast.error(response.message);
      return;
    }
    this.props.saveProductAllowedActions(response.allowed_actions || []);
  };

  prepareDetailsData = (id) => {
    const isCreateAllowed = isActionAllowed('create', this.props.productAllowedActions);
    const productId = Number(id);
    if (!isNaN(productId) && productId > 0) {
      this.loadData(productId);
      this.setRelatedProducts(productId);
      return;
    }
    // is creating
    if (!isCreateAllowed) {
      window.location.replace(`/403`);
    }
  }

  loadData = (id) => {
    this.props.getProductDetails(id);
  }

  backToList = () => {
    this.props.history.push('/products');
  }

  goToPrevProduct = () => {
    this.props.history.push(`/products/${this.state.prevProductId}`);
  }

  goToNextProduct = () => {
    this.props.history.push(`/products/${this.state.nextProductId}`);
  }

  setRelatedProducts = (id) => {
    const { productList } = this.props;
    const productIndex = productList.findIndex(p => p.id.toString() === id.toString());
    if (productIndex < 0) {
      this.setState({
        relatedProducts: [],
        nextProductId: null,
        prevProductId: null,
      });
      return;
    }

    const nextProducts = productList.slice(productIndex + 1, productIndex + relatedProductsPageSize + 1);
    const prevIndex = (productIndex - 1) > -1 ? (productIndex - 1) : productList.length - 1
    if (nextProducts.length === relatedProductsPageSize) {
      this.setState({
        relatedProducts: nextProducts,
        nextProductId: nextProducts[0]?.id,
        prevProductId: productList[prevIndex]?.id,
      });
      return;
    }

    const lastIndex = Math.min(relatedProductsPageSize - nextProducts.length, productIndex);
    const prevProducts = productList.slice(0, lastIndex);
    const relatedProducts = [...nextProducts, ...prevProducts];
    this.setState({
      relatedProducts: relatedProducts,
      nextProductId: relatedProducts[0]?.id,
      prevProductId: productList[prevIndex]?.id,
    });
    return;
  }

  render() {
    const { productDetails, productAllowedActions } = this.props;
    const { prevProductId, nextProductId, relatedProducts } = this.state;
    const isLoading = !productDetails;
    const isEditAllowed = isActionAllowed('edit', productAllowedActions);

    return (
      <div className={classes.PageMain}>
        <div className={classes.PageMainHeader}>
          <DetailsPageHeader
            isLoading={isLoading}
            title={productDetails && productDetails.name}
            prevItemId={prevProductId}
            nextItemId={nextProductId}
            backToList={this.backToList}
            goToPrevItem={this.goToPrevProduct}
            goToNextItem={this.goToNextProduct}
          />
        </div>
        {relatedProducts.length > 0 &&
          <div className={classes.PageSide}>
            <Panel
              content={<RelatedProducts relatedProducts={relatedProducts}/>}
              panelClassName={classes.PanelWrap}
              titleClassName={classes.PageSideHeader}
              contentClassName={classes.PageSideContent}
              isBorder
            />
          </div>
        }
        <div className={classes.PageContent}>
          <div className={classes.PageContentInner}>
            <ProductDetails
              className={isLoading && 'OverlayLoading'}
              productDetails={productDetails}
              isEditAllowed={isEditAllowed}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  productDetails: makeSelectProductDetails(),
  productList: makeSelectProductList(),
  productAllowedActions: makeSelectProductAllowedActions(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getProductDetails: (payload) => dispatch(getProductDetails(payload)),
    saveProductAllowedActions: (payload) => dispatch(saveProductAllowedActions(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter)(Product);
