import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { saveSalesTeamMember, removeSalesTeamMember } from 'redux/actions/admin/salesTeamActions';
import { connect } from 'react-redux';
import { compose } from 'redux';
import salesTeamApi from 'utils/api/admin/salesTeamApi';
import { debounce } from 'lodash';
import {
  OTHER_SALES_ROLE,
  otherSalesRoleMap,
} from 'utils/constanst/adminSalesTeamConstants';

import EditOutlined from '@material-ui/icons/EditOutlined';
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import IconButton from '@material-ui/core/IconButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';

import gridClasses from '../SalesTeamMembersGrid.module.scss';
import classes from './SalesTeamMember.module.scss';

class SalesTeamMember extends PureComponent {
  constructor(props) {
    super(props);

    const isOtherRoleSelected =
      props.getcare_sales_role &&
      (!props.getcare_sales_role.id || props.getcare_sales_role.id === OTHER_SALES_ROLE) &&
      props.getcare_sales_role.name;

    this.state = {
      isEditing: !props.getcare_sales?.id,
      isDialogOpen: false,
      inputSaleCode: '',
      inputSaleName: '',
      salesUsers: [],
      selectedMember: {
        id: props.getcare_sales?.id,
        name: props.getcare_sales?.name,
        code: props.getcare_sales?.code,
      },
      selectedRole: !isOtherRoleSelected
        ? { ...props.getcare_sales_role }
        : {
            id: OTHER_SALES_ROLE,
            name: otherSalesRoleMap[OTHER_SALES_ROLE],
          },
      anotherRole: !isOtherRoleSelected ? '' : props.getcare_sales_role.name,
    };
    this.validator = {
      anotherRole: {
        maxLength: 50,
      }
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { inputSaleName, inputSaleCode } = this.state;
    if (inputSaleName !== prevState.inputSaleName) {
      this.loadSalesUsers({
        params: {
          name: inputSaleName,
        },
      });
    }
    if (inputSaleCode !== prevState.inputSaleCode) {
      this.loadSalesUsers({
        params: {
          code: inputSaleCode,
        },
      });
    }
    const { getcare_sales, getcare_sales_role } = this.props;
    if (getcare_sales !== prevProps.getcare_sales) {
      this.setState({
        selectedMember: { ...getcare_sales },
      });
    }
    if (getcare_sales_role !== prevProps.getcare_sales_role) {
      const isOtherRoleSelected =
        getcare_sales_role && (!getcare_sales_role.id || getcare_sales_role.id === OTHER_SALES_ROLE) && getcare_sales_role.name;
      this.setState({
        selectedRole: !isOtherRoleSelected
          ? { ...getcare_sales_role }
          : { id: OTHER_SALES_ROLE, name: otherSalesRoleMap[OTHER_SALES_ROLE] },
        anotherRole: !isOtherRoleSelected ? '' : getcare_sales_role.name,
      });
    }
  }

  loadSalesUsers = debounce(async ({ params }) => {
    const { data: response } = await salesTeamApi.getSalesUsers({ params });
    if (!response?.result) return;
    this.setState({
      salesUsers: response.data
        ? this.filteredSalesUsers([...response.data])
        : [],
    });
  }, 500);
  filteredSalesUsers = (fullArr) => {
    return fullArr.filter((user) => {
      return !this.props.currentList.some(
        (item) => item.getcare_sales?.id === user.id
      );
    });
  };
  isNameValid = () => {
    return !!this.state.selectedMember.id;
  };
  isRowValid = () => {
    return this.isNameValid();
  };
  _hasOneItem = () => {
    return this.props.currentList.filter(item => !!item.getcare_sales).length <= 1;
  }

  handleEdit = () => {
    this.setState({
      isEditing: true,
    });
  };
  handleCancel = () => {
    this.setState({
      isEditing: false,
    });
    if (!this.props.id) {
      this.props.removeSalesTeamMember({
        id: this.props.id,
        idStr: this.props.idStr,
      });
    }
  };
  handleSave = () => {
    const { selectedMember, selectedRole } = this.state;
    this.props.saveSalesTeamMember({
      idStr: this.props.idStr,
      id: this.props.id,
      getcare_sales: {
        id: selectedMember.id,
        name: selectedMember.name,
        code: selectedMember.code,
      },
      getcare_sales_role:
        selectedRole.id === OTHER_SALES_ROLE
          ? { name: this.state.anotherRole.trim() }
          : { ...selectedRole },
    });
    this.setState({
      isEditing: false,
    });
    this.props.onSaveLists();
  };
  handleDelete = () => {
    this.props.removeSalesTeamMember({
      idStr: this.props.idStr,
      id: this.props.id,
    });
    this.props.onSaveLists();
  };
  handleRowClick = () => {
    if (!this.state.isEditing) {
      this.handleEdit();
    }
  };
  handleRowBlur = () => {
    if (this.state.isEditing && this.isRowValid()) {
      this.handleSave();
    }
  };
  handleAnotherRoleChange = (e) => {
    const maxLength = this.validator.anotherRole.maxLength;
    const newValue = maxLength ? e.target.value.slice(0, maxLength) : e.target.value;
    this.setState({
      anotherRole: newValue,
    });
  };
  handleOpenDialog = (e) => {
    if (e) e.stopPropagation();
    this.setState({
      isDialogOpen: true,
    });
  }
  handleCloseDialog = () => {
    this.setState({
      isDialogOpen: false,
    });
  }

  render() {
    const { getcare_sales, getcare_sales_role, salesRoles, id } = this.props;
    const { isEditing, selectedMember, selectedRole, salesUsers } = this.state;
    const isDeleteDisabled = this._hasOneItem();

    return (<>
      <ClickAwayListener onClickAway={this.handleRowBlur}>
        <div
          className={`${classes.ProductItem} ${gridClasses.Row}`}
          onClick={this.handleRowClick}
        >
          <div className={gridClasses.Col}>
            {isEditing && !id ? (
              <Autocomplete
                selectOnFocus
                size="small"
                handleHomeEndKeys={false}
                value={selectedMember || null}
                onChange={(e, value) => {
                  this.setState({
                    selectedMember: { ...value },
                  });
                }}
                options={salesUsers || []}
                filterOptions={(x) => x}
                renderOption={(option) => `${option.name} - ${option.code}`}
                getOptionLabel={(option) => (option && option.name) || ''}
                getOptionSelected={(option, value) =>
                  value && value.id && option ? option.id === value.id : null
                }
                onInputChange={(e, newInputValue) => {
                  this.setState({
                    inputSaleName: newInputValue,
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!this.isNameValid()}
                    placeholder="- Chọn -"
                  />
                )}
              />
            ) : (
              getcare_sales?.name
            )}
          </div>
          <div className={gridClasses.Col}>
            {isEditing && !id ? (
              <Autocomplete
                selectOnFocus
                size="small"
                handleHomeEndKeys={false}
                value={selectedMember || null}
                onChange={(e, value) => {
                  this.setState({
                    selectedMember: { ...value },
                  });
                }}
                options={salesUsers || []}
                filterOptions={(x) => x}
                renderOption={(option) => `${option.name} - ${option.code}`}
                getOptionLabel={(option) => (option && option.code) || ''}
                getOptionSelected={(option, value) =>
                  value && option && value.id ? option.id === value.id : null
                }
                onInputChange={(e, newInputValue) => {
                  this.setState({
                    inputSaleCode: newInputValue,
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="- Chọn -" />
                )}
              />
            ) : (
              getcare_sales?.code
            )}
          </div>
          <div className={gridClasses.Col}>
            {isEditing ? (
              <div className={classes.RoleFieldsWrap}>
                <Autocomplete
                  openOnFocus
                  selectOnFocus
                  size="small"
                  handleHomeEndKeys={false}
                  value={selectedRole || null}
                  onChange={(event, newValue) => {
                    this.setState({
                      selectedRole: { ...newValue },
                    });
                  }}
                  options={salesRoles || []}
                  filterOptions={(x) => x}
                  renderOption={(option) => `${option.name}`}
                  getOptionLabel={(option) => (option && option.name) || ''}
                  getOptionSelected={(option, value) =>
                    value && option && value.id ? option.id === value.id : null
                  }
                  renderInput={(params) => (
                    <TextField {...params} placeholder="- Chọn -" />
                  )}
                />
                {selectedRole?.id === OTHER_SALES_ROLE && (
                  <TextField
                    value={this.state.anotherRole}
                    placeholder="Nhập..."
                    onChange={this.handleAnotherRoleChange}
                  />
                )}
              </div>
            ) : (
              getcare_sales_role?.name
            )}
          </div>
          <div className={`${gridClasses.Col} ${classes.ActionsCol}`}>
            {isEditing ? (
              <>
                <IconButton
                  onClick={this.handleCancel}
                  style={{ padding: '3px' }}
                  size="small"
                >
                  <CancelOutlined
                    fontSize="small"
                    color='secondary'
                  />
                </IconButton>
                <IconButton
                  onClick={this.handleSave}
                  style={{ padding: '3px' }}
                  size="small"
                  disabled={!this.isRowValid()}
                >
                  <CheckCircleOutlineOutlined
                    fontSize="small"
                    color={this.isRowValid() ? `primary` : `inherit`}
                  />
                </IconButton>
              </>
            ) : (
              <>
                <IconButton
                  disabled={isDeleteDisabled}
                  onClick={this.handleOpenDialog}
                  style={{ padding: '3px' }}
                  size="small"
                >
                  <DeleteOutline fontSize="small" />
                </IconButton>
                <IconButton
                  onClick={this.handleEdit}
                  style={{ padding: '3px' }}
                  size="small"
                >
                  <EditOutlined fontSize="small" color="primary" />
                </IconButton>
              </>
            )}
          </div>
        </div>
      </ClickAwayListener>
      { this.state.isDialogOpen &&
        <ConfirmationDialog
          isOpen={this.state.isDialogOpen}
          title="Xác nhận xoá"
          message={(<p>{`Xoá thành viên: `} <strong>{getcare_sales?.name}</strong> ra khỏi đội ngũ bán hàng.</p>)}
          onClose={this.handleCloseDialog}
          onSubmit={this.handleDelete}
        />
      }
    </>);
  }
}

SalesTeamMember.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  getcare_sales: PropTypes.object,
  getcare_sales_role: PropTypes.object,
  idStr: PropTypes.string,
  currentList: PropTypes.array,
  salesRoles: PropTypes.array,
  onSaveLists: PropTypes.func,
};

SalesTeamMember.defaultProps = {
  currentList: [],
  salesRoles: [],
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveSalesTeamMember: (payload) => dispatch(saveSalesTeamMember(payload)),
    removeSalesTeamMember: (payload) => dispatch(removeSalesTeamMember(payload)),
  };
};
const withConnect = connect(null, mapDispatchToProps);
export default compose(withConnect)(SalesTeamMember);
