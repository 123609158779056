import { matchingKeyword } from 'utils/helper/index';

export const CATEGORY_MAX_LEVEL = 5;

// function

// Lấy danh sách category theo level
export function getGetcareCategoryLevelList({ level, getcareCategoryTrees, selectedCategories, keyword }) {
    const getcareCategoryList = mapGetcareCategoryTreeToList(getcareCategoryTrees); // map category theo dạng list
    return getcareCategoryList.filter( item => {
        if ( keyword ) return matchingKeyword(keyword,item.name) && item.level === level; // check nếu có tìm kiếm thì list tất cả category theo kết quả name
        if ( !level ) return item.level === level; // mặc định list main category
        // list children category theo category được chọn
        const selectedCategoryLevel = selectedCategories.find( selectedCategory => selectedCategory.level === level - 1 );
        return item.level === level && item.parent_id === selectedCategoryLevel?.category_id;
    })
}
export function getSelectedGetcareCategoryLevel({ level, selectedCategories }) {
    return selectedCategories.find( item => item.level === level );
}
export function mapGetcareCategoryTreeToList(getcareCategoryTrees) {
    let getcareCategoryList = [];
    const loops = (_getcareCategoryTrees,level) => {
        for ( let getcareCategory of _getcareCategoryTrees ) {
            
            getcareCategoryList.push({...getcareCategory, level});
            if ( getcareCategory.children_items ) loops(getcareCategory.children_items,level+1);
        }
    }
    loops(getcareCategoryTrees,0);
    return getcareCategoryList;
}
export function mapGetcareCategoryTrees(getcareCategoryTrees,parent_id = undefined) {
    return getcareCategoryTrees.map( item => {
      if ( Array.isArray(item.children_items) ) return {
        ...item,
        parent_id,
        children_items: [...mapGetcareCategoryTrees(item.children_items,item.id)]
      }; 
      return { ...item, parent_id };
    })
}