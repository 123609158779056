import {
  SAVE_TOTAL,
  SAVE_LOADING,
  SAVE_DELETE_LOADING,
  SAVE_COORDINATER_LIST,
  SAVE_COORDINATER_ITEM,
  REMOVE_COORDINATER_ITEMS,
  SAVE_COORDINATER_ALLOWED_ACTIONS,
} from 'redux/constants/admin/coordinaterConstants';

const initialState = {
  coordinaterList: [],
  coordinaterListTotal: 0,
  loading: false,
  deleteLoading: false,
  coordinaterAllowedActions: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_LOADING: {
      return {
        ...state,
        loading: action.loading,
      };
    }
    case SAVE_DELETE_LOADING: {
      return {
        ...state,
        deleteLoading: action.deleteLoading,
      };
    }
    case SAVE_COORDINATER_ITEM: {
      const list = [...state.coordinaterList];
      let newTotal = state.coordinaterListTotal;
      const comparedField = action.coordinaterItem.idStr
        ? 'idStr'
        : 'id';
      const index = list.findIndex((p) => p[comparedField] === action.coordinaterItem[comparedField]);
      if (index > -1) {
        const tempItem = { ...list[index] };
        list[index] = { ...tempItem, ...action.coordinaterItem };
      } else {
        list.unshift({ ...action.coordinaterItem });
        newTotal++;
      }
      return {
        ...state,
        coordinaterList: list,
        coordinaterListTotal: newTotal,
      };
    }
    case REMOVE_COORDINATER_ITEMS: {
      const newTotal = state.coordinaterListTotal - action.coordinaterItems.length;
      const list = state.coordinaterList.filter(
        (p) =>
          !action.coordinaterItems.some((item) => {
            const comparedField = item.idStr ? 'idStr' : 'id';
            return item[comparedField] === p[comparedField];
          })
      );
      return {
        ...state,
        coordinaterList: list,
        coordinaterListTotal: newTotal,
      };
    }
    case SAVE_COORDINATER_LIST: {
      const list = action.coordinaterList;
      return {
        ...state,
        coordinaterList: list ? [...list] : [],
      };
    }
    case SAVE_TOTAL: {
      return {
        ...state,
        coordinaterListTotal: action.coordinaterListTotal,
      };
    }
    case SAVE_COORDINATER_ALLOWED_ACTIONS: {
      return {
        ...state,
        coordinaterAllowedActions: [...action.coordinaterAllowedActions],
      };
    }
    default:
      return state;
  }
}

export default reducer;
