import React, { PureComponent } from 'react';
import { getDisplayFields } from 'utils/helper';
import {
  keyAccountListDisplayFields,
  keyAccountListParamsMap,
} from 'utils/constanst/adminSalesTeamConstants';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectSalesTeamKeyAccounts } from 'redux/selectors';

import SalesTeamKeyAccount from './SalesTeamKeyAccount/SalesTeamKeyAccount';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';

import gridClasses from './SalesTeamKeyAccountsGrid.module.scss';
import classes from './SalesTeamKeyAccounts.module.scss';

class SalesTeamKeyAccounts extends PureComponent {
  render() {
    const { salesTeamKeyAccounts } = this.props;
    const isLoading = !salesTeamKeyAccounts;
    const displayFields = getDisplayFields({ ...keyAccountListParamsMap }, [
      ...keyAccountListDisplayFields,
    ]);

    return (
      <div className={classes.ListWrap}>
        <div className={`${classes.List} ${isLoading && 'OverlayLoading'}`}>
          <div className={`${classes.Body}`}>
            <div className={`${gridClasses.Row} ${classes.Header}`}>
              {displayFields.map((item, index) => (
                <ListHeaderCol
                  key={`${index}`}
                  className={`${gridClasses.Col} ${classes.HeaderCol}`}
                  {...item}
                />
              ))}
            </div>
          
            {salesTeamKeyAccounts && salesTeamKeyAccounts.length ? (
              salesTeamKeyAccounts.map((item, index) => (
                <SalesTeamKeyAccount key={`sale-${index}`} {...item} />
              ))
            ) : (
              <p className="NoData">Không có data</p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

SalesTeamKeyAccounts.propTypes = {};

SalesTeamKeyAccounts.defaultProps = {};

const mapStateToProps = createStructuredSelector({
  salesTeamKeyAccounts: makeSelectSalesTeamKeyAccounts(),
});
const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(SalesTeamKeyAccounts);
