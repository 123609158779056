import React, { PureComponent } from 'react';
import { getDisplayFields, genID } from 'utils/helper';
import {
  channelListDisplayFields,
  channelListParamsMap,
} from 'utils/constanst/adminSalesTeamConstants';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { makeSelectSalesTeamChannels } from 'redux/selectors';
import { saveSalesTeamChannel } from 'redux/actions/admin/salesTeamActions';

import SalesTeamChannel from './SalesTeamChannel/SalesTeamChannel';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';
import Button from '@material-ui/core/Button';

import gridClasses from './SalesTeamChannelsGrid.module.scss';
import classes from './SalesTeamChannels.module.scss';

class SalesTeamChannels extends PureComponent {
  handleCreateNewRow = () => {
    this.props.saveSalesTeamChannel({
      idStr: genID(),
      id: 0,
      sales_channel: null,
    });
  };

  render() {
    const { salesTeamChannels } = this.props;
    const isLoading = !salesTeamChannels;
    const displayFields = getDisplayFields({ ...channelListParamsMap }, [
      ...channelListDisplayFields,
    ]);

    return (
      <div className={classes.ListWrap}>
        <div className={`${classes.List} ${isLoading && 'OverlayLoading'}`}>
          <div className={`${classes.Body}`}>
            <div className={`${gridClasses.Row} ${classes.Header}`}>
              {displayFields.map((item, index) => (
                <ListHeaderCol
                  key={`${item.name}-${index}`}
                  className={`${gridClasses.Col} ${classes.HeaderCol}`}
                  {...item}
                />
              ))}
              <div
                className={`${gridClasses.Col} ${classes.HeaderCol} ${classes.ActionCol}`}
              >
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  onClick={this.handleCreateNewRow}
                >
                  Thêm dòng
                </Button>
              </div>
            </div>
          
            {salesTeamChannels && salesTeamChannels.length ? (
              salesTeamChannels.map((item) => (
                <SalesTeamChannel
                  key={`sale-${item.id || item.idStr}`}
                  {...item}
                  currentList={[...salesTeamChannels]}
                  onSaveLists={this.props.onSaveLists}
                />
              ))
            ) : (
              <p className="NoData">Không có data</p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

SalesTeamChannels.propTypes = {
  onSaveLists: PropTypes.func,
};

SalesTeamChannels.defaultProps = {};

const mapStateToProps = createStructuredSelector({
  salesTeamChannels: makeSelectSalesTeamChannels(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    saveSalesTeamChannel: (payload) => dispatch(saveSalesTeamChannel(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(SalesTeamChannels);
