import React, { PureComponent } from 'react';
import priceTestApi from 'utils/api/admin/priceTestApi';
import { toast } from 'react-toastify';
import isEqual from 'lodash/isEqual';

import ProductSelection from './ProductSelection/ProductSelection';
import Policies from './Policies/Policies';
import Button from '@material-ui/core/Button';

class PolicyPrices extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedProduct: null,
      policyList: null,
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { policyList, selectedProduct } = this.state;
    if (policyList && (selectedProduct && !isEqual(selectedProduct, prevState.selectedProduct))) {
      this.setState({ policyList: null });
    }
  }
  _isAbleToCheck = () => {
    const { selectedProduct } = this.state;
    return selectedProduct;
  }
  _loadPolicies = async () => {
    const { data: response } = await priceTestApi.getProductPolicyPrices({
      params: {
        getcare_product_price_vendor_id: this.state.selectedProduct?.id,
      },
    });
    if (!response?.result || !response.data) {
      toast.error(response.message);
      return;
    }
    this.setState({
      policyList: response.data,
    });
  }
  handleChangeProduct = (newValue) => {
    this.setState({
      selectedProduct: { ...newValue },
    });
  }
  handleCheckPolicies = () => {
    this._loadPolicies();
  }

  render() {
    return (<>
      <ProductSelection selectedProduct={this.state.selectedProduct} onChangeProduct={this.handleChangeProduct}/>
      <Button
        disabled={!this._isAbleToCheck()}
        variant="contained"
        color="primary"
        onClick={this.handleCheckPolicies}
      >
        Xem chính sách
      </Button>
      { this._isAbleToCheck() && this.state.policyList && <Policies list={[...this.state.policyList]} /> }
    </>);
  }
}

export default PolicyPrices;
