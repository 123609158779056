import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classes from './RelatedStores.module.scss';

class RelatedStores extends PureComponent {
  render() {
    const { relatedStores, baseUrl } = this.props;

    return (
      <>
        {relatedStores.length > 0 ? (
          <ul className={classes.List}>
            { relatedStores.map(store => (
              <li key={store.id}>
                <Link to={`${baseUrl}/${store.id}`}>
                  <h4 className={classes.Heading}>{ store.name }</h4>
                  <p className={classes.Address}>{ store.address }</p>
                  <p className={classes.Phone}>{ store.phone }</p>
                </Link>
              </li>)
            ) }
          </ul>)
          : (<p className="NoData">Không có nhà thuốc nào</p>)
        }
      </>
    );
  }
}

RelatedStores.propTypes = {
  relatedStores: PropTypes.array,
};

RelatedStores.defaultProps = {
  relatedStores: [],
};

export default RelatedStores;
