import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { currencyFormatVN } from 'utils/helper';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { updateAwaitingProduct } from 'redux/actions/admin/productActions';

import Checkbox from '@material-ui/core/Checkbox';
import FieldEditInline from 'components/FieldEditInline/FieldEditInline';

import gridClasses from '../AwaitingProductListGrid.module.scss';
import classes from './AwaitingProductListItem.module.scss';

class AwaitingProductListItem extends PureComponent {
  findBrandById = (id) => {
    return this.props.brands.find((item) => item.id === id);
  };

  findIndustryById = (id) => {
    return this.props.industries.find((item) => item.id === id);
  };

  handleCheckboxChange = (e) => {
    e.stopPropagation();
    this.props.onItemSelectedToggle({
      id: this.props.id,
      isSelected: !this.props.isSelected,
    });
  };

  stopPropagation = (e) => {
    e.stopPropagation();
  };

  handleSaveField = (fieldMap) => {
    this.props.updateAwaitingProduct({
      id: this.props.id,
      params: { ...fieldMap },
    });
  }

  render() {
    const {
      name,
      code,
      getcare_product_industry_id,
      getcare_product_brand_id,
      attributes,
      unit,
      description,
      price,
      seller_sku,
      guarantee,
      size,
      packaging,
      isSelected,
    } = this.props;
    const brand = this.findBrandById(getcare_product_brand_id);
    const industry = this.findIndustryById(getcare_product_industry_id);

    return (
      <div className={`${classes.ProductItem} ${gridClasses.Row}`}>
        <div className={gridClasses.Col}>
          <Checkbox
            className={classes.Checkbox}
            checked={isSelected}
            onClick={this.stopPropagation}
            onChange={this.handleCheckboxChange}
          />
        </div>
        <div className={`${gridClasses.Col} ${classes.EditInlineCol}`}>
          <FieldEditInline
            fieldName="name"
            value={name || ''}
            onSave={this.handleSaveField}
          />
        </div>
        <div className={`${gridClasses.Col} ${classes.EditInlineCol}`}>
          <FieldEditInline
            fieldName="code"
            value={code || ''}
            onSave={this.handleSaveField}
          />
        </div>
        <div className={`${gridClasses.Col} ${classes.EditInlineCol}`}>
          <FieldEditInline
            fieldName="getcare_product_industry_id"
            value={getcare_product_industry_id || ''}
            displayedValue={industry?.name}
            fieldType="select"
            options={this.props.industries}
            onSave={this.handleSaveField}
          />
        </div>
        <div className={`${gridClasses.Col} ${classes.EditInlineCol}`}>
          <FieldEditInline
            fieldName="getcare_product_brand_id"
            value={getcare_product_brand_id || ''}
            displayedValue={brand?.name}
            fieldType="select"
            options={this.props.brands}
            onSave={this.handleSaveField}
          />
        </div>
        <div className={`${gridClasses.Col} ${classes.EditInlineCol}`}>
          <FieldEditInline
            fieldName="attributes"
            value={attributes || ''}
            onSave={this.handleSaveField}
          />
        </div>
        <div className={`${gridClasses.Col} ${classes.EditInlineCol}`}>
          <FieldEditInline
            fieldName="unit"
            value={unit || ''}
            onSave={this.handleSaveField}
          />
        </div>
        <div className={`${gridClasses.Col} ${classes.EditInlineCol}`}>
          <FieldEditInline
            fieldName="description"
            value={description || ''}
            saveField={this.handleSaveField}
          />
        </div>
        <div className={`${gridClasses.Col} ${classes.EditInlineCol}`}>
          <FieldEditInline
            fieldName="price"
            value={price || ''}
            displayedValue={currencyFormatVN(price)}
            type="number"
            onSave={this.handleSaveField}
          />
        </div>
        <div className={`${gridClasses.Col} ${classes.EditInlineCol}`}>
          <FieldEditInline
            fieldName="seller_sku"
            value={seller_sku || ''}
            onSave={this.handleSaveField}
          />
        </div>
        <div className={`${gridClasses.Col} ${classes.EditInlineCol}`}>
          <FieldEditInline
            fieldName="guarantee"
            value={guarantee || ''}
            onSave={this.handleSaveField}
          />
        </div>
        <div className={`${gridClasses.Col} ${classes.EditInlineCol}`}>
          <FieldEditInline
            fieldName="size"
            value={size || ''}
            onSave={this.handleSaveField}
          />
        </div>
        <div className={`${gridClasses.Col} ${classes.EditInlineCol}`}>
          <FieldEditInline
            fieldName="packaging"
            value={packaging || ''}
            onSave={this.handleSaveField}
          />
        </div>
      </div>
    );
  }
}

AwaitingProductListItem.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  code: PropTypes.string,
  getcare_product_industry_id: PropTypes.number,
  getcare_product_brand_id: PropTypes.number,
  attributes: PropTypes.string,
  unit: PropTypes.string,
  description: PropTypes.string,
  price: PropTypes.number,
  seller_sku: PropTypes.string,
  guarantee: PropTypes.string,
  size: PropTypes.string,
  packaging: PropTypes.string,
  brands: PropTypes.array,
  industries: PropTypes.array,
  isSelected: PropTypes.bool,
  onItemSelectedToggle: PropTypes.func,
};

AwaitingProductListItem.defaultProps = {
  brands: [],
  industries: [],
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateAwaitingProduct: (payload) => dispatch(updateAwaitingProduct(payload)),
  };
};
const withConnect = connect(null, mapDispatchToProps);
export default compose(withConnect)(AwaitingProductListItem);
