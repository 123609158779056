export function getAvailableRoutes(user, routes) {
  if (!user || !user.getcare_components || !user.getcare_components.length) return routes;
  return routes.filter(
    route => !route.userComponent || user.getcare_components.includes(route.userComponent)
  );
}

export function doPathsExist(paths, availableRoutes) {
  return paths.some(
    path => availableRoutes.some(route => route.path === path)
  );
}

export function isActionAllowed(actionCode, allowedActions) {
  if (!allowedActions || !allowedActions.length) return;
  return allowedActions.includes(actionCode);
}
