import coordinaterApi from 'utils/api/admin/coordinaterApi';
import { put } from 'redux-saga/effects';
import { coordinaterActions } from 'redux/actions';
import { toast } from 'react-toastify';
import { genID } from 'utils/helper';

export function* loadCoordinaterList(payload) {
  const { params } = payload.payload;
  try {
    yield put(coordinaterActions.saveLoading(true));

    const { data: response } = yield coordinaterApi.getList({ params });

    yield put(coordinaterActions.saveLoading(false));
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(
      coordinaterActions.saveCoordinaterList(
        response.data ? response.data.map(item => ({
          ...item,
          idStr: genID(),
        })) : []
      )
    );
    yield put(
      coordinaterActions.saveCoordinaterListTotal(response.total_records)
    );
  } catch (err) {
    console.log(err);
  }
}
export function* postCoordinater(payload) {
  const { params } = payload.payload;
  try {
    const { data: response } = yield coordinaterApi.updateCoordinater({ params });
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(coordinaterActions.saveCoordinaterItem({
      ...response.data,
      idStr: params.idStr,
    }));
  } catch (err) {
    console.log(err);
  }
}
export function* deleteCoordinaterItems(payload) {
  const { params } = payload.payload;
  try {
    yield put(coordinaterActions.saveDeleteLoading(true));

    const { data: response } = yield coordinaterApi.deleteCoordinaters({ params });

    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(coordinaterActions.saveDeleteLoading(false));
  } catch (err) {
    console.log(err);
  }
}
