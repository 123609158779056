export const GET_VENDOR_DETAILS = 'GET_VENDOR_DETAILS';
export const GET_VENDOR_LIST = 'GET_VENDOR_LIST';
export const UPDATE_VENDOR = 'UPDATE_VENDOR';

// mutation
export const SAVE_VENDOR_DETAILS = 'SAVE_VENDOR_DETAILS';
export const SAVE_VENDOR_LIST = 'SAVE_VENDOR_LIST';
export const SAVE_VENDOR_LIST_LOADING = 'SAVE_VENDOR_LIST_LOADING';
export const SAVE_VENDOR_LIST_TOTAL = 'SAVE_VENDOR_LIST_TOTAL';

export const EXPORT_VENDOR_LIST_TEMPLATE = 'EXPORT_VENDOR_LIST_TEMPLATE';
