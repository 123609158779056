export const productStatusMap = {
  '1': 'Đang hoạt động',
  '-1': 'Ngừng hoạt động',
};
export const listDisplayFields = [
  {
    name: 'getcare_id',
    label: 'Phahub ID',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'name',
    label: 'Tên thuốc',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'active',
    label: 'Tình trạng',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'getcare_uom_name',
    label: 'Quy cách đóng gói',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'getcare_uom_uom_base_name',
    label: 'Đơn vị cơ bản',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'getcare_uom_uom_1_name',
    label: 'Đơn vị bán lẻ',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'getcare_uom_uom_2_name',
    label: 'ĐV 2',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'getcare_uom_uom_3_name',
    label: 'ĐV 3',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'getcare_uom_uom_4_name',
    label: 'ĐV 4',
    sortable: true,
    sortDir: '',
  },
]

export const listParamsMap = {
  page_size: 50,
  page: 1,
  order: '',
  getcare_id: '',
  name: '',
  active: '',
  'getcare_uom_name': '',
  'getcare_uom_uom_base_name': '',
  'getcare_uom_uom_1_name': '',
  'getcare_uom_uom_2_name': '',
  'getcare_uom_uom_3_name': '',
  'getcare_uom_uom_4_name': '',
}

export const filterFields = [
  {
    name: 'getcare_id',
    label: 'Phahub ID',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'name',
    label: 'Tên thuốc',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'active',
    label: 'Tình trạng',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'getcare_uom_name',
    label: 'Quy cách đóng gói',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'getcare_uom_uom_base_name',
    label: 'Đơn vị cơ bản',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'getcare_uom_smallest_mou',
    label: 'Đơn vị bán lẻ',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
]

export const baseListDisplayFields = [
  {
    name: 'id',
    label: 'ID',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'name',
    label: 'Tên đầy đủ',
    sortable: true,
    sortDir: '',
  },
]

export const baseListParamsMap = {
  page_size: 50,
  page: 1,
  order: '',
}
