import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import customerApi from 'utils/api/admin/customerApi';
import { getFullAddressStr } from 'utils/helper';

import Checkbox from '@material-ui/core/Checkbox';
import EditOutlined from '@material-ui/icons/EditOutlined';
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import IconButton from '@material-ui/core/IconButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import gridClasses from '../GroupCustomersGrid.module.scss';
import classes from './GroupCustomersItem.module.scss';

class GroupCustomersItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: !props.id,
      codeInput: props.customer?.code || '',
      nameInput: props.customer?.name || '',
      selectedCustomer: props.customer
        ? {
            ...props.customer,
            phone: props.phone || '',
            address: props.address || '',
          }
        : null,
      customerOptions: props.customer
        ? [
            {
              ...props.customer,
              phone: props.phone || '',
              address: props.address || '',
            },
          ]
        : [],
    };
  }
  _resetState = () => {
    this.setState({
      isEditing: !this.props.id,
      codeInput: this.props.customer?.code || '',
      nameInput: this.props.customer?.name || '',
      selectedCustomer:
        {
          ...this.props.customer,
          phone: this.props.phone || '',
          address: this.props.address || '',
        } || null,
      customerOptions: this.props.customer
        ? [
            {
              ...this.props.customer,
              phone: this.props.phone || '',
              address: this.props.address || '',
            },
          ]
        : [],
    });
  };
  stopPropagation = (e) => {
    e.stopPropagation();
  };
  handleCheckboxChange = (e) => {
    e.stopPropagation();
    this.props.onItemSelectedToggle({
      item: { ...this.props },
      isSelected: !this.props.isSelected,
    });
  };
  componentDidUpdate(prevProps, prevState) {
    const { codeInput, nameInput } = this.state;
    if (codeInput && codeInput !== prevState.codeInput) {
      this.loadSuggestedCustomers({
        params: {
          code: codeInput,
        },
      });
    }
    if (nameInput && nameInput !== prevState.nameInput) {
      this.loadSuggestedCustomers({
        params: {
          name: nameInput,
        },
      });
    }
  }
  loadSuggestedCustomers = debounce(async ({ params }) => {
    const { data: response } = await customerApi.getAllCustomers({ params });
    if (!response?.result) return;
    this.setState({
      customerOptions: response.data
        ? this.filteredOptions([...response.data])
        : [],
    });
  }, 500);
  filteredOptions = (fullArr) => {
    return fullArr.filter((p) => {
      return (
        p.id === this.props.customer?.id ||
        !this.props.currentList.some((item) => item.customer?.id === p.id)
      );
    });
  };
  isRowValid = () => {
    return !!this.state.selectedCustomer;
  };
  handleEdit = () => {
    this.setState({
      isEditing: true,
    });
  };
  handleCancel = () => {
    this._resetState();
    if (!this.props.id) {
      this.props.removeGroupCustomer({ ...this.props });
    }
  };
  handleSave = () => {
    const { selectedCustomer } = this.state;
    if (!this.isRowValid()) return;
    this.props.saveGroupCustomer({
      groupCustomerId: this.props.id || null,
      params: {
        getcare_customer_id: Number(selectedCustomer.id),
        idStr: this.props.idStr || undefined,
      },
    });
    this.setState({
      isEditing: false,
    });
  };
  handleRowClick = () => {
    if (!this.state.isEditing) {
      this.handleEdit();
    }
  };
  handleRowBlur = () => {
    if (this.state.isEditing && this.isRowValid()) {
      this.handleSave();
    }
  };
  handleSelectedCustomerChange = (e, newValue) => {
    const {
      address,
      getcare_ward,
      getcare_district,
      getcare_province,
      getcare_country,
    } = newValue;
    this.setState({
      selectedCustomer: {
        ...newValue,
        address: getFullAddressStr(
          address,
          getcare_ward,
          getcare_district,
          getcare_province,
          getcare_country
        ),
      },
    });
  };

  render() {
    const {
      customer,
      phone,
      address,
      isSelected,
      isGroupInactive,
    } = this.props;
    const { isEditing, selectedCustomer, customerOptions } = this.state;

    return (
      <ClickAwayListener onClickAway={this.handleRowBlur}>
        <div
          className={`${classes.Item} ${gridClasses.Row}`}
          onClick={!isGroupInactive ? this.handleRowClick : null}
        >
          <div className={gridClasses.Col}>
            <Checkbox
              disabled={isGroupInactive}
              className={classes.Checkbox}
              checked={isSelected}
              onClick={this.stopPropagation}
              onChange={this.handleCheckboxChange}
            />
          </div>
          <div className={gridClasses.Col}>
            {isEditing ? (
              <Autocomplete
                openOnFocus
                selectOnFocus
                disableClearable
                size="small"
                handleHomeEndKeys={false}
                value={selectedCustomer || null}
                onChange={this.handleSelectedCustomerChange}
                options={customerOptions || []}
                filterOptions={(x) => x}
                renderOption={(option) => `${option.code} - ${option.name}`}
                getOptionLabel={(option) => (option && option.code) || ''}
                getOptionSelected={(option, value) =>
                  value && value.id && option ? option.id === value.id : null
                }
                onInputChange={(e, newInputValue) => {
                  this.setState({
                    codeInput: newInputValue,
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="- Chọn -" />
                )}
              />
            ) : (
              customer?.code
            )}
          </div>
          <div className={gridClasses.Col}>
            {isEditing ? (
              <Autocomplete
                openOnFocus
                selectOnFocus
                disableClearable
                size="small"
                handleHomeEndKeys={false}
                value={selectedCustomer || null}
                onChange={this.handleSelectedCustomerChange}
                options={customerOptions || []}
                filterOptions={(x) => x}
                renderOption={(option) => `${option.code} - ${option.name}`}
                getOptionLabel={(option) => (option && option.name) || ''}
                getOptionSelected={(option, value) =>
                  value && value.id && option ? option.id === value.id : null
                }
                onInputChange={(e, newInputValue) => {
                  this.setState({
                    nameInput: newInputValue,
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="- Chọn -" />
                )}
              />
            ) : (
              customer?.name
            )}
          </div>
          <div className={gridClasses.Col}>
            {isEditing ? selectedCustomer?.phone : phone}
          </div>
          <div className={gridClasses.Col}>
            {isEditing ? selectedCustomer?.address : address}
          </div>
          <div className={`${gridClasses.Col} ${classes.ActionsCol}`}>
            {isEditing ? (
              <>
                <IconButton
                  disabled={isGroupInactive}
                  onClick={this.handleCancel}
                  style={{ padding: '3px' }}
                  size="small"
                >
                  <CancelOutlined fontSize="small" color="secondary" />
                </IconButton>
                <IconButton
                  onClick={this.handleSave}
                  disabled={isGroupInactive || !this.isRowValid()}
                  style={{ padding: '3px' }}
                  size="small"
                >
                  <CheckCircleOutlineOutlined
                    fontSize="small"
                    color={this.isRowValid() ? `primary` : `inherit`}
                  />
                </IconButton>
              </>
            ) : (
              <>
                <IconButton
                  disabled={isGroupInactive}
                  onClick={this.handleEdit}
                  style={{ padding: '3px' }}
                  size="small"
                >
                  <EditOutlined fontSize="small" color="primary" />
                </IconButton>
              </>
            )}
          </div>
        </div>
      </ClickAwayListener>
    );
  }
}

GroupCustomersItem.propTypes = {
  isGroupInactive: PropTypes.bool,
  idStr: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  customer: PropTypes.object,
  address: PropTypes.string,
  phone: PropTypes.string,
  currentList: PropTypes.array,
  isSelected: PropTypes.bool,
  saveGroupCustomer: PropTypes.func,
  removeGroupCustomer: PropTypes.func,
  onItemSelectedToggle: PropTypes.func,
};

GroupCustomersItem.defaultProps = {
  currentList: [],
  isGroupInactive: false,
  isSelected: false,
};

export default GroupCustomersItem;
