import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { validDate } from 'utils/helper';
import { dateTimeFormat } from 'utils/constanst/dateConstants';
import { makeSelectShippingCouponHistory } from 'redux/selectors';

import classes from './ShippingCouponHistory.module.scss';

class ShippingCouponHistory extends PureComponent {
  render() {
    const { shippingCouponHistory } = this.props;

    return (
      <div className={`${classes.Wrap}`}>
        <div className={`${classes.Inner}`}>
          <ul className={classes.EventList}>
            {shippingCouponHistory.map((event, index) => (
              <li key={`event-${index}`} className={classes.EventItem}>
                <span className={classes.Bullet}></span>
                <div className={classes.EventDetails}>
                  <p className={classes.EventTime}>
                    {validDate(event.created_at)
                      ? format(validDate(event.created_at), dateTimeFormat)
                      : ''}
                  </p>
                  <p
                    className={classes.EventTitle}
                  >{`${event.user_name}: ${event.action}`}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

ShippingCouponHistory.propTypes = {
  shippingCouponId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ShippingCouponHistory.defaultProps = {
};

const mapStateToProps = createStructuredSelector({
  shippingCouponHistory: makeSelectShippingCouponHistory(),
});
const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(ShippingCouponHistory);
