import vendorProductApi from 'utils/api/admin/vendorProductApi';
import { put } from 'redux-saga/effects';
import { vendorProductActions } from 'redux/actions';
import { toast } from 'react-toastify';

export function* loadVendorProductList(payload) {
  const { params, vendorId } = payload.payload;
  try {
    yield put(vendorProductActions.saveLoading(true));
    yield put(vendorProductActions.saveVendorProductList([]));
    yield put(vendorProductActions.saveOriVendorProductList([]));
    yield put(vendorProductActions.saveVendorProductListTotal(0));

    const { data: response } = yield vendorProductApi.getVendorProductList({
      params,
      vendorId,
    });

    yield put(vendorProductActions.saveLoading(false));
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(
      vendorProductActions.saveVendorProductList(
        response.data ? [...response.data] : []
      )
    );
    yield put(
      vendorProductActions.saveOriVendorProductList(
        response.data ? [...response.data] : []
      )
    );
    yield put(
      vendorProductActions.saveVendorProductListTotal(response.total_records)
    );
  } catch (err) {
    console.log(err);
  }
}
export function* postVendorProductList(payload) {
  const { params, currentListParams } = payload.payload;
  try {
    yield put(vendorProductActions.saveActionLoading(true));

    const { data: response } = yield vendorProductApi.updateVendorProductList({
      params,
    });

    yield put(vendorProductActions.saveActionLoading(false));
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(vendorProductActions.getVendorProductList(currentListParams));
  } catch (err) {
    console.log(err);
  }
}

export function* approvalVendorProductItems(payload) {
  const params = {
    "ids":payload.payload.map(x => x.id)
  };
  try {
    yield put(vendorProductActions.saveLoading(true));

    const { data: response } = yield vendorProductApi.approvalVendorProductList({ params });
    if (!response?.result) {
      toast.error(response.message);
      return;
    }

    toast.success('Duyệt thành công');
    yield put(vendorProductActions.saveApproval(response.data));
    yield put(vendorProductActions.saveLoading(false));
    // window.location.reload();
  } catch (err) {
    console.log(err);
  }
}

export function* unmapVendorProductItems(payload) {
  const params = payload.payload;
  try {
    yield put(vendorProductActions.saveLoading(true));

    const { data: response } =
     yield vendorProductApi.unMapVendorProductList({ params });
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    toast.success('Unmap thành công');
    yield put(vendorProductActions.saveUnMap(response));
    yield put(vendorProductActions.saveActionLoading(false));
    // window.location.reload();
  } catch (err) {
    console.log(err);
  }
}



export function* exportVendorProductList(payload) {
  const { vendorId } = payload.payload;
  try {
    yield put(vendorProductActions.saveLoading(true));

    const { data: response } = yield vendorProductApi.exportVendorProducts({
      vendorId,
    });
    if (!response?.result) {
      toast.error(response.message);
      return;
    }

    const anchor = document.createElement('a');
    anchor.setAttribute('href', `${response.data ? response.data : ''}`);
    anchor.click();
    anchor.remove();

    yield put(vendorProductActions.saveLoading(false));
  } catch (err) {
    console.log(err);
  }
}
export function* exportVendorProductListTemplate(payload) {
  try {
    yield put(vendorProductActions.saveLoading(true));

    const {
      data: response,
    } = yield vendorProductApi.exportVendorProductsTemplate();
    if (!response?.result) {
      toast.error(response.message);
      return;
    }

    const anchor = document.createElement('a');
    anchor.setAttribute('href', `${response.data ? response.data : ''}`);
    anchor.click();
    anchor.remove();

    yield put(vendorProductActions.saveLoading(false));
  } catch (err) {
    console.log(err);
  }
}
