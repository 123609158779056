import React, { PureComponent } from 'react';
import storeApi from 'utils/api/admin/storeApi';
import { searchPharmaciesPageSize } from 'utils/constanst/adminPharmacyConstants';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  getProductBrands,
  getProductIndustries,
  getAwaitingProductsByPharmacyId,
} from 'redux/actions/admin/productActions';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectIndustryList,
  makeSelectBrandList,
  makeSelectAwaitingProductsTotal,
} from 'redux/selectors';

import { Tabs, Tab } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import MedicineList from 'components/admin/MedicineList/MedicineList';
import OtherProductList from 'components/admin/OtherProductList/OtherProductList';
import AwaitingProductList from 'components/admin/AwaitingProductList/AwaitingProductList';

import classes from './ConsumerGoods.module.scss';

class ConsumerGoods extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabActive: 0,
      selectedPharmacy: null,
      inputValue: '',
      isLoading: false,
      pharmacies: [],
      total: 0,
      page: 1,
    };
    this.optionsRefMap = {};
    this.optionsListRef = null;
  }

  fetchPharmacies = async () => {
    const payload = {
      params: {
        page_size: searchPharmaciesPageSize,
        page: this.state.page,
        name: this.state.inputValue.trim(),
      },
    };
    this.setState({
      isLoading: true,
    });

    const { data: response } = await storeApi.getAll(payload);
    if (!response?.result) {
      return;
    }

    this.setState({
      pharmacies:
        this.state.page === 1
          ? [...response.data]
          : [...this.state.pharmacies, ...response.data],
      isLoading: false,
      total: response.total_records,
    });
  };

  handleTabChange = (e, value) => {
    this.setState({
      tabActive: value,
    });
  };

  handleAutocompleteChanged = (event, newValue) => {
    this.setState({
      selectedPharmacy: newValue,
    });
  };

  handleQueryStringChanged = (event, newInputValue) => {
    this.setState({
      inputValue: newInputValue,
    });
  };

  handleLoadMore = () => {
    this.setState({
      page: this.state.page + 1,
    });
  };

  handleAutocompleteOpened = (e) => {
    const { inputValue, selectedPharmacy } = this.state;
    if (inputValue === '' && selectedPharmacy === null) {
      this.setState(
        {
          page: 1,
        },
        () => {
          this.fetchPharmacies();
        }
      );
    }
  };

  handleInputValueChange = debounce(() => {
    this.setState(
      {
        page: 1,
      },
      () => {
        this.fetchPharmacies();
      }
    );
  }, 500);

  componentDidMount() {
    this.props.getProductBrands({ params: {} });
    this.props.getProductIndustries({ params: {} });
  }

  componentDidUpdate(prevProps, prevState) {
    const { inputValue, page, pharmacies, selectedPharmacy, tabActive } = this.state;

    if (inputValue !== prevState.inputValue) {
      this.handleInputValueChange();
    }
    if (page !== prevState.page) {
      this.fetchPharmacies();
    }
    if (
      pharmacies !== prevState.pharmacies &&
      pharmacies.length > prevState.pharmacies.length &&
      page !== 1
    ) {
      const lastItemPrevPageIndex = (page - 1) * searchPharmaciesPageSize;
      const itemRef = this.optionsRefMap[pharmacies[lastItemPrevPageIndex].id];
      if (!itemRef) return;
      const listRef = this.optionsListRef;
      if (!listRef) return;
      listRef.scrollTo({
        top: itemRef.offsetTop - listRef.offsetTop,
      });
    }
    // load the awaiting list to get the total records -> need to improve
    if (
      selectedPharmacy &&
      (!prevState.selectedPharmacy ||
        selectedPharmacy.id !== prevState.selectedPharmacy.id)
      && tabActive !== 2
    ) {
      const payload = {
        params: {
          page_size: 1,
          page: 1,
        },
        pharmacyId: selectedPharmacy.id,
      };
      this.props.getAwaitingProductsByPharmacyId(payload);
    }
  }

  render() {
    const { tabActive, pharmacies, selectedPharmacy, isLoading } = this.state;
    const showLoadMore =
      this.state.page * searchPharmaciesPageSize < this.state.total;

    return (
      <React.Fragment>
        <div className={classes.PageWrap}>
          <div className={classes.PageHeader}>
            <h1 className={classes.PageTitle}>Danh sách hàng bán</h1>
          </div>
          <div className={classes.PageMain}>
            <div className={classes.SearchBar}>
              <label className={classes.Label}>Nhà thuốc</label>
              <Autocomplete
                openOnFocus
                className={classes.Autocomplete}
                getOptionLabel={(option) =>
                  typeof option === 'string'
                    ? option
                    : `${option.name} - ${option.address}`
                }
                filterOptions={(x) => x}
                options={pharmacies}
                autoComplete
                includeInputInList
                filterSelectedOptions
                size="small"
                handleHomeEndKeys={false}
                noOptionsText="Không có nhà thuốc nào"
                value={selectedPharmacy}
                onOpen={this.handleAutocompleteOpened}
                onChange={this.handleAutocompleteChanged}
                onInputChange={this.handleQueryStringChanged}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="- Chọn nhà thuốc -"
                    fullWidth
                  />
                )}
                renderOption={(option) => {
                  return (
                    <div
                      ref={(el) => (this.optionsRefMap[option.id] = el)}
                      className={classes.AutocompleteOption}
                    >
                      <label className={classes.OptionName}>
                        {option.name}
                      </label>
                      {option.address}
                    </div>
                  );
                }}
                ListboxComponent={React.forwardRef((props, ref) => {
                  const { children, ...other } = props;
                  return (
                    <div
                      role="listbox"
                      ref={ref}
                      {...other}
                      style={{
                        overflowY: 'hidden',
                        display: 'flex',
                      }}
                    >
                      <div
                        className={`${classes.AutoListWrap} ${
                          isLoading && 'OverlayLoading'
                        }`}
                        ref={(el) => (this.optionsListRef = el)}
                      >
                        {children}
                        {showLoadMore && (
                          <div className={classes.LoadMoreWrap}>
                            <Button
                              variant="contained"
                              onClick={this.handleLoadMore}
                            >
                              Show more
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              />
            </div>
            {selectedPharmacy?.id && (
              <>
                <Tabs
                  className={classes.TabsWrap}
                  value={tabActive}
                  onChange={this.handleTabChange}
                >
                  <Tab label="Danh sách sản phẩm dược" />
                  <Tab label="Danh sách sản phẩm khác" />
                  <Tab
                    label={
                      <label className={classes.AwaitingTabLabel}>
                        Danh sách sản phẩm khác chờ duyệt{' '}
                        <span className={`${classes.AwaitingNumber} Badge`}>{this.props.awaitingProductsTotal}</span>
                      </label>
                    }
                  />
                </Tabs>
                <div className={classes.TabsPanel}>
                  {tabActive === 0 && (
                    <MedicineList pharmacyId={selectedPharmacy.id} />
                  )}
                  {tabActive === 1 && (
                    <OtherProductList
                      pharmacyId={selectedPharmacy.id}
                      brands={this.props.brands}
                      industries={this.props.industries}
                    />
                  )}
                  {tabActive === 2 && (
                    <AwaitingProductList
                      pharmacyId={selectedPharmacy.id}
                      brands={this.props.brands}
                      industries={this.props.industries}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  brands: makeSelectBrandList(),
  industries: makeSelectIndustryList(),
  awaitingProductsTotal: makeSelectAwaitingProductsTotal(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getProductIndustries: (payload) => dispatch(getProductIndustries(payload)),
    getProductBrands: (payload) => dispatch(getProductBrands(payload)),
    getAwaitingProductsByPharmacyId: (payload) =>
      dispatch(getAwaitingProductsByPharmacyId(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(ConsumerGoods);
