import {
  GET_KEY_ACCOUNT_LIST,
  GET_KEY_ACCOUNT,
  UPDATE_KEY_ACCOUNT,
  GET_KEY_ACCOUNT_PRODUCTS,
  GET_KEY_ACCOUNT_HISTORY,

  SAVE_LOADING,
  SAVE_ACTION_LOADING,

  SAVE_KEY_ACCOUNT_LIST,
  SAVE_KEY_ACCOUNT_LIST_TOTAL,

  RESET_KEY_ACCOUNT,

  SAVE_ORI_KEY_ACCOUNT_DETAILS,
  SAVE_KEY_ACCOUNT_DETAILS,

  SAVE_ORI_KEY_ACCOUNT_PRODUCTS,
  SAVE_KEY_ACCOUNT_PRODUCTS,
  SAVE_KEY_ACCOUNT_PRODUCT_ITEM,

  SAVE_ORI_KEY_ACCOUNT_SALES_USERS,
  SAVE_KEY_ACCOUNT_SALES_USERS,
  SAVE_KEY_ACCOUNT_SALES_USER_ITEM,
  REMOVE_KEY_ACCOUNT_SALES_USER_ITEMS,

  SAVE_ORI_KEY_ACCOUNT_CUSTOMERS,
  SAVE_KEY_ACCOUNT_CUSTOMERS,
  SAVE_KEY_ACCOUNT_CUSTOMER_ITEM,
  REMOVE_KEY_ACCOUNT_CUSTOMER_ITEMS,

  SAVE_KEY_ACCOUNT_HISTORY,
} from 'redux/constants/admin/keyAccountConstants';

export function getKeyAccountList(payload) {
  return {
    type: GET_KEY_ACCOUNT_LIST,
    payload,
  };
}

export function getKeyAccount(id) {
  return {
    type: GET_KEY_ACCOUNT,
    id,
  };
}

export function updateKeyAccount(payload) {
  return {
    type: UPDATE_KEY_ACCOUNT,
    payload,
  };
}

export function getKeyAccountProducts(payload) {
  return {
    type: GET_KEY_ACCOUNT_PRODUCTS,
    payload,
  };
}

export function getKeyAccountHistory(payload) {
  return {
    type: GET_KEY_ACCOUNT_HISTORY,
    payload,
  };
}

// mutation
export function saveLoading(loading) {
  return {
    type: SAVE_LOADING,
    loading,
  };
}
export function saveActionLoading(actionLoading) {
  return {
    type: SAVE_ACTION_LOADING,
    actionLoading,
  };
}
export function saveKeyAccountList(keyAccountList) {
  return {
    type: SAVE_KEY_ACCOUNT_LIST,
    keyAccountList,
  };
}
export function saveKeyAccountListTotal(keyAccountListTotal) {
  return {
    type: SAVE_KEY_ACCOUNT_LIST_TOTAL,
    keyAccountListTotal,
  };
}

export function resetKeyAccount(keyAccountDetails) {
  return {
    type: RESET_KEY_ACCOUNT,
    keyAccountDetails,
  };
}

export function saveOriKeyAccountDetails(oriKeyAccountDetails) {
  return {
    type: SAVE_ORI_KEY_ACCOUNT_DETAILS,
    oriKeyAccountDetails,
  };
}
export function saveKeyAccountDetails(keyAccountDetails) {
  return {
    type: SAVE_KEY_ACCOUNT_DETAILS,
    keyAccountDetails,
  };
}

export function saveOriKeyAccountProducts(oriKeyAccountProducts) {
  return {
    type: SAVE_ORI_KEY_ACCOUNT_PRODUCTS,
    oriKeyAccountProducts,
  };
}
export function saveKeyAccountProducts(keyAccountProducts) {
  return {
    type: SAVE_KEY_ACCOUNT_PRODUCTS,
    keyAccountProducts,
  };
}
export function saveKeyAccountProductItem(keyAccountProductItem) {
  return {
    type: SAVE_KEY_ACCOUNT_PRODUCT_ITEM,
    keyAccountProductItem,
  };
}

export function saveOriKeyAccountSalesUsers(oriKeyAccountSalesUsers) {
  return {
    type: SAVE_ORI_KEY_ACCOUNT_SALES_USERS,
    oriKeyAccountSalesUsers,
  };
}
export function saveKeyAccountSalesUsers(keyAccountSalesUsers) {
  return {
    type: SAVE_KEY_ACCOUNT_SALES_USERS,
    keyAccountSalesUsers,
  };
}
export function saveKeyAccountSalesUserItem(keyAccountSalesUserItem) {
  return {
    type: SAVE_KEY_ACCOUNT_SALES_USER_ITEM,
    keyAccountSalesUserItem,
  };
}
export function removeKeyAccountSalesUserItems(keyAccountSalesUserItems) {
  return {
    type: REMOVE_KEY_ACCOUNT_SALES_USER_ITEMS,
    keyAccountSalesUserItems,
  };
}

export function saveOriKeyAccountCustomers(oriKeyAccountCustomers) {
  return {
    type: SAVE_ORI_KEY_ACCOUNT_CUSTOMERS,
    oriKeyAccountCustomers,
  };
}
export function saveKeyAccountCustomers(keyAccountCustomers) {
  return {
    type: SAVE_KEY_ACCOUNT_CUSTOMERS,
    keyAccountCustomers,
  };
}
export function saveKeyAccountCustomerItem(keyAccountCustomerItem) {
  return {
    type: SAVE_KEY_ACCOUNT_CUSTOMER_ITEM,
    keyAccountCustomerItem,
  };
}
export function removeKeyAccountCustomerItems(keyAccountCustomerItems) {
  return {
    type: REMOVE_KEY_ACCOUNT_CUSTOMER_ITEMS,
    keyAccountCustomerItems,
  };
}

export function saveKeyAccountHistory(keyAccountHistory) {
  return {
    type: SAVE_KEY_ACCOUNT_HISTORY,
    keyAccountHistory,
  };
}
