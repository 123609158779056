import salesTeamApi from 'utils/api/admin/salesTeamApi';
import { put } from 'redux-saga/effects';
import { salesTeamActions } from 'redux/actions';
import { toast } from 'react-toastify';

export function* loadSalesTeamList(payload) {
  const { params } = payload.payload;
  try {
    yield put(salesTeamActions.saveSalesTeamList(null));

    const { data: response } = yield salesTeamApi.getAll({ params });

    if (!response?.result) {
      yield put(salesTeamActions.saveSalesTeamList([]));
      yield put(salesTeamActions.saveSalesTeamListTotal(0));
      return;
    }
    yield put(
      salesTeamActions.saveSalesTeamList(
        response.data ? [...response.data] : []
      )
    );
    yield put(salesTeamActions.saveSalesTeamListTotal(response.total_records));
  } catch (err) {
    console.log(err);
  }
}
export function* loadSalesTeam(payload) {
  try {
    yield put(salesTeamActions.saveSalesTeamDetails(null));
    yield put(salesTeamActions.saveSalesTeamMembers([]));
    yield put(salesTeamActions.saveSalesTeamChannels([]));
    yield put(salesTeamActions.saveSalesTeamKeyAccounts([]));

    const { data: response } = yield salesTeamApi.get(payload.id);
    if (!response?.result) {
      yield put(salesTeamActions.saveSalesTeamDetails({}));
      return;
    }
    yield put(
      salesTeamActions.saveSalesTeamDetails(
        response.data
          ? {
              ...response.data,
              sales_team_list: undefined,
              sales_channel_team_list: undefined,
              key_accounts: undefined,
            }
          : {}
      )
    );
    yield put(
      salesTeamActions.saveSalesTeamMembers(
        response.data.sales_team_list ? [...response.data.sales_team_list] : []
      )
    );
    yield put(
      salesTeamActions.saveSalesTeamChannels(
        response.data.sales_channel_team_list
          ? [...response.data.sales_channel_team_list]
          : []
      )
    );
    yield put(
      salesTeamActions.saveSalesTeamKeyAccounts(
        response.data.key_accounts ? [...response.data.key_accounts] : []
      )
    );
  } catch (err) {
    console.log(err);
  }
}
export function* putNewSalesTeam(payload) {
  const { params } = payload.payload;
  try {
    const { data: response } = yield salesTeamApi.create({ params });
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    toast.success(`Lưu đội ngũ bán hàng thành công.`);
    yield put(
      salesTeamActions.saveSalesTeamDetails(
        response.data
          ? {
              ...response.data,
              sales_team_list: undefined,
              sales_channel_team_list: undefined,
              key_accounts: undefined,
            }
          : {}
      )
    );
  } catch (err) {
    console.log(err);
  }
}
export function* editSalesTeam(payload) {
  const { id, params } = payload.payload;
  try {
    const { data: response } = yield salesTeamApi.update({ id, params });
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    if (params.code) {
      // edit sales team info (not edit inline on its lists)
      yield put(
        salesTeamActions.saveSalesTeamDetails(
          response.data
            ? {
                ...response.data,
                sales_team_list: undefined,
                sales_channel_team_list: undefined,
                key_accounts: undefined,
              }
            : {}
        )
      );
    }
  } catch (err) {
    console.log(err);
  }
}
export function* loadSalesRoles(payload) {
  const { params } = payload.payload;
  try {
    yield put(salesTeamActions.saveSalesRoles(null));

    const { data: response } = yield salesTeamApi.getSalesRoles({ params });

    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(
      salesTeamActions.saveSalesRoles(response.data ? [...response.data] : [])
    );
  } catch (err) {
    console.log(err);
  }
}
