import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import ProductListItem from './ProductListItem/ProductListItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';

import gridClasses from './ProductListGrid.module.scss';
import classes from './ProductList.module.scss';

class ProductList extends PureComponent {
  isAllProductSelected = () => {
    const { productList, selectedIds } = this.props;
    return selectedIds.length > 0 && selectedIds.length >= productList.length;
  }

  isProductSelected = (id) => {
    return this.props.selectedIds.findIndex(item => item === id) > -1;
  }

  render() {
    const { productList, isLoading, displayFields, isEditAllowed } = this.props;

    return <div className={`${classes.List} ${isLoading && 'OverlayLoading'}`}>
      <div
        className={`${classes.Header}`}
        ref={(el) => (this.rowRef = el)}
      >
        <div className={`${gridClasses.Row} ${isEditAllowed ? gridClasses.HasCheckboxCol : ''} ${classes.Header}`}>
          { isEditAllowed && (
            <div className={gridClasses.Col}>
              <Checkbox
                className={classes.Checkbox}
                checked={this.isAllProductSelected()}
                onChange={this.props.onAllSelectedToggle}
              />
            </div>
          )}
          { displayFields.map((item) => (
            <ListHeaderCol
              key={item.name}
              className={gridClasses.Col}
              {...item}
              onSortChange={this.props.onSortChange}
            />
          )) }
        </div>
        </div>

      <div className={`${classes.Body}`}>
      
        { productList.length
          ? productList.map(item => (
            <ProductListItem
              key={`medicine-${item.id}`}
              {...item}
              isEditAllowed={isEditAllowed}
              isSelected={this.isProductSelected(item.id)}
              onItemSelectedToggle={this.props.onItemSelectedToggle}
            />))
          : (<p className="NoData">Không có sản phẩm nào</p>)
        }
      </div>
    </div>;
  }
}

ProductList.propTypes = {
  displayFields: PropTypes.array,
  productList: PropTypes.array,
  selectedIds: PropTypes.array,
  isLoading: PropTypes.bool,
  onSortChange: PropTypes.func,
  onAllSelectedToggle: PropTypes.func,
  onItemSelectedToggle: PropTypes.func,
};

ProductList.defaultProps = {
  displayFields: [],
  productList: [],
  isLoading: false,
  selectedIds: [],
};

export default ProductList;
