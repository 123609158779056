import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import VendorListItem from './VendorListItem/VendorListItem';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';

import gridClasses from './VendorListGrid.module.scss';
import classes from './VendorList.module.scss';

class VendorList extends PureComponent {
  render() {
    const { vendorList, isLoading, displayFields } = this.props;

    return <div className={`${classes.List} ${isLoading && 'OverlayLoading'}`}>
       <div
        className={`${classes.Header}`}
        ref={(el) => (this.rowRef = el)}
      >
            <div className={`${gridClasses.Row} ${classes.Header}`}>
          { displayFields.map((item) => (
            <ListHeaderCol
              key={item.name}
              className={gridClasses.Col}
              {...item}
              onSortChange={this.props.onSortChange}
              
            />
          )) }
        </div>
        </div>

      <div className={`${classes.Body}`}>  
        { (vendorList && vendorList.length)
          ? vendorList.map(item => (
            <VendorListItem
              key={`vendor-${item.id}`}
              {...item}
            />))
          : (<p className="NoData">Không có nhà cung cấp nào</p>)
        }
      </div>
    </div>;
  }
}

VendorList.propTypes = {
  displayFields: PropTypes.array,
  vendorList: PropTypes.array,
  isLoading: PropTypes.bool,
  onSortChange: PropTypes.func,
};

VendorList.defaultProps = {
  displayFields: [],
  vendorList: [],
  isLoading: false,
};

export default VendorList;
