import {
  SAVE_UOM_LIST,
  SAVE_UOM_BASE_LIST,
  SAVE_UOM_TOTAL,
  SAVE_UOM_BASE_TOTAL,
  SAVE_PRODUCT_UNIT_LIST,
} from 'redux/constants/admin/uomConstants';

const initialState = {
  uomList: null,
  uomBaseList: null,
  uomTotal: 0,
  uomBaseTotal: 0,
  productUnits: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_UOM_LIST: {
      const list = action.uomList;
      return {
        ...state,
        uomList: list ? [ ...list ] : null,
      };
    }
    case SAVE_UOM_BASE_LIST: {
      const baseList = action.uomBaseList;
      return {
        ...state,
        uomBaseList: baseList ? [ ...baseList ] : null,
      };
    }
    case SAVE_UOM_TOTAL: {
      return {
        ...state,
        uomTotal: action.uomTotal,
      };
    }
    case SAVE_UOM_BASE_TOTAL: {
      return {
        ...state,
        uomBaseTotal: action.uomBaseTotal,
      };
    }
    case SAVE_PRODUCT_UNIT_LIST: {
      const list = action.productUnits;
      return {
        ...state,
        productUnits: list ? [ ...list ] : null,
      };
    }
    default:
      return state;
  }
}

export default reducer;
