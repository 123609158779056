export const GET_VENDOR_PRICE_LIST = 'getcare/mdm/vendorPrice/GET_VENDOR_PRICE_LIST';
export const UPDATE_VENDOR_PRICE_LIST = 'getcare/mdm/vendorPrice/UPDATE_VENDOR_PRICE_LIST';

// mutation
export const SAVE_LOADING = 'getcare/mdm/vendorPrice/SAVE_LOADING';
export const SAVE_TEMP_VENDOR_PRICE_ITEM = 'getcare/mdm/vendorPrice/SAVE_TEMP_VENDOR_PRICE_ITEM';
export const REMOVE_TEMP_VENDOR_PRICE_ITEM = 'getcare/mdm/vendorPrice/REMOVE_TEMP_VENDOR_PRICE_ITEM';
export const SAVE_TEMP_VENDOR_PRICE_LIST = 'getcare/mdm/vendorPrice/SAVE_TEMP_VENDOR_PRICE_LIST';
export const SAVE_VENDOR_PRICE_LIST = 'getcare/mdm/vendorPrice/SAVE_VENDOR_PRICE_LIST';
export const SAVE_VENDOR_PRICE_LIST_TOTAL = 'getcare/mdm/vendorPrice/SAVE_VENDOR_PRICE_LIST_TOTAL';
export const SAVE_VENDOR_PRICE_ITEM = 'getcare/mdm/vendorPrice/SAVE_VENDOR_PRICE_ITEM';

export const SAVE_VENDOR_PRICE_ALLOWED_ACTIONS = 'getcare/mdm/vendorPrice/SAVE_VENDOR_PRICE_ALLOWED_ACTIONS';
export const SAVE_VENDOR_PRICE_VISIBILITY = 'getcare/mdm/vendorPrice/SAVE_VENDOR_PRICE_VISIBILITY';
