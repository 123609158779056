import {
  SAVE_LOADING,
  SAVE_ACTION_LOADING,

  SAVE_KEY_ACCOUNT_LIST,
  SAVE_KEY_ACCOUNT_LIST_TOTAL,

  RESET_KEY_ACCOUNT,

  SAVE_ORI_KEY_ACCOUNT_DETAILS,
  SAVE_KEY_ACCOUNT_DETAILS,

  SAVE_ORI_KEY_ACCOUNT_PRODUCTS,
  SAVE_KEY_ACCOUNT_PRODUCTS,
  SAVE_KEY_ACCOUNT_PRODUCT_ITEM,

  SAVE_ORI_KEY_ACCOUNT_SALES_USERS,
  SAVE_KEY_ACCOUNT_SALES_USERS,
  SAVE_KEY_ACCOUNT_SALES_USER_ITEM,
  REMOVE_KEY_ACCOUNT_SALES_USER_ITEMS,

  SAVE_ORI_KEY_ACCOUNT_CUSTOMERS,
  SAVE_KEY_ACCOUNT_CUSTOMERS,
  SAVE_KEY_ACCOUNT_CUSTOMER_ITEM,
  REMOVE_KEY_ACCOUNT_CUSTOMER_ITEMS,

  SAVE_KEY_ACCOUNT_HISTORY,
} from 'redux/constants/admin/keyAccountConstants';

const initialState = {
  keyAccountList: [],
  keyAccountListTotal: 0,

  loading: false,
  actionLoading: false,

  oriKeyAccountDetails: null,
  keyAccountDetails: null,

  oriKeyAccountProducts: [],
  keyAccountProducts: [],

  oriKeyAccountSalesUsers: [],
  keyAccountSalesUsers: [],

  oriKeyAccountCustomers: [],
  keyAccountCustomers: [],

  keyAccountHistory: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_LOADING: {
      return {
        ...state,
        loading: action.loading,
      };
    }
    case SAVE_ACTION_LOADING: {
      return {
        ...state,
        actionLoading: action.actionLoading,
      };
    }

    case SAVE_KEY_ACCOUNT_LIST: {
      const list = action.keyAccountList;
      return {
        ...state,
        keyAccountList: list ? [...list] : [],
      };
    }
    case SAVE_KEY_ACCOUNT_LIST_TOTAL: {
      return {
        ...state,
        keyAccountListTotal: action.keyAccountListTotal,
      };
    }

    case RESET_KEY_ACCOUNT: {
      return {
        ...state,
        oriKeyAccountDetails: action.keyAccountDetails || null,
        keyAccountDetails: action.keyAccountDetails || null,
        oriKeyAccountProducts: [],
        keyAccountProducts: [],
        oriKeyAccountSalesUsers: [],
        keyAccountSalesUsers: [],
        oriKeyAccountCustomers: [],
        keyAccountCustomers: [],
        keyAccountHistory: [],
      };
    }

    case SAVE_ORI_KEY_ACCOUNT_DETAILS: {
      return {
        ...state,
        oriKeyAccountDetails: action.oriKeyAccountDetails,
      };
    }
    case SAVE_KEY_ACCOUNT_DETAILS: {
      return {
        ...state,
        keyAccountDetails: action.keyAccountDetails,
      };
    }

    case SAVE_ORI_KEY_ACCOUNT_PRODUCTS: {
      return {
        ...state,
        oriKeyAccountProducts: action.oriKeyAccountProducts,
      };
    }
    case SAVE_KEY_ACCOUNT_PRODUCTS: {
      return {
        ...state,
        keyAccountProducts: action.keyAccountProducts,
      };
    }
    case SAVE_KEY_ACCOUNT_PRODUCT_ITEM: {
      const list = [...state.keyAccountProducts];
      const comparedField = action.keyAccountProductItem.idStr ? 'idStr' : 'id';
      const index = list.findIndex((p) => p[comparedField] === action.keyAccountProductItem[comparedField]);
      if (index > -1) {
        const tempItem = { ...list[index] };
        list[index] = { ...tempItem, ...action.keyAccountProductItem };
      } else {
        list.push({ ...action.keyAccountProductItem });
      }
      return {
        ...state,
        keyAccountProducts: list,
      };
    }

    case SAVE_ORI_KEY_ACCOUNT_SALES_USERS: {
      return {
        ...state,
        oriKeyAccountSalesUsers: action.oriKeyAccountSalesUsers,
      };
    }
    case SAVE_KEY_ACCOUNT_SALES_USERS: {
      return {
        ...state,
        keyAccountSalesUsers: action.keyAccountSalesUsers,
      };
    }
    case SAVE_KEY_ACCOUNT_SALES_USER_ITEM: {
      const list = [...state.keyAccountSalesUsers];
      const comparedField = action.keyAccountSalesUserItem.idStr ? 'idStr' : 'id';
      const index = list.findIndex((p) => p[comparedField] === action.keyAccountSalesUserItem[comparedField]);
      if (index > -1) {
        const tempItem = { ...list[index] };
        list[index] = { ...tempItem, ...action.keyAccountSalesUserItem };
      } else {
        list.push({ ...action.keyAccountSalesUserItem });
      }
      return {
        ...state,
        keyAccountSalesUsers: list,
      };
    }
    case REMOVE_KEY_ACCOUNT_SALES_USER_ITEMS: {
      const list = state.keyAccountSalesUsers.filter(
        (p) =>
          !action.keyAccountSalesUserItems.some((item) => {
            const comparedField = item.idStr ? 'idStr' : 'id';
            return item[comparedField] === p[comparedField];
          })
      );
      return {
        ...state,
        keyAccountSalesUsers: list,
      };
    }

    case SAVE_ORI_KEY_ACCOUNT_CUSTOMERS: {
      return {
        ...state,
        oriKeyAccountCustomers: action.oriKeyAccountCustomers,
      };
    }
    case SAVE_KEY_ACCOUNT_CUSTOMERS: {
      return {
        ...state,
        keyAccountCustomers: action.keyAccountCustomers,
      };
    }
    case SAVE_KEY_ACCOUNT_CUSTOMER_ITEM: {
      const list = [...state.keyAccountCustomers];
      const comparedField = action.keyAccountCustomerItem.idStr ? 'idStr' : 'id';
      const index = list.findIndex((p) => p[comparedField] === action.keyAccountCustomerItem[comparedField]);
      if (index > -1) {
        const tempItem = { ...list[index] };
        list[index] = { ...tempItem, ...action.keyAccountCustomerItem };
      } else {
        list.push({ ...action.keyAccountCustomerItem });
      }
      return {
        ...state,
        keyAccountCustomers: list,
      };
    }
    case REMOVE_KEY_ACCOUNT_CUSTOMER_ITEMS: {
      const list = state.keyAccountCustomers.filter(
        (p) =>
          !action.keyAccountCustomerItems.some((item) => {
            const comparedField = item.idStr ? 'idStr' : 'id';
            return item[comparedField] === p[comparedField];
          })
      );
      return {
        ...state,
        keyAccountCustomers: list,
      };
    }

    case SAVE_KEY_ACCOUNT_HISTORY: {
      return {
        ...state,
        keyAccountHistory: action.keyAccountHistory,
      };
    }

    default:
      return state;
  }
};

export default reducer;
