import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import UomBaseListItem from './UomBaseListItem/UomBaseListItem';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';

import gridClasses from './UomBaseListGrid.module.scss';
import classes from './UomBaseList.module.scss';

class UomBaseList extends PureComponent {
  render() {
    const { uomBaseList, isLoading, displayFields } = this.props;

    return <div className={`${classes.List} ${isLoading && 'OverlayLoading'}`}>
      <div
        className={`${classes.Header}`}
        ref={(el) => (this.rowRef = el)}
      >
            <div className={`${gridClasses.Row} ${classes.Header}`}>
          { displayFields.map((item, index) => (
            <ListHeaderCol
              key={`${item.name}-${index}`}
              className={gridClasses.Col}
              {...item}
              onSortChange={this.props.onSortChange}
            />
          )) }
        </div>
        </div>

      <div className={`${classes.Body}`}>
      
        { !isLoading && uomBaseList.length
          ? uomBaseList.map(item => (
            <UomBaseListItem
              key={`medicine-${item.id}`}
              {...item}
            />))
          : (<p className="NoData">Không có data</p>)
        }
      </div>
    </div>;
  }
}

UomBaseList.propTypes = {
  displayFields: PropTypes.array,
  uomBaseList: PropTypes.array,
  isLoading: PropTypes.bool,
  onSortChange: PropTypes.func,
};

UomBaseList.defaultProps = {
  displayFields: [],
  uomBaseList: [],
  isLoading: false,
};

export default UomBaseList;
