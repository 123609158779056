import axiosAuth from 'utils/axios/axiosAuth';

const assetBrandingApi = {
  getAssetBrandingList: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_asset_branding/`, {
      params: queryParams,
    });
  },
  createOrUpdateAssetBranding: ({ params }) => {
    return axiosAuth.post(`getcare_asset_branding`, params);
  },
  deleteAssetBrandingMultiple: ({ params }) => {
    return axiosAuth({
      url: "getcare_asset_branding_delete_multiple",
      method: "DELETE",
      data: params,
    });
  },
};

export default assetBrandingApi;
