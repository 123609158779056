import React, { PureComponent } from 'react';

import format from 'date-fns/format';
import { validDate } from 'utils/helper';
import { dateFormat } from 'utils/constanst/dateConstants';
import Checkbox from '@material-ui/core/Checkbox';
import gridClasses from '../KeyAccountLockListGrid.module.scss';
import classes from './KeyAccountLockListItem.module.scss';
import { Link } from 'react-router-dom';

class KeyAccountLockListItem extends PureComponent {

  handleCheckboxChange = (e) => {
    e.preventDefault();
    this.props.onItemSelectedToggle({
      id: this.props.id,
      isSelected: !this.props.isSelected,
    });
  };
  
  render() {
    const {
      getcare_sales,
      getcare_customer,
      getcare_vendor,
      getcare_product,
      start_date,
      left_count_days,
      isSelected,
    } = this.props;

    return <div className={`${classes.Item} ${gridClasses.Row}`}>
       <div className={gridClasses.Col}>
            <Checkbox
              className={classes.Checkbox}
              checked={isSelected}
              onClick={this.handleCheckboxChange}
            />
          </div>
      <div className={`${gridClasses.Col}`}>
        <p>{ getcare_sales?.name }</p>
        <p>{ getcare_sales?.code }</p>
      </div>
      <div className={`${gridClasses.Col}`}>
        <Link to={`/vendor/${getcare_vendor?.id }`} className="TextSecondary"> { getcare_vendor?.name }</Link>
      </div>
      <div className={`${gridClasses.Col}`}>
        { `${getcare_product?.name} - ${getcare_product?.getcare_id}` }
      </div>
      <div className={`${gridClasses.Col}`}>
        <p>{ getcare_customer?.name }</p>
        <p>{ getcare_customer?.code }</p>
      </div>
      <div className={`${gridClasses.Col}`}>
        { validDate(start_date) ? format(validDate(start_date), dateFormat) : '' }
      </div>
      <div className={`${gridClasses.Col} TextCenter`}>
        <span className="Badge">{ left_count_days }</span>
      </div>
    </div>;
  }
}

export default KeyAccountLockListItem;
