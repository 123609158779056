import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import gridClasses from '../SalesTeamKeyAccountsGrid.module.scss';
import classes from './SalesTeamKeyAccount.module.scss';

class SalesTeamKeyAccount extends PureComponent {
  render() {
    const {
      code,
      name,
      province_name,
      district_name,
      ward_name,
      address,
      sales_name,
    } = this.props;

    return (
      <div className={`${classes.ProductItem} ${gridClasses.Row}`}>
        <div className={gridClasses.Col}>{code}</div>
        <div className={gridClasses.Col}>{name}</div>
        <div className={gridClasses.Col}>{province_name}</div>
        <div className={gridClasses.Col}>{district_name}</div>
        <div className={gridClasses.Col}>{ward_name}</div>
        <div className={gridClasses.Col}>{address}</div>
        <div className={gridClasses.Col}>{sales_name}</div>
      </div>
    );
  }
}

SalesTeamKeyAccount.propTypes = {
  ID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  code: PropTypes.string,
  name: PropTypes.string,
  province_name: PropTypes.string,
  district_name: PropTypes.string,
  ward_name: PropTypes.string,
  address: PropTypes.string,
  sales_name: PropTypes.string,
};

SalesTeamKeyAccount.defaultProps = {};

export default SalesTeamKeyAccount;
