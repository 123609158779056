import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import {
  makeSelectShippingCouponRules,
  makeSelectOriShippingCouponRules,
} from 'redux/selectors';
import {
  saveShippingCouponRuleItem,
  saveShippingCouponRules,
} from 'redux/actions/admin/shippingCouponActions';
import { getDisplayFields, genID } from 'utils/helper';
import {
  shippingCouponRulesDisplayFields,
  shippingCouponRulesParamsMap,
  defaultParamsRule,
} from 'utils/constanst/adminShippingCouponConstants';

import ShippingCouponRule from './ShippingCouponRule/ShippingCouponRule';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';

import gridClasses from './ShippingCouponRulesGrid.module.scss';
import classes from './ShippingCouponRules.module.scss';

class ShippingCouponRules extends PureComponent {
  componentDidUpdate(prevProps) {
    const { shippingCouponRules, isEditing } = this.props;
    // set default rules if is creating
    if (!isEditing && !shippingCouponRules.length) {
      this.handleCreateNewRow({
        amount_gt: 0,
        amount_lt: null,
      });
    }
  }

  handleCreateNewRow = (params) => {
    this.props.saveShippingCouponRuleItem({
      ...defaultParamsRule,

      idStr: genID(),
      amount_gt: params.amount_gt,
      amount_lt: params.amount_lt,
    });
  };
  handleSaveShippingCouponRule = (params) => {
    this.props.saveShippingCouponRuleItem({
      ...params,
    });
  };
  handleSaveShippingCouponRuleAmountTo = ({ index, amount_lt }) => {
    const theItem = this.props.shippingCouponRules[index];
    try {
      this.props.saveShippingCouponRuleItem({
        ...theItem,
        amount_lt,
      });
    } catch(e) {};
  }
  handleInsertNewShippingCouponRule = ({ index, amount_gt, amount_lt }) => {
    const newRules = [...this.props.shippingCouponRules];
    newRules.splice(index, 0, {
      ...defaultParamsRule,

      idStr: genID(),
      amount_gt,
      amount_lt,
    });
    this.props.saveShippingCouponRules(newRules);
  }
  handleSaveNewShippingCouponRule = ({ amount_gt }) => {
    this.handleCreateNewRow({
      amount_gt,
      amount_lt: null,
    });
  }
  handleRemoveShippingCouponRule = (params) => {
    const { shippingCouponRules } = this.props;

    // remove the params
    // and update the (index - 1) item's amount_lt to params.amount_lt
    const newRules = shippingCouponRules.filter(item => {
      const comparedField = params.idStr ? 'idStr' : 'id';
      return item[comparedField] !== params[comparedField];
    }).map((item, index) => ({
      ...item,
      amount_lt: index !== params.index - 1 ? item.amount_lt : params.amount_lt,
    }));
    this.props.saveShippingCouponRules(newRules);
  };

  render() {
    const { shippingCouponRules } = this.props;
    const displayFields = getDisplayFields(shippingCouponRulesParamsMap, shippingCouponRulesDisplayFields);

    return (<div className={`${classes.Wrap}`}>
      <div className={`${classes.List}`}>
        <div className={`${classes.Body}`}>
          <div className={`${gridClasses.Row} ${classes.Header}`}>
            {displayFields.map((item) => (
              <ListHeaderCol
                key={item.name}
                {...item}
                className={`${gridClasses.Col} ${item.className}`}
              />
            ))}
            <div className={`${gridClasses.Col}`}></div>
          </div>

          {shippingCouponRules.length ? (
            shippingCouponRules.map((item, index) => (
              <ShippingCouponRule
                key={`coupon-rule-${item.id || item.idStr}`}
                {...item}
                index={index}
                currentList={[...shippingCouponRules]}
                saveShippingCouponRule={this.handleSaveShippingCouponRule}
                saveShippingCouponRuleAmountTo={this.handleSaveShippingCouponRuleAmountTo}
                saveNewShippingCouponRule={this.handleSaveNewShippingCouponRule}
                insertNewShippingCouponRule={this.handleInsertNewShippingCouponRule}
                removeShippingCouponRule={this.handleRemoveShippingCouponRule}
              />
            ))
          ) : (
            <p className="NoData">Không có data</p>
          )}
        </div>
      </div>
    </div>);
  }
}

ShippingCouponRules.propTypes = {
  isEditing: PropTypes.bool,
  onRemoveShippingCouponRuleItem: PropTypes.func,
  onSaveShippingCouponRuleItem: PropTypes.func,
};

ShippingCouponRules.defaultProps = {
  isEditing: false,
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveShippingCouponRuleItem: (payload) => dispatch(saveShippingCouponRuleItem(payload)),
    saveShippingCouponRules: (payload) => dispatch(saveShippingCouponRules(payload)),
  };
};
const mapStateToProps = createStructuredSelector({
  shippingCouponRules: makeSelectShippingCouponRules(),
  oriShippingCouponRules: makeSelectOriShippingCouponRules(),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(ShippingCouponRules);
