import axiosAuth from 'utils/axios/axiosAuth';

const vendorProductApi = {
  getVendorProductList: ({ params, vendorId }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (![null, undefined, ''].includes(params[key])) memo[key] = params[key].toString().trim();
      return memo;
    }, {});
    return axiosAuth.get(`getcare_vendor_product/`, {
      params: { ...queryParams, getcare_vendor_id: vendorId },
    });
  },

  getVendorSuggestions:({ params }) => {
    return axiosAuth.get(`getcare_vendor?name=${params.getcare_vendor_suggestion}`);
  },

  approvalVendorProductList: ({ params }) => {
    return axiosAuth.post(`getcare_vendor_product_action/approve_product`, params);
  },
  unMapVendorProductList: ({ params }) => {
    return axiosAuth.post(`update_getcare_vendor_product`, params);
  },
  updateVendorProductList: ({ params }) => {
    return axiosAuth.post(`update_getcare_vendor_product/`, params);
  },
  pushCategoryEcomForVendorProduct: ({ params }) => {
    return axiosAuth.post(`getcare_vendor_product_action/tag_category_ecom`, params);
  },
  exportVendorProductsTemplate: () => {
    return axiosAuth.post(`export/getcare_vendor_product_template/`);
  },
  exportVendorProducts: ({ vendorId }) => {
    return axiosAuth.post(`export/getcare_vendor_product/${vendorId}`);
  },
  exportVendorProductsLog: ({ importId }) => {
    return axiosAuth.post(`export/getcare_vendor_product/${importId}`);
  },
  importVendorProducts: ({ vendorId, params }) => {
    const newParams = { ...params, getcare_vendor_id: vendorId };
    const formData = new FormData();
    Object.keys(newParams).forEach((key) => {
      if (newParams[key]) formData.append(key, newParams[key]);
    });

    return axiosAuth.post(`import/getcare_vendor_product`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  confirmImportVendorProducts: ({ importId, params }) => {
    return axiosAuth.put(`getcare_vendor_product_import/${importId}`, params);
  },
};

export default vendorProductApi;
