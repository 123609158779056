import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { debounce } from 'lodash';
import { getUomList } from 'redux/actions/admin/uomActions';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectUomList,
  makeSelectUomTotal,
} from 'redux/selectors';
import {
  listParamsMap,
  listDisplayFields,
} from 'utils/constanst/adminUomConstants';

import UomList from 'components/admin/UomList/UomList';
import UomListFilters from 'components/admin/UomList/UomListFilters/UomListFilters';
import ListPagination from 'components/ListPagination/ListPagination';

import classes from './ProductUoms.module.scss';

class ProductUoms extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      listParams: { ...listParamsMap },
    };
  }

  componentDidMount() {
    this.props.getUomList({
      params: this.state.listParams,
    });
  }

  getSortsMap = (sortStr) => {
    const sortArr = sortStr.split(',');
    return sortArr.reduce((memo, item) => {
      const arr = item.split(' ');
      if (arr[0] && arr[0].trim()) {
        memo[arr[0]] = arr[1] ? arr[1] : '';
      }
      return memo;
    }, {});
  };

  getSortsString = (sortMap) => {
    return Object.keys(sortMap)
      .reduce((memo, key) => {
        memo.push(`${key} ${sortMap[key]}`);
        return memo;
      }, [])
      .join(',');
  };

  getDisplayFields = (listParams, displayFields) => {
    const sortsMap = this.getSortsMap(listParams.order);
    return displayFields.map((item) => ({
      ...item,
      sortDir: sortsMap[item.name],
    }));
  };

  handleFilterChange = (filters, forceResetPage: false) => {
    const paramsFromFilters = filters.reduce((memo, item) => {
      memo[item.name] = item.value;
      return memo;
    }, {});
    const newParams = forceResetPage ? {
      ...this.state.listParams,
      ...paramsFromFilters,
      page: 1,
    } : {
      ...this.state.listParams,
      ...paramsFromFilters,
    };
    this.setState({
      listParams: newParams,
    });
    this.props.getUomList({
      params: newParams,
    });
  };

  handleSortChange = ({ sortBy, sortDir }) => {
    const newSortString = this.getSortsString({
      [sortBy]: sortDir,
    });
    this.handleFilterChange([
      {
        name: 'order',
        value: newSortString,
      },
    ]);
  };

  render() {
    const { uomList, uomTotal } = this.props;
    const { listParams } = this.state;
    const isListLoading = !uomList;

    return (
      <React.Fragment>
        <div className={classes.PageWrap}>
          <div className={classes.PageHeader}>
            <h1 className={classes.PageTitle}>Danh sách sản phẩm theo đơn vị</h1>
          </div>
          <div className={classes.PageMain}>
            <UomListFilters
              onFilterChange={debounce(this.handleFilterChange, 500)}
            />
            <UomList
              displayFields={this.getDisplayFields(
                listParams,
                listDisplayFields
              )}
              uomList={uomList}
              isLoading={isListLoading}
              onSortChange={this.handleSortChange}
            />
          </div>
          <div className={classes.PageFooter}>
            <ListPagination
              page={listParams.page}
              pageSize={listParams.page_size}
              total={uomTotal}
              listName="sản phẩm"
              onFilterChange={this.handleFilterChange}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  uomList: makeSelectUomList(),
  uomTotal: makeSelectUomTotal(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getUomList: (payload) => dispatch(getUomList(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(ProductUoms);
