import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import ProductBonusListItem from './ProductBonusListItem/ProductBonusListItem';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';
import Button from '@material-ui/core/Button';

import gridClasses from './ProductBonusListGrid.module.scss';
import classes from './ProductBonusList.module.scss';

class ProductBonusList extends PureComponent {
  render() {
    const { productBonusList, isLoading, displayFields, productUnits } = this.props;

    return <div className={`${classes.List} ${isLoading && 'OverlayLoading'}`}>
      <div className={`${classes.Body}`}>
        <div className={`${gridClasses.Row} ${classes.Header}`}>
          { displayFields.map((item) => (
            <ListHeaderCol
              key={item.name}
              className={gridClasses.Col}
              {...item}
              onSortChange={this.props.onSortChange}
            />
          )) }
          <div className={`${gridClasses.Col} ${classes.ActionsCol}`}>
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={this.props.onAddNewRow}
            >Thêm dòng</Button>
          </div>
        </div>
      
        { (productBonusList && productBonusList.length)
          ? productBonusList.map(item => (
            <ProductBonusListItem
              key={`product-${item.id || item.idStr}`}
              {...item}
              productUnits={productUnits}
              saveProductBonus={this.props.onSaveProductBonus}
              removeProductBonuses={this.props.onRemoveProductBonuses}
            />))
          : (<p className="NoData">Không có hàng khuyến mãi nào</p>)
        }
      </div>
    </div>;
  }
}

ProductBonusList.propTypes = {
  displayFields: PropTypes.array,
  productBonusList: PropTypes.array,
  isLoading: PropTypes.bool,
  onSortChange: PropTypes.func,
  onSaveProductBonus: PropTypes.func,
  onRemoveProductBonuses: PropTypes.func,
  onAddNewRow: PropTypes.func,
};

ProductBonusList.defaultProps = {
  displayFields: [],
  productBonusList: [],
  isLoading: false,
};

export default ProductBonusList;
