import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { productStatusMap } from 'utils/constanst/adminUomConstants';

import gridClasses from '../UomListGrid.module.scss';
import classes from './UomListItem.module.scss';

class UomListItem extends PureComponent {
  getUOMs = () => {
    const uoms = [];
    const { getcare_uom } = this.props;
    Object.keys(getcare_uom).forEach(key => {
      if (key.match(/^uom_\d+$/g) && !!getcare_uom[key]?.id) {
        uoms.push({
          ...getcare_uom[key],
          number: getcare_uom[`${key}_number`],
        });
      }
    });
    // remove uom_1
    uoms.shift();
    return uoms;
  }
  getDisplayedUOMs = () => {
    // only show ĐVT 2, 3, 4
    return this.getUOMs().filter((item, index) => index < 5);
  }

  render() {
    const {
      getcare_id,
      name,
      active,
      getcare_uom,
    } = this.props;
    const uom_base = getcare_uom?.uom_base;
    const uom_1 = getcare_uom?.uom_1;
    const uoms = this.getUOMs();

    return <div
      className={`${classes.ProductItem} ${gridClasses.Row}`}
    >
      <div className={gridClasses.Col}>{ getcare_id }</div>
      <div className={gridClasses.Col}>{ name }</div>
      <div className={gridClasses.Col}>{ productStatusMap[active] }</div>
      <div className={gridClasses.Col}>{ getcare_uom?.name || '' }</div>
      <div className={gridClasses.Col}>{ uom_base?.name || '' }</div>
      <div className={gridClasses.Col}>{ uom_1?.name || '' }</div>
      { this.getDisplayedUOMs().map((uom, index) => (
        <div key={`uom-${index + 1}`} className={gridClasses.Col}>
          { uom.name || '' } { uom.number ? `(${uom.number} ${uoms[index + 1] ? uoms[index + 1].name : uom_1.name})` : `` }
        </div>
      )) }
    </div>;
  }
}

UomListItem.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  getcare_id: PropTypes.string,
  name: PropTypes.string,
  active: PropTypes.number,
  getcare_uom: PropTypes.object,
};

UomListItem.defaultProps = {
};

export default UomListItem;
