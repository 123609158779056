import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectPolicyPriceCustomers,
  makeSelectTempPolicyPriceCustomers,
} from 'redux/selectors';
import {
  saveTempPolicyPriceCustomerItem,
  removeTempPolicyPriceCustomerItems,
} from 'redux/actions/admin/policyPriceActions';
import { getDisplayFields, genID } from 'utils/helper';
import {
  policyPriceCustomersDisplayFields,
  policyPriceCustomersParamsMap,
  POLICY_PRICE_CUSTOMER_TYPE,
} from 'utils/constanst/adminPolicyPriceContants';
import { isEqual, sortBy } from 'lodash';

import PolicyPriceCustomer from './PolicyPriceCustomer/PolicyPriceCustomer';
import Checkbox from '@material-ui/core/Checkbox';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';
import Button from '@material-ui/core/Button';

import gridClasses from './PolicyPriceCustomersGrid.module.scss';
import classes from './PolicyPriceCustomers.module.scss';

class PolicyPriceCustomers extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedItems: [],
    };
  }
  componentDidUpdate(prevProps) {
    const { tempPolicyPriceCustomers } = this.props;
    const { selectedItems } = this.state;
    if (
      !isEqual(
        sortBy(tempPolicyPriceCustomers),
        sortBy(prevProps.tempPolicyPriceCustomers)
      ) &&
      selectedItems.length > 0
    ) {
      const remainItems = selectedItems.filter((p) =>
        tempPolicyPriceCustomers.some((item) => {
          const comparedField = item.idStr ? `idStr` : `id`;
          return item[comparedField] === p[comparedField];
        })
      );
      this.setState({
        selectedItems: remainItems,
      });
    }
  }
  _getChangedItem = (price) => {
    const { policyPriceCustomers } = this.props;
    const comparedField = price.idStr ? 'idStr' : 'id';
    const theItem = policyPriceCustomers.find(
      (item) => item[comparedField] === price[comparedField]
    );
    if (!theItem) return null;
    return !isEqual(theItem.getcare_erp_group, price.getcare_erp_group) ? {...theItem} : null;
  };
  _getHighLightList = () => {
    const { tempPolicyPriceCustomers, policyPriceCustomers } = this.props;
    if (!tempPolicyPriceCustomers.length || !policyPriceCustomers.length) {
      return [...tempPolicyPriceCustomers];
    }
    return tempPolicyPriceCustomers.map((price) => ({
      ...price,
      changedItem: this._getChangedItem(price),
    }));
  };
  isAllItemSelected = () => {
    const { tempPolicyPriceCustomers } = this.props;
    const { selectedItems } = this.state;
    return (
      selectedItems.length > 0 &&
      selectedItems.length >= tempPolicyPriceCustomers.length
    );
  };
  isItemSelected = (item) => {
    return (
      this.state.selectedItems.findIndex((p) => {
        const comparedField = p.idStr ? 'idStr' : 'id';
        return p[comparedField] === item[comparedField];
      }) > -1
    );
  };
  handleAllSelectedToggle = (e) => {
    this.setState({
      selectedItems: e.target.checked
        ? [...this.props.tempPolicyPriceCustomers]
        : [],
    });
  };
  handleItemSelectedToggle = ({ item, isSelected }) => {
    const remainItems = this.state.selectedItems.filter((p) => {
      const comparedField = p.idStr ? 'idStr' : 'id';
      return p[comparedField] !== item[comparedField];
    });
    this.setState({
      selectedItems: isSelected ? [...remainItems, { ...item }] : remainItems,
    });
  };
  handleCreateNewRow = () => {
    this.props.saveTempPolicyPriceCustomerItem({
      idStr: genID(),
      id: 0,
      type_id: POLICY_PRICE_CUSTOMER_TYPE,
      getcare_erp_group: null, // is not null if type_id = POLICY_PRICE_GROUP_CUSTOMER_TYPE
      getcare_customer: null, // // is not null if type_id = POLICY_PRICE_CUSTOMER_TYPE
    });
  };
  handleSavePolicyPriceCustomer = (params) => {
    this.props.saveTempPolicyPriceCustomerItem({
      ...params,
    });
  };
  handleRemovePolicyPriceCustomer = (params) => {
    this.props.removeTempPolicyPriceCustomerItems([{ ...params }]);
  };
  handleRemovePolicyPriceCustomers = () => {
    this.props.removeTempPolicyPriceCustomerItems([
      ...this.state.selectedItems,
    ]);
  };

  render() {
    const { tempPolicyPriceCustomers, readOnly } = this.props;
    const displayFields = getDisplayFields(
      policyPriceCustomersParamsMap,
      policyPriceCustomersDisplayFields
    );
    const list = this._getHighLightList();
    const hasSelected = this.state.selectedItems.length > 0;

    return (
      <div className={`${classes.Wrap}`}>
        <div className={classes.Toolbar}>
          <Button
            className={`${classes.MultiActionBtn}`}
            disabled={!hasSelected || readOnly}
            color="secondary"
            variant="contained"
            size="small"
            onClick={this.handleRemovePolicyPriceCustomers}
          >
            Xoá
          </Button>
        </div>
        <div className={`${classes.List}`}>
          <div className={`${classes.Body}`}>
            <div className={`${gridClasses.Row} ${classes.Header}`}>
              <div className={gridClasses.Col}>
                <Checkbox
                  disabled={readOnly}
                  className={classes.Checkbox}
                  checked={this.isAllItemSelected()}
                  onChange={this.handleAllSelectedToggle}
                />
              </div>
              {displayFields.map((item) => (
                <ListHeaderCol
                  key={item.name}
                  className={gridClasses.Col}
                  {...item}
                />
              ))}
              <div className={`${gridClasses.Col} ${classes.HeaderCol} ${classes.ActionsCol}`}>
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  disabled={readOnly}
                  onClick={this.handleCreateNewRow}
                >
                  Thêm dòng
                </Button>
              </div>
            </div>
            
            {list.length ? (
              list.map((item) => (
                <PolicyPriceCustomer
                  key={`price-customer-${item.id || item.idStr}`}
                  {...item}
                  readOnly={readOnly}
                  isSelected={this.isItemSelected(item)}
                  currentList={[...tempPolicyPriceCustomers]}
                  onItemSelectedToggle={this.handleItemSelectedToggle}
                  savePolicyPriceCustomer={this.handleSavePolicyPriceCustomer}
                  removePolicyPriceCustomer={this.handleRemovePolicyPriceCustomer}
                />
              ))
            ) : (
              <p className="NoData">Không có data</p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

PolicyPriceCustomers.propTypes = {
  policyPriceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  readOnly: PropTypes.bool,
};

PolicyPriceCustomers.defaultProps = {
  readOnly: false,
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveTempPolicyPriceCustomerItem: (payload) =>
      dispatch(saveTempPolicyPriceCustomerItem(payload)),
    removeTempPolicyPriceCustomerItems: (payload) =>
      dispatch(removeTempPolicyPriceCustomerItems(payload)),
  };
};
const mapStateToProps = createStructuredSelector({
  policyPriceCustomers: makeSelectPolicyPriceCustomers(),
  tempPolicyPriceCustomers: makeSelectTempPolicyPriceCustomers(),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(PolicyPriceCustomers);
