import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Radio from '@material-ui/core/Radio';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import DetailsPageHeader from 'components/DetailsPageHeader/DetailsPageHeader';
import shipperApi from 'utils/api/admin/shipperApi';
import { toast } from 'react-toastify';

import classes from './Shipper.module.scss';

const GENDERS = [
  {
    id: 'male',
    name: 'Nam',
  },
  {
    id: 'female',
    name: 'Nữ',
  },
  {
    id: 'other',
    name: 'Khác',
  },
]

class Shipper extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      hasChanges: false,
      shipper: null,
    }
  }

  async componentDidMount() {
    const { id } = this.props.match.params;
    const shipperId = Number(id);
    if (!isNaN(shipperId) && shipperId > 0) {
      try {
        const response = await shipperApi.getShipper(shipperId);
        if (response?.result && response?.data) {
          const shipper = response.data;
          this.setState({
            ...shipper,
            shipper,
          }, () => {
            // Reset the form with new values
            this.formRef.resetForm({
              values: this.getDefaultValues()
            });
          })
        } else if (response?.message) {
          toast.error(response.message);
        }
      } catch (err) {
        toast.error('Có lỗi xảy ra khi tải dữ liệu.');
      }
    }
  }

  backToList = () => {
    this.props.history.push('/shipper');
  }

  onSave = () => {
    if (this.formRef.isValid) {
      this.formRef.submitForm();
      this.setState({
        hasChanges: false
      })
    } else {
      toast.warning('Thông tin nhập sai quy định, vui lòng kiểm tra lại.');
    }
  }

  onDataChange = (field, value) => {
    this.setState({
      ...this.state,
      [field]: value,
      hasChanges: true,
    })
  }

  getDefaultValues = () => {
    const {
      name,
      code,
      phone,
      gender,
      status,
      address,
      shipper,
      other_name,
    } = this.state;

    // Use Active as default status in case of creating new
    return {
      status: shipper ? status : 1,
      name: name || '',
      code: code || '',
      phone: phone || '',
      gender: gender || '',
      address: address || '',
      other_name: other_name || '',
    }
  }

  getDataValidation = () => {
    return {
      code: Yup.string().required("Vui lòng nhập").max(55, 'Tối đa 55 ký tự'),
      name: Yup.string().required("Vui lòng nhập").max(255, 'Tối đa 255 ký tự'),
      phone: Yup.string().required("Vui lòng nhập").max(55, 'Tối đa 55 ký tự'),
      other_name: Yup.string().max(55, 'Tối đa 55 ký tự'),
      address: Yup.string().max(255, 'Tối đa 255 ký tự'),
    }
  }

  onShipperUpdate = async (id, data) => {
    try {
      const response = await shipperApi.updateShipper(id, data);
      if (response?.result && response?.data) {
        toast.success('Lưu nhân viên giao hàng thành công.');
      } else if (response?.message) {
        toast.error(response.message);
      }
    } catch (err) {
      toast.error('Có lỗi xảy ra khi xử lý dữ liệu.');
    }
  }

  onShipperCreate = async (data) => {
    try {
      const response = await shipperApi.createShipper(data);
      if (response?.result && response?.data) {
        toast.success('Thêm mới nhân viên giao hàng thành công.');
        const shipper = response.data;
        this.setState({
          ...shipper,
          shipper,
        }, () => {
          this.props.history.replace(`/shipper/${response.data.id}`);
        })
      } else if (response?.message) {
        toast.error(response.message);
      }
    } catch (err) {
      toast.error('Có lỗi xảy ra khi xử lý dữ liệu.');
    }
  }

  onSubmit = async (values) => {
    const { shipper } = this.state;

    values.phone = (/^(84)/).test(values.phone) ? values.phone.replace(/^(84)/, '+84') : values.phone;

    if (shipper) {
      let changes = {};
      // Collect any changes which different to original value
      Object.keys(values).forEach(field => {
        if (values[field] !== shipper[field]) {
          changes = {
            ...changes,
            [field]: values[field],
          }
        }
      })

      if (Object.keys(changes).length) {
        await this.onShipperUpdate(shipper.id, changes);
      }
    } else {
      // Submit all values made in the form
      await this.onShipperCreate(values);
    }
  }

  render() {
    const { hasChanges, shipper } = this.state;
    const saveLabel = shipper ? 'Lưu' : 'Thêm mới';
    return (
      <div className={classes.PageMain}>
        <div className={classes.PageMainHeader}>
          <DetailsPageHeader
            isLoading={false}
            title={'Nhân viên giao hàng'}
            backToList={this.backToList}
            onSave={this.onSave}
            hasChanges={hasChanges}
            saveLabel={saveLabel}
          />
        </div>
        <div className={classes.PageContent}>
          <Formik
            innerRef={(ref) => this.formRef = ref}
            initialValues={this.getDefaultValues()}
            onSubmit={this.onSubmit}
            validationSchema={Yup.object().shape(this.getDataValidation())}>
            {({ values, errors, handleChange }) => {
              const isActive = values.status === 1;
              return (
                <form noValidate autoComplete="off">
                  <div className={`${classes.Details}`}>
                    <div className={classes.DetailsCol}>
                      <div className={classes.FieldControl}>
                        <label>Mã nhân viên*</label>
                        <TextField
                          autoComplete="off"
                          value={values.code}
                          placeholder="Nhập..."
                          autoFocus={true}
                          name="code"
                          error={!!errors.code}
                          helperText={errors.code}
                          onChange={(e) => {
                            handleChange(e);
                            this.onDataChange('code', e.target.value);
                          }}
                        />
                      </div>
                      <div className={classes.FieldControl}>
                        <label>Tên nhân viên*</label>
                        <TextField
                          autoComplete="off"
                          value={values.name}
                          placeholder="Nhập..."
                          name="name"
                          error={!!errors.name}
                          helperText={errors.name}
                          onChange={(e) => {
                            handleChange(e);
                            this.onDataChange('name', e.target.value);
                          }}
                        />
                      </div>
                      <div className={classes.FieldControl}>
                        <label>Số điện thoại*</label>
                        <TextField
                          autoComplete="off"
                          value={values.phone}
                          placeholder="Nhập..."
                          name="phone"
                          error={!!errors.phone}
                          helperText={errors.phone}
                          onChange={(e) => {
                            handleChange(e);
                            this.onDataChange('phone', e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className={classes.DetailsCol}>
                      <div className={classes.FieldControl}>
                        <label>Tên thường gọi</label>
                        <TextField
                          autoComplete="off"
                          value={values.other_name}
                          placeholder="Nhập..."
                          name="other_name"
                          error={!!errors.other_name}
                          helperText={errors.other_name}
                          onChange={(e) => {
                            handleChange(e);
                            this.onDataChange('other_name', e.target.value);
                          }}
                        />
                      </div>
                      <div className={classes.FieldControl}>
                        <label>Giới tính</label>
                        <RadioGroup
                          name="gender"
                          value={values.gender}
                          className={`${classes.Field} ${classes.RadioGroup}`}
                          onChange={(e) => {
                            handleChange(e);
                            this.onDataChange('gender', e.target.value);
                          }}>
                          {GENDERS.map(gender => (
                            <FormControlLabel
                              key={gender.id}
                              value={gender.id}
                              control={<Radio size="small" style={{ padding: '4px 0', marginRight: '12px' }} />}
                              fontSize="small"
                              className={classes.FormControlLabel}
                              label={gender.name}
                            />
                          ))}
                        </RadioGroup>
                        {errors?.gender && <span className="TextDanger">{errors?.gender}</span>}
                      </div>
                      <div className={classes.FieldControl}>
                        <label>Địa chỉ</label>
                        <TextField
                          autoComplete="off"
                          value={values.address}
                          placeholder="Nhập..."
                          name="address"
                          error={!!errors.address}
                          helperText={errors.address}
                          onChange={(e) => {
                            handleChange(e);
                            this.onDataChange('address', e.target.value);
                          }}
                        />
                      </div>
                      <div className={classes.FieldControl}>
                        <label>Trạng thái</label>
                        <FormGroup>
                          <FormControlLabel
                            control={<Switch checked={isActive} onChange={(e) => {
                              handleChange(e);
                              this.onDataChange('status', isActive ? -1 : 1);
                            }}
                            />}
                            label={isActive ? 'Đang hoạt động' : 'Ngừng hoạt động'}
                          />
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                </form>
              )
            }}
          </Formik>
        </div>
      </div>
    );
  }
}

export default withRouter(Shipper);