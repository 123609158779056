import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getSalesChannelList } from 'redux/actions/admin/salesChannelActions';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectSalesChannelList,
  makeSelectSalesChannelListTotal,
} from 'redux/selectors';
import {
  listDisplayFields,
  listParamsMap,
} from 'utils/constanst/adminSalesChannelConstants';
import { getSortsString, getDisplayFields } from 'utils/helper';
import { withRouter } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';

import SalesChannelList from 'components/admin/SalesChannelList/SalesChannelList';
import ListPagination from 'components/ListPagination/ListPagination';

import classes from './SalesChannels.module.scss';

class SalesChannels extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      listParams: { ...listParamsMap },
    };
  }

  componentDidMount() {
    this.props.getSalesChannelList({
      params: this.state.listParams,
    });
  }

  handleFilterListChange = (filters) => {
    const paramsFromFilters = filters.reduce((memo, item) => {
      memo[item.name] = item.value;
      return memo;
    }, {});
    const newParams = {
      ...this.state.listParams,
      ...paramsFromFilters,
    };
    this.setState({
      listParams: newParams,
    });
    this.props.getSalesChannelList({
      params: newParams,
    });
  };

  handleSortListChange = ({ sortBy, sortDir }) => {
    const newSortString = getSortsString({
      [sortBy]: sortDir,
    });
    this.handleFilterListChange([
      {
        name: 'order',
        value: newSortString,
      },
    ]);
  };
  handleDeleteItemSuccess = () => {
    this.props.getSalesChannelList({
      params: this.state.listParams,
    });
  }

  createNewSalesChannel = () => {
    this.props.history.push(`/saleschannel/-1`);
  }

  render() {
    const { salesChannelList, salesChannelListTotal } = this.props;
    const { listParams } = this.state;
    const isListLoading = !salesChannelList;

    return (
      <React.Fragment>
        <div className={classes.PageWrap}>
          <div className={classes.PageHeader}>
            <h1 className={classes.PageTitle}>Danh sách khu vực bán hàng</h1>
            <Button
              size="small"
              variant="contained"
              color="primary"
              startIcon={<Add />}
              onClick={this.createNewSalesChannel}
            >Tạo mới</Button>
          </div>
          <div className={classes.PageMain}>
            <SalesChannelList
              displayFields={getDisplayFields(
                listParams,
                listDisplayFields
              )}
              salesChannelList={salesChannelList}
              isLoading={isListLoading}
              onSortChange={this.handleSortListChange}
              onDeleteItemSuccess={this.handleDeleteItemSuccess}
            />
          </div>
          <div className={classes.PageFooter}>
            <ListPagination
              page={listParams.page}
              pageSize={listParams.page_size}
              total={salesChannelListTotal}
              listName="khu vực bán hàng"
              onFilterChange={this.handleFilterListChange}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  salesChannelList: makeSelectSalesChannelList(),
  salesChannelListTotal: makeSelectSalesChannelListTotal(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getSalesChannelList: (payload) => dispatch(getSalesChannelList(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter)(SalesChannels);
