import axiosAuth from 'utils/axios/axiosAuth';

const priceTestApi = {
  getProductCustomerPrices: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (![null, undefined, ''].includes(params[key])) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_product_price/customer`, {
      params: queryParams,
    });
  },
  getProductPolicyPrices: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (![null, undefined, ''].includes(params[key])) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_product_price/policy`, {
      params: queryParams,
    });
  },
};

export default priceTestApi;
