export const GET_PRICE_PIORITIES = 'getcare/admin/policyPrice/GET_PRICE_PIORITIES';
export const GET_PRICE_UNITS = 'getcare/admin/policyPrice/GET_PRICE_UNITS';

export const GET_POLICY_PRICE_LIST = 'getcare/admin/policyPrice/GET_POLICY_PRICE_LIST';
export const GET_POLICY_PRICE = 'getcare/admin/policyPrice/GET_POLICY_PRICE';

export const UPDATE_POLICY_PRICE = 'getcare/admin/policyPrice/UPDATE_POLICY_PRICE';

export const GET_POLICY_PRICE_HISTORY = 'getcare/admin/policyPrice/GET_POLICY_PRICE_HISTORY';

export const EXPORT_POLICY_PRICE_TEMPLATE = 'getcare/admin/policyPrice/EXPORT_POLICY_PRICE_TEMPLATE';

export const GET_POLICY_PRICE_COUNTS = 'getcare/admin/policyPrice/GET_POLICY_PRICE_COUNTS';

// mutation
export const SAVE_PRICE_PIORITIES = 'getcare/admin/policyPrice/SAVE_PRICE_PIORITIES';
export const SAVE_PRICE_UNITS = 'getcare/admin/policyPrice/SAVE_PRICE_UNITS';

export const SAVE_LOADING = 'getcare/admin/policyPrice/SAVE_LOADING';
export const SAVE_POLICY_PRICE_LIST = 'getcare/admin/policyPrice/SAVE_POLICY_PRICE_LIST';
export const SAVE_POLICY_PRICE_ITEMS = 'getcare/admin/policyPrice/SAVE_POLICY_PRICE_ITEMS';
export const SAVE_POLICY_PRICE_LIST_TOTAL = 'getcare/admin/policyPrice/SAVE_POLICY_PRICE_LIST_TOTAL';

export const RESET_POLICY_PRICE = 'getcare/admin/policyPrice/RESET_POLICY_PRICE';

export const SAVE_ORIGINAL_POLICY_PRICE_DETAILS = 'getcare/admin/policyPrice/SAVE_ORIGINAL_POLICY_PRICE_DETAILS';
export const SAVE_POLICY_PRICE_DETAILS = 'getcare/admin/policyPrice/SAVE_POLICY_PRICE_DETAILS';

export const SAVE_POLICY_PRICE_PRODUCTS = 'getcare/admin/policyPrice/SAVE_POLICY_PRICE_PRODUCTS';
export const SAVE_TEMP_POLICY_PRICE_PRODUCTS = 'getcare/admin/policyPrice/SAVE_TEMP_POLICY_PRICE_PRODUCTS';
export const SAVE_TEMP_POLICY_PRICE_PRODUCT_ITEM = 'getcare/admin/policyPrice/SAVE_TEMP_POLICY_PRICE_PRODUCT_ITEM';
export const REMOVE_TEMP_POLICY_PRICE_PRODUCT_ITEMS = 'getcare/admin/policyPrice/REMOVE_TEMP_POLICY_PRICE_PRODUCT_ITEMS';

export const SAVE_POLICY_PRICE_CUSTOMERS = 'getcare/admin/policyPrice/SAVE_POLICY_PRICE_CUSTOMERS';
export const SAVE_TEMP_POLICY_PRICE_CUSTOMERS = 'getcare/admin/policyPrice/SAVE_TEMP_POLICY_PRICE_CUSTOMERS';
export const SAVE_TEMP_POLICY_PRICE_CUSTOMER_ITEM = 'getcare/admin/policyPrice/SAVE_TEMP_POLICY_PRICE_CUSTOMER_ITEM';
export const REMOVE_TEMP_POLICY_PRICE_CUSTOMER_ITEMS = 'getcare/admin/policyPrice/REMOVE_TEMP_POLICY_PRICE_CUSTOMER_ITEMS';

export const SAVE_POLICY_PRICE_CHANNELS = 'getcare/admin/policyPrice/SAVE_POLICY_PRICE_CHANNELS';
export const SAVE_TEMP_POLICY_PRICE_CHANNELS = 'getcare/admin/policyPrice/SAVE_TEMP_POLICY_PRICE_CHANNELS';
export const SAVE_TEMP_POLICY_PRICE_CHANNEL_ITEM = 'getcare/admin/policyPrice/SAVE_TEMP_POLICY_PRICE_CHANNEL_ITEM';
export const REMOVE_TEMP_POLICY_PRICE_CHANNEL_ITEMS = 'getcare/admin/policyPrice/REMOVE_TEMP_POLICY_PRICE_CHANNEL_ITEMS';
export const SAVE_TEMP_POLICY_PRICE_CHANNELS_COUNTS = 'getcare/admin/policyPrice/SAVE_TEMP_POLICY_PRICE_CHANNELS_COUNTS';

export const SAVE_POLICY_PRICE_CUSTOMER_TYPES = 'getcare/admin/policyPrice/SAVE_POLICY_PRICE_CUSTOMER_TYPES';
export const SAVE_TEMP_POLICY_PRICE_CUSTOMER_TYPES = 'getcare/admin/policyPrice/SAVE_TEMP_POLICY_PRICE_CUSTOMER_TYPES';

export const SAVE_POLICY_PRICE_HISTORY = 'getcare/admin/policyPrice/SAVE_POLICY_PRICE_HISTORY';
export const SAVE_POLICY_PRICE_COUNTS = 'getcare/admin/policyPrice/SAVE_POLICY_PRICE_COUNTS';
