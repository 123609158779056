export const listDisplayFields = [  
  {
    name: 'id',
    // authorize_name: 'id',
    label: 'Location ID',
    sortable: true,
    sortDir: 'desc',
  },
  {
    name: 'name',
    // authorize_name: 'id',
    label: 'Name',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'code',
    // authorize_name: 'id',
    label: 'Code',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'value',
    // authorize_name: 'id',
    label: 'Value',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'sample',
    // authorize_name: 'id',
    label: 'Sample',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'vendor_name',
    // authorize_name: 'id',
    label: 'Vendor',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'action',
    // authorize_name: 'id',
    label: '',
    sortable: false,
    sortDir: '',
  },
]
export const listParamsMap = {
  page_size: 50,
  page: 1,
  order: 'id desc'
}
export const filterFields = [
  {
    name: 'name',
    label: 'Name',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'code',
    label: 'Code',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'value',
    label: 'Value',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'getcare_vendor_id',
    label: 'Vendor',
    value: '',
    type: 'custom',
    placeholder: 'Nhập...',
  },
]

export const assetBrandingTypes = [{
  value: 1,
  label: 'Text'
},{
  value: 2,
  label: 'Color'
},{
  value: 3,
  label: 'Hình ảnh'
}]