import getcareCategoryApi from 'utils/api/admin/getcareCategoryApi';
import { put } from 'redux-saga/effects';
import { getcareCategoryActions } from 'redux/actions';
import { toast } from 'react-toastify';

export function* loadGetcareCategoryList(brand) {
  try {
    yield put(getcareCategoryActions.saveLoadingAction(true));
    yield put(getcareCategoryActions.saveStateGetcareCategoryList([]));

    const { data: response } = yield getcareCategoryApi.getAll({ brand: brand });

    yield put(getcareCategoryActions.saveLoadingAction(false));
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(
      getcareCategoryActions.saveStateGetcareCategoryList(
        response.data ? [...response.data] : []
      )
    );
  } catch (err) {
    console.log(err);
  }
}
