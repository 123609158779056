import React, { PureComponent } from 'react';
import { getDisplayFields, genID } from 'utils/helper';
import {
  memberListParamsMap,
  memberListDisplayFields,
  DEFAULT_MEMBER_ROLE_ID,
} from 'utils/constanst/adminSalesTeamConstants';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectSalesTeamMembers, makeSelectSalesRoles } from 'redux/selectors';
import {
  getSalesRoles,
  saveSalesTeamMember,
} from 'redux/actions/admin/salesTeamActions';
import PropTypes from 'prop-types';
import { suggestionPageSize } from 'utils/constanst/common';

import SalesTeamMember from './SalesTeamMember/SalesTeamMember';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';
import Button from '@material-ui/core/Button';

import gridClasses from './SalesTeamMembersGrid.module.scss';
import classes from './SalesTeamMembers.module.scss';

class SalesTeamMembers extends PureComponent {
  componentDidMount() {
    this.props.getSalesRoles({
      params: {
        page: 1,
        page_size: suggestionPageSize,
      },
    });
  }
  handleCreateNewRow = () => {
    this.props.saveSalesTeamMember({
      idStr: genID(),
      id: 0,
      getcare_sales: null,
      getcare_sales_role: this.getRoleById(DEFAULT_MEMBER_ROLE_ID),
    });
  };
  getRoleById = (roleId) => {
    return this.props.salesRoles.find(item => item.id === roleId);
  }

  render() {
    const { salesTeamMembers, salesRoles } = this.props;
    const isLoading = !salesTeamMembers;
    const displayFields = getDisplayFields({ ...memberListParamsMap }, [
      ...memberListDisplayFields,
    ]);

    return (
      <div className={classes.ListWrap}>
        <div className={`${classes.List} ${isLoading && 'OverlayLoading'}`}>
          <div className={`${classes.Body}`}>
            <div className={`${gridClasses.Row} ${classes.Header}`}>
              {displayFields.map((item, index) => (
                <ListHeaderCol
                  key={`${item.name}-${index}`}
                  className={`${gridClasses.Col} ${classes.HeaderCol}`}
                  {...item}
                />
              ))}
              <div
                className={`${gridClasses.Col} ${classes.HeaderCol} ${classes.ActionsCol}`}
              >
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  onClick={this.handleCreateNewRow}
                >
                  Thêm dòng
                </Button>
              </div>
            </div>
          
            {salesTeamMembers && salesTeamMembers.length ? (
              salesTeamMembers.map((item) => (
                <SalesTeamMember
                  key={`sale-${item.id || item.idStr}`}
                  {...item}
                  currentList={[...salesTeamMembers]}
                  salesRoles={salesRoles}
                  onSaveLists={this.props.onSaveLists}
                />
              ))
            ) : (
              <p className="NoData">Không có data</p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

SalesTeamMembers.propTypes = {
  onSaveLists: PropTypes.func,
};

SalesTeamMembers.defaultProps = {};

const mapStateToProps = createStructuredSelector({
  salesTeamMembers: makeSelectSalesTeamMembers(),
  salesRoles: makeSelectSalesRoles(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getSalesRoles: (payload) => dispatch(getSalesRoles(payload)),
    saveSalesTeamMember: (payload) => dispatch(saveSalesTeamMember(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(SalesTeamMembers);
