import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import VendorProductListItem from './VendorProductListItem/VendorProductListItem';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import ImageImportDialog from './ImageImportDialog/ImageImportDialog';
import DescriptionImportDialog from './DescriptionImportDialog/DescriptionImportDialog';
import uomApi from 'utils/api/admin/uomApi';

import gridClasses from './VendorProductListGrid.module.scss';
import classes from './VendorProductList.module.scss';

class VendorProductList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isImportImageDialogOpen: false,
      isImportDescription: false,
    }
    this.handleSaveFieldImages = null;
    this.handleSaveFieldDescription = null;
    this.rowRef = null;
  }

  componentDidMount() {
    this._loadSuggetionByVendor();
  }

  _loadSuggetionByVendor = async () => {
    const params = {
      page_size: 1000,
      page: 1,
    }
    const { data: response } = await uomApi.getProductVendor({params});
    this.setState({
      suggettionVendorList: response.data || [],
    });
  }

    
  isAllItemSelected = () => {
    const { vendorProductList, selectedItems } = this.props;
    return (
      selectedItems.length > 0 &&
      selectedItems.length >= vendorProductList.length
    );
  };
  isItemSelected = (item) => {
    return (
      this.props.selectedItems.findIndex((p) => {
        const comparedField = p.idStr ? 'idStr' : 'id';
        return p[comparedField] === item[comparedField];
      }) > -1
    );
  };
  getTemplateColumnsMap = () => {
    const { isEditAllowed, isMappingAllowed, displayFields } = this.props;

    if (!isEditAllowed && !isMappingAllowed) return {
      template: `repeat(${displayFields.length}, 9rem)`,
      width: `${displayFields.length * 9}rem`,
    };
    if (!isEditAllowed) return {
      template: `repeat(${displayFields.length}, 9rem) 7rem`,
      width: `${displayFields.length * 9 + 7}rem`,
    };

    return {
      template: `1.75rem repeat(${displayFields.length}, 9rem) 7.5rem`,
      width: `${displayFields.length * 9 + 10}rem`,
    };
  }
  handleCompleteImportImage = (urls) => {
    this.handleSaveFieldImages({ action: "ADD", urls })
  }

  handleCompleteImportDescription = (params) => {
    console.log(params);
    this.handleSaveFieldDescription({ action: "ADD", params })

  }

  handleImportImageDialogOpen = (handleSaveFieldImages) => {
    this.handleSaveFieldImages = handleSaveFieldImages;
    this.setState({
      isImportImageDialogOpen: true
    })
  }
  handleImportImageDialogClose = () => {
    this.setState({
      isImportImageDialogOpen: false
    })
  }

  getDescription = (getDescription) => {
    this.setState({
      dataDescription : getDescription  
    })
  }

  handleImportDescriptionDialogOpen = (handleSaveFieldDescription) => {
    this.handleSaveFieldDescription = handleSaveFieldDescription;
    this.setState({
      isImportDescriptionDialogOpen: true,
      
    })
  }


  handleImportDescriptionDialogClose = () => {
    this.setState({
      isImportDescriptionDialogOpen: false
    })
  }

  render() {
    const { vendorProductList, isLoading, displayFields, isEditAllowed, isMappingAllowed } = this.props;
    const { suggettionVendorList} = this.state;

    const templateColumnsMap = this.getTemplateColumnsMap();

    return <div
      className={`${classes.List}`}
      style={{
        overflowX: this.rowRef ? 'scroll' : '',
      }}
    >
      <div
        className={`${classes.Header}`}
        ref={(el) => (this.rowRef = el)}
        style={{width: templateColumnsMap.width}}
      >
        <div
          className={`${gridClasses.Row} ${classes.Header}`}
          style={{ gridTemplateColumns: templateColumnsMap.template }}
        >
          { isEditAllowed && (
            <div className={gridClasses.Col}>
              <Checkbox
                className={classes.Checkbox}
                checked={this.isAllItemSelected()}
                onChange={this.props.onAllSelectedToggle}
              />
            </div>
          )}
          { displayFields.map((item) => (
            <ListHeaderCol
              key={item.name}
              {...item}
              className={`${gridClasses.Col} ${item.className}`}
              onSortChange={this.props.onSortChange}
            />
          )) }
          { (isEditAllowed || isMappingAllowed) && (
            <div className={`${gridClasses.Col} ${classes.ActionsCol}`}>
              { isEditAllowed &&
                <Button
                  className={`${classes.MultiActionBtn}`}
                  color="primary"
                  variant="contained"
                  size="small"
                  onClick={this.props.onAddNewRow}
                >
                  Thêm dòng
                </Button>
              }
            </div>
          )}
        </div>
      </div>
      <div
        className={`${classes.Body} ${isLoading && 'OverlayLoading'}`}
        style={{width: (this.rowRef?.offsetWidth) || 'auto'}}
      >
        { (vendorProductList && vendorProductList.length)
          ? vendorProductList.map(item => (
            <VendorProductListItem
              key={`product-${item.id || item.idStr}`}
              {...item}
              isSelected={this.isItemSelected(item)}
              displayFields={[...displayFields]}
              isEditAllowed={isEditAllowed}
              suggettionVendorList={suggettionVendorList}
              isMappingAllowed={isMappingAllowed}
              templateColumnsMap={templateColumnsMap}
              onItemSelectedToggle={this.props.onItemSelectedToggle}
              saveVendorProduct={this.props.onSaveVendorProduct}
              removeVendorProducts={this.props.onRemoveVendorProducts}
              addImagesProducts={this.handleImportImageDialogOpen}
              addDescriptionProducts={this.handleImportDescriptionDialogOpen}
              getDescription={this.getDescription}
            />))
          : (<p className="NoData">Không có sản phẩm nào</p>)
        }
      </div>
      { this.state.isImportImageDialogOpen
        && <ImageImportDialog
          title="Tải hình ảnh sản phẩm"
          isOpen={this.state.isImportImageDialogOpen}
          onClose={this.handleImportImageDialogClose}
          onCompleteImport={this.handleCompleteImportImage}
        />
      }
       { this.state.isImportDescriptionDialogOpen
        && <DescriptionImportDialog
          title="Miêu tả sản phẩm"
          dataDescription={this.state.dataDescription}
          isOpen={this.state.isImportDescriptionDialogOpen}
          onClose={this.handleImportDescriptionDialogClose}
          onCompleteImportDescription={this.handleCompleteImportDescription}
        />
      }
    </div>;
  }
}

VendorProductList.propTypes = {
  displayFields: PropTypes.array,
  vendorProductList: PropTypes.array,
  selectedItems: PropTypes.array,
  isLoading: PropTypes.bool,
  onSortChange: PropTypes.func,
  onAllSelectedToggle: PropTypes.func,
  onItemSelectedToggle: PropTypes.func,
  onSaveVendorProduct: PropTypes.func,
  onRemoveVendorProducts: PropTypes.func,
  onAddNewRow: PropTypes.func,
};

VendorProductList.defaultProps = {
  displayFields: [],
  vendorProductList: [],
  isLoading: false,
  selectedItems: [],
};

export default VendorProductList;
