import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { isEqual, sortBy } from 'lodash';

import {
  makeSelectShippingCouponCustomers,
  makeSelectOriShippingCouponCustomers,
} from 'redux/selectors';
import {
  saveShippingCouponCustomerItem,
  removeShippingCouponCustomerItems,
} from 'redux/actions/admin/shippingCouponActions';
import { getDisplayFields, genID } from 'utils/helper';
import {
  shippingCouponCustomersDisplayFields,
  shippingCouponCustomersParamsMap,
} from 'utils/constanst/adminShippingCouponConstants';

import ShippingCouponCustomer from './ShippingCouponCustomer/ShippingCouponCustomer';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';

import gridClasses from './ShippingCouponCustomersGrid.module.scss';
import classes from './ShippingCouponCustomers.module.scss';

class ShippingCouponCustomers extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedItems: [],
    };
  }
  componentDidUpdate(prevProps) {
    const { shippingCouponCustomers } = this.props;
    const { selectedItems } = this.state;
    if (!isEqual(sortBy(shippingCouponCustomers), sortBy(prevProps.shippingCouponCustomers)) && selectedItems.length > 0) {
      const remainItems = selectedItems.filter((p) =>
        shippingCouponCustomers.some((item) => {
          const comparedField = item.idStr ? `idStr` : `id`;
          return item[comparedField] === p[comparedField];
        })
      );
      this.setState({
        selectedItems: remainItems,
      });
    }
  }

  isAllItemSelected = () => {
    const { shippingCouponCustomers } = this.props;
    const { selectedItems } = this.state;
    return (
      selectedItems.length > 0 &&
      selectedItems.length >= shippingCouponCustomers.length
    );
  };
  isItemSelected = (item) => {
    return (
      this.state.selectedItems.findIndex((p) => {
        const comparedField = p.idStr ? 'idStr' : 'id';
        return p[comparedField] === item[comparedField];
      }) > -1
    );
  };

  handleAllSelectedToggle = (e) => {
    this.setState({
      selectedItems: e.target.checked
        ? [...this.props.shippingCouponCustomers]
        : [],
    });
  };
  handleItemSelectedToggle = ({ item, isSelected }) => {
    const remainItems = this.state.selectedItems.filter((p) => {
      const comparedField = p.idStr ? 'idStr' : 'id';
      return p[comparedField] !== item[comparedField];
    });
    this.setState({
      selectedItems: isSelected ? [...remainItems, { ...item }] : remainItems,
    });
  };
  handleCreateNewRow = () => {
    this.props.saveShippingCouponCustomerItem({
      idStr: genID(),
      id: 0,
      getcare_erp_group: null,
      isEditing: true,
    });
  };
  handleSaveShippingCouponCustomer = (params) => {
    this.props.saveShippingCouponCustomerItem({
      ...params,
    });
  };
  handleRemoveShippingCouponCustomer = (params) => {
    this.props.removeShippingCouponCustomerItems([{ ...params }]);
  };
  handleRemoveShippingCouponCustomers = () => {
    this.props.removeShippingCouponCustomerItems([...this.state.selectedItems]);
  };

  render() {
    const { shippingCouponCustomers } = this.props;
    const displayFields = getDisplayFields(
      shippingCouponCustomersParamsMap,
      shippingCouponCustomersDisplayFields
    );
    const hasSelected = this.state.selectedItems.length > 0;

    return (
      <div className={`${classes.Wrap}`}>
        <div className={classes.Toolbar}>
          <Button
            className={`${classes.MultiActionBtn}`}
            disabled={!hasSelected}
            color="secondary"
            variant="contained"
            size="small"
            onClick={this.handleRemoveShippingCouponCustomers}
          >
            Xoá
          </Button>
        </div>
        <div className={`${classes.List}`}>
          <div className={`${classes.Body}`}>
            <div className={`${gridClasses.Row} ${classes.Header}`}>
              <div className={gridClasses.Col}>
                <Checkbox
                  className={classes.Checkbox}
                  checked={this.isAllItemSelected()}
                  onChange={this.handleAllSelectedToggle}
                />
              </div>
              {displayFields.map((item) => (
                <ListHeaderCol
                  key={item.name}
                  {...item}
                  className={gridClasses.Col}
                />
              ))}
              <div className={`${gridClasses.Col} ${classes.HeaderCol} ${classes.ActionsCol}`}>
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  onClick={this.handleCreateNewRow}
                >
                  Thêm dòng
                </Button>
              </div>
            </div>
          
            {shippingCouponCustomers.length ? (
              shippingCouponCustomers.map((item) => (
                <ShippingCouponCustomer
                  key={`shipping-coupon-customer-${item.id || item.idStr}`}
                  {...item}
                  isSelected={this.isItemSelected(item)}
                  currentList={[...shippingCouponCustomers]}
                  onItemSelectedToggle={this.handleItemSelectedToggle}
                  saveShippingCouponCustomer={this.handleSaveShippingCouponCustomer}
                  removeShippingCouponCustomer={this.handleRemoveShippingCouponCustomer}
                />
              ))
            ) : (
              <p className="NoData">Không có data</p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

ShippingCouponCustomers.propTypes = {
  shippingCouponId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isEditing: PropTypes.bool,
};

ShippingCouponCustomers.defaultProps = {
  isEditing: false,
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveShippingCouponCustomerItem: (payload) => dispatch(saveShippingCouponCustomerItem(payload)),
    removeShippingCouponCustomerItems: (payload) => dispatch(removeShippingCouponCustomerItems(payload)),
  };
};
const mapStateToProps = createStructuredSelector({
  shippingCouponCustomers: makeSelectShippingCouponCustomers(),
  oriShippingCouponCustomers: makeSelectOriShippingCouponCustomers(),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(ShippingCouponCustomers);
