import React, { PureComponent } from 'react';
import { testPriceOptions } from 'utils/constanst/adminTestPriceConstants';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CustomerPrices from 'components/admin/PolicyPriceTest/CustomerPrices/CustomerPrices';
import PolicyPrices from 'components/admin/PolicyPriceTest/PolicyPrices/PolicyPrices';

import classes from './PriceTest.module.scss';

class PriceTest extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedTest: null,
      testOptions: [...testPriceOptions],
    }
  }
  handleChangeTest = (e, newValue) => {
    this.setState({
      selectedTest: newValue,
    });
  }

  render() {
    const { selectedTest, testOptions } = this.state;

    return (<>
      <div className={classes.PageWrap}>
        <div className={classes.PageHeader}>
          <div className={classes.PageHeaderInner}>
            <h1 className={classes.PageTitle}>Test price</h1>
            <div className={classes.PageActions}>
              <Autocomplete
                openOnFocus
                fullWidth
                handleHomeEndKeys={false}
                value={selectedTest}
                onChange={this.handleChangeTest}
                options={testOptions}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) => option.id === value.id}
                renderInput={(params) => <TextField {...params} placeholder="- Chọn -" />}
              />
            </div>
          </div>
        </div>
        <div className={classes.PageMain}>
          { selectedTest && selectedTest.id === 1 && (<CustomerPrices/>)}
          { selectedTest && selectedTest.id === 2 && (<PolicyPrices/>)}
        </div>
      </div>
    </>);
  }
}

export default PriceTest;
