import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { validDate } from 'utils/helper';
import { format, isSameDay, isAfter } from 'date-fns';
import { dateFormat } from 'utils/constanst/dateConstants';
import { isEqual } from 'lodash';
import { toast } from 'react-toastify';
import * as QueryString from 'query-string';

import { DEACTIVE } from 'utils/constanst/common';
import { isAwaitingProgress } from 'utils/constanst/adminShippingCouponConstants';
import {
  makeSelectOriShippingCouponDetails,
  makeSelectShippingCouponDetails,
  makeSelectShippingCouponLoading,
  makeSelectOriShippingCouponRules,
  makeSelectShippingCouponRules,
  makeSelectOriShippingCouponCustomers,
  makeSelectShippingCouponCustomers,
  makeSelectOriShippingCouponSalesChannels,
  makeSelectShippingCouponSalesChannels,
} from 'redux/selectors';
import {
  getShippingCoupon,
  resetShippingCoupon,
  updateShippingCoupon,
  saveShippingCouponDetails,
} from 'redux/actions/admin/shippingCouponActions';

import Button from '@material-ui/core/Button';
import ArrowBack from '@material-ui/icons/ArrowBack';
import CheckOutlined from '@material-ui/icons/CheckOutlined';
import { Tabs, Tab } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import ShippingCouponDetails from 'components/admin/ShippingCouponDetails/ShippingCouponDetails';
import ShippingCouponRules from 'components/admin/ShippingCouponRules/ShippingCouponRules';
import ShippingCouponCustomers from 'components/admin/ShippingCouponCustomers/ShippingCouponCustomers';
import ShippingCouponSalesChannels from 'components/admin/ShippingCouponSalesChannels/ShippingCouponSalesChannels';
import ShippingCouponHistory from 'components/admin/ShippingCouponHistory/ShippingCouponHistory';
import ConfirmInactiveDialog from 'components/admin/ShippingCouponDetails/ConfirmInactiveDialog/ConfirmInactiveDialog';

import classes from './ShippingCoupon.module.scss';

class ShippingCoupon extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabActive: 'shipping-coupon-rule',
      isConfirmDialogOpen: false,
    }
    this.validate = {
      getcare_vendor_id: {
        required: true,
      },
      description: {
        maxLength: 255,
      },
      start_date: {
        required: true,
      },
    };
  }
  componentDidMount() {
    const { id } = this.props.match.params;
    this._loadData(id);
  }
  componentDidUpdate(prevProps, prevState) {
    const { id } = this.props.match.params;
    if (id !== prevProps.match.params.id) {
      this._loadData(id);
    }
    const { shippingCouponDetails } = this.props;
    if (shippingCouponDetails && shippingCouponDetails.id) {
      if (!prevProps.shippingCouponDetails || shippingCouponDetails.id !== prevProps.shippingCouponDetails.id) {
        if (
          !prevProps.shippingCouponDetails ||
          !prevProps.shippingCouponDetails.id ||
          isNaN(prevProps.shippingCouponDetails.id)
        ) {
          // created successfull
          this.props.history.push(`/shipping-coupon/${shippingCouponDetails.id}`);
          return;
        }
      }
    }
  }

  isVendorValid = () => {
    if (!this.props.shippingCouponDetails) return false;
    return !!this.props.shippingCouponDetails.getcare_vendor;
  }
  isStartDateValid = () => {
    const { shippingCouponDetails } = this.props;
    if (!shippingCouponDetails) return false;
    if (this._isEditing() && !isAwaitingProgress(shippingCouponDetails)) return true;
    const { start_date } = shippingCouponDetails;
    return !!start_date && (validDate(start_date) && (isSameDay(new Date(start_date), new Date()) || isAfter(new Date(start_date), new Date())));
  }
  isEndDateValid = () => {
    if (!this.props.shippingCouponDetails) return false;
    const { end_date, start_date } = this.props.shippingCouponDetails;
    return !end_date
    || (start_date && validDate(start_date)
        && validDate(end_date)
        && (isSameDay(new Date(end_date), new Date(start_date)) || isAfter(new Date(end_date), new Date(start_date)))
    );
  }
  hasRules = () => {
    const { shippingCouponRules } = this.props;
    return shippingCouponRules
      && shippingCouponRules.length > 0
      && shippingCouponRules.some(item => item.id || item.idStr);
  }
  hasCustomers = () => {
    const { shippingCouponCustomers } = this.props;
    return shippingCouponCustomers
      && shippingCouponCustomers.length > 0
      && shippingCouponCustomers.some(item => item.id || item.idStr);
  }
  hasSalesChannels = () => {
    const { shippingCouponSalesChannels } = this.props;
    return shippingCouponSalesChannels
      && shippingCouponSalesChannels.length > 0
      && shippingCouponSalesChannels.some(item => item.id || item.idStr);
  }
  _isValid = () => {
    return (
      this.isVendorValid() &&
      this.isStartDateValid() &&
      this.isEndDateValid() &&
      this.hasRules()
    );
  }

  _getShippingCouponDetailsContrains = (params) => {
    return {
      getcare_vendor_id: params.getcare_vendor?.id,
      description: params.description,
      active: params.active,

      startDate: validDate(params.start_date) ? format(validDate(params.start_date), dateFormat) : '',
      endDate: validDate(params.end_date) ? format(validDate(params.end_date), dateFormat) : '',
    }
  }
  _isDetailsChanged = () => {
    if (!this._isEditing()) return false;
    const { oriShippingCouponDetails, shippingCouponDetails } = this.props;
    if (!oriShippingCouponDetails || !shippingCouponDetails) return false;
    return !isEqual(this._getShippingCouponDetailsContrains(shippingCouponDetails), this._getShippingCouponDetailsContrains(oriShippingCouponDetails));
  }

  _getRuleContrains = (params) => {
    return {
      description: params.description,
      amount_gt: params.amount_gt,
      amount_lt: params.amount_lt,
      discount_amount: params.discount_amount,
      freeship: params.freeship,
    }
  }
  _isRulesChanged = () => {
    if (!this._isEditing()) return false;

    const { shippingCouponRules, oriShippingCouponRules } = this.props;
    if (!shippingCouponRules || !oriShippingCouponRules) return false;

    const hasDeletedItem = oriShippingCouponRules.some(item => item.id && !shippingCouponRules.some(p => p.id && p.id === item.id));
    if (hasDeletedItem) return true;

    return shippingCouponRules.some(tempItem => {
      const tempItemMap = this._getRuleContrains(tempItem);
      return !oriShippingCouponRules.some(item => isEqual(
        tempItemMap,
        this._getRuleContrains(item)
      ))
    });
  }

  _getCustomerContrains = (params) => {
    return {
      id: params.getcare_erp_group?.id,
    }
  }
  _isCustomersChanged = () => {
    if (!this._isEditing()) return false;

    const { shippingCouponCustomers, oriShippingCouponCustomers } = this.props;
    if (!shippingCouponCustomers || !oriShippingCouponCustomers) return false;

    const hasDeletedItem = oriShippingCouponCustomers.some(item => item.id && !shippingCouponCustomers.some(p => p.id && p.id === item.id));
    if (hasDeletedItem) return true;

    return shippingCouponCustomers.some(tempItem => {
      const tempItemMap = this._getCustomerContrains(tempItem);
      return !oriShippingCouponCustomers.some(item => isEqual(
        tempItemMap,
        this._getCustomerContrains(item)
      ))
    });
  }

  _getSalesChannelContrains = (params) => {
    return {
      id: params.getcare_sales_channel?.id,
    }
  }
  _isSalesChannelsChanged = () => {
    if (!this._isEditing()) return false;

    const { shippingCouponSalesChannels, oriShippingCouponSalesChannels } = this.props;
    if (!shippingCouponSalesChannels || !oriShippingCouponSalesChannels) return false;

    const hasDeletedItem = oriShippingCouponSalesChannels.some(item => item.id && !shippingCouponSalesChannels.some(p => p.id && p.id === item.id));
    if (hasDeletedItem) return true;

    return shippingCouponSalesChannels.some(tempItem => {
      const tempItemMap = this._getSalesChannelContrains(tempItem);
      return !oriShippingCouponSalesChannels.some(item => isEqual(
        tempItemMap,
        this._getSalesChannelContrains(item)
      ))
    });
  }

  _loadData = (id) => {
    if (this._isEditing()) {
      this.props.getShippingCoupon({
        id,
        isDuplicate: false,
      });
      return;
    }
    const duplicatedId = this._getDuplicatedId();
    if (duplicatedId && !isNaN(duplicatedId) && Number(duplicatedId) > 0) {
      this.props.getShippingCoupon({
        id: duplicatedId,
        isDuplicate: true,
      });

      return;
    }
    this.props.resetShippingCoupon();
  };

  _backToList = () => {
    this.props.history.push('/shipping-coupon');
  };
  _isEditing = () => {
    const { id } = this.props.match.params;
    return id && !isNaN(id) && Number(id) > 0;
  };
  _getDuplicatedId = () => {
    if (this._isEditing()) return null;
    const { location } = this.props;
    const params = QueryString.parse(location.search);
    return params.dup;
  }

  _isRulesNotCompleted = () => {
    return this.props.shippingCouponRules.some(item => item.isEditing);
  }
  _isCustomersNotCompleted = () => {
    return this.props.shippingCouponCustomers.some(item => item.isEditing);
  }
  _isSalesChannelsNotCompleted = () => {
    return this.props.shippingCouponSalesChannels.some(item => item.isEditing);
  }
  hasNotCompletedForm = () => {
    return this._isRulesNotCompleted()
      || this._isCustomersNotCompleted()
      || this._isSalesChannelsNotCompleted();
  }

  isRulesValid = () => {
    let messages = [];
    const { shippingCouponRules } = this.props;
    for(let i = 1; i < shippingCouponRules.length; i++) {
      if (shippingCouponRules[i].quantity_number <= shippingCouponRules[i - 1].quantity_number) {
        messages.push('• Số lượng mua level sau phải lớn hơn level trước');
        break;
      }
    }
    return messages;
  }

  hasWarningMessages = () => {
    if (this.hasNotCompletedForm()) {
      toast.error('Có một dòng bạn đang làm dang dở. Hãy hoàn thành để tiếp tục.');
      return true;
    }
    const messages = [
      ...this.isRulesValid(),
    ];
    if (messages.length) {
      toast.error(messages.join('\n'));
      return true;
    }
    return false;
  }

  _prepareData = () => {
    const isEditing = this._isEditing();

    const { shippingCouponDetails, shippingCouponRules, shippingCouponCustomers, shippingCouponSalesChannels } = this.props;

    return {
      id: shippingCouponDetails?.id || undefined,
      description: shippingCouponDetails?.description,

      getcare_vendor_id: shippingCouponDetails?.getcare_vendor?.id,
      start_date: !!shippingCouponDetails?.start_date ? shippingCouponDetails?.start_date : null,
      end_date: !!shippingCouponDetails?.end_date ? shippingCouponDetails?.end_date : null,

      active: !isEditing ? undefined : shippingCouponDetails.active,

      getcare_shipping_coupon_rules: shippingCouponRules.map((item, index) => ({
        id: isEditing && item.id ? item.id : undefined,
        level: index + 1,
        description: item.description,
        amount_gt: item.amount_gt,
        amount_lt: item.amount_lt,
        discount_amount: item.discount_amount,
        freeship: item.freeship,
      })),

      getcare_erp_groups: shippingCouponCustomers.map((item) => ({
        id: item.getcare_erp_group?.id,
      })),

      getcare_sales_channels: shippingCouponSalesChannels.map((item) => ({
        id: item.getcare_sales_channel?.id,
      })),
    };
  };

  handleTabChange = (e, value) => {
    this.setState({
      tabActive: value,
    });
  };

  handleCancelShippingCoupon = () => {
    this._loadData(this.props.match.params.id);
  }
  handleSubmitShippingCoupon = () => {
    const hasErrors = this.hasWarningMessages();
    if (hasErrors) return;
    this.props.updateShippingCoupon({
      params: {
        ...this._prepareData(),
      },
    });
  }
  handleDeactiveShippingCoupon = () => {
    if (this.hasWarningMessages()) return;
    this.handleOpenConfirmDialog();
  };
  handleSubmitConfirmUpdateStatus = ({ deactive_reason }) => {
    this.props.saveShippingCouponDetails({
      ...this.props.shippingCouponDetails,
      active: DEACTIVE,
      deactive_reason,
    });
    this.props.updateShippingCoupon({
      params: {
        ...this._prepareData(),
        active: DEACTIVE,
        deactive_reason,
      },
    });
    this.handleCloseConfirmDialog();
  };
  handleCloseConfirmDialog = () => {
    this.setState({
      isConfirmDialogOpen: false,
    });
  };
  handleOpenConfirmDialog = () => {
    this.setState({
      isConfirmDialogOpen: true,
    });
  };

  handleClickDuplicate = () => {
    this.props.history.push(`/shipping-coupon/new?dup=${this.props.shippingCouponDetails.id}`);
  }

  render() {
    const isEditing = this._isEditing();
    const isDetailsChanged = this._isDetailsChanged();
    const isRulesChanged = this._isRulesChanged();
    const isCustomersChanged = this._isCustomersChanged();
    const isSalesChannelsChanged = this._isSalesChannelsChanged();
    const isChanged = isDetailsChanged || isRulesChanged || isCustomersChanged || isSalesChannelsChanged;
    const isValid = this._isValid();

    return (
      <div
        className={`${classes.PageWrap} ${
          this.props.loading ? 'OverlayLoading' : ''
        }`}
      >
        <div className={classes.PageHeader}>
          <h1 className={classes.PageTitle}>
            {isEditing ? `Xem shipping coupon` : `Tạo mới shipping coupon`}
          </h1>

          {!isEditing && (
            <Button
              size="small"
              variant="outlined"
              startIcon={<ArrowBack />}
              onClick={this._backToList}
            >
              Huỷ và Trở về
            </Button>
          )}

          {isEditing && (
            <>
              <Button
                size="small"
                variant="outlined"
                startIcon={<ArrowBack />}
                onClick={this._backToList}
              >
                Trở về
              </Button>
              <Button
                size="small"
                variant="outlined"
                color="primary"
                startIcon={<FileCopyIcon />}
                onClick={this.handleClickDuplicate}
              >
                Duplicate
              </Button>
              <Button
                size="small"
                disabled={(!isEditing && !isValid) || (isEditing && (!isValid || !isChanged))}
                variant="outlined"
                color="secondary"
                onClick={this.handleCancelShippingCoupon}
              >
                Huỷ thay đổi
              </Button>
            </>
          )}

          <Button
            size="small"
            variant="contained"
            color="primary"
            disabled={(!isEditing && !isValid) || (isEditing && (!isValid || !isChanged))}
            startIcon={<CheckOutlined />}
            onClick={this.handleSubmitShippingCoupon}
          >
            {isEditing ? `Xác nhận thay đổi` : `Tạo shipping coupon`}
          </Button>
        </div>
        <div className={classes.PageMain}>
          <ShippingCouponDetails
            key={`policy-${this.props.shippingCouponDetails?.id || `new`}`}
            isEditing={isEditing}
            isValid={isValid}
            isVendorValid={this.isVendorValid()}
            isStartDateValid={this.isStartDateValid()}
            isEndDateValid={this.isEndDateValid()}
            onDeactiveShippingCoupon={this.handleDeactiveShippingCoupon}
          />
          <Tabs
            className={classes.TabsWrap}
            value={this.state.tabActive}
            onChange={this.handleTabChange}
          >
            <Tab value="shipping-coupon-rule" label={<label className={classes.TabLabel}>
              Cơ cấu{` `}
              { isRulesChanged ? <span className={classes.HighlightMark}>*</span> : '' }
            </label>} />
            <Tab value="shipping-coupon-customer" label={<label className={classes.TabLabel}>
              Nhóm khách hàng{` `}
            </label>} />
            <Tab value="shipping-coupon-sales-channel" label={<label className={classes.TabLabel}>
              Khu vực bán hàng{` `}
            </label>} />
            { isEditing && <Tab value="shipping-coupon-history" label={<label className={classes.TabLabel}>
              Lịch sử
            </label>} /> }
          </Tabs>
          <div className={classes.TabsPanel}>
            {this.state.tabActive === 'shipping-coupon-rule' && (
              <ShippingCouponRules
                isEditing={isEditing}
                shippingCouponId={this.props.shippingCouponDetails?.id}
              />
            )}
            {this.state.tabActive === 'shipping-coupon-customer' && (
              <ShippingCouponCustomers
                isEditing={isEditing}
                shippingCouponId={this.props.shippingCouponDetails?.id}
              />
            )}
            {this.state.tabActive === 'shipping-coupon-sales-channel' && (
              <ShippingCouponSalesChannels
                isEditing={isEditing}
                shippingCouponId={this.props.shippingCouponDetails?.id}
              />
            )}
            {this.state.tabActive === 'shipping-coupon-history' && (
              <ShippingCouponHistory shippingCouponId={this.props.shippingCouponDetails?.id} />
            )}
          </div>

        </div>
        {this.state.isConfirmDialogOpen && (
          <ConfirmInactiveDialog
            isOpen={this.state.isConfirmDialogOpen}
            title={`Xác nhận dừng hoạt động ${this.props.shippingCouponDetails?.code}`}
            shippingCouponCode={this.props.shippingCouponDetails?.code}
            onClose={this.handleCloseConfirmDialog}
            onSubmit={this.handleSubmitConfirmUpdateStatus}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  oriShippingCouponDetails: makeSelectOriShippingCouponDetails(),
  shippingCouponDetails: makeSelectShippingCouponDetails(),
  oriShippingCouponRules: makeSelectOriShippingCouponRules(),
  shippingCouponRules: makeSelectShippingCouponRules(),
  oriShippingCouponCustomers: makeSelectOriShippingCouponCustomers(),
  shippingCouponCustomers: makeSelectShippingCouponCustomers(),
  oriShippingCouponSalesChannels: makeSelectOriShippingCouponSalesChannels(),
  shippingCouponSalesChannels: makeSelectShippingCouponSalesChannels(),
  loading: makeSelectShippingCouponLoading(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getShippingCoupon: (payload) => dispatch(getShippingCoupon(payload)),
    resetShippingCoupon: (payload) => dispatch(resetShippingCoupon(payload)),
    updateShippingCoupon: (payload) => dispatch(updateShippingCoupon(payload)),
    saveShippingCouponDetails: (payload) => dispatch(saveShippingCouponDetails(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter)(ShippingCoupon);
