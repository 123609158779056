import axiosAuth from 'utils/axios/axiosAuth';

const userApi = {
  getList: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (![null, undefined, ''].includes(params[key])) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_user/`, {
      params: queryParams,
    });
  },
  getSalesUserList: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (![null, undefined, ''].includes(params[key])) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_key_account/`, {
      params: { ...queryParams, mode: 'sales' },
    });
  },
  get: (id) => {
    return axiosAuth.get(`getcare_user/${id}`);
  },

  create: ({ params }) => {
    return axiosAuth.post(`getcare_user`, params);
  },
  update: ({ params }) => {
    return axiosAuth.put(`getcare_user/${params.id}`, params);
  },

  getDepeartmentList: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (![null, undefined, ''].includes(params[key])) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_department/`, {
      params: queryParams,
    });
  },
  getRoleList: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (![null, undefined, ''].includes(params[key])) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_role/`, {
      params: queryParams,
    });
  },
  resetPassword: ({ params }) => {
    return axiosAuth.post(`user/reset_password`, params);
  }
};

export default userApi;
