import React, { PureComponent } from 'react';
import { currencyFormatVN } from 'utils/helper';
import { FREESHIP_TEXT } from 'utils/constanst/adminTestShippingCouponConstants';

import classes from './ShippingCouponAvailable.module.scss';
import gridClasses from '../ShippingCouponAvailablesGrid.module.scss';

class ShippingCouponAvailable extends PureComponent {
  render() {
    const {
      getcare_vendor,
      amount,
      shipping_coupon_code,
      shipping_coupon_amount,
      freeship,
    } = this.props;

    return (<>
      <div className={`${gridClasses.Row} ${classes.Item}`}>
        <div className={`${gridClasses.Col}`}>
          { getcare_vendor?.code }
        </div>
        <div className={`${gridClasses.Col}`}>
          { getcare_vendor?.name }
        </div>
        <div className={`${gridClasses.Col} TextRight`}>
          { ![null, undefined, ''].includes(amount) ? currencyFormatVN(amount) : `` }
        </div>
        <div className={`${gridClasses.Col}`}>
          { shipping_coupon_code }
        </div>
        <div className={`${gridClasses.Col} TextRight`}>
          { freeship ? FREESHIP_TEXT : ![null, undefined, ''].includes(shipping_coupon_amount) ? currencyFormatVN(shipping_coupon_amount) : `` }
        </div>
      </div>
    </>);
  }
}

export default ShippingCouponAvailable;
