import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectKeyAccountSalesUsers,
  makeSelectOriKeyAccountSalesUsers,
} from 'redux/selectors';
import {
  removeKeyAccountSalesUserItems,
  saveKeyAccountSalesUserItem,
} from 'redux/actions/admin/keyAccountActions';
import { getDisplayFields, genID } from 'utils/helper';
import {
  keyAccountSalesUsersDisplayFields,
  keyAccountSalesUsersParamsMap,
  defaultKeyAccountSalesUserParams,
} from 'utils/constanst/adminKeyAccountConstants';
import { SALES_USER_MODE_ALL, SALES_USER_MODE_OPTION, salesUserModesMap } from 'utils/constanst/adminKeyAccountConstants';

import KeyAccountSalesUserItem from './KeyAccountSalesUserItem/KeyAccountSalesUserItem';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import gridClasses from './KeyAccountSalesUserListGrid.module.scss';
import classes from 'views/admin/KeyAccount/KeyAccount.module.scss';

class KeyAccountSalesUsers extends PureComponent {
  handleCreateNewRow = (params) => {
    this.props.saveKeyAccountSalesUserItem({
      ...defaultKeyAccountSalesUserParams,
      idStr: genID(),
    });
  };
  handleSaveKeyAccountSalesUser = (params) => {
    this.props.saveKeyAccountSalesUserItem({
      ...params,
    });
  };
  handleRemoveKeyAccountSalesUser = (params) => {
    this.props.removeKeyAccountSalesUserItems([{...params}]);
  };

  render() {
    const {
      values,
      handleChange,
      keyAccountSalesUsers,
    } = this.props;

    const displayFields = getDisplayFields(keyAccountSalesUsersParamsMap, keyAccountSalesUsersDisplayFields);

    return (
      <div className={`${classes.SectionWrap}`}>
        <h4 className={classes.SectionTitle}>Trình dược viên</h4>

        <RadioGroup
          className={classes.RadioGroupWrap}
          name="sales_mode"
          value={values.sales_mode + ``}
          onChange={(e) => {
            handleChange(e);
            this.props.onFieldChange({'sales_mode': e.target.value});
          }}
        >
          <div className={`${classes.RadioGroupRow}`}>
            <FormControlLabel
              value={SALES_USER_MODE_ALL + ``}
              size="small"
              control={<Radio size="small" />}
              label={salesUserModesMap[SALES_USER_MODE_ALL]}
            />
          </div>
          <div className={`${classes.RadioGroupRow}`}>
            <FormControlLabel
              value={SALES_USER_MODE_OPTION + ``}
              size="small"
              control={<Radio size="small" />}
              label={salesUserModesMap[SALES_USER_MODE_OPTION]}
            />
          </div>
        </RadioGroup>

        <div className={`${classes.List} ${[SALES_USER_MODE_ALL, SALES_USER_MODE_ALL + ``].includes(values.sales_mode) ? classes.ListHidden : ''}`}>
          <div className={`${gridClasses.Row} ${classes.Header}`}>
            {displayFields.map((item) => (
              <ListHeaderCol
                key={item.name}
                {...item}
                className={`${gridClasses.Col} ${item.className}`}
              />
            ))}
            <div className={`${gridClasses.Col} ${classes.HeaderCol} TextCenter`}>
              <Button
                color="primary"
                variant="contained"
                size="small"
                onClick={this.handleCreateNewRow}
              >
                Thêm dòng
              </Button>
            </div>
          </div>
          <div className={`${classes.Body}`}>
            {keyAccountSalesUsers && keyAccountSalesUsers.length ? (<>
              { keyAccountSalesUsers.map((item, index) => (
                <KeyAccountSalesUserItem
                  key={`user-${item.idStr || item.id}`}
                  {...item}
                  index={index}
                  currentList={[...keyAccountSalesUsers]}
                  onSaveKeyAccountSalesUserItem={this.handleSaveKeyAccountSalesUser}
                  onRemoveKeyAccountSalesUserItem={this.handleRemoveKeyAccountSalesUser}
                />
              )) }
            </>) : (
              <p className="NoData">Không có data</p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

KeyAccountSalesUsers.propTypes = {
  isEditing: PropTypes.bool,
  onFieldChange: PropTypes.func,
};

KeyAccountSalesUsers.defaultProps = {
  isEditing: false,
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveKeyAccountSalesUserItem: (payload) => dispatch(saveKeyAccountSalesUserItem(payload)),
    removeKeyAccountSalesUserItems: (payload) => dispatch(removeKeyAccountSalesUserItems(payload)),
  };
};
const mapStateToProps = createStructuredSelector({
  keyAccountSalesUsers: makeSelectKeyAccountSalesUsers(),
  oriKeyAccountSalesUsers: makeSelectOriKeyAccountSalesUsers(),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(KeyAccountSalesUsers);
