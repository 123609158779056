import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import assetComponentApi from 'utils/api/admin/assetComponentApi';
import {

} from 'utils/constanst/adminGetcareCategoryConstants';

import { toast } from 'react-toastify';
import {
  DialogActions,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core/';
import AssetComponentFormDialogForm from './Form/AssetComponentFormDialogForm';

class AssetComponentFormDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    }
    this.innerFormRef = null;
  }

  onClose = () => {
    this.props.onClose();
  };

  handleSubmitForm = async (params) => {
    const { action } = this.props;
    this.setState({
      loading: true,
    });
    const { data: response } = await assetComponentApi.createOrUpdateAssetComponent({
      params: {
        ...params,
        id: action === 'CREATE' ? undefined : params.id,
      }
    });
    if (!response.result) {
      toast.error(response.message);
      this.setState({ loading: false });
      return;
    }
    this.setState({
      loading: false,
    }, () => {
      toast.success(`Đã ${action === 'CREATE' ? 'tạo mới' : 'cập nhật'} thành công!`);
      this.props.onClose();
      this.props.onSubmitSuccess();
    });
  }
  handleSave = () => {
    this.innerFormRef.submitForm();
  }
  
  render() {
    const { open, action, data } = this.props;
    const { loading } = this.state;

    return (
      <Dialog
        open={open}
        onClose={this.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        disableBackdropClick
        disableEscapeKeyDown={loading}
        classes={{ paper: `${loading && 'OverlayLoading'}` }}
      >
        <DialogTitle>{ action === 'UPDATE' ? `Cập nhật ${data.name}` : 'Tạo mới asset branding'}</DialogTitle>
        <DialogContent dividers>
            <AssetComponentFormDialogForm
              formRef={ref => {
                this.innerFormRef = ref;
              }}
              data={data}
              onSubmitForm={this.handleSubmitForm}
            />
        </DialogContent>
        <DialogActions>
          <Button type="button" variant="outlined" onClick={this.props.onClose}>
            Huỷ bỏ
          </Button>
          <Button
            type="button"
            variant="contained"
            onClick={this.handleSave}
            color="primary"
          >
            Lưu
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

AssetComponentFormDialog.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.object,
  action: PropTypes.oneOf(['CREATE', 'UPDATE']),
  onClose: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
}

AssetComponentFormDialog.defaultProps = {
  open: false,
  action: 'CREATE',
}

export default AssetComponentFormDialog;
