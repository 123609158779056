import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import customerApi from 'utils/api/admin/customerApi';

import { getFullAddressStr } from 'utils/helper';
import { dateFormat } from 'utils/constanst/dateConstants';
import { validDate } from 'utils/helper';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import classes from './CustomerAndDateSelector.module.scss';

class CustomerAndDateSelector extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      queryInput: '',
      autocompleteValue: null,
      customerOptions: [],
    }
  }
  _resetState = () => {
    this.setState({
      queryInput: '',
      autocompleteValue: null,
      customerOptions: [],
    });
  }
  componentDidUpdate(prevProps, prevState) {
    const { queryInput } = this.state;
    if (queryInput && queryInput !== prevState.queryInput) {
      this._loadSuggestedOptions({
        params: {
          keyword: queryInput,
        },
      });
    }
  }
  _loadSuggestedOptions = debounce(async ({ params }) => {
    const { data: response } = await customerApi.getAllCustomers({ params });
    if (!response?.result) return;
    this.setState({
      customerOptions: response.data || [],
    });
  }, 500);
  handleSelectedItemChange = (e, newValue) => {
    this._resetState();
    this.props.onChangeCustomer(newValue);
  }
  handleDateChange = (newValue) => {
    this.props.onChangeOrderDate(newValue);
  }

  render() {
    const { customerOptions, queryInput, autocompleteValue } = this.state;
    const { selectedCustomer, orderDate } = this.props;
    const fullAddress = selectedCustomer
      ? getFullAddressStr(selectedCustomer.address, selectedCustomer.getcare_ward, selectedCustomer.getcare_district, selectedCustomer.getcare_province, selectedCustomer.getcare_country)
      : '';

    return (<>
      <div className={classes.ControlWrap}>
        <Autocomplete
          fullWidth
          disableClearable
          value={autocompleteValue}
          inputValue={queryInput}
          onChange={this.handleSelectedItemChange}
          options={customerOptions}
          filterOptions={(x) => x}
          renderOption={(option) => `${option.code} - ${option.name}`}
          getOptionLabel={(option) => `${option.code} - ${option.name}`}
          getOptionSelected={(option, value) =>
            value && value.id && option ? option.id === value.id : null
          }
          onInputChange={(e, newInputValue) => {
            this.setState({
              queryInput: newInputValue,
            });
          }}
          renderInput={(params) => (
            <TextField {...params} placeholder="- Chọn khách hàng -" />
          )}
        />
        <div className={`${classes.DateControlWrap}`}>
          <label>Ngày đơn hàng</label>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              fullWidth
              disableToolbar
              autoOk
              variant="inline"
              format={dateFormat}
              value={validDate(orderDate) ? validDate(orderDate) : ''}
              onChange={this.handleDateChange}
              placeholder="Chọn hoặc nhập ngày đơn hàng"
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
      </div>
      { selectedCustomer && (
        <div className={`${classes.List}`}>
          <div className={`${classes.Row} ${classes.Header}`}>
            <ListHeaderCol className={classes.Col} label="Mã khách hàng"/>
            <ListHeaderCol className={classes.Col} label="Tên khách hàng"/>
            <ListHeaderCol className={classes.Col} label="Số điện thoại"/>
            <ListHeaderCol className={classes.Col} label="Địa chỉ"/>
          </div>
          <div className={`${classes.Body}`}>
            <div className={`${classes.Row} ${classes.Item}`}>
              <div className={classes.Col}>{ selectedCustomer.code }</div>
              <div className={classes.Col}>{ selectedCustomer.name }</div>
              <div className={classes.Col}>{ selectedCustomer.phone }</div>
              <div className={classes.Col}>{ fullAddress }</div>
            </div>
          </div>
        </div>
      )}

    </>);
  }
}

CustomerAndDateSelector.propTypes = {
  selectedCustomer: PropTypes.object,
  onChangeCustomer: PropTypes.func,
};

CustomerAndDateSelector.defaultProps = {
  selectedCustomer: null,
};

export default CustomerAndDateSelector;
