export const GET_COORDINATER_LIST = 'getcare/admin/coordinater/GET_COORDINATER_LIST';
export const UPDATE_COORDINATER_ITEM = 'getcare/admin/coordinater/UPDATE_COORDINATER_ITEM';
export const DELETE_COORDINATER_ITEMS = 'getcare/admin/coordinater/DELETE_COORDINATER_ITEMS';

// mutation
export const SAVE_TOTAL = 'getcare/admin/coordinater/SAVE_TOTAL';
export const SAVE_LOADING = 'getcare/admin/coordinater/SAVE_LOADING';
export const SAVE_DELETE_LOADING = 'getcare/admin/coordinater/SAVE_DELETE_LOADING';

export const SAVE_COORDINATER_LIST = 'getcare/admin/coordinater/SAVE_COORDINATER_LIST';
export const SAVE_COORDINATER_ITEM = 'getcare/admin/coordinater/SAVE_COORDINATER_ITEM';
export const REMOVE_COORDINATER_ITEMS = 'getcare/admin/coordinater/REMOVE_COORDINATER_ITEMS';

export const SAVE_COORDINATER_ALLOWED_ACTIONS = 'getcare/mdm/coordinater/SAVE_COORDINATER_ALLOWED_ACTIONS';
