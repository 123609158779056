export const GET_VENDOR_PRODUCT_LIST = 'getcare/mdm/vendorProduct/GET_VENDOR_PRODUCT_LIST';
export const UPDATE_VENDOR_PRODUCT_LIST = 'getcare/mdm/vendorProduct/UPDATE_VENDOR_PRODUCT_LIST';
export const EXPORT_VENDOR_PRODUCT_LIST = 'getcare/mdm/vendorProduct/EXPORT_VENDOR_PRODUCT_LIST';
export const EXPORT_VENDOR_PRODUCT_LIST_TEMPLATE = 'getcare/mdm/vendorProduct/EXPORT_VENDOR_PRODUCT_LIST_TEMPLATE';

// mutation
export const SAVE_VENDOR_PRODUCT_LIST_TOTAL = 'getcare/mdm/vendorProduct/SAVE_VENDOR_PRODUCT_LIST_TOTAL';
export const SAVE_LOADING = 'getcare/mdm/vendorProduct/SAVE_LOADING';
export const SAVE_UNMAP = 'getcare/mdm/vendorProduct/SAVE_UNMAP';
export const SAVE_APPROVAL = 'getcare/mdm/vendorProduct/SAVE_APPROVAL';
export const SAVE_ACTION_LOADING = 'getcare/mdm/vendorProduct/SAVE_ACTION_LOADING';


export const SAVE_VENDOR_PRODUCT_LIST = 'getcare/mdm/vendorProduct/SAVE_VENDOR_PRODUCT_LIST';
export const REMOVE_VENDOR_PRODUCT_ITEMS = 'getcare/mdm/vendorProduct/REMOVE_VENDOR_PRODUCT_ITEMS';
export const SAVE_VENDOR_PRODUCT_ITEM = 'getcare/mdm/vendorProduct/SAVE_VENDOR_PRODUCT_ITEM';
export const APPROVAL_VENDOR_PRODUCT_ITEMS = 'getcare/vendor/vendorProduct/APPROVAL_VENDOR_PRODUCT_ITEMS';
export const UNMAP_VENDOR_PRODUCT_ITEMS = 'getcare/vendor/vendorProduct/UNMAP_VENDOR_PRODUCT_ITEMS';

export const SAVE_ORI_VENDOR_PRODUCT_LIST = 'getcare/mdm/vendorProduct/SAVE_ORI_VENDOR_PRODUCT_LIST';

export const SAVE_VENDOR_PRODUCT_ALLOWED_ACTIONS = 'getcare/mdm/vendorProduct/SAVE_VENDOR_PRODUCT_ALLOWED_ACTIONS';
export const SAVE_VENDOR_PRODUCT_VISIBILITY = 'getcare/mdm/vendorProduct/SAVE_VENDOR_PRODUCT_VISIBILITY';
