import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import {
  getQueueIngredientList,
  deleteQueueIngredientItem,
  approveQueueIngredientItem,
} from 'redux/actions/admin/ingredientActions';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectQueueIngredientList,
  makeSelectQueueIngredientTotal,
  makeSelectQueueIngredientActionLoading,
} from 'redux/selectors';
import {
  queueListParamsMap,
  queueListDisplayFields,
  APPROVE,
} from 'utils/constanst/adminIngredientConstants';
import { getSortsString, getDisplayFields } from 'utils/helper';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import QueueIngredientList from 'components/admin/QueueIngredientList/QueueIngredientList';
import ListPagination from 'components/ListPagination/ListPagination';

import classes from './QueueIngredients.module.scss';

class QueueIngredients extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      listParams: { ...queueListParamsMap },
      isDialogOpen: false,
      isApproving: false,
      isDeleting: false,
      selectedIng: null,
    };
  }
  componentDidMount() {
    let params = { ...this.state.listParams };
    this.props.getQueueIngredientList({ params: params });
  }
  componentDidUpdate(prevProps, prevState) {
    const { loading } = this.props;
    const { isDialogOpen, isApproving, isDeleting } = this.state;
    if (!loading && loading !== prevProps.loading && isDialogOpen && (isApproving || isDeleting)) {
      this.handleDialogClose();
    }
  }
  handleFilterChange = (filters, forceResetPage: false) => {
    const paramsFromFilters = filters.reduce((memo, item) => {
      memo[item.name] = item.value;
      return memo;
    }, {});
    const newParams = forceResetPage ? {
      ...this.state.listParams,
      ...paramsFromFilters,
      page: 1,
    } : {
      ...this.state.listParams,
      ...paramsFromFilters,
    };
    this.setState({
      listParams: newParams,
    });
    this.props.getQueueIngredientList({
      params: newParams,
    });
  };
  handleSortChange = ({ sortBy, sortDir }) => {
    const newSortString = getSortsString({
      [sortBy]: sortDir,
    });
    this.handleFilterChange([
      {
        name: 'order',
        value: newSortString,
      },
    ]);
  };
  handleApproveItem = (item) => {
    this.setState({
      isDialogOpen: true,
      isApproving: true,
      selectedIng: item,
    });
  }
  handleDeleteItem = (item) => {
    this.setState({
      isDialogOpen: true,
      isDeleting: true,
      selectedIng: item,
    });
  }
  handleDialogClose = () => {
    this.setState({
      isDialogOpen: false,
      isApproving: false,
      isDeleting: false,
      selectedIng: null,
    });
  };
  handleSubmitConfirmation = () => {
    const { isApproving, isDeleting, selectedIng } = this.state;
    if (!selectedIng) return;
    if (isApproving) {
      this.props.approveQueueIngredientItem({
        id: selectedIng.id,
        params: {
          l0_code: selectedIng.l0_code,
          l0_name: selectedIng.l0_name,
          l1_code: selectedIng.l1_code,
          l1_name: selectedIng.l1_name,
          l2_code: selectedIng.l2_code,
          l2_name: selectedIng.l2_name,
          l3_code: selectedIng.l3_code,
          l3_name: selectedIng.l3_name,
          l4_code: selectedIng.l4_code,
          l4_name: selectedIng.l4_name,
          l5_code: selectedIng.l5_code,
          l5_name: selectedIng.l5_name,
          l6_name: selectedIng.l6_name,
          approval: APPROVE,
        }
      });
    }
    if (isDeleting) {
      this.props.deleteQueueIngredientItem({
        id: selectedIng.id,
        params: {
          l6_name: selectedIng.l6_name,
        }
      });
    }
  }

  render() {
    const { queueIngredientList, queueIngredientTotal, loading } = this.props;
    const { listParams, isDialogOpen, isApproving, isDeleting, selectedIng } = this.state;
    const isListLoading = loading && !isDialogOpen;

    return (
      <React.Fragment>
        <div className={classes.PageWrap}>
          <div className={classes.PageHeader}>
            <h1 className={classes.PageTitle}>Danh mục hoạt chất đợi duyệt</h1>
          </div>
          <div className={classes.PageMain}>
            <QueueIngredientList
              displayFields={getDisplayFields(
                listParams,
                queueListDisplayFields
              )}
              ingredientList={[...queueIngredientList]}
              isLoading={isListLoading}
              onSortChange={this.handleSortChange}
              onDeleteItem={this.handleDeleteItem}
              onApproveItem={this.handleApproveItem}
            />
          </div>
          <div className={classes.PageFooter}>
            <ListPagination
              page={listParams.page}
              pageSize={listParams.page_size}
              total={queueIngredientTotal}
              listName="danh mục"
              onFilterChange={this.handleFilterChange}
            />
          </div>
        </div>

        <Dialog
          open={isDialogOpen}
          onClose={this.handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.Dialog}
        >
          <div className={`${classes.DialogContentWrap} ${loading && 'OverlayLoading'}`}>
            <DialogTitle className={classes.DialogTitle}>
              Xác nhận {`${isApproving ? 'duyệt' : ''}`} {`${isDeleting ? 'xoá' : ''}`}
            </DialogTitle>
            <DialogContent className={classes.DialogContent}>
              <p className={classes.FormIntro}>
                {`${isApproving ? 'Duyệt: ' : ''}`} {`${isDeleting ? 'Xoá: ' : ''}`}
                <strong>{selectedIng?.l6_name}</strong>
              </p>
            </DialogContent>
            <DialogActions className={classes.DialogFooter}>
              <Button onClick={this.handleDialogClose}>
                Huỷ bỏ
              </Button>
              <Button
                onClick={this.handleSubmitConfirmation}
                variant="contained"
                color="primary"
                autoFocus
              >
                Xác nhận
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  queueIngredientList: makeSelectQueueIngredientList(),
  queueIngredientTotal: makeSelectQueueIngredientTotal(),
  loading: makeSelectQueueIngredientActionLoading(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    getQueueIngredientList: (payload) => dispatch(getQueueIngredientList(payload)),
    approveQueueIngredientItem: (payload) => dispatch(approveQueueIngredientItem(payload)),
    deleteQueueIngredientItem: (payload) => dispatch(deleteQueueIngredientItem(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter)(QueueIngredients);
