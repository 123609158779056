import axiosAuth from 'utils/axios/axiosAuth';

const keyAccountApi = {
  getList: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (![null, undefined, ''].includes(params[key])) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_key_account`, {
      params: queryParams,
    });
  },
  get: (id) => {
    return axiosAuth.get(`getcare_key_account/${id}`);
  },
  getKeyAccountProductsByVendor: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (![null, undefined, ''].includes(params[key])) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_key_account_product_search`, {
      params: queryParams,
    });
  },
  getKeyAccountHistory: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (![null, undefined, ''].includes(params[key])) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_key_account_history`, {
      params: queryParams,
    });
  },

  update: ({ params }) => {
    return axiosAuth.post(`getcare_key_account`, params);
  },
};

export default keyAccountApi;
