import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import salesChannelApi from 'utils/api/admin/salesChannelApi';
import { toast } from 'react-toastify';

import Button from '@material-ui/core/Button';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';

class SalesChannelDeletingDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    }
  }

  handleDelete = async () => {
    this.setState({ isLoading: true });
    const { data: response } = await salesChannelApi.delete({
      params: { id: this.props.id },
    });
    this.setState({
      isLoading: false,
    });
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    toast.success('Khu vực bán hàng đã được xoá.');
    this.props.onDeleteItemSuccess();
  }

  render() {
    const {
      code,
      isOpen,
      wasSetUp,
    } = this.props;

    const { isLoading } = this.state;

    return <ConfirmationDialog
      isOpen={isOpen}
      isLoading={isLoading}
      type={wasSetUp ? 'info' : 'normal'}
      title="Xoá khu vực bán hàng"
      message={wasSetUp
        ? <>
            <p>Khu vực bán hàng <strong>{code}</strong> đã được cấu hình áp dụng trên hệ thống.</p>
            <p className="TextPrimary">Bạn không thể xoá khu vực bán hàng này.</p>
          </>
        : <p>Bạn có chắc xoá khu vực bán hàng <strong>{code}</strong>?</p>
      }
      dialogAction={wasSetUp
        ? <Button variant="contained" onClick={this.props.onClose}>
            Đóng
          </Button>
        : undefined
      }
      onClose={wasSetUp ? undefined: this.props.onClose}
      onSubmit={wasSetUp ? undefined: this.handleDelete}
    />
  }
}

SalesChannelDeletingDialog.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  code: PropTypes.string,
  wasSetUp: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onDeleteItemSuccess: PropTypes.func,
};

SalesChannelDeletingDialog.defaultProps = {
  code: '',
  wasSetUp: false,
  isOpen: false,
};

export default SalesChannelDeletingDialog;
