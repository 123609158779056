import {
  GET_COORDINATER_LIST,
  UPDATE_COORDINATER_ITEM,
  DELETE_COORDINATER_ITEMS,
  SAVE_TOTAL,
  SAVE_LOADING,
  SAVE_DELETE_LOADING,
  SAVE_COORDINATER_LIST,
  SAVE_COORDINATER_ITEM,
  REMOVE_COORDINATER_ITEMS,
  SAVE_COORDINATER_ALLOWED_ACTIONS,
} from 'redux/constants/admin/coordinaterConstants';

export function getCoordinaterList(payload) {
  return {
    type: GET_COORDINATER_LIST,
    payload
  };
}
export function updateCoordinaterItem(payload) {
  return {
    type: UPDATE_COORDINATER_ITEM,
    payload
  };
}
export function deleteCoordinaterItems(payload) {
  return {
    type: DELETE_COORDINATER_ITEMS,
    payload
  };
}

// mutation
export function saveCoordinaterListTotal(coordinaterListTotal) {
  return {
    type: SAVE_TOTAL,
    coordinaterListTotal
  };
}
export function saveLoading(loading) {
  return {
    type: SAVE_LOADING,
    loading
  };
}
export function saveDeleteLoading(deleteLoading) {
  return {
    type: SAVE_DELETE_LOADING,
    deleteLoading
  };
}
export function saveCoordinaterList(coordinaterList) {
  return {
    type: SAVE_COORDINATER_LIST,
    coordinaterList
  };
}
export function removeCoordinaterItems(coordinaterItems) {
  return {
    type: REMOVE_COORDINATER_ITEMS,
    coordinaterItems
  };
}
export function saveCoordinaterItem(coordinaterItem) {
  return {
    type: SAVE_COORDINATER_ITEM,
    coordinaterItem
  };
}
export function saveCoordinaterAllowedActions(coordinaterAllowedActions) {
  return {
    type: SAVE_COORDINATER_ALLOWED_ACTIONS,
    coordinaterAllowedActions
  };
}
