import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { filterFields } from 'utils/constanst/adminIngredientConstants';

import FilterField from 'components/FilterField/FilterField';

import classes from './IngredientFilters.module.scss';

class IngredientFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: [...filterFields],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.listParams !== this.props.listParams) {
      const fields = this._initFilters({...this.props.listParams}, [...filterFields]);
      this.setState({
        fields: [...fields],
      });
    }
  }

  _initFilters = (params, fields) => {
    return fields.map(item => ({
      ...item,
      value: params[item.name],
    }));
  }

  _getFieldIndex = (fieldName) => {
    return this.state.fields.findIndex(item => item.name === fieldName);
  }

  handleFieldChange = ({ name, value }) => {
    const newFields = this.state.fields.map(field => field.name === name ? { ...field, value } : field);
    this.setState({
      fields: newFields,
    });
    this.props.onFilterChange(newFields, true);
  }

  render() {
    const { fields } = this.state;

    return (
      <div className={classes.Panel}>
        <h3 className={classes.PanelTitle}>Lọc theo:</h3>
        <div className={classes.PanelContent}>
          {fields.map((field) => (
            <FilterField key={field.name} {...field} handleFieldChange={this.handleFieldChange} />
          ))}
        </div>
      </div>
    );
  }
}

IngredientFilters.propTypes = {
  listParams: PropTypes.object,
  onFilterChange: PropTypes.func,
};

export default IngredientFilters;
