import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { validDate } from 'utils/helper';
import { dateTimeFormat } from 'utils/constanst/dateConstants';
import format from 'date-fns/format';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectPolicyPriceHistory } from 'redux/selectors';
import { getPolicyPriceHistory } from 'redux/actions/admin/policyPriceActions';

import classes from './PolicyPriceHistory.module.scss';

class PolicyPriceHistory extends PureComponent {
  componentDidMount() {
    this._loadData();
  }
  componentDidUpdate(prevProps) {
    const { policyPriceId } = this.props;
    if (policyPriceId && policyPriceId !== prevProps.policyPriceId) {
      this._loadData();
    }
  }
  _loadData = () => {
    this.props.getPolicyPriceHistory({
      params: { getcare_policy_price_id: this.props.policyPriceId },
    });
  };

  render() {
    const { policyPriceHistory } = this.props;

    return (
      <div className={`${classes.Wrap}`}>
        <div className={`${classes.Inner}`}>
          <ul className={classes.EventList}>
            {policyPriceHistory.map((event) => (
              <li key={`event-${event.id}`} className={classes.EventItem}>
                <span className={classes.Bullet}></span>
                <div className={classes.EventDetails}>
                  <p className={classes.EventTime}>
                    {validDate(event.date)
                      ? format(validDate(event.date), dateTimeFormat)
                      : ''}
                  </p>
                  <p
                    className={classes.EventTitle}
                  >{`${event.name}: ${event.action}`}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

PolicyPriceHistory.propTypes = {
  policyPriceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

PolicyPriceHistory.defaultProps = {};

const mapStateToProps = createStructuredSelector({
  policyPriceHistory: makeSelectPolicyPriceHistory(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getPolicyPriceHistory: (payload) =>
      dispatch(getPolicyPriceHistory(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(PolicyPriceHistory);
