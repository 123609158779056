import shippingCouponApi from 'utils/api/admin/shippingCouponApi';
import { put } from 'redux-saga/effects';
import { shippingCouponActions } from 'redux/actions';
import { toast } from 'react-toastify';
import { startOfToday, startOfDay, isAfter } from 'date-fns';

export function* loadShippingCouponList(payload) {
  const { params } = payload.payload;
  try {
    yield put(shippingCouponActions.saveLoading(true));

    const { data: response } = yield shippingCouponApi.getList({ params });

    yield put(shippingCouponActions.saveLoading(false));
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(
      shippingCouponActions.saveShippingCouponList(response.data ? [...response.data] : [])
    );
    yield put(shippingCouponActions.saveShippingCouponListTotal(response.total_records));
  } catch (err) {
    console.log(err);
  }
}
export function* loadShippingCoupon(payload) {
  const { id, isDuplicate } = payload.payload;
  try {
    yield put(shippingCouponActions.saveLoading(true));

    yield put(shippingCouponActions.saveOriShippingCouponDetails(null));
    yield put(shippingCouponActions.saveShippingCouponDetails(null));

    yield put(shippingCouponActions.saveOriShippingCouponRules([]));
    yield put(shippingCouponActions.saveShippingCouponRules([]));

    yield put(shippingCouponActions.saveOriShippingCouponSalesChannels([]));
    yield put(shippingCouponActions.saveShippingCouponSalesChannels([]));

    yield put(shippingCouponActions.saveOriShippingCouponCustomers([]));
    yield put(shippingCouponActions.saveShippingCouponCustomers([]));

    yield put(shippingCouponActions.saveShippingCouponHistory([]));

    const { data: response } = yield shippingCouponApi.get(id);

    yield put(shippingCouponActions.saveLoading(false));

    if (!response?.result) {
      toast.error(response.message);
      return;
    }

    const oriData = response.data ? {
      ...response.data,
      getcare_shipping_coupon_rules: undefined,
      getcare_sales_channels: undefined,
      getcare_erp_groups: undefined,
      getcare_shipping_coupon_history: undefined,
    } : null;
    const salesChannels = response.data?.getcare_sales_channels.map(item => ({
      ...item,
      getcare_sales_channel: { ...item },
    })) || [];
    const customers = response.data?.getcare_erp_groups.map(item => ({
      ...item,
      getcare_erp_group: { ...item },
    })) || [];

    if (!isDuplicate) {
      yield put(shippingCouponActions.saveOriShippingCouponDetails(oriData ? {...oriData} : null));
      yield put(shippingCouponActions.saveOriShippingCouponRules(response.data?.getcare_shipping_coupon_rules || []));
      yield put(shippingCouponActions.saveOriShippingCouponSalesChannels(salesChannels));
      yield put(shippingCouponActions.saveOriShippingCouponCustomers(customers));

      yield put(shippingCouponActions.saveShippingCouponDetails(oriData ? {...oriData} : null));
      yield put(shippingCouponActions.saveShippingCouponRules(response.data?.getcare_shipping_coupon_rules || []));
      yield put(shippingCouponActions.saveShippingCouponSalesChannels(salesChannels));
      yield put(shippingCouponActions.saveShippingCouponCustomers(customers));
      yield put(shippingCouponActions.saveShippingCouponHistory(response.data?.getcare_shipping_coupon_history || []));
    }

    if (isDuplicate) {
      yield put(
        shippingCouponActions.saveShippingCouponDetails(oriData ? {
          ...oriData,
          id: undefined,
          start_date: !isAfter(startOfDay(new Date(oriData.start_date)), startOfToday())
            ? startOfToday().toISOString()
            : oriData.start_date,
          end_date: oriData.end_date && !isAfter(startOfDay(new Date(oriData.end_date)), startOfToday())
            ? ''
            : oriData.end_date,
        } : null)
      );
      yield put(shippingCouponActions.saveShippingCouponRules(response.data?.getcare_shipping_coupon_rules || []));
      yield put(shippingCouponActions.saveShippingCouponSalesChannels(salesChannels));
      yield put(shippingCouponActions.saveShippingCouponCustomers(customers));
      yield put(shippingCouponActions.saveShippingCouponHistory([]));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* postShippingCoupon(payload) {
  const { params } = payload.payload;
  try {
    yield put(shippingCouponActions.saveLoading(true));

    const { data: response } = params.id ? yield shippingCouponApi.update({ params }) : yield shippingCouponApi.create({ params });

    yield put(shippingCouponActions.saveLoading(false));
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    toast.success(`Lưu shipping coupon thành công.`);

    const oriData = response.data ? {
      ...response.data,

      getcare_shipping_coupon_rules: undefined,
      getcare_sales_channels: undefined,
      getcare_erp_groups: undefined,
      getcare_shipping_coupon_history: undefined,
    } : null;
    yield put(
      shippingCouponActions.saveOriShippingCouponDetails(oriData ? {...oriData} : null)
    );
    yield put(
      shippingCouponActions.saveShippingCouponDetails(oriData ? {...oriData} : null)
    );

    if (params.id) {
      // is editing successfull
      yield put(shippingCouponActions.saveOriShippingCouponRules(response.data?.getcare_shipping_coupon_rules || []));
      yield put(shippingCouponActions.saveShippingCouponRules(response.data?.getcare_shipping_coupon_rules || []));

      const salesChannels = response.data?.getcare_sales_channels.map(item => ({
        ...item,
        getcare_sales_channel: { ...item },
      })) || [];
      yield put(shippingCouponActions.saveOriShippingCouponSalesChannels(salesChannels));
      yield put(shippingCouponActions.saveShippingCouponSalesChannels(salesChannels));

      const customers = response.data?.getcare_erp_groups.map(item => ({
        ...item,
        getcare_erp_group: { ...item },
      })) || [];
      yield put(shippingCouponActions.saveOriShippingCouponCustomers(customers));
      yield put(shippingCouponActions.saveShippingCouponCustomers(customers));

      yield put(shippingCouponActions.saveShippingCouponHistory(response.data?.getcare_shipping_coupon_history || []));
    }
  } catch (err) {
    console.log(err);
  }
}
