import assetComponentApi from 'utils/api/admin/assetComponentApi';
import { put } from 'redux-saga/effects';
import { assetComponentActions } from 'redux/actions';
import { toast } from 'react-toastify';

export function* loadAssetComponentList(payload) {
  const { params, vendorId } = payload.payload;
  try {
    yield put(assetComponentActions.saveLoading(true));
    yield put(assetComponentActions.saveAssetComponentList([]));
    yield put(assetComponentActions.saveAssetComponentListTotal(0));

    const { data: response } = yield assetComponentApi.getAssetComponentList({
      params,
      vendorId,
    });

    yield put(assetComponentActions.saveLoading(false));
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(
      assetComponentActions.saveAssetComponentList(
        response.data ? [...response.data] : []
      )
    );
    yield put(
      assetComponentActions.saveAssetComponentListTotal(response.total_records)
    );
  } catch (err) {
    console.log(err);
  }
}
