import {
  SAVE_LOADING,

  SAVE_KEY_ACCOUNT_LOCK_LIST,
  SAVE_KEY_ACCOUNT_LOCK_LIST_TOTAL,
} from 'redux/constants/admin/keyAccountLockConstants';

const initialState = {
  keyAccountLockList: [],
  keyAccountLockListTotal: 0,

  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_LOADING: {
      return {
        ...state,
        loading: action.loading,
      };
    }

    case SAVE_KEY_ACCOUNT_LOCK_LIST: {
      const list = action.keyAccountLockList;
      return {
        ...state,
        keyAccountLockList: list ? [...list] : [],
      };
    }
    case SAVE_KEY_ACCOUNT_LOCK_LIST_TOTAL: {
      return {
        ...state,
        keyAccountLockListTotal: action.keyAccountLockListTotal,
      };
    }

    default:
      return state;
  }
};

export default reducer;
