import keyAccountLockApi from 'utils/api/admin/keyAccountLockApi';
import { put } from 'redux-saga/effects';
import { keyAccountLockActions } from 'redux/actions';
import { toast } from 'react-toastify';

export function* loadKeyAccountLockList(payload) {
  const { params } = payload.payload;
  try {
    yield put(keyAccountLockActions.saveLoading(true));

    const { data: response } = yield keyAccountLockApi.getList({ params });

    yield put(keyAccountLockActions.saveLoading(false));
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(
      keyAccountLockActions.saveKeyAccountLockList(response.data ? [...response.data] : [])
    );
    yield put(keyAccountLockActions.saveKeyAccountLockListTotal(response.total_records));
  } catch (err) {
    console.log(err);
  }
}


export function* deleteKeyAccountLockList(payload) {
  const { params } = payload.payload;
  try {
    yield put(keyAccountLockActions.saveLoading(true));

    const { data: response } = yield keyAccountLockApi.deletekeyAccountLock({ params });
    yield put(keyAccountLockActions.saveLoading(false));
    window.location.reload();
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
  } catch (err) {
    console.log(err);
  }
}