import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { genID } from 'utils/helper';
import ingredientApi from 'utils/api/admin/ingredientApi';
import { toast } from 'react-toastify';
import { APPROVE } from 'utils/constanst/adminIngredientConstants';
import { orderBy } from 'lodash';

import Button from '@material-ui/core/Button';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';
import ProductIngredientItem from './ProductIngredientItem/ProductIngredientItem';

import gridClasses from './ProductIngredientsGrid.module.scss';
import classes from './ProductIngredients.module.scss';

class ProductIngredients extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      categoryList: [],
    }
  }
  componentDidMount() {
    this.setState({
      categoryList: this.getCategories(this.props.getcare_product_active_ingredients),
    });
  }
  componentDidUpdate(prevProps) {
    const { getcare_product_active_ingredients } = this.props;
    if (getcare_product_active_ingredients && getcare_product_active_ingredients !== prevProps.getcare_product_active_ingredients) {
      this.setState({
        categoryList: this.getCategories(getcare_product_active_ingredients),
      });
    }
  }
  getCategories = (list) => {
    return orderBy(list, 'created_at', 'desc').map(item => ({
      idStr: genID(),
      id: item.getcare_active_ingredients_id,
      l6_name: item.getcare_active_ingredients?.l6_name || '',
      content: item.content,
      isApproved: item.getcare_active_ingredients && item.getcare_active_ingredients.approval === APPROVE,
    }));
  }
  handleCreateNewRow = () => {
    this.setState({
      categoryList: [
        {idStr: genID(), id: 0, l6_name: '', content: ''},
        ...this.state.categoryList,
      ],
    });
  }
  handleSaveRow = ({idStr, id, l6_name, content}) => {
    const { categoryList } = this.state;
    const itemIndex = categoryList.findIndex(item => item.idStr === idStr);
    const list = [...categoryList];
    if (itemIndex > -1) {
      list[itemIndex] = {
        idStr,
        id,
        l6_name,
        content,
        isApproved: !!id,
      };
    }
    if (itemIndex <= -1) {
      list.unshift({idStr, id, l6_name, content, isApproved: true,});
    }
    this.handleSubmitCategories(list);
    this.setState({
      categoryList: list,
    });
  }
  handleDeleteRow = ({idStr, id, l6_name, content}) => {
    const { categoryList } = this.state;
    const list = categoryList.filter(item => item.idStr !== idStr);
    this.handleSubmitCategories(list);
    this.setState({
      categoryList: list,
    });
  }
  handleSubmitCategories = async (categories) => {
    const { data: response } = await ingredientApi.tagCategoriesToProduct({
      params: {
        getcare_product_id: this.props.productId,
        getcare_active_ingredients_list: categories.map(cate => ({
          id: cate.id,
          name: cate.l6_name,
          content: cate.content,
        })),
      },
    });
    if (!response.result) {
      toast.error(response.message);
      return;
    }
  }

  render() {
    const { active_ingredients_main, isEditAllowed } = this.props;
    const { categoryList } = this.state;

    return (<div className={classes.Wrap}>
      <p>{ active_ingredients_main }</p>

      <div className={`${classes.List}`}>
        <div className={`${gridClasses.Row} ${isEditAllowed ? gridClasses.HasActionCol : ''} ${classes.Header}`}>
          <ListHeaderCol className={gridClasses.Col} label="Hoạt chất"/>
          <ListHeaderCol className={gridClasses.Col} label="Hàm lượng"/>
          { isEditAllowed && (
            <div className={`${gridClasses.Col} ${classes.HeaderCol} ${classes.ActionCol}`}>
              <Button
                color="primary"
                variant="contained"
                size="small"
                onClick={this.handleCreateNewRow}
              >
                Thêm dòng
              </Button>
            </div>
          )}
        </div>
        <div className={`${classes.Body}`}>
          { (categoryList && categoryList.length)
            ? categoryList.map((item) => (
              <ProductIngredientItem
                key={`sale-${item.idStr}`}
                {...item}
                onSaveCategory={this.handleSaveRow}
                onDeleteCategory={this.handleDeleteRow}
                isEditAllowed={isEditAllowed}
              />))
            : (<p className="NoData">Không có data</p>)
          }
        </div>
      </div>
    </div>)
  }
}

ProductIngredients.propTypes = {
  productId:PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  getcare_product_active_ingredients: PropTypes.array,
  active_ingredients_main: PropTypes.string,
};

ProductIngredients.defaultProps = {
  getcare_product_active_ingredients: [],
  active_ingredients_main: '',
};

export default ProductIngredients;
