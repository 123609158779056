import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import { validDate } from 'utils/helper';
import { dateFormat, apiDateFormat } from 'utils/constanst/dateConstants';
import { format } from 'date-fns';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import classes from './EndDateGroupFilter.module.scss';

class EndDateGroupFilter extends PureComponent {
  handleCheckboxChange = (e) => {
    this.props.handleFieldChange({
      name: this.props.name,
      value: [this.props.value[0], e.target.checked ? 1 : 0],
    });
  };
  handleDateChange = (date) => {
    this.props.handleFieldChange({
      name: this.props.name,
      value: [validDate(date) ? format(date, apiDateFormat) : '', this.props.value[1]],
    });
  };

  render() {
    const { value } = this.props;

    return (
      <FormGroup className={`${classes.FormGroup}`}>
        <div className={`${classes.FieldWrap}`}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              autoOk
              variant="inline"
              format={dateFormat}
              placeholder="Nhập hoặc chọn"
              name="end_date"
              fullWidth
              value={validDate(value[0]) ? validDate(value[0]) : null}
              onChange={this.handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
        <div className={`${classes.FieldWrap}`}>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={value[1] ? true : false}
                onChange={this.handleCheckboxChange}
                name="end_date_empty"
                style={{ padding: '0.4rem' }}
              />
            }
            label="Ngày kết thúc rỗng"
          />
        </div>
      </FormGroup>
    );
  }
}

EndDateGroupFilter.propTypes = {
  name: PropTypes.string,
  value: PropTypes.array,
  handleFieldChange: PropTypes.func,
};

EndDateGroupFilter.defaultProps = {
  value: ['', ''],
};

export default EndDateGroupFilter;
