import React, { PureComponent } from 'react';
import productApi from 'utils/api/admin/productApi';
import { isEqual, sortBy, debounce } from 'lodash';
import { currencyFormatVN, validNumber } from 'utils/helper';

import IconButton from '@material-ui/core/IconButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import NumberFormatCustom from 'components/NumberFormatCustom/NumberFormatCustom';

import classes from './OrderListItem.module.scss';
import gridClasses from '../OrderListGrid.module.scss';

class OrderListItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: props.isEditing,

      productOptions: this._initProductOptions(props),
      vendorOptions: this._initVendorOptions(props),
      unitOptions: this._initUnitOptions(props),

      productKeywordInput: '',
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { productKeywordInput, vendorOptions, unitOptions } = this.state;
    const { getcare_vendor_id, getcare_uom_base_id, getcare_product } = this.props;

    if (productKeywordInput && productKeywordInput !== prevState.productKeywordInput) {
      this._loadSuggestedProducts({
        params: {
          keyword: productKeywordInput,
          mode: 'INCLUDE_VENDORS',
          page_size: 20,
        },
      });
    }

    if (getcare_product && getcare_product.id !== prevProps.getcare_product?.id) {
      this._getVendorOptions();
    }

    if (!isEqual(sortBy(vendorOptions), sortBy(prevState.vendorOptions))) {
      if (vendorOptions && vendorOptions.length > 0) {
        // default select the first vendor option
        const selectedVendor = { ...vendorOptions[0] };
        this.handleSaveField({target: {name: 'getcare_vendor_id'}}, selectedVendor?.id || null);
      }
    }

    if (getcare_vendor_id !== prevProps.getcare_vendor_id) {
      // vendor id change -> unit options will change
      this._setUnitOptions();
    }

    if (getcare_uom_base_id && getcare_uom_base_id !== prevProps.getcare_uom_base_id) {
      // unit id change -> price, vat will change
      const selectedUom = this._getSelectedUnit(getcare_uom_base_id);
      this.handleSaveMultiFields({
        price_sales_total: validNumber(selectedUom.price),
        vat: validNumber(selectedUom.vat),
      })
    }

    if (unitOptions && unitOptions.length > 0 && !isEqual(sortBy(unitOptions), sortBy(prevState.unitOptions))) {
      // unit id doesn't change but unit options change (by change vendor id)
      // -> default select the first uom option
      const selectedUom = this._getSelectedUnit(getcare_uom_base_id) || unitOptions[0];
      this.handleSaveField({target: {name: 'getcare_uom_base_id'}}, selectedUom?.id || null);
      if (selectedUom?.id === getcare_uom_base_id) {
        // vendor id change
        // -> unit options change
        // -> but unit id doesn't change because multi vendors have the same unit but different prices
        // -> price, vat will change
        this.handleSaveMultiFields({
          price_sales_total: validNumber(selectedUom?.price),
          vat: validNumber(selectedUom?.vat),
        })
      }
    }
  }

  _initProductOptions = (params) => {
    return params.getcare_product ? [{...params.getcare_product}] : [];
  }

  _initVendorOption = (params) => {
    return {
      id: params.getcare_vendor_id,
      name: params.vendor_name,
    }
  }
  _initVendorOptions = (params) => {
    return params.getcare_vendor_id ? [{...this._initVendorOption(params)}] : [];
  }

  _initUnitOption = (params) => {
    return {
      id: params.getcare_uom_base_id,
      name: params.uom_base_name,
    };
  }
  _initUnitOptions = (params) => {
    return params.getcare_uom_base_id ? [{...this._initUnitOption(params)}] : [];
  }

  _loadSuggestedProducts = debounce(async ({ params }) => {
    const { data: response } = await productApi.getAll({ params });
    if (!response?.result) return;
    this.setState({ productOptions: response.data ? response.data : [] })
  }, 500);

  _getVendorOptions = debounce(async () => {
    const { getcare_product, selectedCustomerCode } = this.props;
    const { data: response } = await productApi.getDetailsWithParams(getcare_product?.id, {
      getcare_pharmacy_code: selectedCustomerCode,
    });
    if (!response?.result || !response.data) return;
    this.setState({ vendorOptions: response.data.getcare_vendors ? response.data.getcare_vendors : [] })
  }, 500);

  _getUnitOptions = () => {
    const { getcare_vendor_id } = this.props;
    const { vendorOptions } = this.state;
    const newUnitOptions = (!getcare_vendor_id || !vendorOptions || !vendorOptions.length)
      ? []
      : this._getSelectedVendor(getcare_vendor_id)?.uom?.map(item => ({
        ...item,
        id: item.uom_base_id,
        name: item.uom_base_name,
      }))
    return newUnitOptions;
  }
  _setUnitOptions = () => {
    const units = this._getUnitOptions();
    this.setState({
      unitOptions: units,
    });
  }

  _getInitParams = (params) => {
    return {
      idStr: params?.idStr,
      id: params?.id,

      getcare_product: params.getcare_product ? { ...params.getcare_product } : null,

      getcare_vendor_id: params.getcare_vendor_id,
      getcare_vendor: params.getcare_vendor_id ? {...this._getSelectedVendor(params.getcare_vendor_id)} : null,

      getcare_uom_base_id: params.getcare_uom_base_id,
      getcare_uom_base: params.getcare_uom_base_id ? {...this._getSelectedUnit(params.getcare_uom_base_id)} : null,

      quantity_number: params.quantity_number,
      price_sales_total: params.price_sales_total,
      vat: params.vat,
    }
  }

  _getSelectedVendor = (vendorId) => {
    return this.state.vendorOptions?.find(item => item.id === vendorId) || null;
  }
  _getSelectedUnit = (unitId) => {
    return this.state.unitOptions?.find(item => item.id === unitId) || null;
  }

  _hasProduct = () => {
    const { getcare_product } = this.props;
    return !!getcare_product && !!getcare_product.id;
  }
  _hasVendor = () => {
    const { getcare_vendor } = this.props;
    return !!getcare_vendor && !!getcare_vendor.id;
  }
  _hasUnit = () => {
    const { getcare_uom_base } = this.props;
    return !!getcare_uom_base && !!getcare_uom_base.id;
  }
  _isRowValid = () => {
    return this._hasProduct() && this._hasVendor() && this._hasUnit();
  };

  handleSaveField = (e, value) => {
    const newParams = {
      ...this._getInitParams(this.props),
      [e.target.name]: (e.target.type === 'number')  ? validNumber(value) : value,
    }
    this.props.saveOrderListItem({
      ...newParams,
      idStr: this.props.idStr,
      id: this.props.id,
    });
  }
  handleSaveMultiFields = (fieldsMap) => {
    const newParams = {
      ...this._getInitParams(this.props),
      ...fieldsMap,
    }
    this.props.saveOrderListItem({
      ...newParams,
      idStr: this.props.idStr,
      id: this.props.id,
    });
  }
  handleRemove = () => {
    this.props.removeOrderListItem({
      id: this.props.id,
      idStr: this.props.idStr,
    });
  }

  render() {
    const {
      price_sales_total,
      quantity_number,
      discount_amount,
      getcare_product,
      getcare_vendor_id,
      getcare_uom_base_id,
    } = this.props;

    const {
      productOptions,
      unitOptions,
      vendorOptions,
    } = this.state;

    const selectedUnit = this._getSelectedUnit(getcare_uom_base_id);

    return (<>
      <div className={`${gridClasses.Row} ${classes.Item}`}>
        <div className={`${gridClasses.Col}`}>
          <Autocomplete
            openOnFocus
            selectOnFocus
            disableClearable
            fullWidth
            size="small"
            handleHomeEndKeys={false}
            value={getcare_product}
            onChange={(e, newValue) => this.handleSaveField({target: {name: 'getcare_product'}}, newValue)}
            options={productOptions || []}
            filterOptions={(x) => x}
            renderOption={(option) => `${option.getcare_id} - ${option.name}`}
            getOptionLabel={(option) => `${option.getcare_id}`}
            getOptionSelected={(option, value) => {
              return value?.id && option ? option.id === value.id : null
             } }
            onInputChange={(e, newInputValue) => {
              this.setState({
                productKeywordInput: newInputValue,
              });
            }}
            renderInput={(params) => (
              <TextField {...params} placeholder="- Chọn -" />
            )}
          />
        </div>
        <div className={`${gridClasses.Col}`}>
          <Autocomplete
            openOnFocus
            selectOnFocus
            disableClearable
            fullWidth
            size="small"
            handleHomeEndKeys={false}
            value={getcare_product}
            onChange={(e, newValue) => this.handleSaveField({target: {name: 'getcare_product'}}, newValue)}
            options={productOptions || []}
            filterOptions={(x) => x}
            renderOption={(option) => `${option.getcare_id} - ${option.name}`}
            getOptionLabel={(option) => `${option.name}`}
            getOptionSelected={(option, value) =>
              value?.id && option ? option.id === value.id : null
            }
            onInputChange={(e, newInputValue) => {
              this.setState({
                productKeywordInput: newInputValue,
              });
            }}
            renderInput={(params) => (
              <TextField {...params} placeholder="- Chọn -" />
            )}
          />
        </div>
        <div className={`${gridClasses.Col}`}>
          { getcare_product?.code }
        </div>
        <div className={`${gridClasses.Col}`}>
          <Autocomplete
            openOnFocus
            selectOnFocus
            disableClearable
            fullWidth
            size="small"
            handleHomeEndKeys={false}
            value={this._getSelectedVendor(getcare_vendor_id)}
            onChange={(e, newValue) => this.handleSaveField({target: {name: 'getcare_vendor_id'}}, newValue?.id)}
            options={vendorOptions || []}
            filterOptions={(x) => x}
            renderOption={(option) => `${option.name}`}
            getOptionLabel={(option) => `${option.name}`}
            getOptionSelected={(option, value) =>
              value?.id && option ? option.id === value.id : null
            }
            renderInput={(params) => (
              <TextField {...params} placeholder="- Chọn -" />
            )}
          />
        </div>
        <div className={`${gridClasses.Col}`}>
          <Autocomplete
            disableClearable
            openOnFocus
            fullWidth
            size="small"
            handleHomeEndKeys={false}
            value={selectedUnit}
            onChange={(e, newValue) => this.handleSaveField({target: {name: 'getcare_uom_base_id'}}, newValue?.id)}
            options={unitOptions || []}
            renderOption={(option) => `${option.name}`}
            getOptionLabel={(option) => (option && option.name) || ''}
            getOptionSelected={(option, value) =>
              value?.id && option ? option.id === value.id : null
            }
            renderInput={(params) => (
              <TextField {...params} placeholder="- Chọn -" />
            )}
          />
        </div>
        <div className={`${gridClasses.Col} TextRight`}>
          { ![null, undefined, ''].includes(price_sales_total) ? currencyFormatVN(price_sales_total) : ``}
        </div>
        <div className={`${gridClasses.Col}`}>
          <TextField
            autoComplete="off"
            className={classes.Field}
            value={quantity_number}
            placeholder=""
            name="quantity_number"
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
            onChange={(e) => {
              this.handleSaveField(e, e.target.floatValue);
            }}
          />
        </div>
        <div className={`${gridClasses.Col} TextRight`}>
          { [null, undefined, ''].includes(quantity_number) || [null, undefined, ''].includes(price_sales_total) ? `` : currencyFormatVN(price_sales_total * Number(quantity_number)) }
        </div>
        <div className={`${gridClasses.Col} TextRight`}>
          <TextField
            autoComplete="off"
            className={`${classes.Field} InputTextRight`}
            value={discount_amount}
            placeholder=""
            name="discount_amount"
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
            onChange={(e) => {
              this.handleSaveField(e, e.target.floatValue);
            }}
          />
        </div>
        <div className={`${gridClasses.Col} ${classes.ActionsCol}`}>
          <IconButton
            onClick={this.handleRemove}
            style={{ padding: '0' }}
            size="small"
          >
            <DeleteOutline fontSize="small" />
          </IconButton>
        </div>
      </div>
    </>);
  }
}

export default OrderListItem;

