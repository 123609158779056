import {
  GET_PRODUCT_DETAILS,
  SAVE_PRODUCT_DETAILS,
  GET_PRODUCT_LIST,
  SAVE_PRODUCT_LIST,
  SAVE_TOTAL,
  SAVE_LOADING,
  DEACTIVE_PRODUCTS,
  REMOVE_PRODUCTS,
  SAVE_RELATED_PRODUCTS,
  GET_MEDICINE_BY_PHARMACY_ID,
  GET_OTHERS_BY_PHARMACY_ID,
  GET_AWAITING_PRODUCTS_BY_PHARMACY_ID,
  SAVE_MEDICINE_BY_PHARMACY_ID,
  SAVE_TOTAL_MEDICINES,
  SAVE_OTHERS_BY_PHARMACY_ID,
  SAVE_TOTAL_OTHERS,
  SAVE_AWAITING_PRODUCTS_BY_PHARMACY_ID,
  SAVE_TOTAL_AWAITING_PRODUCTS,
  GET_PRODUCT_BRANDS,
  SAVE_PRODUCT_BRANDS,
  GET_PRODUCT_INDUSTRIES,
  SAVE_PRODUCT_INDUSTRIES,
  UPDATE_AWAITING_PRODUCT,
  SAVE_AWAITING_PRODUCT,
  EXPORT_PRODUCT_LIST_TEMPLATE,
  SAVE_PRODUCT_ALLOWED_ACTIONS,
} from 'redux/constants/admin/productConstants';

export function getProductDetails(id) {
  return {
    type: GET_PRODUCT_DETAILS,
    id,
  };
}
export function getProductList(payload) {
  return {
    type: GET_PRODUCT_LIST,
    payload,
  };
}
export function deactiveProducts(ids) {
  return {
    type: DEACTIVE_PRODUCTS,
    ids,
  };
}
export function getMedicineByPharmacyId(payload) {
  return {
    type: GET_MEDICINE_BY_PHARMACY_ID,
    payload,
  };
}
export function getOtherProductsByPharmacyId(payload) {
  return {
    type: GET_OTHERS_BY_PHARMACY_ID,
    payload,
  };
}

export function getAwaitingProductsByPharmacyId(payload) {
  return {
    type: GET_AWAITING_PRODUCTS_BY_PHARMACY_ID,
    payload,
  };
}
export function updateAwaitingProduct(payload) {
  return {
    type: UPDATE_AWAITING_PRODUCT,
    payload,
  };
}

export function getProductBrands(payload) {
  return {
    type: GET_PRODUCT_BRANDS,
    payload,
  };
}
export function getProductIndustries(payload) {
  return {
    type: GET_PRODUCT_INDUSTRIES,
    payload,
  };
}
export function exportProductListTemplate(payload) {
  return {
    type: EXPORT_PRODUCT_LIST_TEMPLATE,
    payload,
  };
}

// mutation
export function saveProduct(productDetails) {
  return {
    type: SAVE_PRODUCT_DETAILS,
    productDetails,
  };
}
export function saveProductList(productList) {
  return {
    type: SAVE_PRODUCT_LIST,
    productList,
  };
}
export function removeProducts(ids) {
  return {
    type: REMOVE_PRODUCTS,
    ids,
  };
}
export function saveTotal(total) {
  return {
    type: SAVE_TOTAL,
    total,
  };
}
export function saveLoading(isLoading) {
  return {
    type: SAVE_LOADING,
    isLoading,
  };
}
export function saveRelatedProducts(relatedProducts) {
  return {
    type: SAVE_RELATED_PRODUCTS,
    relatedProducts,
  };
}
export function saveMedicines(medicines) {
  return {
    type: SAVE_MEDICINE_BY_PHARMACY_ID,
    medicines,
  };
}
export function saveMedicinesTotal(medicinesTotal) {
  return {
    type: SAVE_TOTAL_MEDICINES,
    medicinesTotal,
  };
}
export function saveOtherProducts(otherProducts) {
  return {
    type: SAVE_OTHERS_BY_PHARMACY_ID,
    otherProducts,
  };
}
export function saveOtherProductsTotal(otherProductsTotal) {
  return {
    type: SAVE_TOTAL_OTHERS,
    otherProductsTotal,
  };
}
export function saveAwaitingProducts(awaitingProducts) {
  return {
    type: SAVE_AWAITING_PRODUCTS_BY_PHARMACY_ID,
    awaitingProducts,
  };
}

export function saveAwaitingProductsTotal(awaitingProductsTotal) {
  return {
    type: SAVE_TOTAL_AWAITING_PRODUCTS,
    awaitingProductsTotal,
  };
}
export function saveAwaitingProduct(awaitingProduct) {
  return {
    type: SAVE_AWAITING_PRODUCT,
    awaitingProduct,
  };
}

export function saveProductBrands(brands) {
  return {
    type: SAVE_PRODUCT_BRANDS,
    brands,
  };
}
export function saveProductIndustries(industries) {
  return {
    type: SAVE_PRODUCT_INDUSTRIES,
    industries,
  };
}

export function saveProductAllowedActions(productAllowedActions) {
  return {
    type: SAVE_PRODUCT_ALLOWED_ACTIONS,
    productAllowedActions,
  };
}
