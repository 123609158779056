import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { debounce } from 'lodash';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectUserLoading,
  makeSelectSalesUserList,
  makeSelectSalesUserListTotal,
} from 'redux/selectors';
import { withRouter } from 'react-router-dom';
import { getSortsString, getDisplayFields } from 'utils/helper';

import {
  salesUserListDisplayFields,
  salesUserListParamsMap,
} from 'utils/constanst/adminUserConstants';
import { getSalesUserList } from 'redux/actions/admin/userActions';

import ListPagination from 'components/ListPagination/ListPagination';
import SalesUserList from 'components/admin/SalesUserList/SalesUserList';
import SalesUserListFilters from 'components/admin/SalesUserList/SalesUserListFilters/SalesUserListFilters';

import classes from './SalesUsers.module.scss';

class SalesUsers extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      listParams: { ...salesUserListParamsMap },
    };
  }
  componentDidMount() {
    const { listParams } = this.state;
    this.props.getSalesUserList({
      params: listParams,
    });
  }

  handleSortChange = ({ sortBy, sortDir }) => {
    const newSortString = getSortsString({
      [sortBy]: sortDir,
    });
    this.handleFilterChange([
      {
        name: 'order',
        value: newSortString,
      },
    ]);
  };
  handleFilterChange = (filters, forceResetPage: false) => {
    const paramsFromFilters = filters.reduce((memo, item) => {
      memo[item.name] = item.value;
      return memo;
    }, {});
    const newParams = forceResetPage ? {
      ...this.state.listParams,
      ...paramsFromFilters,
      page: 1,
    } : {
      ...this.state.listParams,
      ...paramsFromFilters,
    };
    this.setState({
      listParams: newParams,
    });
    this.props.getSalesUserList({
      params: newParams,
    });
  };

  render() {
    const { salesUserList, salesUserListTotal, loading } = this.props;
    const { listParams } = this.state;
    const isListLoading = loading;

    return (
      <React.Fragment>
        <div className={classes.PageWrap}>
          <div className={classes.PageHeader}>
            <h1 className={classes.PageTitle}>Danh sách trình dược viên</h1>
          </div>
          <div className={classes.PageMain}>
            <SalesUserListFilters onFilterChange={debounce(this.handleFilterChange, 500)}/>
            <SalesUserList
              displayFields={getDisplayFields(
                listParams,
                salesUserListDisplayFields
              )}
              salesUserList={salesUserList}
              isLoading={isListLoading}
              onSortChange={this.handleSortChange}
            />
          </div>
          <div className={classes.PageFooter}>
            <ListPagination
              page={listParams.page}
              pageSize={listParams.page_size}
              total={salesUserListTotal}
              listName="trình dược viên"
              onFilterChange={this.handleFilterChange}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  salesUserList: makeSelectSalesUserList(),
  salesUserListTotal: makeSelectSalesUserListTotal(),
  loading: makeSelectUserLoading(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getSalesUserList: (payload) => dispatch(getSalesUserList(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter)(SalesUsers);
