export const GET_UOM_LIST = 'admin/uom/GET_UOM_LIST';
export const GET_UOM_BASE_LIST = 'admin/uom/GET_UOM_BASE_LIST';

export const GET_PRODUCT_UNIT_LIST = 'admin/uom/GET_PRODUCT_UNIT_LIST';
export const UPDATE_PRODUCT_UOM = 'admin/uom/UPDATE_PRODUCT_UOM';

// mutation
export const SAVE_UOM_LIST = 'admin/uom/SAVE_UOM_LIST';
export const SAVE_UOM_BASE_LIST = 'admin/uom/SAVE_UOM_BASE_LIST';
export const SAVE_UOM_TOTAL = 'admin/uom/SAVE_UOM_TOTAL';
export const SAVE_UOM_BASE_TOTAL = 'admin/uom/SAVE_UOM_BASE_TOTAL';

export const SAVE_PRODUCT_UNIT_LIST = 'admin/uom/SAVE_PRODUCT_UNIT_LIST';
export const SAVE_PRODUCT_UOM = 'admin/uom/SAVE_PRODUCT_UOM';
