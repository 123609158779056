import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import QueueIngredientListItem from './QueueIngredientListItem/QueueIngredientListItem';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';

import gridClasses from './QueueIngredientListGrid.module.scss';
import classes from './QueueIngredientList.module.scss';

class QueueIngredientList extends PureComponent {
  render() {
    const { ingredientList, isLoading, displayFields } = this.props;

    return <div
        className={`${classes.List}`}
        style={{
          overflowX: this.rowRef ? 'scroll' : '',
        }}
      >
      <div
        className={`${classes.Header}`}
        ref={(el) => (this.rowRef = el)}
        style={{width: (this.rowRef?.offsetWidth) || 'auto'}}
      >
        <div className={`${gridClasses.Row} ${classes.Header}`}>
          { displayFields.map((item) => (
            <ListHeaderCol
              key={item.name}
              className={gridClasses.Col}
              {...item}
              onSortChange={this.props.onSortChange}
            />
          )) }
          <div className={`${gridClasses.Col} ${classes.ActionsCol}`}></div>
        </div>
      </div>
      <div
        className={`${classes.Body} ${isLoading && 'OverlayLoading'}`}
        style={{width: (this.rowRef?.offsetWidth) || 'auto'}}
      >
        { ingredientList && ingredientList.length
          ? ingredientList.map(item => (
            <QueueIngredientListItem
              key={`ingredient-${item.id}`}
              {...item}
              onDeleteItem={this.props.onDeleteItem}
              onApproveItem={this.props.onApproveItem}
            />))
          : (<p className="NoData">Không có danh mục nào</p>)
        }
      </div>
    </div>;
  }
}

QueueIngredientList.propTypes = {
  displayFields: PropTypes.array,
  ingredientList: PropTypes.array,
  isLoading: PropTypes.bool,
  onSortChange: PropTypes.func,
  onDeleteItem: PropTypes.func,
  onApproveItem: PropTypes.func,
};

QueueIngredientList.defaultProps = {
  displayFields: [],
  ingredientList: [],
  isLoading: false,
};

export default QueueIngredientList;
