import authReducer from './authReducer';
import wsReducer from './wsReducer';
import locationReducer from './locationReducer';
import { combineReducers } from 'redux';
import adminProduct from './admin/productReducer';
import ingredientReducer from './admin/ingredientReducer';
import uomReducer from './admin/uomReducer';
import vendorReducer from './admin/vendorReducer';
import storeReducer from './admin/storeReducer';
import salesChannelReducer from './admin/salesChannelReducer';
import salesTeamReducer from './admin/salesTeamReducer';
import groupReducer from './admin/groupReducer';
import vendorPriceReducer from './admin/vendorPriceReducer';
import policyPriceReducer from './admin/policyPriceReducer';
import vendorProductReducer from './admin/vendorProductReducer';
import productBonusReducer from './admin/productBonusReducer';
import shippingCouponReducer from './admin/shippingCouponReducer';
import testShippingCouponReducer from './admin/testShippingCouponReducer';
import userReducer from './admin/userReducer';
import keyAccountReducer from './admin/keyAccountReducer';
import keyAccountLockReducer from './admin/keyAccountLockReducer';
import coordinaterReducer from './admin/coordinaterReducer';
import getcareCategoryReducer from './admin/getcareCategoryReducer';
import assetBrandingReducer from './admin/assetBrandingReducer';
import assetComponentReducer from './admin/assetComponentReducer';

export default combineReducers({
  authReducer,
  wsReducer,
  locationReducer,
  adminProduct,
  ingredientReducer,
  uomReducer,
  vendorReducer,
  storeReducer,
  salesChannelReducer,
  salesTeamReducer,
  groupReducer,
  vendorPriceReducer,
  policyPriceReducer,
  vendorProductReducer,
  productBonusReducer,
  shippingCouponReducer,
  testShippingCouponReducer,
  userReducer,
  keyAccountReducer,
  keyAccountLockReducer,
  coordinaterReducer,
  getcareCategoryReducer,
  assetBrandingReducer,
  assetComponentReducer,
});
