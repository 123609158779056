import axiosAuth from 'utils/axios/axiosAuth';

const STORE_API_URL = 'getcare_customer/';
const storeApi = {
  get: ({ id, params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`${STORE_API_URL}${id}`, {
      params: queryParams,
    });
  },
  getAll: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(STORE_API_URL, {
      params: queryParams,
    });
  },
  getReason: () => {
    return axiosAuth.get(`getcare_deactive_reason/?object=getcare_customer`);
  },
  updateCustomerReason: ({params}) => {
    return axiosAuth.post(`getcare_customer_action/deactive`, params)
  },
  updateVendorReason: ({params}) => {
    return axiosAuth.post(`getcare_vendor_action/deactive`, params)
  },
  updateStore: ({ id, params }) => {
    return axiosAuth.put(`${STORE_API_URL}${id}`, params);
  },
  createStore: ({ params }) => {
    return axiosAuth.post(STORE_API_URL, params);
  },
  deleteStore: ({ params }) => {
    return axiosAuth({
      method: 'DELETE',
      url: `${STORE_API_URL}${params.id}`,
    });
  },
  getDocuments: ({ id, params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_pharmacy_document/`, {
      params: { ...queryParams, getcare_pharmacy_id: id },
    });
  },
  getApprovalInfo: ({ id }) => {
    const queryParams = id ? { getcare_pharmacy_id: id } : {};
    return axiosAuth.get(`getcare_pharmacy_approval/`, {
      params: { ...queryParams },
    });
  },
  saveApprovalInfo: ({ id, params }) => {
    const formParams = {
      ...params,
      getcare_pharmacy_id: id,
    };
    return axiosAuth.post(`getcare_pharmacy_approval/`, formParams);
  },
  getDeliveryInfo: ({ id }) => {
    const queryParams = id ? { getcare_customer_id: id } : {};
    return axiosAuth.get(`getcare_customer_delivery/`, {
      params: { ...queryParams },
    });
  },
  exportStoresTemplate: () => {
    return axiosAuth.post(`export/getcare_customer_template/`);
  },
  importStores: ({ params }) => {
    const newParams = { ...params };
    const formData = new FormData();
    Object.keys(newParams).forEach((key) => {
      if (newParams[key]) formData.append(key, newParams[key]);
    });

    return axiosAuth.post(`import/getcare_customer`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  getChanges: ({ id, params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_customer_history/`, {
      params: { ...queryParams, getcare_customer_id: id },
    });
  },
};

export default storeApi;
