import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { debounce } from 'lodash';
import {
  getVendorList,
  exportVendorListTemplate,
} from 'redux/actions/admin/vendorActions';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectVendorList,
  makeSelectVendorListLoading,
  makeSelectVendorListTotal,
} from 'redux/selectors';
import {
  listParamsMap,
  listDisplayFields,
} from 'utils/constanst/adminVendorConstants';

import VendorList from 'components/admin/VendorList/VendorList';
import VendorFilters from 'components/admin/VendorList/VendorFilters/VendorFilters';
import ListPagination from 'components/ListPagination/ListPagination';
import FileImportDialog from 'components/admin/VendorList/FileImportDialog/FileImportDialog';
import Button from '@material-ui/core/Button';

import classes from './Vendors.module.scss';

class Vendors extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      listParams: { ...listParamsMap },
      isImportDialogOpen: false,
    };
  }

  componentDidMount() {
    const payload = {
      params: this.state.listParams,
    };
    this.props.getVendorList(payload);
  }

  getSortsMap = (sortStr) => {
    const sortArr = sortStr.split(',');
    return sortArr.reduce((memo, item) => {
      const arr = item.split(' ');
      if (arr[0] && arr[0].trim()) {
        memo[arr[0]] = arr[1] ? arr[1] : '';
      }
      return memo;
    }, {});
  };

  getSortsString = (sortMap) => {
    return Object.keys(sortMap)
      .reduce((memo, key) => {
        memo.push(`${key} ${sortMap[key]}`);
        return memo;
      }, [])
      .join(',');
  };

  getDisplayFields = () => {
    const sortsMap = this.getSortsMap(this.state.listParams.order);
    return listDisplayFields.map((item) => ({
      ...item,
      sortDir: sortsMap[item.name],
    }));
  };

  handleFilterChange = (filters, forceResetPage: false) => {
    const paramsFromFilters = filters.reduce((memo, item) => {
      memo[item.name] = item.value;
      return memo;
    }, {});
    const newParams = forceResetPage
      ? {
          ...this.state.listParams,
          ...paramsFromFilters,
          page: 1,
        }
      : {
          ...this.state.listParams,
          ...paramsFromFilters,
        };
    this.setState({
      listParams: newParams,
    });
    this.props.getVendorList({
      params: newParams,
    });
  };

  handleSortChange = ({ sortBy, sortDir }) => {
    const newSortString = this.getSortsString({ [sortBy]: sortDir });
    this.handleFilterChange([
      {
        name: 'order',
        value: newSortString,
      },
    ]);
  };

  handleDownloadTemplate = () => {
    this.props.exportVendorListTemplate();
  };

  handleImportDialogOpen = () => {
    this.setState({
      isImportDialogOpen: true,
    });
  };

  handleImportDialogClose = () => {
    this.setState({
      isImportDialogOpen: false,
    });
  };

  handleCompleteImport = () => {
    // Reload the page with current settings (e.g.. filters)
    this.props.getVendorList({
      params: this.state.listParams,
    });
  };

  render() {
    const { vendorList, vendorListTotal, isVendorListLoading } = this.props;
    const { listParams, isImportDialogOpen } = this.state;

    return (
      <React.Fragment>
        <div className={classes.PageWrap}>
          <div className={classes.PageHeader}>
            <h1 className={classes.PageTitle}>Nhà cung cấp</h1>
            <div className={classes.PageActions}>
              <Button size="small" variant="contained" onClick={this.handleImportDialogOpen}>
                Import File
              </Button>
              <Button size="small" variant="contained" onClick={this.handleDownloadTemplate}>
                Download template
              </Button>
            </div>
          </div>
          <div className={classes.PageMain}>
            <VendorFilters
              onFilterChange={debounce(this.handleFilterChange, 50)}
            />
            <VendorList
              displayFields={this.getDisplayFields()}
              vendorList={vendorList}
              isLoading={isVendorListLoading}
              onSortChange={this.handleSortChange}
            />
          </div>
          <div className={classes.PageFooter}>
            <ListPagination
              page={listParams.page}
              pageSize={listParams.page_size}
              total={vendorListTotal}
              listName="nhà cung cấp"
              onFilterChange={this.handleFilterChange}
            />
          </div>
        </div>
        {isImportDialogOpen && (
          <FileImportDialog
            title="Import danh sách nhà cung cấp"
            isOpen={isImportDialogOpen}
            onClose={this.handleImportDialogClose}
            onCompleteImport={this.handleCompleteImport}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  vendorList: makeSelectVendorList(),
  vendorListTotal: makeSelectVendorListTotal(),
  isVendorListLoading: makeSelectVendorListLoading(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getVendorList: (payload) => dispatch(getVendorList(payload)),
    exportVendorListTemplate: (payload) =>
      dispatch(exportVendorListTemplate(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(Vendors);
