import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Switch from '@material-ui/core/Switch';

import classes from './KeyAccountProductItem.module.scss';
import gridClasses from '../KeyAccountProductListGrid.module.scss';

class KeyAccountProductItem extends PureComponent {
  handleActiveChange = (e) => {
    this.props.onSaveKeyAccountProductItem({
      ...this.props,
      idStr: this.props.idStr,
      id: this.props.id,
      active: e.target.checked,
    });
  }

  render() {
    const { getcare_product, active } = this.props;

    return (
      <>
        <div className={`${classes.Item} ${gridClasses.Row}`}>
          <div className={`${gridClasses.Col}`}>
            { getcare_product?.getcare_id }
          </div>
          <div className={`${gridClasses.Col}`}>
            { getcare_product?.name }
          </div>
          <div className={`${gridClasses.Col} TextCenter`}>
            <Switch
              size="small"
              checked={active}
              onChange={this.handleActiveChange}
              color="primary"
              name="status"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </div>
        </div>
      </>
    );
  }
}

KeyAccountProductItem.propTypes = {
  idStr: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onSaveKeyAccountProductItem: PropTypes.func,
  onRemoveKeyAccountProductItem: PropTypes.func,
}

KeyAccountProductItem.defaultProps = {
};

export default KeyAccountProductItem;
