import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { listDisplayFields } from 'utils/constanst/adminIngredientConstants';

import gridClasses from '../IngredientListGrid.module.scss';
import classes from './IngredientListItem.module.scss';

class IngredientListItem extends PureComponent {
  render() {
    return <div className={`${classes.ProductItem} ${gridClasses.Row}`}>
      { listDisplayFields.map(item => (
          <div
            key={item.name}
            className={gridClasses.Col}
          >
            { this.props[item.name] }
          </div>
        )) }
    </div>;
  }
}

IngredientListItem.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

IngredientListItem.defaultProps = {
};

export default IngredientListItem;
