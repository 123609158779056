import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { removeAccents } from 'utils/helper';

import classes from './SalesChannelSelect.module.scss';

class SalesChannelSelect extends PureComponent {
  constructor(props) {
    super(props);
    this.validateCode = {
      template: /^[a-zA-Z0-9\-_\s]*$/,
      required: true,
      maxLength: 20,
      duplicated: false,
    };
    this.state = {
      inputValue: '',
      error: null,
    };
  }

  componentDidMount() {
    const selectedChannel = this._getSelectedChannel();
    if (!selectedChannel) return;
    this.setState({
      inputValue: selectedChannel.code,
    });
  }

  handleChange = (e, newValue) => {
    if (!newValue || !newValue.id) return;
    this.props.onSelectedIdChange(newValue.id);
  }

  handleInputChange = (e, newValue) => {
    if (this.props.isEditing) {
      this.setState({
        inputValue: newValue,
      });
      return;
    }
    const value = this.preprocessInputValue(newValue);
    this.setState({
      inputValue: value,
    });
    this.props.onCreateNewCode({
      newCode: value.trim(),
      isNewCodeValid: this.isInputValueValid(value),
    });
  }
  preprocessInputValue = (value) => {
    return removeAccents(value).toUpperCase();
  }
  isNotEmpty = (value) => {
    return value.trim() !== '';
  }
  isTemplateValid = (value) => {
    return this.validateCode.template.test(value);
  }
  isMaxlengthValid = (value) => {
    return value.trim().length <= this.validateCode.maxLength;
  }
  isNotDuplicated = (value) => {
    return !this.props.list.some(item => (item.id !== this.props.selectedId) && item.code.toLowerCase() === value.toLowerCase());
  }
  isInputValueValid = (valueStr) => {
    const isNotEmpty = this.isNotEmpty(valueStr);
    const isTemplateValid = this.isTemplateValid(valueStr);
    const isMaxlengthValid = this.isMaxlengthValid(valueStr);
    const isNotDuplicated = this.isNotDuplicated(valueStr);
    this.setState({
      error: {
        template: !isTemplateValid,
        required: !isNotEmpty,
        maxLength: !isMaxlengthValid,
        duplicated: !isNotDuplicated,
      }
    });
    return isNotEmpty && isTemplateValid && isMaxlengthValid && isNotDuplicated;
  }

  _getSelectedChannel = () => {
    const { selectedId, list } = this.props;
    if (!selectedId || !list) return undefined;
    return list.find(item => item.id.toString() === selectedId.toString());
  }

  render() {
    const { list, isEditing } = this.props;
    const { inputValue, error } = this.state;
    const selectedChannel = this._getSelectedChannel();
    const isError = !isEditing && error && (error.required || error.template || error.maxLength || error.duplicated);

    return (<>
      <div className={classes.FieldControl}>
        <label>Mã khu vực <span className={classes.RequiredMark}>*</span></label>
        <Autocomplete
          freeSolo={!isEditing}
          handleHomeEndKeys={false}
          value={selectedChannel || null}
          onChange={this.handleChange}
          inputValue={inputValue}
          onInputChange={this.handleInputChange}
          id="combo-box-channels"
          options={(isEditing && list) ? list : []}
          getOptionLabel={(option) => option.code}
          getOptionSelected={(option, value) => option.id === value.id}
          renderInput={(params) => <TextField {...params} error={isError} />}
        />
        {
          !isEditing &&
          <ul className={classes.HelpText}>
            <li className={(error && error.required) ? classes.Error : ''}>- Không để trống</li>
            <li className={(error && error.template) ? classes.Error : ''}>- Chấp nhận chữ, số, khoảng trắng, underscore</li>
            <li className={(error && error.maxLength) ? classes.Error : ''}>- Tối đa 20 ký tự</li>
            <li className={(error && error.duplicated) ? classes.Error : ''}>- Không được trùng với các mã khu vực đã có</li>
          </ul>
         }
      </div>
    </>);
  }
}

SalesChannelSelect.propTypes = {
  isEditing: PropTypes.bool,
  list: PropTypes.array,
  selectedId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onSelectedIdChange: PropTypes.func,
  onCreateNewCode: PropTypes.func,
};

SalesChannelSelect.defaultProps = {
  list: [],
  isEditing: true,
};

export default SalesChannelSelect;
