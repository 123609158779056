import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { isAfter } from 'date-fns';
import { debounce } from 'lodash';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectUserDetails,
  makeSelectDepartmentList,
  makeSelectVendorList,
  makeSelectStoreList,
} from 'redux/selectors';
import { getDepartmentList, saveUserDetails } from 'redux/actions/admin/userActions';
import { getVendorList } from 'redux/actions/admin/vendorActions';
import { getStoreList } from 'redux/actions/admin/storeActions';
import {
  userApprovalMap,
  STATUS_WORKING,
  TYPE_SYSTEM,
  userTypes,
  isVendorType,
  userGenders,
  userStatuses,
  getStatus,
  isCustomerType,
} from 'utils/constanst/adminUserConstants';
import { allInPageSize, suggestionPageSize2 } from 'utils/constanst/common';
import { validDate } from 'utils/helper';
import { dateFormat } from 'utils/constanst/dateConstants';

import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import classes from './UserDetails.module.scss';

class UserDetails extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedUserType: '',
      customerNameInput: '',
    }
  }
  componentDidMount() {
    this._loadDepartmentList();
    this._loadVendorList();
    this._loadCustomerList({
      customerNameInput: '',
    });
    this._initState();
  }
  componentDidUpdate(prevProps, prevState) {
    const { userDetails } = this.props;

    if (userDetails && userDetails.id && (!prevProps.userDetails || userDetails.id !== prevProps.userDetails.id)) {
      this._initState();
    }

    const { customerNameInput } = this.state;
    if (customerNameInput !== prevState.customerNameInput) {
      this._loadCustomerList({
        keyword: customerNameInput,
      });
    }
  }

  _initState = () => {
    const { userDetails } = this.props;
    this.setState({
      selectedUserType: userDetails?.user_type || TYPE_SYSTEM,
    });
  }
  _loadDepartmentList = () => {
    this.props.getDepartmentList({
      params: { page_size: allInPageSize },
    });
  }
  _loadVendorList = () => {
    this.props.getVendorList({
      params: {
        page_size: allInPageSize,
        mode: 'CREATE_USER',
      },
    });
  }
  _loadCustomerList = debounce((params) => {
    this.props.getStoreList({
      params: {
        ...params,
        page_size: suggestionPageSize2,
      },
    });
  }, 100);

  _getValidationShape = () => {
    const { selectedUserType } = this.state;
    return {
      status: Yup.string().required("Vui lòng nhập"),
      name: Yup.string().required("Vui lòng nhập").max(55, 'Tối đa 55 ký tự'),
      other_name: Yup.string().max(55, 'Tối đa 55 ký tự'),
      phone: Yup.string().required("Vui lòng nhập").max(55, 'Tối đa 55 ký tự'),
      email: Yup.string().matches(/^((?!\s).)*$/, 'Email không được có khoảng trắng').max(55, 'Tối đa 55 ký tự'),
      user_type: Yup.string().required("Vui lòng chọn"),
      getcare_vendor_id: isVendorType(selectedUserType) ? Yup.string().required("Vui lòng chọn") : undefined,
      getcare_customer_id: isCustomerType(selectedUserType) ? Yup.string().required("Vui lòng chọn") : undefined,
      address: Yup.string().max(255, 'Tối đa 255 ký tự'),
      birthday: Yup.mixed().test(
        'birthday',
        'Ngày sinh không hợp lệ',
        (value, context) => ['', null, undefined].includes(value) || (!!validDate(value) && !isAfter(validDate(value), new Date())),
      ),
    };
  }
  _getInitialValues = () => {
    const { userDetails } = this.props;
    return {
      statusMap: getStatus(userDetails?.status || STATUS_WORKING),
      status: userDetails?.status || STATUS_WORKING,
      name: userDetails?.name || '',
      other_name: userDetails?.other_name || '',
      birthday: userDetails?.birthday || '',
      phone: userDetails?.phone || '',
      gender: userDetails?.gender || '',
      email: userDetails?.email || '',
      address: userDetails?.address || '',
      user_type: userDetails?.user_type || TYPE_SYSTEM,
      getcare_vendor: userDetails?.getcare_vendor || null,
      getcare_vendor_id: userDetails?.getcare_vendor_id || '',
      getcare_customer: userDetails?.getcare_customer || null,
      getcare_customer_id: userDetails?.getcare_customer_id || '',
      getcare_department: userDetails?.getcare_department || null,
      getcare_department_id: userDetails?.getcare_department_id || '',
      approval: userDetails?.approval || false,
    }
  }

  handleChange = (fieldMap) => {
    this.props.saveUserDetails({
      ...this.props.userDetails,
      ...fieldMap,
    });
  }
  handleSubmit = (values) => {
    this.props.onSubmit(values);
  }

  render() {
    const {
      userDetails,
      isEditing,
      vendorList,
      departmentList,
      storeList,
    } = this.props;

    return (
      <Formik
        innerRef={this.props.formRef}
        initialValues={this._getInitialValues()}
        onSubmit={this.handleSubmit}
        validationSchema={Yup.object().shape(this._getValidationShape())}
      >
        {(props) => {
          const { values, errors, handleChange, setFieldValue } = props;

          return (
            <form noValidate autoComplete="off">
              <div className={`${classes.Details} ${isEditing ? classes.IsEditing : ''}`}>

                <div className={classes.DetailsCol}>
                  { isEditing &&
                    <div className={classes.FieldControl}>
                      <label>Mã nhân viên</label>
                      { userDetails?.code }
                    </div>
                  }
                  <div className={classes.FieldControl}>
                    <label>Tên nhân viên *</label>
                    <TextField
                      autoComplete="off"
                      value={values.name}
                      placeholder="Nhập..."
                      autoFocus={true}
                      name="name"
                      error={!!errors.name}
                      helperText={errors.name}
                      onChange={(e) => {
                        handleChange(e);
                        this.handleChange({'name': e.target.value});
                      }}
                    />
                  </div>

                  <div className={classes.FieldControl}>
                    <label>Điện thoại *</label>
                    <TextField
                      autoComplete="off"
                      value={values.phone}
                      placeholder="Nhập..."
                      name="phone"
                      error={!!errors.phone}
                      helperText={errors.phone}
                      onChange={(e) => {
                        handleChange(e);
                        this.handleChange({'phone': e.target.value});
                      }}
                    />
                  </div>

                  <div className={classes.FieldControl}>
                    <label>Email</label>
                    <TextField
                      autoComplete="off"
                      value={values.email}
                      placeholder="Nhập..."
                      name="email"
                      error={!!errors.email}
                      helperText={errors.email}
                      onChange={(e) => {
                        handleChange(e);
                        this.handleChange({'email': e.target.value});
                      }}
                    />
                  </div>

                  <div className={classes.FieldControl}>
                    <label>Thuộc *</label>
                    <RadioGroup
                      name="user_type"
                      value={values.user_type}
                      className={`${classes.Field} ${classes.RadioGroup}`}
                      onChange={(e) => {
                        const typeValue = parseFloat(e.target.value);
                        this.setState({ selectedUserType: typeValue });
                        setFieldValue('user_type', typeValue);

                        let fieldMap = {
                          'user_type': typeValue,
                        };
                        if (isVendorType(typeValue) && !values.getcare_vendor && vendorList.length > 0) {
                          const defaultVendor = { ...vendorList[0] };
                          setFieldValue('getcare_vendor_id', defaultVendor.id);
                          setFieldValue('getcare_vendor', defaultVendor);
                          fieldMap = {
                            ...fieldMap,
                            'user_type': typeValue,
                            'getcare_vendor_id': defaultVendor.id,
                            'getcare_vendor': defaultVendor,
                          };
                        }
                        this.handleChange(fieldMap);
                      }}
                    >
                      { userTypes.map(type => (
                        <FormControlLabel
                          key={type.id}
                          value={type.id}
                          control={<Radio size="small" style={{padding: '4px 0', marginRight: '12px'}} />}
                          fontSize="small"
                          className={classes.FormControlLabel}
                          label={type.name}
                        />
                      )) }
                    </RadioGroup>
                  </div>
                  <div className={classes.FieldControl}>
                    <label>Trạng thái duyệt</label>
                    <div>
                      <FormControlLabel
                        control={<Checkbox name="approval" checked={values.approval} onChange={(e) => {
                          setFieldValue(e.target.name,e.target.checked,false)
                          this.handleChange({[e.target.name]: e.target.checked});
                        }} />}
                        label={userApprovalMap[values.approval]}
                      />
                    </div>
                  </div>

                  { isVendorType(values.user_type) &&
                    <div className={classes.FieldControl}>
                      <label>Nhà cung cấp *</label>
                      <Autocomplete
                        disableClearable
                        name="vendor"
                        handleHomeEndKeys={false}
                        value={values.getcare_vendor}
                        options={vendorList || []}
                        getOptionLabel={(option) => option?.name || ''}
                        renderOption={(option) => option?.name}
                        getOptionSelected={(option, value) => option.id === value.id}
                        renderInput={(params) => (
                          <TextField
                            placeholder="Chọn..."
                            {...params}
                            error={!!errors.getcare_vendor_id}
                            helperText={errors.getcare_vendor_id}
                          />
                        )}
                        onChange={(e, newValue) => {
                          setFieldValue('getcare_vendor_id', newValue?.id || '');
                          setFieldValue('getcare_vendor', newValue || null);
                          this.handleChange({
                            'getcare_vendor_id': newValue?.id || '',
                            'getcare_vendor': newValue || null
                          });
                        }}
                      />
                    </div>
                  }

                  { isCustomerType(values.user_type) &&
                    <div className={classes.FieldControl}>
                      <label>Khách hàng *</label>
                      <Autocomplete
                        disableClearable
                        name="customer"
                        handleHomeEndKeys={false}
                        value={values.getcare_customer}
                        options={storeList || []}
                        getOptionLabel={(option) => option ? `${option.code} - ${option.name}` : ''}
                        renderOption={(option) => option ? `${option.code} - ${option.name}` : ''}
                        getOptionSelected={(option, value) => option.id === value.id}
                        renderInput={(params) => (
                          <TextField
                            placeholder="Chọn..."
                            {...params}
                            error={!!errors.getcare_customer_id}
                            helperText={errors.getcare_customer_id}
                          />
                        )}
                        onInputChange={(e, newInputValue) => {
                          this.setState({
                            customerNameInput: newInputValue,
                          });
                        }}
                        filterOptions={(x) => x}
                        onChange={(e, newValue) => {
                          setFieldValue('getcare_customer', newValue);
                          setFieldValue('getcare_customer_id', newValue?.id);
                          setFieldValue('address', newValue?.address);
                          this.handleChange({
                            'getcare_customer': newValue,
                            'getcare_customer_id': newValue?.id,
                            'address': newValue?.address,
                          });
                        }}
                      />
                    </div>
                  }
                </div>

                <div className={classes.DetailsCol}>
                  { isEditing &&
                    <div className={classes.FieldControl}>
                      <label>Trạng thái</label>
                      <Autocomplete
                        disableClearable
                        name="status"
                        handleHomeEndKeys={false}
                        value={values.statusMap || null}
                        options={userStatuses || []}
                        getOptionLabel={(option) => option?.name || ''}
                        renderOption={(option) => option?.name}
                        getOptionSelected={(option, value) => option.id === value.id}
                        renderInput={(params) => (
                          <TextField
                            placeholder="Chọn..."
                            {...params}
                            error={!!errors.status}
                            helperText={errors.status}
                          />
                        )}
                        onChange={(e, newValue) => {
                          setFieldValue('status', newValue?.id || '');
                          setFieldValue('statusMap', newValue || null);
                          this.handleChange({
                            'status': newValue?.id || '',
                            'status_map': newValue || null
                          });
                        }}
                      />
                    </div>
                  }
                  <div className={classes.FieldControl}>
                    <label>Tên thường gọi</label>
                    <TextField
                      autoComplete="off"
                      value={values.other_name}
                      placeholder="Nhập..."
                      name="other_name"
                      error={!!errors.other_name}
                      helperText={errors.other_name}
                      onChange={(e) => {
                        handleChange(e);
                        this.handleChange({'other_name': e.target.value});
                      }}
                    />
                  </div>

                  <div className={classes.FieldControl}>
                    <label>Phòng ban</label>
                    <Autocomplete
                      name="department"
                      handleHomeEndKeys={false}
                      value={values.getcare_department || null}
                      options={departmentList || []}
                      getOptionLabel={(option) => option?.name || ''}
                      renderOption={(option) => option?.name}
                      getOptionSelected={(option, value) => option.id === value.id}
                      renderInput={(params) => (
                        <TextField
                          placeholder="Chọn..."
                          {...params}
                        />
                      )}
                      onChange={(e, newValue) => {
                        setFieldValue('getcare_department_id', newValue?.id || '');
                        setFieldValue('getcare_department', newValue || null);
                        this.handleChange({
                          'getcare_department_id': newValue?.id || '',
                          'getcare_department': newValue || null
                        });
                      }}
                    />
                  </div>

                  <div className={classes.FieldControl}>
                    <label>Ngày sinh</label>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableFuture
                        disableToolbar
                        autoOk
                        variant="inline"
                        format={dateFormat}
                        name="birthday"
                        error={!!errors.birthday}
                        helperText={errors.birthday}
                        value={values.birthday || null}
                        onChange={(date, newValue) => {
                          const value = validDate(date) ? date.toISOString() : newValue;
                          setFieldValue('birthday', value);
                          this.handleChange({'birthday': value});
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  <div className={classes.FieldControl}>
                    <label>Giới tính</label>
                    <RadioGroup
                      name="gender"
                      value={values.gender + ``}
                      className={`${classes.Field} ${classes.RadioGroup}`}
                      onChange={(e) => {
                        setFieldValue('gender', e.target.value);
                        this.handleChange({'gender': e.target.value});
                      }}
                    >
                      { userGenders.map(gender => (
                        <FormControlLabel
                          key={gender.id}
                          value={gender.id}
                          control={<Radio size="small" style={{padding: '4px 0', marginRight: '12px'}} />}
                          fontSize="small"
                          className={classes.FormControlLabel}
                          label={gender.name}
                        />
                      )) }
                    </RadioGroup>
                    { errors?.gender && <span className="TextDanger">{ errors?.gender }</span> }
                  </div>
                  <div className={classes.FieldControl}>
                    <label>Địa chỉ</label>
                    <TextField
                      multiline
                      rowsMax={3}
                      autoComplete="off"
                      value={values.address}
                      placeholder="Nhập..."
                      name="address"
                      error={!!errors.address}
                      helperText={errors.address}
                      onChange={(e) => {
                        handleChange(e);
                        this.handleChange({'address': e.target.value});
                      }}
                    />
                  </div>
                </div>

              </div>
            </form>
          );
        }}
      </Formik>
    );
  }
}

UserDetails.propTypes = {
  isEditing: PropTypes.bool,
  isValid: PropTypes.bool,
  onSubmit: PropTypes.func,
};

UserDetails.defaultProps = {
  isEditing: false,
  isValid: true,
};

const mapStateToProps = createStructuredSelector({
  userDetails: makeSelectUserDetails(),
  vendorList: makeSelectVendorList(),
  departmentList: makeSelectDepartmentList(),
  storeList: makeSelectStoreList(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    saveUserDetails: (payload) => dispatch(saveUserDetails(payload)),
    getDepartmentList: (payload) => dispatch(getDepartmentList(payload)),
    getVendorList: (payload) => dispatch(getVendorList(payload)),
    getStoreList: (payload) => dispatch(getStoreList(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter)(UserDetails);
