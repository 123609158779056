export const listDisplayFields = [  
  {
    name: 'id',
    // authorize_name: 'id',
    label: 'Location ID',
    sortable: true,
    sortDir: 'desc',
  },
  {
    name: 'name',
    // authorize_name: 'id',
    label: 'Name',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'code',
    // authorize_name: 'id',
    label: 'Code',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'link',
    // authorize_name: 'id',
    label: 'Link',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'font',
    // authorize_name: 'id',
    label: 'Font',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'font_size',
    // authorize_name: 'id',
    label: 'Font size',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'platform',
    // authorize_name: 'id',
    label: 'Platform',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'width',
    // authorize_name: 'id',
    label: 'Width',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'height',
    // authorize_name: 'id',
    label: 'Height',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'description',
    // authorize_name: 'id',
    label: 'Description',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'vendor_name',
    // authorize_name: 'id',
    label: 'Vendor',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'action',
    // authorize_name: 'id',
    label: '',
    sortable: false,
    sortDir: '',
  },
]
export const listParamsMap = {
  page_size: 50,
  page: 1,
  order: 'id desc'
}
export const filterFields = [
  {
    name: 'name',
    label: 'Name',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'code',
    label: 'Code',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'getcare_vendor_id',
    label: 'Vendor',
    value: '',
    type: 'custom',
    placeholder: 'Nhập...',
  },
]