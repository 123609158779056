import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import SalesTeamListItem from './SalesTeamListItem/SalesTeamListItem';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';

import gridClasses from './SalesTeamListGrid.module.scss';
import classes from './SalesTeamList.module.scss';

class SalesTeamList extends PureComponent {
  render() {
    const { salesTeamList, isLoading, displayFields } = this.props;

    return <div className={`${classes.List} ${isLoading && 'OverlayLoading'}`}>
      <div className={`${classes.Body}`}>
        <div className={`${gridClasses.Row} ${classes.Header}`}>
          { displayFields.map((item, index) => (
            <ListHeaderCol
              key={`${item.name}-${index}`}
              className={gridClasses.Col}
              {...item}
              onSortChange={this.props.onSortChange}
            />
          )) }
        </div>
      
        { !isLoading && salesTeamList.length
          ? salesTeamList.map(item => (
            <SalesTeamListItem
              key={`medicine-${item.id}`}
              {...item}
            />))
          : (<p className="NoData">Không có data</p>)
        }
      </div>
    </div>;
  }
}

SalesTeamList.propTypes = {
  displayFields: PropTypes.array,
  salesTeamList: PropTypes.array,
  isLoading: PropTypes.bool,
  onSortChange: PropTypes.func,
};

SalesTeamList.defaultProps = {
  displayFields: [],
  salesTeamList: [],
  isLoading: false,
};

export default SalesTeamList;
