import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Panel from 'components/Panel/Panel';
import StoreInfo from './StoreInfo/StoreInfo';
import StoreInfoChanges from './StoreInfoChanges/StoreInfoChanges';
import StoreApprovalStatus from './StoreApprovalStatus/StoreApprovalStatus';
import StoreDocuments from './StoreDocuments/StoreDocuments';
import StoreDeliveryInfo from './StoreDeliveryInfo/StoreDeliveryInfo';
import StoreImages from './StoreImages/StoreImages';

import classes from './StoreDetails.module.scss';

class StoreDetails extends PureComponent {
  render() {
    const {
      storeDetails,
      isCreateAllowed,
      isEditAllowed,
      isApproveAllowed,
      editMode,
      setStoreChangeRef,
      onDataUpdated,
      ...others
    } = this.props;
    const storeId = storeDetails?.getcare_pharmacy_id;

    return (
      <div className={classes.StoreDetails}>
        <StoreInfo
          {...storeDetails}
          editMode={editMode}
          isCreateAllowed={isCreateAllowed}
          isEditAllowed={isEditAllowed}
          onDataUpdated={onDataUpdated}
          {...others}
        />
        {editMode && (
          <Panel
            title="Trạng thái duyệt"
            panelClassName={classes.Panel}
            contentClassName={classes.PanelContent}
            content={
              <StoreApprovalStatus
                approval={storeDetails?.approval}
                isTrial={storeDetails?.is_trial}
                storeId={storeId}
                storeName={storeDetails?.name}
                isApproveAllowed={isApproveAllowed}
                onDataUpdated={onDataUpdated}
              />
            }
            size="sm"
            isBorder
          />
        )}
        {editMode && (
          <Panel
            title="Lịch sử thay đổi"
            panelClassName={classes.Panel}
            contentClassName={classes.PanelContent}
            content={
              <StoreInfoChanges
                storeId={storeDetails?.id}
                setStoreChangeRef={setStoreChangeRef}
              />
            }
            size="sm"
            isBorder
          />
        )}
        <Panel
          title="Hình ảnh khách hàng"
          panelClassName={classes.Panel}
          contentClassName={classes.PanelContent}
          content={
            <StoreImages customerImages={storeDetails?.getcare_customer_images ? [...storeDetails.getcare_customer_images] : []} />
          }
          size="sm"
          isBorder
        />
        <Panel
          title="Tài liệu liên quan"
          panelClassName={classes.Panel}
          contentClassName={classes.PanelContent}
          content={
            <StoreDocuments storeId={storeId} />
          }
          size="sm"
          isBorder
        />
        {editMode && (
          <Panel
            title="Địa chỉ nhận hàng1"
            panelClassName={classes.Panel}
            contentClassName={classes.PanelContent}
            content={
              <StoreDeliveryInfo
                storeId={storeId}
                customerCode={storeDetails?.code}
                isEditAllowed={isEditAllowed}
              />
            }
            size="sm"
            isBorder
          />
        )}
      </div>
    )
  }
}

StoreDetails.propTypes = {
  storeDetails: PropTypes.object,
};

StoreDetails.defaultProps = {
  storeDetails: null,
};

export default StoreDetails;
