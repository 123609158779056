import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getMedicineByPharmacyId } from 'redux/actions/admin/productActions';
import { createStructuredSelector } from "reselect";
import {
  makeSelectMedicines,
  makeSelectMedicinesTotal,
} from 'redux/selectors';
import { medicineListParamsMap, medicineListDisplayFields } from 'utils/constanst/adminProductConstants';

import MedicineListItem from './MedicineList/MedicineListItem';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';
import ListPagination from 'components/ListPagination/ListPagination';

import gridClasses from './MedicineListGrid.module.scss';
import classes from './MedicineList.module.scss';

class MedicineList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      listParams: { ...medicineListParamsMap },
      isLoading: false,
    }
  }

  componentDidMount() {
    this.loadData({ params: this.state.listParams });
  }

  componentDidUpdate(prevProps) {
    const { medicines, pharmacyId } = this.props;
    if (medicines !== prevProps.medicines && !prevProps.medicines) {
      this.setState({
        isLoading: false,
      });
    }
    if (pharmacyId !== prevProps.pharmacyId) {
      this.setState({
        listParams: { ...medicineListParamsMap }
      });
      this.loadData({
        params: { ...medicineListParamsMap },
      });
    }
  }

  loadData = ({ params }) => {
    const { pharmacyId } = this.props;
    if (!pharmacyId) return;
    const payload = {
      params: {...params},
      pharmacyId: pharmacyId,
    };
    this.setState({
      isLoading: true,
    });
    this.props.getMedicineByPharmacyId(payload);
  }

  getDisplayFields = () => {
    const sortsMap = this.getSortsMap(this.state.listParams.order);
    return medicineListDisplayFields.map(item => ({
      ...item,
      sortDir: sortsMap[item.name],
    }));
  }

  getSortsMap = (sortStr) => {
    const sortArr = sortStr.split(',');
    return sortArr.reduce((memo, item) => {
      const arr = item.split(' ');
      if (arr[0] && arr[0].trim()) {
        memo[arr[0]] = arr[1] ? arr[1] : '';
      }
      return memo;
    }, {});
  }

  getSortsString = (sortMap) => {
    return Object.keys(sortMap).reduce((memo, key) => {
      memo.push(`${key} ${sortMap[key]}`);
      return memo;
    }, []).join(',');
  }

  handleSortChange = ({ sortBy, sortDir }) => {
    const newSortString = this.getSortsString({[sortBy]: sortDir});
    this.handleFilterChange([{
      name: 'order',
      value: newSortString,
    }]);
  }

  handleFilterChange = (filters, forceResetPage: false) => {
    const paramsFromFilters = filters.reduce((memo, item) => {
      memo[item.name] = item.value;
      return memo;
    }, {});
    const newParams = forceResetPage ? {
      ...this.state.listParams,
      ...paramsFromFilters,
      page: 1,
    } : {
      ...this.state.listParams,
      ...paramsFromFilters,
    };
    this.setState({
      listParams: newParams,
    });
    this.loadData({ params: this.state.listParams });
  }

  render() {
    const { medicines, medicinesTotal } = this.props;
    const { isLoading, listParams } = this.state;
    const displayFields = this.getDisplayFields();

    return <div className={classes.ListWrap}>
      <div className={`${classes.List}`}>
        <div className={`${classes.Body} ${isLoading && 'OverlayLoading'}`}>
          <div className={`${gridClasses.Row} ${classes.Header}`}>
            { displayFields.map((item, index) => (
              <ListHeaderCol
                key={`${item.name}-${index}`}
                className={gridClasses.Col}
                {...item}
                onSortChange={this.handleSortChange}
              />
            )) }
          </div>
        
          { (medicines && medicines.length)
            ? medicines.map(item => (
              <MedicineListItem
                key={`medicine-${item.id}`}
                {...item}
              />))
            : (<p className="NoData">Không có data</p>)
          }
        </div>
      </div>
      <div className={classes.Footer}>
        <ListPagination
          page={listParams.page}
          pageSize={listParams.page_size}
          listName="sản phẩm"
          total={medicinesTotal}
          onFilterChange={this.handleFilterChange}
        />
      </div>
    </div>;
  }
}

MedicineList.propTypes = {
  pharmacyId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

MedicineList.defaultProps = {
};

const mapStateToProps = createStructuredSelector({
  medicines: makeSelectMedicines(),
  medicinesTotal: makeSelectMedicinesTotal(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getMedicineByPharmacyId: (payload) => dispatch(getMedicineByPharmacyId(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(MedicineList);
