export const ROLE_LEADER = 1;
export const ROLE_COORNIDATOR = 2;
export const coordinaterRolesMap = {
  [ROLE_LEADER]: 'Leader',
  [ROLE_COORNIDATOR]: 'Điều phối viên',
};
export const coordinaterRoles = [
  {
    id: ROLE_LEADER,
    name: coordinaterRolesMap[ROLE_LEADER],
  },
  {
    id: ROLE_COORNIDATOR,
    name: coordinaterRolesMap[ROLE_COORNIDATOR],
  },
];

export const listDisplayFields = [
  {
    name: 'code',
    label: 'Mã nhân viên',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'name',
    label: 'Tên nhân viên',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'role',
    label: 'Vị trí',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'phone',
    label: 'Điện thoại',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'email',
    label: 'Email',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'getcare_department_name',
    label: 'Phòng ban',
    sortable: true,
    sortDir: '',
  },
]
export const listParamsMap = {
  page_size: 50,
  page: 1,
  order: '',
  code: '',
  name: '',
  phone: '',
}
export const filterFields = [
  {
    name: 'code',
    label: 'Mã nhân viên',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'name',
    label: 'Tên nhân viên',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'phone',
    label: 'Điện thoại',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
]

// allowed actions 
export const CREATE_ACTION_CODE = `create`;
export const EDIT_ACTION_CODE = `edit`;
