import { allInPageSize } from 'utils/constanst/common';

export const ACTIVE = 1;
export const INACTIVE = -1;
export const DEFAULT_MEMBER_ROLE_ID = 4; // Nhan vien kinh doanh
export const OTHER_SALES_ROLE = 6;
export const statusMap = {
  [ACTIVE]: 'Đang hoạt động',
  [INACTIVE]: 'Ngừng hoạt động',
};
export const otherSalesRoleMap = {
  [OTHER_SALES_ROLE]: 'Khác',
};
export const listDisplayFields = [
  {
    name: 'code',
    label: 'Mã sales team',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'name',
    label: 'Sales team',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'rsm_names',
    label: 'RSM',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'asm_names',
    label: 'ASM',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'sales_sup_names',
    label: 'Sales Sup',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'sales_names',
    label: 'Nhân viên kinh doanh',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'sales_channel_teams',
    label: 'Khu vực phụ trách',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'key_accounts',
    label: 'Key accounts',
    sortable: false,
    sortDir: '',
  },
];
export const listParamsMap = {
  page_size: 50,
  page: 1,
  order: '',
  active: ACTIVE,
};
export const statusDropdownList = [
  {
    value: '',
    label: 'All',
  },
  {
    value: ACTIVE,
    label: statusMap[ACTIVE],
  },
  {
    value: INACTIVE,
    label: statusMap[INACTIVE],
  },
];

export const memberListParamsMap = {
  page_size: allInPageSize,
  page: 1,
  order: '',
};
export const memberListDisplayFields = [
  {
    name: 'getcare_sales_name',
    label: 'Tên NV',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'getcare_sales_code',
    label: 'Mã NV',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'getcare_sales_role_name',
    label: 'Vị trí',
    sortable: false,
    sortDir: '',
  },
];

export const channelListParamsMap = {
  page_size: allInPageSize,
  page: 1,
  order: '',
};
export const channelListDisplayFields = [
  {
    name: 'sales_channel_code',
    label: 'Khu vực bán hàng',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'sales_channel_coordinator_names',
    label: 'Người điều phối KVBH',
    sortable: false,
    sortDir: '',
  },
];

export const keyAccountListParamsMap = {
  page_size: allInPageSize,
  page: 1,
  order: '',
};
export const keyAccountListDisplayFields = [
  {
    name: 'code',
    label: 'Mã KH',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'name',
    label: 'Tên KH',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'province_name',
    label: 'Tỉnh/TP',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'district_name',
    label: 'Quận/Huyện',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'ward_name',
    label: 'Phường/Xã',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'address',
    label: 'Địa chỉ',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'sales_name',
    label: 'Phụ trách bởi',
    sortable: false,
    sortDir: '',
  },
];
