import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';

import classes from './DeleteGroupBtns.module.scss';

class DeleteGroupBtns extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isMoreActionsOpen: false,
    }
    this.moreActionsMenuRef = React.createRef();
  }

  handleToggleDropdown = () => {
    this.setState({
      isMoreActionsOpen: !this.state.isMoreActionsOpen,
    });
  };

  handleCloseDropdown = () => {
    this.setState({
      isMoreActionsOpen: false,
    });
  };

  render() {
    const { isMoreActionsOpen } = this.state;

    return (<div>
      <Button
        ref={this.moreActionsMenuRef}
        className={`${classes.Btn}`}
        aria-controls={isMoreActionsOpen ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        variant="contained"
        onClick={this.handleToggleDropdown}
      >
        Xoá <ArrowDropDownRoundedIcon/>
      </Button>
      <Popper
        open={isMoreActionsOpen}
        anchorEl={this.moreActionsMenuRef.current}
        role={undefined}
        transition
        disablePortal
        className={classes.Popper}
        style={{
          minWidth: this.moreActionsMenuRef && this.moreActionsMenuRef.current && this.moreActionsMenuRef.current.offsetWidth,
          zIndex: 3,
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'left top' : 'left bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={this.handleCloseDropdown}>
                <MenuList id="menu-list-grow" autoFocusItem={isMoreActionsOpen}>
                  <MenuItem disabled={!this.props.listLength} onClick={this.props.deleteAllProducts}>Xoá toàn danh sách</MenuItem>
                  <MenuItem disabled={!this.props.selectedLength} onClick={this.props.deleteSelectedProducts}>Xoá sản phẩm đã chọn</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>)
  }
}

DeleteGroupBtns.propTypes = {
  deleteAllProducts: PropTypes.func,
  deleteSelectedProducts: PropTypes.func,
  selectedLength: PropTypes.number,
  listLength: PropTypes.number,
};

DeleteGroupBtns.defaultProps = {
  selectedLength: 0,
  listLength: 0,
};

export default DeleteGroupBtns;
