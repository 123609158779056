export const CHECK_SHIPPING_COUPON = 'getcare/admin/testShippingCoupon/CHECK_SHIPPING_COUPON';

// mutation
export const SAVE_LOADING = 'getcare/admin/testShippingCoupon/SAVE_LOADING';

export const SAVE_ORDER_LIST = 'getcare/admin/testShippingCoupon/SAVE_ORDER_LIST';
export const SAVE_ORDER_LIST_ITEM = 'getcare/admin/testShippingCoupon/SAVE_ORDER_LIST_ITEM';
export const REMOVE_ORDER_LIST_ITEMS = 'getcare/admin/testShippingCoupon/REMOVE_ORDER_LIST_ITEMS';

export const SAVE_SHIPPING_COUPON_AVAILABLES = 'getcare/admin/testShippingCoupon/SAVE_SHIPPING_COUPON_AVAILABLES';

export const SAVE_SHIPPING_COUPON_AMOUNT = 'getcare/admin/testShippingCoupon/SAVE_SHIPPING_COUPON_AMOUNT';
