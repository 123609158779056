import React, { PureComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';
import { doPathsExist } from 'utils/helper/authorization';

import UserDropdown from './UserDropdown/UserDropdown';
import ServicesPanel from 'components/PageHeader/ServicesPanel/ServicesPanel';

import classes from './PageHeader.module.scss';

class PageHeader extends PureComponent {
  getNavLinkClass = (pathArr) => {
    return pathArr.some((path) => this.props.location.pathname.split('/').includes(path.replace('/', '')))
      ? classes.Active
      : '';
  };

  render() {
    const { availableRoutes } = this.props;

    return (
      <div className={classes.Header}>
        <div className={classes.Brand}>
        <img className={classes.Logo} src={`${process.env.REACT_APP_PATH}static/logo/logo-phahub.svg`} height="100" alt="Phahub" />
        </div>
        <nav className={classes.HeaderNav}>
          <ul>
            { doPathsExist(['/products','/product-approval'], availableRoutes) &&
              <li>
                <NavLink to="/products" activeClassName={classes.Active}>
                  Sản phẩm
                </NavLink>
                <ul className={classes.SubNav}>
                { doPathsExist(['/products'], availableRoutes) &&
                    <li>
                      <NavLink to="/products" activeClassName={classes.Active}>
                        Sản phẩm tổng
                      </NavLink>
                    </li>
                  }
                  { doPathsExist(['/products-approval'], availableRoutes) &&
                    <li>
                      <NavLink to="/products-approval" activeClassName={classes.Active}>
                        Sản phẩm chờ duyệt
                      </NavLink>
                    </li>
                  }
                </ul>
              </li>
            }
            { doPathsExist(['/customer-goods', '/product-bonus'], availableRoutes) &&
              <li>
                <NavLink
                  disabled
                  to="/customer-goods"
                  className={this.getNavLinkClass(['/customer-goods', '/product-bonus'])}
                >
                  Hàng Bán - Marketing
                </NavLink>
                <ul className={classes.SubNav}>
                  { doPathsExist(['/customer-goods'], availableRoutes) &&
                    <li>
                      <NavLink to="/customer-goods" activeClassName={classes.Active}>
                        Danh sách hàng bán
                      </NavLink>
                    </li>
                  }
                  { doPathsExist(['/product-bonus'], availableRoutes) &&
                    <li>
                      <NavLink
                        to="/product-bonus"
                        activeClassName={classes.Active}
                      >
                        Hàng khuyến mãi
                      </NavLink>
                    </li>
                  }
                </ul>
              </li>
            }
            { doPathsExist(['/productuom', 'basicuom'], availableRoutes) &&
              <li>
                <NavLink
                  disabled
                  to="/productuom"
                  className={this.getNavLinkClass(['/productuom', '/basicuom'])}
                >
                  ĐVT
                </NavLink>
                <ul className={classes.SubNav}>
                  { doPathsExist(['/productuom'], availableRoutes) &&
                    <li>
                      <NavLink to="/productuom" activeClassName={classes.Active}>
                        Danh sách sản phẩm theo đơn vị
                      </NavLink>
                    </li>
                  }
                  { doPathsExist(['/basicuom'], availableRoutes) &&
                    <li>
                      <NavLink to="/basicuom" activeClassName={classes.Active}>
                        Danh sách đơn vị chuẩn
                      </NavLink>
                    </li>
                  }
                </ul>
              </li>
            }
            { doPathsExist(['/category', 'queuecategory'], availableRoutes) &&
              <li>
                <NavLink
                  disabled
                  to="/category"
                  className={this.getNavLinkClass([
                    '/category',
                    '/queuecategory',
                  ])}
                >
                  Danh mục kỹ thuật
                </NavLink>
                <ul className={classes.SubNav}>
                  { doPathsExist(['/category'], availableRoutes) &&
                    <li>
                      <NavLink to="/category" activeClassName={classes.Active}>
                        Danh mục chuẩn
                      </NavLink>
                    </li>
                  }
                  { doPathsExist(['/queuecategory'], availableRoutes) &&
                    <li>
                      <NavLink to="/queuecategory" activeClassName={classes.Active}>
                        Danh mục chờ duyệt
                      </NavLink>
                    </li>
                  }
                </ul>
              </li>
            }
            { 
            doPathsExist(['/getcare-category/:brand'], availableRoutes) &&
              <li>
                <NavLink
                  disabled
                  to="/getcare-category"
                  className={this.getNavLinkClass([
                    '/getcare-category/getcare', 
                    '/getcare-category/shopee',
                    '/getcare-category/lazada'
                  ])}
                >
                  Danh mục sản phẩm
                </NavLink>
                <ul className={classes.SubNav}>
                  <li>
                    <NavLink to="/getcare-category/getcare" activeClassName={classes.Active}>
                      Danh mục Phahub
                    </NavLink>
                  </li>
                  {/* { doPathsExist(['/product-category/getcare'], availableRoutes) &&
                    <li>
                      <NavLink to="/product-category/getcare" activeClassName={classes.Active}>
                        Danh mục Shopee
                      </NavLink>
                    </li>
                  }
                  { doPathsExist(['/product-category/getcare'], availableRoutes) &&
                    <li>
                      <NavLink to="/product-category/getcare" activeClassName={classes.Active}>
                        Danh mục Lazada
                      </NavLink>
                    </li>
                  } */}
                </ul>
              </li>
            }
            { doPathsExist(['/price', '/policy', '/testprice'], availableRoutes) &&
              <li>
                <NavLink
                  disabled
                  to="/price"
                  className={this.getNavLinkClass([
                    '/price',
                    '/policy',
                    '/testprice',
                  ])}
                >
                  Giá
                </NavLink>
                <ul className={classes.SubNav}>
                  { doPathsExist(['/price'], availableRoutes) &&
                    <li>
                      <NavLink to="/price" activeClassName={classes.Active}>
                        Giá theo nhà cung cấp
                      </NavLink>
                    </li>
                  }
                  { doPathsExist(['/policy'], availableRoutes) &&
                    <li>
                      <NavLink to="/policy" activeClassName={classes.Active}>
                        Chính sách giá
                      </NavLink>
                    </li>
                  }
                  { doPathsExist(['/testprice'], availableRoutes) &&
                    <li>
                      <NavLink to="/testprice" activeClassName={classes.Active}>
                        Price test
                      </NavLink>
                    </li>
                  }
                </ul>
              </li>
            }
            { doPathsExist(['/vendor', '/customer', '/unapproved-pharmacy', '/unapproved-doctor'], availableRoutes) &&
              <li>
                <NavLink
                  disabled
                  to="/vendor"
                  className={this.getNavLinkClass([
                    '/vendor',
                    '/customer',
                    '/unapproved-pharmacy',
                    '/unapproved-doctor',
                  ])}
                >
                  Khách hàng
                </NavLink>
                <ul className={classes.SubNav}>
                  { doPathsExist(['/vendor'], availableRoutes) &&
                    <li>
                      <NavLink to="/vendor" activeClassName={classes.Active}>
                        Nhà cung cấp
                      </NavLink>
                    </li>
                  }
                  { doPathsExist(['/customer'], availableRoutes) &&
                    <li>
                      <NavLink to="/customer" activeClassName={classes.Active}>
                        Khách hàng
                      </NavLink>
                    </li>
                  }
                  { doPathsExist(['/unapproved-pharmacy'], availableRoutes) &&
                    <li>
                      <NavLink to="/unapproved-pharmacy" activeClassName={classes.Active}>
                        Nhà thuốc chưa duyệt
                      </NavLink>
                    </li>
                  }
                  { doPathsExist(['/unapproved-doctor'], availableRoutes) &&
                    <li>
                      <NavLink to="/unapproved-doctor" activeClassName={classes.Active}>
                        Bác sĩ chưa duyệt
                      </NavLink>
                    </li>
                  }
                </ul>
              </li>
            }
            { doPathsExist(['/group'], availableRoutes) &&
              <li>
                <NavLink to="/group" className={this.getNavLinkClass(['/group'])}>
                  Nhóm
                </NavLink>
              </li>
            }
            { doPathsExist(['/saleschannel', '/salesteam', '/coordinater', '/keyaccount', '/salesuser', '/keyaccountlock'], availableRoutes) &&
              <li>
                <NavLink
                  disabled
                  to="/saleschannel"
                  className={this.getNavLinkClass([
                    '/saleschannel',
                    '/salesteam',
                    '/coordinater',
                    '/keyaccount',
                    '/salesuser',
                    '/keyaccountlock',
                  ])}
                >
                  KVBH
                </NavLink>
                <ul className={classes.SubNav}>
                  { doPathsExist(['/saleschannel'], availableRoutes) &&
                    <li>
                      <NavLink to="/saleschannel" activeClassName={classes.Active}>
                        Khu vực bán hàng
                      </NavLink>
                    </li>
                  }
                  { doPathsExist(['/salesteam'], availableRoutes) &&
                    <li>
                      <NavLink to="/salesteam" activeClassName={classes.Active}>
                        Đội ngũ bán hàng
                      </NavLink>
                    </li>
                  }
                  { doPathsExist(['/coordinater'], availableRoutes) &&
                    <li>
                      <NavLink to="/coordinater" activeClassName={classes.Active}>
                        Người điều phối KVBH
                      </NavLink>
                    </li>
                  }
                  { doPathsExist(['/keyaccount'], availableRoutes) &&
                    <li>
                      <NavLink to="/keyaccount" activeClassName={classes.Active}>
                        Đặc quyền bán
                      </NavLink>
                    </li>
                  }
                  { doPathsExist(['/salesuser'], availableRoutes) &&
                    <li>
                      <NavLink to="/salesuser" activeClassName={classes.Active}>
                        Trình dược viên
                      </NavLink>
                    </li>
                  }
                  { doPathsExist(['/keyaccountlock'], availableRoutes) &&
                    <li>
                      <NavLink to="/keyaccountlock" activeClassName={classes.Active}>
                        Key account lock
                      </NavLink>
                    </li>
                  }
                </ul>
              </li>
            }
            { doPathsExist(['/shipping-coupon', '/test-shipping-coupon', '/delivery', '/shipper'], availableRoutes) &&
              <li>
                <NavLink
                  disabled
                  to="/shipping-coupon"
                  className={this.getNavLinkClass([
                    '/shipping-coupon',
                    '/test-shipping-coupon',
                    '/delivery',
                    '/shipper',
                  ])}
                >
                  Vận chuyển
                </NavLink>
                <ul className={classes.SubNav}>
                  { doPathsExist(['/shipping-coupon'], availableRoutes) &&
                    <li>
                      <NavLink to="/shipping-coupon" activeClassName={classes.Active}>
                        Shipping coupon theo vendor
                      </NavLink>
                    </li>
                  }
                  { doPathsExist(['/test-shipping-coupon'], availableRoutes) &&
                    <li>
                      <NavLink to="/test-shipping-coupon" activeClassName={classes.Active}>
                        Test shipping coupon
                      </NavLink>
                    </li>
                  }
                  { doPathsExist(['/delivery'], availableRoutes) &&
                    <li>
                      <NavLink to="/delivery" activeClassName={classes.Active}>
                        Loại hình vận chuyển
                      </NavLink>
                    </li>
                  }
                  { doPathsExist(['/shipper'], availableRoutes) &&
                    <li>
                      <NavLink to="/shipper" activeClassName={classes.Active}>
                        Nhân viên giao hàng
                      </NavLink>
                    </li>
                  }
                </ul>
              </li>
            }
            { doPathsExist(['/asset/brandings','/asset/components'], availableRoutes) &&
              <li>
                <NavLink
                  disabled
                  to="/asset"
                  className={this.getNavLinkClass([
                    '/asset',
                  ])}
                >
                  Asset
                </NavLink>
                <ul className={classes.SubNav}>
                  <li>
                    <NavLink to="/asset/brandings" activeClassName={classes.Active}>
                      Asset Branding
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/asset/components" activeClassName={classes.Active}>
                      Asset Component
                    </NavLink>
                  </li>
                </ul>
              </li>
            }
            { doPathsExist(['/user'], availableRoutes) &&
              <li>
                <NavLink
                  disabled
                  to="/user"
                  className={this.getNavLinkClass([
                    '/user',
                  ])}
                >
                  Tài khoản
                </NavLink>
                <ul className={classes.SubNav}>
                  <li>
                    <NavLink to="/user" activeClassName={classes.Active}>
                      Nhân viên
                    </NavLink>
                  </li>
                </ul>
              </li>
            }
          </ul>
        </nav>
        <ul className={classes.RightNav}>
          <li>
            <ServicesPanel iconSize="default" />
          </li>
          <li>
            <UserDropdown />
          </li>
        </ul>
      </div>
    );
  }
}

export default withRouter(PageHeader);
