import {
  SAVE_TOTAL,
  SAVE_LOADING,
  SAVE_ACTION_LOADING,
  SAVE_PRODUCT_BONUS_LIST,
  SAVE_PRODUCT_BONUS_ITEM,
  REMOVE_PRODUCT_BONUS_ITEMS,
} from 'redux/constants/admin/productBonusConstants';

const initialState = {
  productBonusList: [],
  productBonusListTotal: 0,
  loading: false,
  actionLoading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_LOADING: {
      return {
        ...state,
        loading: action.loading,
      };
    }
    case SAVE_ACTION_LOADING: {
      return {
        ...state,
        actionLoading: action.actionLoading,
      };
    }
    case SAVE_PRODUCT_BONUS_ITEM: {
      const list = [...state.productBonusList];
      let newTotal = state.productBonusListTotal;
      const comparedField = action.productBonusItem.idStr
        ? 'idStr'
        : 'id';
      const index = list.findIndex((p) => p[comparedField] === action.productBonusItem[comparedField]);
      if (index > -1) {
        const tempItem = { ...list[index] };
        list[index] = { ...tempItem, ...action.productBonusItem };
      } else {
        list.unshift({ ...action.productBonusItem });
        newTotal++;
      }
      return {
        ...state,
        productBonusList: list,
        productBonusListTotal: newTotal,
      };
    }
    case REMOVE_PRODUCT_BONUS_ITEMS: {
      const newTotal = state.productBonusListTotal - action.productBonusItems.length;
      const list = state.productBonusList.filter(
        (p) =>
          !action.productBonusItems.some((item) => {
            const comparedField = item.idStr ? 'idStr' : 'id';
            return item[comparedField] === p[comparedField];
          })
      );
      return {
        ...state,
        productBonusList: list,
        productBonusListTotal: newTotal,
      };
    }
    case SAVE_PRODUCT_BONUS_LIST: {
      const list = action.productBonusList;
      return {
        ...state,
        productBonusList: list ? [...list] : [],
      };
    }
    case SAVE_TOTAL: {
      return {
        ...state,
        productBonusListTotal: action.productBonusListTotal,
      };
    }
    default:
      return state;
  }
}

export default reducer;
