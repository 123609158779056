import assetBrandingApi from 'utils/api/admin/assetBrandingApi';
import { put } from 'redux-saga/effects';
import { assetBrandingActions } from 'redux/actions';
import { toast } from 'react-toastify';

export function* loadAssetBrandingList(payload) {
  const { params, vendorId } = payload.payload;
  try {
    yield put(assetBrandingActions.saveLoading(true));
    yield put(assetBrandingActions.saveAssetBrandingList([]));
    yield put(assetBrandingActions.saveAssetBrandingListTotal(0));

    const { data: response } = yield assetBrandingApi.getAssetBrandingList({
      params,
      vendorId,
    });

    yield put(assetBrandingActions.saveLoading(false));
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(
      assetBrandingActions.saveAssetBrandingList(
        response.data ? [...response.data] : []
      )
    );
    yield put(
      assetBrandingActions.saveAssetBrandingListTotal(response.total_records)
    );
  } catch (err) {
    console.log(err);
  }
}
// export function* postAssetBrandingList(payload) {
//   const { params, currentListParams } = payload.payload;
//   try {
//     yield put(assetBrandingActions.saveActionLoading(true));

//     const { data: response } = yield assetBrandingApi.updateAssetBrandingList({
//       params,
//     });

//     yield put(assetBrandingActions.saveActionLoading(false));
//     if (!response?.result) {
//       toast.error(response.message);
//       return;
//     }
//     yield put(assetBrandingActions.getAssetBrandingList(currentListParams));
//   } catch (err) {
//     console.log(err);
//   }
// }
