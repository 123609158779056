import React, { PureComponent } from 'react';
import { validNumber } from 'utils/helper';

import IconButton from '@material-ui/core/IconButton';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import FieldEditable from 'components/FieldEditable/FieldEditable';

import classes from './TagUOMItem.module.scss';

class TagUOMItem extends PureComponent {
  componentDidUpdate(prevProps) {
    const { uomId, uomNextId, uomIndex, uomsLength } = this.props;
    if (uomId && uomId !== prevProps.uomId && uomIndex > 0) {
      this.props.onUpdateUOMItem({
        uomIndex: uomIndex - 1,
        fieldMap: {
          'uomNextId': uomId,
        }
      });
    }
    if (uomNextId && uomNextId !== prevProps.uomNextId) {
      if (uomIndex < uomsLength - 1) {
        this.props.onUpdateUOMItem({
          uomIndex: uomIndex + 1,
          fieldMap: {
            'uomId': uomNextId,
          }
        });
        return;
      }
      if (uomIndex === uomsLength - 1) {
        this.props.onUpdateUOMParent({
          uom1Id: uomNextId
        });
      }
    }
  }

  _getUOMItem = (id) => {
    return this.props.uomBaseList ? this.props.uomBaseList.find(item => item.id === id) : null;
  }
  _getItemParams = () => {
    return {
      uomIndex: this.props.uomIndex,
      uomId: this.props.uomId,
      uomNumber: this.props.uomNumber,
      uomNextId: this.props.uomNextId,
    }
  }

  handleSaveField = (fieldMap) => {
    this.props.onSaveUOMItem({
      ...this._getItemParams(),
      ...fieldMap,
    });
  }
  handleChangeField = (e, value) => {
    this.props.onSaveUOMItem({
      ...this._getItemParams(),
      [e.target.name]: e.target.type === 'number' ? validNumber(value) : value,
    });
  }
  handleRemove = () => {
    this.props.onRemoveUOMItem({
      uomIndex: this.props.uomIndex,
    });
  }

  render() {
    const { uomId, uomNumber, uomNextId, uomBaseList, startId, uomIndex } = this.props;

    return (<div className={`${classes.UOMItem} ${classes.FieldControl}`}>
      <label>{`ĐVT ${startId + uomIndex}`}</label>
      <FieldEditable
        editModeOnly
        fieldType="select"
        fieldName="uomId"
        value={uomId}
        displayedValue={this._getUOMItem(uomId)?.name}
        options={uomBaseList || []}
        error={!uomId}
        onSave={() => {return;}}
        onChange={this.handleChangeField}
      />
      <span>&#61;</span>
      <FieldEditable
        editModeOnly
        fieldName="uomNumber"
        type="number"
        value={uomNumber}
        error={!uomNumber || uomNumber <= 0}
        onSave={() => {return;}}
        onChange={this.handleChangeField}
      />
      <FieldEditable
        editModeOnly
        fieldType="select"
        fieldName="uomNextId"
        value={uomNextId}
        displayedValue={this._getUOMItem(uomNextId)?.name}
        options={uomBaseList || []}
        error={!uomNextId}
        onSave={() => {return;}}
        onChange={this.handleChangeField}
      />
      { this.props.uomsLength > 1 && <IconButton
          onClick={this.handleRemove}
          style={{ padding: '0' }}
          size="small"
          color="secondary"
        >
          <DeleteOutline fontSize="small" />
        </IconButton>
      }
    </div>);
  }
}

export default TagUOMItem;
