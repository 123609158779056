import {
  GET_SHIPPING_COUPON_LIST,
  SAVE_SHIPPING_COUPON_LIST,
  SAVE_SHIPPING_COUPON_LIST_TOTAL,

  SAVE_LOADING,

  GET_SHIPPING_COUPON,
  UPDATE_SHIPPING_COUPON,
  RESET_SHIPPING_COUPON,

  SAVE_SHIPPING_COUPON_HISTORY,

  SAVE_ORI_SHIPPING_COUPON_DETAILS,
  SAVE_SHIPPING_COUPON_DETAILS,

  SAVE_ORI_SHIPPING_COUPON_RULES,
  SAVE_SHIPPING_COUPON_RULES,
  SAVE_SHIPPING_COUPON_RULE_ITEM,
  REMOVE_SHIPPING_COUPON_RULE_ITEMS,

  SAVE_ORI_SHIPPING_COUPON_CUSTOMERS,
  SAVE_SHIPPING_COUPON_CUSTOMERS,
  SAVE_SHIPPING_COUPON_CUSTOMER_ITEM,
  REMOVE_SHIPPING_COUPON_CUSTOMER_ITEMS,

  SAVE_ORI_SHIPPING_COUPON_SALES_CHANNELS,
  SAVE_SHIPPING_COUPON_SALES_CHANNELS,
  SAVE_SHIPPING_COUPON_SALES_CHANNEL_ITEM,
  REMOVE_SHIPPING_COUPON_SALES_CHANNEL_ITEMS,
} from 'redux/constants/admin/shippingCouponConstants';

export function getShippingCouponList(payload) {
  return {
    type: GET_SHIPPING_COUPON_LIST,
    payload,
  };
}

export function getShippingCoupon(payload) {
  return {
    type: GET_SHIPPING_COUPON,
    payload,
  };
}

export function updateShippingCoupon(payload) {
  return {
    type: UPDATE_SHIPPING_COUPON,
    payload,
  };
}

// mutation
export function saveLoading(loading) {
  return {
    type: SAVE_LOADING,
    loading,
  };
}
export function saveShippingCouponList(shippingCouponList) {
  return {
    type: SAVE_SHIPPING_COUPON_LIST,
    shippingCouponList,
  };
}
export function saveShippingCouponListTotal(shippingCouponListTotal) {
  return {
    type: SAVE_SHIPPING_COUPON_LIST_TOTAL,
    shippingCouponListTotal,
  };
}

export function resetShippingCoupon(payload) {
  return {
    type: RESET_SHIPPING_COUPON,
    payload,
  };
}

export function saveOriShippingCouponDetails(oriShippingCouponDetails) {
  return {
    type: SAVE_ORI_SHIPPING_COUPON_DETAILS,
    oriShippingCouponDetails,
  };
}
export function saveShippingCouponDetails(shippingCouponDetails) {
  return {
    type: SAVE_SHIPPING_COUPON_DETAILS,
    shippingCouponDetails,
  };
}

export function saveOriShippingCouponRules(oriShippingCouponRules) {
  return {
    type: SAVE_ORI_SHIPPING_COUPON_RULES,
    oriShippingCouponRules,
  };
}
export function saveShippingCouponRules(shippingCouponRules) {
  return {
    type: SAVE_SHIPPING_COUPON_RULES,
    shippingCouponRules,
  };
}
export function saveShippingCouponRuleItem(shippingCouponRuleItem) {
  return {
    type: SAVE_SHIPPING_COUPON_RULE_ITEM,
    shippingCouponRuleItem,
  };
}
export function removeShippingCouponRuleItems(shippingCouponRuleItems) {
  return {
    type: REMOVE_SHIPPING_COUPON_RULE_ITEMS,
    shippingCouponRuleItems,
  };
}

export function saveOriShippingCouponCustomers(oriShippingCouponCustomers) {
  return {
    type: SAVE_ORI_SHIPPING_COUPON_CUSTOMERS,
    oriShippingCouponCustomers,
  };
}
export function saveShippingCouponCustomers(shippingCouponCustomers) {
  return {
    type: SAVE_SHIPPING_COUPON_CUSTOMERS,
    shippingCouponCustomers,
  };
}
export function saveShippingCouponCustomerItem(shippingCouponCustomerItem) {
  return {
    type: SAVE_SHIPPING_COUPON_CUSTOMER_ITEM,
    shippingCouponCustomerItem,
  };
}
export function removeShippingCouponCustomerItems(shippingCouponCustomerItems) {
  return {
    type: REMOVE_SHIPPING_COUPON_CUSTOMER_ITEMS,
    shippingCouponCustomerItems,
  };
}

export function saveOriShippingCouponSalesChannels(oriShippingCouponSalesChannels) {
  return {
    type: SAVE_ORI_SHIPPING_COUPON_SALES_CHANNELS,
    oriShippingCouponSalesChannels,
  };
}
export function saveShippingCouponSalesChannels(shippingCouponSalesChannels) {
  return {
    type: SAVE_SHIPPING_COUPON_SALES_CHANNELS,
    shippingCouponSalesChannels,
  };
}
export function saveShippingCouponSalesChannelItem(shippingCouponSalesChannelItem) {
  return {
    type: SAVE_SHIPPING_COUPON_SALES_CHANNEL_ITEM,
    shippingCouponSalesChannelItem,
  };
}
export function removeShippingCouponSalesChannelItems(shippingCouponSalesChannelItems) {
  return {
    type: REMOVE_SHIPPING_COUPON_SALES_CHANNEL_ITEMS,
    shippingCouponSalesChannelItems,
  };
}

export function saveShippingCouponHistory(shippingCouponHistory) {
  return {
    type: SAVE_SHIPPING_COUPON_HISTORY,
    shippingCouponHistory,
  };
}
