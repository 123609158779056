import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import gridClasses from '../StoreListGrid.module.scss';
import classes from './StoreListItem.module.scss';

class StoreListItem extends PureComponent {
  goToDetails = (e) => {
    this.props.history.push(`/customer/${this.props.id}`);
  }

  render() {
    const {
      id,
      is_trial,
      code,
      name,
      getcare_customer_type,
      registration_number,
      tax_code,
      type_pharmacy,
      representative_name,
      expertise_person,
      expertise_number,
      phone,
      getcare_vendor,
      address,
      approval,
      source,
      unapprovedPharmacy,
      unapprovedDoctor,
    } = this.props;

    let linkUrl;
    if (unapprovedPharmacy) {
      linkUrl = `/unapproved-pharmacy/${id}`;
    } else if (unapprovedDoctor) {
      linkUrl = `/unapproved-doctor/${id}`;
    } else {
      linkUrl = `/customer/${id}`;
    }

    const sourceName = source === 1 ? 'Hệ thống tổng' : getcare_vendor?.name;

    return <Link
      className={`${classes.ProductItem} ${gridClasses.Row}`}
      to={linkUrl}
    >
      <div className={gridClasses.Col}>{ code }</div>
      <div className={gridClasses.Col}>{ name }</div>
      <div className={gridClasses.Col}>{ getcare_customer_type?.name }</div>
      <div className={gridClasses.Col}>{ tax_code }</div>
      <div className={gridClasses.Col}>{ phone }</div>
      <div className={gridClasses.Col}>{ address }</div>
      <div className={gridClasses.Col}>{ registration_number }</div>
      <div className={gridClasses.Col}>{ type_pharmacy }</div>
      <div className={gridClasses.Col}>{ representative_name }</div>
      <div className={gridClasses.Col}>{ expertise_person }</div>
      <div className={gridClasses.Col}>{ expertise_number }</div>
      <div className={gridClasses.Col}>{ sourceName }</div>
      <div className={gridClasses.Col}> {is_trial === 1 ? (
                'Đang dùng thử'
            ) : is_trial === -1 && approval === 1 ? (
              'Đã duyệt'
            ) : (
             'Chưa duyệt'
            )}
</div>   
       </Link>;
  }
}

StoreListItem.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  code: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  registration_number: PropTypes.string,
  tax_code: PropTypes.string,
  type_pharmacy: PropTypes.string,
  branch_create: PropTypes.string,
  representative_name: PropTypes.string,
  expertise_person: PropTypes.string,
  expertise_number: PropTypes.string,
  phone: PropTypes.string,
  address: PropTypes.string,
  getcare_vendor: PropTypes.object,
  approval: PropTypes.number,
};

export default withRouter(StoreListItem);
