import React from 'react';
import PropTypes from 'prop-types';
import { filterFields } from 'utils/constanst/adminAssetBrandingConstants';

import FilterField from 'components/FilterField/FilterField';
import AutocompleteVendor from 'components/AutocompleteVendor/AutocompleteVendor';

import classes from './AssetBrandingListFilters.module.scss';

class AssetBrandingListFilters extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fields: [...filterFields],
    };
  }

  _getFieldIndex = (fieldName) => {
    return this.state.fields.findIndex(item => item.name === fieldName);
  }

  handleFieldChange = ({ name, value }) => {
    const newFields = this.state.fields.map(field => field.name === name ? { ...field, value } : field);
    this.setState({
      fields: newFields,
    });
    this.props.onFilterChange(newFields, true);
  }

  render() {
    const { fields } = this.state;

    return (
      <div className={classes.Panel}>
        <h3 className={classes.PanelTitle}>Lọc theo:</h3>
        <div className={classes.PanelContent}>
          {fields.map((field) => {
            let customField;
            switch (field.name) {
              case 'getcare_vendor_id': {
                customField = <AutocompleteVendor
                  {...field} 
                  disableCloseOnSelect
                  onChange={(e,value) => {
                    this.handleFieldChange({ name: 'getcare_vendor_id', value: value });
                  }}
                />
                break;
              }
            }
            return (
              <FilterField 
                key={field.name} 
                {...field} 
                handleFieldChange={this.handleFieldChange}
                customField={customField} 
              />
            )
          })}
        </div>
      </div>
    );
  }
}

AssetBrandingListFilters.propTypes = {
  onFilterChange: PropTypes.func,
};

export default AssetBrandingListFilters;
