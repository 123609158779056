import React, { PureComponent } from 'react';
import productApi from 'utils/api/admin/productApi';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectProductDetails } from 'redux/selectors';
import { saveProduct } from 'redux/actions/admin/productActions';
import { cloneDeep } from 'lodash';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import classes from './OtherInfo.module.scss';

class OtherInfo extends PureComponent {
  handleFieldChange = async (e) => {
    const { productDetails } = this.props;
    const fieldsMap = {
      is_new: productDetails?.is_new,
      is_popular: productDetails?.is_popular,
      is_selling: productDetails?.is_selling,
      [e.target.name]: e.target.checked ? 1 : 0,
    };
    const { data: response } = await productApi.updateProductsTrend({
      params: {
        ...fieldsMap,
        getcare_product_ids: [productDetails?.id],
      },
    });
    if (!response.result) {
      toast.error(response.message);
      return;
    }
    this.props.saveProduct({
      ...cloneDeep(productDetails),
      ...fieldsMap,
    });
  }

  render() {
    const { productDetails, isEditAllowed } = this.props;

    return (
      <div className={classes.Wrap}>
        <div className={classes.ColInner}>
          <div className={classes.Info}>
            <FormControlLabel
              name="is_new"
              value="is_new"
              control={<Checkbox color="secondary" />}
              label="Sản phẩm mới"
              labelPlacement="start"
              checked={Number(productDetails?.is_new) === 1}
              disabled={!isEditAllowed}
              onChange={this.handleFieldChange}
            />
          </div>
          <div className={classes.Info}>
            <FormControlLabel
              name="is_popular"
              value="is_popular"
              control={<Checkbox color="secondary" />}
              label="Sản phẩm phổ biến"
              labelPlacement="start"
              checked={Number(productDetails?.is_popular) === 1}
              disabled={!isEditAllowed}
              onChange={this.handleFieldChange}
            />
          </div>
          <div className={classes.Info}>
            <FormControlLabel
              name="is_selling"
              value="is_selling"
              control={<Checkbox color="secondary" />}
              label="Sản phẩm bán chạy"
              labelPlacement="start"
              checked={Number(productDetails?.is_selling) === 1}
              disabled={!isEditAllowed}
              onChange={this.handleFieldChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  productDetails: makeSelectProductDetails(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    saveProduct: (payload) => dispatch(saveProduct(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(OtherInfo);
