import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { numberFormat } from 'utils/helper';
import { isEqual } from 'lodash';
import uomApi from 'utils/api/admin/uomApi';
import { toast } from 'react-toastify';

import FieldEditable from 'components/FieldEditable/FieldEditable';
import InputAdornment from '@material-ui/core/InputAdornment';

import classes from './UOMInfo.module.scss';

class UOMInfo extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      productUomsMap: { ...props.getcare_uom },
    }
  }
  componentDidUpdate(prevProps) {
    const { getcare_uom } = this.props;
    if (getcare_uom && !isEqual(getcare_uom, prevProps.getcare_uom)) {
      this.setState({
        productUomsMap: { ...getcare_uom },
      });
    }
  }

  _getUOMs = () => {
    const uoms = [];
    const { productUomsMap } = this.state;
    Object.keys(productUomsMap).forEach(key => {
      if (key.match(/^uom_\d+$/g) && !!productUomsMap[key]?.id) {
        uoms.push({
          ...productUomsMap[key],
          key,
          number: productUomsMap[`${key}_number`],
          weight: productUomsMap[`${key}_weight`],
        });
      }
    });
    // remove uom_1
    uoms.shift();
    return uoms;
  }
  _getDisplayedWeight = (w) => {
    if (['', null, undefined].includes(w)) return ``;
    return `${numberFormat(w)} (gram)`;
  }
  _getTheSameUomBaseIdWeightsMap = (uomBaseId, name, value) => {
    // find the same uomBaseId uoms
    const { getcare_uom } = this.props;
    return Object.keys(getcare_uom).reduce((memo, key) => {
      if (key.match(/^uom_[\d,base]+$/g) && getcare_uom[key]?.id === uomBaseId && `${key}_weight` !== name) {
        memo[`${key}_weight`] = value;
      }
      return memo;
    }, {});
  }

  handleSaveField = async (uomBaseId, fieldName, fieldMap) => {
    const uomBaseIdWeightsMap = this._getTheSameUomBaseIdWeightsMap(uomBaseId, fieldName, fieldMap[fieldName]);
    this.setState({
      productUomsMap: { ...this.state.productUomsMap, ...uomBaseIdWeightsMap, ...fieldMap },
    });

    const { data: response } = await uomApi.updateUOMToProduct({
      getcare_uom_id: this.state.productUomsMap.id,
      params: {
        ...fieldMap,
      },
    });
    if (!response.result || !response.data) {
      toast.error(response.message);
      return;
    }
  }

  render() {
    const {
      name,
      uom_base,
      uom_base_weight,
      uom_base_id,
      uom_1,
      uom_1_weight,
      uom_1_id,
      smallest,
    } = this.state.productUomsMap;
    const { isEditAllowed } = this.props;
    const uoms = this._getUOMs();

    return(<div className={classes.Wrap}>
        <div className={classes.Info}>
          <label>Quy cách đóng gói</label>
          { name }
        </div>
        <div className={classes.ColInner}>
          <div>
            <div className={`${classes.Info}`}>
              <label>ĐV cơ bản</label>
              { uom_base && uom_base.name }
            </div>

            <div className={`${classes.Info} ${classes.UOMWeight}`}>
              <label>Trọng lượng</label>
              { isEditAllowed ? (
                  <FieldEditable
                    fieldName="uom_base_weight"
                    value={uom_base_weight}
                    displayedValue={this._getDisplayedWeight(uom_base_weight)}
                    type="number"
                    inputProps={{
                      endAdornment: <InputAdornment position="end">gram</InputAdornment>,
                    }}
                    onSave={(fieldMap) => this.handleSaveField(uom_base_id, 'uom_base_weight', fieldMap)}
                    onChange={() => { return false; }}
                  />
                ) : this._getDisplayedWeight(uom_base_weight)
              }
            </div>
          </div>

          <div>
            <div className={classes.Info}>
              <label>ĐV bán lẻ</label>
              { uom_1 && uom_1.name }
            </div>

            <div className={`${classes.Info} ${classes.UOMWeight}`}>
              <label>Trọng lượng</label>
              { isEditAllowed ? (
                  <FieldEditable
                    fieldName="uom_1_weight"
                    value={uom_1_weight}
                    displayedValue={this._getDisplayedWeight(uom_1_weight)}
                    type="number"
                    inputProps={{
                      endAdornment: <InputAdornment position="end">gram</InputAdornment>,
                    }}
                    onSave={(fieldMap) => this.handleSaveField(uom_1_id, 'uom_1_weight', fieldMap)}
                    onChange={() => { return false; }}
                  />
                ) : this._getDisplayedWeight(uom_1_weight)
              }
            </div>
          </div>

          { uoms.map((uom, index) => (
            <div key={`uom-${index + 1}-${uom.key}`} >
              <div className={classes.Info}>
                <label>ĐVT {index + 2}</label>
                { uom.name || '' } { uom.number ? `(${numberFormat(uom.number)} ${uoms[index + 1] ? uoms[index + 1].name : uom_1.name})` : `` }
              </div>

              <div className={`${classes.Info} ${classes.UOMWeight}`}>
                <label>Trọng lượng</label>
                { isEditAllowed ? (
                    <FieldEditable
                      fieldName={`${uom.key}_weight`}
                      value={uom.weight}
                      displayedValue={this._getDisplayedWeight(uom.weight)}
                      type="number"
                      inputProps={{
                        endAdornment: <InputAdornment position="end">gram</InputAdornment>,
                      }}
                      onSave={(fieldMap) => this.handleSaveField(uom.id, `${uom.key}_weight`, fieldMap)}
                      onChange={() => { return false; }}
                    />
                  ) : this._getDisplayedWeight(uom.weight)
                }
              </div>
            </div>
          )) }
        </div>
        <div className={`${classes.Info} ${classes.Helper}`}>
          <label>Bán lẻ</label>
          { smallest }
        </div>
    </div>);
  }
}

UOMInfo.propTypes = {
  getcare_uom: PropTypes.object,
};

UOMInfo.defaultProps = {
  getcare_uom: {},
};

export default UOMInfo;
