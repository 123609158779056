import {
  SAVE_ASSET_COMPONENT_LIST,
  SAVE_ASSET_COMPONENT_LIST_TOTAL,
  SAVE_LOADING,
  REMOVE_ASSET_COMPONENT_ITEMS,
  SAVE_ASSET_COMPONENT_ITEM,
  SAVE_ACTION_LOADING,
  SAVE_ASSET_COMPONENT_ALLOWED_ACTIONS,
  SAVE_ASSET_COMPONENT_VISIBILITY,
} from 'redux/constants/admin/assetComponentConstants';

const initialState = {
  assetComponentList: [],
  assetComponentListTotal: 0,
  loading: false,
  actionLoading: false,
  assetComponentAllowedActions: [],
  assetComponentVisibility: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_LOADING: {
      return {
        ...state,
        loading: action.loading,
      };
    }
    case SAVE_ACTION_LOADING: {
      return {
        ...state,
        actionLoading: action.actionLoading,
      };
    }
    case SAVE_ASSET_COMPONENT_ITEM: {
      const list = [...state.assetComponentList];
      let newTotal = state.assetComponentListTotal;
      const comparedField = action.assetComponentItem.idStr
        ? 'idStr'
        : 'id'; // ???
      const index = list.findIndex((p) => p[comparedField] === action.assetComponentItem[comparedField]);
      if (index > -1) {
        const tempItem = { ...list[index] };
        list[index] = { ...tempItem, ...action.assetComponentItem };
      } else {
        list.unshift({ ...action.assetComponentItem });
        newTotal++;
      }
      return {
        ...state,
        assetComponentList: list,
        assetComponentListTotal: newTotal,
      };
    }
    case REMOVE_ASSET_COMPONENT_ITEMS: {
      const newTotal = state.assetComponentListTotal - action.assetComponentItems.length;
      const list = state.assetComponentList.filter(
        (p) =>
          !action.assetComponentItems.some((item) => {
            const comparedField = item.idStr ? 'idStr' : 'id';
            return item[comparedField] === p[comparedField];
          })
      );
      return {
        ...state,
        assetComponentList: list,
        assetComponentListTotal: newTotal,
      };
    }
    case SAVE_ASSET_COMPONENT_LIST: {
      const list = action.assetComponentList;
      return {
        ...state,
        assetComponentList: list ? [...list] : [],
      };
    }
    case SAVE_ASSET_COMPONENT_LIST_TOTAL: {
      return {
        ...state,
        assetComponentListTotal: action.assetComponentListTotal,
      };
    }

    // case SAVE_ORI_VENDOR_PRODUCT_LIST: {
    //   const list = action.oriVendorProductList;
    //   return {
    //     ...state,
    //     oriVendorProductList: list ? [...list] : [],
    //   };
    // }

    case SAVE_ASSET_COMPONENT_ALLOWED_ACTIONS: {
      return {
        ...state,
        assetComponentAllowedActions: [...action.assetComponentAllowedActions],
      };
    }
    case SAVE_ASSET_COMPONENT_VISIBILITY: {
      return {
        ...state,
        assetComponentVisibility: action.assetComponentVisibility ? {...action.assetComponentVisibility} : null,
      };
    }

    default:
      return state;
  }
}

export default reducer;
