export const STATUS_ACTIVE = 1;
export const STATUS_INACTIVE = -1;
export const shippingCouponStatusMap = {
  [STATUS_ACTIVE]: 'Đang hoạt động',
  [STATUS_INACTIVE]: 'Dừng hoạt động',
};
export const shippingCouponStatuses = [
  {
    id: STATUS_ACTIVE,
    name: shippingCouponStatusMap[STATUS_ACTIVE],
  },
  {
    id: STATUS_INACTIVE,
    name: shippingCouponStatusMap[STATUS_INACTIVE],
  },
];

export const listDisplayFields = [
  {
    name: 'getcare_vendor_code',
    label: 'Mã vendor',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'getcare_vendor_name',
    label: 'Tên vendor',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'code',
    label: 'Mã coupon',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'description',
    label: 'Mô tả',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'start_date',
    label: 'Ngày bắt đầu',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'end_date',
    label: 'Ngày kết thúc',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'amount_freeship',
    label: 'Mức freeship',
    sortable: true,
    sortDir: '',
    className: 'TextRight',
  },
];
export const listParamsMap = {
  page_size: 50,
  page: 1,
  order: '',
  active: [STATUS_ACTIVE],
};

export const filterFields = [
  {
    name: 'active',
    label: 'Trạng thái',
    value: [STATUS_ACTIVE],
    type: 'custom',
    placeholder: '- Chọn -',
  },
  {
    name: 'getcare_vendor_code',
    label: 'Mã vendor',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'getcare_vendor_name',
    label: 'Tên vendor',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'code',
    label: 'Mã coupon',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
];

// details
export const APPLY_AWAITING = -1;
export const APPLY_IN_PROGRESS = 1;
export const APPLY_UNDEFINED = 0;
export const applyStatusMap = {
  [APPLY_AWAITING]: 'Chưa áp dụng',
  [APPLY_IN_PROGRESS]: 'Đang áp dụng',
  [APPLY_UNDEFINED]: '-',
}

export const ACTIVITY_AWAITING = -1;
export const ACTIVITY_IN_PROGRESS = 1;
export const ACTIVITY_STOP = 0;
export const activityMap = {
  [ACTIVITY_AWAITING]: 'Đang chờ',
  [ACTIVITY_IN_PROGRESS]: 'Đang hoạt động',
  [ACTIVITY_STOP]: '',
};

// rules
export const MAX_LEVEL_NUM = 10;

export const defaultParamsRule = {
  id: 0,

  description: '',
  amount_gt: 0,
  amount_lt: null,
  discount_amount: 0,
  freeship: false,

  isEditing: true,
}

export const shippingCouponRulesDisplayFields = [
  {
    name: 'level',
    label: 'Level',
  },
  {
    name: 'description',
    label: 'Mô tả',
  },
  {
    name: 'amount_gt',
    label: 'From',
    className: 'TextRight',
  },
  {
    name: 'amount_lt',
    label: 'To',
    className: 'TextRight',
  },
  {
    name: 'discount_amount',
    label: 'Discount amount',
    className: 'TextRight',
  },
  {
    name: 'freeship',
    label: 'Freeship',
    className: 'TextCenter',
  },
]
export const shippingCouponRulesParamsMap = {
  page_size: 50,
  page: 1,
  order: '',
}

// sales channels
export const shippingCouponSalesChannelsParamsMap = {
  order: '',
};
export const shippingCouponSalesChannelsDisplayFields = [
  {
    name: 'code',
    label: 'Khu vực bán hàng',
  },
];

// customers
export const shippingCouponCustomersParamsMap = {
  order: '',
};
export const shippingCouponCustomersDisplayFields = [
  {
    name: 'code',
    label: 'Mã nhóm',
  },
  {
    name: 'name',
    label: 'Tên nhóm',
  },
];

// functions
export function getActivityStatus(shippingCouponDetails) {
  if (!shippingCouponDetails) return APPLY_AWAITING;
  if (shippingCouponDetails.active === STATUS_INACTIVE || shippingCouponDetails.apply === APPLY_UNDEFINED) return ACTIVITY_STOP;
  return shippingCouponDetails.apply;
}
export function isAwaitingProgress(shippingCouponDetails) {
  return getActivityStatus(shippingCouponDetails) === APPLY_AWAITING;
}
export function isStoppedProgress(shippingCouponDetails) {
  return getActivityStatus(shippingCouponDetails) === ACTIVITY_STOP;
}
