import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { debounce } from 'lodash';
import {
  getCoordinaterList,
  saveCoordinaterItem,
  deleteCoordinaterItems,
  updateCoordinaterItem,
  saveCoordinaterAllowedActions,
} from 'redux/actions/admin/coordinaterActions';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectCoordinaterList,
  makeSelectCoordinaterListTotal,
  makeSelectCoordinaterLoading,
  makeSelectCoordinaterDeleteLoading,
  makeSelectCoordinaterAllowedActions,
} from 'redux/selectors';
import {
  listParamsMap,
  listDisplayFields,
  coordinaterRoles,
  ROLE_LEADER,
} from 'utils/constanst/adminCoordinaterConstants';
import { getSortsString, getDisplayFields, genID } from 'utils/helper';
import { withRouter } from 'react-router-dom';
import authApi from 'utils/api/authApi';
import { toast } from 'react-toastify';
import { isActionAllowed } from 'utils/helper/authorization';

import CoordinaterList from 'components/admin/CoordinaterList/CoordinaterList';
import CoordinaterListFilters from 'components/admin/CoordinaterList/CoordinaterListFilters/CoordinaterListFilters';
import ListPagination from 'components/ListPagination/ListPagination';

import classes from './Coordinaters.module.scss';

class Coordinaters extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      listParams: { ...listParamsMap },
    };
  }
  async componentDidMount() {
    await this.getAllowedActions();
    this.loadList();
  }
  componentDidUpdate(prevProps) {
    const { deleteLoading } = this.props;
    if (!deleteLoading && deleteLoading !== prevProps.deleteLoading) {
      this.loadList();
    }
  }

  getAllowedActions = async () => {
    const { data: response } = await authApi.getAllowedActions({
      component: 'coordinater',
    });
    if (!response.result) {
      toast.error(response.message);
      return;
    }
    this.props.saveCoordinaterAllowedActions(response.allowed_actions || []);
  };
  loadList = () => {
    this.props.getCoordinaterList({
      params: this.state.listParams,
    });
  }
  _prepareItemData = (params) => {
    return {
      id: params.id,
      getcare_user_id: params.getcare_user?.id || undefined,
      is_leader: params.getcare_role?.id === ROLE_LEADER,
    }
  }
  handleSortChange = ({ sortBy, sortDir }) => {
    const newSortString = getSortsString({
      [sortBy]: sortDir,
    });
    this.handleFilterChange([
      {
        name: 'order',
        value: newSortString,
      },
    ]);
  };
  handleFilterChange = (filters, forceResetPage: false) => {
    const paramsFromFilters = filters.reduce((memo, item) => {
      memo[item.name] = item.value;
      return memo;
    }, {});
    const newParams = forceResetPage ? {
      ...this.state.listParams,
      ...paramsFromFilters,
      page: 1,
    } : {
      ...this.state.listParams,
      ...paramsFromFilters,
    };
    this.setState({
      listParams: newParams,
    });
    this.props.getCoordinaterList({
      params: newParams,
    });
  };
  handleCreateNewRow = () => {
    this.props.saveCoordinaterItem({
      idStr: genID(),
      id: 0,
      getcare_user: null,
      is_leader: true,
    });
  }
  handleSaveCoordinater = (params) => {
    this.props.saveCoordinaterItem({
      ...params,
    });
    this.props.updateCoordinaterItem({
      params: {
        ...this._prepareItemData(params),
        idStr: params.idStr,
      },
    });
  };
  handleRemoveCoordinater = (item) => {
    this.props.deleteCoordinaterItems({
      params: {
        getcare_coordinator_ids: [item.id],
      }
    });
  }

  render() {
    const { coordinaterListTotal, loading, coordinaterList, deleteLoading, coordinaterAllowedActions } = this.props;
    const { listParams } = this.state;
    const isListLoading = loading;
    const isCreateAllowed = isActionAllowed('create', coordinaterAllowedActions);
    const isEditAllowed = isActionAllowed('edit', coordinaterAllowedActions);

    return (
      <React.Fragment>
        <div className={classes.PageWrap}>
          <div className={classes.PageHeader}>
            <h1 className={classes.PageTitle}>Danh sách người điều phối KVBH</h1>
          </div>
          <div className={classes.PageMain}>
            <CoordinaterListFilters onFilterChange={debounce(this.handleFilterChange, 500)}/>
            <CoordinaterList
              displayFields={getDisplayFields(
                listParams,
                listDisplayFields
              )}
              coordinaterList={coordinaterList}
              coordinaterRoles={[...coordinaterRoles]}
              isCreateAllowed={isCreateAllowed}
              isEditAllowed={isEditAllowed}
              isLoading={isListLoading}
              deleteLoading={deleteLoading}
              onAddNewRow={this.handleCreateNewRow}
              onSortChange={this.handleSortChange}
              onSaveCoordinater={this.handleSaveCoordinater}
              onRemoveCoordinater={this.handleRemoveCoordinater}
            />
          </div>
          <div className={classes.PageFooter}>
            <ListPagination
              page={listParams.page}
              pageSize={listParams.page_size}
              total={coordinaterListTotal}
              listName="người điều phối"
              onFilterChange={this.handleFilterChange}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  coordinaterListTotal: makeSelectCoordinaterListTotal(),
  coordinaterList: makeSelectCoordinaterList(),
  loading: makeSelectCoordinaterLoading(),
  deleteLoading: makeSelectCoordinaterDeleteLoading(),
  coordinaterAllowedActions: makeSelectCoordinaterAllowedActions(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getCoordinaterList: (payload) => dispatch(getCoordinaterList(payload)),
    saveCoordinaterItem: (payload) => dispatch(saveCoordinaterItem(payload)),
    updateCoordinaterItem: (payload) => dispatch(updateCoordinaterItem(payload)),
    deleteCoordinaterItems: (payload) => dispatch(deleteCoordinaterItems(payload)),
    saveCoordinaterAllowedActions: (payload) => dispatch(saveCoordinaterAllowedActions(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter)(Coordinaters);
