import {
  SAVE_LOADING,
  SAVE_VENDOR_PRICE_LIST,
  SAVE_VENDOR_PRICE_LIST_TOTAL,
  SAVE_TEMP_VENDOR_PRICE_ITEM,
  SAVE_TEMP_VENDOR_PRICE_LIST,
  REMOVE_TEMP_VENDOR_PRICE_ITEM,
  SAVE_VENDOR_PRICE_ITEM,
  SAVE_VENDOR_PRICE_ALLOWED_ACTIONS,
  SAVE_VENDOR_PRICE_VISIBILITY,
} from 'redux/constants/admin/vendorPriceConstants';

const initialState = {
  tempVendorPriceList: [],
  vendorPriceList: [],
  vendorPriceListTotal: 0,
  loading: false,
  updatePriceLoading: false,
  vendorPriceAllowedActions: [],
  vendorPriceVisibility: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_LOADING: {
      return {
        ...state,
        loading: action.loading,
      };
    }
    case SAVE_TEMP_VENDOR_PRICE_ITEM: {
      const tempItem = {...action.tempVendorPriceItem};
      const list = [...state.tempVendorPriceList];
      const itemIndex = list.findIndex(item => item.id === tempItem.id);
      if (itemIndex > -1) {
        const item = {...list[itemIndex]};
        list[itemIndex] = {...item, ...tempItem};
      } else {
        list.push(tempItem);
      }
      return {
        ...state,
        tempVendorPriceList: list,
      };
    }
    case REMOVE_TEMP_VENDOR_PRICE_ITEM: {
      const remainList = state.tempVendorPriceList.filter(item => item.id !== action.tempVendorPriceItem.id);
      return {
        ...state,
        tempVendorPriceList: remainList,
      };
    }
    case SAVE_TEMP_VENDOR_PRICE_LIST: {
      const list = action.tempVendorPriceList;
      return {
        ...state,
        tempVendorPriceList: list ? [...list] : [],
      };
    }
    case SAVE_VENDOR_PRICE_LIST: {
      const list = action.vendorPriceList;
      return {
        ...state,
        vendorPriceList: list ? [...list] : [],
      };
    }
    case SAVE_VENDOR_PRICE_LIST_TOTAL: {
      return {
        ...state,
        vendorPriceListTotal: action.vendorPriceListTotal,
      };
    }
    case SAVE_VENDOR_PRICE_ITEM: {
      const tempItem = {...action.vendorPriceItem};
      const list = [...state.vendorPriceList];
      const itemIndex = list.findIndex(item => item.id === tempItem.id);
      if (itemIndex > -1) {
        const item = {...list[itemIndex]};
        list[itemIndex] = {...item, ...tempItem};
      } else {
        list.push(tempItem);
      }
      return {
        ...state,
        vendorPriceList: list,
      };
    }

    case SAVE_VENDOR_PRICE_ALLOWED_ACTIONS: {
      return {
        ...state,
        vendorPriceAllowedActions: [...action.vendorPriceAllowedActions],
      };
    }
    case SAVE_VENDOR_PRICE_VISIBILITY: {
      return {
        ...state,
        vendorPriceVisibility: action.vendorPriceVisibility ? {...action.vendorPriceVisibility} : null,
      };
    }

    default:
      return state;
  }
}

export default reducer;
