import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classes from './ProductManufacturer.module.scss';

class ProductManufacturer extends PureComponent {
  render() {
    const {
      name,
      production_address,
      getcare_country,
      registration_company,
      registration_address,
    } = this.props.getcare_manufacturer;

    return(<div className={classes.Wrap}>
        <div className={classes.ColInner}>
          <div className={classes.Info}>
            <label>Nhà sản xuất</label>
            { name }
          </div>
          <div className={classes.Info}>
            <label>Địa chỉ SX</label>
            { production_address }
          </div>
          <div className={classes.Info}>
            <label>SX tại</label>
            { getcare_country && getcare_country.name }
          </div>
          <div className={classes.Info}>
            <label>Công ty đăng ký</label>
            { registration_company }
          </div>
          <div className={classes.Info}>
            <label>Địa chỉ đăng ký</label>
            { registration_address }
          </div>
        </div>
    </div>);
  }
}

ProductManufacturer.propTypes = {
  getcare_manufacturer: PropTypes.object,
};

ProductManufacturer.defaultProps = {
  getcare_manufacturer: {},
};

export default ProductManufacturer;
