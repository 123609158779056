import { allInPageSize } from 'utils/constanst/common';

export const ACTIVE = 1;
export const INACTIVE = -1;
export const statusMap = {
  [ACTIVE]: 'Đang hoạt động',
  [INACTIVE]: 'Ngừng hoạt động',
};
export const listDisplayFields = [
  {
    name: 'code',
    label: 'Mã khu vực',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'coordinator_names',
    label: 'Người điều phối KVBH',
  },
  {
    name: 'active',
    label: 'Tình trạng',
    sortable: true,
    sortDir: '',
  },
]

export const listParamsMap = {
  page_size: 50,
  page: 1,
  order: '',
}

// sales channels coordinaters
export const salesChannelCoordinaterListParamsMap = {
  page_size: allInPageSize,
  page: 1,
  order: '',
};
export const salesChannelCoordinaterDisplayFields = [
  {
    name: 'getcare_sales_code',
    label: 'Mã nhân viên',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'getcare_sales_name',
    label: 'Tên nhân viên',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'getcare_sales_phone',
    label: 'Điện thoại',
    sortable: false,
    sortDir: '',
  },
];

// allowed actions 
export const EDIT_ACTION_CODE = `edit`;
export const ASSIGN_COORDINATER_ACTION_CODE = `assign_coordinater`;
