import {
  SAVE_PRODUCT_DETAILS,
  SAVE_PRODUCT_LIST,
  SAVE_TOTAL,
  SAVE_LOADING,
  DEACTIVE_PRODUCTS,
  SAVE_RELATED_PRODUCTS,
  SAVE_MEDICINE_BY_PHARMACY_ID,
  SAVE_OTHERS_BY_PHARMACY_ID,
  SAVE_AWAITING_PRODUCTS_BY_PHARMACY_ID,
  SAVE_TOTAL_MEDICINES,
  SAVE_TOTAL_OTHERS,
  SAVE_TOTAL_AWAITING_PRODUCTS,
  SAVE_PRODUCT_BRANDS,
  SAVE_PRODUCT_INDUSTRIES,
  SAVE_AWAITING_PRODUCT,
  SAVE_PRODUCT_ALLOWED_ACTIONS,
} from 'redux/constants/admin/productConstants';

const initialState = {
  productList: [],
  total: 0,
  productDetails: null,
  isLoading: false,
  relatedProducts: [],
  medicines: null,
  medicinesTotal: 0,
  otherProducts: null,
  otherProductsTotal: 0,
  awaitingProducts: null,
  awaitingProductsTotal: 0,
  brands: [],
  industries: [],
  productAllowedActions: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_PRODUCT_DETAILS: {
      return {
        ...state,
        productDetails: { ...action.productDetails },
      };
    }
    case SAVE_PRODUCT_LIST: {
      return {
        ...state,
        productList: [ ...action.productList ],
      };
    }
    case DEACTIVE_PRODUCTS: {
      const removeIds = action.ids;
      const removedCount = removeIds.length;
      const remainProducts = state.productList.filter(item => !removeIds.includes(item.id));
      return {
        ...state,
        productList: remainProducts,
        total: state.total - removedCount,
      };
    }
    case SAVE_TOTAL: {
      return {
        ...state,
        total: action.total,
      };
    }
    case SAVE_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading,
      };
    }
    case SAVE_RELATED_PRODUCTS: {
      const relatedProducts = action.relatedProducts;
      return {
        ...state,
        relatedProducts: [...relatedProducts],
      };
    }
    case SAVE_MEDICINE_BY_PHARMACY_ID: {
      const medicines = action.medicines;
      return {
        ...state,
        medicines: medicines ? [...medicines] : null,
      };
    }
    case SAVE_TOTAL_MEDICINES: {
      return {
        ...state,
        medicinesTotal: action.medicinesTotal,
      };
    }
    case SAVE_OTHERS_BY_PHARMACY_ID: {
      const otherProducts = action.otherProducts;
      return {
        ...state,
        otherProducts: otherProducts ? [...otherProducts] : null,
      };
    }
    case SAVE_TOTAL_OTHERS: {
      return {
        ...state,
        otherProductsTotal: action.otherProductsTotal,
      };
    }
    case SAVE_AWAITING_PRODUCTS_BY_PHARMACY_ID: {
      const awaitingProducts = action.awaitingProducts;
      return {
        ...state,
        awaitingProducts: awaitingProducts ? [...awaitingProducts] : null,
      };
    }
    case SAVE_TOTAL_AWAITING_PRODUCTS: {
      return {
        ...state,
        awaitingProductsTotal: action.awaitingProductsTotal,
      };
    }
    case SAVE_AWAITING_PRODUCT: {
      const awaitingProduct = action.awaitingProduct;
      if (!awaitingProduct) return state;

      const productArr = [...state.awaitingProducts];
      const productIndex = productArr.findIndex(item => item.id === awaitingProduct.id);
      productArr[productIndex] = { ...awaitingProduct };
      return {
        ...state,
        awaitingProducts: productArr,
      };
    }
    case SAVE_PRODUCT_BRANDS: {
      const brands = action.brands;
      return {
        ...state,
        brands: brands ? [...brands] : [],
      };
    }
    case SAVE_PRODUCT_INDUSTRIES: {
      const industries = action.industries;
      return {
        ...state,
        industries: industries ? [...industries] : [],
      };
    }
    case SAVE_PRODUCT_ALLOWED_ACTIONS: {
      return {
        ...state,
        productAllowedActions: action.productAllowedActions ? [...action.productAllowedActions] : [],
      };
    }
    default:
      return state;
  }
}

export default reducer;
