export const GET_USER_LIST = 'getcare/admin/user/GET_USER_LIST';
export const GET_SALES_USER_LIST = 'getcare/admin/user/GET_SALES_USER_LIST';
export const GET_USER = 'getcare/admin/user/GET_USER';

export const UPDATE_USER = 'getcare/admin/user/UPDATE_USER';

export const GET_DEPARTMENT_LIST = 'getcare/admin/user/GET_DEPARTMENT_LIST';
export const GET_ROLE_LIST = 'getcare/admin/user/GET_ROLE_LIST';

// mutation
export const SAVE_DEPARTMENT_LIST = 'getcare/admin/user/SAVE_DEPARTMENT_LIST';
export const SAVE_ROLE_LIST = 'getcare/admin/user/SAVE_ROLE_LIST';

export const SAVE_LOADING = 'getcare/admin/user/SAVE_LOADING';
export const SAVE_ACTION_LOADING = 'getcare/admin/user/SAVE_ACTION_LOADING';

export const SAVE_USER_LIST = 'getcare/admin/user/SAVE_USER_LIST';
export const SAVE_USER_LIST_ITEM = 'getcare/admin/user/SAVE_USER_LIST_ITEM';
export const SAVE_USER_LIST_TOTAL = 'getcare/admin/user/SAVE_USER_LIST_TOTAL';

export const SAVE_SALES_USER_LIST = 'getcare/admin/user/SAVE_SALES_USER_LIST';
export const SAVE_SALES_USER_LIST_TOTAL = 'getcare/admin/user/SAVE_SALES_USER_LIST_TOTAL';

export const RESET_USER = 'getcare/admin/user/RESET_USER';

export const SAVE_ORI_USER_DETAILS = 'getcare/admin/user/SAVE_ORI_USER_DETAILS';
export const SAVE_USER_DETAILS = 'getcare/admin/user/SAVE_USER_DETAILS';

export const SAVE_ORI_USER_ROLES = 'getcare/admin/user/SAVE_ORI_USER_ROLES';
export const SAVE_USER_ROLES = 'getcare/admin/user/SAVE_USER_ROLES';
export const SAVE_USER_ROLE_ITEM = 'getcare/admin/user/SAVE_USER_ROLE_ITEM';
export const REMOVE_USER_ROLE_ITEMS = 'getcare/admin/user/REMOVE_USER_ROLE_ITEMS';
