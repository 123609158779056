import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { validDate } from 'utils/helper';
import { dateTimeFormat } from 'utils/constanst/dateConstants';
import format from 'date-fns/format';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectSalesChannelHistory } from 'redux/selectors';
import { getSalesChannelHistory } from 'redux/actions/admin/salesChannelActions';

import classes from './SalesChannelHistory.module.scss';

class SalesChannelHistory extends PureComponent {
  componentDidMount() {
    this._loadData();
  }
  componentDidUpdate(prevProps) {
    const { salesChannelId } = this.props;
    if (salesChannelId && salesChannelId !== prevProps.salesChannelId) {
      this._loadData();
    }
  }
  _loadData = () => {
    this.props.getSalesChannelHistory({
      params: { getcare_sales_channel_id: this.props.salesChannelId },
    });
  };

  render() {
    const { salesChannelHistory } = this.props;

    return (
      <div className={`${classes.Wrap}`}>
        <div className={`${classes.Inner}`}>
          { salesChannelHistory && salesChannelHistory.length > 0
            ? (<ul className={classes.EventList}>
                {salesChannelHistory.map((event, index) => (
                  <li key={`event-${index}`} className={classes.EventItem}>
                    <span className={classes.Bullet}></span>
                    <div className={classes.EventDetails}>
                      <p className={classes.EventTime}>
                        {validDate(event.date)
                          ? format(validDate(event.date), dateTimeFormat)
                          : ''}
                      </p>
                      <p
                        className={classes.EventTitle}
                      >
                        {`${event.user_name}: `}
                        <span dangerouslySetInnerHTML={{ __html: event.action }}></span>
                      </p>
                    </div>
                  </li>
                ))}
              </ul>)
            : <p className="NoData">Không có data</p>
          }
        </div>
      </div>
    );
  }
}

SalesChannelHistory.propTypes = {
  salesChannelId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

SalesChannelHistory.defaultProps = {};

const mapStateToProps = createStructuredSelector({
  salesChannelHistory: makeSelectSalesChannelHistory(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getSalesChannelHistory: (payload) => dispatch(getSalesChannelHistory(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(SalesChannelHistory);
