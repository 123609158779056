import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectUserRoles, makeSelectOriUserRoles } from 'redux/selectors';
import { saveUserRoleItem, removeUserRoleItems } from 'redux/actions/admin/userActions';
import { getDisplayFields, genID } from 'utils/helper';
import {
  userRolesDisplayFields,
  userRolesParamsMap,
  defaultRoleParams,
} from 'utils/constanst/adminUserConstants';

import UserRole from './UserRole/UserRole';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';
import Button from '@material-ui/core/Button';

import gridClasses from './UserRolesGrid.module.scss';
import classes from './UserRoles.module.scss';

class UserRoles extends PureComponent {
  handleCreateNewRow = (params) => {
    this.props.saveUserRoleItem({
      ...defaultRoleParams,
      idStr: genID(),
    });
  };
  handleSaveUserRole = (params) => {
    this.props.saveUserRoleItem({
      ...params,
    });
  };
  handleRemoveUserRole = (params) => {
    this.props.removeUserRoleItems([{...params}]);
  };

  render() {
    const { userRoles } = this.props;
    const displayFields = getDisplayFields(userRolesParamsMap, userRolesDisplayFields);

    return (
      <div className={`${classes.Wrap}`}>
        <h4 className={classes.SectionTitle}>Phân quyền nhân viên</h4>
        <div className={`${classes.List}`}>
          <div className={`${gridClasses.Row} ${classes.Header}`}>
            <ListHeaderCol className={`${gridClasses.Col} TextCenter`} label="#"/>
            {displayFields.map((item) => (
              <ListHeaderCol
                key={item.name}
                {...item}
                className={`${gridClasses.Col} ${item.className}`}
              />
            ))}
            <div className={`${gridClasses.Col} ${classes.HeaderCol} ${classes.ActionsCol}`}>
              <Button
                color="primary"
                variant="contained"
                size="small"
                onClick={this.handleCreateNewRow}
              >
                Thêm dòng
              </Button>
            </div>
          </div>
          <div className={`${classes.Body}`}>
            {userRoles && userRoles.length ? (<>
              { userRoles.map((item, index) => (
                <UserRole
                  key={`user-role-${item.idStr || item.id}`}
                  {...item}
                  index={index}
                  currentList={[...userRoles]}
                  roleList={this.props.roleList}
                  onSaveUserRole={this.handleSaveUserRole}
                  onRemoveUserRole={this.handleRemoveUserRole}
                />
              )) }
            </>) : (
              <p className="NoData">Không có data</p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

UserRoles.propTypes = {
  isEditing: PropTypes.bool,
  roleList: PropTypes.array,
};

UserRoles.defaultProps = {
  isEditing: false,
  roleList: [],
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveUserRoleItem: (payload) => dispatch(saveUserRoleItem(payload)),
    removeUserRoleItems: (payload) => dispatch(removeUserRoleItems(payload)),
  };
};
const mapStateToProps = createStructuredSelector({
  userRoles: makeSelectUserRoles(),
  oriUserRoles: makeSelectOriUserRoles(),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(UserRoles);
