import axiosAuth from 'utils/axios/axiosAuth';

const customerApi = {
  getAllCustomers: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_customer/`, {
      params: queryParams,
    });
  },
  getCustomerTypes: () => {
    return axiosAuth.get(`getcare_customer_type/`);
  },

  getAddressBook: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_customer_delivery/`, {
      params: queryParams,
    });
  },
  // add or update
  postAddress: ({ params }) => {
    return axiosAuth.post(`getcare_customer_delivery`, params);
  },
  deleteAddress: ({ params }) => {
    return axiosAuth({
      method: 'DELETE',
      url: `getcare_customer_delivery/${params.id}`,
    });
  },
};

export default customerApi;
