import axiosAuth from 'utils/axios/axiosAuth';
import { relatedProductsPageSize } from 'utils/constanst/adminProductConstants';

const ADMIN_PRODUCT_API_URL = 'getcare_product/';
const productApi = {
  get: (id) => {
    return axiosAuth.get(`${ADMIN_PRODUCT_API_URL}${id}`);
  },
  getDetailsWithParams: (id, params) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`${ADMIN_PRODUCT_API_URL}${id}`, {
      params: queryParams,
    });
  },
  getRelatedProducts: (id) => {
    return axiosAuth.get(`getcare_product_related/${id}`, {
      params: {
        page_size: relatedProductsPageSize,
      },
    });
  },
  getAll: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(ADMIN_PRODUCT_API_URL, {
      params: queryParams,
    });
  },
  deactiveProducts: (ids) => {
    return {
      data: {
        result: true,
      },
    };
  },
  deleteProducts: ({ params }) => {
    return axiosAuth({
      method: 'DELETE',
      url: `getcare_product_delete/`,
      data: { ...params },
    });
  },

  getMedicinesByPharmacyId: ({ params, pharmacyId }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(ADMIN_PRODUCT_API_URL, {
      params: {
        ...queryParams,
        getcare_pharmacy_id: pharmacyId,
      },
    });
  },

  getOtherProductsByPharmacyId: ({ params, pharmacyId }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_product_other/`, {
      params: {
        ...queryParams,
        getcare_pharmacy_id: pharmacyId,
        approval: 1,
      },
    });
  },

  getAwaitingProductsByPharmacyId: ({ params, pharmacyId }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_product_other/`, {
      params: {
        ...queryParams,
        getcare_pharmacy_id: pharmacyId,
        approval: -1,
      },
    });
  },
  updateAwaitingProduct: ({ id, params }) => {
    return axiosAuth.put(`getcare_product_other/${id}`, params);
  },
  approveProducts: ({ params }) => {
    return axiosAuth.post(`getcare_product_other_approval/`, {
      ...params,
      approval: 1,
    });
  },
  deleteOtherProducts: ({ params }) => {
    return axiosAuth({
      method: 'DELETE',
      url: `getcare_product_other_delete/`,
      data: { ...params },
    });
  },

  getProductBrands: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_product_brand/`, {
      params: queryParams,
    });
  },

  getProductIndustries: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_product_industry/`, {
      params: queryParams,
    });
  },

  exportProductsTemplate: () => {
    return axiosAuth.post(`export/getcare_product_template/`);
  },

  importProducts: ({ params }) => {
    const newParams = { ...params };
    const formData = new FormData();
    Object.keys(newParams).forEach((key) => {
      if (newParams[key]) formData.append(key, newParams[key]);
    });

    return axiosAuth.post(`import/getcare_product`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  updateProductsTrend: ({params}) => {
    return axiosAuth.post(`getcare_product_multiple_trend/`, params);
  },

  getProductSuggestions: ({ params }) => {
    return axiosAuth.get(`getcare_vendor_product_action/suggest_product?keyword=${params.getcare_product_sugget}`);
  },


  pushGetcareCategoryForProducts: ({params}) => {
    return axiosAuth.post(`getcare_category_ecom_tag_multiple_product`, params);
  },

  getVendorProductList: ({ params}) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (![null, undefined, ''].includes(params[key])) memo[key] = params[key].toString().trim();
      return memo;
    }, {});
    return axiosAuth.get(`getcare_vendor_product/`, {
      params: { ...queryParams },
    });
  },

  updateParentProduct: (params) => {
    return axiosAuth.post(`getcare_vendor_product_action/tag_parent_product`, params.params);
  },

  updateProductsGetcareCategory: ({params}) => {
    return axiosAuth.post(`getcare_product_tag_multiple_category_ecom`, params);
  },
};

export default productApi;
