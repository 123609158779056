import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { filterFields } from 'utils/constanst/adminProductConstants';

import * as QueryString from 'query-string';

import FilterField from 'components/FilterField/FilterField';
import GetcareCategoryFormAutocomplete from 'components/admin/GetcareCategory/FormAutocomplete/FormAutocomplete';

import classes from './ProductFilters.module.scss';

class ProductFilters extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fields: [...filterFields],
    };
  }
  componentDidMount() {
    const params = QueryString.parse(this.props.location.search);
    let fields = [...this.state.fields];
    let _isChangedField = false;
    fields = fields.map( item => {
      if ( params[item.name] ) {
        item.value = Array.isArray(params[item.name]) ? params[item.name].map( v => Number(v) ) : [Number(params[item.name])];
        _isChangedField = true;
      } return item;
    })
    if ( _isChangedField ) {
      this.setState({ fields });
      this.props.onFilterChange(fields, true);
    }
  }

  _getFieldIndex = (fieldName) => {
    return this.state.fields.findIndex(item => item.name === fieldName);
  }

  handleFieldChange = ({ name, value }) => {
    const newFields = this.state.fields.map(field => field.name === name ? { ...field, value } : field);
    this.setState({
      fields: newFields,
    });
    this.props.onFilterChange(newFields, true);
  }

  render() {
    const { fields } = this.state;

    return (
      <div className={classes.Panel}>
        <h3 className={classes.PanelTitle}>Lọc theo:</h3>
        <div className={classes.PanelContent}>
          {fields.map((field) => {
            let customField;
            switch (field.name) {
              case 'getcare_category_ecom_id': {
                customField = <GetcareCategoryFormAutocomplete
                  {...field} 
                  multiple
                  disableCloseOnSelect
                  onChange={(e,value) => {
                    this.handleFieldChange({ name: 'getcare_category_ecom_id', value: value });
                  }}
                />
                break;
              }
            }
            return (
              <FilterField 
                key={field.name} 
                {...field} 
                handleFieldChange={this.handleFieldChange}
                customField={customField} 
              />
            )
          })}
        </div>
      </div>
    );
  }
}

ProductFilters.propTypes = {
  onFilterChange: PropTypes.func,
};

export default withRouter(ProductFilters);
