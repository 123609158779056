import axiosAuth from 'utils/axios/axiosAuth';

const productBonusApi = {
  getProductBonusList: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (![null, undefined, ''].includes(params[key])) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_product_bonus/`, {
      params: queryParams,
    });
  },
  updateProductBonus: ({ params }) => {
    return axiosAuth.post(`getcare_product_bonus/`, params);
  },

  exportProductBonusListTemplate: () => {
    return axiosAuth.post(`export/getcare_product_bonus_template`);
  },
  importProductBonuses: ({ params }) => {
    const newParams = { ...params };
    const formData = new FormData();
    Object.keys(newParams).forEach((key) => {
      if (newParams[key]) formData.append(key, newParams[key]);
    });

    return axiosAuth.post(`import/getcare_product_bonus`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
};

export default productBonusApi;
