import {
  GET_INGREDIENT_LIST,
  SAVE_INGREDIENT_LIST,
  SAVE_INGREDIENT_TOTAL,
  GET_QUEUE_INGREDIENT_LIST,
  SAVE_QUEUE_INGREDIENT_LIST,
  SAVE_QUEUE_INGREDIENT_TOTAL,
  UPDATE_QUEUE_INGREDIENT_ITEM,
  APPROVE_QUEUE_INGREDIENT_ITEM,
  DELETE_QUEUE_INGREDIENT_LIST,
  SAVE_QUEUE_INGREDIENT_ITEM,
  REMOVE_QUEUE_INGREDIENT_ITEM,
  SAVE_LOADING,
} from 'redux/constants/admin/ingredientConstants';

export function getIngredientList(payload) {
  return {
    type: GET_INGREDIENT_LIST,
    payload
  };
}
export function getQueueIngredientList(payload) {
  return {
    type: GET_QUEUE_INGREDIENT_LIST,
    payload
  };
}
export function updateQueueIngredientItem(payload) {
  return {
    type: UPDATE_QUEUE_INGREDIENT_ITEM,
    payload
  };
}
export function approveQueueIngredientItem(payload) {
  return {
    type: APPROVE_QUEUE_INGREDIENT_ITEM,
    payload
  };
}
export function deleteQueueIngredientItem(payload) {
  return {
    type: DELETE_QUEUE_INGREDIENT_LIST,
    payload
  };
}

// mutation
export function saveActionLoading(loading) {
  return {
    type: SAVE_LOADING,
    loading
  };
}
export function saveIngredientList(ingredientList) {
  return {
    type: SAVE_INGREDIENT_LIST,
    ingredientList
  };
}
export function saveIngredientTotal(ingredientTotal) {
  return {
    type: SAVE_INGREDIENT_TOTAL,
    ingredientTotal
  };
}
export function saveQueueIngredientList(queueIngredientList) {
  return {
    type: SAVE_QUEUE_INGREDIENT_LIST,
    queueIngredientList
  };
}
export function saveQueueIngredientTotal(queueIngredientTotal) {
  return {
    type: SAVE_QUEUE_INGREDIENT_TOTAL,
    queueIngredientTotal
  };
}
export function saveQueueIngredientItem(queueIngredient) {
  return {
    type: SAVE_QUEUE_INGREDIENT_ITEM,
    queueIngredient
  };
}
export function removeQueueIngredientItem(queueIngredient) {
  return {
    type: REMOVE_QUEUE_INGREDIENT_ITEM,
    queueIngredient
  };
}
