import React, { PureComponent } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectCountryList,
  makeSelectProvinceList,
  makeSelectDistrictList,
  makeSelectWardList,
} from 'redux/selectors';
import {
  getCountryList,
  getProvinceList,
  getDistrictList,
  getWardList,
} from 'redux/actions/locationActions';
import { VN_ID } from 'utils/constanst/locationConstants';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import classes from './StoreDeliveryAddressForm.module.scss';


class StoreDeliveryAddressForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedCountryId:  props.selectedAddress?.getcare_country?.id,
      selectedProvinceId: props.selectedAddress?.getcare_province?.id,
      selectedDistrictId: props.selectedAddress?.getcare_district?.id,
      selectedWardId: props.selectedAddress?.getcare_ward?.id,
    };
  }


  componentDidMount() {
    const { selectedProvinceId, selectedDistrictId,selectedCountryId } = this.state;
    this.props.getCountryList({
      params: {},
    });
    this.props.getProvinceList({
      params: { name: '', getcare_country_id: selectedCountryId },
    });
    this.props.getDistrictList({
      params: {
        name: '',
        getcare_province_id: selectedProvinceId,
      },
    });
    this.props.getWardList({
      params: {
        name: '',
        getcare_district_id: selectedDistrictId,
      },
    });
  }
  componentDidUpdate(prevProps, prevState) {
    const { selectedProvinceId, selectedDistrictId,selectedCountryId } = this.state;
    if (selectedCountryId !== prevState.selectedCountryId) {
      this.props.getProvinceList({
        params: { name: '', getcare_country_id: selectedCountryId },
      });
    }
    if (selectedProvinceId !== prevState.selectedProvinceId) {
      this.props.getDistrictList({
        params: { name: '', getcare_province_id: selectedProvinceId },
      });
    }
    if (selectedDistrictId !== prevState.selectedDistrictId) {
      this.props.getWardList({
        params: { name: '', getcare_district_id: selectedDistrictId },
      });
    }
  }

  handleCancel = () => {
    this.props.onCancel();
  }
  handleSubmit = (values) => {
    const phoneValue = values.phone;
    this.props.onSubmit({
      id: this.props.selectedAddress?.id,
      label: values.label,
      name: values.name,
      phone: (/^(84)/).test(phoneValue) ? phoneValue.replace(/^(84)/, '+84') : phoneValue,
      address: values.address,
      getcare_country_id: values.getcare_country_id,
      getcare_province_id: values.getcare_province_id || null,
      getcare_district_id: values.getcare_district_id || null,
      getcare_ward_id: values.getcare_ward_id || null,
      default: values.default,
    });
  }

  render() {
    const {
      countryList,
      provinceList,
      districtList,
      wardList,
      selectedAddress,
      isNew,
    } = this.props;

    return (<Formik
      initialValues={{
        label: selectedAddress?.label || '',
        name: selectedAddress?.name || '',
        phone: selectedAddress?.phone || '',
        address: selectedAddress?.address || '',
        getcare_country: selectedAddress?.getcare_country || {
          "name":"Viet Nam",
          "id": 220
        },
        getcare_country_id: selectedAddress?.getcare_country?.id|| VN_ID,
        getcare_province: selectedAddress?.getcare_province || null,
        getcare_province_id: selectedAddress?.getcare_province?.id || '',
        getcare_district: selectedAddress?.getcare_district || null,
        getcare_district_id: selectedAddress?.getcare_district?.id || '',
        getcare_ward: selectedAddress?.getcare_ward || null,
        getcare_ward_id: selectedAddress?.getcare_ward?.id || '',
        default: selectedAddress?.default || false,
      }}
      enableReinitialize
      onSubmit={(values) => {
        this.handleSubmit(values);
      }}
      validationSchema={
        Yup.object().shape({
          name: Yup.string().required('Vui lòng nhập tên người nhận').max(255, 'Tối đa 255 ký tự'),
          label: Yup.string().required('Vui lòng nhập cơ sở nhận').max(255, 'Tối đa 255 ký tự'),
          phone: Yup.string().required("Vui lòng nhập số điện thoại").max(55, 'Tối đa 55 ký tự'),
          address: Yup.string().required('Vui lòng nhập địa chỉ').max(255, 'Tối đa 255 ký tự'),
          getcare_country_id: Yup.string().trim().required('Vui lòng chọn'),
          getcare_province_id: Yup.string().trim().when('getcare_country', { 
            is: value => value && value.id === 220,
            then: Yup.string().trim().required('Vui lòng chọn'),
          }),
          getcare_district_id: Yup.string().trim().when('getcare_country', { 
            is: value => value && value.id === 220,
            then: Yup.string().trim().required('Vui lòng chọn'),
          }),
          getcare_ward_id: Yup.string().trim().when('getcare_country', { 
            is: value => value && value.id === 220,
            then: Yup.string().trim().required('Vui lòng chọn'),
          }),
          
        })
      }
    >
      {(props) => {
        const {
          values,
          errors,
          handleChange,
          handleSubmit,
          setFieldValue,
        } = props;

        return (
          <form noValidate autoComplete="off" className={classes.FormWrap}>
            <div className={classes.FieldControlGroup}>
              <div className={classes.FieldControl}>
                <label>Cơ sở nhận *</label>
                <TextField
                  fullWidth
                  autoComplete="off"
                  value={values.label}
                  placeholder="Nhập..."
                  name="label"
                  className={`${classes.Field} TextFieldFilled`}
                  error={!!errors.label}
                  helperText={errors.label}
                  onChange={handleChange}
                />
              </div>
              <div className={classes.FieldControl}>
                <label>Tên người nhận *</label>
                <TextField
                  fullWidth
                  autoComplete="off"
                  value={values.name}
                  placeholder="Nhập..."
                  name="name"
                  className={`${classes.Field} TextFieldFilled`}
                  error={!!errors.name}
                  helperText={errors.name}
                  onChange={handleChange}
                />
              </div>
              <div className={classes.FieldControl}>
                <label>Số điện thoại *</label>
                <TextField
                  fullWidth
                  autoComplete="off"
                  value={values.phone}
                  placeholder="0912345678"
                  name="phone"
                  className={`${classes.Field} TextFieldFilled`}
                  error={!!errors.phone}
                  helperText={errors.phone}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className={classes.FieldControlGroup}>
            <div className={classes.FieldControl}>
                <label>Quốc gia</label>
                <Autocomplete
                  disableClearable
                  name="country"
                  handleHomeEndKeys={false}
                  value={values.getcare_country|| null}
                  options={countryList || []}
                  getOptionLabel={(option) => option?.name || ''}
                  renderOption={(option) => option?.name}
                  getOptionSelected={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <TextField
                      placeholder="Chọn..."
                      {...params}
                      fullWidth
                      className={`${classes.Field} TextFieldFilled`}
                      error={!!errors.getcare_country_id}
                      helperText={errors.getcare_country_id}
                    />
                  )}
                  onChange={async (e, newValue) => {
                    this.setState({
                      selectedCountryId: newValue?.id,
                    });
                   await setFieldValue('getcare_country', newValue);
                   await setFieldValue('getcare_country_id', newValue?.id || '');
                   await setFieldValue('getcare_province', null);
                   await setFieldValue('getcare_province_id','',);
                   await setFieldValue('getcare_district', null);
                   await setFieldValue('getcare_district_id', '');
                   await setFieldValue('getcare_ward', null);
                   await setFieldValue('getcare_ward_id', '');
                  }}
                />
              </div>
              <div className={classes.FieldControl}>
                <label>Tỉnh/TP *</label>
                <Autocomplete
                  disableClearable
                  name="province"
                  handleHomeEndKeys={false}
                  value={values.getcare_province}
                  options={provinceList || []}
                  getOptionLabel={(option) => option?.name || ''}
                  renderOption={(option) => option?.name}
                  getOptionSelected={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <TextField
                      placeholder="Chọn..."
                      {...params}
                      fullWidth
                      className={`${classes.Field} TextFieldFilled`}
                      error={!!errors.getcare_province_id}
                      helperText={errors.getcare_province_id}
                    />
                  )}
                  onChange={(e, newValue) => {
                    this.setState({
                      selectedProvinceId: newValue?.id,
                    });
                    setFieldValue('getcare_province', newValue);
                    setFieldValue('getcare_province_id', newValue?.id || '');
                    setFieldValue('getcare_district', null);
                    setFieldValue('getcare_district_id', '');
                    setFieldValue('getcare_ward', null);
                    setFieldValue('getcare_ward_id', '');
                  }}
                />
              </div>
              <div className={classes.FieldControl}>
                <label>Quận/Huyện *</label>
                <Autocomplete
                  disableClearable
                  name="district"
                  handleHomeEndKeys={false}
                  value={values.getcare_district}
                  options={districtList || []}
                  getOptionLabel={(option) => option?.name || ''}
                  renderOption={(option) => option?.name}
                  getOptionSelected={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      placeholder="Chọn..."
                      {...params}
                      className={`${classes.Field} TextFieldFilled`}
                      error={!!errors.getcare_district_id}
                      helperText={errors.getcare_district_id}
                    />
                  )}
                  onChange={(e, newValue) => {
                    this.setState({
                      selectedDistrictId: newValue?.id,
                    });
                    setFieldValue('getcare_district', newValue);
                    setFieldValue('getcare_district_id', newValue?.id || '');
                    setFieldValue('getcare_ward', null);
                    setFieldValue('getcare_ward_id', '');
                  }}
                />
              </div>
              <div className={classes.FieldControl}>
                <label>Phường/Xã *</label>
                <Autocomplete
                  disableClearable
                  name="ward"
                  handleHomeEndKeys={false}
                  value={values.getcare_ward}
                  options={wardList || []}
                  getOptionLabel={(option) => option?.name || ''}
                  renderOption={(option) => option?.name}
                  getOptionSelected={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      className={`${classes.Field} TextFieldFilled`}
                      placeholder="Chọn..."
                      {...params}
                      error={!!errors.getcare_ward_id}
                      helperText={errors.getcare_ward_id}
                    />
                  )}
                  onChange={(e, newValue) => {
                    this.setState({
                      selectedWardId: newValue?.id,
                    });
                    setFieldValue('getcare_ward', newValue);
                    setFieldValue('getcare_ward_id', newValue?.id || '');
                  }}
                />
              </div>
            </div>
            <div className={classes.FieldControl}>
              <label>Địa chỉ *</label>
              <TextField
                fullWidth
                autoComplete="off"
                value={values.address}
                placeholder="Nhập..."
                className={`${classes.Field} TextFieldFilled`}
                color="primary"
                name="address"
                error={!!errors.address}
                helperText={errors.address}
                onChange={handleChange}
              />
            </div>
            <div className={`${classes.FieldControl} ${classes.FieldControlLabel}`}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    name="default"
                    checked={values.default}
                    onChange={(e) => {
                      setFieldValue('default', e.target.checked);
                    }}
                  />
                }
                label="Làm địa chỉ mặc định"
              />
            </div>
            <DialogActions className={classes.DialogActions}>
              <Button
                type="button"
                variant="contained"
                onClick={this.handleCancel}
              >
                Huỷ
              </Button>
              <Button
                type="button"
                variant="contained"
                onClick={handleSubmit}
                color="primary"
              >
                { isNew ? `Tạo mới` : `Cập nhật` }
              </Button>
            </DialogActions>
          </form>
        );
      }}
    </Formik>);
  }
}

const mapStateToProps = createStructuredSelector({
  countryList: makeSelectCountryList(),
  provinceList: makeSelectProvinceList(),
  districtList: makeSelectDistrictList(),
  wardList: makeSelectWardList(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getCountryList: (payload) => dispatch(getCountryList(payload)),
    getProvinceList: (payload) => dispatch(getProvinceList(payload)),
    getDistrictList: (payload) => dispatch(getDistrictList(payload)),
    getWardList: (payload) => dispatch(getWardList(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(StoreDeliveryAddressForm);
