import axiosAuth from 'utils/axios/axiosAuth';
import { APPROVE, UNAPPROVE } from 'utils/constanst/adminIngredientConstants';

const ingredientApi = {
  getAll: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_active_ingredients/`, {
      params: { ...queryParams, approval: APPROVE },
    });
  },
  tagCategoryToProducts: ({params}) => {
    return axiosAuth.post(`getcare_active_ingredients_tag_multiple_product/`, params);
  },
  tagCategoriesToProduct: ({params}) => {
    return axiosAuth.post(`getcare_product_tag_multiple_active_ingredients/`, params);
  },
  getQueueIngredients: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_active_ingredients/`, {
      params: { ...queryParams, approval: UNAPPROVE },
    });
  },
  updateIngredient: ({ id, params }) => {
    return axiosAuth.put(`getcare_active_ingredients/${id}`, params);
  },
  approveIngredient: ({ id, params }) => {
    return axiosAuth.put(`getcare_active_ingredients/${id}`, {
      ...params,
      approval: APPROVE,
    });
  },
  deleteIngredient: ({ id, params }) => {
    return axiosAuth({
      method: 'DELETE',
      url: `getcare_active_ingredients/${id}`,
      data: {...params, id: id},
    });
  },
};

export default ingredientApi;
