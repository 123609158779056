import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { filterFields, shippingCouponStatusMap } from 'utils/constanst/adminShippingCouponConstants';

import FilterField from 'components/FilterField/FilterField';
import Checkboxes from 'components/FilterField/Checkboxes';

import classes from './ShippingCouponListFilters.module.scss';

class ShippingCouponListFilters extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fields: [...filterFields],
    };
  }
  _getFieldIndex = (fieldName) => {
    return this.state.fields.findIndex((item) => item.name === fieldName);
  };
  handleFieldChange = ({ name, value }) => {
    const newFields = this.state.fields.map(field => field.name === name ? { ...field, value } : field);
    this.setState({
      fields: newFields,
    });
    this.props.onFilterChange(newFields, true);
  };

  render() {
    const { fields } = this.state;

    return (
      <div className={classes.Panel}>
        <h3 className={classes.PanelTitle}>Lọc theo:</h3>
        <div className={classes.PanelContent}>
          {fields.map((field) => (
            <FilterField
              key={field.name}
              {...field}
              defaultValue={['active'].includes(field.name) ? [] : ''}
              customField={
                field.name === 'active' ? (
                  <Checkboxes
                    name={field.name}
                    value={field.value}
                    multiple
                    valuesMap={shippingCouponStatusMap}
                    handleFieldChange={this.handleFieldChange}
                  />
                ) : ''
              }
              handleFieldChange={this.handleFieldChange}
            />
          ))}
        </div>
      </div>
    );
  }
}

ShippingCouponListFilters.propTypes = {
  onFilterChange: PropTypes.func,
};

export default ShippingCouponListFilters;
