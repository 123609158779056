import userApi from 'utils/api/admin/userApi';
import { put } from 'redux-saga/effects';
import { userActions } from 'redux/actions';
import { toast } from 'react-toastify';
import { genID } from 'utils/helper';

export function* loadDepartmentList(payload) {
  const { params } = payload.payload;
  try {
    const { data: response } = yield userApi.getDepeartmentList({ params });
    if (!response?.result) {
      return;
    }
    yield put(
      userActions.saveDepartmentList(response.data ? [...response.data] : [])
    );
  } catch (err) {
    console.log(err);
  }
}
export function* loadRoleList(payload) {
  const { params } = payload.payload;
  try {
    const { data: response } = yield userApi.getRoleList({ params });
    if (!response?.result) {
      return;
    }
    yield put(
      userActions.saveRoleList(response.data ? [...response.data] : [])
    );
  } catch (err) {
    console.log(err);
  }
}

export function* loadUserList(payload) {
  const { params } = payload.payload;
  try {
    yield put(userActions.saveLoading(true));

    const { data: response } = yield userApi.getList({ params });

    yield put(userActions.saveLoading(false));
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(
      userActions.saveUserList(response.data ? [...response.data] : [])
    );
    yield put(userActions.saveUserListTotal(response.total_records));
  } catch (err) {
    console.log(err);
  }
}
export function* loadSalesUserList(payload) {
  const { params } = payload.payload;
  try {
    yield put(userActions.saveLoading(true));

    const { data: response } = yield userApi.getSalesUserList({ params });

    yield put(userActions.saveLoading(false));
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(
      userActions.saveSalesUserList(response.data ? [...response.data] : [])
    );
    yield put(userActions.saveSalesUserListTotal(response.total_records));
  } catch (err) {
    console.log(err);
  }
}
export function* loadUser(payload) {
  try {
    yield put(userActions.saveLoading(true));

    yield put(userActions.saveOriUserDetails(null));
    yield put(userActions.saveUserDetails(null));
    yield put(userActions.saveOriUserRoles([]));
    yield put(userActions.saveUserRoles([]));

    const { data: response } = yield userApi.get(payload.id);

    yield put(userActions.saveLoading(false));

    if (!response?.result) {
      toast.error(response.message);
      return;
    }

    yield put(userActions.saveOriUserDetails(response.data ? {...response.data} : null));
    yield put(userActions.saveUserDetails(response.data ? {...response.data} : null));

    const oriRoles = response.data?.getcare_roles?.map(role => ({...role, idStr: genID()})) || [];
    yield put(userActions.saveOriUserRoles([...oriRoles]));
    yield put(userActions.saveUserRoles([...oriRoles]));
  } catch (err) {
    console.log(err);
  }
}

export function* postUser(payload) {
  const { params } = payload.payload;
  try {
    yield put(userActions.saveActionLoading(true));

    const { data: response } = params.id ? yield userApi.update({ params }) : yield userApi.create({ params });

    yield put(userActions.saveActionLoading(false));
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    toast.success(`Lưu nhân viên thành công.`);

    yield put(
      userActions.saveOriUserDetails(response.data ? {...response.data} : null)
    );
    yield put(
      userActions.saveUserDetails(response.data ? {...response.data} : null)
    );

    const oriRoles = response.data?.getcare_roles?.map(role => ({...role, idStr: genID()})) || [];
    yield put(userActions.saveOriUserRoles([...oriRoles]));
    yield put(userActions.saveUserRoles([...oriRoles]));
  } catch (err) {
    console.log(err);
  }
}
