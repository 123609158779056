import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { currencyFormatVN } from 'utils/helper';
import getcareCategoryApi from 'utils/api/admin/getcareCategoryApi';
import productApi from 'utils/api/admin/productApi';
import { toast } from 'react-toastify';
import { isEqual } from 'lodash';
import { 
  mapGetcareCategoryTreeToList,
  mapGetcareCategoryTrees,
} from 'utils/constanst/adminGetcareCategoryConstants';
import { thumbnailResize } from 'utils/constanst/adminProductConstants';
import DefaultImage from 'components/DefaultImage/DefaultImage';
import FieldEditable from 'components/FieldEditable/FieldEditable';
import Chip from '@material-ui/core/Chip';
import classes from './CommonInfo.module.scss';

class CommonInfo extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      productCommonInfoMap: {
        getcare_product_category_ecom_items: [],
      },
      getcareCategoryOptions: [],
      isLoadingGetcareCategory: true,
    };
  }

  componentDidMount() {
    this._loadGetcareCategory();
  }

  componentDidUpdate(prevProps) {
    const { getcare_product_category_ecom_items } = this.props;
    const { productCommonInfoMap } = this.state;
    if ( !isEqual(getcare_product_category_ecom_items,prevProps.getcare_product_category_ecom_items) ) {
      this.setState({
        productCommonInfoMap: {
          ...productCommonInfoMap,
          getcare_product_category_ecom_items: getcare_product_category_ecom_items && getcare_product_category_ecom_items.map( item => {
            return {
              id: item.getcare_category_ecom.id,
              name: item.getcare_category_ecom.name,
            }
          })
        }
      })
    }
  }

  _loadGetcareCategory = async () => {
    this.setState({ isLoadingGetcareCategory: true })
    const { data: response } = await getcareCategoryApi.getAll({});
    if (!response?.result) { return; }
    let getcareCategoryOptions = mapGetcareCategoryTreeToList(mapGetcareCategoryTrees(response.data));
    this.setState({ isLoadingGetcareCategory: false, getcareCategoryOptions })
  }

  handleSaveGetcareCategory = async (fieldMap) => {
    const { getcareCategoryOptions } = this.state;
    // const uomBaseIdWeightsMap = this._getTheSameUomBaseIdWeightsMap(uomBaseId, fieldName, fieldMap[fieldName]);
    this.setState({
      productCommonInfoMap: { 
        ...this.state.productUomsMap, 
        getcare_product_category_ecom_items: getcareCategoryOptions.filter( item =>  fieldMap.getcare_category_ecom_ids.includes(item.id)  )
      },
    });
    const { id } = this.props;
    const { data: response } = await productApi.updateProductsGetcareCategory({
      params: {
        id,
        getcare_category_ecom_ids: fieldMap.getcare_category_ecom_ids,
      },
    });
    if (!response.result || !response.data) {
      toast.error(response.message);
      return;
    }
  }

  render() {
    const {
      thumbnail,
      registration_number,
      getcare_product_price_decralations,
      name,
      expiry_date,
      expiry_date_unit,
      dosage_forms,
      getcare_id,
      code,
      isEditAllowed,
    } = this.props;
    const {
      getcareCategoryOptions,
      isLoadingGetcareCategory,
    } = this.state;
    const salePrice =
      getcare_product_price_decralations &&
      getcare_product_price_decralations[0];
    const {
      getcare_product_category_ecom_items,
    } = this.state.productCommonInfoMap;
    
    return (
      <div className={classes.Wrap}>
        {thumbnail ? (
          <div className={classes.Thumbnail}>
            <img src={`${thumbnail}?size=${thumbnailResize}`} alt={name} />
          </div>
        ) : (
          <DefaultImage className={classes.Thumbnail} />
        )}
        <div className={classes.ColInner}>
          <div className={classes.Info}>
            <label>Số đăng ký</label>
            {registration_number}
          </div>
          <div className={classes.Info}>
            <label>Giá công bố</label>
            {`${currencyFormatVN(salePrice?.sales_price)}/${salePrice?.unit}`}
          </div>
          <div className={classes.Info}>
            <label>Tên thông dụng</label>
            {name}
          </div>
          <div className={classes.Info}>
            <label>Hạn sử dụng</label>
            {expiry_date && `${expiry_date} ${expiry_date_unit}`}
          </div>
          <div className={classes.Info}>
            <label>Dạng bào chế</label>
            {dosage_forms}
          </div>
          <div className={classes.Info}>
            <label>Phahub ID</label>
            {getcare_id}
          </div>
          <div className={classes.Info}>
            <label>Mã thuốc</label>
            {code}
          </div>
          <div className={classes.Info}>
            <label>Danh mục</label>
            { (isEditAllowed && !isLoadingGetcareCategory) ? (
                <FieldEditable
                  fieldName="getcare_category_ecom_ids"
                  value={
                    getcare_product_category_ecom_items ? getcare_product_category_ecom_items.map( item => item.id ) : []
                  }
                  displayedValue={
                    <div className={classes.GetcareCategory}> {
                      getcare_product_category_ecom_items && getcare_product_category_ecom_items.map( 
                        item => <Chip size="small" label={item.name }/>
                      )
                    }
                    </div>
                  }
                  options={getcareCategoryOptions}
                  multiple
                  disableCloseOnSelect
                  fieldType="select"
                  onSave={(fieldMap) => this.handleSaveGetcareCategory(fieldMap)}
                  onChange={() => { return false; }}
                />
              ) : ""
            }
          </div>
        </div>
      </div>
    );
  }
}

CommonInfo.propTypes = {
  thumbnail: PropTypes.string,
  registration_number: PropTypes.string,
  getcare_product_price_decralations: PropTypes.array,
  name: PropTypes.string,
  expiry_date: PropTypes.number,
  expiry_date_unit: PropTypes.string,
  dosage_forms: PropTypes.string,
  getcare_id: PropTypes.string,
  code: PropTypes.string,
};

CommonInfo.defaultProps = {
  thumbnail: '',
  registration_number: '',
  getcare_product_price_decralations: [],
  name: '',
  expiry_date: 0,
  expiry_date_unit: '',
  dosage_forms: '',
  getcare_id: '',
  code: '',
};

export default CommonInfo;
