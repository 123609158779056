import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import salesChannelApi from 'utils/api/admin/salesChannelApi';

import Checkbox from '@material-ui/core/Checkbox';
import EditOutlined from '@material-ui/icons/EditOutlined';
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import IconButton from '@material-ui/core/IconButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import gridClasses from '../GroupCustomerMappingsGrid.module.scss';
import classes from './GroupCustomerMappingsItem.module.scss';

class GroupCustomerMappingsItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: !props.id,
      codeInput: props.sales_channel?.code || '',
      countInput: props.count,
      selectedChannel: props.sales_channel || null,
      channelOptions: props.sales_channel ? [{
        ...props.sales_channel,
        count: props.count || '',
      }] : [],
    };
  }
  _resetState = () => {
    this.setState({
      isEditing: !this.props.id,
      codeInput: this.props.sales_channel?.code || '',
      channelOptions: this.props.sales_channel ? [{
        ...this.props.sales_channel,
        count: this.props.count || '',
      }] : [],
    });
  }
  stopPropagation = (e) => {
    e.stopPropagation();
  };
  handleCheckboxChange = (e) => {
    e.stopPropagation();
    this.props.onItemSelectedToggle({
      item: {...this.props},
      isSelected: !this.props.isSelected,
    });
  };
  componentDidUpdate(prevProps, prevState) {
    const { codeInput } = this.state;
    if (codeInput && codeInput !== prevState.codeInput) {
      this.loadSuggestedChannels({
        params: {
          code: codeInput,
        },
      });
    }
  }
  loadSuggestedChannels = debounce(async ({ params }) => {
    const { data: response } = await salesChannelApi.getAll({ params });
    if (!response?.result) return;
    this.setState({
      channelOptions: response.data ? this.filteredOptions([...response.data]) : [],
    });
  }, 500)
  filteredOptions = (fullArr) => {
    return fullArr.filter(p => {
      return (p.id === this.props.sales_channel?.id
        || !this.props.currentList.some(item => item.sales_channel?.id === p.id)
      );
    });
  }
  isRowValid = () => {
    return !!this.state.selectedChannel;
  }
  handleEdit = () => {
    this.setState({
      isEditing: true,
    });
  }
  handleCancel = () => {
    this._resetState();
    if (!this.props.id) {
      this.props.removeGroupCustomerMapping({...this.props});
    }
  }
  handleSave = () => {
    const { selectedChannel } = this.state;
    if (!this.isRowValid()) return;
    this.props.saveGroupCustomerMapping({
      groupCustomerMappingId: this.props.id || null,
      params: {
        getcare_sales_channel_id: Number(selectedChannel.id),
        idStr: this.props.idStr || undefined,
      },
    });
    this.setState({
      isEditing: false,
    });
  }
  handleRowClick = () => {
    if (!this.state.isEditing) {
      this.handleEdit();
    }
  }
  handleRowBlur = () => {
    if (this.state.isEditing && this.isRowValid()) {
      this.handleSave();
    }
  }
  handleSelectedChannelChange = (e, newValue) => {
    this.setState({ selectedChannel: newValue });
  }

  render() {
    const {
      sales_channel,
      count,
      isSelected,
      isGroupInactive,
    } = this.props;
    const { isEditing, selectedChannel, channelOptions } = this.state;

    return (<ClickAwayListener onClickAway={this.handleRowBlur}>
      <div
        className={`${classes.Item} ${gridClasses.Row}`}
        onClick={!isGroupInactive ? this.handleRowClick : null}
      >
        <div className={gridClasses.Col}>
          <Checkbox
            disabled={isGroupInactive}
            className={classes.Checkbox}
            checked={isSelected}
            onClick={this.stopPropagation}
            onChange={this.handleCheckboxChange}
          />
        </div>
        <div className={gridClasses.Col}>
          { isEditing
            ? (<Autocomplete
                openOnFocus
                selectOnFocus
                disableClearable
                size="small"
                handleHomeEndKeys={false}
                value={selectedChannel || null}
                onChange={this.handleSelectedChannelChange}
                options={channelOptions || []}
                filterOptions={(x) => x}
                renderOption={(option) => `${option.code}`}
                getOptionLabel={(option) => (option && option.code) || ''}
                getOptionSelected={(option, value) => (value && value.id && option) ? option.id === value.id : null}
                onInputChange={(e, newInputValue) => {
                  this.setState({
                    codeInput: newInputValue,
                  });
                }}
                renderInput={(params) => <TextField {...params} placeholder="- Chọn -" />}
              />)
            : sales_channel?.code
          }
        </div>
        <div className={gridClasses.Col}>
          {isEditing ? selectedChannel?.count : count}
        </div>
        <div className={`${gridClasses.Col} ${classes.ActionsCol}`}>
          { isEditing
            ? (<>
                <IconButton
                  disabled={isGroupInactive}
                  onClick={this.handleCancel}
                  style={{ padding: '3px' }}
                  size="small"
                ><CancelOutlined fontSize="small" color="secondary"/></IconButton>
                <IconButton
                  onClick={this.handleSave}
                  disabled={isGroupInactive || !this.isRowValid()}
                  style={{ padding: '3px' }}
                  size="small"
                ><CheckCircleOutlineOutlined fontSize="small" color={ this.isRowValid() ? `primary` : `inherit`}/></IconButton>

              </>)
            : (<>
                <IconButton
                  disabled={isGroupInactive}
                  onClick={this.handleEdit}
                  style={{ padding: '3px' }}
                  size="small"
                ><EditOutlined fontSize="small" color="primary"/></IconButton>
              </>)
          }
        </div>
      </div>
      </ClickAwayListener>);
  }
}

GroupCustomerMappingsItem.propTypes = {
  isGroupInactive: PropTypes.bool,
  idStr: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sales_channel: PropTypes.object,
  count: PropTypes.number,
  currentList: PropTypes.array,
  isSelected: PropTypes.bool,
  saveGroupCustomerMapping: PropTypes.func,
  removeGroupCustomerMapping: PropTypes.func,
  onItemSelectedToggle: PropTypes.func,
};

GroupCustomerMappingsItem.defaultProps = {
  currentList: [],
  isGroupInactive: false,
  isSelected: false,
};

export default GroupCustomerMappingsItem;
