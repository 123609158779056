export const listPageSizes = [50, 100, 150];
export const suggestionPageSize = 250;
export const allInPageSize = 1000;
export const suggestionPageSize2 = 20;

export const DEFAULT_PAGE = `/products`;

export const TOOLTIP_ROW_MAX = 10;

export const ACTIVE = 1;
export const DEACTIVE = -1;
export const TRUE = 1;
export const FALSE = 0;

export const activeStatusMap = {
  [ACTIVE]: 'Đang hoạt động',
  [DEACTIVE]: 'Ngừng hoạt động',
};

export const MODE_ALL_OPTIONS = 1;
export const MODE_SOME_OPTIONS = 2;
export const modeOptionsMap = {
  [MODE_ALL_OPTIONS]: 'Tất cả',
};

export const vats = [0, 5, 10];
export const vatsOptions = [
  {
    id: 0,
    name: `${0}%`,
  },
  {
    id: 5,
    name: `${5}%`,
  },
  {
    id: 10,
    name: `${10}%`,
  },
]
