import React, { PureComponent } from 'react';
import {
  listDisplayFields,
} from 'utils/constanst/adminShipperConstants';

import ShipperFilters from 'components/admin/ShipperList/ShipperFilters/ShipperFilters';
import ListPagination from 'components/ListPagination/ListPagination';
import ShipperList from 'components/admin/ShipperList/ShipperList';
import shipperApi from 'utils/api/admin/shipperApi';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';

import classes from './Shippers.module.scss';

class Shippers extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      shippers: [],
      filters: [
        { name: 'page', value: 1 },
        { name: 'page_size', value: 50 },
      ],
      total_records: 0,
    }
  }

  componentDidMount() {
    this.loadData(this.state.filters);
  }

  onFilterChange = (filters) => {
    // Merge current filters with new ones
    let merged = {};
    this.state.filters.forEach(current => {
      merged = {
        ...merged,
        [current.name]: current.value,
      }
    })
    filters.forEach(other => {
      merged = {
        ...merged,
        [other.name]: other.value,
      }
    })
    const newFilters = [];
    Object.keys(merged).forEach(key => {
      newFilters.push({
        name: key,
        value: merged[key],
      })
    })

    // Apply new filters
    this.setState({
      filters: newFilters,
    })

    this.loadData(newFilters);
  }

  loadData = async (filters) => {
    const shippers = await shipperApi.getShippers(filters);
    if (shippers) {
      const { data, total_records } = shippers;
      this.setState({
        shippers: data,
        total_records,
      })
    }
  }

  onCreateNew = () => {
    this.props.history.push(`/shipper/new`);
  }

  render() {
    const { shippers, filters, total_records } = this.state;
    let page, pageSize;
    filters.forEach(filter => {
      if (filter.name === 'page') {
        page = filter.value;
      } else if (filter.name === 'page_size') {
        pageSize = filter.value;
      }
    })
    return (
      <React.Fragment>
        <div className={classes.PageWrap}>
          <div className={classes.PageHeader}>
            <h1 className={classes.PageTitle}>Danh sách nhân viên giao hàng</h1>
            <Button
              size="small"
              variant="contained"
              color="primary"
              startIcon={<Add />}
              onClick={this.onCreateNew}>
              Thêm mới
            </Button>
          </div>
          <div className={classes.PageMain}>
            <ShipperFilters onFilterChange={this.onFilterChange} />
            <ShipperList
              displayFields={listDisplayFields}
              shipperList={shippers}
            />
          </div>
          <div className={classes.PageFooter}>
            <ListPagination
              page={page}
              pageSize={pageSize}
              total={total_records}
              listName="nhân viên"
              onFilterChange={this.onFilterChange}
            />
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withRouter(Shippers);
