import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { debounce } from 'lodash';
import {
  saveStore,
  getStoreList,
  exportStoreListTemplate,
  saveStoreAllowedActions,
} from 'redux/actions/admin/storeActions';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectStoreList,
  makeSelectStoreListLoading,
  makeSelectStoreListTotal,
  makeSelectStoreAllowedActions,
} from 'redux/selectors';
import {
  listParamsMap,
  listDisplayFields,
} from 'utils/constanst/adminStoreConstants';
import { withRouter } from 'react-router-dom';
import { isActionAllowed } from 'utils/helper/authorization';
import authApi from 'utils/api/authApi';
import { toast } from 'react-toastify';

import StoreList from 'components/admin/StoreList/StoreList';
import StoreFilters from 'components/admin/StoreList/StoreFilters/StoreFilters';
import ListPagination from 'components/ListPagination/ListPagination';
import FileImportDialog from 'components/admin/StoreList/FileImportDialog/FileImportDialog';
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';

import classes from './Stores.module.scss';

class Stores extends PureComponent {
  constructor(props) {
    super(props);
    let listParams = {
      ...listParamsMap,
    };
    if (props.unapprovedPharmacy) {
      listParams = {
        ...listParams,
        approval: -1,
        getcare_customer_type_id: [1],
      }
    } else if (props.unapprovedDoctor) {
      listParams = {
        ...listParams,
        approval: -1,
        getcare_customer_type_id: [3],
      }
    }

    this.state = {
      listParams,
      isImportDialogOpen: false,
    };
  }

  async componentDidMount() {
    await this.getAllowedActions();

    const payload = {
      params: this.state.listParams,
    };
    this.props.getStoreList(payload);
  }

  getAllowedActions = async () => {
    const { data: response } = await authApi.getAllowedActions({
      component: this.props.unapprovedPharmacy ? 'unapproved_pharmacy' : this.props.unapprovedDoctor ? 'unapproved_doctor' : 'customer',
    });
    if (!response.result) {
      toast.error(response.message);
      return;
    }
    this.props.saveStoreAllowedActions(response.allowed_actions || []);
  };

  getSortsMap = (sortStr) => {
    const sortArr = sortStr.split(',');
    return sortArr.reduce((memo, item) => {
      const arr = item.split(' ');
      if (arr[0] && arr[0].trim()) {
        memo[arr[0]] = arr[1] ? arr[1] : '';
      }
      return memo;
    }, {});
  };

  getSortsString = (sortMap) => {
    return Object.keys(sortMap)
      .reduce((memo, key) => {
        memo.push(`${key} ${sortMap[key]}`);
        return memo;
      }, [])
      .join(',');
  };

  getDisplayFields = () => {
    const sortsMap = this.getSortsMap(this.state.listParams.order);
    return listDisplayFields.map((item) => ({
      ...item,
      sortDir: sortsMap[item.name],
    }));
  };

  handleFilterChange = (filters, forceResetPage: false) => {
    const paramsFromFilters = filters.reduce((memo, item) => {
      memo[item.name] = item.value;
      return memo;
    }, {});
    const newParams = forceResetPage
      ? {
          ...this.state.listParams,
          ...paramsFromFilters,
          page: 1,
        }
      : {
          ...this.state.listParams,
          ...paramsFromFilters,
        };
    this.setState({
      listParams: newParams,
    });
    this.props.getStoreList({
      params: newParams,
    });
  };

  handleSortChange = ({ sortBy, sortDir }) => {
    const newSortString = this.getSortsString({ [sortBy]: sortDir });
    this.handleFilterChange([
      {
        name: 'order',
        value: newSortString,
      },
    ]);
  };

  handleDownloadTemplate = () => {
    this.props.exportStoreListTemplate();
  };

  handleImportDialogOpen = () => {
    this.setState({
      isImportDialogOpen: true,
    });
  };

  handleImportDialogClose = () => {
    this.setState({
      isImportDialogOpen: false,
    });
  };

  handleCompleteImport = () => {
    // Reload the page with current settings (e.g.. filters)
    this.props.getStoreList({
      params: this.state.listParams,
    });
  };

  onCreateNew = () => {
    this.props.saveStore(null);
    this.props.history.push(`/customer/new`);
  }

  render() {
    const { storeList, storeListTotal, isStoreListLoading, unapprovedPharmacy, unapprovedDoctor, storeAllowedActions } = this.props;
    const { listParams, isImportDialogOpen } = this.state;
    let storeType;
    let listTitle;
    if (unapprovedPharmacy) {
      storeType = 'nhà thuốc';
      listTitle = 'Nhà thuốc chưa duyệt';
    } else if (unapprovedDoctor) {
      storeType = 'bác sĩ';
      listTitle = 'Bác sĩ chưa duyệt';
    } else {
      storeType = 'khách hàng';
      listTitle = 'Danh sách khách hàng';
    }
    const isCreateAllowed = isActionAllowed('create', storeAllowedActions);
    const isImportAllowed = isActionAllowed('import', storeAllowedActions);

    return (
      <React.Fragment>
        <div className={classes.PageWrap}>
          <div className={classes.PageHeader}>
            <h1 className={classes.PageTitle}>{listTitle}</h1>
            {!unapprovedPharmacy && !unapprovedDoctor && (
              <div className={classes.PageActions}>
                { isImportAllowed &&
                  <>
                    <Button size="small" variant="contained" onClick={this.handleImportDialogOpen}>
                      Import File
                    </Button>
                    <Button size="small" variant="contained" onClick={this.handleDownloadTemplate}>
                      Download template
                    </Button>
                  </>
                }
                { isCreateAllowed &&
                  <Button size="small" variant="contained" color="primary" startIcon={<Add />} onClick={this.onCreateNew}>
                    Thêm mới
                  </Button>
                }
              </div>
            )}
          </div>
          <div className={classes.PageMain}>
            <StoreFilters
              onFilterChange={debounce(this.handleFilterChange, 50)}
              unapprovedPharmacy={unapprovedPharmacy}
              unapprovedDoctor={unapprovedDoctor}
            />
            <StoreList
              displayFields={this.getDisplayFields()}
              storeList={storeList}
              isLoading={isStoreListLoading}
              onSortChange={this.handleSortChange}
              unapprovedPharmacy={unapprovedPharmacy}
              unapprovedDoctor={unapprovedDoctor}
            />
          </div>
          <div className={classes.PageFooter}>
            <ListPagination
              page={listParams.page}
              pageSize={listParams.page_size}
              total={storeListTotal}
              listName={storeType}
              onFilterChange={this.handleFilterChange}
            />
          </div>
        </div>
        {isImportDialogOpen && (
          <FileImportDialog
            title="Import danh sách nhà thuốc"
            isOpen={isImportDialogOpen}
            onClose={this.handleImportDialogClose}
            onCompleteImport={this.handleCompleteImport}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  storeList: makeSelectStoreList(),
  storeListTotal: makeSelectStoreListTotal(),
  isStoreListLoading: makeSelectStoreListLoading(),
  storeAllowedActions: makeSelectStoreAllowedActions(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    saveStore: (payload) => dispatch(saveStore(payload)),
    getStoreList: (payload) => dispatch(getStoreList(payload)),
    exportStoreListTemplate: (payload) => dispatch(exportStoreListTemplate(payload)),
    saveStoreAllowedActions: (payload) => dispatch(saveStoreAllowedActions(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter)(Stores);
