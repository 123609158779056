export const ACTIVE = 1;
export const DEACTIVE = -1;
export const APPROVE = 1;
export const UNAPPROVE = -1;

export const statusMap = {
  [ACTIVE]: 'Đang hoạt động',
  [DEACTIVE]: 'Ngừng hoạt động',
}

export const listDisplayFields = [
  {
    name: 'code',
    label: 'Mã nhân viên',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'name',
    label: 'Tên nhân viên',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'short_name',
    label: 'Tên thường gọi',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'phone',
    label: 'Điện thoại',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'status',
    label: 'Trạng thái',
    sortable: false,
    sortDir: '',
  },
]

export const listParamsMap = {
  page_size: 50,
  page: 1,
  code: '',
  name: '',
  other_name: '',
  phone: '',
  status: '',
}

export const filterFields = [
  {
    name: 'code',
    label: 'Mã nhân viên',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'name',
    label: 'Tên nhân viên',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'other_name',
    label: 'Tên thường gọi',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'phone',
    label: 'Số điện thoại',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'status',
    label: 'Trạng thái',
    value: '',
    type: 'custom',
    placeholder: 'Nhập...',
  },
]