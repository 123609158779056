import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import gridClasses from '../GroupMappingCustomersGrid.module.scss';
import classes from './GroupMappingCustomersItem.module.scss';

class GroupMappingCustomersItem extends PureComponent {
  render() {
    const {
      phone,
      address,
      name,
      code,
    } = this.props;

    return (<div className={`${classes.Item} ${gridClasses.Row}`}>
      <div className={gridClasses.Col}>
        { code }
      </div>
      <div className={gridClasses.Col}>
        { name }
      </div>
      <div className={gridClasses.Col}>
        { phone }
      </div>
      <div className={gridClasses.Col}>
        { address }
      </div>
    </div>);
  }
}

GroupMappingCustomersItem.propTypes = {
  isGroupInactive: PropTypes.bool,
  idStr: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  address: PropTypes.string,
  phone: PropTypes.string,
  code: PropTypes.string,
  name: PropTypes.string,
};

GroupMappingCustomersItem.defaultProps = {
  isGroupInactive: false,
};

export default GroupMappingCustomersItem;
