export const testPriceOptions = [
  {
    id: 1,
    name: 'Check giá theo khách hàng',
  },
  {
    id: 2,
    name: 'Check giá sản phẩm theo chính sách',
  },
];

export const customerPriceHeaderMap = {
  'priority_name': 'Ưu tiên',
  'price_type': 'Tính theo',
};
