import axiosAuth from 'utils/axios/axiosAuth';

const policyPriceApi = {
  getPricePriorities: () => {
    return axiosAuth.get(`getcare_policy_priority`);
  },
  getPriceUnits: () => {
    return axiosAuth.get(`getcare_policy_unit`);
  },
  getGroupCustomers: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (![null, undefined, ''].includes(params[key])) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_policy_price_customer_search/`, {
      params: queryParams,
    });
  },
  getGroupProducts: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (![null, undefined, ''].includes(params[key])) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_policy_price_product_search/`, {
      params: queryParams,
    });
  },

  getList: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (![null, undefined, ''].includes(params[key])) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_policy_price/`, {
      params: queryParams,
    });
  },
  get: (id) => {
    return axiosAuth.get(`getcare_policy_price/${id}`);
  },
  update: ({ params }) => {
    return axiosAuth.post(`getcare_policy_price/`, params);
  },

  getPolicyPriceCounts: ({ params }) => {
    return axiosAuth.post(`getcare_policy_price_count/`, params);
  },

  getPolicyPriceHistory: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (![null, undefined, ''].includes(params[key])) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_policy_price_history/`, {
      params: queryParams,
    });
  },

  exportPolicyPriceTemplate: () => {
    return axiosAuth.post(`export/getcare_policy_price_product_template/`);
  },

  importPolicyPrice: ({ params }) => {
    const newParams = { ...params };
    const formData = new FormData();
    Object.keys(newParams).forEach((key) => {
      if (newParams[key]) formData.append(key, newParams[key]);
    });

    return axiosAuth.post(`import/getcare_policy_price_product`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  checkPolicyPriceCodeDuplicated: ({ params }) => {
    return axiosAuth.post(`getcare_policy_price_check_duplicate`, params);
  },

  flushPricesCache: () => {
    return axiosAuth.post(`flush_cache/policy_price`);
  },
  flushPricesCacheVendor: (params) => {
    return axiosAuth.post(`flush_cache/policy_price?${params}`);
  },
};

export default policyPriceApi;
