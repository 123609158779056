import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import DefaultImage from 'components/DefaultImage/DefaultImage';
import { Link } from 'react-router-dom';
import { thumbnailResize } from 'utils/constanst/adminProductConstants';
import classes from './RelatedProducts.module.scss';

class RelatedProducts extends PureComponent {
  render() {
    const { relatedProducts } = this.props;

    return (
      <>
        {relatedProducts.length > 0 ? (
          <ul className={classes.List}>
            { relatedProducts.map(product => (
              <li key={product.id}>
                <Link to={`/products/${product.id}`}>
                  { product.thumbnail
                    ? (<div className={classes.Thumbnail}><img src={`${product.thumbnail}?size=${thumbnailResize}`} alt={product.name}/></div>)
                    : (<DefaultImage className={classes.Thumbnail}/>)
                  }
                  <p className={classes.Name}>{ product.name }</p>
                </Link>
              </li>)
            ) }
          </ul>)
          : (<p className="NoData">Không có sản phẩm nào</p>)
        }
      </>
    );
  }
}

RelatedProducts.propTypes = {
  relatedProducts: PropTypes.array,
};

RelatedProducts.defaultProps = {
  relatedProducts: [],
};

export default RelatedProducts;
