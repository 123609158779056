import { createSelector } from 'reselect';

// authentication
const selectAuthState = (state) => {
  return state.authReducer || {};
};
const makeSelectLoginUser = () =>
  createSelector(selectAuthState, (substate) => substate.user);
const makeSelectAuthLoading = () =>
  createSelector(selectAuthState, (substate) => substate.authLoading);
const makeSelectAuthError = () =>
  createSelector(selectAuthState, (substate) => substate.authError);

// location
const selectLocationState = (state) => {
  return state.locationReducer || {};
};

const makeSelectCountryList = () =>
  createSelector(selectLocationState, (substate) => substate.countryList);
const makeSelectProvinceList = () =>
  createSelector(selectLocationState, (substate) => substate.provinceList);
const makeSelectDistrictList = () =>
  createSelector(selectLocationState, (substate) => substate.districtList);
const makeSelectWardList = () =>
  createSelector(selectLocationState, (substate) => substate.wardList);

// products
const selectAdminProductState = (state) => {
  return state.adminProduct || {};
};
const makeSelectProductDetails = () =>
  createSelector(
    selectAdminProductState,
    (substate) => substate.productDetails
  );
const makeSelectProductList = () =>
  createSelector(selectAdminProductState, (substate) => substate.productList);
const makeSelectProductTotal = () =>
  createSelector(selectAdminProductState, (substate) => substate.total);
const makeSelectProductIsLoading = () =>
  createSelector(selectAdminProductState, (substate) => substate.isLoading);
const makeSelectRelatedProducts = () =>
  createSelector(
    selectAdminProductState,
    (substate) => substate.relatedProducts
  );
const makeSelectMedicines = () =>
  createSelector(selectAdminProductState, (substate) => substate.medicines);
const makeSelectMedicinesTotal = () =>
  createSelector(
    selectAdminProductState,
    (substate) => substate.medicinesTotal
  );
const makeSelectOtherProducts = () =>
  createSelector(selectAdminProductState, (substate) => substate.otherProducts);
const makeSelectOtherProductsTotal = () =>
  createSelector(
    selectAdminProductState,
    (substate) => substate.otherProductsTotal
  );
const makeSelectAwaitingProductList = () =>
  createSelector(
    selectAdminProductState,
    (substate) => substate.awaitingProducts
  );
const makeSelectAwaitingProductsTotal = () =>
  createSelector(
    selectAdminProductState,
    (substate) => substate.awaitingProductsTotal
  );
const makeSelectBrandList = () =>
  createSelector(selectAdminProductState, (substate) => substate.brands);
const makeSelectIndustryList = () =>
  createSelector(selectAdminProductState, (substate) => substate.industries);
const makeSelectProductAllowedActions = () =>
  createSelector(selectAdminProductState, (substate) => substate.productAllowedActions);

// ingredients (categories)
const selectAdminIngredientState = (state) => {
  return state.ingredientReducer || {};
};
const makeSelectIngredientList = () =>
  createSelector(
    selectAdminIngredientState,
    (substate) => substate.ingredientList
  );
const makeSelectIngredientTotal = () =>
  createSelector(
    selectAdminIngredientState,
    (substate) => substate.ingredientTotal
  );
const makeSelectQueueIngredientList = () =>
  createSelector(
    selectAdminIngredientState,
    (substate) => substate.queueIngredientList
  );
const makeSelectQueueIngredientTotal = () =>
  createSelector(
    selectAdminIngredientState,
    (substate) => substate.queueIngredientTotal
  );
const makeSelectQueueIngredientActionLoading = () =>
  createSelector(selectAdminIngredientState, (substate) => substate.loading);

// uoms
const selectAdminUomState = (state) => {
  return state.uomReducer || {};
};
const makeSelectUomList = () =>
  createSelector(selectAdminUomState, (substate) => substate.uomList);
const makeSelectUomBaseList = () =>
  createSelector(selectAdminUomState, (substate) => substate.uomBaseList);
const makeSelectUomTotal = () =>
  createSelector(selectAdminUomState, (substate) => substate.uomTotal);
const makeSelectUomBaseTotal = () =>
  createSelector(selectAdminUomState, (substate) => substate.uomBaseTotal);
const makeSelectProductUnits = () =>
  createSelector(selectAdminUomState, (substate) => substate.productUnits);

// vendors
const selectAdminVendorState = (state) => {
  return state.vendorReducer || {};
};
const makeSelectVendorDetails = () =>
  createSelector(selectAdminVendorState, (substate) => substate.vendorDetails);
const makeSelectVendorList = () =>
  createSelector(selectAdminVendorState, (substate) => substate.vendorList);
const makeSelectVendorListTotal = () =>
  createSelector(
    selectAdminVendorState,
    (substate) => substate.vendorListTotal
  );
const makeSelectVendorListLoading = () =>
  createSelector(
    selectAdminVendorState,
    (substate) => substate.isVendorListLoading
  );

// stores
const selectAdminStoreState = (state) => {
  return state.storeReducer || {};
};
const makeSelectStoreDetails = () =>
  createSelector(selectAdminStoreState, (substate) => substate.storeDetails);
const makeSelectStoreList = () =>
  createSelector(selectAdminStoreState, (substate) => substate.storeList);
const makeSelectStoreListTotal = () =>
  createSelector(selectAdminStoreState, (substate) => substate.storeListTotal);
const makeSelectStoreListLoading = () =>
  createSelector(selectAdminStoreState, (substate) => substate.isStoreListLoading);
const makeSelectStoreActionLoading = () =>
  createSelector(selectAdminStoreState, (substate) => substate.isStoreActionLoading);
const makeSelectStoreDeliveryAddressList = () =>
  createSelector(selectAdminStoreState, (substate) => substate.deliveryAddressList);
const makeSelectStoreAllowedActions = () =>
  createSelector(selectAdminStoreState, (substate) => substate.storeAllowedActions);

// sales channel
const selectAdminSalesChannelState = (state) => {
  return state.salesChannelReducer || {};
};
const makeSelectSalesChannelList = () =>
  createSelector(
    selectAdminSalesChannelState,
    (substate) => substate.salesChannelList
  );
const makeSelectSalesChannelListTotal = () =>
  createSelector(
    selectAdminSalesChannelState,
    (substate) => substate.salesChannelListTotal
  );
const makeSelectSalesChannel = () =>
  createSelector(
    selectAdminSalesChannelState,
    (substate) => substate.salesChannel
  );
const makeSelectSalesChannelCoordinaters = () =>
  createSelector(
    selectAdminSalesChannelState,
    (substate) => substate.salesChannelCoordinaters
  );
const makeSelectSalesChannelHistory = () =>
  createSelector(
    selectAdminSalesChannelState,
    (substate) => substate.salesChannelHistory
  );
const makeSelectSalesChannelAllowedActions = () =>
  createSelector(
    selectAdminSalesChannelState,
    (substate) => substate.salesChannelAllowedActions
  );

// sales team
const selectAdminSalesTeamState = (state) => {
  return state.salesTeamReducer || {};
};
const makeSelectSalesTeamList = () =>
  createSelector(
    selectAdminSalesTeamState,
    (substate) => substate.salesTeamList
  );
const makeSelectSalesTeamListTotal = () =>
  createSelector(
    selectAdminSalesTeamState,
    (substate) => substate.salesTeamListTotal
  );
const makeSelectSalesTeamDetails = () =>
  createSelector(
    selectAdminSalesTeamState,
    (substate) => substate.salesTeamDetails
  );
const makeSelectSalesTeamChannels = () =>
  createSelector(
    selectAdminSalesTeamState,
    (substate) => substate.salesTeamChannels
  );
const makeSelectSalesTeamMembers = () =>
  createSelector(
    selectAdminSalesTeamState,
    (substate) => substate.salesTeamMembers
  );
const makeSelectSalesRoles = () =>
  createSelector(selectAdminSalesTeamState, (substate) => substate.salesRoles);
const makeSelectSalesTeamKeyAccounts = () =>
  createSelector(
    selectAdminSalesTeamState,
    (substate) => substate.salesTeamKeyAccounts
  );

// group
const selectAdminGroupState = (state) => {
  return state.groupReducer || {};
};
const makeSelectGroupList = () =>
  createSelector(selectAdminGroupState, (substate) => substate.groupList);
const makeSelectGroupTotal = () =>
  createSelector(selectAdminGroupState, (substate) => substate.groupTotal);
const makeSelectGroupLoading = () =>
  createSelector(selectAdminGroupState, (substate) => substate.loading);
const makeSelectGroupInfo = () =>
  createSelector(selectAdminGroupState, (substate) => substate.groupInfo);

const makeSelectGroupProducts = () =>
  createSelector(selectAdminGroupState, (substate) => substate.groupProducts);

const makeSelectGroupVendors = () =>
  createSelector(selectAdminGroupState, (substate) => substate.groupVendors);

const makeSelectGroupCustomers = () =>
  createSelector(selectAdminGroupState, (substate) => substate.groupCustomers);
const makeSelectGroupCustomersTotal = () =>
  createSelector(
    selectAdminGroupState,
    (substate) => substate.groupCustomersTotal
  );

const makeSelectGroupCustomerMappings = () =>
  createSelector(
    selectAdminGroupState,
    (substate) => substate.groupCustomerMappings
  );

const makeSelectCustomerTypes = () =>
  createSelector(selectAdminGroupState, (substate) => substate.customerTypes);

// vendor price list
const selectAdminVendorPriceState = (state) => {
  return state.vendorPriceReducer || {};
};
const makeSelectVendorPriceList = () =>
  createSelector(
    selectAdminVendorPriceState,
    (substate) => substate.vendorPriceList
  );
const makeSelectTempVendorPriceList = () =>
  createSelector(
    selectAdminVendorPriceState,
    (substate) => substate.tempVendorPriceList
  );
const makeSelectVendorPriceListTotal = () =>
  createSelector(
    selectAdminVendorPriceState,
    (substate) => substate.vendorPriceListTotal
  );
const makeSelectVendorPriceLoading = () =>
  createSelector(selectAdminVendorPriceState, (substate) => substate.loading);

const makeSelectVendorPriceAllowedActions = () =>
  createSelector(selectAdminVendorPriceState, (substate) => substate.vendorPriceAllowedActions);
const makeSelectVendorPriceVisibility = () =>
  createSelector(selectAdminVendorPriceState, (substate) => substate.vendorPriceVisibility);

// policy price list
const selectAdminPolicyPriceState = (state) => {
  return state.policyPriceReducer || {};
};

const makeSelectPricePiorities = () =>
  createSelector(
    selectAdminPolicyPriceState,
    (substate) => substate.pricePiorities
  );
const makeSelectPriceUnits = () =>
  createSelector(
    selectAdminPolicyPriceState,
    (substate) => substate.priceUnits
  );

const makeSelectPolicyPriceList = () =>
  createSelector(
    selectAdminPolicyPriceState,
    (substate) => substate.policyPriceList
  );
const makeSelectPolicyPriceListTotal = () =>
  createSelector(
    selectAdminPolicyPriceState,
    (substate) => substate.policyPriceListTotal
  );
const makeSelectPolicyPriceLoading = () =>
  createSelector(selectAdminPolicyPriceState, (substate) => substate.loading);

const makeSelectOriginalPolicyPriceDetails = () =>
  createSelector(
    selectAdminPolicyPriceState,
    (substate) => substate.originalPolicyPriceDetails
  );
const makeSelectPolicyPriceDetails = () =>
  createSelector(
    selectAdminPolicyPriceState,
    (substate) => substate.policyPriceDetails
  );

const makeSelectPolicyPriceProducts = () =>
  createSelector(
    selectAdminPolicyPriceState,
    (substate) => substate.policyPriceProducts
  );
const makeSelectTempPolicyPriceProducts = () =>
  createSelector(
    selectAdminPolicyPriceState,
    (substate) => substate.tempPolicyPriceProducts
  );

const makeSelectPolicyPriceCustomers = () =>
  createSelector(
    selectAdminPolicyPriceState,
    (substate) => substate.policyPriceCustomers
  );
const makeSelectTempPolicyPriceCustomers = () =>
  createSelector(
    selectAdminPolicyPriceState,
    (substate) => substate.tempPolicyPriceCustomers
  );

const makeSelectPolicyPriceChannels = () =>
  createSelector(
    selectAdminPolicyPriceState,
    (substate) => substate.policyPriceChannels
  );
const makeSelectTempPolicyPriceChannels = () =>
  createSelector(
    selectAdminPolicyPriceState,
    (substate) => substate.tempPolicyPriceChannels
  );

const makeSelectPolicyPriceCustomerTypes = () =>
  createSelector(
    selectAdminPolicyPriceState,
    (substate) => substate.policyPriceCustomerTypes
  );
const makeSelectTempPolicyPriceCustomerTypes = () =>
  createSelector(
    selectAdminPolicyPriceState,
    (substate) => substate.tempPolicyPriceCustomerTypes
  );

const makeSelectPolicyPriceHistory = () =>
  createSelector(
    selectAdminPolicyPriceState,
    (substate) => substate.policyPriceHistory
  );

// websocket
const selectWSState = (state) => {
  return state.wsReducer || {};
};
const makeSelectGlobalWS= () =>
  createSelector(
    selectWSState,
    (substate) => substate.ws
  );
const makeSelectWSEvents = () =>
  createSelector(
    selectWSState,
    (substate) => substate.events
  );

// vendor product
const selectVendorProductState = (state) => {
  return state.vendorProductReducer || {};
};
const makeSelectVendorProductList = () =>
  createSelector(
    selectVendorProductState,
    (substate) => substate.vendorProductList
  );
const makeSelectVendorProductListTotal = () =>
  createSelector(
    selectVendorProductState,
    (substate) => substate.vendorProductListTotal
  );
const makeSelectVendorProductLoading = () =>
  createSelector(
    selectVendorProductState,
    (substate) => substate.loading
  );
const makeSelectVendorProductUnmap = () =>
  createSelector(
    selectVendorProductState,
    (substate) => substate.unmap
  );

const makeSelectVendorProductApproval = () =>
  createSelector(
    selectVendorProductState,
    (substate) => substate.approval
  );
const makeSelectVendorProductActionLoading = () =>
  createSelector(
    selectVendorProductState,
    (substate) => substate.actionLoading
  );
const makeSelectOriVendorProductList = () =>
  createSelector(
    selectVendorProductState,
    (substate) => substate.oriVendorProductList
  );
const makeSelectVendorProductAllowedActions = () =>
  createSelector(selectVendorProductState, (substate) => substate.vendorProductAllowedActions);
const makeSelectVendorProductVisibility = () =>
  createSelector(selectVendorProductState, (substate) => substate.vendorProductVisibility);

// product bonus
const selectProductBonusState = (state) => {
  return state.productBonusReducer || {};
};
const makeSelectProductBonusList = () =>
  createSelector(
    selectProductBonusState,
    (substate) => substate.productBonusList
  );
const makeSelectProductBonusListTotal = () =>
  createSelector(
    selectProductBonusState,
    (substate) => substate.productBonusListTotal
  );
const makeSelectProductBonusLoading = () =>
  createSelector(
    selectProductBonusState,
    (substate) => substate.loading
  );
const makeSelectProductBonusActionLoading = () =>
  createSelector(
    selectProductBonusState,
    (substate) => substate.actionLoading
  );

// shipping coupon
const selectShippingCouponState = (state) => {
  return state.shippingCouponReducer || {};
};

const makeSelectShippingCouponList = () =>
  createSelector(
    selectShippingCouponState,
    (substate) => substate.shippingCouponList
  );
const makeSelectShippingCouponListTotal = () =>
  createSelector(
    selectShippingCouponState,
    (substate) => substate.shippingCouponListTotal
  );

const makeSelectShippingCouponLoading = () =>
  createSelector(
    selectShippingCouponState,
    (substate) => substate.loading
  );

const makeSelectOriShippingCouponDetails = () =>
  createSelector(
    selectShippingCouponState,
    (substate) => substate.oriShippingCouponDetails
  );
const makeSelectShippingCouponDetails = () =>
  createSelector(
    selectShippingCouponState,
    (substate) => substate.shippingCouponDetails
  );

const makeSelectOriShippingCouponRules = () =>
  createSelector(
    selectShippingCouponState,
    (substate) => substate.oriShippingCouponRules
  );
const makeSelectShippingCouponRules = () =>
  createSelector(
    selectShippingCouponState,
    (substate) => substate.shippingCouponRules
  );

const makeSelectOriShippingCouponSalesChannels = () =>
  createSelector(
    selectShippingCouponState,
    (substate) => substate.oriShippingCouponSalesChannels
  );
const makeSelectShippingCouponSalesChannels = () =>
  createSelector(
    selectShippingCouponState,
    (substate) => substate.shippingCouponSalesChannels
  );

const makeSelectOriShippingCouponCustomers = () =>
  createSelector(
    selectShippingCouponState,
    (substate) => substate.oriShippingCouponCustomers
  );
const makeSelectShippingCouponCustomers = () =>
  createSelector(
    selectShippingCouponState,
    (substate) => substate.shippingCouponCustomers
  );

const makeSelectShippingCouponHistory = () =>
  createSelector(
    selectShippingCouponState,
    (substate) => substate.shippingCouponHistory
  );

// test shipping coupon
const selectTestShippingCouponState = (state) => {
  return state.testShippingCouponReducer || {};
};

const makeSelectTestShippingCouponLoading = () =>
  createSelector(
    selectTestShippingCouponState,
    (substate) => substate.loading
  );
const makeSelectTestShippingCouponOrderList = () =>
  createSelector(
    selectTestShippingCouponState,
    (substate) => substate.orderList
  );
const makeSelectTestShippingCouponAvailables = () =>
  createSelector(
    selectTestShippingCouponState,
    (substate) => substate.shippingCouponAvailables
  );
const makeSelectTestShippingCouponAmount = () =>
  createSelector(
    selectTestShippingCouponState,
    (substate) => substate.shippingCouponAmount
  );

// user
const selectUserState = (state) => {
  return state.userReducer || {};
};

const makeSelectDepartmentList = () =>
  createSelector(
    selectUserState,
    (substate) => substate.departmentList
  );
const makeSelectRoleList = () =>
  createSelector(
    selectUserState,
    (substate) => substate.roleList
  );

const makeSelectUserList = () =>
  createSelector(
    selectUserState,
    (substate) => substate.userList
  );
const makeSelectUserListTotal = () =>
  createSelector(
    selectUserState,
    (substate) => substate.userListTotal
  );

const makeSelectSalesUserList = () =>
  createSelector(
    selectUserState,
    (substate) => substate.salesUserList
  );
const makeSelectSalesUserListTotal = () =>
  createSelector(
    selectUserState,
    (substate) => substate.salesUserListTotal
  );

const makeSelectUserLoading = () =>
  createSelector(
    selectUserState,
    (substate) => substate.loading
  );
const makeSelectUserActionLoading = () =>
  createSelector(
    selectUserState,
    (substate) => substate.actionLoading
  );

const makeSelectOriUserDetails = () =>
  createSelector(
    selectUserState,
    (substate) => substate.oriUserDetails
  );
const makeSelectUserDetails = () =>
  createSelector(
    selectUserState,
    (substate) => substate.userDetails
  );
const makeSelectOriUserRoles = () =>
  createSelector(
    selectUserState,
    (substate) => substate.oriUserRoles
  );
const makeSelectUserRoles = () =>
  createSelector(
    selectUserState,
    (substate) => substate.userRoles
  );

// key account
const selectKeyAccountState = (state) => {
  return state.keyAccountReducer || {};
};

const makeSelectKeyAccountList = () =>
  createSelector(
    selectKeyAccountState,
    (substate) => substate.keyAccountList
  );
const makeSelectKeyAccountListTotal = () =>
  createSelector(
    selectKeyAccountState,
    (substate) => substate.keyAccountListTotal
  );

const makeSelectKeyAccountLoading = () =>
  createSelector(
    selectKeyAccountState,
    (substate) => substate.loading
  );
const makeSelectKeyAccountActionLoading = () =>
  createSelector(
    selectKeyAccountState,
    (substate) => substate.actionLoading
  );

const makeSelectOriKeyAccountDetails = () =>
  createSelector(
    selectKeyAccountState,
    (substate) => substate.oriKeyAccountDetails
  );
const makeSelectKeyAccountDetails = () =>
  createSelector(
    selectKeyAccountState,
    (substate) => substate.keyAccountDetails
  );

const makeSelectOriKeyAccountProducts = () =>
  createSelector(
    selectKeyAccountState,
    (substate) => substate.oriKeyAccountProducts
  );
const makeSelectKeyAccountProducts = () =>
  createSelector(
    selectKeyAccountState,
    (substate) => substate.keyAccountProducts
  );

const makeSelectOriKeyAccountSalesUsers = () =>
  createSelector(
    selectKeyAccountState,
    (substate) => substate.oriKeyAccountSalesUsers
  );
const makeSelectKeyAccountSalesUsers = () =>
  createSelector(
    selectKeyAccountState,
    (substate) => substate.keyAccountSalesUsers
  );

const makeSelectOriKeyAccountCustomers = () =>
  createSelector(
    selectKeyAccountState,
    (substate) => substate.oriKeyAccountCustomers
  );
const makeSelectKeyAccountCustomers = () =>
  createSelector(
    selectKeyAccountState,
    (substate) => substate.keyAccountCustomers
  );

const makeSelectKeyAccountHistory = () =>
  createSelector(
    selectKeyAccountState,
    (substate) => substate.keyAccountHistory
  );

// key account lock
const selectKeyAccountLockState = (state) => {
  return state.keyAccountLockReducer || {};
};

const makeSelectKeyAccountLockList = () =>
  createSelector(
    selectKeyAccountLockState,
    (substate) => substate.keyAccountLockList
  );
const makeSelectKeyAccountLockListTotal = () =>
  createSelector(
    selectKeyAccountLockState,
    (substate) => substate.keyAccountLockListTotal
  );

const makeSelectKeyAccountLockLoading = () =>
  createSelector(
    selectKeyAccountLockState,
    (substate) => substate.loading
  );

// coordinater
const selectCoordinaterState = (state) => {
  return state.coordinaterReducer || {};
};
const makeSelectCoordinaterList = () =>
  createSelector(
    selectCoordinaterState,
    (substate) => substate.coordinaterList
  );
const makeSelectCoordinaterListTotal = () =>
  createSelector(
    selectCoordinaterState,
    (substate) => substate.coordinaterListTotal
  );
const makeSelectCoordinaterLoading = () =>
  createSelector(
    selectCoordinaterState,
    (substate) => substate.loading
  );
const makeSelectCoordinaterDeleteLoading = () =>
  createSelector(
    selectCoordinaterState,
    (substate) => substate.deleteLoading
  );
const makeSelectCoordinaterAllowedActions = () =>
  createSelector(selectCoordinaterState, (substate) => substate.coordinaterAllowedActions);

// Product Cagtegory
const selectGetcareCategoryState = (state) => {
  return state.getcareCategoryReducer || {};
};
const makeSelectGetcareCategoryList = () =>
  createSelector( selectGetcareCategoryState, (substate) => substate.getcareCategoryList);
const makeSelectIsLoading = () =>
  createSelector( selectGetcareCategoryState, (substate) => substate.isLoading);
const makeSelectGetcareCategoryAllowedActions = () =>
  createSelector( selectGetcareCategoryState, (substate) => substate.getcareCategoryAllowedActions);

// asset branding
const selectAssetBrandingState = (state) => {
  return state.assetBrandingReducer || {};
};
const makeSelectAssetBrandingList = () =>
  createSelector(
    selectAssetBrandingState,
    (substate) => substate.assetBrandingList
  );
const makeSelectAssetBrandingListTotal = () =>
  createSelector(
    selectAssetBrandingState,
    (substate) => substate.assetBrandingListTotal
  );
const makeSelectAssetBrandingLoading = () =>
  createSelector(
    selectAssetBrandingState,
    (substate) => substate.loading
  );
const makeSelectAssetBrandingActionLoading = () =>
  createSelector(
    selectAssetBrandingState,
    (substate) => substate.actionLoading
  );
const makeSelectAssetBrandingAllowedActions = () =>
  createSelector(selectAssetBrandingState, (substate) => substate.assetBrandingAllowedActions);
const makeSelectAssetBrandingVisibility = () =>
  createSelector(selectAssetBrandingState, (substate) => substate.assetBrandingVisibility);

// asset component
const selectAssetComponentState = (state) => {
  return state.assetComponentReducer || {};
};
const makeSelectAssetComponentList = () =>
  createSelector(
    selectAssetComponentState,
    (substate) => substate.assetComponentList
  );
const makeSelectAssetComponentListTotal = () =>
  createSelector(
    selectAssetComponentState,
    (substate) => substate.assetComponentListTotal
  );
const makeSelectAssetComponentLoading = () =>
  createSelector(
    selectAssetComponentState,
    (substate) => substate.loading
  );
const makeSelectAssetComponentActionLoading = () =>
  createSelector(
    selectAssetComponentState,
    (substate) => substate.actionLoading
  );
const makeSelectAssetComponentAllowedActions = () =>
  createSelector(selectAssetComponentState, (substate) => substate.assetComponentAllowedActions);
const makeSelectAssetComponentVisibility = () =>
  createSelector(selectAssetComponentState, (substate) => substate.assetComponentVisibility);

export {
  makeSelectAssetComponentList,
  makeSelectAssetComponentListTotal,
  makeSelectAssetComponentLoading,
  makeSelectAssetComponentActionLoading,
  makeSelectAssetComponentAllowedActions,
  makeSelectAssetComponentVisibility,

  makeSelectAssetBrandingList,
  makeSelectAssetBrandingListTotal,
  makeSelectAssetBrandingLoading,
  makeSelectAssetBrandingActionLoading,
  makeSelectAssetBrandingAllowedActions,
  makeSelectAssetBrandingVisibility,

  makeSelectGetcareCategoryList,
  makeSelectIsLoading,
  makeSelectGetcareCategoryAllowedActions,
  
  makeSelectCountryList,
  makeSelectProvinceList,
  makeSelectDistrictList,
  makeSelectWardList,

  makeSelectProductDetails,
  makeSelectProductList,
  makeSelectProductTotal,
  makeSelectProductIsLoading,
  makeSelectRelatedProducts,
  makeSelectMedicines,
  makeSelectMedicinesTotal,
  makeSelectOtherProducts,
  makeSelectOtherProductsTotal,
  makeSelectAwaitingProductList,
  makeSelectAwaitingProductsTotal,
  makeSelectProductAllowedActions,

  makeSelectIngredientList,
  makeSelectIngredientTotal,
  makeSelectQueueIngredientList,
  makeSelectQueueIngredientTotal,
  makeSelectQueueIngredientActionLoading,
  makeSelectProductUnits,
  makeSelectUomList,
  makeSelectUomBaseList,
  makeSelectUomTotal,
  makeSelectUomBaseTotal,
  makeSelectBrandList,
  makeSelectIndustryList,
  makeSelectVendorDetails,
  makeSelectVendorList,
  makeSelectVendorListTotal,
  makeSelectVendorListLoading,
  makeSelectLoginUser,
  makeSelectAuthLoading,
  makeSelectAuthError,

  makeSelectStoreDetails,
  makeSelectStoreList,
  makeSelectStoreListTotal,
  makeSelectStoreListLoading,
  makeSelectStoreActionLoading,
  makeSelectStoreDeliveryAddressList,
  makeSelectStoreAllowedActions,

  makeSelectSalesChannelList,
  makeSelectSalesChannelListTotal,
  makeSelectSalesChannel,
  makeSelectSalesChannelCoordinaters,
  makeSelectSalesChannelHistory,
  makeSelectSalesChannelAllowedActions,

  makeSelectSalesTeamList,
  makeSelectSalesTeamListTotal,
  makeSelectSalesTeamDetails,
  makeSelectSalesTeamMembers,
  makeSelectSalesTeamChannels,
  makeSelectSalesRoles,
  makeSelectSalesTeamKeyAccounts,
  makeSelectGroupList,
  makeSelectGroupTotal,
  makeSelectGroupLoading,
  makeSelectGroupInfo,
  makeSelectGroupProducts,
  makeSelectGroupVendors,
  makeSelectGroupCustomers,
  makeSelectGroupCustomersTotal,
  makeSelectGroupCustomerMappings,
  makeSelectCustomerTypes,

  makeSelectVendorPriceList,
  makeSelectVendorPriceListTotal,
  makeSelectVendorPriceLoading,
  makeSelectTempVendorPriceList,
  makeSelectVendorPriceAllowedActions,
  makeSelectVendorPriceVisibility,

  makeSelectPricePiorities,
  makeSelectPriceUnits,
  makeSelectPolicyPriceList,
  makeSelectPolicyPriceListTotal,
  makeSelectPolicyPriceLoading,
  makeSelectOriginalPolicyPriceDetails,
  makeSelectPolicyPriceDetails,
  makeSelectPolicyPriceProducts,
  makeSelectTempPolicyPriceProducts,
  makeSelectPolicyPriceCustomers,
  makeSelectTempPolicyPriceCustomers,
  makeSelectPolicyPriceChannels,
  makeSelectTempPolicyPriceChannels,
  makeSelectPolicyPriceCustomerTypes,
  makeSelectTempPolicyPriceCustomerTypes,
  makeSelectPolicyPriceHistory,

  makeSelectGlobalWS,
  makeSelectWSEvents,

  makeSelectVendorProductList,
  makeSelectVendorProductListTotal,
  makeSelectVendorProductLoading,
  makeSelectVendorProductUnmap,
  makeSelectVendorProductApproval,
  makeSelectOriVendorProductList,
  makeSelectVendorProductActionLoading,
  makeSelectVendorProductAllowedActions,
  makeSelectVendorProductVisibility,

  makeSelectProductBonusList,
  makeSelectProductBonusListTotal,
  makeSelectProductBonusLoading,
  makeSelectProductBonusActionLoading,

  makeSelectShippingCouponList,
  makeSelectShippingCouponListTotal,
  makeSelectShippingCouponLoading,
  makeSelectOriShippingCouponDetails,
  makeSelectShippingCouponDetails,
  makeSelectOriShippingCouponRules,
  makeSelectShippingCouponRules,
  makeSelectOriShippingCouponSalesChannels,
  makeSelectShippingCouponSalesChannels,
  makeSelectOriShippingCouponCustomers,
  makeSelectShippingCouponCustomers,
  makeSelectShippingCouponHistory,

  makeSelectTestShippingCouponLoading,
  makeSelectTestShippingCouponOrderList,
  makeSelectTestShippingCouponAvailables,
  makeSelectTestShippingCouponAmount,

  makeSelectDepartmentList,
  makeSelectRoleList,
  makeSelectUserList,
  makeSelectUserListTotal,
  makeSelectUserLoading,
  makeSelectUserActionLoading,
  makeSelectOriUserDetails,
  makeSelectUserDetails,
  makeSelectOriUserRoles,
  makeSelectUserRoles,
  makeSelectSalesUserList,
  makeSelectSalesUserListTotal,

  makeSelectKeyAccountList,
  makeSelectKeyAccountListTotal,
  makeSelectKeyAccountLoading,
  makeSelectKeyAccountActionLoading,
  makeSelectOriKeyAccountDetails,
  makeSelectKeyAccountDetails,
  makeSelectOriKeyAccountProducts,
  makeSelectKeyAccountProducts,
  makeSelectOriKeyAccountSalesUsers,
  makeSelectKeyAccountSalesUsers,
  makeSelectOriKeyAccountCustomers,
  makeSelectKeyAccountCustomers,
  makeSelectKeyAccountHistory,

  makeSelectKeyAccountLockList,
  makeSelectKeyAccountLockListTotal,
  makeSelectKeyAccountLockLoading,

  makeSelectCoordinaterList,
  makeSelectCoordinaterListTotal,
  makeSelectCoordinaterLoading,
  makeSelectCoordinaterDeleteLoading,
  makeSelectCoordinaterAllowedActions,
};
