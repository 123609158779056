export const listDisplayFields = [
  {
    name: 'getcare_product_getcare_id',
    authorize_name: 'product.getcare_id',
    label: 'Phahub ID',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'getcare_product_name',
    authorize_name: 'product.name',
    label: 'Tên sản phẩm',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'getcare_product_registration_number',
    authorize_name: 'product.registration_number',
    label: 'Số đăng ký',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'getcare_vendor_name',
    authorize_name: 'vendor.name',
    label: 'Nhà cung cấp',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'price_buy',
    authorize_name: 'price_buy',
    label: 'Giá mua base',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'price_sales',
    authorize_name: 'price_sales',
    label: 'Giá bán base',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'price_sales_retail',
    authorize_name: 'price_sales_retail',
    label: 'Giá bán lẻ',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'price_buy_recently',
    authorize_name: 'price_buy_recently',
    label: 'Giá mua trước',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'price_sales_recently',
    authorize_name: 'price_sales_recently',
    label: 'Giá bán trước',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'getcare_uom_base_name',
    authorize_name: 'uom_base.name',
    label: 'ĐVT',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'vat',
    authorize_name: 'vat',
    label: 'VAT (%)',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'minimum_quantity',
    authorize_name: 'minimum_quantity',
    label: 'Số lượng\ntối thiểu',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'estimated_quantity',
    authorize_name: 'estimated_quantity',
    label: 'Số lượng\ncó thể bán',
    sortable: true,
    sortDir: '',
  },
];
export const listParamsMap = {
  page_size: 50,
  page: 1,
  order: '',
  getcare_product_getcare_id: '',
  getcare_product_name: '',
  getcare_product_registration_number: '',
  getcare_vendor_name: '',
  getcare_uom_base_name: '',
  price_buy_min: '',
  price_buy_max: '',
  price_sales_min: '',
  price_sales_max: '',
};
export const filterFields = [
  {
    name: 'getcare_product_getcare_id',
    label: 'Phahub ID',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'getcare_product_name',
    label: 'Tên sản phẩm',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'getcare_product_registration_number',
    label: 'Số đăng ký',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'getcare_vendor_name',
    label: 'Nhà cung cấp',
    value: '',
    type: 'input',
    placeholder: '- Chọn -',
  },
  {
    name: 'getcare_uom_base_name',
    label: 'Đơn vị cơ bản',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'price_buy',
    label: 'Giá mua hiện tại',
    value: ['', ''],
    type: 'custom',
    placeholder: 'Nhập...',
  },
  {
    name: 'price_sales',
    label: 'Giá bán hiện tại',
    value: ['', ''],
    type: 'custom',
    placeholder: 'Nhập...',
  },
];
