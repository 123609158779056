import {
  SAVE_STORE_DETAILS,
  SAVE_STORE_LIST,
  SAVE_STORE_LIST_TOTAL,
  SAVE_STORE_LIST_LOADING,
  APPROVE_STORE_INFO,
  SAVE_STORE_ACTION_LOADING,
  SAVE_STORE_DELIVERY_ADDRESS_LIST,
  SAVE_STORE_DELIVERY_ADDRESS,
  REMOVE_STORE_DELIVERY_ADDRESS,
  SAVE_STORE_ALLOWED_ACTIONS,
} from 'redux/constants/admin/storeConstants';

const initialState = {
  storeList: [],
  storeListTotal: 0,
  storeDetails: null,
  isStoreListLoading: false,
  isStoreActionLoading: false,
  deliveryAddressList: [],
  storeAllowedActions: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_STORE_DETAILS: {
      return {
        ...state,
        storeDetails: action.storeDetails ? { ...action.storeDetails } : null,
      };
    }
    case SAVE_STORE_LIST: {
      return {
        ...state,
        storeList: [...action.storeList],
      };
    }
    case SAVE_STORE_LIST_TOTAL: {
      return {
        ...state,
        storeListTotal: action.storeListTotal,
      };
    }
    case SAVE_STORE_LIST_LOADING: {
      return {
        ...state,
        isStoreListLoading: action.isStoreListLoading,
      };
    }
    case APPROVE_STORE_INFO: {
      return {
        ...state,
        storeDetails: {
          ...state.storeDetails,
          approval: 1
        },
      };
    }
    case SAVE_STORE_ACTION_LOADING: {
      return {
        ...state,
        isStoreActionLoading: action.isStoreActionLoading,
      };
    }

    case SAVE_STORE_DELIVERY_ADDRESS_LIST: {
      return {
        ...state,
        deliveryAddressList: action.deliveryAddressList ? [...action.deliveryAddressList] : [],
      };
    }
    case SAVE_STORE_DELIVERY_ADDRESS: {
      const list = [...state.deliveryAddressList];
      const index = list.findIndex((p) => (
        p.id === action.deliveryAddress.id
      ));
      if (index > -1) {
        list[index] = { ...action.deliveryAddress };
      } else {
        list.unshift({ ...action.deliveryAddress });
      }
      return {
        ...state,
        deliveryAddressList: list,
      };
    }
    case REMOVE_STORE_DELIVERY_ADDRESS: {
      const list = state.deliveryAddressList.filter((p) => (
        p.id !== action.deliveryAddress.id
      ));
      return {
        ...state,
        deliveryAddressList: list,
      };
    }

    case SAVE_STORE_ALLOWED_ACTIONS: {
      return {
        ...state,
        storeAllowedActions: action.storeAllowedActions ? [...action.storeAllowedActions] : [],
      };
    }

    default:
      return state;
  }
}

export default reducer;
