import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from "reselect";
import { makeSelectGroupVendors } from 'redux/selectors';
import {
  updateGroupVendor,
  saveGroupVendor,
  removeGroupVendors,
  deleteGroupVendors,
  exportGroupVendors,
  saveGroupVendors,
} from 'redux/actions/admin/groupActions';
import { getDisplayFields, genID } from 'utils/helper';
import {
  groupVendorsParamsMap,
  groupVendorsDisplayFields,
} from 'utils/constanst/adminGroupConstants';
import groupApi from 'utils/api/admin/groupApi';
import { isEqual, sortBy } from 'lodash';

import GroupVendorsItem from './GroupVendorsItem/GroupVendorsItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';
import Button from '@material-ui/core/Button';
import FileImportDialog from '../FileImportDialog/FileImportDialog';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';

import gridClasses from './GroupVendorsGrid.module.scss';
import classes from './GroupVendors.module.scss';

class GroupVendors extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedItems: [],
      isDialogOpen: false,
      isImportLoading: false,
      importResult: null,
      isConfirmDialogOpen: false,
    }
  }
  componentDidUpdate(prevProps) {
    const { groupVendors } = this.props;
    const { selectedItems } = this.state;
    if (!isEqual(sortBy(groupVendors), sortBy(prevProps.groupVendors)) && selectedItems.length > 0) {
      const remainItems = selectedItems.filter(p => groupVendors.some(item => {
        const comparedField = item.idStr ? `idStr` : `id`;
        return item[comparedField] === p[comparedField];
      }));
      this.setState({
        selectedItems: remainItems,
      });
    }
  }
  isAllItemSelected = () => {
    const { groupVendors } = this.props;
    const { selectedItems } = this.state;
    return selectedItems.length > 0 && selectedItems.length >= groupVendors.length;
  }
  isItemSelected = (item) => {
    return this.state.selectedItems.findIndex(p => {
      const comparedField = p.idStr ? 'idStr' : 'id';
      return p[comparedField] === item[comparedField];
    }) > -1;
  }
  handleAllSelectedToggle = (e) => {
    this.setState({
      selectedItems: e.target.checked ? [...this.props.groupVendors] : [],
    });
  }
  handleItemSelectedToggle = ({ item, isSelected }) => {
    const remainItems = this.state.selectedItems.filter(p => {
      const comparedField = p.idStr ? 'idStr' : 'id';
      return p[comparedField] !== item[comparedField];
    });
    this.setState({
      selectedItems: isSelected ? [...remainItems, {...item}] : remainItems,
    });
  }
  handleCreateNewRow = () => {
    this.props.saveGroupVendor({
      idStr: genID(),
      id: 0,
      vendor: null,
      phone: '',
      address: '',
    });
  }
  handleSaveGroupVendor = ({ groupVendorId, params }) => {
    this.props.updateGroupVendor({
      groupVendorId: groupVendorId ? groupVendorId : undefined,
      params: {
        ...params,
        code: groupVendorId ? undefined : this.props.groupCodeItems,
        getcare_erp_group_id: this.props.groupId ? this.props.groupId : undefined,
      },
    });
  }
  handleRemoveGroupVendor = (params) => {
    this.props.removeGroupVendors([{...params}]);
  }
  handleDeleteGroupVendors = () => {
    this.props.deleteGroupVendors({
      id: isNaN(this.props.groupId) ? undefined : this.props.groupId,
      code: !this.props.groupCodeItems ? undefined : this.props.groupCodeItems,
      groupVendors: [...this.state.selectedItems],
    });
    this.handleConfirmDialogClose();
  }
  handleExportGroupVendors = () => {
    this.props.exportGroupVendors({
      id: isNaN(this.props.groupId) ? undefined : this.props.groupId,
      code: !this.props.groupCodeItems ? undefined : this.props.groupCodeItems,
    });
  }
  handleDialogOpen = () => {
    this.setState({
      isDialogOpen: true,
    });
  }
  handleDialogClose = () => {
    this.setState({
      isDialogOpen: false,
      isImportLoading: false,
      importResult: null,
    });
  }
  handleConfirmDialogOpen = () => {
    this.setState({
      isConfirmDialogOpen: true,
    });
  }
  handleConfirmDialogClose = () => {
    this.setState({
      isConfirmDialogOpen: false,
    });
  }
  handleSubmitImport = async ({file, start_row}) => {
    this.setState({
      isImportLoading: true,
    });
    const { data: response } = await groupApi.importGroupVendors({
      id: undefined,
      code: this.props.groupCodeItems,
      params: {
        file: file,
        start_row: start_row,
      }
    });
    this.setState({
      isImportLoading: false,
    });

    const isSuccess = response.result && response.data;
    this.setState({
      importResult: {
        isSuccess: isSuccess,
        message: isSuccess ? '' : response.message,
        totalRecords: response.rows_total,
        successRecords: isSuccess ? response.rows_affected : 0,
      },
    });
    if (isSuccess) {
      this.props.saveGroupVendors([...response.data]);
    }
  };

  render() {
    const { groupVendors, isGroupInactive } = this.props;
    const displayFields = getDisplayFields(
      groupVendorsParamsMap,
      groupVendorsDisplayFields
    );
    const hasSelected = this.state.selectedItems.length > 0;

    return (<div className={`${classes.Wrap}`}>
      <div className={classes.Toolbar}>
        <div>
          <Button
            disabled={isGroupInactive}
            variant="contained"
            color="primary"
            size="small"
            onClick={this.handleDialogOpen}
          >
            Import File
          </Button>
          <Button
            disabled={!this.props.groupVendors.length}
            variant="contained"
            size="small"
            onClick={this.handleExportGroupVendors}
          >
            Export danh sách ra .CSV
          </Button>
        </div>
        <Button
          className={`${classes.MultiActionBtn} ${hasSelected && classes.Active}`}
          color="secondary"
          variant="contained"
          size="small"
          onClick={this.handleConfirmDialogOpen}
        >
          Xoá
        </Button>
      </div>
      <div className={`${classes.List}`}>
        <div className={`${classes.Body}`}>
          <div className={`${gridClasses.Row} ${classes.Header}`}>
            <div className={gridClasses.Col}>
              <Checkbox
                disabled={isGroupInactive}
                className={classes.Checkbox}
                checked={this.isAllItemSelected()}
                onChange={this.handleAllSelectedToggle}
              />
            </div>
            { displayFields.map((item) => (
              <ListHeaderCol
                key={item.name}
                className={gridClasses.Col}
                {...item}
              />
            )) }
            <div className={`${gridClasses.Col} ${classes.HeaderCol} ${classes.ActionsCol}`}>
              <Button
                disabled={isGroupInactive}
                color="primary"
                variant="contained"
                size="small"
                onClick={this.handleCreateNewRow}
              >
                Thêm dòng
              </Button>
            </div>
          </div>
        
          { groupVendors.length
            ? groupVendors.map(item => (
              <GroupVendorsItem
                key={`group-vendor-${item.id}`}
                {...item}
                isGroupInactive={isGroupInactive}
                isSelected={this.isItemSelected(item)}
                currentList={[...groupVendors]}
                onItemSelectedToggle={this.handleItemSelectedToggle}
                saveGroupVendor={this.handleSaveGroupVendor}
                removeGroupVendor={this.handleRemoveGroupVendor}
              />))
            : (<p className="NoData">Không có nhà cung cấp nào</p>)
          }
        </div>
      </div>
      { this.state.isDialogOpen
        &&  <FileImportDialog
            title="Import danh sách nhà cung cấp"
            isOpen={this.state.isDialogOpen}
            onClose={this.handleDialogClose}
            onSubmitImport={this.handleSubmitImport}
            isLoading={this.state.isImportLoading}
            importResult={this.state.importResult}
          />
      }
      { this.state.isConfirmDialogOpen
        && <ConfirmationDialog
          isOpen={this.state.isConfirmDialogOpen}
          title="Xác nhận xoá"
          message="Bạn có chắc xoá các nhà cung cấp đã chọn ra khỏi nhóm?"
          onClose={this.handleConfirmDialogClose}
          onSubmit={this.handleDeleteGroupVendors}
        />
      }
    </div>);
  }
}

GroupVendors.propTypes = {
  groupId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  groupCodeItems: PropTypes.string,
  isGroupInactive: PropTypes.bool,
};

GroupVendors.defaultProps = {
  isGroupInactive: false,
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateGroupVendor: (payload) => dispatch(updateGroupVendor(payload)),
    saveGroupVendor: (payload) => dispatch(saveGroupVendor(payload)),
    removeGroupVendors: (payload) => dispatch(removeGroupVendors(payload)),
    deleteGroupVendors: (payload) => dispatch(deleteGroupVendors(payload)),
    exportGroupVendors: (payload) => dispatch(exportGroupVendors(payload)),
    saveGroupVendors: (payload) => dispatch(saveGroupVendors(payload)),
  };
};
const mapStateToProps = createStructuredSelector({
  groupVendors: makeSelectGroupVendors(),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(GroupVendors);
