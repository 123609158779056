import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { removeAccents } from 'utils/helper';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from "reselect";
import { makeSelectSalesTeamDetails } from 'redux/selectors';
import { statusMap } from 'utils/constanst/adminSalesTeamConstants';
import { saveSalesTeamDetails } from 'redux/actions/admin/salesTeamActions';
import salesTeamApi from 'utils/api/admin/salesTeamApi';
import { debounce } from 'lodash';
import { suggestionPageSize } from 'utils/constanst/common';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import FieldEditable from 'components/FieldEditable/FieldEditable';
import ReportProblemOutlined from '@material-ui/icons/ReportProblemOutlined';
import Tooltip from '@material-ui/core/Tooltip';

import classes from './SalesTeamDetails.module.scss';

class SalesTeamDetails extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      codeOptions: [],
      salesTeamOptions: [],
      codeInput: '',
      nameInput: '',
      selectedCode: null,
    }
  }
  componentDidMount() {
    const { salesTeamDetails } = this.props;
    if (salesTeamDetails && salesTeamDetails.id) {
      this._initData();
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { salesTeamDetails } = this.props;
    if (salesTeamDetails && salesTeamDetails.id && salesTeamDetails !== prevProps.salesTeamDetails) {
      this._initData();
    }

    const { codeInput, nameInput, codeOptions } = this.state;
    if (codeInput !== prevState.codeInput) {
      this.loadSalesTeams({code: codeInput});
    }
    if (nameInput !== prevState.nameInput) {
      this.loadSalesTeams({name: nameInput});
    }
    if (codeOptions !== prevState.codeOptions) {
      this.props.checkCodeDupplication([...codeOptions]);
    }
  }
  _initData = () => {
    const { salesTeamDetails } = this.props;
    this.setState({
      codeOptions: [{...salesTeamDetails}],
      selectedCode: {...salesTeamDetails},
      codeInput: salesTeamDetails.code,
      nameInput: salesTeamDetails.name,
    });
  }
  loadSalesTeams = debounce(async (params) => {
    const { data: response } = await salesTeamApi.getAll({ params: { page: 1, page_size: suggestionPageSize, ...params } });
    if (!response?.result) return;
    if (params.code) {
      this.setState({
        codeOptions: response.data ? [...response.data] : [],
      });
    }
    if (params.name) {
      this.setState({
        salesTeamOptions: response.data ? [...response.data] : [],
      });
    }
  }, 500)
  isTeamDuplicated = () => {
    const { salesTeamOptions } = this.state;
    return salesTeamOptions
      && salesTeamOptions.length > 0
      && salesTeamOptions.some(n => {
        return (n.id !== this.props.salesTeamDetails.id) && n.name.toLowerCase() === this.state.nameInput.toLowerCase();
      });
  }
  preprocessInputValue = (value) => {
    return removeAccents(value).toUpperCase();
  }
  handleCodeInputChange = (e, newValue) => {
    const value = this.preprocessInputValue(newValue);
    this.setState({
      codeInput: value,
    });
    this.handleSaveField({code: value.trim()});
    return;
  }
  handleChange = (e, newValue) => {
    if (!newValue || !newValue.id) return;
    this.props.history.push(`/salesteam/${newValue.id}`);
  }
  handleSaveField = (fieldMap) => {
    const params = {
      ...this.props.salesTeamDetails,
      ...fieldMap,
    };
    this.props.saveSalesTeamDetails(params);
    if (this.props.isEditing) {
      this.props.onSaveDetails(params);
    }
  }
  handleFieldChange = (e, newValue) => {
    if (this.props.isEditing) return false;
    this.handleSaveField({
      [e.target.name]: newValue,
    });
  }

  render() {
    const { codeInput, codeOptions, selectedCode } = this.state;
    const { salesTeamDetails, isEditing, isCodeValid, isNameValid } = this.props;

    return (<div className={classes.Details}>
      <div className={classes.FieldControl}>
        <label>Mã sales team <span className={classes.RequiredMark}>*</span></label>
        { isEditing ? salesTeamDetails?.code : (
          <Autocomplete
            openOnFocus
            disableClearable
            freeSolo
            handleHomeEndKeys={false}
            value={selectedCode || null}
            inputValue={codeInput}
            onInputChange={this.handleCodeInputChange}
            options={(isEditing && codeOptions) ? codeOptions : []}
            getOptionLabel={(option) => option?.code || ''}
            getOptionSelected={(option, value) => option.id === value.id}
            renderInput={(params) => <TextField {...params} error={!isCodeValid} />}
            onChange={this.handleChange}
          />
        )}
      </div>

      <div className={classes.FieldControl}>
        <label>Mô tả</label>
        <FieldEditable
          editModeOnly={!isEditing}
          maxLength={255}
          fieldName="description"
          value={salesTeamDetails?.description || ''}
          onSave={this.handleSaveField}
          onChange={this.handleFieldChange}
        />
      </div>

      <div className={classes.FieldControl}>
        <label className={classes.Flex}>
          Sales Team
          <span className={classes.RequiredMark}>*</span>
          { this.isTeamDuplicated()
            && (<Tooltip title={`Tên sales team bị trùng`} arrow placement="top">
                  <span className={classes.WarningIcon}><ReportProblemOutlined fontSize="small"/></span>
                </Tooltip>)
          }
        </label>
        <FieldEditable
          editModeOnly={!isEditing}
          fieldName="name"
          error={!isNameValid}
          maxLength={55}
          value={salesTeamDetails?.name || ''}
          onSave={this.handleSaveField}
          onChange={this.handleFieldChange}
        />
      </div>
      <div className={classes.FieldControl}>
        <label>Trạng thái</label>
        <div className={classes.ActiveWrap}>
          <label>{ salesTeamDetails ? statusMap[salesTeamDetails.active] : '' }</label>
          <Button
            disabled
            variant="contained"
            size="small"
            color="secondary"
          >Dừng hoạt động</Button>
        </div>
      </div>
    </div>);
  }
}

SalesTeamDetails.propTypes = {
  isEditing: PropTypes.bool,
  isCodeValid: PropTypes.bool,
  isNameValid: PropTypes.bool,
  checkCodeDupplication: PropTypes.func,
  onSaveDetails: PropTypes.func,
};

SalesTeamDetails.defaultProps = {
  isEditing: true,
  isCodeValid: true,
  isNameValid: true,
};

const mapStateToProps = createStructuredSelector({
  salesTeamDetails: makeSelectSalesTeamDetails(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    saveSalesTeamDetails: (payload) => dispatch(saveSalesTeamDetails(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter)(SalesTeamDetails);
