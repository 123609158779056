import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import classes from './Panel.module.scss';

class Panel extends PureComponent {
  render() {
    const { title, content, titleClassName, contentClassName, panelClassName, isBorder, titleAction, panelHeadingClassName } = this.props;
    return (
      <div className={`${classes.Panel} ${panelClassName} ${isBorder && classes.Border}`}>
        <div className={`${classes.PanelHeading} ${panelHeadingClassName}`}>
          <h4 className={`${classes.Title} ${titleClassName}`}>{ title }</h4>
          { titleAction }
        </div>
        <div className={contentClassName}>{ content }</div>
      </div>
    );
  }
}

Panel.propTypes = {
  title: PropTypes.string,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  panelClassName: PropTypes.string,
  titleClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  panelHeadingClassName: PropTypes.string,
  isBorder: PropTypes.bool,
  titleAction: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
};
Panel.defaultProps = {
  title: '',
  content: '',
  titleClassName: '',
  contentClassName: '',
  panelHeadingClassName: '',
  panelClassName: '',
  isBorder: false,
  titleAction: '',
};

export default Panel;
