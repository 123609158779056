import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectPriceUnits } from 'redux/selectors';
import policyPriceApi from 'utils/api/admin/policyPriceApi';
import {
  POLICY_PRICE_GROUP_TYPE,
  POLICY_PRICE_PRODUCT_TYPE,
  operations,
  POLICY_PRICE_VND_UNIT,
  PLUS_OPERATION,
  groupTypes,
} from 'utils/constanst/adminPolicyPriceContants';
import { currencyFormatVN, validNumber } from 'utils/helper';

import Checkbox from '@material-ui/core/Checkbox';
import EditOutlined from '@material-ui/icons/EditOutlined';
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import IconButton from '@material-ui/core/IconButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import NumberFormatCustom from 'components/NumberFormatCustom/NumberFormatCustom';

import gridClasses from '../PolicyPriceProductsGrid.module.scss';
import classes from './PolicyPriceProduct.module.scss';

class PolicyPriceProduct extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: !props.id && !props.code,
      groupOrVendorProductOptions: props.code
        ? [{ ...this._getItemProps(props) }]
        : [],
      typeId: props.type_id || '',
      codeInput: props.code || '',
      nameInput: props.name || '',
      selectedGroupOrVendorProduct: props.code
        ? {
          ...this._getItemProps(this.props),
          id: undefined,
        }
        : null,
      selectedOperation: {
        ...this._getOperation(props.operation),
      },
      selectedPriceUnit: props.policy_unit
        ? { ...props.policy_unit }
        : null,
      amountInput: props.amount || '',
    };
  }
  _getOperation = (value) => {
    return operations.find((item) => item.value === value);
  };
  _getPriceUnit = (id) => {
    return this.props.priceUnits.find((item) => item.id === id);
  };
  _getItemProps = (params) => {
    return {
      id: params.id,
      idStr: params.idStr,
      code: params.code,
      name: params.name,
      type_id: params.type_id,
      group_or_vendor_product_id: params.type_id === POLICY_PRICE_GROUP_TYPE ? params.erp_group?.id : params.product_price_vendor_id,
      vendor: params.vendor ? { ...params.vendor } : null,
      uom_base: params.uom_base ? { ...params.uom_base } : null,
      price_sales: params.price_sales ? params.price_sales : '',
      price_sales_retail: params.price_sales_retail ? params.price_sales_retail : '',
      operation: params.operation || '',
      amount: params.amount || '',
      policy_unit: params.policy_unit ? { ...params.policy_unit } : null,
    };
  };
  _resetState = () => {
    this.setState({
      isEditing: !this.props.id && !this.props.code,

      codeInput: this.props.code || '',
      nameInput: this.props.name || '',
      typeId: this.props.type_id || '',
      selectedGroupOrVendorProduct: this.props.code
        ? {
            ...this._getItemProps(this.props),
            id: undefined,
          }
        : null,
      groupOrVendorProductOptions: this.props.code
        ? [{
            ...this._getItemProps(this.props),
            id: undefined,
          }]
        : [],
      selectedOperation: {
        ...this._getOperation(this.props.operation),
      },
      selectedPriceUnit: this.props.policy_unit
        ? { ...this.props.policy_unit }
        : null,
      amountInput: this.props.amount || '',
    });
  };
  _resetStateForm = () => {
    this.setState({
      isEditing: true,
      codeInput: '',
      nameInput: '',
      selectedGroupOrVendorProduct: null,
      groupOrVendorProductOptions: [],
      selectedOperation: {
        ...this._getOperation(PLUS_OPERATION),
      },
      selectedPriceUnit: {
        ...this._getPriceUnit(POLICY_PRICE_VND_UNIT),
      },
    });
  };
  _isChangedField = (fieldName, subObjectName) => {
    const { changedItem } = this.props;
    if (!changedItem) return false;
    if (!subObjectName) return changedItem[fieldName] !== this.props[fieldName];
    return changedItem[subObjectName][fieldName] !== this.props[subObjectName][fieldName];
  }
  stopPropagation = (e) => {
    e.stopPropagation();
  };
  handleCheckboxChange = (e) => {
    e.stopPropagation();
    this.props.onItemSelectedToggle({
      item: {
        ...this._getItemProps(this.props),
      },
      isSelected: !this.props.isSelected,
    });
  };
  componentDidUpdate(prevProps, prevState) {
    const { codeInput, nameInput } = this.state;
    if (codeInput && codeInput !== prevState.codeInput) {
      this.loadSuggestedProducts({
        params: {
          code: codeInput,
        },
      });
    }
    if (nameInput && nameInput !== prevState.nameInput) {
      this.loadSuggestedProducts({
        params: {
          name: nameInput,
        },
      });
    }
  }
  loadSuggestedProducts = debounce(async ({ params }) => {
    const { data: response } = await policyPriceApi.getGroupProducts({
      params: {
        ...params,
        type_id: this.state.typeId,
      },
    });
    if (!response?.result) return;
    this.setState({
      groupOrVendorProductOptions: response.data
        ? this.filteredOptions(
            this.state.typeId === POLICY_PRICE_GROUP_TYPE
              ? [...response.data.erp_groups]
              : [...response.data.product_price_vendors]
          )
        : [],
    });
  }, 500);
  filteredOptions = (fullArr) => {
    const { typeId } = this.state;
    return fullArr.filter((p) => {
      return typeId === POLICY_PRICE_GROUP_TYPE ? (
        (p.code === this.props.code) ||
        !this.props.currentList.some(
          (item) => item.code === p.code
        )
      ) : (
        (p.code === this.props.code && p.vendor?.id === this.props.vendor?.id && p.uom_base?.id === this.props.uom_base?.id) ||
        !this.props.currentList.some(
          (item) => item.code === p.code && item.vendor?.id === p.vendor?.id && item.uom_base?.id === p.uom_base?.id
        )
      );
    });
  };
  _isProductValid = () => {
    return !!this.state.selectedGroupOrVendorProduct;
  }
  _isOperationValid = () => {
    return !!this.state.selectedOperation;
  }
  _isPriceUnitValid = () => {
    return !!this.state.selectedPriceUnit;
  }
  _isAmountValid = () => {
    return this.state.amountInput !== '';
  }
  _getProductOptionLabel = (option) => {
    return `${option.code} - ${option.name}${option.vendor ? ' - ' + option.vendor.name : ''}${option.uom_base ? ' - ' + option.uom_base.name : ''}`
  }
  isRowValid = () => {
    return this._isProductValid() && this._isOperationValid() && this._isPriceUnitValid() && this._isAmountValid();
  };
  handleEdit = () => {
    this.setState({
      isEditing: true,
    });
  };
  handleCancel = () => {
    this._resetState();
    if (!this.props.id) {
      this.props.removePolicyPriceProduct({
        id: this.props.id,
        idStr: this.props.idStr,
      });
    }
  };
  handleSave = () => {
    const {
      selectedGroupOrVendorProduct,
      typeId,
      selectedOperation,
      amountInput,
      selectedPriceUnit,
    } = this.state;
    if (!this.isRowValid()) return;
    this.props.savePolicyPriceProduct({
      id: this.props.id,
      idStr: this.props.idStr,

      code: selectedGroupOrVendorProduct?.code,
      name: selectedGroupOrVendorProduct?.name,
      // initial from the props: get from selectedGroupOrVendorProduct.group_or_vendor_product_id and make sure selectedGroupOrVendorProduct.id is undefined
      // if reselect selectedGroupOrVendorProduct from the options: get from selectedGroupOrVendorProduct.id
      group_or_vendor_product_id: selectedGroupOrVendorProduct.id || selectedGroupOrVendorProduct.group_or_vendor_product_id,
      type_id: typeId,
      vendor:
        typeId === POLICY_PRICE_PRODUCT_TYPE
          ? { ...selectedGroupOrVendorProduct.vendor }
          : null,
      uom_base:
        typeId === POLICY_PRICE_PRODUCT_TYPE
          ? { ...selectedGroupOrVendorProduct.uom_base }
          : null,
      price_sales:
        typeId === POLICY_PRICE_PRODUCT_TYPE ? selectedGroupOrVendorProduct.price_sales : '',
      price_sales_retail:
        typeId === POLICY_PRICE_PRODUCT_TYPE ? selectedGroupOrVendorProduct.price_sales_retail : '',
      operation: selectedOperation.value,
      amount: Number(amountInput),
      policy_unit: { ...selectedPriceUnit },
    });
    this.setState({
      isEditing: false,
    });
  };
  handleRowClick = () => {
    if (this.props.readOnly) {
      return;
    }

    if (!this.state.isEditing) {
      this.handleEdit();
    }
  };
  handleRowBlur = () => {
    if (this.props.readOnly) {
      return;
    }

    if (this.state.isEditing && this.isRowValid()) {
      this.handleSave();
    }
  };
  handleSelectedGroupOrVendorProductChange = (e, newValue) => {
    this.setState({
      selectedGroupOrVendorProduct: newValue,
    });
  };
  handleSelectGroupChange = (e) => {
    this.setState({
      typeId: e.target.value,
    });
    // reset the form
    this._resetStateForm();
  };
  handleSelectedOperationChange = (e, newValue) => {
    this.setState({
      selectedOperation: newValue,
    });
  };
  handleSelectedUnitChange = (e, newValue) => {
    this.setState({
      selectedPriceUnit: newValue,
    });
  };
  handleAmountChange = (e) => {
    this.setState({
      amountInput: validNumber(e.target.value),
    });
  };

  render() {
    const {
      code,
      name,
      isSelected,
      operation,
      amount,
      readOnly,
      priceUnits,
      isRetail,
    } = this.props;
    const {
      isEditing,
      selectedGroupOrVendorProduct,
      groupOrVendorProductOptions,
      selectedOperation,
      typeId,
      amountInput,
      selectedPriceUnit,
    } = this.state;

    let displayPrice;
    if (isRetail) {
      displayPrice = selectedGroupOrVendorProduct?.price_sales_retail ? currencyFormatVN(selectedGroupOrVendorProduct.price_sales_retail) : '';
    } else {
      displayPrice = selectedGroupOrVendorProduct?.price_sales ? currencyFormatVN(selectedGroupOrVendorProduct.price_sales) : '';
    }

    return (
      <ClickAwayListener onClickAway={this.handleRowBlur}>
        <div
          className={`${classes.Item} ${gridClasses.Row}`}
          onClick={this.handleRowClick}
        >
          <div className={`${gridClasses.Col} ${classes.ActionsCol}`}>
            <Checkbox
              className={classes.Checkbox}
              checked={isSelected}
              disabled={readOnly}
              onClick={this.stopPropagation}
              onChange={this.handleCheckboxChange}
            />
          </div>
          <div className={`${gridClasses.Col} ${this._isChangedField('typeId', null) ? classes.Highlighted : ''}`}>
            <Select
              className={classes.SelectWrap}
              disabled={readOnly}
              value={typeId}
              name="type_id"
              size="small"
              fullWidth
              onChange={this.handleSelectGroupChange}
            >
              {
                groupTypes.map(item => <MenuItem key={`type-${item.id}`} value={item.id}>{ item.name }</MenuItem>)
              }
            </Select>
          </div>
          <div className={`${gridClasses.Col} ${ this._isChangedField('code', null) ? classes.Highlighted : '' }`}>
            {isEditing ? (
              <Autocomplete
                openOnFocus
                selectOnFocus
                disableClearable
                fullWidth
                size="small"
                handleHomeEndKeys={false}
                value={selectedGroupOrVendorProduct || null}
                onChange={this.handleSelectedGroupOrVendorProductChange}
                options={groupOrVendorProductOptions || []}
                filterOptions={(x) => x}
                renderOption={(option) => this._getProductOptionLabel(option)}
                getOptionLabel={(option) => (option && option.code) || ''}
                getOptionSelected={(option, value) =>
                  value && value.code && option ? option.code === value.code : null
                }
                onInputChange={(e, newInputValue) => {
                  this.setState({
                    codeInput: newInputValue,
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} error={!this._isProductValid()} placeholder="- Chọn -" />
                )}
              />
            ) : (
              code
            )}
          </div>
          <div className={`${gridClasses.Col} ${ this._isChangedField('code', null) ? classes.Highlighted : '' }`}>
            {isEditing ? (
              <Autocomplete
                openOnFocus
                selectOnFocus
                disableClearable
                size="small"
                fullWidth
                handleHomeEndKeys={false}
                value={selectedGroupOrVendorProduct || null}
                onChange={this.handleSelectedGroupOrVendorProductChange}
                options={groupOrVendorProductOptions || []}
                filterOptions={(x) => x}
                renderOption={(option) => this._getProductOptionLabel(option)}
                getOptionLabel={(option) => (option && option.name) || ''}
                getOptionSelected={(option, value) =>
                  value && value.code && option ? option.code === value.code : null
                }
                onInputChange={(e, newInputValue) => {
                  this.setState({
                    nameInput: newInputValue,
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} error={!this._isProductValid()} placeholder="- Chọn -" />
                )}
              />
            ) : (
              name
            )}
          </div>
          <div className={`${gridClasses.Col} ${ this._isChangedField('code', null) ? classes.Highlighted : '' }`}>
            {selectedGroupOrVendorProduct?.vendor?.name}
          </div>
          <div className={`${gridClasses.Col} ${ this._isChangedField('code', null) ? classes.Highlighted : '' }`}>
            {selectedGroupOrVendorProduct?.uom_base?.name}
          </div>
          <div className={`${gridClasses.Col} ${ this._isChangedField('code', null) ? classes.Highlighted : '' }`}>
            {displayPrice}
          </div>
          <div className={`${gridClasses.Col} ${classes.OperationCol} ${ this._isChangedField('operation', null) ? classes.Highlighted : '' }`}>
            {isEditing ? (
              <Autocomplete
                openOnFocus
                selectOnFocus
                disableClearable
                size="small"
                fullWidth
                handleHomeEndKeys={false}
                value={selectedOperation || null}
                onChange={this.handleSelectedOperationChange}
                options={operations || []}
                filterOptions={(x) => x}
                renderOption={(option) => `${option.name}`}
                getOptionLabel={(option) => (option && option.name) || ''}
                getOptionSelected={(option, value) =>
                  value && option ? option.value === value : null
                }
                renderInput={(params) => (
                  <TextField {...params} error={!this._isOperationValid()} placeholder="- Chọn -" />
                )}
              />
            ) : (
              this._getOperation(operation)?.name
            )}
          </div>
          <div className={`${gridClasses.Col} ${this._isChangedField('amount', null) || this._isChangedField('id', 'policy_unit') ? classes.Highlighted : ''}`}>
            {isEditing ? (
              <div className={classes.FieldsWrap}>
                <TextField
                  value={amountInput}
                  name="amountInput"
                  error={!this._isAmountValid()}
                  placeholder="Nhập..."
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                  onChange={this.handleAmountChange}
                />
                <Autocomplete
                  openOnFocus
                  size="small"
                  selectOnFocus
                  disableClearable
                  handleHomeEndKeys={false}
                  value={selectedPriceUnit || null}
                  onChange={this.handleSelectedUnitChange}
                  options={priceUnits || []}
                  filterOptions={(x) => x}
                  renderOption={(option) => `${option.name}`}
                  getOptionLabel={(option) => (option && option.name) || ''}
                  getOptionSelected={(option, value) =>
                    value && option ? option.id === value.id : null
                  }
                  renderInput={(params) => (
                    <TextField {...params} error={!this._isPriceUnitValid()} placeholder="- Chọn -" />
                  )}
                />
              </div>
            ) : selectedPriceUnit?.id === POLICY_PRICE_VND_UNIT ? (
              currencyFormatVN(amount)
            ) : (
              `${amount} ${selectedPriceUnit?.name}`
            )}
          </div>
          <div className={`${gridClasses.Col} ${classes.ActionsCol}`}>
            {isEditing ? (
              <>
                <IconButton
                  onClick={this.handleCancel}
                  style={{ padding: '0' }}
                  size="small"
                >
                  <CancelOutlined fontSize="small" color="secondary" />
                </IconButton>
                <IconButton
                  onClick={this.handleSave}
                  disabled={!this.isRowValid()}
                  style={{ padding: '0' }}
                  size="small"
                >
                  <CheckCircleOutlineOutlined
                    fontSize="small"
                    color={this.isRowValid() ? `primary` : `inherit`}
                  />
                </IconButton>
              </>
            ) : (
              <>
                <IconButton
                  onClick={this.handleEdit}
                  disabled={readOnly}
                  style={{ padding: '0' }}
                  size="small"
                >
                  <EditOutlined fontSize="small" color={readOnly ? 'inherit' : 'primary'} />
                </IconButton>
              </>
            )}
          </div>
        </div>
      </ClickAwayListener>
    );
  }
}

PolicyPriceProduct.propTypes = {
  idStr: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  getcare_erp_group: PropTypes.object,
  currentList: PropTypes.array,
  isSelected: PropTypes.bool,
  changedItem: PropTypes.object,
  savePolicyPriceProduct: PropTypes.func,
  removePolicyPriceProduct: PropTypes.func,
  onItemSelectedToggle: PropTypes.func,
};

PolicyPriceProduct.defaultProps = {
  currentList: [],
  isSelected: false,
};

const mapStateToProps = createStructuredSelector({
  priceUnits: makeSelectPriceUnits(),
});
const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(PolicyPriceProduct);
