import {
  CHECK_SHIPPING_COUPON,

  SAVE_LOADING,
  SAVE_ORDER_LIST,
  SAVE_ORDER_LIST_ITEM,
  REMOVE_ORDER_LIST_ITEMS,
  SAVE_SHIPPING_COUPON_AVAILABLES,

  SAVE_SHIPPING_COUPON_AMOUNT,
} from 'redux/constants/admin/testShippingCouponConstants';

export function checkShippingCoupon(payload) {
  return {
    type: CHECK_SHIPPING_COUPON,
    payload,
  };
}

// mutation
export function saveLoading(loading) {
  return {
    type: SAVE_LOADING,
    loading,
  };
}

export function saveOrderList(orderList) {
  return {
    type: SAVE_ORDER_LIST,
    orderList,
  };
}
export function saveOrderListItem(orderListItem) {
  return {
    type: SAVE_ORDER_LIST_ITEM,
    orderListItem,
  };
}
export function removeOrderListItems(orderListItems) {
  return {
    type: REMOVE_ORDER_LIST_ITEMS,
    orderListItems,
  };
}

export function saveShippingCouponAvailables(shippingCouponAvailables) {
  return {
    type: SAVE_SHIPPING_COUPON_AVAILABLES,
    shippingCouponAvailables,
  };
}

export function saveShippingCouponAmount(shippingCouponAmount) {
  return {
    type: SAVE_SHIPPING_COUPON_AMOUNT,
    shippingCouponAmount,
  };
}
