import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { getDisplayFields } from 'utils/helper';
import { listParamsMap, listDisplayFields } from 'utils/constanst/locationConstants';

import SalesChannelLocationItem from './SalesChannelLocationItem/SalesChannelLocationItem';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';
import Button from '@material-ui/core/Button';

import gridClasses from './SalesChannelLocationsGrid.module.scss';
import classes from './SalesChannelLocations.module.scss';

class SalesChannelLocations extends PureComponent {
  handleCreateNewRow = () => {
    this.props.onCreateNewLocation();
  }

  render() {
    const { list, hasOneLocation, isEditAllowed } = this.props;
    const isLoading = !list;
    const displayFields = getDisplayFields({ ...listParamsMap }, [ ...listDisplayFields ]);

    return (<div className={classes.ListWrap}>
      <div className={`${classes.List} ${isLoading && 'OverlayLoading'}`}>
      <div
          className={`${classes.Header}`}
          ref={(el) => (this.rowRef = el)}
        >
           <div className={`${gridClasses.Row} ${isEditAllowed ? gridClasses.RowHasActions : ''} ${classes.Header}`}>
            { displayFields.map((item, index) => (
              <ListHeaderCol
                key={`${item.name}-${index}`}
                className={`${gridClasses.Col} ${classes.HeaderCol}`}
                {...item}
              />
            )) }
            {isEditAllowed && 
              <div className={`${gridClasses.Col} ${classes.HeaderCol} ${classes.ActionCol}`}>
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  onClick={this.handleCreateNewRow}
                >
                  Thêm dòng
                </Button>
              </div>
            }
          </div>
          </div>

        <div className={`${classes.Body}`}>
      
        
          { (list && list.length)
            ? list.map(item => (
              <SalesChannelLocationItem
                key={`medicine-${item.id}`}
                {...item}
                hasOneLocation={hasOneLocation}
                isEditAllowed={isEditAllowed}
                onSaveLocationItem={this.props.onSaveLocationItem}
                onRemoveLocationItems={this.props.onRemoveLocationItems}
              />))
            : (<p className="NoData">Không có data</p>)
          }
        </div>
      </div>
    </div>);
  }
}

SalesChannelLocations.propTypes = {
  list: PropTypes.array,
  selectedId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  hasOneLocation: PropTypes.bool,
  onCreateNewLocation: PropTypes.func,
  onSaveLocationItem: PropTypes.func,
  onRemoveLocationItems: PropTypes.func,
};

SalesChannelLocations.defaultProps = {
  hasOneLocation: false,
};

export default SalesChannelLocations;
