import React, { PureComponent } from 'react';

import { 
  assetBrandingTypes,
} from 'utils/constanst/adminAssetBrandingConstants';
import authApi from 'utils/api/authApi';

import { Formik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { isEqual, maxBy } from 'lodash';

import { toast } from 'react-toastify';
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  Grid,
} from '@material-ui/core';
import AutocompleteVendor from 'components/AutocompleteVendor/AutocompleteVendor';
import FileUpload from 'components/FileUpload/FileUpload';

import classes from './AssetBrandingFormDialogForm.module.scss';

class AssetBrandingFormDialogForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      typeOptions: [...assetBrandingTypes],
      uploadingFile: false,
    }
    this.formikRef = React.createRef();
  }

  _prepareData = (params) => {
    return {
      id: params.id,
      name: params.name,
      code: params.code,
      value: params.value,
      getcare_vendor_id: params.getcare_vendor_id,
      type_id: params.type_id,
    }
  }
  _formikInitialValues = () => {
    const { data } = this.props;
    const { typeOptions } = this.state;
    const typeOption = typeOptions.find( item => item.value === data?.type_id )
    return {
        id: data?.id || undefined,
        name: data?.name || '',
        code: data?.code || '',
        value: data?.value || '',
        getcare_vendor_id: data?.getcare_vendor_id || '',
        type_id: typeOption ? typeOption.value : typeOptions[0].value,
    }
  }
  _formikValidationSchema = () => {
    return {
      name: Yup.string().required("Vui lòng nhập").max(255, 'Tối đa 255 ký tự'),
      code: Yup.string().required("Vui lòng nhập").max(255, 'Tối đa 255 ký tự'),
      value: Yup.string().required("Vui lòng nhập"),
    }
  }

  handleSubmit = (values) => {
    this.props.onSubmitForm({
      ...this._prepareData(values),
    });
  }
  handleFilesAdded = async (files,setFieldValue) => {
    if (!files[0]) return
    this.setState({ uploadingFile: true });
    const imageFiles = await this.handleUploadImageFiles({ params: { file: files[0] } });
    setFieldValue("value",imageFiles[0]?.url)
    this.setState({ uploadingFile: false });
  }
  handleUploadImageFiles = async ({ params }) => {
    try {
      const { data: response } = await authApi.uploadMultipleFiles({
        params,
      });
      if (!response?.result || !response.data) {
        toast.error(response.message);
        return;
      }
      return response.data;
    } catch(err) {
      toast.error(err);
    }
    return;
  }

  render() {
    const { 
      typeOptions, 
      uploadingFile,
    } = this.state;
    
    return (
      <Formik
        innerRef={(ref) => { this.props.formRef(ref); this.formikRef = ref }}
        ref={this.props.formikRef}
        initialValues={this._formikInitialValues()}
        onSubmit={this.handleSubmit}
        validationSchema={Yup.object().shape(this._formikValidationSchema())}
      >
        {(props) => {
          const { handleChange, values, errors, setFieldValue } = props;
          return (
            <form noValidate autoComplete="off">
                <FormControl className={classes.FormControl} component="fieldset">
                  <div className={classes.FieldControl}>
                    <label>
                      Name <span className={classes.RequiredMark}>*</span>
                    </label>
                    <TextField
                      autoComplete="off"
                      value={values.name}
                      placeholder="Nhập name"
                      name="name"
                      error={!!errors.name}
                      helperText={errors.name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className={classes.FieldControl}>
                    <label>
                      Code <span className={classes.RequiredMark}>*</span>
                    </label>
                    <TextField
                      autoComplete="off"
                      value={values.code}
                      placeholder="Nhập code"
                      name="code"
                      error={!!errors.code}
                      helperText={errors.code}
                      onChange={handleChange}
                    />
                  </div>
                  <div className={classes.FieldControl}>
                    <label>
                      Type <span className={classes.RequiredMark}>*</span>
                    </label>
                    <Select
                      style={{ marginTop: 0}}
                      value={values.type_id}
                      name="type_id"
                      onChange={(e) => {
                        setFieldValue("value","")
                        handleChange(e)
                      }}
                    >
                      { typeOptions.map( item => {
                        return <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                      })}
                    </Select>
                  </div>
                  <div className={classes.FieldControl}>
                    <label>
                      Value <span className={classes.RequiredMark}>*</span>
                    </label>
                    {
                      (() => {
                        let inputValue = <TextField
                          autoComplete="off"
                          value={values.value}
                          placeholder="Nhập value"
                          name="value"
                          error={!!errors.value}
                          helperText={errors.value}
                          onChange={handleChange}
                        />
                        switch (values.type_id) {
                          case 2:
                            inputValue = <><input 
                            name="value" 
                            type="color" 
                            onChange={(e) => {
                              setFieldValue("value",e.target.value.replace("#", "").toUpperCase())
                            }} 
                            value={`#${values.value}`}
                          /> { errors.value && <div className="error">{errors.value}</div>}</>
                            break;
                          case 3:
                            inputValue = <div className={`${uploadingFile && 'OverlayLoading'}`}>
                              <TextField
                                style={{ width: '100%', marginBottom: '.75rem'}}
                                autoComplete="off"
                                value={values.value}
                                placeholder="Nhập url hình ảnh"
                                name="value"
                                error={!!errors.value}
                                helperText={errors.value}
                                onChange={handleChange}
                              />
                              <FileUpload onFilesAdded={(files) => this.handleFilesAdded(files,setFieldValue)} accept="image/*"/>
                              { values.value  && <div style={{ marginTop: '.75rem'}} className={classes.FileImageUpload}>
                                  <img src={values.value} />
                                </div>
                              }
                            </div>
                            break;
                        }
                        return inputValue
                      })()
                    }
                  </div>
                  <div className={classes.FieldControl}>
                    <label>
                      Vendor <span className={classes.RequiredMark}>*</span>
                    </label>
                    <AutocompleteVendor
                      name="getcare_vendor_id"
                      value={values.getcare_vendor_id}
                      onChange={(e, value) => {
                        setFieldValue("getcare_vendor_id",value)
                      }}
                    />
                  </div>
                </FormControl>
            </form>
          );
        }}
      </Formik>
    );
  }
}

AssetBrandingFormDialogForm.propTypes = {

};

AssetBrandingFormDialogForm.defaultProps = {

};

export default AssetBrandingFormDialogForm;
