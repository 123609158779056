import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from "reselect";
import { makeSelectGroupProducts } from 'redux/selectors';
import {
  updateGroupProduct,
  saveGroupProduct,
  removeGroupProducts,
  deleteGroupProducts,
  exportGroupProducts,
  saveGroupProducts,
} from 'redux/actions/admin/groupActions';
import { getSortsString, getDisplayFields, genID } from 'utils/helper';
import {
  groupProductsParamsMap,
  groupProductsDisplayFields,
} from 'utils/constanst/adminGroupConstants';
import groupApi from 'utils/api/admin/groupApi';
import { isEqual, sortBy, orderBy } from 'lodash';

import GroupProductsItem from './GroupProductsItem/GroupProductsItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';
import Button from '@material-ui/core/Button';
import FileImportDialog from '../FileImportDialog/FileImportDialog';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';

import gridClasses from './GroupProductsGrid.module.scss';
import classes from './GroupProducts.module.scss';

class GroupProducts extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedItems: [],
      isDialogOpen: false,
      isImportLoading: false,
      importResult: null,
      isConfirmDialogOpen: false,
      listParams: {...groupProductsParamsMap},
    }
  }
  componentDidUpdate(prevProps) {
    const { groupProducts } = this.props;
    const { selectedItems } = this.state;
    if (!isEqual(sortBy(groupProducts), sortBy(prevProps.groupProducts)) && selectedItems.length > 0) {
      const remainItems = selectedItems.filter(p => groupProducts.some(item => {
        const comparedField = item.idStr ? `idStr` : `id`;
        return item[comparedField] === p[comparedField];
      }));
      this.setState({
        selectedItems: remainItems,
      });
    }
  }
  isAllItemSelected = () => {
    const { groupProducts } = this.props;
    const { selectedItems } = this.state;
    return selectedItems.length > 0 && selectedItems.length >= groupProducts.length;
  }
  isItemSelected = (item) => {
    return this.state.selectedItems.findIndex(p => {
      const comparedField = p.idStr ? 'idStr' : 'id';
      return p[comparedField] === item[comparedField];
    }) > -1;
  }
  handleAllSelectedToggle = (e) => {
    this.setState({
      selectedItems: e.target.checked ? [...this.props.groupProducts] : [],
    });
  }
  handleItemSelectedToggle = ({ item, isSelected }) => {
    const remainItems = this.state.selectedItems.filter(p => {
      const comparedField = p.idStr ? 'idStr' : 'id';
      return p[comparedField] !== item[comparedField];
    });
    this.setState({
      selectedItems: isSelected ? [...remainItems, {...item}] : remainItems,
    });
  }
  handleCreateNewRow = () => {
    this.props.saveGroupProduct({
      idStr: genID(),
      id: 0,
      product: null,
      ratio: '',
    });
  }
  handleSaveGroupProduct = ({ groupProductId, params }) => {
    this.props.updateGroupProduct({
      groupProductId: groupProductId ? groupProductId : undefined,
      params: {
        ...params,
        code: groupProductId ? undefined : this.props.groupCodeItems,
        getcare_erp_group_id: this.props.groupId ? this.props.groupId : undefined,
      },
    });
  }
  handleRemoveGroupProduct = (params) => {
    this.props.removeGroupProducts([{...params}]);
  }
  handleDeleteGroupProducts = () => {
    this.props.deleteGroupProducts({
      id: isNaN(this.props.groupId) ? undefined : this.props.groupId,
      code: !this.props.groupCodeItems ? undefined : this.props.groupCodeItems,
      groupProducts: [...this.state.selectedItems],
    });
    this.handleConfirmDialogClose();
  }
  handleExportGroupProducts = () => {
    this.props.exportGroupProducts({
      id: isNaN(this.props.groupId) ? undefined : this.props.groupId,
      code: !this.props.groupCodeItems ? undefined : this.props.groupCodeItems,
    });
  }
  handleDialogOpen = () => {
    this.setState({
      isDialogOpen: true,
      importResult: null,
      isImportLoading: false,
    });
  }
  handleDialogClose = () => {
    this.setState({
      isDialogOpen: false,
    });
  }
  handleConfirmDialogOpen = () => {
    this.setState({
      isConfirmDialogOpen: true,
    });
  }
  handleConfirmDialogClose = () => {
    this.setState({
      isConfirmDialogOpen: false,
    });
  }
  handleSubmitImport = async ({file, start_row}) => {
    this.setState({
      isImportLoading: true,
    });
    const { data: response } = await groupApi.importGroupProducts({
      id: undefined,
      code: this.props.groupCodeItems,
      params: {
        file: file,
        start_row: start_row,
      }
    });
    this.setState({
      isImportLoading: false,
    });

    const isSuccess = response.result && response.data;
    this.setState({
      importResult: {
        isSuccess: isSuccess,
        message: isSuccess ? '' : response.message,
        totalRecords: response.rows_total,
        successRecords: isSuccess ? response.rows_affected : 0,
      },
    });
    if (isSuccess) {
      this.props.saveGroupProducts([...response.data]);
      this.setState({
        listParams: {...groupProductsParamsMap},
      });
    }
  };
  handleSortChange = ({ sortBy, sortDir }) => {
    if (sortBy !== 'product_getcare_id') return;
    const newSortString = getSortsString({
      [sortBy]: sortDir,
    });

    this.handleFilterChange([
      {
        name: 'order',
        value: newSortString,
      },
    ]);

    const orderedProducts = orderBy(this.props.groupProducts, (item) => item.product.getcare_id, sortDir);
    this.props.saveGroupProducts([...orderedProducts]);
  };
  handleFilterChange = (filters, forceResetPage: false) => {
    const paramsFromFilters = filters.reduce((memo, item) => {
      memo[item.name] = item.value;
      return memo;
    }, {});
    const newParams = forceResetPage ? {
      ...this.state.listParams,
      ...paramsFromFilters,
      page: 1,
    } : {
      ...this.state.listParams,
      ...paramsFromFilters,
    };
    this.setState({
      listParams: newParams,
    });
  };

  render() {
    const { groupProducts, isGroupInactive } = this.props;
    const displayFields = getDisplayFields(
      this.state.listParams,
      groupProductsDisplayFields
    );
    const hasSelected = this.state.selectedItems.length > 0;

    return (<div className={`${classes.Wrap}`}>
      <div className={classes.Toolbar}>
        <div>
          <Button
            disabled={isGroupInactive}
            variant="contained"
            color="primary"
            size="small"
            onClick={this.handleDialogOpen}
          >
            Import File
          </Button>
          <Button
            disabled={!this.props.groupProducts.length}
            variant="contained"
            size="small"
            onClick={this.handleExportGroupProducts}
          >
            Export danh sách ra .CSV
          </Button>
        </div>
        <Button
          className={`${classes.MultiActionBtn} ${hasSelected && classes.Active}`}
          color="secondary"
          variant="contained"
          size="small"
          onClick={this.handleConfirmDialogOpen}
        >
          Xoá
        </Button>
      </div>
      <div className={`${classes.List}`}>
        <div className={`${classes.Body}`}>
          <div className={`${gridClasses.Row} ${classes.Header}`}>
            <div className={gridClasses.Col}>
              <Checkbox
                disabled={isGroupInactive}
                className={classes.Checkbox}
                checked={this.isAllItemSelected()}
                onChange={this.handleAllSelectedToggle}
              />
            </div>
            { displayFields.map((item) => (
              <ListHeaderCol
                key={item.name}
                className={gridClasses.Col}
                {...item}
                onSortChange={this.handleSortChange}
              />
            )) }
            <div className={`${gridClasses.Col} ${classes.HeaderCol} ${classes.ActionsCol}`}>
              <Button
                disabled={isGroupInactive}
                color="primary"
                variant="contained"
                size="small"
                onClick={this.handleCreateNewRow}
              >
                Thêm dòng
              </Button>
            </div>
          </div>
        
          { groupProducts.length
            ? groupProducts.map(item => (
              <GroupProductsItem
                key={`medicine-${item.id}`}
                {...item}
                isGroupInactive={isGroupInactive}
                isSelected={this.isItemSelected(item)}
                currentList={[...groupProducts]}
                onItemSelectedToggle={this.handleItemSelectedToggle}
                saveGroupProduct={this.handleSaveGroupProduct}
                removeGroupProduct={this.handleRemoveGroupProduct}
              />))
            : (<p className="NoData">Không có sản phẩm nào</p>)
          }
        </div>
      </div>
      { this.state.isDialogOpen
        && <FileImportDialog
          title="Import danh sách sản phẩm"
          isOpen={this.state.isDialogOpen}
          onClose={this.handleDialogClose}
          onSubmitImport={this.handleSubmitImport}
          isLoading={this.state.isImportLoading}
          importResult={this.state.importResult}
        />
      }
      { this.state.isConfirmDialogOpen
        && <ConfirmationDialog
          isOpen={this.state.isConfirmDialogOpen}
          title="Xác nhận xoá"
          message="Bạn có chắc xoá các sản phẩm đã chọn ra khỏi nhóm?"
          onClose={this.handleConfirmDialogClose}
          onSubmit={this.handleDeleteGroupProducts}
        />
      }
    </div>);
  }
}

GroupProducts.propTypes = {
  groupId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  groupCodeItems: PropTypes.string,
  isGroupInactive: PropTypes.bool,
};

GroupProducts.defaultProps = {
  isGroupInactive: false,
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateGroupProduct: (payload) => dispatch(updateGroupProduct(payload)),
    saveGroupProduct: (payload) => dispatch(saveGroupProduct(payload)),
    removeGroupProducts: (payload) => dispatch(removeGroupProducts(payload)),
    deleteGroupProducts: (payload) => dispatch(deleteGroupProducts(payload)),
    exportGroupProducts: (payload) => dispatch(exportGroupProducts(payload)),
    saveGroupProducts: (payload) => dispatch(saveGroupProducts(payload)),
  };
};
const mapStateToProps = createStructuredSelector({
  groupProducts: makeSelectGroupProducts(),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(GroupProducts);
