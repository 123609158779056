export const GET_SALES_CHANNEL_LIST = 'getcare/mdm/salesChannel/GET_SALES_CHANNEL_LIST';
export const GET_SALES_CHANNEL = 'getcare/mdm/salesChannel/GET_SALES_CHANNEL';
export const CREATE_SALES_CHANNEL = 'getcare/mdm/salesChannel/CREATE_SALES_CHANNEL';
export const UPDATE_SALES_CHANNEL = 'getcare/mdm/salesChannel/UPDATE_SALES_CHANNEL';
export const GET_SALES_CHANNEL_HISTORY = 'getcare/admin/salesChannel/GET_SALES_CHANNEL_HISTORY';

// mutation
export const SAVE_SALES_CHANNEL_LIST = 'getcare/mdm/salesChannel/SAVE_SALES_CHANNEL_LIST';
export const SAVE_SALES_CHANNEL_LIST_TOTAL = 'getcare/mdm/salesChannel/SAVE_SALES_CHANNEL_LIST_TOTAL';
export const SAVE_SALES_CHANNEL = 'getcare/mdm/salesChannel/SAVE_SALES_CHANNEL';
export const SAVE_SALES_CHANNEL_COORDINATERS = 'getcare/mdm/salesChannel/SAVE_SALES_CHANNEL_COORDINATERS';
export const SAVE_SALES_CHANNEL_COORDINATER = 'getcare/mdm/salesChannel/SAVE_SALES_CHANNEL_COORDINATER';
export const REMOVE_SALES_CHANNEL_COORDINATERS = 'getcare/mdm/salesChannel/REMOVE_SALES_CHANNEL_COORDINATERS';

export const SAVE_SALES_CHANNEL_HISTORY = 'getcare/admin/salesChannel/SAVE_SALES_CHANNEL_HISTORY';

export const SAVE_SALES_CHANNEL_ALLOWED_ACTIONS = 'getcare/admin/salesChannel/SAVE_SALES_CHANNEL_ALLOWED_ACTIONS';
