import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import KeyAccountLockListItem from './KeyAccountLockListItem/KeyAccountLockListItem';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';
import Checkbox from '@material-ui/core/Checkbox';

import gridClasses from './KeyAccountLockListGrid.module.scss';
import classes from './KeyAccountLockList.module.scss';

class KeyAccountLockList extends PureComponent {

  isAllKeyAccountLockListSelected = () => {
    const { keyAccountLockList, selectedIds } = this.props;
    return selectedIds.length > 0 && selectedIds.length >= keyAccountLockList.length;
  }

  isKeyAccountLockSelected = (id) => {
    return this.props.selectedIds.findIndex(item => item === id) > -1;
  }
  render() {
    const { keyAccountLockList, isLoading, displayFields, isEditAllowed } = this.props;

    return <div className={`${classes.List} ${isLoading && 'OverlayLoading'}`}>
      <div
        className={`${classes.Header}`}
        ref={(el) => (this.rowRef = el)}
      >
        <div className={`${gridClasses.Row} ${isEditAllowed ? gridClasses.HasCheckboxCol : ''} ${classes.Header}`}>

          <div className={gridClasses.Col}>
            <Checkbox
              className={classes.Checkbox}
              checked={this.isAllKeyAccountLockListSelected()}
              onChange={this.props.onAllSelectedToggle}
            />
          </div>
          {displayFields.map((item) => (
            <ListHeaderCol
              key={item.name}
              className={gridClasses.Col}
              {...item}
              onSortChange={this.props.onSortChange}
            />
          ))}
        </div>
      </div>

      <div className={`${classes.Body}`}>

        {!isLoading && keyAccountLockList.length
          ? keyAccountLockList.map(item => (
            <KeyAccountLockListItem
              key={`keyAccount-${item.id}`}
              {...item}
              onStatusChange={this.props.onStatusChange}
              isSelected={this.isKeyAccountLockSelected(item.id)}
              onItemSelectedToggle={this.props.onItemSelectedToggle}
            />))
          : (<p className="NoData">Không có data</p>)
        }
      </div>
    </div>;
  }
}

KeyAccountLockList.propTypes = {
  displayFields: PropTypes.array,
  keyAccountLockList: PropTypes.array,
  isLoading: PropTypes.bool,
  onSortChange: PropTypes.func,
  onStatusChange: PropTypes.func,
  onAllSelectedToggle: PropTypes.func,
  onItemSelectedToggle: PropTypes.func,
};

KeyAccountLockList.defaultProps = {
  displayFields: [],
  keyAccountLockList: [],
  isLoading: false,
};

export default KeyAccountLockList;
