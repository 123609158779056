import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { statusMap } from 'utils/constanst/adminShipperConstants';

import gridClasses from '../ShipperListGrid.module.scss';
import classes from './ShipperListItem.module.scss';

class ShipperListItem extends PureComponent {
  render() {
    const {
      id,
      code,
      name,
      phone,
      status,
      other_name,
    } = this.props;

    return (
      <Link to={`shipper/${id}`} className={`${classes.ProductItem} ${gridClasses.Row}`}>
        <div className={gridClasses.Col}>{code}</div>
        <div className={gridClasses.Col}>{name}</div>
        <div className={gridClasses.Col}>{other_name}</div>
        <div className={gridClasses.Col}>{phone}</div>
        <div className={gridClasses.Col}>{statusMap[status]}</div>
      </Link>
    )
  }
}

ShipperListItem.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  name: PropTypes.string,
  code: PropTypes.string,
  phone: PropTypes.string,
  status: PropTypes.number,
  other_name: PropTypes.string,
};

export default withRouter(ShipperListItem);
