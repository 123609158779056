import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { debounce } from 'lodash';
import { getShippingCouponList } from 'redux/actions/admin/shippingCouponActions';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectShippingCouponLoading,
  makeSelectShippingCouponList,
  makeSelectShippingCouponListTotal,
  makeSelectWSEvents
} from 'redux/selectors';
import { removeWSEvent } from 'redux/actions/wsActions';

import {
  listParamsMap,
  listDisplayFields,
} from 'utils/constanst/adminShippingCouponConstants';
import { getSortsString, getDisplayFields } from 'utils/helper';
import { withRouter } from 'react-router-dom';
import shippingCouponApi from 'utils/api/admin/shippingCouponApi';

import ShippingCouponList from 'components/admin/ShippingCouponList/ShippingCouponList';
import ShippingCouponListFilters from 'components/admin/ShippingCouponList/ShippingCouponListFilters/ShippingCouponListFilters';
import ListPagination from 'components/ListPagination/ListPagination';
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';
import { toast } from 'react-toastify';
import { FLUSH_CACHE_POLICY_PRICE } from 'utils/constanst/wsConstants';
import classes from './ShippingCoupons.module.scss';

class ShippingCoupons extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      listParams: { ...listParamsMap },
      flushCacheEnabled: true,
    };
  }
  componentDidMount() {
    const { listParams } = this.state;
    this.props.getShippingCouponList({
      params: listParams,
    });
  }

  handleSortChange = ({ sortBy, sortDir }) => {
    const newSortString = getSortsString({
      [sortBy]: sortDir,
    });
    this.handleFilterChange([
      {
        name: 'order',
        value: newSortString,
      },
    ]);
  };
  handleFilterChange = (filters, forceResetPage: false) => {
    const paramsFromFilters = filters.reduce((memo, item) => {
      memo[item.name] = item.value;
      return memo;
    }, {});
    const newParams = forceResetPage ? {
      ...this.state.listParams,
      ...paramsFromFilters,
      page: 1,
    } : {
      ...this.state.listParams,
      ...paramsFromFilters,
    };
    this.setState({
      listParams: newParams,
    });
    this.props.getShippingCouponList({
      params: newParams,
    });
  };
  createNewShippingCoupon = () => {
    this.props.history.push(`/shipping-coupon/new`);
  }

  getFlushCachePolicyPriceEvent = () => {
    return this.props.events.find(e => e.event === FLUSH_CACHE_POLICY_PRICE);
  }
  handleFlushCache = async () => {
    this.setState({ flushCacheLoading: true, flushCacheEnabled: false, });
    try {
      const { data: response } = await shippingCouponApi.flush_cache();
      if (!response?.result) {
        toast.error(response.message);
        return;
      }
      this.setState({ flushCacheLoading: false });
      this.props.getShippingCouponList({
        params: this.state.listParams,
      });
    } catch(err) {
      this.setState({ flushCacheLoading: false, flushCacheEnabled: true, });
      toast.error(err);
    }
  }

  render() {
    const { shippingCouponList, shippingCouponListTotal, loading } = this.props;
    const { listParams } = this.state;
    const isListLoading = loading;

    return (
      <React.Fragment>
        <div className={classes.PageWrap}>
          <div className={classes.PageHeader}>
            <h1 className={classes.PageTitle}>Danh sách shipping coupon theo vendor</h1>
            <div>
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              onClick={this.handleFlushCache}
            >
              Flush cache
            
            </Button>
            <Button
              size="small"
              variant="contained"
              color="primary"
              className={classes.addNew}
              startIcon={<Add />}
              onClick={this.createNewShippingCoupon}
            >Tạo mới</Button>
            </div>
          </div>
          <div className={classes.PageMain}>
            <ShippingCouponListFilters
              onFilterChange={debounce(this.handleFilterChange, 500)}
            />
            <ShippingCouponList
              displayFields={getDisplayFields(
                listParams,
                listDisplayFields
              )}
              shippingCouponList={shippingCouponList}
              isLoading={isListLoading}
              onSortChange={this.handleSortChange}
            />
          </div>
          <div className={classes.PageFooter}>
            <ListPagination
              page={listParams.page}
              pageSize={listParams.page_size}
              total={shippingCouponListTotal}
              listName="shipping coupon"
              onFilterChange={this.handleFilterChange}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  shippingCouponList: makeSelectShippingCouponList(),
  shippingCouponListTotal: makeSelectShippingCouponListTotal(),
  loading: makeSelectShippingCouponLoading(),
  events: makeSelectWSEvents(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getShippingCouponList: (payload) => dispatch(getShippingCouponList(payload)),
    removeWSEvent: (payload) => dispatch(removeWSEvent(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter)(ShippingCoupons);
