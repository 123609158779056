import {
  GET_SALES_CHANNEL_LIST,
  SAVE_SALES_CHANNEL_LIST,
  SAVE_SALES_CHANNEL_LIST_TOTAL,
  GET_SALES_CHANNEL,
  CREATE_SALES_CHANNEL,
  UPDATE_SALES_CHANNEL,
  SAVE_SALES_CHANNEL,
  SAVE_SALES_CHANNEL_COORDINATER,
  SAVE_SALES_CHANNEL_COORDINATERS,
  REMOVE_SALES_CHANNEL_COORDINATERS,
  GET_SALES_CHANNEL_HISTORY,
  SAVE_SALES_CHANNEL_HISTORY,
  SAVE_SALES_CHANNEL_ALLOWED_ACTIONS,
} from 'redux/constants/admin/salesChannelConstants';

export function getSalesChannelList(payload) {
  return {
    type: GET_SALES_CHANNEL_LIST,
    payload
  };
}
export function getSalesChannel(id) {
  return {
    type: GET_SALES_CHANNEL,
    id
  };
}
export function createSalesChannel(payload) {
  return {
    type: CREATE_SALES_CHANNEL,
    payload
  };
}
export function updateSalesChannel(payload) {
  return {
    type: UPDATE_SALES_CHANNEL,
    payload
  };
}

export function getSalesChannelHistory(payload) {
  return {
    type: GET_SALES_CHANNEL_HISTORY,
    payload,
  };
}

// mutation
export function saveSalesChannelList(salesChannelList) {
  return {
    type: SAVE_SALES_CHANNEL_LIST,
    salesChannelList
  };
}
export function saveSalesChannelListTotal(salesChannelListTotal) {
  return {
    type: SAVE_SALES_CHANNEL_LIST_TOTAL,
    salesChannelListTotal
  };
}
export function saveSalesChannel(salesChannel) {
  return {
    type: SAVE_SALES_CHANNEL,
    salesChannel
  };
}

export function saveSalesChannelCoordinater(salesChannelCoordinater) {
  return {
    type: SAVE_SALES_CHANNEL_COORDINATER,
    salesChannelCoordinater
  };
}
export function removeSalesChannelCoordinaters(salesChannelCoordinaters) {
  return {
    type: REMOVE_SALES_CHANNEL_COORDINATERS,
    salesChannelCoordinaters
  };
}
export function saveSalesChannelCoordinaters(salesChannelCoordinaters) {
  return {
    type: SAVE_SALES_CHANNEL_COORDINATERS,
    salesChannelCoordinaters
  };
}

export function saveSalesChannelHistory(salesChannelHistory) {
  return {
    type: SAVE_SALES_CHANNEL_HISTORY,
    salesChannelHistory,
  };
}

export function saveSalesChannelAllowedActions(salesChannelAllowedActions) {
  return {
    type: SAVE_SALES_CHANNEL_ALLOWED_ACTIONS,
    salesChannelAllowedActions,
  };
}
