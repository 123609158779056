import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import UserListItem from './UserListItem/UserListItem';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';

import gridClasses from './UserListGrid.module.scss';
import classes from './UserList.module.scss';

class UserList extends PureComponent {
  render() {
    const { userList, isLoading, displayFields } = this.props;

    return <div className={`${classes.List} ${isLoading && 'OverlayLoading'}`}>
      <div
        className={`${classes.Header}`}
        ref={(el) => (this.rowRef = el)}
      >
            <div className={`${gridClasses.Row} ${classes.Header}`}>
          { displayFields.map((item) => (
            <ListHeaderCol
              key={item.name}
              className={gridClasses.Col}
              {...item}
              onSortChange={this.props.onSortChange}
            />
          )) }
        </div>
        </div>

      <div className={`${classes.Body}`}>
        { !isLoading && userList.length
          ? userList.map(item => (
            <UserListItem
              key={`user-${item.id}`}
              {...item}
              onStatusChange={this.props.onStatusChange}
            />))
          : (<p className="NoData">Không có data</p>)
        }
      </div>
    </div>;
  }
}

UserList.propTypes = {
  displayFields: PropTypes.array,
  userList: PropTypes.array,
  isLoading: PropTypes.bool,
  onSortChange: PropTypes.func,
  onStatusChange: PropTypes.func,
};

UserList.defaultProps = {
  displayFields: [],
  userList: [],
  isLoading: false,
};

export default UserList;
