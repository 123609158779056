import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectTestShippingCouponOrderList } from 'redux/selectors';
import {
  saveOrderListItem,
  removeOrderListItems,
} from 'redux/actions/admin/testShippingCouponActions';
import { getDisplayFields, genID } from 'utils/helper';
import { orderListDisplayFields, orderListParamsmap } from 'utils/constanst/adminTestShippingCouponConstants';

import OrderListItem from './OrderListItem/OrderListItem';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';
import Button from '@material-ui/core/Button';

import gridClasses from './OrderListGrid.module.scss';
import classes from './OrderList.module.scss';

class OrderList extends PureComponent {
  handleCreateNewRow = () => {
    this.props.saveOrderListItem({
      idStr: genID(),
      id: 0,
      getcare_product: null,
      getcare_vendor: null,
      getcare_uom_base: null,
      quantity_number: 1,
      price_sales: 0,
      vat: null,
      isEditing: true,
    });
  };
  handleSaveOrderListItem = (params) => {
    this.props.saveOrderListItem({
      ...params,
    });
  };
  handleRemoveOrderListItems = (params) => {
    this.props.removeOrderListItems([{ ...params }]);
  };

  render() {
    const { orderList } = this.props;
    const displayFields = getDisplayFields(orderListParamsmap, orderListDisplayFields);

    return (
      <div className={`${classes.Wrap}`}>
        <div className={classes.Toolbar}>
          <h3><strong>Sản phẩm đặt hàng</strong></h3>
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={this.handleCreateNewRow}
          >
            Thêm dòng
          </Button>
        </div>
        <div className={`${classes.List}`}>
          <div className={`${gridClasses.Row} ${classes.Header}`}>
            {displayFields.map((item) => (
              <ListHeaderCol
                key={item.name}
                {...item}
                className={`${gridClasses.Col} ${item.className}`}
              />
            ))}
            <ListHeaderCol
              className={`${gridClasses.Col} ${classes.ActionsCol}`}
              label=""
            />
          </div>
          <div className={`${classes.Body}`}>
            {orderList.length ? (
              orderList.map((item) => (
                <OrderListItem
                  key={`promotion-product-${item.id || item.idStr}`}
                  {...item}
                  selectedCustomerId={this.props.selectedCustomerId}
                  selectedCustomerCode={this.props.selectedCustomerCode}
                  orderDate={this.props.orderDate}
                  currentList={[...orderList]}
                  saveOrderListItem={this.handleSaveOrderListItem}
                  removeOrderListItem={this.handleRemoveOrderListItems}
                />
              ))
            ) : (
              <p className="NoData">Không có data</p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

OrderList.propTypes = {
};

OrderList.defaultProps = {
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveOrderListItem: (payload) =>
      dispatch(saveOrderListItem(payload)),
    removeOrderListItems: (payload) =>
      dispatch(removeOrderListItems(payload)),
  };
};
const mapStateToProps = createStructuredSelector({
  orderList: makeSelectTestShippingCouponOrderList(),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(OrderList);
