import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import {
  makeSelectTestShippingCouponOrderList,
  makeSelectTestShippingCouponAvailables,
  makeSelectTestShippingCouponLoading,
} from 'redux/selectors';
import {
  checkShippingCoupon,
  saveShippingCouponAvailables,
  saveShippingCouponAmount,
} from 'redux/actions/admin/testShippingCouponActions';
import { currencyFormatVN } from 'utils/helper';

import Button from '@material-ui/core/Button';
import OrderList from 'components/admin/TestShippingCoupon/OrderList/OrderList';
import CustomerAndDateSelector from 'components/admin/TestShippingCoupon/CustomerAndDateSelector/CustomerAndDateSelector';
import ShippingCouponAvailables from 'components/admin/TestShippingCoupon/ShippingCouponAvailables/ShippingCouponAvailables';

import classes from './TestShippingCoupon.module.scss';

class TestShippingCoupon extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      orderDate: new Date(),
      selectedCustomer: null,

      hasChecked: false,
    }
  }

  _isAbleToCheck = () => {
    const { orderDate, selectedCustomer } = this.state;
    return !!this._getValidOrderItems().length && !!orderDate && !!selectedCustomer;
  }
  _getValidOrderItems = () => {
    const { orderList } = this.props;
    return orderList.filter(
      item => this._isOrderItemValid(item)
    );
  }
  _getOrderAmount = () => {
    const { orderList } = this.props;
    return orderList.filter(product =>
      product.getcare_product?.id && product.getcare_vendor?.id && product.getcare_uom_base?.id
    ).reduce((memo, item) => {
        memo += (Number(item.price_sales_total || 0) * Number(item.quantity_number || 0) - Number(item.discount_amount || 0));
        return memo;
      }, 0);
  }
  _isOrderItemValid = (order) => {
    return order.getcare_product?.id
      && order.getcare_vendor?.id
      && order.getcare_uom_base?.id
      && order.quantity_number > 0
  }
  _getOrderListData = () => {
    const { orderList } = this.props;
    return orderList.filter(
      item => this._isOrderItemValid(item)
    ).map(item => ({
      getcare_product_id: item.getcare_product.id,
      getcare_vendor_id: item.getcare_vendor.id,
      getcare_uom_base_id: item.getcare_uom_base.id,
      quantity_number: Number(item.quantity_number),
      discount_amount: Number(item.discount_amount),
    }));
  }
  _prepareData = () => {
    const { orderDate, selectedCustomer } = this.state;
    return {
      getcare_pharmacy_code: selectedCustomer?.code,
      date: orderDate.toISOString(),

      order_items: this._getOrderListData(),
    }
  }
  _resetData = () => {
    this.props.saveShippingCouponAvailables([]);
    this.props.saveShippingCouponAmount(null);
  }

  handleChangeCustomer = (newValue) => {
    this.setState({
      selectedCustomer: { ...newValue },
    });
  }
  handleChangeOrderDate = (newValue) => {
    this.setState({
      orderDate: newValue,
    });
  }
  handleCheckShippingCoupon = () => {
    this._resetData();
    this.setState({
      hasChecked: true,
    });
    this.props.checkShippingCoupon({
      params: this._prepareData(),
    });
  }

  render() {
    const { selectedCustomer, hasChecked } = this.state;
    const { shippingCouponAvailables, loading } = this.props;

    const isAbleToCheck = this._isAbleToCheck();
    const orderAmount = this._getOrderAmount();

    return (<>
      <div className={classes.PageWrap}>
        <div className={classes.PageHeader}>
          <div className={classes.PageHeaderInner}>
            <h1 className={classes.PageTitle}>Test Shipping Coupon</h1>
          </div>
        </div>

        <div className={`${classes.PageMain} ${loading ? 'OverlayLoading' : ''}`}>
          <CustomerAndDateSelector
            orderDate={this.state.orderDate}
            selectedCustomer={selectedCustomer}
            onChangeCustomer={this.handleChangeCustomer}
            onChangeOrderDate={this.handleChangeOrderDate}
          />

          { selectedCustomer?.id &&
            <OrderList orderDate={this.state.orderDate} selectedCustomerId={selectedCustomer?.id} selectedCustomerCode={selectedCustomer?.code}/>
          }

          { isAbleToCheck &&
            <div className={classes.OrderAmountWrap}>
              <div className={classes.OrderAmount}>
                <p className={`${classes.OrderAmountLine} ${classes.TotalLine}`}>
                  <label>Tổng cộng</label>
                  <span className={classes.OrderAmountValue}>{![null, undefined, ''].includes(orderAmount) ? currencyFormatVN(orderAmount) : `-`}</span>
                </p>
              </div>
            </div>
          }

          <div className={classes.CheckControl}>
            <Button
              color="primary"
              variant="contained"
              disabled={!isAbleToCheck}
              onClick={this.handleCheckShippingCoupon}
            >
              Tính shipping coupon cho đơn hàng này
            </Button>
          </div>

          { isAbleToCheck &&
            <div className={classes.ShippingCouponActivesWrap}>
              { shippingCouponAvailables && shippingCouponAvailables.length > 0
                ? <ShippingCouponAvailables/>
                : hasChecked ? 'Không có shipping coupon nào' : ''
              }
            </div>
          }
        </div>
      </div>
    </>);
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    checkShippingCoupon: (payload) => dispatch(checkShippingCoupon(payload)),
    saveShippingCouponAvailables: (payload) => dispatch(saveShippingCouponAvailables(payload)),
    saveShippingCouponAmount: (payload) => dispatch(saveShippingCouponAmount(payload)),
  };
};
const mapStateToProps = createStructuredSelector({
  loading: makeSelectTestShippingCouponLoading(),
  orderList: makeSelectTestShippingCouponOrderList(),
  shippingCouponAvailables: makeSelectTestShippingCouponAvailables(),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(TestShippingCoupon);
