import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import ingredientApi from 'utils/api/admin/ingredientApi';
import { suggestionPageSize } from 'utils/constanst/common';

import { Link } from 'react-router-dom';
import ArrowRightOutlined from '@material-ui/icons/ArrowRightOutlined';
import EditOutlined from '@material-ui/icons/EditOutlined';
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import IconButton from '@material-ui/core/IconButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';

import gridClasses from '../ProductIngredientsGrid.module.scss';
import classes from './ProductIngredientItem.module.scss';

class ProductIngredientItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      selectedCategory: null,
      contentInput: '',
      categoryInput: '',
      categoryOptions: [],
      isDialogOpen: false,
    };
  }
  componentDidMount() {
    this.setState({
      isEditing: !this.props.id,
      categoryInput: this.props.name,
      selectedCategory: this.props.id
        ? {
            id: this.props.id,
            l6_name: this.props.l6_name,
          }
        : null,
      contentInput: this.props.content,
    });
  }
  componentDidUpdate(prevProps, prevState) {
    const { categoryInput } = this.state;
    if (categoryInput && categoryInput !== prevState.categoryInput) {
      this.loadCategories({ l6_name: categoryInput });
    }
  }
  loadCategories = debounce(async (params) => {
    const { data: response } = await ingredientApi.getAll({
      params: { page: 1, page_size: suggestionPageSize, ...params },
    });
    if (!response?.result) return;
    this.setState({
      categoryOptions: response.data ? [...response.data] : [],
    });
  }, 500);
  isNameValid = () => {
    return !!this.state.categoryInput;
  };
  isRowValid = () => {
    return this.isNameValid();
  };
  handleEdit = (e) => {
    if (e) e.stopPropagation();
    this.setState({
      isEditing: true,
    });
  };
  handleCancel = (e) => {
    if (e) e.stopPropagation();
    this.setState({
      isEditing: false,
    });
  };
  handleSave = (e) => {
    if (e) e.stopPropagation();
    const { selectedCategory, contentInput, categoryInput } = this.state;
    this.props.onSaveCategory({
      idStr: this.props.idStr,
      id: selectedCategory?.id || undefined,
      l6_name: selectedCategory?.l6_name || categoryInput,
      content: contentInput,
    });
    this.setState({
      isEditing: false,
    });
  };
  handleRowClick = () => {
    if (!this.state.isEditing) {
      this.handleEdit();
    }
  };
  handleRowBlur = () => {
    if (this.state.isEditing && this.isRowValid()) {
      this.handleSave();
    }
  };
  handleDelete = () => {
    const { selectedCategory, contentInput, categoryInput } = this.state;
    this.props.onDeleteCategory({
      idStr: this.props.idStr,
      id: selectedCategory?.id || undefined,
      l6_name: selectedCategory?.l6_name || categoryInput,
      content: contentInput,
    });
  };
  handleOpenDialog = (e) => {
    if (e) e.stopPropagation();
    this.setState({
      isDialogOpen: true,
    });
  }
  handleCloseDialog = () => {
    this.setState({
      isDialogOpen: false,
    });
  }

  render() {
    const { id, l6_name, content, isApproved, isEditAllowed } = this.props;
    const {
      isEditing,
      selectedCategory,
      contentInput,
      categoryOptions,
      categoryInput,
    } = this.state;

    return (<>
      <ClickAwayListener onClickAway={this.handleRowBlur}>
        <div
          className={`${classes.Item} ${gridClasses.Row} ${isEditAllowed ? gridClasses.HasActionCol : ''}`}
          onClick={isEditAllowed ? this.handleRowClick : undefined}
          style={{ cursor: isEditAllowed ? 'pointer' : 'default'}}
        >
          <div className={gridClasses.Col}>
            {isEditing ? (
              <Autocomplete
                freeSolo
                selectOnFocus
                openOnFocus
                size="small"
                handleHomeEndKeys={false}
                value={selectedCategory || null}
                onInputChange={(e, newInputValue) => {
                  this.setState({
                    categoryInput: newInputValue,
                  });
                }}
                options={categoryOptions || []}
                filterOptions={(x) => x}
                getOptionLabel={(option) => option?.l6_name || ''}
                renderOption={(option) => {
                  const {
                    l1_name,
                    l2_name,
                    l6_name,
                  } = option;
                  return `${l1_name ? l1_name + '-' : ''}${l2_name ? l2_name + '-' : ''}${l6_name ? l6_name : ''}`;
                }}
                getOptionSelected={(option, value) =>
                  value && value.id && option ? option.id === value.id : null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!this.isNameValid()}
                    placeholder="- Chọn -"
                  />
                )}
                onChange={(e, value) => {
                  this.setState({
                    selectedCategory: value,
                  });
                }}
              />
            ) : (
              <span
                className={`${classes.Label} ${
                  isApproved ? '' : classes.Unapprove
                }`}
              >
                { isApproved && <Link
                    to={{ pathname: '/category', state: { ingredientId: id } }}
                  >
                    <ArrowRightOutlined color="error" fontSize="small" />
                  </Link>
                }
                {l6_name}
              </span>
            )}
          </div>
          <div className={gridClasses.Col}>
            {isEditing ? (
              <TextField
                className={classes.FieldWrap}
                value={contentInput}
                placeholder="Nhập..."
                name="content"
                autoComplete="off"
                onChange={(e) => {
                  this.setState({
                    contentInput: e.target.value,
                  });
                }}
              />
            ) : (
              content
            )}
          </div>
          { isEditAllowed && (
            <div className={`${gridClasses.Col} ${classes.ActionsCol}`}>
              {isEditing ? (
                <>
                  <IconButton
                    disabled={!this.props.id}
                    onClick={this.handleCancel}
                    style={{ padding: '3px' }}
                    size="small"
                  >
                    <CancelOutlined
                      fontSize="small"
                      color={!!this.props.id ? `secondary` : `inherit`}
                    />
                  </IconButton>
                  <IconButton
                    onClick={this.handleSave}
                    style={{ padding: '3px' }}
                    size="small"
                    disabled={!this.isRowValid()}
                  >
                    <CheckCircleOutlineOutlined
                      fontSize="small"
                      color={this.isRowValid() ? `primary` : `inherit`}
                    />
                  </IconButton>
                </>
              ) : (
                <>
                  <IconButton
                    onClick={this.handleOpenDialog}
                    style={{ padding: '3px' }}
                    size="small"
                  >
                    <DeleteOutline fontSize="small" />
                  </IconButton>
                  <IconButton
                    onClick={this.handleEdit}
                    style={{ padding: '3px' }}
                    size="small"
                  >
                    <EditOutlined fontSize="small" color="primary" />
                  </IconButton>
                </>
              )}
            </div>
          )}
        </div>
      </ClickAwayListener>
      { this.state.isDialogOpen &&
        <ConfirmationDialog
          isOpen={this.state.isDialogOpen}
          title="Xác nhận xoá"
          message={(<p>{`Xoá hoạt chất: `} <strong>{selectedCategory?.l6_name || categoryInput}</strong></p>)}
          onClose={this.handleCloseDialog}
          onSubmit={this.handleDelete}
        />
      }
    </>);
  }
}

ProductIngredientItem.propTypes = {
  idStr: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  l6_name: PropTypes.string,
  content: PropTypes.string,
  isApproved: PropTypes.bool,
  onSaveCategory: PropTypes.func,
};

ProductIngredientItem.defaultProps = {
  l6_name: '',
  content: '',
};

export default ProductIngredientItem;
