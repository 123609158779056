import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import classes from './ConfirmationDialog.module.scss';

class ConfirmationDialog extends PureComponent {
  render() {
    const { isOpen, isLoading, title, message, type, dialogAction } = this.props;

    return (
      <Dialog
        open={isOpen}
        onClose={this.props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.Dialog}
        disableBackdropClick={isLoading}
        disableEscapeKeyDown={isLoading}
        classes={{ paper: `${isLoading && 'OverlayLoading'}` }}
      >
          <DialogTitle className={classes.DialogTitle}>
            { title }
          </DialogTitle>
          <DialogContent className={classes.DialogContent}>
            { message }
          </DialogContent>
          {
            <>

              <DialogActions className={classes.DialogFooter}>
                { type === 'info' && dialogAction ? dialogAction : `` }
                { type === 'normal' && <>
                    <Button onClick={this.props.onClose}>
                      Huỷ bỏ
                    </Button>
                    <Button
                      onClick={this.props.onSubmit}
                      variant="contained"
                      color="primary"
                    >
                      Xác nhận
                    </Button>
                  </>
                }
              </DialogActions>
            </>
          }
      </Dialog>
    );
  }
}

ConfirmationDialog.propTypes = {
  isOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  type: PropTypes.oneOf(['normal', 'info']),
  dialogAction: PropTypes.element,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

ConfirmationDialog.defaultProps = {
  isOpen: false,
  isLoading: false,
  title: '',
  message: '',
  type: 'normal',
};

export default ConfirmationDialog;
