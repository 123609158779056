import axiosAuth from 'utils/axios/axiosAuth';

const uomApi = {
  getAllUom: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_product/`, {
      params: {
        ...queryParams,
        type: 'uom',
      }
    });
  },
  getAllUomBase: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_uom_base/`, {
      params: queryParams,
    });
  },


  getProductSuggestions: ({ params }) => {
    return axiosAuth.get(`getcare_vendor_product_action/suggest_product?keyword=${params.getcare_product_sugget}`);
  },

  getProductVendor: ({ params }) => {
      const queryParams = Object.keys(params).reduce((memo, key) => {
        if (!!params[key]) memo[key] = params[key];
        return memo;
      }, {});
      return axiosAuth.get('getcare_vendor', {
        params: queryParams,
      });
  },

  getProductUnits: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_product_unit/`, {
      params: queryParams,
    });
  },

  tagUOMSToProducts: ({ params }) => {
    return axiosAuth.post(`getcare_uom_tag_multiple_product/`, params);
  },
  updateUOMToProduct: ({ getcare_uom_id, params }) => {
    return axiosAuth.put(`getcare_uom/${getcare_uom_id}`, params);
  },
};

export default uomApi;
