import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import ReportProblemOutlined from '@material-ui/icons/ReportProblemOutlined';
import Tooltip from '@material-ui/core/Tooltip';

import gridClasses from '../GroupListGrid.module.scss';
import classes from './GroupListItem.module.scss';

class GroupListItem extends PureComponent {
  goToDetails = (e) => {
    this.props.history.push(`/group/${this.props.id}`);
  };

  render() {
    const {
      id,
      code,
      name,
      getcare_erp_group_type,
      description,
      is_empty,
    } = this.props;

    return <Link
      to={`/group/${id}`}
      className={`${classes.Item} ${gridClasses.Row}`}
    >
      <div className={`${gridClasses.Col} ${classes.CodeCol}`}>
        { code }
        { is_empty && (
          <Tooltip title={`Nhóm đang trống`} arrow placement="bottom">
            <span className={classes.WarningIcon}><ReportProblemOutlined fontSize="small"/></span>
          </Tooltip>
        )}
      </div>
      <div className={gridClasses.Col}>{ name }</div>
      <div className={gridClasses.Col}>{ getcare_erp_group_type?.name }</div>
      <div className={gridClasses.Col}>{ description }</div>
    </Link>;
  }
}

GroupListItem.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  code: PropTypes.string,
  name: PropTypes.string,
  getcare_erp_group_type: PropTypes.object,
  description: PropTypes.string,
};

GroupListItem.defaultProps = {
};

export default withRouter(GroupListItem);
