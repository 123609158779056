import React, { PureComponent } from 'react';
import userApi from 'utils/api/admin/userApi';
import { suggestionPageSize2 } from 'utils/constanst/common';
import { 
  ROLE_LEADER, 
  ROLE_COORNIDATOR, 
  coordinaterRolesMap, 
} from 'utils/constanst/adminCoordinaterConstants';
import { STATUS_WORKING } from 'utils/constanst/adminUserConstants';

import EditOutlined from '@material-ui/icons/EditOutlined';
import IconButton from '@material-ui/core/IconButton';
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';

import gridClasses from '../CoordinaterListGrid.module.scss';
import classes from './CoordinaterListItem.module.scss';

class CoordinaterListItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: !props.id,
      params: this._getInitParams(props),
      userOptions: props.getcare_user ? [{...props.getcare_user}] : [],
      userNameInput: '',
      userCodeInput: '',
      isDialogOpen: false,
    }
  }
  componentDidMount() {
    const { isEditing } = this.state;
    if (isEditing) {
      this._loadUsers({
        code: '',
        name: '',
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { isEditing, userCodeInput, userNameInput, isDialogOpen } = this.state;
    if (isEditing && isEditing !== prevState.isEditing) {
      this._loadUsers({
        code: '',
        name: '',
      });
    }
    if (userCodeInput !== prevState.userCodeInput) {
      this._loadUsers({
        code: userCodeInput,
      });
    }
    if (userNameInput !== prevState.userNameInput) {
      this._loadUsers({
        name: userNameInput,
      });
    }

    const { deleteLoading } = this.props;
    if (isDialogOpen && !deleteLoading && deleteLoading !== prevProps.deleteLoading) {
      this.handleCloseDialog();
    }
  }

  _loadUsers = async (params) => {
    const { data: response } = await userApi.getList({
      params: {
        ...params,
        page_size: suggestionPageSize2,
        status: [STATUS_WORKING],
        is_coordinator: 1,
      }
    });
    if (!response?.result) return;
    this.setState({
      userOptions: response.data ? [...response.data] : [],
    });
  }
  _initRole = (params) => {
    const roleId = params.is_leader ? ROLE_LEADER : ROLE_COORNIDATOR;
    return {
      id: roleId,
      name: coordinaterRolesMap[roleId],
    }
  }
  _getInitParams = (params) => {
    return {
      idStr: params.idStr,
      id: params.id,
      getcare_user: { ...params.getcare_user },
      getcare_role: this._initRole(params),
    }
  }
  _resetState = () => {
    this.setState({
      isEditing: false,
      params: this._getInitParams(this.props),
    });
  }
  _isRoleValid = () => {
    return !!this.state.params?.getcare_role?.id;
  }
  _isUserValid = () => {
    return this.state.params?.getcare_user?.id || this.state.params?.getcare_user?.code;
  }
  _isRowValid = () => {
    return this._isRoleValid() && this._isUserValid();
  };
  stopPropagation = (e) => {
    e.stopPropagation();
  }
  handleSaveField = (e, value) => {
    let newValue = value;
    if (e.target.name === 'getcare_role') {
      newValue = { ...value };
    }
    if (e.target.name === 'getcare_user') {
      newValue = { ...value };
    }
    this.setState({
      params: {
        ...this.state.params,
        [e.target.name]: newValue,
      },
    });
  }
  handleEdit = (e) => {
    e.stopPropagation();
    this.setState({
      isEditing: true,
    });
  }
  handleCancel = (e) => {
    e.stopPropagation();
    this._resetState();
    if (!this.props.id) {
      this.props.removeCoordinaters([{
        id: this.props.id,
        idStr: this.props.idStr,
      }]);
    }
  }
  handleSave = (e) => {
    if (e) e.stopPropagation();
    this.props.saveCoordinater({
      ...this.state.params,
      idStr: this.props.idStr,
      id: this.props.id,
    });
    this.setState({
      isEditing: false,
    });
  }
  handleRowBlur = () => {
  }
  handleDelete = () => {
    this.props.removeCoordinater({
      idStr: this.props.idStr,
      id: this.props.id,
    });
  };

  handleOpenDialog = (e) => {
    if (e) e.stopPropagation();
    this.setState({
      isDialogOpen: true,
    });
  }
  handleCloseDialog = () => {
    this.setState({
      isDialogOpen: false,
    });
  }

  render() {
    const { coordinaterRoles, isCreateAllowed, isEditAllowed } = this.props;
    const { isEditing, params, userOptions } = this.state;
    const hasActions = isCreateAllowed || isEditAllowed;

    return (<>
      <ClickAwayListener onClickAway={this.handleRowBlur}>
        <div
          className={`${gridClasses.Row} ${hasActions ? gridClasses.RowHasActions : ''} ${classes.Item} ${isEditAllowed ? classes.Editable : ''}`}
          onClick={isEditAllowed ? this.handleEdit : undefined}
        >
          <div className={`${gridClasses.Col}`}>
            {isEditing ? (
              <Autocomplete
                openOnFocus
                selectOnFocus
                disableClearable
                fullWidth
                size="small"
                handleHomeEndKeys={false}
                value={params.getcare_user || null}
                onChange={(e, newValue) => {
                  this.handleSaveField({target: { name: 'getcare_user' } }, newValue);
                }}
                options={userOptions || []}
                filterOptions={(x) => x}
                renderOption={(option) => `${option.code} - ${option.name}`}
                getOptionLabel={(option) => option?.code ? `${option.code}` : ''}
                getOptionSelected={(option, value) => 
                  value && value.code && option && option.code ? option.code === value.code : null
                }
                onInputChange={(e, newInputValue) => {
                  this.setState({
                    userCodeInput: newInputValue,
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} error={!this._isUserValid()} placeholder="- Chọn -" />
                )}
              />
            ) : (
              params.getcare_user?.code
            )}
          </div>
          <div className={`${gridClasses.Col}`}>
            {isEditing ? (
              <Autocomplete
                openOnFocus
                selectOnFocus
                disableClearable
                size="small"
                fullWidth
                handleHomeEndKeys={false}
                value={params.getcare_user || null}
                onChange={(e, newValue) => {
                  this.handleSaveField({target: { name: 'getcare_user' } }, newValue);
                }}
                options={userOptions || []}
                filterOptions={(x) => x}
                renderOption={(option) => `${option.code} - ${option.name}`}
                getOptionLabel={(option) => option?.code ? `${option.name}` : ''}
                getOptionSelected={(option, value) =>
                  value && value.code && option && option.code ? option.code === value.code : null
                }
                onInputChange={(e, newInputValue) => {
                  this.setState({
                    userNameInput: newInputValue,
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} error={!this._isUserValid()} placeholder="- Chọn -" />
                )}
              />
            ) : (
              params.getcare_user?.name
            )}
          </div>
          <div className={`${gridClasses.Col}`}>
            {isEditing ? (
              <Autocomplete
                openOnFocus
                selectOnFocus
                disableClearable
                fullWidth
                size="small"
                handleHomeEndKeys={false}
                value={params.getcare_role || null}
                onChange={(e, newValue) => {
                  this.handleSaveField({target: { name: 'getcare_role' } }, newValue);
                }}
                options={coordinaterRoles || []}
                filterOptions={(x) => x}
                renderOption={(option) => option.name}
                getOptionLabel={(option) => option?.id ? option.name : ''}
                getOptionSelected={(option, value) =>
                  value?.id && option?.id ? option.id === value.id : null 
                }
                renderInput={(params) => (
                  <TextField {...params} error={!this._isRoleValid()} placeholder="- Chọn -" />
                )}
              />
            ) : (
              params.getcare_role?.name
            )}
          </div>
          <div className={`${gridClasses.Col}`}>
            { params.getcare_user?.phone }
          </div>
          <div className={`${gridClasses.Col}`}>
            { params.getcare_user?.email }
          </div>
          <div className={`${gridClasses.Col}`}>
            { params.getcare_user?.getcare_department?.name }
          </div>
          { hasActions && 
            <div className={`${gridClasses.Col} ${classes.ActionsCol}`}>
              { isEditAllowed && <>
                { isEditing ? <>
                  <IconButton
                    onClick={this.handleCancel}
                    style={{ padding: '0' }}
                    size="small"
                  >
                    <CancelOutlined fontSize="small" color="secondary" />
                  </IconButton>
                  <IconButton
                    disabled={!this._isRowValid()}
                    onClick={this.handleSave}
                    style={{ padding: '0' }}
                    size="small"
                  >
                    <CheckCircleOutlineOutlined
                      fontSize="small"
                      color={this._isRowValid() ? `primary` : `inherit`}
                    />
                  </IconButton>
                </> : <>
                  <IconButton
                    onClick={this.handleOpenDialog}
                    style={{ padding: '0' }}
                    size="small"
                  >
                    <DeleteOutline fontSize="small" />
                  </IconButton>
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={this.handleEdit}
                  >
                    <EditOutlined fontSize="small"/>
                  </IconButton>
                </>
                }
              </>}
            </div>
          }
        </div>
      </ClickAwayListener>
      { this.state.isDialogOpen &&
        <ConfirmationDialog
          isOpen={this.state.isDialogOpen}
          title="Xác nhận xoá"
          message={(<p>{`Bạn có chắc xoá người điều phối `} <strong>{params.getcare_user?.name}</strong>?</p>)}
          onClose={this.handleCloseDialog}
          onSubmit={this.handleDelete}
        />
      }
    </>);
  }
}

CoordinaterListItem.propTypes = {
};

CoordinaterListItem.defaultProps = {
}

export default CoordinaterListItem;
