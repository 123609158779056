import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classes from './DefaultImage.module.scss';

class DefaultImage extends PureComponent {
  render() {
    const { className } = this.props;

    return (<div className={`${classes.DefaultImage} ${className}`}/>);
  }
}

DefaultImage.propTypes = {
  className: PropTypes.string,
};

DefaultImage.defaultProps = {
  className: '',
};

export default DefaultImage;
