import React from 'react';
import PropTypes from 'prop-types';
import { filterFields } from 'utils/constanst/adminVendorProductConstants';

import FilterField from 'components/FilterField/FilterField';
import GetcareCategoryFormAutocomplete from 'components/admin/GetcareCategory/FormAutocomplete/FormAutocomplete';
import DateRange from 'components/FilterField/DateRange/DateRange';

import classes from './VendorProductFilters.module.scss';

class VendorProductFilters extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fields: [...filterFields],
    };
  }

  _getFieldIndex = (fieldName) => {
    return this.state.fields.findIndex(item => item.name === fieldName);
  }

  handleFieldChange = ({ name, value }) => {
    const newFields = this.state.fields.map(field => field.name === name ? { ...field, value } : field);
    this.setState({
      fields: newFields,
    });
    this.props.onFilterChange(newFields, true);
  }

  render() {
    const { fields } = this.state;

    return (
      <div className={classes.Panel}>
        <h3 className={classes.PanelTitle}>Lọc theo:</h3>
        <div className={classes.PanelContent}>
          {fields.map((field) => {
            let customField;
            switch (field.name) {
              case 'getcare_category_ecom_id': {
                customField = <GetcareCategoryFormAutocomplete
                  {...field} 
                  multiple
                  disableCloseOnSelect
                  onChange={(e,value) => {
                    this.handleFieldChange({ name: 'getcare_category_ecom_id', value: value });
                  }}
                />
                break;
              }
            }
            return (
              ['updated_at'].includes(field.name) ? (
                <FilterField
                  key={field.name}
                  {...field}
                  defaultValue={['', '']}
                  customField={<DateRange
                    name={field.name}
                    value={field.value}
                    handleFieldChange={this.handleFieldChange}
                  />}
                  handleFieldChange={this.handleFieldChange}
                />
              ):
              <FilterField 
                key={field.name} 
                {...field} 
                handleFieldChange={this.handleFieldChange}
                customField={customField} 
              />
            )
          })}
        </div>
      </div>
    );
  }
}

VendorProductFilters.propTypes = {
  onFilterChange: PropTypes.func,
};

export default VendorProductFilters;
