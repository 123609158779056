import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { validDate } from 'utils/helper';
import addDays from 'date-fns/addDays';
import { dateFormat } from 'utils/constanst/dateConstants';
import isSameDay from 'date-fns/isSameDay';
import compareAsc from 'date-fns/compareAsc';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import classes from './ConfirmCreateDialog.module.scss';

class ConfirmCreateDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      startDate: props.startDate,
    };
  }
  _resetState = () => {
    this.setState({
      startDate: this.props.startDate,
    });
  };
  _isStartDateTommorow = () => {
    const { startDate } = this.state;
    if (!validDate(startDate)) return false;
    const tomorrow = addDays(new Date(), 1);
    return isSameDay(new Date(startDate), tomorrow);
  };
  _isStartDateValid = () => {
    return this.state.startDate && validDate(this.state.startDate);
  }
  isFormValid = () => {
    return this._isStartDateValid();
  };
  handleDateChange = (date) => {
    const value = validDate(date) ? date.toISOString() : '';
    this.setState({
      startDate: value,
    });
  };
  handleCheckboxChange = (e) => {
    const tomorrow = addDays(new Date(), 1);
    this.setState({
      startDate: e.target.checked ? tomorrow.toISOString() : '',
    });
  };
  handleSubmit = async () => {
    let endDate = '';
    if (
      !this.props.endDate &&
      compareAsc(
        new Date(this.state.startDate),
        new Date(this.props.endDate)
      ) === 1
    ) {
      endDate = this.state.startDate;
    }
    this.props.onSubmit({
      start_date: this.state.startDate,
      end_date: endDate,
    });
  };
  onClose = () => {
    this.props.onClose();
  };
  onEntered = () => {
    this._resetState();
  };

  render() {
    const { isOpen, title, isLoading } = this.props;

    return (
      <Dialog
        open={isOpen}
        onClose={this.onClose}
        onEntered={this.onEntered}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.Dialog}
      >
        <div
          className={`${classes.DialogContentWrap} ${
            isLoading && 'OverlayLoading'
          }`}
        >
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <p className={classes.Info}>
              Bạn đang kích hoạt chính sách giá bắt đầu ngày hôm nay. Nghĩa là
              chính sách giá này sẽ được áp dụng ngay khi bạn xác nhận.
            </p>
            <p className={classes.Info}>
              Bạn có chắc muốn tạo 1 chính sách áp dụng ngay bây giờ hoặc bạn có
              thể nhập ngày bắt đầu trong tương lại ở đây:
            </p>
            <div className={classes.FormControl}>
              <div className={classes.FieldControl}>
                <label>Chọn ngày bắt đầu</label>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    disablePast
                    autoOk
                    variant="inline"
                    format={dateFormat}
                    value={
                      validDate(this.state.startDate)
                        ? validDate(this.state.startDate)
                        : null
                    }
                    error={!this._isStartDateValid()}
                    onChange={this.handleDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this._isStartDateTommorow()}
                    onChange={this.handleCheckboxChange}
                    value={true}
                    name="tommorow"
                  />
                }
                label="Ngày mai"
              />
            </div>
          </DialogContent>
          <DialogActions className={classes.DialogActions}>
            <Button variant="outlined" onClick={this.props.onClose}>
              Huỷ bỏ
            </Button>
            <Button
              disabled={!this.isFormValid()}
              variant="contained"
              onClick={this.handleSubmit}
              color="primary"
              autoFocus
            >
              Xác nhận
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    );
  }
}

ConfirmCreateDialog.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func,
};

ConfirmCreateDialog.defaultProps = {
  isOpen: false,
  title: '',
  startDate: '',
  endDate: '',
  isLoading: false,
  endDateInterval: null,
};

export default ConfirmCreateDialog;
