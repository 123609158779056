export const GET_SHIPPING_COUPON_LIST = 'getcare/admin/shippingCoupon/GET_SHIPPING_COUPON_LIST';
export const GET_SHIPPING_COUPON = 'getcare/admin/shippingCoupon/GET_SHIPPING_COUPON';

export const UPDATE_SHIPPING_COUPON = 'getcare/admin/shippingCoupon/UPDATE_SHIPPING_COUPON';

// mutation
export const SAVE_LOADING = 'getcare/admin/shippingCoupon/SAVE_LOADING';

export const SAVE_SHIPPING_COUPON_LIST = 'getcare/admin/shippingCoupon/SAVE_SHIPPING_COUPON_LIST';
export const SAVE_SHIPPING_COUPON_LIST_TOTAL = 'getcare/admin/shippingCoupon/SAVE_SHIPPING_COUPON_LIST_TOTAL';

export const RESET_SHIPPING_COUPON = 'getcare/admin/shippingCoupon/RESET_SHIPPING_COUPON';

export const SAVE_ORI_SHIPPING_COUPON_DETAILS = 'getcare/admin/shippingCoupon/SAVE_ORI_SHIPPING_COUPON_DETAILS';
export const SAVE_SHIPPING_COUPON_DETAILS = 'getcare/admin/shippingCoupon/SAVE_SHIPPING_COUPON_DETAILS';

export const SAVE_ORI_SHIPPING_COUPON_RULES = 'getcare/admin/shippingCoupon/SAVE_ORI_SHIPPING_COUPON_RULES';
export const SAVE_SHIPPING_COUPON_RULES = 'getcare/admin/shippingCoupon/SAVE_SHIPPING_COUPON_RULES';
export const SAVE_SHIPPING_COUPON_RULE_ITEM = 'getcare/admin/shippingCoupon/SAVE_SHIPPING_COUPON_RULE_ITEM';
export const REMOVE_SHIPPING_COUPON_RULE_ITEMS = 'getcare/admin/shippingCoupon/REMOVE_SHIPPING_COUPON_RULE_ITEMS';

export const SAVE_ORI_SHIPPING_COUPON_CUSTOMERS = 'getcare/admin/shippingCoupon/SAVE_ORI_SHIPPING_COUPON_CUSTOMERS';
export const SAVE_SHIPPING_COUPON_CUSTOMERS = 'getcare/admin/shippingCoupon/SAVE_SHIPPING_COUPON_CUSTOMERS';
export const SAVE_SHIPPING_COUPON_CUSTOMER_ITEM = 'getcare/admin/shippingCoupon/SAVE_SHIPPING_COUPON_CUSTOMER_ITEM';
export const REMOVE_SHIPPING_COUPON_CUSTOMER_ITEMS = 'getcare/admin/shippingCoupon/REMOVE_SHIPPING_COUPON_CUSTOMER_ITEMS';

export const SAVE_ORI_SHIPPING_COUPON_SALES_CHANNELS = 'getcare/admin/shippingCoupon/SAVE_ORI_SHIPPING_COUPON_SALES_CHANNELS';
export const SAVE_SHIPPING_COUPON_SALES_CHANNELS = 'getcare/admin/shippingCoupon/SAVE_SHIPPING_COUPON_SALES_CHANNELS';
export const SAVE_SHIPPING_COUPON_SALES_CHANNEL_ITEM = 'getcare/admin/shippingCoupon/SAVE_SHIPPING_COUPON_SALES_CHANNEL_ITEM';
export const REMOVE_SHIPPING_COUPON_SALES_CHANNEL_ITEMS = 'getcare/admin/shippingCoupon/REMOVE_SHIPPING_COUPON_SALES_CHANNEL_ITEMS';

export const SAVE_SHIPPING_COUPON_HISTORY = 'getcare/admin/shippingCoupon/SAVE_SHIPPING_COUPON_HISTORY';
