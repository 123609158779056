import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { debounce } from 'lodash';
import { getVendorDetails } from 'redux/actions/admin/vendorActions';
import {
  getVendorProductList,
  saveVendorProductItem,
  removeVendorProductItems,
  unMapVendorProductItems,
  approvalVendorProductItems,
  updateVendorProductList,
  exportVendorProductList,
  exportVendorProductListTemplate,
  saveVendorProductAllowedActions,
  saveVendorProductVisibility,
} from 'redux/actions/admin/vendorProductActions';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectVendorProductList,
  makeSelectVendorProductListTotal,
  makeSelectVendorProductLoading,
  makeSelectVendorProductUnmap,
  makeSelectVendorProductApproval,
  makeSelectVendorDetails,
  makeSelectOriVendorProductList,
  makeSelectVendorProductActionLoading,
  makeSelectVendorProductAllowedActions,
  makeSelectVendorProductVisibility,
} from 'redux/selectors';
import {
  listParamsMap,
  listDisplayFields,
  productOptions,
  listDisplayFieldsShow,
} from 'utils/constanst/adminVendorProductConstants';
import {
  getSortsString,
  getDisplayFields,
  genID,
  validNumber,
} from 'utils/helper';
import vendorProductApi from 'utils/api/admin/vendorProductApi';

import { withRouter } from 'react-router-dom';
import { isEqual, sortBy } from 'lodash';
import { isActionAllowed } from 'utils/helper/authorization';
import authApi from 'utils/api/authApi';
import { Popper, RadioGroup, Paper } from '@material-ui/core';
import viewMore from 'assets/images/more.svg';
import ArrowBack from '@material-ui/icons/ArrowBack';
import CheckOutlined from '@material-ui/icons/CheckOutlined';
import VendorProductList from 'components/admin/VendorProductList/VendorProductList';
import VendorProductFilters from 'components/admin/VendorProductList/VendorProductFilters/VendorProductFilters';
import ListPagination from 'components/ListPagination/ListPagination';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FileImportDialog from 'components/admin/VendorProductList/FileImportDialog/FileImportDialog';
import TagGetcareCategory from 'components/admin/ProductList/TagGetcareCategory/TagGetcareCategory';
import ParentProduct from 'components/admin/ProductList/ParentProduct/ParentProduct';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import classes from './VendorProducts.module.scss';
import { toast } from 'react-toastify';

class VendorProducts extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      listParams: { ...listParamsMap },
      selectedItems: [],
      anchorEl2: null,
      selectedSetting: [...listDisplayFields],
      openEl2: false,
      isConfirmDialogOpen: false,
      isImportDialogOpen: false,
      isTagGetcareCategoryDialogOpen: false,
      isParentProductDialogOpen: false,
      pausedAction: '',
    };
  }
  async componentDidMount() {
    const { id } = this.props.match.params;
    await this.getAllowedActions();
    this._loadData(id);
    if (sessionStorage.getItem('Setting')) {
      this.setState({
        selectedSetting: JSON.parse(sessionStorage.getItem('Setting')),
      });
    } else {
      sessionStorage.setItem('Setting', JSON.stringify([...listDisplayFields]));
    }
  }
  async componentDidUpdate(prevProps,prevState) {
    const { id } = this.props.match.params;
    if (id && id !== prevProps.match.params.id) {
      await this.getAllowedActions();

      this._loadData(id);
    }
    
    const { vendorProductList, approval,unmap } = this.props;
    const { selectedItems } = this.state;
    if (id && id !== prevProps.match.params.id) {
      await this.getAllowedActions();
      this._loadData(id);
    }
    if (approval && approval !== prevProps.approval) {
      await this.props.getVendorProductList({
        params: this.state.listParams,
        vendorId: this.props.vendorDetails?.id,
      });
    }
    if (
      !isEqual(
        sortBy(vendorProductList),
        sortBy(prevProps.vendorProductList)
      ) &&
      selectedItems.length > 0
    ) {
      const remainItems = selectedItems.filter((p) =>
        vendorProductList.some((item) => {
          const comparedField = item.idStr ? `idStr` : `id`;
          return item[comparedField] === p[comparedField];
        })
      );
      this.setState({
        selectedItems: remainItems,
      });
    }
  }

  getAllowedActions = async () => {
    const { data: response } = await authApi.getAllowedActions({
      component: 'vendor_products',
    });
    if (!response.result) {
      toast.error(response.message);
      return;
    }
    this.props.saveVendorProductAllowedActions(response.allowed_actions || []);
    this.props.saveVendorProductVisibility(response.visibility || null);
  };
  getVisibleColumns = () => {
    const { vendorProductVisibility } = this.props;
    const readableFields = vendorProductVisibility?.read;
    const editableFields = vendorProductVisibility?.edit;
    const { id } = this.props.match.params;
    if (!readableFields || readableFields.length === 0) return [];
    if (id) {

      return listDisplayFields.filter(field =>
        ([null, undefined].includes(field.authorize_name) && field.name  !== 'getcare_vendor_name' || readableFields.includes(field.authorize_name) && field.name  !== 'getcare_vendor_name')
      ).map(field => ({
        ...field,
        editable: editableFields && editableFields.includes(field.authorize_name),
      }));
    }else{
      return listDisplayFields.filter(field =>
        ([null, undefined].includes(field.authorize_name) || readableFields.includes(field.authorize_name))
      ).map(field => ({
        ...field,
        editable: editableFields && editableFields.includes(field.authorize_name),
      }));
    }
  }
  _isRowValid = (row) => {
    return  !!row.product_vendor_name;
  };
  _loadData = (id) => {
    if (id) {
      this.props.getVendorDetails(id);
      this.props.getVendorProductList({
        params: this.state.listParams,
        vendorId: id,
      });
    }else {
      const newParams = id
      ? {
          ...this.state.listParams,
        }
      : {
          ...this.state.listParams,
          approval: -1,
        };
      this.setState({
        listParams: newParams,
      });
      this.props.getVendorProductList({
        params: this.state.listParams
      });
    }
  };
  _getVendorProductContrains = (params) => {
    return {
      id: params.id,
      getcare_product_id: params.getcare_product?.id,
      getcare_vendor_id: params.getcare_vendor_id,
      product_vendor_code: params.product_vendor_code ?params.product_vendor_code : '',
      product_vendor_name: params.product_vendor_name,
      getcare_uom_base_id: params.getcare_uom_base?.id,
      price_buy_total: params.price_buy_total,
      price_sales_total: params.price_sales_total,
      price_sales_retail_total: params.price_sales_retail_total,
      commission: params.commission,
      subsidiary_name: params.subsidiary_name,
      registration_number: params.registration_number,
      description: params.description,
      vat: params.vat,
      minimum_quantity: params.minimum_quantity,
      estimated_quantity: params.estimated_quantity,
      remaining_quantity: params.remaining_quantity,
      type_label: params.type_label,
      ka_days: params.ka_days,
      getcare_category_ecoms: params.getcare_category_ecoms,
      weight: params.weight,
      images: params.images,
    };
  };
  _getNewAndUpdatedVendorProducts = () => {
    const { vendorProductList, oriVendorProductList } = this.props;
    return vendorProductList.filter((tempItem) => {
      const oriItem = oriVendorProductList.find(
        (item) => item.id === tempItem.id
      );
      return (
        this._isRowValid(tempItem) &&
        (!oriItem ||
          !isEqual(
            this._getVendorProductContrains(tempItem),
            this._getVendorProductContrains(oriItem)
          ))
      );
    });
  };
  _isVendorProductsChanged = () => {
    const { vendorProductList, oriVendorProductList } = this.props;
    if (!vendorProductList || !oriVendorProductList) return false;
    return (
      this._getNewAndUpdatedVendorProducts().length > 0 ||
      this._getProductsDeletedData().length > 0
    );
  };
  _prepareData = () => {
    const { vendorDetails } = this.props;
    const list = this._getNewAndUpdatedVendorProducts().map((product) => ({
      id: product.id,
      getcare_vendor_id: product.getcare_vendor_id ?  product.getcare_vendor_id : vendorDetails?.id,
      getcare_product_id: product.getcare_product?.id ? product.getcare_product_id === -1  ?  product.getcare_product?.id  : product.getcare_product?.id  : product.getcare_product_id,
      getcare_product_getcare_id: product.getcare_product?.getcare_id || null,
      product_vendor_code: product.product_vendor_code || '',
      product_vendor_name: product.product_vendor_name || null,
      getcare_uom_base_id: product.getcare_uom_base?.id || null,
      price_buy_total: validNumber(product.price_buy_total),
      price_sales_total: validNumber(product.price_sales_total),
      price_sales_retail_total: validNumber(product.price_sales_retail_total),
      commission: validNumber(product.commission),
      subsidiary_name:product.subsidiary_name|| '',
      registration_number: product.registration_number,
      vat: validNumber(product.vat),
      minimum_quantity: validNumber(product.minimum_quantity),
      estimated_quantity: validNumber(product.remaining_quantity),
      remaining_quantity: validNumber(product.remaining_quantity),
      type_label: product.type_label,
      ka_days: validNumber(product.ka_days),
      deleted: 0,
      weight: product.weight,
      images: product.images,
      getcare_category_ecoms: product.getcare_category_ecoms,
      description: product.description,
    }));
    const deletedList = this._getProductsDeletedData();
    return [...list, ...deletedList];
  };
  _getProductsDeletedData = () => {
    const {
      vendorProductList,
      oriVendorProductList,
      vendorDetails,
    } = this.props;
    const remainItems = oriVendorProductList.filter(
      (p) => !vendorProductList.some((item) => item.id === p.id)
    );
    return remainItems.map((product) => ({
      id: product.id,
      getcare_vendor_id: vendorDetails?.id,
      deleted: 1,
    }));
  };
  _getHighlightedList = () => {
    const { vendorProductList, oriVendorProductList } = this.props;
    return vendorProductList.map((p) => {
      const oriItem = oriVendorProductList.find((item) => item.id === p.id);
      return {
        ...p,
        oriItem: oriItem ? { ...oriItem } : null,
      };
    });
  };
  _hasProductUomBaseDuplicated = () => {
    const productUomBaseMap = this.props.vendorProductList
      .filter((item) => item.getcare_product?.id && item.getcare_uom_base?.id)
      .reduce((memo, item) => {
        const key = `${item.getcare_product.id}-${item.getcare_uom_base.id}-${item.getcare_vendor_name}`;
        memo[key] = [null, undefined, ''].includes(memo[key])
          ? 0
          : memo[key] + 1;
        return memo;
      }, {});
    return Object.keys(productUomBaseMap).some(
      (key) => productUomBaseMap[key] > 0
    );
  };
  handleSortChange = ({ sortBy, sortDir }) => {
    const newSortString = getSortsString({
      [sortBy]: sortDir,
    });
    this.handleFilterChange([
      {
        name: 'order',
        value: newSortString,
      },
    ]);
  };
  handleFilterChange = (filters, forceResetPage = false) => {
    const paramsFromFilters = filters.reduce((memo, item) => {
      if (['updated_at'].includes(item.name)) {
         memo[`${item.name}_from`] = item.value[0];
         memo[`${item.name}_to`] = item.value[1];
       } else {
         memo[item.name] = item.value;
       }
       return memo;
     }, {});
    const newParams = forceResetPage
      ? {
          ...this.state.listParams,
          ...paramsFromFilters,
          page: 1,
        }
      : {
          ...this.state.listParams,
          ...paramsFromFilters,
        };
    this.setState({
      listParams: newParams,
    });
    if (this._isVendorProductsChanged()) {
      this.setState({
        pausedAction: 'filter',
      });
      this.handleConfirmDialogOpen();
      return;
    }
    this.props.getVendorProductList({
      params: newParams,
      vendorId: this.props.vendorDetails?.id,
    });
  };
  handleAllSelectedToggle = (e) => {
    this.setState({
      selectedItems: e.target.checked
        ? [...this.state.selectedItems, ...this.props.vendorProductList]
        : [],
    });
  };

  handleClick2 = (event) => {
    const { currentTarget } = event;
    this.setState((state) => ({
      anchorEl2: currentTarget,
      openEl2: !state.openEl2,
      openEl1: false,
    }));
  };

  handleClose = () => {
    this.setState({
      openEl2: false,
    });
  };

  handleItemSelectedToggle = ({ item, isSelected }) => {
    const remainItems = this.state.selectedItems.filter((p) => {
      const comparedField = p.idStr ? 'idStr' : 'id';
      return p[comparedField] !== item[comparedField];
    });
    this.setState({
      selectedItems: isSelected ? [...remainItems, { ...item }] : remainItems,
    });
  };
  handleSelectChange = (e) => {
    this.handleFilterChange([
      {
        name: e.target.name,
        value: e.target.value,
      },
    ]);
  };
  handleCreateNewRow = () => {
    this.props.saveVendorProductItem({
      idStr: genID(),
      id: 0,
      getcare_product: null,
      product_vendor_code: '',
      product_vendor_name: '',
      getcare_uom_base: null,
      price_buy_total: '',
    });
  };
  handleSaveVendorProduct = (params) => {
    this.props.saveVendorProductItem({
      ...params,
    });
  };
  handleRemoveVendorProducts = (items) => {
    this.props.removeVendorProductItems(items);
  };
  handleRemoveSelectedItems = () => {
    this.handleRemoveVendorProducts([...this.state.selectedItems]);
  };

  
  handleApprovalSelectedItems = () => {
    this.handleApprovalVendorProducts([...this.state.selectedItems]);
  }

  handleUnmapSelectedItems = async () => {
    const params =  this.state.selectedItems.map(v => ({...v, getcare_product_id: -1}));
    const { data: response } = await vendorProductApi.unMapVendorProductList({params});
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    await this.props.getVendorProductList({
      params: this.state.listParams,
      vendorId: this.props.vendorDetails?.id,
    });
    toast.success('Unmap thành công');
   }

  handleApprovalVendorProducts = async (items) => {
   await this.props.approvalVendorProductItems(items);
  }


  handleCancelVendorProducts = () => {
    this._loadData(this.props.match.params.id);
  };
  handleSubmitVendorProducts = () => {
    const { id } = this.props.match.params;
    const hasDupplicated = this._hasProductUomBaseDuplicated();
    if (hasDupplicated) {
      toast.error('Có một cặp sản phẩm - đơn vị tính trùng nhau.');
      return;
    }
    this.props.updateVendorProductList({
      params: [...this._prepareData()],
      currentListParams: {
        params: this.state.listParams,
        vendorId: this.props.vendorDetails?.id,
      },
    });
    if (id === undefined) {
      this._loadData();
    }
  };
  handleDownloadTemplate = () => {
    this.props.exportVendorProductListTemplate();
  };
  handleDownloadProducts = () => {
    this.props.exportVendorProductList({
      vendorId: this.props.vendorDetails?.id,
    });
  };
  handleConfirmDialogOpen = () => {
    this.setState({
      isConfirmDialogOpen: true,
    });
  };
  handleConfirmDialogClose = () => {
    if (this.state.pausedAction === 'filter') {
      this.props.getVendorProductList({
        params: this.state.listParams,
        vendorId: this.props.vendorDetails?.id,
      });
    }
    if (this.state.pausedAction === 'import') {
      this.handleImportDialogOpen();
    }
    this.setState({
      isConfirmDialogOpen: false,
      pausedAction: '',
    });
  };
  backToVendorDetails = () => {
    this.props.history.push(`/vendor/${this.props.vendorDetails?.id}`);
  };
  handleConfirmDialogCancel = () => {
    this.handleConfirmDialogClose();
  };
  handleConfirmDialogSubmit = () => {
    this.handleSubmitVendorProducts();
    this.handleConfirmDialogClose();
  };
  handleConfirmImportDialogOpen = () => {
    if (this._isVendorProductsChanged()) {
      this.setState({
        pausedAction: 'import',
      });
      this.handleConfirmDialogOpen();
      return;
    }
    this.handleImportDialogOpen();
  };
  handleImportDialogOpen = () => {
    this.setState({
      isImportDialogOpen: true,
    });
  };
  handleImportDialogClose = () => {
    this.setState({
      isImportDialogOpen: false,
    });
  };
  handleCompleteImport = () => {
    this.props.getVendorProductList({
      params: this.state.listParams,
      vendorId: this.props.vendorDetails?.id,
    });
  };

  handleProductCategorySelectDialogOpen = () => {
    this.setState({
      isTagGetcareCategoryDialogOpen: true,
    });
  }

  handleParentProductSelectDialogOpen = () => {
    this.setState({
      isParentProductDialogOpen: true,
    });
  }

  handleParentProductSelectDialogClose = () => {
    this.setState({
      isParentProductDialogOpen: false,
    });
  }

  handleTagGetcareCategoryDialogClose = () => {
    this.setState({
      isTagGetcareCategoryDialogOpen: false,
    });
  }
  handleSubmitSuccessTagGetcareCategory = () => {
    const { id } = this.props.match.params;
    this.props.getVendorProductList({
      params: this.state.listParams,
      vendorId: id,
    });
  }

  handleSubmitSuccessParentProduct = () => {
    const { id } = this.props.match.params;
    this.props.getVendorProductList({
      params: this.state.listParams,
      vendorId: id,
    });
  }

  render() {
    const {
      vendorProductListTotal,
      loading,
      actionLoading,
      vendorDetails,
      vendorProductAllowedActions,
    } = this.props;
    const { id } = this.props.match.params;
    const { listParams, selectedItems, openEl2, anchorEl2,isTagGetcareCategoryDialogOpen, selectedSetting,isParentProductDialogOpen} = this.state;
    const isListLoading = loading || actionLoading;
    const hasSelected = selectedItems.length > 0;
    const isChanged = this._isVendorProductsChanged();
    const isMappingAllowed = isActionAllowed('mapping', vendorProductAllowedActions);
    const isEditAllowed = isActionAllowed('edit', vendorProductAllowedActions);
    const isApprovalAllowed = isActionAllowed('approve_product', vendorProductAllowedActions);
    const isImportAllowed = isActionAllowed('import', vendorProductAllowedActions);
    const isExportAllowed = isActionAllowed('export', vendorProductAllowedActions);
    const visibleColumns = this.getVisibleColumns();
    const dataSetting = JSON.parse(sessionStorage.getItem('Setting'));

    return (
      <React.Fragment>
        <div className={classes.PageWrap}>
          <div className={classes.PageHeader}>
            <h1 className={classes.PageTitle}>
              { id ? vendorDetails?.name : 'Danh sách sản phẩm chờ duyệt'}
            </h1>
            <div className={classes.PageHeaderToolbar}>
              <Button
                size="small"
                variant="outlined"
                startIcon={<ArrowBack />}
                onClick={this.backToVendorDetails}
              >
                Trở về
              </Button>
              { (isEditAllowed || isMappingAllowed) && (<>
                <Button
                  size="small"
                  variant="outlined"
                  color="secondary"
                  disabled={!isChanged}
                  onClick={this.handleCancelVendorProducts}
                >
                  Huỷ thay đổi
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  disabled={!isChanged}
                  startIcon={<CheckOutlined />}
                  onClick={this.handleSubmitVendorProducts}
                >
                  Xác nhận thay đổi
                </Button>
              </>)}
            </div>
          </div>
          <div className={classes.PageHeader}>
            <div>
              <label className={classes.SelectLabel}>
                Hiển thị danh sách theo
              </label>
              <Select
                className={classes.SelectWrap}
                value={listParams.getcare_product_is_null}
                name="getcare_product_is_null"
                size="small"
                autoWidth
                onChange={this.handleSelectChange}
              >
                {
                  id ? (
                    productOptions.map((item) => (
                      <MenuItem key={`status-${item.id}`} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))
                  ):
                  ( productOptions.map((item) => (
                    <MenuItem key={`status-${item.id}`} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))
                 )
                }
             
              </Select>
              { isImportAllowed && id &&(
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={this.handleConfirmImportDialogOpen}
                >
                  Import File
                </Button>
              )}
              { isExportAllowed && id &&(
                <Button
                  disabled={!this.props.vendorProductList.length}
                  variant="contained"
                  size="small"
                  onClick={this.handleDownloadProducts}
                >
                  Export File
                </Button>
              )}
              { isImportAllowed && id &&(
                <Button
                  variant="contained"
                  size="small"
                  onClick={this.handleDownloadTemplate}
                >
                  Download template
                </Button>
              )}
            </div>
            <div>
            { isApprovalAllowed && (
              <Button
                className={`${classes.MultiActionBtn}`}
                disabled={!hasSelected}
                variant="contained"
                color="primary"
                size="small"
                onClick={this.handleParentProductSelectDialogOpen}
              >
                Parent Product
              </Button>
            )}
            { isApprovalAllowed && (
              <Button
                className={`${classes.MultiActionBtn}`}
                disabled={!hasSelected}
                variant="contained"
                color="primary"
                size="small"
                onClick={this.handleProductCategorySelectDialogOpen}
              >
                Tag danh mục
              </Button>
            )}
            { isApprovalAllowed && (
            <Button
                className={`${classes.MultiActionBtn}`}
                disabled={!hasSelected}
                variant="contained"
                color="primary"
                size="small"
                onClick={this.handleUnmapSelectedItems}
              >
                Unmap
              </Button>
              )}
            { isApprovalAllowed && (
            <Button
                className={`${classes.MultiActionBtn}`}
                disabled={!hasSelected}
                variant="contained"
                color="primary"
                size="small"
                onClick={this.handleApprovalSelectedItems}
              >
                Duyệt SP
              </Button>
              )}

              { isEditAllowed && (
                <Button
                  className={`${classes.MultiActionBtn}`}
                  disabled={!hasSelected}
                  color="secondary"
                  variant="contained"
                  size="small"
                  onClick={this.handleRemoveSelectedItems}
                >
                  Xoá
                </Button>
              )}
               <Button
                className={`${classes.MultiActionBtn}`}
                size="medium"
                onClick={this.handleClick2}
                variant="contained"
                size="medium"
              >
                <img
                  src={viewMore}
                  alt="View more"
                  className={`${classes.viewMore}`}
                />
                Hiển thị
              </Button>
              <Popper
                open={openEl2}
                anchorEl={anchorEl2}
                placement="bottom"
                className={`${classes.wrapperContent}`}
              >
                <ClickAwayListener onClickAway={this.handleClose}>
                  <Paper>
                    <ul>
                      <RadioGroup
                        aria-label="gender"
                        className={`${classes.Radio}`}
                        name="radio-buttons-group"
                      >
                        {dataSetting
                          ? dataSetting.map((item, i) => (
                              <FormControlLabel
                                className={`${classes.ControlLabel}`}
                                control={
                                  <Checkbox
                                    className={`${classes.checkBox}`}
                                    disabled={
                                      item.name === 'product_vendor_code' ||
                                      item.name === 'getcare_product_getcare_id' ||
                                      item.name === 'getcare_product_name' ||
                                      item.name === 'product_vendor_name' ||
                                      item.name === 'getcare_vendor_name' ||
                                      item.name === 'getcare_uom_base_name' ||
                                      item.name === 'price_buy_total' ||
                                      item.name === 'price_sales_total' ||
                                      item.name === 'price_sales_retail_total'
                                        ? true
                                        : false
                                    }
                                    onChange={(e) => {
                                      if (e.target.checked === false) {
                                        const index = dataSetting.findIndex(
                                          (x) => x.name === item.name
                                        );
                                        dataSetting[index].checked = false;
                                        this.setState({
                                          selectedSetting: dataSetting,
                                        });
                                        sessionStorage.setItem(
                                          'Setting',
                                          JSON.stringify(dataSetting)
                                        );
                                      } else {
                                        const index = dataSetting.findIndex(
                                          (x) => x.name === item.name
                                        );
                                        dataSetting[index].checked = true;
                                        this.setState({
                                          selectedSetting: dataSetting,
                                        });
                                        sessionStorage.setItem(
                                          'Setting',
                                          JSON.stringify(dataSetting)
                                        );
                                      }
                                    }}
                                    checked={
                                      selectedSetting.filter(
                                        (e) =>
                                          e.name === item.name &&
                                          e.checked === true
                                      ).length > 0
                                        ? true
                                        : false
                                    }
                                  />
                                }
                                key={i}
                                label={item.label}
                              />
                            ))
                          : null}
                      </RadioGroup>
                    </ul>
                  </Paper>
                </ClickAwayListener>
              </Popper>
            </div>
          </div>
          <div className={classes.PageMain}>
            <VendorProductFilters onFilterChange={debounce(this.handleFilterChange, 500)}/>
            <VendorProductList
              displayFields={getDisplayFields(
                listParams,
                selectedSetting.filter((e) => e.checked === true)
              )}
              vendorProductList={this._getHighlightedList()}
              selectedItems={selectedItems}
              isLoading={isListLoading}
              isEditAllowed={isEditAllowed}
              isMappingAllowed={isMappingAllowed}
              onSortChange={this.handleSortChange}
              onItemSelectedToggle={this.handleItemSelectedToggle}
              onAllSelectedToggle={this.handleAllSelectedToggle}
              onSaveVendorProduct={this.handleSaveVendorProduct}
              onRemoveVendorProducts={this.handleRemoveVendorProducts}
              onAddNewRow={this.handleCreateNewRow}
            />
          </div>
          <div className={classes.PageFooter}>
            <ListPagination
              page={listParams.page}
              pageSize={listParams.page_size}
              total={vendorProductListTotal}
              listName="sản phẩm"
              onFilterChange={this.handleFilterChange}
            />
          </div>
        </div>
        {this.state.isConfirmDialogOpen && (
          <ConfirmationDialog
            isOpen={this.state.isConfirmDialogOpen}
            title="Lưu thay đổi"
            message="Bạn vừa có thay đổi, bạn có xác nhận lưu thay đổi này không?"
            onClose={this.handleConfirmDialogCancel}
            onSubmit={this.handleConfirmDialogSubmit}
          />
        )}
        {this.state.isImportDialogOpen && (
          <FileImportDialog
            vendorId={vendorDetails?.id}
            title="Import danh sách sản phẩm"
            isOpen={this.state.isImportDialogOpen}
            onClose={this.handleImportDialogClose}
            onCompleteImport={this.handleCompleteImport}
            onViewImportLog={this.handleViewImportLog}
          />
        )}
        {isTagGetcareCategoryDialogOpen && (
          <TagGetcareCategory
            isOpen={isTagGetcareCategoryDialogOpen}
            onClose={this.handleTagGetcareCategoryDialogClose}
            productIds={selectedItems.map(item => item.id)}
            onSubmitSuccess={this.handleSubmitSuccessTagGetcareCategory}
            isTagForVendor
          />
        )}
         {isParentProductDialogOpen && (
          <ParentProduct
            isOpen={isParentProductDialogOpen}
            filterUrl={listParams}
            vendorId={vendorDetails?.id}
            onClose={this.handleParentProductSelectDialogClose}
            productIds={selectedItems.map(item => item.id)}
            onSubmitSuccess={this.handleSubmitSuccessParentProduct}
            isTagForVendor
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  vendorProductListTotal: makeSelectVendorProductListTotal(),
  vendorProductList: makeSelectVendorProductList(),
  loading: makeSelectVendorProductLoading(),
  unmap: makeSelectVendorProductUnmap(),
  approval: makeSelectVendorProductApproval(),
  vendorDetails: makeSelectVendorDetails(),
  oriVendorProductList: makeSelectOriVendorProductList(),
  actionLoading: makeSelectVendorProductActionLoading(),
  vendorProductAllowedActions: makeSelectVendorProductAllowedActions(),
  vendorProductVisibility: makeSelectVendorProductVisibility(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getVendorProductList: (payload) => dispatch(getVendorProductList(payload)),
    getVendorDetails: (payload) => dispatch(getVendorDetails(payload)),
    saveVendorProductItem: (payload) => dispatch(saveVendorProductItem(payload)),
    removeVendorProductItems: (payload) => dispatch(removeVendorProductItems(payload)),
    approvalVendorProductItems: (payload) => dispatch(approvalVendorProductItems(payload)),
    unMapVendorProductItems:  async (payload) => await dispatch(unMapVendorProductItems(payload)),
    updateVendorProductList: (payload) => dispatch(updateVendorProductList(payload)),
    exportVendorProductListTemplate: (payload) => dispatch(exportVendorProductListTemplate(payload)),
    exportVendorProductList: (payload) => dispatch(exportVendorProductList(payload)),
    saveVendorProductAllowedActions: (payload) => dispatch(saveVendorProductAllowedActions(payload)),
    saveVendorProductVisibility: (payload) => dispatch(saveVendorProductVisibility(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter)(VendorProducts);
