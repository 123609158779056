import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import EditOutlined from '@material-ui/icons/EditOutlined';
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import IconButton from '@material-ui/core/IconButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteOutline from '@material-ui/icons/DeleteOutline';

import classes from './UserRole.module.scss';
import gridClasses from '../UserRolesGrid.module.scss';

class UserRole extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: props.isEditing,

      params: this._getInitParams(props),
    };
  }
  componentDidUpdate(prevProps, prevState) {
    const { isEditing, params } = this.state;
    if (isEditing !== prevState.isEditing && this._isRowValid(params)) {
      this.props.onSaveUserRole({
        ...params,
        idStr: this.props.idStr,
        id: params.id,
        isEditing,
      });
    }
  }

  _filteredOptions = () => {
    const { params } = this.state;
    return this.props.roleList.filter(p =>
      (p.id === params?.id) ||
      !this.props.currentList.some(
        (item) => item.id === p.id
      )
    );
  };
  _getInitParams = (params) => {
    return {
      idStr: params?.idStr,
      id: params?.id,
      name: params?.name,
      getcare_data_permissions: params?.getcare_data_permissions,
    }
  }
  _resetState = () => {
    this.setState({
      isEditing: false,
      params: this._getInitParams(this.props),
    });
  }
  _hasRole = (params) => {
    return !!params?.id;
  }
  _isRowValid = (params) => {
    return this._hasRole(params);
  };

  stopPropagation = (e) => {
    e.stopPropagation();
  }

  handleSaveField = (e, value) => {
    this.setState({
      params: {
        ...this.state.params,
        [e.target.name]: value,
      },
    });
  }
  handleRowClick = () => {
    if (!this.state.isEditing) {
      this.handleEdit();
    }
  }
  handleEdit = (e) => {
    if (e) e.stopPropagation();
    this.setState({
      isEditing: true,
    });
  }
  handleCancel = (e) => {
    if (e) e.stopPropagation();
    if (this._isRowValid(this.props)) {
      // if it was saved to DB or it was used to save on client successful
      this._resetState();
      return;
    }
    this.props.onRemoveUserRole({
      id: this.props.id,
      idStr: this.props.idStr,
    });
  }
  handleSave = (e) => {
    if (e) e.stopPropagation();
    this.setState({
      isEditing: false,
    });
  }
  handleRemove = (e)=> {
    if (e) e.stopPropagation();
    this.props.onRemoveUserRole({
      idStr: this.props.idStr,
      id: this.props.id,
    });
  }
  handleSelectRoleChange = (e, newValue) => {
    this.setState({
      params: {
        ...this.state.params,
        ...newValue,
      },
    });
  };

  render() {
    const { index, name } = this.props;
    const { isEditing, params } = this.state;

    return (
      <>
        <div
          className={`${classes.Item} ${gridClasses.Row}`}
          onClick={this.handleRowClick}
        >
          <div className={`${gridClasses.Col} TextCenter`}>{ index + 1 }</div>
          <div className={`${gridClasses.Col}`}>
            {isEditing ? (
              <Autocomplete
                disableClearable
                openOnFocus
                selectOnFocus
                fullWidth
                size="small"
                handleHomeEndKeys={false}
                value={{...params}}
                onChange={this.handleSelectRoleChange}
                options={this._filteredOptions() || []}
                renderOption={(option) => option.name}
                getOptionLabel={(option) => ((option && option.name) || '')}
                getOptionSelected={(option, value) =>
                  value && value.id && option ? option.id === value.id : null
                }
                renderInput={(parms) => (
                  <TextField {...parms} error={!this._isRowValid(params)} placeholder="- Chọn -" />
                )}
              />
            ) : (
              name
            )}
          </div>
          <div className={`${gridClasses.Col}`}>
            { params?.getcare_data_permissions?.length ?
              <Tooltip
                title={<div className={classes.TooltipListWrap}>
                  { params?.getcare_data_permissions.map(item => (
                    <p key={item.id}>{ item.name }</p>
                  )) }
                </div>}
                arrow
                placement="bottom"
                interactive
              >
                <span className="TextSecondary">
                  { `${params?.getcare_data_permissions.length} quyền`}
                </span>
              </Tooltip> : ''
            }
          </div>
          <div className={`${gridClasses.Col} TextCenter`}>
            {isEditing ? (
              <>
                <IconButton
                  onClick={this.handleCancel}
                  style={{ padding: '0' }}
                  size="small"
                >
                  <CancelOutlined fontSize="small" color="secondary" />
                </IconButton>
                <IconButton
                  onClick={this.handleSave}
                  disabled={!this._isRowValid(params)}
                  style={{ padding: '0' }}
                  size="small"
                >
                  <CheckCircleOutlineOutlined
                    fontSize="small"
                    color={this._isRowValid(params) ? `primary` : `inherit`}
                  />
                </IconButton>
              </>
            ) : (
              <>
                <IconButton
                  onClick={this.handleRemove}
                  style={{ padding: '3px' }}
                  size="small"
                >
                  <DeleteOutline fontSize="small" />
                </IconButton>
                <IconButton
                  onClick={this.handleEdit}
                  style={{ padding: '0' }}
                  size="small"
                >
                  <EditOutlined fontSize="small" color="primary" />
                </IconButton>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

UserRole.propTypes = {
  roleList: PropTypes.array,

  idStr: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currentList: PropTypes.array,
  isSelected: PropTypes.bool,
  saveUserRole: PropTypes.func,
  removeUserRole: PropTypes.func,
  onItemSelectedToggle: PropTypes.func,
};

UserRole.defaultProps = {
  currentList: [],
  roleList: [],
};

export default UserRole;
