import React, { PureComponent } from 'react';
import vendorPriceApi from 'utils/api/admin/vendorPriceApi';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import NumberFormatCustom from 'components/NumberFormatCustom/NumberFormatCustom';

import classes from './UpdateProductQuantitiesDialog.module.scss';

class UpdateProductQuantitiesDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    }
  }
  onClose = () => {
    this.props.onClose();
  };
  onEntered = () => {
  };
  handleSubmit = async (values) => {
    this.setState({
      isLoading: true,
    });
    const params = {
      getcare_product_ids: this.props.selectedItems.map(item => item.id),
      estimated_quantity: values.estimated_quantity,
      ka_days: values.ka_days,
    }
    const { data: response } = await vendorPriceApi.updateCustomerDelivery({
      params
    });
    if (!response.result) {
      toast.error(response.message);
      this.setState({ isLoading: false });
      return;
    }
    this.setState({
      isLoading: false,
    }, () => {
      //this.props.onSaveSuccess(response.data);
      this.props.onSaveSuccess(params);
      this.props.onClose();
    });
  }

  render() {
    const { isOpen } = this.props;
    const { isLoading } = this.state;

    return (
      <Dialog
        open={isOpen}
        onClose={this.onClose}
        onEntered={this.onEntered}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.Dialog}
      >
        <div className={`${classes.DialogContentWrap} ${isLoading && 'OverlayLoading'}`}>
          <Formik
            initialValues={{
              estimated_quantity: '',
              ka_days: '',
            }}
            onSubmit={(values) => {
              this.handleSubmit(values);
            }}
            validateOnChange={true}
            validateOnBlur={true}
            validationSchema={Yup.object().shape({
              estimated_quantity: Yup.number().required('Vui lòng nhập').min(0, 'Phải là số nguyên >= 0').integer('Phải là số nguyên >= 0'),
              ka_days: Yup.number().required('Vui lòng nhập').positive('Phải là số nguyên dương').integer('Phải là số nguyên dương'),
            })}
          >
            {(props) => {
              const {
                values,
                errors,
                handleChange,
                handleSubmit,
              } = props;
              return (
                <form noValidate autoComplete="off" className={classes.Dialog}>
                  <DialogTitle>Cập nhật hàng loạt</DialogTitle>
                  <DialogContent className={`${classes.DialogContent}`}>
                    <p className={`${classes.Info} ${classes.Warning}`}>
                      Hành động của bạn có thể ảnh hưởng đến một số đặc quyền bán đã thiết lập.
                    </p>
                    <div className={classes.FieldControl}>
                      <label>
                        Số lượng dự kiến <span className={classes.RequiredMark}>*</span>
                      </label>
                      <TextField
                        autoFocus
                        autoComplete="off"
                        value={values.estimated_quantity}
                        placeholder="Nhập..."
                        name="estimated_quantity"
                        error={!!errors.estimated_quantity}
                        helperText={errors.estimated_quantity}
                        onChange={handleChange}
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                      />
                    </div>
                    <div className={classes.FieldControl}>
                      <label>
                        Số ngày hiệu lực <span className={classes.RequiredMark}>*</span>
                      </label>
                      <TextField
                        autoComplete="off"
                        value={values.ka_days}
                        placeholder="Nhập..."
                        name="ka_days"
                        error={!!errors.ka_days}
                        helperText={errors.ka_days}
                        onChange={handleChange}
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                      />
                    </div>
                  </DialogContent>
                  <DialogActions className={classes.DialogActions}>
                    <Button type="button" variant="outlined" onClick={this.props.onClose}>
                      Huỷ bỏ
                    </Button>
                    <Button
                      type="button"
                      variant="contained"
                      onClick={handleSubmit}
                      color="primary"
                      autoFocus
                    >
                      Xác nhận
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          </Formik>
        </div>
      </Dialog>
    );
  }
}

export default UpdateProductQuantitiesDialog;
