import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import vendorApi from 'utils/api/admin/vendorApi';
import { toast } from 'react-toastify';

import { approvalStatusMap } from 'utils/constanst/adminVendorConstants';
import { getFullAddressStr } from 'utils/helper';

import { Link } from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import gridClasses from '../VendorListGrid.module.scss';
import classes from './VendorListItem.module.scss';

class VendorListItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ranking: props.ranking,
    }
  }
  componentDidUpdate(prevProps) {
    const { ranking } = this.props;
    if (ranking !== prevProps.ranking) {
      this.setState({ ranking });
    }
  }

  goToDetails = (e) => {
    this.props.history.push(`/vendor/${this.props.id}`);
  }
  getRankingOptions = () => {
    return Array.from(new Array(100), (_, index) => index + 1);
  }

  hanldeChangeRanking = async (e, newValue) => {
    this.setState({
      ranking: newValue,
    });
    try {
      const { data: response } = await vendorApi.updateVendor({
        id: this.props.id,
        params: {
          ranking: newValue,
        },
      });
      if (!response?.result) return;

      toast.success(`Nhà cung cấp ${this.props.name} đã được cập nhật thành công`);
    } catch(err) {
      toast.error(err);
    }
  }

  render() {
    const {
      id,
      name,
      code,
      phone,
      tax_code,
      registration_company,
      registration_address,
      getcare_ward,
      getcare_district,
      getcare_province,
      getcare_country,
      approval,
    } = this.props;
    const fullAddress = getFullAddressStr(registration_address, getcare_ward, getcare_district, getcare_province, getcare_country);

    return <div
      className={`${classes.ProductItem} ${gridClasses.Row}`}
      to={`/vendor/${id}`}
    >
      <div className={gridClasses.Col}>
        <Link to={`/vendor/${id}`}>{ code }</Link>
      </div>
      <div className={gridClasses.Col}>
      <Link to={`/vendor/${id}`}>{ name }</Link>
      </div>
      <div className={gridClasses.Col}>{ phone }</div>
      <div className={gridClasses.Col}>{ tax_code }</div>
      <div className={gridClasses.Col}>{ registration_company }</div>
      <div className={gridClasses.Col}>{ fullAddress }</div>
      <div className={gridClasses.Col}>
        <Autocomplete
          className={classes.Autocomplete}
          size="small"
          disableClearable
          handleHomeEndKeys={false}
          value={this.state.ranking || null}
          options={this.getRankingOptions()}
          renderOption={(option) => option}
          getOptionLabel={(option) => option.toString() || ''}
          getOptionSelected={(option, value) =>
            value && option ? option === value : null
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="- Chọn -"
            />
          )}
          onChange={this.hanldeChangeRanking}
        />
      </div>
      <div className={gridClasses.Col}>{ approvalStatusMap[approval] }</div>
    </div>;
  }
}

VendorListItem.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  code: PropTypes.string,
  name: PropTypes.string,
  phone: PropTypes.string,
  tax_code: PropTypes.string,
  registration_company: PropTypes.string,
  registration_address: PropTypes.string,
  approval: PropTypes.number,
};

export default VendorListItem;
